import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import commonStore, { userStore } from '../../../../common';
import { CREDIT_CARD, EVO_USER_TYPES, FORMS, USER_TYPES } from '../../../../common/Constants';
import { showModal } from '../../../../common/Utils';
import { withContent } from '../../../../components';
import paymentsStore, {
  fetchPaymentMethods,
  fetchPaymentsMethodContent,
  mapPaymentDetailsToPayload,
  submitManagePaymentsMethod,
  updateUserData,
} from '../../PaymentsStore';
import { tokenize } from '../../TokenEx';
import ManagePaymentMethod from './ManagePaymentMethod';

const { getMvjStrings } = commonStore.selectors;

const {
  selectors: {
    getCreditCardFields,
    getAccountECheckFields,
    getManagePaymentMethodInitialValues,
    getFailedNotificationModalData,
    getCardTypeHasError,
  },
  creators: { setPaymentMethodChangeType },
} = paymentsStore;

const { getCountryCodeFromCurrency, getIsTaAccount, getUpdateUserData, getUserType } = userStore.selectors;

const formName = FORMS.MANAGE_PAYMENT_METHOD;

const mapStateToProps = (state, ownProps) => {
  const { paymentMethodType, match } = ownProps;
  const { id } = match.params || '';

  let fields;
  let title;
  let message;
  let changeType;
  let backButton;
  const mvjStrings = getMvjStrings(state);
  const updateUserInfo = getUpdateUserData(state);
  const genericValidationMessage = get(mvjStrings.errors, 'genericValidationError[0].message', '');
  const restrictPaymentMessage =
    updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA]
      ? get(mvjStrings.errors, 'restrictPaymentAddAccountError[0].message', '')
      : '';
  const country = formValueSelector(formName)(state, 'country') || getCountryCodeFromCurrency(state);
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);
  const hasPreHeader = isTaAccount || [USER_TYPES.CSA, USER_TYPES.AIR].includes(userType);
  if (paymentMethodType === CREDIT_CARD) {
    ({ creditCardFields: fields, title, message, changeType, backButton } = getCreditCardFields(state)({
      paymentMethodType,
      id,
      country,
    }));
  } else {
    ({ accountECheckFields: fields, title, message, changeType, backButton } = getAccountECheckFields(state)(
      paymentMethodType,
      id
    ));
  }
  return {
    labels: {
      cancel: fields.cancel.placeholder,
      submit: id ? fields.update.placeholder : fields.add.placeholder,
      backButton,
    },
    fields,
    message,
    changeType,
    title,
    initialValues: {
      paymentMethodType,
      ...getManagePaymentMethodInitialValues(state)(id),
    },
    forEdit: !!id,
    failedNotificationModalData: getFailedNotificationModalData(state)(paymentMethodType, id),
    cardTypeError: getCardTypeHasError(state),
    genericValidationMessage,
    country,
    disableAddAccountButton: updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA],
    restrictPaymentMessage,
    hasPreHeader,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchContent: () => {
    dispatch(fetchPaymentsMethodContent(ownProps.paymentMethodType));
    dispatch(fetchPaymentMethods());
  },
  handleNameOnAccountChange: (passengerId) => dispatch(updateUserData(formName, passengerId)),
  handleBankAccountChange: (v) => dispatch(change(formName, 'reTokenize', v)),
  resetChangeType: () => dispatch(setPaymentMethodChangeType('')),
});

const makePaymentCall = (payload, resolve, rejectPromise) => (dispatch) => {
  const promise = dispatch(submitManagePaymentsMethod(payload))
    .then((errorMessage) => {
      if (errorMessage) {
        rejectPromise(errorMessage);
      } else {
        resolve(true);
      }
    })
    .catch((error) => {
      throw error;
    });
  return promise;
};

const onSubmit = (values, dispatch, { cardTypeError }) => {
  if (cardTypeError) {
    return Promise.reject(new SubmissionError({ _error: 'genericValidationError' }));
  }

  const { token, paymentMethodType, reTokenize } = values;
  const promise = new Promise((resolve, reject) => {
    const rejectPromise = (message) => {
      reject(new SubmissionError({ _error: message }));
    };

    const submit = (payload) => dispatch(makePaymentCall(payload, resolve, rejectPromise)).then(resolve).catch(reject);

    if ([null, 'MC', 'DI'].includes(values.cardType)) {
      rejectPromise('Invalid Card Type');
      return;
    }
    if (!token || reTokenize) {
      dispatch(tokenize(paymentMethodType)).then((tokenExData) => {
        if (!tokenExData) {
          rejectPromise('Failed to generate token');
          return;
        }

        const payload = mapPaymentDetailsToPayload(values, tokenExData.token);
        submit(payload);
      });
    } else {
      const payload = mapPaymentDetailsToPayload(values, token);
      submit(payload);
    }
  });
  // when post = save failed error, when put = update failed
  return promise.catch(() => {
    showModal('failedNotificationModal');
  });
};

const onChange = (values, dispatch, props) => {
  if (props.dirty) {
    dispatch(setPaymentMethodChangeType(''));
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: formName,
    onSubmit,
    onChange,
    enableReinitialize: true,
  })
);

export default enhance(ManagePaymentMethod);
