import { connect } from 'react-redux';

import commonStore from '../../../common';

import DefaultRouteSetter from './DefaultRouteSetter';

const { updateImplicitPath } = commonStore.creators;

const mapDispatchToProps = (dispatch) => ({
  updateImplicitPath: (path) => dispatch(updateImplicitPath(path)),
});

export default connect(null, mapDispatchToProps)(DefaultRouteSetter);
