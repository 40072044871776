import { DESKTOP, TOKENEX_SESSION_MIN_REFRESH } from '../../common/Constants';
import { getDeviceType } from '../../common/Utils';
import paymentsStore, { fetchTokenExConfiguration } from './PaymentsStore';

const {
  creators: { receiveTokenExEvent, resetTokenExMetadata, refreshedTokenEx, updateTokenExSession },
  selectors: { getTokenExConfiguration },
} = paymentsStore;

const IFRAME_EVENT_TYPE = 'message';
const TOKENEX_STYLES = {
  base:
    'border:1px solid #333333;' +
    'font-family:Arial, Helvetica, sans-serif;' +
    'font-size:16px;' +
    'font-weight:700;' +
    'height:28px;' +
    'line-height:1.5;' +
    'margin:3px 0 0 3px;' + // 3px margin on top and left to account for box-shadow
    'padding:15px 15px 5px;' +
    'width:calc(100% - 38px);' +
    '-webkit-border-radius: 0;',
  // Subtract 15px padding, 1px border-width and 3px box-shadow on each side

  focus: 'border-color:#333333 !important;box-shadow:0 0 0 3px rgba(0, 123, 255, 0.45);',
  // Set focus border-color to important to override error color

  error: 'border-color:#b10a32;',
};

function handleEvent({ event: { data, origin }, eventListener, originUrl, resolve }) {
  const removeEventListener = () => {
    if (eventListener) {
      window.removeEventListener(IFRAME_EVENT_TYPE, eventListener);
    }
  };

  if (origin === originUrl) {
    try {
      const { data: tokenExData, event } = data && typeof data === 'string' ? JSON.parse(data) : data;
      switch (event) {
        case 'tokenize': {
          if (resolve) {
            removeEventListener();
            resolve(tokenExData);
          }
          break;
        }
        case 'validate': {
          if (!tokenExData.isValid && resolve) {
            removeEventListener();
            resolve(null);
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new Error(`${error} - data: ${data} (${typeof data})`);
      } else {
        throw error;
      }
    }
  }
}

const listenToWindowEvent = (originUrl) => () => {
  function eventListener(event) {
    handleEvent({ event, originUrl });
  }

  window.addEventListener(IFRAME_EVENT_TYPE, eventListener);

  return () => window.removeEventListener(IFRAME_EVENT_TYPE, eventListener);
};

let iframe;
let unlistenFunction;

export const focus = () => {
  iframe.focus();
};

export const tokenize = (paymentMethod) => (dispatch, getState) =>
  new Promise((resolve) => {
    if (!iframe) {
      resolve(null);
    }

    const { tokenExSessionUrl } = getTokenExConfiguration(getState())(paymentMethod);

    window.addEventListener(IFRAME_EVENT_TYPE, (event) => {
      handleEvent({
        event,
        eventListener: handleEvent,
        originUrl: tokenExSessionUrl,
        resolve,
      });
    });

    iframe.tokenize();
  });

const renderTokenExIframe = (paymentMethod, handleBankAccountChange, forceRefresh = false) => (dispatch) => {
  const device = getDeviceType();
  dispatch(resetTokenExMetadata());
  dispatch(fetchTokenExConfiguration(paymentMethod, forceRefresh)).then(({ tokenExSessionUrl, ...partialConfig }) => {
    const tokenExConfig = {
      ...partialConfig,
      enablePrettyFormat: true,
      enableValidateOnBlur: true,
      inputType: device === DESKTOP ? 'text' : 'number',
      styles: {
        ...TOKENEX_STYLES,
      },
    };

    if (iframe) {
      iframe.remove();
    }
    const containerId = 'tokenExIframeDiv';
    const { TokenEx } = window;
    if (!TokenEx || !document.getElementById(containerId)) {
      // TODO: In a future story, handle missing TokenEx script
      // TODO: handle missing tokenExIframeDiv container
      return;
    }

    iframe = TokenEx.Iframe(containerId, tokenExConfig);

    if (!unlistenFunction) {
      unlistenFunction = dispatch(listenToWindowEvent(tokenExSessionUrl));
    }

    // Get "fake" Bank Account Number input with masked characters and its container
    const maskedBankAccount = document.getElementById('maskedBankAccount');
    const maskedBankAccountContainer = document.querySelector('.tokenex-wrapper .input-text-section');

    iframe.on('load', () => {
      document.getElementsByClassName('placeholder-wrapper')[0].classList.add('iframe-loaded');
      dispatch(updateTokenExSession());
      if (maskedBankAccount) {
        maskedBankAccountContainer.classList.add('iframe-loaded');
      }
    });

    iframe.on('focus', () => {
      dispatch(receiveTokenExEvent({ active: true, touched: true }));
      dispatch(refreshedTokenEx(false));
    });

    iframe.on('blur', () => {
      dispatch(receiveTokenExEvent({ active: false }));
      dispatch(refreshedTokenEx(false));
    });

    iframe.on('validate', ({ cardType, firstSix, isValid, validator }) => {
      dispatch(
        receiveTokenExEvent({
          validate: {
            cardType,
            firstSix,
            isValid,
            validator,
          },
        })
      );

      if (maskedBankAccount) {
        const forceNewToken = isValid || validator !== 'required';
        handleBankAccountChange(forceNewToken);
      }
    });

    iframe.on('cardTypeChange', ({ possibleCardType }) => {
      dispatch(receiveTokenExEvent({ possibleCardType }));
    });

    const noticeTime = Date.now();
    iframe.on('notice', () => {
      if (Date.now() - noticeTime > TOKENEX_SESSION_MIN_REFRESH) {
        dispatch(renderTokenExIframe(paymentMethod, handleBankAccountChange, true));
        dispatch(refreshedTokenEx(true));
      }
    });

    iframe.load();
  });
};

export default renderTokenExIframe;
