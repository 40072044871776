import React from 'react';

import PropTypes from '../../PropTypes';

import { Modal } from '..';
import { prepareHtml } from '../../common/Utils';
import './ViewOnly.scss';

const ViewOnlyModal = ({ id, printLabel, title, subtitle }) => (
  <Modal id={id} printLabel={printLabel}>
    {title && (
      <div className="close-to-sailing-modal mvj-modal">
        <div className="row no-gutters modal-heading">
          <div className="title">
            <span>{title}</span>
          </div>
        </div>
        <div className="horizontal-separator" />
        <div className="modal-body">
          <div className="subtitle" dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        </div>
      </div>
    )}
  </Modal>
);

ViewOnlyModal.propTypes = {
  id: PropTypes.string.isRequired,
  printLabel: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewOnlyModal.defaultProps = {
  printLabel: '',
  title: '',
  subtitle: '',
};

export default ViewOnlyModal;
