import get from 'lodash/get';
import { connect } from 'react-redux';
import { change, formValueSelector, getFormMeta, touch } from 'redux-form';
import commonStore from '../../../common';
import { CARD_TYPE_REPLACE_TOKEN, CREDIT_CARD_TYPE, FORMS } from '../../../common/Constants';
import { stringListOr } from '../../../common/Utils';
import paymentsStore from '../../../pages/payments/PaymentsStore';
import CreditCard from './CreditCard';

const {
  selectors: { getTokenExMetaData, getIsTokenExLoaded, getAvailableCreditCardTypes },
  creators: { updateCardTypeError },
} = paymentsStore;

const { getErrors } = commonStore.selectors;

const getFieldPrefix = (form) =>
  (form === FORMS.PAYMENT_CHECKOUT || form === FORMS.AIR_PAYMENT_CHECKOUT ? 'account.' : '');

const isCardInvalid = ({ cardType, mappedCard, validCardTypes }) => {
  if (!mappedCard) {
    return true;
  }
  if (cardType === 'unknown') {
    return false;
  }
  return validCardTypes && !validCardTypes.includes(mappedCard.code);
};

const getCreditCardTypeError = (cardTypes, baseString, form) => {
  const isOnboard = [FORMS.MANAGE_PAYMENT_METHOD, FORMS.ONBOARD_CREDIT_CARD].includes(form);
  const listedCardTypes = stringListOr(
    cardTypes
      .filter((ct) => (CREDIT_CARD_TYPE[ct]?.label && isOnboard ? CREDIT_CARD_TYPE[ct].isOnboard : true))
      .map((ct) => CREDIT_CARD_TYPE[ct].label)
  );
  if (baseString.indexOf(CARD_TYPE_REPLACE_TOKEN) > -1) {
    return baseString.replace(CARD_TYPE_REPLACE_TOKEN, listedCardTypes);
  }
  return `${baseString} ${listedCardTypes}.`;
};

const handleCardTypeChange = (form, data) => (dispatch, getState) => {
  const { cardType = null } = data;
  const fieldPrefix = getFieldPrefix(form);
  const saveField = `${fieldPrefix}save`;
  const cardTypeField = `${fieldPrefix}cardType`;

  const mappedCard = CREDIT_CARD_TYPE[cardType] || CREDIT_CARD_TYPE.UNKNOWN;
  const validCardTypes = getAvailableCreditCardTypes(getState());
  const cardTypeInvalid = isCardInvalid({ cardType, mappedCard, validCardTypes });
  if (cardTypeInvalid) {
    dispatch(change(form, saveField, false));
  }
  dispatch(updateCardTypeError(cardTypeInvalid));
  dispatch(change(form, cardTypeField, cardType));
  dispatch(touch(form, cardTypeField));
};

const handleExpiryDateChange = (form, date) => (dispatch, getState) => {
  const fieldPrefix = getFieldPrefix(form);
  const expiryDateField = `${fieldPrefix}expiryDate`;

  const currentDate = formValueSelector(form)(getState(), expiryDateField);
  const newDate = {
    ...currentDate,
    ...date,
  };
  dispatch(change(form, expiryDateField, newDate));
};

const mapStateToProps = (state, { form }) => {
  const formMeta = getFormMeta(form)(state);
  const fieldPrefix = getFieldPrefix(form);
  const errors = getErrors(state);
  const isTokenExLoaded = getIsTokenExLoaded(state);
  const fieldSuffix = '.active';
  const { possibleCardType, cardType: validCardType } = getTokenExMetaData(state);
  const tokenExCardType = possibleCardType || validCardType || 'unknown';
  const fieldName = `${getFieldPrefix(form)}cardType`;
  const currentCardType = formValueSelector(form)(state, fieldName);

  const mappedCard =
    Object.values(CREDIT_CARD_TYPE).find(({ tokenExCode }) => tokenExCode === tokenExCardType) ||
    CREDIT_CARD_TYPE.UNKNOWN;
  const cardTypeValue = mappedCard.code || tokenExCardType;
  let { iconName } = mappedCard;

  const activeMonth = get(formMeta, `${fieldPrefix}expiryMonth${fieldSuffix}`);
  const activeYear = get(formMeta, `${fieldPrefix}expiryYear${fieldSuffix}`);
  const validCardTypes = getAvailableCreditCardTypes(state);
  const invalidCard = isCardInvalid({
    cardType: cardTypeValue,
    mappedCard,
    validCardTypes,
  });

  if (invalidCard) {
    iconName = 'unknown-card';
  }

  const baseString =
    form === FORMS.MANAGE_PAYMENT_METHOD || form === FORMS.ONBOARD_CREDIT_CARD
      ? errors.SelectCardTypeInvalidAdd
      : errors.SelectCardTypeInvalid;
  const invalidCardError = getCreditCardTypeError(validCardTypes, baseString, form);

  return {
    expiryDateActive: activeMonth || activeYear,
    cardTypeValue,
    currentCardType,
    iconName,
    invalidCardError: invalidCard ? invalidCardError : '',
    isTokenExLoaded,
  };
};

const mapDispatchToProps = (dispatch, { form }) => ({
  handleExpiryDateChange: (date) => dispatch(handleExpiryDateChange(form, date)),
  updateCardType: (type) => {
    dispatch(handleCardTypeChange(form, { cardType: type }));
  },
  resetFields: (...fields) => fields.forEach((field) => dispatch(change(form, field, ''))),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
