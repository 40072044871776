import { connect } from 'react-redux';
import commonStore, { modalStore } from '../../common';
import { APP_PATHS } from '../../common/Constants';
import userStore, { fetchCart } from '../../common/UserStore';
import { navigateTo } from '../../common/Utils';
import { removeJourneyAddons } from '../../pages/payments/PaymentsStore';
import CartSoldOut from './CartSoldOut';

const { updateCartItemCount } = userStore.creators;
const { clearModal } = modalStore.creators;

const {
  creators: { handleSuccessfulCartUpdate },
} = commonStore;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: async (addonIds) => {
    dispatch(updateCartItemCount(-addonIds.length));
    try {
      // Pass false to removeJourneyAddons and manually retrive the cart.
      // We could pass true but removeJourneyAddons does not await the
      // fetchCart() response, and modifying it to do so would unnecessarily slow down other
      // call sites of removeJourneyAddons
      await dispatch(removeJourneyAddons(addonIds, false));
      await dispatch(fetchCart(true));
    } catch (e) {
      navigateTo(APP_PATHS.OOPS_PAGE);
      return;
    }

    // Call this after we receive the latest cart data via fetchCart()
    ownProps.onAddonsRemoved?.();

    dispatch(clearModal());
    dispatch(handleSuccessfulCartUpdate());
  },
});

export default connect(null, mapDispatchToProps)(CartSoldOut);
