import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getWaiter } from 'redux-waiter';
import commonStore from '../../common';
import { PAGE_NAMES, WAITER_NAMESPACES } from '../../common/Constants';
import userStore from '../../common/UserStore';
import { withContent } from '../../components';
import { fetchHelpPageContent } from '../help/HelpStore';
import Air from './Air';
import airStore, { fetchAirPageContent } from './AirStore';

const { getLabels } = commonStore.selectors;
const { isAllGifCompleted, getBookingLoadingFlag } = userStore.selectors;
const { getCustomizableTabs, getTitle, isLoadingTabs, getAirFaq, getAirCms } = airStore.selectors;
const { AIR_VIEWED, GET_AIR_SCHEDULES } = WAITER_NAMESPACES;

const mapStateToProps = (state, { location, match }) => {
  const cms = getAirCms(state);
  const customizableTabs = getCustomizableTabs(state)(location, match) || {};
  const { tabs, isCruiseOnly } = customizableTabs;
  const airFaqTitle = get(cms, ['abeBookingAirFaqTitle', 'title'], 'Booking Air FAQ’s');
  const airFaqSubTitle = get(
    cms,
    ['abeBookingAirFaqSubTitle', 'title'],
    'Have questions about Viking Air? Search our Air FAQ’s below.'
  );

  return {
    isCruiseOnly,
    isLoading:
      isLoadingTabs(state) ||
      getBookingLoadingFlag(state) ||
      getWaiter(state, GET_AIR_SCHEDULES).isPending ||
      getWaiter(state, GET_AIR_SCHEDULES).response === null ||
      getWaiter(state, AIR_VIEWED).isPending,
    tabLabels: get(getLabels(state), `pages.${PAGE_NAMES.NEW_AIR}.tabs`, {}),
    tabs,
    title: getTitle(state),
    isAllGifCompleted: isAllGifCompleted(state),
    airFaq: getAirFaq(state),
    airFaqTitle,
    airFaqSubTitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchAirPageContent());
    dispatch(fetchHelpPageContent());
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Air);
