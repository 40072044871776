import Notifications from '@viking-eng/notifications';
import React from 'react';
import { history } from '../../common';
import { APP_PATHS } from '../../common/Constants';
import { PageHeader } from '../../components';
import { NotificationModal } from '../../modals';
import PropTypes from '../../PropTypes';
import './Notifications.scss';

class MvjNotifications extends React.Component {
  render() {
    const {
      dismissNotification,
      viewedNotification,
      labels: { back },
      match: { url },
      title,
      notifications,
      isLoading,
      modalData,
      openModal,
      closeModal,
      hasPreHeader,
      trackingData,
    } = this.props;
    const getNotificationIcon = (isDismissable, viewed, dismissed, id) => {
      let notificationIcon = '';
      if (hasPreHeader && !viewed && id) {
        notificationIcon = 'ellipse';
      }
      if (!viewed && id) {
        notificationIcon = 'ellipse';
      }
      if (!isDismissable && viewed) {
        notificationIcon = 'exclamation-mark-filled';
      }
      if (isDismissable && viewed) {
        notificationIcon = '';
      } else if (dismissed || !id) {
        notificationIcon = '';
      }
      return notificationIcon;
    };
    const getNotificationClassName = (isDismissable, viewed, id) => {
      let notificationClassName = '';
      if (hasPreHeader && !viewed && id) {
        notificationClassName = 'notification-not-viewed';
      }
      if (!viewed && id) {
        notificationClassName = 'notification-not-viewed';
      } else if (!id) {
        notificationClassName = 'no-notification';
      }
      return notificationClassName;
    };
    const notificationsWithActions = notifications.map(
      ({ button, id, alertCode, voyageId, isDismissable, viewed, dismissed, targets, ...notification }) => ({
        ...notification,
        id: `${alertCode}_${voyageId}`,
        alertCode,
        voyageId,
        className: getNotificationClassName(isDismissable, viewed, id),
        icon: getNotificationIcon(isDismissable, viewed, dismissed, id),
        button: {
          ...button,
          onButtonClick: () => {
            if (notification.url && !targets) {
              window.open(notification.url, '_blank');
              if (isDismissable) {
                dismissNotification(id, voyageId);
              } else {
                viewedNotification(id, voyageId);
              }
            } else if (notification.url) {
              history.push(notification.url);
            } else {
              openModal('notificationDetail', id);
            }
            if (isDismissable && targets) {
              dismissNotification(id, voyageId);
            }
            if (!isDismissable && targets) {
              viewedNotification(id, voyageId);
            }
          },
        },
      })
    );
    return (
      <div id="notificationsPage" className="notifications-page">
        <PageHeader
          backButton={{ label: back, previousPagePath: APP_PATHS.INDEX }}
          hasLine
          title={title}
          url={url}
          isLoading={isLoading}
        />
        <Notifications notifications={notificationsWithActions} variant="page" />
        <NotificationModal
          id="notificationDetail"
          align="left"
          onClose={() => {
            dismissNotification(modalData.notificationId, modalData.voyageId);
            closeModal(modalData.notificationId);
          }}
          trackingData={trackingData}
          {...modalData}
        />
      </div>
    );
  }
}

MvjNotifications.propTypes = {
  dismissNotification: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string),
  match: PropTypes.routerMatch.isRequired,
  title: PropTypes.string,
  notifications: PropTypes.alerts,
  isLoading: PropTypes.bool,
  modalData: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

MvjNotifications.defaultProps = {
  labels: {},
  title: '',
  notifications: [],
  isLoading: true,
};

export default MvjNotifications;
