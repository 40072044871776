import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { APP_PATHS } from '../../common/Constants';
import PropTypes from '../../PropTypes';
import DefaultRouteSetter from './defaultRouteSetter/DefaultRouteSetterContainer';

const renderRoute = ({ Component, componentProps, path }) => (props) => (
  <DefaultRouteSetter implicitPath={path}>
    <Component {...props} {...componentProps} />
  </DefaultRouteSetter>
);

const RouteSwitch = ({ history, paths, url }) => {
  const routes = [];
  const { pathname } = history.location;

  paths.forEach(({ component, componentProps, path, subPaths = [] }) => {
    const componentPath = `${url}${path}`;

    subPaths.forEach(({ component: subComponent, componentProps: subComponentProps, path: subPath }) => {
      routes.push({
        path: `${componentPath}${subPath}`,
        render: renderRoute({
          Component: subComponent,
          componentProps: subComponentProps,
        }),
      });
    });

    routes.push({
      path: componentPath,
      render: renderRoute({
        Component: component,
        componentProps,
      }),
    });
  });

  const defaultPath = paths.find((path) => path.isDefault);
  if (defaultPath) {
    const { component, componentProps, path } = defaultPath;
    routes.push({
      exact: true,
      path: url,
      render: renderRoute({
        Component: component,
        componentProps,
        path: url + path,
      }),
    });
  }

  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      {url !== pathname && !routes?.includes(pathname) && <Redirect to={APP_PATHS.OOPS_PAGE} />}
    </Switch>
  );
};

RouteSwitch.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.routerMatch),
  url: PropTypes.string,
};

RouteSwitch.defaultProps = {
  paths: [],
  url: null,
};

export default RouteSwitch;
