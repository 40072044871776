import Button from '@viking-eng/button';
import React from 'react';

import PropTypes from '../../../PropTypes';
import { goTo } from '../../../common/Utils';
import './noDataPage.scss';

const NoDataPage = ({ message, actionText, actionLink }) => {
  return (
    <div className="no-data-page">
      {message}
      <div className="col-12 justify-content-center">
        <Button appearance="secondary-blue" onButtonClick={goTo(actionLink)} className="upgrade-class-btn">
          {actionText}
        </Button>
      </div>
    </div>
  );
};

NoDataPage.propTypes = {
  message: PropTypes.string,
  actionText: PropTypes.string,
  actionLink: PropTypes.string,
};
NoDataPage.defaultProps = {
  message: '',
  actionText: '',
  actionLink: '',
};
NoDataPage.displayName = 'NoDataPage';

export default NoDataPage;
