import Button from '@viking-eng/button';
import PropTypes from '@viking-eng/prop-types';
import React from 'react';

import { SEATMAP_TIMEOUT_CALL_MAX } from '../../../common/Constants';
import { prepareHtml } from '../../../common/Utils';

import './seats.scss';

const EmptySeatMap = ({
  emptySeatMapDesktop,
  emptySeatMapMobile,
  seatMapTimeOut,
  tryAgainText,
  onClickTryAgain,
  seatTimeOutCallCount,
}) => (
  /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
  <div className="empty-seat-map" tabIndex="0">
    <div className="left-wing-start" />
    <div className="left-wing" />
    <div className="left-wing-end" />
    <div className="empty-body d-flex align-items-center">
      <div className="d-none d-md-block">
        {!seatMapTimeOut || seatTimeOutCallCount > SEATMAP_TIMEOUT_CALL_MAX ? (
          <h6 className="empty-seatmap-text" dangerouslySetInnerHTML={prepareHtml(emptySeatMapDesktop)} />
        ) : (
          <h6 className="empty-seatmap-text">
            {`${tryAgainText[0]} `}
            <Button type="button" appearance="link" onButtonClick={() => onClickTryAgain()}>
              {tryAgainText[1]}
            </Button>
            {tryAgainText[2]}
          </h6>
        )}
      </div>
      <div className="d-md-none">
        {!seatMapTimeOut ? (
          <div className="small-empty-text" dangerouslySetInnerHTML={prepareHtml(emptySeatMapMobile)} />
        ) : (
          <div className="small-empty-text">
            {`${tryAgainText[0]} `}
            <Button type="button" appearance="link" onButtonClick={() => onClickTryAgain()}>
              {tryAgainText[1]}
            </Button>
            {tryAgainText[2]}
          </div>
        )}
      </div>
    </div>
    <div className="right-wing-start" />
    <div className="right-wing" />
    <div className="right-wing-end" />
  </div>
);

EmptySeatMap.propTypes = {
  emptySeatMapDesktop: PropTypes.string,
  emptySeatMapMobile: PropTypes.string,
  seatMapTimeOut: PropTypes.bool,
  tryAgainText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onClickTryAgain: PropTypes.func,
  seatTimeOutCallCount: PropTypes.number,
};

EmptySeatMap.defaultProps = {
  emptySeatMapDesktop: '',
  emptySeatMapMobile: '',
  seatMapTimeOut: false,
  tryAgainText: '',
  onClickTryAgain: () => null,
  seatTimeOutCallCount: 0,
};

EmptySeatMap.displayName = 'EmptySeatMap';

export default EmptySeatMap;
