import PropTypes from '@viking-eng/prop-types';
import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router';
import { AIR_PATHS, APP_PATHS, MODALS } from '../../common/Constants';
import { AirConfirmModal } from '../../modals';

const okayPages = [
  AIR_PATHS.SELECT_PASSENGERS,
  AIR_PATHS.SEARCH,
  AIR_PATHS.SEATS,
  AIR_PATHS.REVIEW,
  AIR_PATHS.CHECKOUT,
  AIR_PATHS.DONE,
  APP_PATHS.OOPS_PAGE,
  APP_PATHS.MAINTENANCE,
  APP_PATHS.SYSTEM_MAINTENANCE,
];

const okayModals = [MODALS.SEATS_PENDING, MODALS.NO_PNR, MODALS.TOKEN_ERROR];

class AirWindowClose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      when: false,
      navigateToLocation: '',
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onClose);
    this.setState({
      when: true,
    });
  }

  onClose = (e) => {
    const { airLeavePageModalContent, history } = this.props;
    if (
      history.location.pathname !== AIR_PATHS.HOME &&
      history.location.pathname !== AIR_PATHS.DONE &&
      history.location.pathname.includes(AIR_PATHS.HOME)
    ) {
      const confirmationMessage = airLeavePageModalContent.message;
      e.returnValue = confirmationMessage;
      e.stopPropagation();
      e.cancelBubble = true;
      e.stopImmediatePropagation();
      return confirmationMessage;
    }
    window.onbeforeunload = null;
    e.preventDefault();
    return false;
  };

  handleAirLeavePage = (location) => {
    const { handleShowModal, modalView, pageCheck } = this.props;
    const { navigateToLocation } = this.state;
    const currentPath = location.pathname.toLowerCase();
    const destinationPath = navigateToLocation.toLowerCase();
    if (pageCheck && destinationPath && pageCheck(currentPath, destinationPath) === false) {
      this.setState({
        navigateToLocation: currentPath,
      });
      return false;
    }

    if (!okayPages.includes(currentPath) && !okayModals.includes(modalView) && destinationPath !== currentPath) {
      this.setState({
        navigateToLocation: currentPath,
      });
      handleShowModal(MODALS.AIR_LEAVE_PAGE);
      return false;
    }
    return true;
  };

  handleConfirmClick = () => {
    const { clearModal, onConfirmClick } = this.props;
    const { navigateToLocation } = this.state;
    clearModal();
    onConfirmClick(navigateToLocation);
  };

  handleCancelClick = () => {
    const { clearModal } = this.props;
    clearModal();
    this.setState({
      navigateToLocation: '',
    });
  };

  render() {
    const { when } = this.state;
    const { airLeavePageModalContent } = this.props;
    return (
      <>
        <Prompt when={when} message={this.handleAirLeavePage} />
        <AirConfirmModal
          modalProps={airLeavePageModalContent}
          confirmHandler={this.handleConfirmClick}
          cancelHandler={this.handleCancelClick}
        />
      </>
    );
  }
}

AirWindowClose.propTypes = {
  airLeavePageModalContent: PropTypes.shape(),
  clearModal: PropTypes.func,
  handleShowModal: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any),
  modalView: PropTypes.string,
  onConfirmClick: PropTypes.func,
  pageCheck: PropTypes.func,
};

AirWindowClose.defaultProps = {
  airLeavePageModalContent: {},
  clearModal: () => null,
  handleShowModal: () => null,
  history: {},
  modalView: '',
  onConfirmClick: () => null,
  pageCheck: () => true,
};

export default withRouter(AirWindowClose);
