import Icon from '@viking-eng/icon';
import UpsellBar from '@viking-eng/upsell-bar';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from '../../../PropTypes';
import {
  APP_PATHS,
  CALENDAR_ITEM_PIXELS_PER_HOUR,
  EXTENSION_TYPES,
  MODALS,
  VOYAGE_TYPE,
} from '../../../common/Constants';
import { navigateTo } from '../../../common/Utils';
import { ReservationModal, ShorexModal } from '../../../modals';
import DiningModalSideContent from '../../diningModalSideContent/DiningModalSideContentContainer';
import SpaModalSideContent from '../../spaModalSideContent/SpaModalSideContentContainer';
import CalendarLine from '../CalendarLine/CalendarLine';
import './CalendarDay.scss';
import CalendarEntry from './CalendarEntry';

const { DINE, SPA, SHOREX } = EXTENSION_TYPES;
const OTHER = 'OTHER';

class CalendarDay extends React.Component {
  componentDidMount() {
    const {
      fetchExcursions,
      fetchDiningContent,
      fetchSpaData,
      voyageType,
      shorexDatesToFetch,
      cartRedirectModifyModal,
      calendarItems,
      onEdit,
      passengers,
      itineraryDate,
    } = this.props;
    shorexDatesToFetch.forEach(fetchExcursions);
    if (cartRedirectModifyModal?.length) {
      const itemWithTimeConflictModify = calendarItems?.events.find(
        (element) => element.data.serviceCode === cartRedirectModifyModal
      );
      if (!isEmpty(itemWithTimeConflictModify)) {
        onEdit(itemWithTimeConflictModify, { passengers, itineraryDate }, 1);
      }
    }
    if (voyageType !== VOYAGE_TYPE.RIVER) {
      fetchDiningContent();
      fetchSpaData();
    }
  }

  componentDidUpdate() {
    const {
      fetchExcursions,
      fetchDiningContent,
      fetchSpaData,
      handleModalOpen,
      itineraryDate,
      excursionData,
      modalToOpen,
      diningLoaded,
      spaLoaded,
      voyageType,
      shorexDatesToFetch,
    } = this.props;
    const { date } = itineraryDate;
    const { loading: excursionLoading, loaded: excursionLoaded } = get(excursionData, `${date}`, {});
    if (!excursionLoading && !excursionLoaded && !get(excursionData, [date, 'excursions'])) {
      shorexDatesToFetch.forEach(fetchExcursions);
    }

    const isRiverOrExpedition = voyageType === VOYAGE_TYPE.RIVER || voyageType === VOYAGE_TYPE.EXPEDITION;
    const hasDiningReservations = !isRiverOrExpedition;
    const hasSpa = !isRiverOrExpedition;
    if (hasDiningReservations && !diningLoaded.loading && !diningLoaded.loaded) {
      fetchDiningContent();
    }
    if (hasSpa && !spaLoaded.loading && !spaLoaded.loaded) {
      fetchSpaData();
    }

    if (modalToOpen.item) {
      let dataLoaded;
      switch (modalToOpen.item.data.extensionType) {
        case DINE:
          dataLoaded = diningLoaded.loaded;
          break;
        case SPA:
          dataLoaded = spaLoaded.loaded;
          break;
        case SHOREX:
          dataLoaded = excursionLoaded;
          break;
        default:
          dataLoaded = false;
      }
      if (dataLoaded) {
        handleModalOpen(modalToOpen);
      }
    }
  }

  render() {
    const {
      passengers,
      onboardEventLabel,
      shoreEventLabel,
      calendarItems: { events = [], lines = [] },
      upsellItems,
      isUpsellBarDisabled,
      modalData: { dining, shorex, spa },
      onEdit,
      itineraryDate,
      onModalClose,
      isSubmitting,
      cartRedirectModifyModal,
      setCartRedirectModifyModal,
    } = this.props;

    if (!itineraryDate) {
      navigateTo(APP_PATHS.ITINERARY);
    }

    const filteredUpsellItems = upsellItems.filter((item) => {
      const voyageType = itineraryDate.voyageType.toLowerCase();
      const itineraryType = voyageType === VOYAGE_TYPE.MISSISSIPPI ? VOYAGE_TYPE.RIVER : voyageType;
      return item.voyageType.includes(itineraryType);
    });

    const groupedEvents = events.reduce(
      (acc, item) => {
        if (!item) {
          return acc;
        }
        const category = item.data.extensionType === DINE ? DINE : OTHER;
        acc[category].push({
          item,
          itineraryDate,
          passengers,
        });
        return acc;
      },
      {
        [DINE]: [],
        [OTHER]: [],
      }
    );
    const modalData = dining.title ? dining : spa.modalData;
    return (
      <>
        {!cartRedirectModifyModal ? (
          <div className="calendar">
            {!isUpsellBarDisabled && <UpsellBar items={filteredUpsellItems} />}
            <div className="row header">
              <div className="col-2" />
              <div className="col-10 passenger-wrap">
                {passengers.map((name) => (
                  <div key={name} className="column">
                    <div className="h7 h5-md">{name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-2 column-wrap">
                {passengers.map((name, i) => (
                  <div key={name} className="column" style={{ top: CALENDAR_ITEM_PIXELS_PER_HOUR / 2 }}>
                    <div className="column-content">
                      {groupedEvents[OTHER].map((event) => (
                        <CalendarEntry
                          key={event.item.data.id}
                          {...event}
                          passengerIndex={i}
                          onClick={onEdit}
                          loading={event.item.isLoading(i)}
                          error={event.item.hasError(i)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <div className="column column-full">
                  <div className="column-content" style={{ top: CALENDAR_ITEM_PIXELS_PER_HOUR / 2 }}>
                    {groupedEvents[DINE].map((event, index) => (
                      <CalendarEntry
                        key={event.item.data.id}
                        {...event}
                        passengerIndex={index}
                        onClick={onEdit}
                        loading={event.item.isLoading(index)}
                        error={event.item.hasError(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {lines.map((line) => (
              <CalendarLine {...line} key={line.hour} />
            ))}

            <div className="row">
              <div className="legend offset-2 col-10">
                <span className="shore-event">{shoreEventLabel}</span>
                <span className="onboard-event">{onboardEventLabel}</span>
              </div>
            </div>
            <ReservationModal
              {...modalData}
              id={MODALS.RESERVATION}
              onClose={onModalClose}
              forceAction={isSubmitting}
              sideContent={
                dining.title ? (
                  <DiningModalSideContent {...dining} onClose={onModalClose} />
                ) : (
                  <SpaModalSideContent {...spa.sideContent} onClose={onModalClose} />
                )
              }
            />
            <ShorexModal data={shorex} onClose={() => onModalClose(SHOREX)} />
          </div>
        ) : (
          <>
            <div className="time-conflict-modal-spinner">
              <Icon name="spinner" />
            </div>
            <ReservationModal
              {...modalData}
              id={MODALS.RESERVATION}
              onClose={() => {
                onModalClose();
                setCartRedirectModifyModal('');
              }}
              forceAction={isSubmitting}
              sideContent={
                dining.title ? (
                  <DiningModalSideContent
                    {...dining}
                    onClose={() => {
                      onModalClose();
                      setCartRedirectModifyModal('');
                    }}
                  />
                ) : (
                  <SpaModalSideContent
                    {...spa.sideContent}
                    onClose={() => {
                      onModalClose();
                      setCartRedirectModifyModal('');
                    }}
                  />
                )
              }
            />
            <ShorexModal data={shorex} onClose={() => onModalClose(SHOREX)} />
          </>
        )}
      </>
    );
  }
}

CalendarDay.propTypes = {
  passengers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onboardEventLabel: PropTypes.string,
  shoreEventLabel: PropTypes.string,
  calendarItems: PropTypes.shape({
    date: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        unconfirmed: PropTypes.bool,
        displayTime: PropTypes.string,
        item: PropTypes.shape({}),
      })
    ),
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        hour: PropTypes.number,
        min: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    city: PropTypes.string,
  }).isRequired,
  upsellItems: PropTypes.imageTiles.isRequired,
  isUpsellBarDisabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  itineraryDate: PropTypes.itineraryDate.isRequired,
  modalData: PropTypes.objectOf(PropTypes.shape(PropTypes.modalData)),
  onModalClose: PropTypes.func.isRequired,
  fetchExcursions: PropTypes.func.isRequired,
  fetchDiningContent: PropTypes.func.isRequired,
  fetchSpaData: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  excursionData: PropTypes.objectOf(
    PropTypes.shape({
      excursions: PropTypes.shape({}),
      loading: PropTypes.string,
      loaded: PropTypes.string,
    })
  ),
  modalToOpen: PropTypes.shape({
    item: PropTypes.shape({}),
    passengers: PropTypes.arrayOf(PropTypes.string),
    itineraryDate: PropTypes.itineraryDate,
    passengerIndex: PropTypes.number,
  }),
  diningLoaded: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  spaLoaded: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
  }),
  voyageType: PropTypes.string.isRequired,
  shorexDatesToFetch: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSubmitting: PropTypes.bool,
};

CalendarDay.defaultProps = {
  onboardEventLabel: '',
  shoreEventLabel: '',
  modalData: {
    dining: {},
  },
  excursionData: {},
  modalToOpen: {},
  diningLoaded: {
    loading: true,
    loaded: false,
  },
  spaLoaded: {
    loading: true,
    loaded: false,
  },
  isSubmitting: false,
};

export default CalendarDay;
