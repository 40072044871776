import AirItinerary from '@viking-eng/air-itinerary';
import TabPage from '@viking-eng/tab-page';
import React from 'react';
import PropTypes from '../../../PropTypes';
import remapCardDataBasedOnModalType from '../../../common/ModalUtils';
import { getModalType } from '../../../common/Utils';
import history from '../../../common/history';
import { AirPlusModal, ComparisonModal } from '../../../modals';
import './airBooking.scss';

class AirBooking extends React.Component {
  addClickEventsToCard = () => {
    const {
      content: { sections },
      handleCardDetailsClicked,
    } = this.props;
    return sections.map((section) => ({
      ...section,
      cards: section.cards.map((card) => {
        const modalType = getModalType(card.primaryButtonUrl);
        const onClick = () => handleCardDetailsClicked(modalType, card.id);
        card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle
        return remapCardDataBasedOnModalType(card, {
          onPrimaryButtonClick: onClick,
          onSecondaryButtonClick: onClick,
          defaultClick: onClick,
        });
      }),
    }));
  };

  render() {
    const { modalData, airSchedules, isSubmitting, isSubmittingArr } = this.props;

    const remappedSections = this.addClickEventsToCard();

    if (remappedSections.length && airSchedules.referenceNumber) {
      remappedSections[0].preContent = <AirItinerary airSchedules={airSchedules.byDate} {...airSchedules} />;
      remappedSections[0].title = airSchedules.labels.title;
    } else {
      remappedSections[0].title = '';
    }

    return (
      <div className="air-booking">
        <TabPage
          sections={remappedSections}
          headerText={
            airSchedules.referenceNumber || remappedSections[0].cards.length
              ? airSchedules.pageDescription
              : airSchedules.pageDescriptionBackUp
          }
          history={history}
        />
        <ComparisonModal {...modalData} id="comparisonModal" />
        <AirPlusModal data={modalData} isSubmitting={isSubmitting} isSubmittingArr={isSubmittingArr} />
      </div>
    );
  }
}

AirBooking.propTypes = {
  airSchedules: PropTypes.shape({
    byDate: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.airItinerary)),
  }),
  content: PropTypes.shape({
    sections: PropTypes.cardSections,
    pageDescription: PropTypes.string,
  }),

  handleCardDetailsClicked: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isSubmittingArr: PropTypes.arrayOf(PropTypes.bool),
  modalData: PropTypes.shape(PropTypes.modalData).isRequired,
};

AirBooking.defaultProps = {
  airSchedules: {},
  content: {
    pageDescription: null,
    sections: [],
  },
  isSubmitting: false,
  isSubmittingArr: [false, false],
};

export default AirBooking;
