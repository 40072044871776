import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore, { userStore } from '../../common';
import { APP_PATHS } from '../../common/Constants';
import { getPagePath } from '../../common/Utils';
import { withContent } from '../../components';
import onboardStore from '../onboard/OnboardStore';
import Calendar from './Calendar';
import calendarStore, { fetchCalendarItems, ITINERARY_PAGES } from './CalendarStore';

const { getItineraryNavigationData } = commonStore.selectors;
const { clearOnboardData } = onboardStore.creators;
const { getItinerary } = userStore.selectors;
const {
  creators: { clearCalendarItems },
  selectors: { getBackButton, getExpeditionsLandingDayNotification, getTabTitle, getCustomPageLink, isLoadingCalendar },
} = calendarStore;

const mapStateToProps = (state, { location: { pathname } }) => {
  const journeyDays = getItinerary(state);
  const start = get(journeyDays, '0.date');
  const date = pathname.split('/').filter((p) => p).length > 2 ? getPagePath(pathname) : start;

  const { ITINERARY, ITINERARY_SUMMARY } = ITINERARY_PAGES;
  const pageType = pathname.endsWith('/list') ? ITINERARY_SUMMARY : ITINERARY;

  return {
    backButton: getBackButton(state)(pageType),
    isLoading: isLoadingCalendar(state),
    title: getTabTitle(state)(pageType),
    navigationData: getItineraryNavigationData(state)(date, APP_PATHS.ITINERARY),
    customPageLink: getCustomPageLink(state)(pathname),
    landingDayNotification: getExpeditionsLandingDayNotification(state)(date),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchCalendarItems(true)),
  handleCalendarClear: () => {
    dispatch(clearCalendarItems());
    dispatch(clearOnboardData());
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Calendar);
