import React from 'react';
import CardList from '@viking-eng/card-list';
import { COMMON_CONSTANTS } from '@viking-eng/utils';

import PropTypes from '../../PropTypes';
import { ShorexModal } from '../../modals';

const { CARD_VARIANTS } = COMMON_CONSTANTS;

const ShorexCardList = ({ cards, onClose, reservationModalData }) => (
  <>
    <CardList cards={cards} variant={CARD_VARIANTS.FIXED_WIDTH} />
    <ShorexModal data={reservationModalData} onClose={onClose} />
  </>
);

ShorexCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.card),
  reservationModalData: PropTypes.shorexModalData,
  onClose: PropTypes.func,
};

ShorexCardList.defaultProps = {
  cards: [],
  reservationModalData: {
    mainContent: {},
    sideContent: {},
  },
  onClose: () => false,
};

export default ShorexCardList;
