import { LOCK_TYPES } from '../Constants';
import commonStore, {
  fetchCommonContent,
} from '../CommonStore';
import userStore, {
  handleUpdateLoggedInUser,
  fetchCart,
  verifyLockUnlockStatus,
  reloadBookings,
} from '../UserStore';
import modalStore from '../ModalStore';
import { fetchNotifications } from '../../pages/notifications/NotificationsStore';
import { triggerPageView } from '../Analytics';

const {
  creators: {
    clearState,
  },
  // selectors: {},
} = commonStore;

const {
  creators: {
    updateChangeBookingStatus
  },
  selectors: {
    getAuthData,
  }
} = userStore;

const {
  creators: {
    clearModal
  }
} = modalStore;

export const changeBookingFunction = ({ currentBookingNumber, newBookingNumber }) => (dispatch, getState) => {
  const authData = getAuthData(getState());
  dispatch(
    verifyLockUnlockStatus({
      type: LOCK_TYPES.UNLOCK,
      bookingId: currentBookingNumber,
    })
  );
  dispatch(
    verifyLockUnlockStatus({
      type: LOCK_TYPES.LOCK,
      bookingId: newBookingNumber,
    })
  );
  // set loading state
  dispatch(updateChangeBookingStatus(true));
  // update logged in user to correct bookingId
  dispatch(
    handleUpdateLoggedInUser({
      authData,
      bookingId: newBookingNumber,
    })
  )
    // fetch common content, cart, and booking details
    .then(() => {
      dispatch(clearState());
      dispatch(clearModal());
      return dispatch(reloadBookings(newBookingNumber, true)).then(() => {
        triggerPageView();
        dispatch(fetchCommonContent());
        dispatch(fetchNotifications(true));
        dispatch(fetchCart(true));
        // end loading state
        dispatch(updateChangeBookingStatus(false));
      });
    });
};
