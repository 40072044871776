import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import commonStore from '../../common';
import { APP_PATHS, FORMS } from '../../common/Constants';
import userStore from '../../common/UserStore';
import shorexStore from '../../pages/shorex/ShorexStore';
import Shorex from './Shorex';

const { getPassengers } = userStore.selectors;
const {
  selectors: { getShoreExModalInfo, getIsOnboardOnly },
} = shorexStore;

const {
  selectors: { submarineWaiverVideo },
} = commonStore;

const mapStateToProps = (state) => {
  const submarineWaiverVideoCMSData = submarineWaiverVideo(state);
  const subSafetyVideoText = submarineWaiverVideoCMSData?.callToActionTitle;
  const subSafetyVideoUrl = APP_PATHS.SUBMARINE_VIDEO;
  const passengers = getPassengers(state);
  const isSubShorex = getShoreExModalInfo(state)?.[0]?.metadata?.useSubmarineWaiver;
  const isLocked = passengers
    .map(({ passengerNumber }) => isSubmitting(`${FORMS.BOOKING}-${passengerNumber}`)(state))
    .some((submitting) => submitting);
  return {
    isLocked,
    isSubShorex,
    subSafetyVideoText,
    subSafetyVideoUrl,
    ...getIsOnboardOnly(state),
  };
};

export default connect(mapStateToProps)(Shorex);
