import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import accounting from 'accounting';
import Bowser from 'bowser';
import createDOMPurify from 'dompurify';
import { AllHtmlEntities } from 'html-entities';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isInteger from 'lodash/isInteger';
import startCase from 'lodash/startCase';
import times from 'lodash/times';
import moment from 'moment';
import scrollIntoView from 'scroll-into-view-if-needed';
import {
  AIRPHONE,
  AIRPHONE1,
  AIRPHONE2,
  APP_INSIGHTS_TRACK_TYPE,
  CC_EXPIRATION_YEARS,
  CHALLENGE_MERCHANT_URL,
  COUNTRIES,
  COUNTRY_TO_OFFICE,
  CURRENCY_BY_COUNTRY,
  CURRENCY_COUNTRY,
  CURRENCY_SYMBOLS,
  FALLBACK_IMAGE,
  FALLBACK_IMAGE_CSS_CLASS,
  FALLBACK_IMAGE_SVG,
  FIND_COUNTRY_MAPPING,
  FIVE_BY_FOUR,
  IMAGE_HANDLER_BASE_URL,
  IMAGE_HANDLER_BUCKET,
  KEYCODES,
  LONG_DATE_FORMAT,
  MARKETING_SITE_URLS,
  MOBILE,
  NAVIGATION_TYPES,
  NO_IMAGE,
  ONE_BY_ONE,
  PASSENGERS_RESERVATION_STATUS,
  PAYMENT_CHALLENGE_IFRAME_SIZES,
  PHONE,
  PHONE1,
  PHONE2,
  RESERVATION_STATE_KEYS,
  RESERVATION_STATUS,
  SORT_UK_COUNTRY,
  TAB_PATHS,
  TWO_BY_ONE,
  UK_SUPPORT_PHONE,
} from '../Constants';
import history from '../history';

const { logger } = appInsightsHelper;

// eslint-disable-next-line no-confusing-arrow
export const convertStringToStartCase = (str, keepSpecialChars = false) =>
  keepSpecialChars ? str.toLowerCase().replace(/\S+/g, capitalize) : startCase(str.toLowerCase());

export const safeUpperString = (val) => ((val === 0 ? '0' : val) || '').toString().toUpperCase().trim();

export const safeStringCompare = (val1, val2) => safeUpperString(val1) === safeUpperString(val2);

export const getPagePath = (pathname) => pathname.split('/').pop();

// eslint-disable-next-line no-confusing-arrow
export const getCleanedPath = (pathname) =>
  pathname?.slice(pathname?.length - 1) === '/' ? pathname?.slice(0, pathname?.length - 1) : pathname;

export const stringListComma = (arrStrings, separator, finalSeparator) => {
  if (!arrStrings.length) {
    return '';
  }
  if (arrStrings.length < 2) {
    return arrStrings[0];
  }
  if (arrStrings.length === 2) {
    return `${arrStrings[0]}${finalSeparator}${arrStrings[1]}`;
  }

  let ret = '';
  arrStrings.forEach((s, idx) => {
    const sep = idx === arrStrings.length - 1 ? finalSeparator : separator;
    ret += `${idx === 0 ? '' : sep}${s}`;
  });
  return ret;
};

export const stringListAnd = (arrStrings, oxfordComma = false) =>
  stringListComma(arrStrings, ', ', oxfordComma ? ', and ' : ' and ');

export const stringListOr = (arrStrings, oxfordComma = false) =>
  stringListComma(arrStrings, ', ', oxfordComma ? ', or ' : ' or ');

export const updateTabAttributes = (tabs, pathname = '', pageUrl = '') => {
  if (!tabs) {
    return [];
  }
  const path = pathname
    .split('/')
    .filter((e) => e)
    .pop();
  let url = `/${path}`;
  if (tabs.every((tab) => tab.url !== url)) {
    ({ url } = tabs.find((tab) => tab.default));
  }
  return tabs.map((tab) => ({
    ...tab,
    active: tab.url === url,
    url: `${pageUrl}${tab.url}`,
  }));
};

export const getAttributeDescription = (attributes, code) => {
  const attribute = attributes.find((a) => a.code === code) || {};
  return attribute.description;
};

const purifyDom = (html) => {
  const config = {
    ADD_ATTR: ['target'],
  };
  const domPurify = createDOMPurify(window);
  return { __html: domPurify.sanitize(html, config) };
};

export const decodeHtmlEntities = (encodedString) => new AllHtmlEntities().decode(encodedString);

export const prepareHtml = (html) => purifyDom(decodeHtmlEntities(html));

export const stripHTMLTags = (html) => (html || '').replace(/(<([^>]+)>)/gi, '');

export const setDefaultTab = (paths, tabs) => {
  if (!tabs || !tabs.length) {
    return paths;
  }
  return paths
    .filter(({ path }) => tabs.some(({ url }) => url.endsWith(path)))
    .map((path) => ({
      ...path,
      isDefault: tabs.find((tab) => tab.url.includes(path.path)).default,
    }));
};

export const findImageSrc = (imageSet, imageType) => {
  const images = Array.isArray(imageSet) ? imageSet : [];
  const image = images.find((img) => img.type === imageType);
  return image ? image.url : '';
};

export const findImageAlt = (imageSet, imageType) => {
  const images = Array.isArray(imageSet) ? imageSet : [];
  const image = images.find((img) => img.type === imageType);
  return image ? image.alt : '';
};

export const brokenImageHandler = (img, fallbackImage) => (evt) => {
  const addErrorClass = () => {
    if (target.className.indexOf(FALLBACK_IMAGE_CSS_CLASS) < 0) {
      target.className += ` ${FALLBACK_IMAGE_CSS_CLASS}`;
    }
  };
  const removeErrorClass = () => {
    target.className = target.className.replace(FALLBACK_IMAGE_CSS_CLASS, '');
  };
  const { target } = evt;
  if (target.srcset) {
    target.srcset = '';
  }
  const errorSrc = FALLBACK_IMAGE;
  removeErrorClass();
  if (target.src.indexOf(IMAGE_HANDLER_BASE_URL) > -1) {
    target.src = img.errorSrc || errorSrc;
  } else if ([img.errorSrc, target.src, fallbackImage].includes(NO_IMAGE)) {
    target.src = errorSrc;
    target.style.visibility = 'hidden'; // Hide but keep the space
    target.onError = '';
  } else if (![FALLBACK_IMAGE, FALLBACK_IMAGE_SVG].includes(target.src)) {
    target.src = errorSrc;
    addErrorClass();
  } else {
    target.style.display = 'none';
    target.onError = '';
  }
};

export const getImageAttributes = ({ image, tier, type, fallbackImage }) => {
  if (image && Object.keys(image).length > 0) {
    if (image.bucket === IMAGE_HANDLER_BUCKET) {
      return image;
    }
    const { alt, id, src, url, mediaUrl, ...imageProps } = image;
    const cleanString = (inputString) => (inputString || '').replace(/[^a-zA-Z0-9]/g, '');

    let imgSrc = url || src || mediaUrl || '';
    let bucket = '';
    let imageKey;
    const baseImage = {
      alt: '',
      baseUrl: IMAGE_HANDLER_BASE_URL,
      bucket,
      className: '',
      errorSrc: fallbackImage || FALLBACK_IMAGE,
      id: cleanString(imgSrc),
      imageKey: FALLBACK_IMAGE,
      isResponsive: false,
      mediaUrl: '',
      nodeType: 'mediaSet',
      src: FALLBACK_IMAGE,
      tier: tier || 'md',
      type,
      useFallbackImage: false,
      ...imageProps,
    };

    const fallbackImages = [FALLBACK_IMAGE, FALLBACK_IMAGE_SVG];
    if (['', ...fallbackImages].includes(imgSrc) || imgSrc.endsWith('/fallback.jpg')) {
      imgSrc = FALLBACK_IMAGE_SVG;
    }
    const imgSrcUrl = new URL(imgSrc);
    bucket = imgSrcUrl.origin;
    imageKey = imgSrc.substring(bucket.length + 1);
    if (!imageKey) {
      imageKey = imgSrc;
      bucket = IMAGE_HANDLER_BUCKET;
    }
    const isSVG = imgSrc.toLowerCase().endsWith('.svg');

    // eslint-disable-next-line no-underscore-dangle
    const imgAlt = alt !== '' ? alt : purifyDom(image.caption, { ALLOWED_TAGS: [] }).__html;

    const ret = {
      ...baseImage,
      alt: imgAlt,
      bucket,
      className: '',
      errorSrc: imgSrc,
      id: imageKey || cleanString(imgSrc),
      imageKey,
      isResponsive: true,
      src: isSVG ? imgSrc : '',
      tier,
      type,
      ...imageProps,
    };
    const fallbackImg = fallbackImage || imgSrc;
    ret.brokenImageHandler = brokenImageHandler(ret, fallbackImg);
    return ret;
  }
  return {};
};

export const getFallbackImage = (type, filename = '/fallback.jpg') => ({
  alt: 'Viking',
  type,
  ratio: type,
  url: filename,
  mediaUrl: filename,
});

export const getCardThumbnailObject = (images, renderFallback = true) => {
  if (!Array.isArray(images)) {
    return null;
  }

  [ONE_BY_ONE, TWO_BY_ONE, FIVE_BY_FOUR].forEach((type) => {
    if (renderFallback && !images.find((i) => i.type === type)) {
      images.push(getFallbackImage(type));
    }
  });

  return images
    .map(({ alt, type, url }) => {
      let tier;
      switch (type) {
        case ONE_BY_ONE:
        case FIVE_BY_FOUR:
          tier = 'md';
          break;
        case TWO_BY_ONE:
          tier = 'xs';
          break;
        default:
          break;
      }
      if (!tier) {
        return null;
      }
      return {
        type,
        tier,
        alt,
        src: url,
        className: 'img-fluid',
      };
    })
    .filter((tier) => tier);
};

export const getCarouselImageArray = ({
  images = [],
  imageRatio,
  imageRatioPriorities = imageRatio || ['original'],
  shouldRenderCaption = false,
}) =>
  images
    .reduce((acc, aspectRatioImages) => {
      let foundImage;
      try {
        for (let i = 0; !foundImage && i < imageRatioPriorities.length; i += 1) {
          if (Array.isArray(aspectRatioImages)) {
            foundImage = (aspectRatioImages || []).find((image) => image.type === imageRatioPriorities[i]);
          }
        }

        if (foundImage) {
          const { alt, caption, id, type, url, src, ...imgRest } = foundImage;
          const slideImage = {
            ...imgRest,
            alt,
            id,
            type,
            mediaUrl: url || src,
          };

          if (shouldRenderCaption) {
            slideImage.caption = caption;
          }

          // Seeing bad AEM image URLs with spaces
          slideImage.mediaUrl = slideImage.mediaUrl.replace(' ', '%20');
          acc.push(slideImage);
        }
      } catch {
        // do not remove
      }

      return acc;
    }, [])
    .map((image) => getImageAttributes({ image }));

export const formatMoney = (value, precision, country = '') =>
  accounting.formatMoney(checkFloatValue(value), {
    symbol: CURRENCY_BY_COUNTRY[country]?.symbol || CURRENCY_SYMBOLS.USD,
    precision: precision || 0,
    format: {
      pos: '%s%v',
      neg: '\u2013%s%v',
      zero: '%s%v',
    },
  });

const formatDecimals = (value) => (isInteger((100 * value).toFixed(2) / 100) ? 0 : 2);

export const formatMoneyABE = (value, country = '') => formatMoney(value, formatDecimals(value), country);

export const hideModal = (id, url) => {
  if (window.$) {
    window.$(`#${id}`).modal('hide');
  }
  if (url) {
    history.push(url);
  }
};

export const showModal = (id) => {
  if (window.$) {
    window.$(`#${id}`).modal('show');
  }
};

export const hideAllModals = () => {
  Array.from(document.getElementsByClassName('modal')).forEach(({ id }) => {
    if (window.$) {
      window.$(`#${id}`).modal('hide');
    }
  });
};

export const hideAllModalsAndNavigate = (url) => {
  hideAllModals();

  return history.push(url);
};

export const getModalType = (primaryButtonUrl) => {
  let modalType = primaryButtonUrl?.replace('#', '') || '';
  modalType = modalType ? modalType[0].toLowerCase() + modalType.substring(1) : '';
  return modalType;
};

/**
 * use a data object to compile a URL
 * @param   {string} baseUrl [baseUrl = ''] the root path (before any data/value dependencies)
 * @param   {array}  paths   [paths=[]] array of strings, each as a dot-notation path of
 *                           where find value in data object
 * @param   {object} data    [data={}] the data object used to populate values in the URL
 * @param   {object} queryParam [queryParam={}] The query params passed to the end of the
 *                              url if needed. Each key is an argument.
 *                           (see `paths` param. above)
 * @returns {string}         the final URL
 */
export const buildUrl = (baseUrl = '', paths = [], data = {}, queryParam = {}) => {
  const params = paths.map((p) => get(data, p));
  let url = [baseUrl].concat(params).join('/');

  if (queryParam) {
    const queryString = Object.entries(queryParam).reduce((acc, [key, value]) => {
      if (value === undefined) {
        return acc;
      }
      if (acc) {
        // eslint-disable-next-line no-param-reassign
        acc += '&';
        return `${acc}${key}=${encodeURIComponent(value)}`;
      }
      return `?${acc}${key}=${encodeURIComponent(value)}`;
    }, '');

    url += queryString;
  }

  return url;
};

export const mapCardSections = (sections = []) => {
  if (sections[0] && isEmpty(sections[0].cards)) {
    return sections;
  }
  return sections.map(({ title, cards = [], messages, reference, shipId, voyageType }) => {
    const onButtonClick = (buttonUrl) => {
      if (buttonUrl && !buttonUrl.startsWith('#')) {
        return () => {
          if (buttonUrl.startsWith('http')) {
            return window.open(buttonUrl, '_blank');
          }

          return hideAllModalsAndNavigate(buttonUrl);
        };
      }
      return null;
    };

    return {
      title,
      messages,
      reference,
      cards: cards.map(
        ({
          images,
          primaryButtonText,
          primaryButtonUrl,
          secondaryButtonText,
          secondaryButtonUrl,
          ...cardAttributes
        }) => ({
          ...cardAttributes,
          primaryButtonUrl,
          secondaryButtonUrl,
          primaryButton: {
            text: primaryButtonText,
            onButtonClick: onButtonClick(primaryButtonUrl),
          },
          secondaryButton: {
            text: secondaryButtonText,
            onButtonClick: onButtonClick(secondaryButtonUrl),
          },
          images: getCardThumbnailObject(images || [])
            .flat()
            .map((image) => getImageAttributes({ image })),
        })
      ),
      shipId,
      voyageType,
    };
  });
};

export const mapModalSections = (sections, noBorder = true) =>
  sections.map(({ image, longText, title, description, ...other }) => ({
    ...other,
    images: image ? getCardThumbnailObject(image) : [],
    description: longText || description,
    noBorder,
    title: title ? convertStringToStartCase(title, true) : '',
  }));

export const getPassengerFullName = (passenger) => {
  if (!passenger) {
    return '';
  }
  const { firstName, lastName, middle, title, suffix } = passenger;
  const fullName = [title, firstName, middle, lastName, suffix]
    .reduce((acc, name, index) => {
      let cased;
      if (name && name.replace(' ', '')) {
        cased = convertStringToStartCase(name, true);
        if (index === 0 || (index === 2 && name.length === 1)) {
          cased = `${cased}.`;
        }
        acc.push(cased);
      }
      return acc;
    }, [])
    .join(' ');
  return fullName;
};

export const getPassengerAbbrevName = (passenger) => {
  if (!passenger) {
    return '';
  }
  const { firstName, lastName, middle } = passenger;
  const fullName = [firstName, middle, lastName]
    .reduce((acc, name, index) => {
      if (name && name.replace(' ', '')) {
        let cased;
        cased = name.toUpperCase();

        // Middle initial
        if (index === 1) {
          cased = `${cased.charAt(0)}.`;
        }
        acc.push(cased);
      }
      return acc;
    }, [])
    .join(' ');
  return fullName;
};

export const goTo = (url) => () => {
  return history.push(url);
};

export const navigateTo = (url) => goTo(url)();

export const handleCmsLink = (e) => {
  const href = e.target.getAttribute('href');
  if (history && href && href.startsWith('/')) {
    e.preventDefault();
    hideAllModalsAndNavigate(href);
  }
};

export const mapCheckboxItems = ({ items = [], labelField, idField, groupName = '' }) => ({
  groupName,
  items: items.map((item) => ({
    label: item[labelField],
    id: item[idField],
  })),
});

export const convertIsoTime = (time, format) => moment(time, 'HH:mm').format(format);

export const getIsoDate = (date) => {
  const m = moment(date);
  return m.format('Y-MM-DD');
};

export const convertDate = ({ date, fromFormat, toFormat }) => {
  const m = moment(date, fromFormat);
  return m.format(toFormat);
};

export const createCalendarDate = (date, time) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedTime = moment(time, 'hhmm').format('THH:mm:ss');
  return formattedDate + formattedTime;
};

const getExpiryMonths = () => {
  const now = moment();
  return moment.months().map((month) => {
    now.month(month);
    return {
      label: now.format('MMM'),
      value: now.format('MM'),
    };
  });
};

const getExpiryYears = () => {
  const currentYear = moment().year();
  const yearsAhead = CC_EXPIRATION_YEARS || 10;
  return times(yearsAhead, (index) => {
    const year = (currentYear + index).toString();
    return {
      label: year,
      value: year.substr(year.length - 2),
    };
  });
};

export const getCreditCardExpiryValues = () => ({
  months: getExpiryMonths(),
  years: getExpiryYears(),
});

export const decodeCountryCodeFromCurrency = (currency) => CURRENCY_COUNTRY[currency] || null;

// eslint-disable-next-line no-confusing-arrow
export const findCountry = (countries, countryCode) =>
  countryCode
    ? countries.find((country) => {
        const { threeLetterCode, twoLetterCode } = country;

        let codeToMatch = null;
        switch (countryCode.length) {
          case 2:
            codeToMatch = twoLetterCode;
            break;
          case 3:
            codeToMatch = threeLetterCode;
            break;
          default:
            return null;
        }

        return [countryCode, FIND_COUNTRY_MAPPING[countryCode]].includes(codeToMatch);
      })
    : null;

export const getCountryStateProvs = (countryCode, { statesProvinces }) => {
  let states = [];
  let code = countryCode;
  const ukAbreviations = ['GB', 'UK', 'GBR'];
  if (ukAbreviations.includes(countryCode)) {
    code = COUNTRIES.UNITED_KINGDOM;
  }
  if (statesProvinces?.[code]) {
    states = statesProvinces[code];
  }

  if (ukAbreviations.includes(countryCode)) {
    return (
      (states || [])
        // eslint-disable-next-line no-confusing-arrow
        .sort((a, b) =>
          (!SORT_UK_COUNTRY || a.country === b.country ? a.name > b.name : a.country > b.country) ? 1 : -1
        )
        .map((county) => (county.country ? { ...county, name: `${county.name} (${county.country})` } : county))
    );
  }

  return states;
};

export const getCountryLongDateFormat = (countryCode) =>
  LONG_DATE_FORMAT[countryCode.toUpperCase()] || LONG_DATE_FORMAT.US;

export const getFormFieldValue = (fields, htmlName) => fields.find((f) => f.htmlName === htmlName);

export const getFormFields = (htmlNames, formSection = {}) => {
  const { fields = [], title } = formSection;
  const fieldValues = {};

  Object.entries(htmlNames).forEach(([key, fieldName]) => {
    const { label } = getFormFieldValue(fields, fieldName) || {};
    fieldValues[key] = label;
  });
  return {
    title,
    fields: fieldValues,
  };
};

export const mapStatesToOptions = (states) =>
  states.map((s) => ({
    label: s.name,
    value: s.abbreviation,
  }));

export const mapRadioItems = (items) =>
  items.map(({ bedTitle, reference }) => ({
    value: reference,
    label: bedTitle,
    id: reference,
  }));

export const isNumeric = (n) => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

const compareFloats = (left, right, epsilon = 0.0000001) => Math.abs(right - left) < epsilon;
export const isFloatZero = (inputFloat) => compareFloats(inputFloat, 0);
export const checkFloatValue = (inputFloat) => (isFloatZero(inputFloat) ? 0 : inputFloat);

export const getBrowserData = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent);
  return bowser.parse().parsedResult;
};

export const getPaymentChallengeIframeSize = () => {
  const width = window.innerWidth;
  let iframeSize = PAYMENT_CHALLENGE_IFRAME_SIZES.LG;

  if (width < 601) {
    iframeSize = PAYMENT_CHALLENGE_IFRAME_SIZES.MD;
    if (width < 500) {
      if (width < 390) {
        iframeSize = PAYMENT_CHALLENGE_IFRAME_SIZES.XS;
      } else {
        iframeSize = PAYMENT_CHALLENGE_IFRAME_SIZES.SM;
      }
    }
  }

  return { ...iframeSize };
};

export const getPaymentBrowserDetails = ({ postChallengeRedirectURL }) => {
  const { challengeWindowSize } = getPaymentChallengeIframeSize();
  const { colorDepth, width, height } = window?.screen;
  const { language, userAgent } = window?.navigator;

  return {
    acceptHeader: 'text/html,application/xhtml+xml',
    colorDepth,
    javaEnabled: true,
    javaScriptEnabled: true,
    language,
    postChallengeRedirectURL,
    screenHeight: height,
    screenWidth: width,
    timeZone: new Date().getTimezoneOffset() / 60,
    userAgent,
    merchantUrl: CHALLENGE_MERCHANT_URL,
    challengeWindowSize,
  };
};

export const getDeviceType = () => getBrowserData().platform.type;

/**
 * convert a raw phone number into an anchor with tel protocol on specific devices
 * @param {string} phoneNumber the starting, unlinked, phone number
 * @param {array} [deviceTypes=[MOBILE, TABLET]] the devices that should see the linked phone number
 */
export const getPhoneLinkByDeviceType = (phoneNumber, deviceTypes = [MOBILE]) => {
  if (!phoneNumber) {
    return '';
  }
  if (deviceTypes.includes(getDeviceType())) {
    return `<a href="tel:${phoneNumber}" class="text-nowrap">${phoneNumber}</a>`;
  }
  return `<span class="text-nowrap">${phoneNumber}</span>`;
};

/**
 * take a string with replacement tokens and swap out the token with a final/calculated value
 * Example starting string: 'Reservation secured with ${COUNT} guest(s).'
 * @param {string} text the original string with token, ie ${COUNT}
 * @param {array}  [variables=[]] the array of replacement values [{ key: 'COUNT', value: '3' }]
 * @param {string} [startBoundary='\\${'] what syntax defines the start of a variable in the string
 * @param {string} [endBoundary='}'] what syntax defines the ending of a variable in the string
 */
export const replaceCMSTokenWithValue = (text, variables = [], startBoundary = '\\${', endBoundary = '}') => {
  if (!text || typeof text !== 'string') {
    return text;
  }
  let message = text;
  variables.forEach(({ key, value }) => {
    let finalValue;
    const regex = new RegExp(`${startBoundary}${key}${endBoundary}`, 'gi');

    // override the handling of some known special cases
    switch (key) {
      case PHONE:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      case PHONE1:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      case PHONE2:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      case AIRPHONE:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      case AIRPHONE1:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      case AIRPHONE2:
        finalValue = getPhoneLinkByDeviceType(value);
        break;
      default:
        finalValue = typeof value === 'string' || typeof value === 'number' ? value : '';
        break;
    }
    message = message.replace(regex, () => finalValue);
  });
  return message;
};

export const replaceToken = (source, key, value) => replaceCMSTokenWithValue(source, [{ key, value }]);

export const sortAlphaByField = (sourceArray, key) => {
  sourceArray.sort((a, b) => a[key].localeCompare(b[key]));
};

export const sortByField = (sourceArray, key) => {
  sourceArray.sort((a, b) => a[key] - b[key]);
};

export const matchItineraryItem = (itinerary = [], key = '') => itinerary.find((item) => item.date === key);

export const mapModalParameters = (allParams, modalType = '') => {
  const {
    title,
    subtitle,
    subTitle,
    availability,
    disclaimers = [],
    sections = [],
    infoIcons = [],
    timeDetails = {},
    detailedDate,
    labels = {},
    itineraryData = {},
    effortLevel = {},
    subText,
    overland,
    overlandExcursion = {},
  } = allParams;
  const defaultParams = {
    title,
    subtitle: subtitle || subTitle, // CMSv2
    availability,
    disclaimers,
    sections,
    infoIcons,
  };
  switch (modalType) {
    case 'shorex':
      return {
        ...defaultParams,
        timeDetails,
        detailedDate,
        labels,
        itineraryData,
        effortLevel,
        subText,
        overland,
        overlandExcursion,
      };
    default:
      return {
        ...defaultParams,
        subtitle: subtitle || subTitle, // CMSv2
      };
  }
};

export const mapShorexIcons = (attributes) =>
  attributes.map(({ displayName }) => {
    const common = { label: displayName };
    switch (displayName.toUpperCase()) {
      case 'EASY':
        return {
          name: 'easy',
          ...common,
        };
      case 'MODERATE':
        return {
          name: 'moderate',
          ...common,
        };
      case 'DEMANDING':
        return {
          name: 'difficult',
          ...common,
        };
      case 'EVENING':
      case 'MORNING':
      case 'MORNINGAFTERNOON':
      case 'AFTERNOON':
        return {
          name: 'clock',
          ...common,
        };
      case 'LOCAL LIFE':
        return {
          name: 'local-life',
          ...common,
        };
      case 'SHOPPING':
        return {
          name: 'shopping',
          ...common,
        };
      case 'SPECIAL INTEREST':
        return {
          name: 'special-interest',
          ...common,
        };
      case 'SIGHTSEEING':
        return {
          name: 'sightseeing',
          ...common,
        };
      case 'UNESCO':
        return {
          name: 'unesco',
          ...common,
        };
      case 'RECOMMENDED':
        return {
          name: 'guest-favourite',
          ...common,
        };
      case 'WORKING WORLD':
        return {
          name: 'working-world',
          ...common,
        };
      case 'NATURAL WORLD':
        return {
          name: 'natural-world',
          ...common,
        };
      case 'ACTIVE':
        return {
          name: 'active',
          ...common,
        };
      case 'SNACK':
        return {
          name: 'snack-included',
          ...common,
        };
      case 'MEAL':
        return {
          name: 'meal-included',
          ...common,
        };
      case 'PRIVILEGED ACCESS':
        return {
          name: 'privileged-access',
          ...common,
        };
      case 'CULINARY':
        return {
          name: 'culinary',
          ...common,
        };
      case 'CULTURE':
        return {
          name: 'culture',
          ...common,
        };
      case 'AFTER DARK':
        return {
          name: 'after-dark',
          ...common,
        };
      case 'BEACH STAY':
        return {
          name: 'beach-stay',
          ...common,
        };
      case 'WHEELCHAIR':
        return {
          name: 'wheelchair',
          ...common,
        };
      default:
        return null;
    }
  });

export const getReservationStatuses = (passengerStatuses = []) => {
  if (!passengerStatuses.length) {
    return [];
  }

  return Object.keys(RESERVATION_STATUS).reduce((acc, key) => {
    const status = RESERVATION_STATUS[key];
    const statusCheck = (s) => s === status;

    if (passengerStatuses.some(statusCheck)) {
      acc.push(`SOME_${key}`);
    }
    if (passengerStatuses.every(statusCheck)) {
      acc.push(`EVERY_${key}`);
    }

    return acc;
  }, []);
};

export const getSimpleReservationModalState = (reservationStatus, defaultSubText) => {
  const { EVERY_IN_CART, EVERY_OPEN, EVERY_RESERVED } = PASSENGERS_RESERVATION_STATUS;
  const { IN_CART, OPEN, RESERVED } = RESERVATION_STATE_KEYS;

  let state;
  let subText = defaultSubText;

  const statuses = getReservationStatuses(reservationStatus);
  if (statuses.includes(EVERY_OPEN)) {
    state = OPEN;
  } else if (statuses.includes(EVERY_IN_CART)) {
    state = IN_CART;
  } else if (statuses.includes(EVERY_RESERVED)) {
    subText = null;
    state = RESERVED;
  }

  return {
    state,
    subText,
  };
};

export const getCmsLabel = (cmsLabels, reference, textKey = 'title', referenceKey = 'reference') => {
  if (!cmsLabels || !reference) {
    return null;
  }
  let matchingLabel = cmsLabels.find((label) => (label[referenceKey] || '') === reference);
  if (!matchingLabel) {
    matchingLabel = cmsLabels.find((label) => (label[referenceKey] || '').match(reference));
  }
  return get(matchingLabel, textKey, '');
};

export const getCmsLabelLink = (cmsLabels, reference, referenceKey = 'reference') => {
  if (!cmsLabels || !reference) {
    return null;
  }
  let matchingLabel = cmsLabels?.find((label) => (label[referenceKey] || '') === reference);
  if (!matchingLabel) {
    matchingLabel = cmsLabels?.find((label) => (label[referenceKey] || '')?.match(reference));
  }
  if (matchingLabel?.withLink) {
    return {
      ...matchingLabel?.withLink,
      callToActionUrl: matchingLabel.callToActionUrl,
    };
  }
  return null;
};

export const sessionStorageService = (action, key, value) => {
  try {
    switch (action) {
      case 'setItem':
        sessionStorage[action](key, value);
        break;
      case 'getItem':
        sessionStorage[action](key, value);
        break;
      default:
        sessionStorage[action](key);
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('Cannot load window.sessionStorage');
  }
};

export const getTabReference = (url) => url.replace(/[/-]/g, '');

export const getQueryParams = (search) => {
  if (!search) {
    return [];
  }
  const searchString = search[0] === '=' ? search.substring(1) : search;
  return searchString
    .replace(/[?]/g, '')
    .split('&')
    .reduce((acc, str) => {
      const parts = str.split('=');
      const [key, value] = parts;
      acc[key] = value;
      return acc;
    }, {});
};
export const isCreditCardExpired = (expirationDate) => moment(expirationDate, 'MMYY').isBefore(moment(), 'month');

export const getPageTabUrl = (page, tab) => {
  let url = get(TAB_PATHS, `${page}.url`, '');
  if (tab) {
    url = get(TAB_PATHS, `${page}.${tab}`, '');
  }
  return url;
};

export const getEvoErrorMessage = (data, errors) => {
  const error = {};
  let advisoryCode = null;
  if (data?.advisoryCode) {
    advisoryCode = data.advisoryCode;
  } else {
    const errorDescription = typeof data === 'object' ? data?.errorDescription : data;
    if (errorDescription) {
      const hasAdvisoryCodeMatch = errorDescription.match(/\d{4}/);
      if (hasAdvisoryCodeMatch) {
        debugLog(
          'With update to Advisory Code per MR-8625, Check MT to see what code is not returned to FE as own property'
        );
        advisoryCode = hasAdvisoryCodeMatch[0];
      }
    }
  }
  if (advisoryCode) {
    error.errorCode = advisoryCode;
    if (['1667', '1668', '1669'].includes(advisoryCode)) {
      error.errorDescription = replaceCMSTokenWithValue(errors[advisoryCode], [
        { key: PHONE, value: UK_SUPPORT_PHONE },
      ]);
    } else if (['CAL_TBD'].includes(advisoryCode)) {
      const invitees = data.errorDescription?.match(/\[[0-9,\s]+\]/)?.[0]?.match(/[0-9]{7,8}/gi);
      if (invitees?.length) {
        error.errorCode = 'NoCalendarInvitees';
        error.errorDescription = (errors[advisoryCode] || 'Unable to invite guests from booking.').replace(
          /\[BOOKING_NUMBER\]/g,
          invitees.join(', ')
        );
        error.invitees = invitees;
      }
    } else {
      error.errorDescription = errors[advisoryCode];
    }
  } else {
    error.errorCode = data.errorCode;
    error.errorDescription = data.errorDescription;
  }

  return error;
};

export const getCoreErrorMessage = (data, errors) => {
  if ((errors || []).length > 0) {
    const coreErrors = errors.find((e) => e.coreStatus === data.errorCode && e.coreMessage === data.errorDescription);
    if (coreErrors?.coreFriendlyMessage) {
      return coreErrors.coreFriendlyMessage;
    }
  }
  return null;
};

export const getEvoErrorCode = (data) => {
  let code = null;
  if (data?.advisoryCode) {
    return data.advisoryCode;
  }
  if (data && data.errorDescription) {
    const hasAdvisoryCodeMatch = data.errorDescription.match(/\d{4}/);
    if (hasAdvisoryCodeMatch) {
      const [advisoryCode] = hasAdvisoryCodeMatch;
      code = advisoryCode;
    }
  }
  return code;
};

export const isExpedition = (voyageId) => voyageId && voyageId[0] === 'X';

export const scrollToElementIfNeeded = (element, options) => {
  scrollIntoView(
    element,
    options || {
      scrollMode: 'if-needed',
      block: 'nearest',
    }
  );
};

export const getDiff = (a, b) => Math.abs(a - b);

export const roundUp = (val, multiplier = 1) => {
  return Math.ceil(val / multiplier) * multiplier;
};

export const roundDown = (val, multiplier = 1) => {
  return Math.floor(val / multiplier) * multiplier;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const setScrollFlightDetails = (id, offset = 0) => {
  const offsetTop = document.getElementById(id).offsetTop - offset;
  document.getElementById(id).setAttribute('style', 'height: auto;');
  window.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  });
};

export const setFocus = (id) => {
  const focusId = document.getElementById(id);
  if (focusId) {
    focusId.focus();
  }
};

export const isolateCorrectAirSegments = (segments, direction) => {
  const directionIndex = segments.findIndex((seg) => seg.text === direction);
  const stopoverIndex = segments.findIndex((seg) => seg.text === `${direction} Stopover`);

  if (direction === 'Return') {
    return {
      return: directionIndex === 0 ? segments : segments.slice(directionIndex),
      stopover: directionIndex === 0 ? [] : segments.slice(0, directionIndex),
    };
  }

  return {
    outbound: stopoverIndex > 0 ? segments.slice(0, stopoverIndex) : segments,
    stopover: stopoverIndex > 0 ? segments.slice(stopoverIndex) : [],
  };
};

export const printElement = ({ selector, title = '' }) => {
  const html = document.querySelector(selector).outerHTML;
  const printWindow = window.open('', title);
  const titleElement = document.createElement('div');
  titleElement.innerHTML = title;
  const formattedTitle = titleElement.textContent || titleElement.innerText;
  const printHtml = `<html>
    <head>
      <link rel="stylesheet" href="/myjourney/css/bootstrap.min.css" media="all" />
      <link rel="stylesheet" href="/myjourney/css/print.css" media="all" />
      <title>${formattedTitle}</title>
    </head>
    <body>
      <div class="container">
        ${html}
      </div>
      <script type="text/javascript" src="/myjourney/js/print.js"></script>
    </body>
  </html>`;
  printWindow.document.write(printHtml);
};

export const findCardSectionByModalId = (tabContent, modalId, shipId = '', ...elements) => {
  if (!tabContent) {
    return {};
  }
  const sections = shipId ? tabContent.filter((section) => section.shipId === shipId) : tabContent || tabContent;
  const data = {};

  sections.forEach((section) => {
    if (!data.card) {
      const card = section.cards?.find((element) => element.id === modalId);
      if (card) {
        data.card = card;
        elements?.forEach((elementName) => {
          data[elementName] = section[elementName];
        });
      }
    }
  });

  return data;
};

export const ariaFormatter = (string) => string.replace(/[&]/g, 'and').replace(/[^a-zA-Z- ]/g, ' ');

export const isTabPress = (e) => e && e.key === KEYCODES.TAB && !e.shiftKey;
export const classListContains = (classList, classes) => classes.some((cur) => classList.contains(cur));

export const getMarketingSiteForCountry = (country) =>
  MARKETING_SITE_URLS[country] || MARKETING_SITE_URLS[COUNTRY_TO_OFFICE[country]] || MARKETING_SITE_URLS.US;
export const isFalse = (val) => [false, 'false', 0, 'N'].includes(val);
export const isTrue = (val) => [true, 'true', 1, 'Y'].includes(val);

export const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const getPageHasBeenRefreshed = () => window?.performance?.navigation?.type === NAVIGATION_TYPES.RELOAD;

export const getAriaLabel = (replaceCMSTokenFunc, flightInfoAriaLabels, priceObj) => {
  let ariaLabel = '';
  const {
    currentFlightAria,
    currentFlightIsSelected,
    flightSelectedWithAddlCost,
    flightSelectedWithNoAddlCost,
    flightSelectedWithRefund,
    flightWithAddlCostAria,
    flightWithNoAddlCostAria,
    flightWithRefundAria,
  } = flightInfoAriaLabels;

  const { addCollect, currency, price, selectedForYou, type } = priceObj;

  const isSelected = priceObj.input.checked;

  if (selectedForYou !== '') {
    ariaLabel = isSelected ? currentFlightIsSelected : currentFlightAria;
  } else if (addCollect > 0) {
    ariaLabel = isSelected ? flightSelectedWithAddlCost : flightWithAddlCostAria;
  } else if (addCollect < 0) {
    ariaLabel = isSelected ? flightSelectedWithRefund : flightWithRefundAria;
  } else {
    ariaLabel = isSelected ? flightSelectedWithNoAddlCost : flightWithNoAddlCostAria;
  }
  return replaceCMSTokenFunc ? replaceCMSTokenFunc(ariaLabel, type, price, currency) : ariaLabel;
};

export const getChatContainerElements = () => [...document.getElementsByClassName('LPMcontainer')];

export const debugLog = (...logParams) => {
  if (window.location.hostname === 'localhost') {
    // eslint-disable-next-line no-console
    console.log(...logParams);
  }
};

export const getWasTimeout = (res = {}) => {
  const { status, data: { errorDescription } = {} } = res;
  if (status === 408) {
    return true;
  }
  if (status === 500) {
    return !!errorDescription?.match(/timeout/i);
  }
  return false;
};

export const base64EncodeString = (string) => {
  const encodedString = btoa(string);
  return encodedString.replace(/=+$/, '');
};

// Per 8625: New helper function for logging advisory codes to azure for FE encountered errors
export const logAdvisoryCode = ({ advisoryCode, url, message, logData = {} }) => {
  logger({
    type: APP_INSIGHTS_TRACK_TYPE.EVENT,
    name: 'Advisory Code Encountered',
    severity: SeverityLevel.Verbose,
    logData: {
      advisoryCode,
      url,
      message,
      ...logData,
    },
  });
};

export const reloadOTBanner = () => {
  if (window.OneTrust !== null) {
    setTimeout(() => {
      window.OneTrust?.initializeCookiePolicyHtml();

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = (event) => {
          if (event.target.classList.contains('ot-sdk-show-settings')) {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          }
        };
      }
    }, 1000);
  }
};
