import Footer from '@viking-eng/footer';
import React from 'react';
import { FOOTER_MODALS } from '../../common/Constants';
import { reloadOTBanner, replaceCMSTokenWithValue } from '../../common/Utils';
import { ContactModal, FooterModal } from '../../modals';
import PropTypes from '../../PropTypes';
import './MvjFooter.scss';

const MvjFooter = ({
  connect,
  connectHeader,
  copyrightText,
  items,
  itemsTitle,
  handleModalOpen,
  localNumber,
  callViking,
}) => {
  const d = new Date();
  const year = d.getFullYear();
  const copyrightTextDynamic = replaceCMSTokenWithValue(copyrightText, [
    {
      key: 'YEAR',
      value: year,
    },
  ]);
  items.forEach((item) => {
    if (item.modalId) {
      item.onClick = () => handleModalOpen(item.modalId);
    } else if (item.contentUrl) {
      item.onClick = () => window.open(item.contentUrl);
    }
  });
  reloadOTBanner();

  return (
    <>
      <div className="footer-container">
        {callViking && localNumber ? (
          <div className="call-viking">
            <p>
              {`${callViking} `}
              <a href={`tel:${localNumber}`}>{localNumber}</a>
            </p>
          </div>
        ) : null}
        <Footer
          leftMenuItems={items}
          rightMenuItems={connect}
          copyrightText={copyrightTextDynamic}
          leftMenuHeader={itemsTitle}
          rightMenuHeader={connectHeader}
          handleModalOpen={handleModalOpen}
        />
      </div>
      <FooterModal type={FOOTER_MODALS.TERMS} />
      <FooterModal type={FOOTER_MODALS.PRIVACY} />
      <ContactModal />
    </>
  );
};

MvjFooter.propTypes = {
  items: PropTypes.footerLinks,
  connect: PropTypes.footerLinks,
  copyrightText: PropTypes.string,
  itemsTitle: PropTypes.string,
  connectHeader: PropTypes.string,
  handleModalOpen: PropTypes.func.isRequired,
};

MvjFooter.defaultProps = {
  items: [],
  connectHeader: '',
  connect: null,
  copyrightText: '',
  itemsTitle: '',
};

export default MvjFooter;
