// eslint-disable-next-line no-unused-vars
import { useEffect, useRef, useState } from 'react';
import { CHAT_BUTTON_POLL_TIME } from '../../common/Constants';
import { getChatContainerElements } from '../../common/Utils';
import PropTypes from '../../PropTypes';

const ChatAvailableWrapper = ({ chatAvailableContent, chatUnavailableContent }) => {
  const intervalHandle = useRef(null);
  const [isAvailable, setAvailable] = useState((getChatContainerElements() || []).length > 0);
  useEffect(() => {
    intervalHandle.current = setInterval(() => {
      setAvailable((getChatContainerElements() || []).length > 0);
    }, CHAT_BUTTON_POLL_TIME);

    return () => {
      clearTimeout(intervalHandle.current);
    };
  }, []);

  return <>{isAvailable ? <>{chatAvailableContent}</> : <>{chatUnavailableContent}</>}</>;
};

ChatAvailableWrapper.propTypes = {
  chatAvailableContent: PropTypes.node.isRequired,
  chatUnavailableContent: PropTypes.node.isRequired,
};

ChatAvailableWrapper.displayName = 'ChatAvailableWrapper';

export default ChatAvailableWrapper;
