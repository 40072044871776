import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore, { fetchVoyageNotAvailableData } from '../../common/CommonStore';
import { VIKING_LOGO_COLOR } from '../../common/Constants';

import VoyageNotAvailPage from './VoyageNotAvailPage';

const logo = [
  {
    attributes: {
      alt: 'Logo',
      src: VIKING_LOGO_COLOR,
    },
  },
];

const {
  creators: { setIsErrorPage },
  selectors: { isHeaderContentAvailable, getVoyageNotAvailablePage },
} = commonStore;

const mapStateToProps = (state) => ({
  isHeaderContentAvailable: isHeaderContentAvailable(state),
  logo,
  content: getVoyageNotAvailablePage(state),
});

const mapDispatchToProps = (dispatch) => {
  dispatch(setIsErrorPage(true));
  dispatch(fetchVoyageNotAvailableData());
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(VoyageNotAvailPage);
