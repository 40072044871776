import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore from '../../../common';
import { getPassengerAbbrevName } from '../../../common/Utils';
import userStore from '../../../common/UserStore';
import shorexStore from '../../../pages/shorex/ShorexStore';
import { closeModalAndNavigate } from '../../../common/ModalStore';

import SideContent from './SideContent';

const {
  creators: { updateReservationModalState },
  selectors: { getReservationModalInfo },
} = commonStore;
const {
  selectors: { getUpdatingModalStateFlag, getShorexContent, getIsOnboardOnly },
} = shorexStore;
const { getPassengers } = userStore.selectors;

const mapStateToProps = (state) => {
  const passengers = getPassengers(state);
  const passengerData = passengers.map(({ passengerNumber, ...passenger }) => ({
    passengerNumber,
    passengerName: getPassengerAbbrevName(passenger),
  }));

  return {
    passengers: passengerData,
    modalReservationInfo: getReservationModalInfo(state),
    isModalStateUpdating: getUpdatingModalStateFlag(),
    lockedDownMessage: getShorexContent(state),
    ...getIsOnboardOnly(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateReservationModalState: (...args) => dispatch(updateReservationModalState(...args)),
  handleCloseModalAndNav: (url) => dispatch(closeModalAndNavigate(url)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(SideContent);
