import React from 'react';

import PropTypes from '../../../PropTypes';
import { Field } from '../..';

const OnBlurField = ({ handleOnBlur, ...props }) => <Field onBlur={() => handleOnBlur()} {...props} />;

OnBlurField.propTypes = {
  handleOnBlur: PropTypes.func.isRequired,
};

export default OnBlurField;
