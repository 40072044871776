import get from 'lodash/get';
import { connect } from 'react-redux';
import commonStore from '../../common/CommonStore';
import { AIR_LEAVE_MODAL, MODALS, PAGE_NAMES } from '../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import { navigateTo } from '../../pages/newAir/AirStore';
import AirWindowClose from './AirWindowClose';

const { getLabels } = commonStore.selectors;
const {
  creators: { clearModal },
  selectors: { getModalView },
} = modalStore;

const mapStateToProps = (state) => {
  const labels = get(getLabels(state), `pages.${PAGE_NAMES.NEW_AIR}.labels`, {});
  const message = get(labels, 'areYouSureLeavePage', AIR_LEAVE_MODAL.message);

  const airLeavePageModalContent = {
    forceAction: true,
    id: MODALS.AIR_LEAVE_PAGE,
    message,
    confirmText: get(labels, 'buttons.leaveYes', AIR_LEAVE_MODAL.confirm),
    cancelText: get(labels, 'buttons.leaveCancel', AIR_LEAVE_MODAL.cancel),
  };

  const modalView = getModalView(state);

  return {
    airLeavePageModalContent,
    message,
    modalView,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  onConfirmClick: (pathname) => {
    dispatch(navigateTo(pathname));
  },
  handleShowModal: (modalId) => {
    dispatch(setViewAndShowModal(modalId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AirWindowClose);
