const remapCardDataBasedOnModalType = (
  { primaryButtonUrl, ...card },
  { onPrimaryButtonClick, onSecondaryButtonClick, defaultClick }
) => {
  const buttonAttributes = {
    'data-toggle': 'modal',
    'data-dismiss': 'modal',
    'data-target': primaryButtonUrl,
  };
  const remappedCard = { ...card };
  const addPrimaryButtonClick = () => {
    if (onPrimaryButtonClick) {
      remappedCard.primaryButton = {
        ...card.primaryButton,
        attributes: buttonAttributes,
        onButtonClick: onPrimaryButtonClick,
      };
    }
  };
  const addSecondaryButtonClick = () => {
    if (onSecondaryButtonClick) {
      remappedCard.secondaryButton = {
        ...card.secondaryButton,
        attributes: buttonAttributes,
        onButtonClick: onSecondaryButtonClick,
      };
    }
  };
  const addDefaultClick = () => {
    if (defaultClick) {
      remappedCard.defaultClick = {
        ...card.defaultClick,
        onClick: defaultClick,
        ...buttonAttributes,
      };
    }
  };

  switch (primaryButtonUrl) {
    // TODO: [MR-3093] Update primaryButtonUrls or code so that the data-target is equal
    case '#CardDetailsModal':
      buttonAttributes['data-target'] = '#cardDetailsModal';
      addPrimaryButtonClick();
      addDefaultClick();
      break;
    case '#AddToCartModal':
      buttonAttributes['data-target'] = '#detailsModal';
      addPrimaryButtonClick();
      addSecondaryButtonClick();
      addDefaultClick();
      break;
    case '#ComparisonModal':
      buttonAttributes['data-target'] = '#comparisonModal';
      addPrimaryButtonClick();
      addSecondaryButtonClick();
      addDefaultClick();
      break;
    case '#ReserveModal':
      buttonAttributes['data-target'] = '#reserveModal';
      addPrimaryButtonClick();
      addSecondaryButtonClick();
      break;
    case '#MobileAppModal':
      buttonAttributes['data-target'] = '#mobileAppModal';
      addPrimaryButtonClick();
      addSecondaryButtonClick();
      break;
    default:
      addPrimaryButtonClick();
      addSecondaryButtonClick();
      addDefaultClick();
      break;
  }

  return remappedCard;
};

export default remapCardDataBasedOnModalType;
