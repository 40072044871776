import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { history } from '../../../common';
import commonStore from '../../../common/CommonStore';
import {
  APP_PATHS,
  DAYS_BEFORE_AIR_AVAILABLE_RR,
  DAYS_BEFORE_AIR_AVAILABLE_VK,
  RESERVATION_NUMBERS,
} from '../../../common/Constants';
import userStore from '../../../common/UserStore';
import { getCountryLongDateFormat, replaceCMSTokenWithValue } from '../../../common/Utils';
import airStore from '../AirStore';
import AirNotAvailable from './AirNotAvailable';

const { getDaysToGo, getCountryCodeFromCurrency, getBookingDetails, getIsTaAccount } = userStore.selectors;

const {
  selectors: { getAirCms },
} = airStore;

const { getIsUK } = commonStore.selectors;

const mapStateToProps = (state) => {
  const cms = getAirCms(state);
  const daysToGo = getDaysToGo(state);
  const abeAirAvailableDateCMS = cms.abeAirAvailableDate;
  const airTravelServicesCMS = cms.abeLearnMoreAboutABE;
  const airAvailableDateRaw = get(abeAirAvailableDateCMS, 'subtitle', '');
  const abeAirNotAvailableCMS = cms.abeAirNotAvailable;
  const airNotAvailable = get(abeAirNotAvailableCMS, 'subtitle', '');
  const airTravelServices = get(airTravelServicesCMS, 'subtitle', '');
  const reservationPhoneNumber = get(cms.AIRPHONE, 'longText', RESERVATION_NUMBERS.US);
  const airNoVikingAirCopy = get(cms.abeNoVikingAirTxt, 'subtitle', '');
  const airNoVikingAirHeader = replaceCMSTokenWithValue(get(cms.abeNoVikingAirTxt, 'title', ''), [
    { key: 'AIRPHONE', value: reservationPhoneNumber },
  ]);
  const waitingDays = daysToGo - DAYS_BEFORE_AIR_AVAILABLE_VK;
  const countryCode = getCountryCodeFromCurrency(state) || 'US';
  const dateFormat = getCountryLongDateFormat(countryCode) || 'MMMM D, YYYY';
  const comeBackDate = moment().add(waitingDays, 'd').format(dateFormat);
  const airAvailableDate = replaceCMSTokenWithValue(airAvailableDateRaw, [{ key: 'DATE', value: comeBackDate }]);
  const waitingDaysRightRez = daysToGo - DAYS_BEFORE_AIR_AVAILABLE_RR;
  const comeBackDateRightRez = moment().add(waitingDaysRightRez, 'd').format(dateFormat);
  const airAvailableDateRightRez = replaceCMSTokenWithValue(airAvailableDateRaw, [
    { key: 'DATE', value: comeBackDateRightRez },
  ]);

  // eslint-disable-next-line max-len
  const airAvailableDateMessage = daysToGo <= DAYS_BEFORE_AIR_AVAILABLE_VK ? airNotAvailable : airAvailableDate;
  // eslint-disable-next-line max-len
  const airAvailableDateMessageRightRez =
    daysToGo <= DAYS_BEFORE_AIR_AVAILABLE_RR ? airNotAvailable : airAvailableDateRightRez;
  const thankYouMessageCMS = cms.abeThankYouVikingAir;
  const thankYouMessage = get(thankYouMessageCMS, 'subtitle', '');
  const airAvailableActionsMessageCMS = cms.abeGuestOptions;
  const airAvailableActionsMessage = get(airAvailableActionsMessageCMS, 'longText', '');
  const readOnlyThankYouMessageCMS = cms.abeFlightsInProgress;
  const readOnlyThankYouMessage = get(readOnlyThankYouMessageCMS, 'subtitle', '');

  const abeGIFRequiredCMS = cms.abeGIFRequired;
  const incompleteGIFText = get(abeGIFRequiredCMS, 'title', '');
  const incompleteGIFLongText = get(abeGIFRequiredCMS, 'longText', '');
  const incompleteGIFBtn = {
    text: get(abeGIFRequiredCMS, 'callToActionTitle', 'Guest Information Form'),
    onButtonClick: () => history.push(APP_PATHS.GIF),
  };

  const isDirectBooking = get(getBookingDetails(state), 'directBooking', '');
  const isTALoggedIn = getIsTaAccount(state);
  const isUkBooking = getIsUK(state);
  const noTAMessaging = isDirectBooking || isTALoggedIn;

  const paymentPhoneNumber = get(cms.PHONE, 'longText');
  const noAirNoBookingPaymentMessageDirect = replaceCMSTokenWithValue(
    get(cms.mvaNoBookingPaymentCruiseOnly, 'title', ''),
    [{ key: 'AIRPHONE', value: paymentPhoneNumber }]
  );
  const noAirNoBookingPaymentMessageTA = get(cms.mvaNoBookingPaymentTACruiseOnly, 'title', '');
  const noAirNoBookingPaymentMessage = noTAMessaging
    ? noAirNoBookingPaymentMessageDirect
    : noAirNoBookingPaymentMessageTA;
  const noAirNoBookingPaymentBtn = noTAMessaging
    ? {
        text: get(cms.mvaNoBookingPaymentCruiseOnly, 'callToActionTitle', 'Make A Payment'),
        onButtonClick: () => history.push(APP_PATHS.CRUISE_PAYMENTS),
      }
    : {};

  return {
    airAvailableDateMessage,
    thankYouMessage,
    airAvailableActionsMessage,
    readOnlyThankYouMessage,
    airAvailableDateMessageRightRez,
    airTravelServices,
    airNoVikingAirCopy,
    airNoVikingAirHeader,
    incompleteGIFText,
    incompleteGIFLongText,
    incompleteGIFBtn,
    noAirNoBookingPaymentMessage,
    noAirNoBookingPaymentBtn,
    hidePaymentBanner: isUkBooking,
  };
};

export default connect(mapStateToProps)(AirNotAvailable);
