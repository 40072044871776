import ImageCarousel from '@viking-eng/image-carousel';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from '..';
import PropTypes from '../../PropTypes';
import './Picture.scss';

const PictureModal = ({ description, id, images, isImageFullSize, printLabel, title }) => (
  <Modal id={id} printLabel={printLabel} isImageFullSize={isImageFullSize} title={title} subTitle={description}>
    {title && (
      <ModalBody>
        <ModalHeader description={description} title={title} />
        <div className="content">
          <ImageCarousel id="picture-carousel" className="carousel" images={images} />
        </div>
      </ModalBody>
    )}
  </Modal>
);

PictureModal.propTypes = {
  ...PropTypes.modalData,
  isImageFullSize: PropTypes.bool,
};

PictureModal.defaultProps = {
  isImageFullSize: false,
};

export default PictureModal;
