import React from 'react';
import TabPage from '@viking-eng/tab-page';

import PropTypes from '../../../PropTypes';

const Enrichment = ({ enrichmentContent: { pageDescription, remappedSections } }) => (
  <div className="enrichment">
    <TabPage sections={remappedSections} headerText={pageDescription} />
  </div>
);

Enrichment.propTypes = {
  enrichmentContent: PropTypes.shape({
    pageDescription: PropTypes.string,
    remappedSections: PropTypes.shape({}),
  }),
};

Enrichment.defaultProps = {
  enrichmentContent: {
    pageDescription: '',
    remappedSections: {},
  },
};

export default Enrichment;
