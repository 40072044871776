import TabPage from '@viking-eng/tab-page';
import isInteger from 'lodash/isInteger';
import React from 'react';
import remapCardDataBasedOnModalType from '../../../common/ModalUtils';
import { CardDetailsModal, InformationModal, PictureModal } from '../../../modals';
import PropTypes from '../../../PropTypes';

const ShipAndCrew = ({
  content: { sections, pageDescription },
  modalData: { deckPlan, ship, additionalInfo },
  handleCardDetailsClicked,
  modalToOpen,
}) => {
  const mappedSections = sections.map((section) => ({
    ...section,
    cards: section.cards.map((card) => {
      const modalType = card.primaryButtonUrl.replace('#', '');
      card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle

      const onClick = () => {
        if (card.primaryButtonUrl.startsWith('http')) {
          return window.open(card.primaryButtonUrl);
        }
        return handleCardDetailsClicked(modalType, card.id);
      };
      return remapCardDataBasedOnModalType(card, {
        onPrimaryButtonClick: onClick,
        onSecondaryButtonClick: onClick,
        defaultClick: onClick,
      });
    }),
  }));

  if (modalToOpen && modalToOpen.modalType && isInteger(modalToOpen.modalId)) {
    handleCardDetailsClicked(modalToOpen.modalType, modalToOpen.modalId);
  }

  return (
    <div className="ship-crew">
      <TabPage sections={mappedSections} headerText={pageDescription} />
      {deckPlan && <PictureModal id="DeckPlansModal" headingOnNewLine {...deckPlan} />}
      {ship && <CardDetailsModal {...ship} id="ShipFeaturesModal" displayCardGroups />}
      {additionalInfo && <InformationModal {...additionalInfo} id="AdditionalInfoModal" />}
    </div>
  );
};

ShipAndCrew.propTypes = {
  content: PropTypes.shape({
    sections: PropTypes.cardSections,
    pageDescription: PropTypes.string,
  }),
  modalData: PropTypes.shape({
    deckPlan: PropTypes.shape(PropTypes.modalData),
  }),
  modalToOpen: PropTypes.shape({ modalType: PropTypes.string, id: PropTypes.string }),
  handleCardDetailsClicked: PropTypes.func.isRequired,
};

ShipAndCrew.defaultProps = {
  content: {
    sections: [],
    pageDescription: null,
  },
  modalData: {
    deckPlan: {},
  },
  modalToOpen: null,
};

export default ShipAndCrew;
