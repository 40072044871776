import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from '../../../PropTypes';
import { MODALS } from '../../../common/Constants';
import { prepareHtml } from '../../../common/Utils';
import { GuestInfoForm, GuestSelector, PageHeader } from '../../../components';
import { DialogModal } from '../../../modals';
import './GuestInfo.scss';

const dialogModalId = MODALS.DIALOG;

const GuestInfo = ({
  handleGuestChange,
  handleSubmit,
  passengerSelectorData,
  selectedGuest,
  dirty,
  pristine,
  anyTouched,
  valid,
  submitFailed,
  submitSucceeded,
  submitting,
  asyncValidating,
  dialogModalLabels,
  handleConfirm,
  updateNextGuest,
  handleCancel,
  nextLocation,
  handleBlockedNavigation,
  guestSelectorLabels,
  initialValues,
  subtitle,
  title,
  isLoading,
  backButton,
}) => (
  <div className="guest-information">
    <PageHeader hasLine title={title} backButton={backButton} isLoading={isLoading} />
    {!isLoading && (
      <>
        {subtitle && (
          <div className="guest-info-subtitle text-center" dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        )}
        {passengerSelectorData.length > 0 && (
          <>
            <GuestSelector
              guests={passengerSelectorData}
              selectedGuest={selectedGuest}
              handleGuestChange={handleGuestChange}
              dirty={dirty}
              updateNextGuest={updateNextGuest}
              labels={guestSelectorLabels}
            />
            <GuestInfoForm
              dirty={dirty}
              handleSubmit={handleSubmit}
              pristine={pristine}
              anyTouched={anyTouched}
              valid={valid}
              submitFailed={submitFailed}
              submitSucceeded={submitSucceeded}
              asyncValidating={asyncValidating}
              submitting={submitting}
              initialValues={initialValues}
            />
            <DialogModal
              id={dialogModalId}
              onCancel={() => handleCancel(selectedGuest)}
              onConfirm={() => {
                handleConfirm();
              }}
              {...dialogModalLabels}
            />
            <Prompt when={dirty && !nextLocation} message={handleBlockedNavigation} />
          </>
        )}
      </>
    )}
  </div>
);

GuestInfo.propTypes = {
  handleGuestChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  passengerSelectorData: PropTypes.arrayOf(
    PropTypes.shape({
      isCompleted: PropTypes.bool,
      name: PropTypes.string,
      passengerID: PropTypes.number,
    })
  ),
  selectedGuest: PropTypes.number,
  dirty: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  asyncValidating: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  dialogModalLabels: PropTypes.shape({
    buttons: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    title: PropTypes.string,
  }).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  updateNextGuest: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleBlockedNavigation: PropTypes.func.isRequired,
  nextLocation: PropTypes.string,
  guestSelectorLabels: PropTypes.guestSelectorLabels.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])).isRequired,
  subtitle: PropTypes.string,
};

GuestInfo.defaultProps = {
  passengerSelectorData: [],
  selectedGuest: 0,
  nextLocation: null,
  subtitle: '',
};

export default GuestInfo;
