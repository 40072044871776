import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import commonStore from '../../common';
import { getData } from '../../common/Api';
import { COUNTRIES, FAQ_CATEGORIES_LOGIN, PAGE_NAMES, TOTAL_SEARCH_RESULTS, VOYAGE_TYPE } from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import { buildUrl, decodeHtmlEntities } from '../../common/Utils';
import { sortFAQByOrder } from '../help/HelpStore';

const {
  selectors: { getPageTabLabels },
} = commonStore;

const loginFaqsStore = createPageTitleDuck('loginFaqs').extend({
  types: ['UPDATE_ACTIVE_CATEGORY', 'UPDATE_FAQ_SEARCH_VALUE'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.UPDATE_ACTIVE_CATEGORY: {
        return {
          ...state,
          activeCategoryIndex: action.categoryIndex,
          activeQuestionIndex: -1,
        };
      }
      case types.UPDATE_FAQ_SEARCH_VALUE: {
        return {
          ...state,
          searchValue: action.value,
        };
      }
      default: {
        return state;
      }
    }
  },
  creators: ({ types }) => ({
    updateActiveCategory: (index) => ({
      type: types.UPDATE_ACTIVE_CATEGORY,
      categoryIndex: index,
    }),
    handleUpdateFAQSearchValue: (value) => ({
      type: types.UPDATE_FAQ_SEARCH_VALUE,
      value,
    }),
  }),
  selectors: {
    getActiveCategoryIndex: (state) => get(state, 'loginFaqs.activeCategoryIndex', 0),
    getSectionsItems: (state) => get(state, 'loginFaqs.content.sections[0].items[0]', {}),
    getFooterText: (state) =>
      // eslint-disable-next-line no-template-curly-in-string
      get(state, 'common.content.footer.copyrightText', '&copy; Viking Cruises, ${YEAR}. All Rights Reserved.'),
    getCallToActionUrl: new Selector(({ getSectionsItems }) =>
      createSelector(getSectionsItems, (items) => items.callToActionUrl)
    ),
    getSearchValue: (state) => state?.loginFaqs?.searchValue,
    getSearchBarLabels: new Selector(() =>
      createSelector([(state) => getPageTabLabels(state)(PAGE_NAMES.LOGIN_FAQS)], ({ labels }) => {
        return {
          ariaLabel: labels.search,
          fieldLabel: labels.searchLabel,
        };
      })
    ),
    getFaqCategories: new Selector(({ getPageContent, getActiveCategoryIndex }) =>
      createSelector(
        [getPageContent, getActiveCategoryIndex, (state) => getPageTabLabels(state)(PAGE_NAMES.HELP)],

        (content, activeCategoryIndex, { labels }) => {
          const rawSections = get(content, 'sections', []);
          const faqCategoriesLoginArray = Object.keys(FAQ_CATEGORIES_LOGIN);
          const loginFaqsCatsUnordered = faqCategoriesLoginArray
            .map((item) => rawSections.filter((section) => section.title === FAQ_CATEGORIES_LOGIN[item].title))
            .flat();

          const loginFaqsCatsWithOrderNumber = loginFaqsCatsUnordered.map((item) => {
            const orderNumberSection = faqCategoriesLoginArray.filter(
              (cat) => FAQ_CATEGORIES_LOGIN[cat].title === item.title
            );
            const orderNumber = FAQ_CATEGORIES_LOGIN[orderNumberSection].order;
            return {
              ...item,
              order: orderNumber,
            };
          });

          const loginFaqsCats = loginFaqsCatsWithOrderNumber.sort((a, b) => {
            return a.order - b.order;
          });
          const categories = loginFaqsCats.map((section, sectionIndex) => {
            const questions = sortFAQByOrder(section.questions || [], true).map((item, ind) => ({
              question: item.title,
              answer: item.longText,
              id: ind,
            }));
            return {
              active: sectionIndex === activeCategoryIndex,
              name: section.title,
              questions,
            };
          });

          return {
            categories,
            heading: labels.tableOfContents,
          };
        }
      )
    ),
    getSearchResults: new Selector(({ getSearchValue, getFaqCategories }) =>
      createSelector([getFaqCategories, getSearchValue], ({ categories }, searchValue) => {
        if (!searchValue) {
          return null;
        }

        const totalQuestions = categories.reduce((acc, { questions }) => {
          const escapeRegex = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
          };
          const regex = new RegExp(escapeRegex(searchValue), 'i');
          const filteredQuestions = questions.filter(
            ({ answer, question }) =>
              decodeHtmlEntities(answer).match(regex) || decodeHtmlEntities(question).match(regex)
          );
          filteredQuestions.forEach((item) => {
            const alreadyExists = acc.find(({ question }) => question === item.question);
            if (!alreadyExists) {
              acc.push({
                ...item,
                id: acc.length,
              });
            }
          });
          return acc;
        }, []);
        const resultsText = totalQuestions.length === 1 ? TOTAL_SEARCH_RESULTS.SINGLE : TOTAL_SEARCH_RESULTS.MULTIPLE;
        return {
          name: `${totalQuestions.length} ${resultsText}`,
          questions: totalQuestions,
        };
      })
    ),
  },
});

export const fetchHelpPageContent = () => (dispatch) => {
  const { receiveContent } = loginFaqsStore.creators;

  const url = buildUrl('/pages/faq/river', [], {
    country: COUNTRIES.UNITED_STATES,
    voyageType: VOYAGE_TYPE.RIVER,
  });
  dispatch(
    getData({
      url,
      store: loginFaqsStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export default loginFaqsStore;
