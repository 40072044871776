import { Selector } from 'extensible-duck';
import { createSelector } from 'reselect';
import commonStore, { modalStore, userStore } from '../../common';
import { getData, postData } from '../../common/Api';
import { PAGE_NAMES, TAB_NAMES, USER_TYPES, VOYAGE_STATUSES } from '../../common/Constants';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import { buildUrl, getCmsLabel, replaceCMSTokenWithValue } from '../../common/Utils';

const {
  selectors: { getPageTabLabels, getLabels, getSubmitting, getTabUrl },
} = commonStore;

const {
  getBookingDetails,
  getUpdateUserData,
  getUserEmailViaCSA,
  getVoyageSailingStatus,
  getEmail,
  getIsDirect,
  getIsTaAccount,
} = userStore.selectors;

const {
  selectors: { getModalData },
} = modalStore;

const { PROFILE, REQUEST_INVOICE } = TAB_NAMES;
const accountStore = createPageTabsDuck('account').extend({
  selectors: {
    getUserLabels: new Selector(() =>
      createSelector(
        [(state) => getPageTabLabels(state)(PAGE_NAMES.ACCOUNT)],
        ({
          buttons: { changePassword },
          labels: {
            email,
            password,
            newPassword,
            confirmNewPassword,
            passwordRequirement,
            incorrectCurrentPassword,
            successfulUpdate,
            passwordRequirementFailed,
          },
        }) => ({
          changePassword,
          email,
          password,
          newPassword,
          confirmNewPassword,
          passwordRequirement,
          incorrectCurrentPassword,
          successfulUpdate,
          passwordRequirementFailed,
        })
      )
    ),

    getRelatedEmailAddress: new Selector(() =>
      createSelector(
        [getUpdateUserData, getUserEmailViaCSA, getEmail],
        // eslint-disable-next-line max-len
        ({ updateUserType }, bookingEmail, email) => (updateUserType === USER_TYPES.CSA ? bookingEmail : email)
      )
    ),

    // eslint-disable-next-line max-len
    getRequestInvoiceData: new Selector(({ getTabContentSections, getRelatedEmailAddress }) =>
      createSelector(
        [
          getModalData,
          (state) => getTabContentSections(state)(REQUEST_INVOICE),
          getSubmitting,
          getLabels,
          getRelatedEmailAddress,
        ],
        ({ id }, sections, submitting, { buttons: { cancel, confirm, close } = {} }, emailValue) => {
          const messages = sections?.[0]?.messages || [];
          const getCMSLabelText = (reference) => getCmsLabel(messages, reference, 'text');
          // eslint-disable-next-line max-len
          const getMessageWithEmail = (reference) =>
            replaceCMSTokenWithValue(getCMSLabelText(reference), [{ key: 'EMAIL', value: emailValue }]);
          return {
            modalData: {
              entityId: id,
              modalTitle: getCMSLabelText('title'),
              message: getMessageWithEmail('accountRequestInvoiceModal'),
              successTitle: getCMSLabelText('accountRequestInvoiceCompleteTitle'),
              successMessage: getCMSLabelText('accountRequestInvoiceCompleteBody'),
              buttons: {
                confirm,
                cancel,
                close,
              },
              submitting,
            },
            content: {
              title: getCMSLabelText('accountRequestInvoice'),
              requestInvoiceMessage: getMessageWithEmail('accountRequestInvoiceReceive'),
              button: getCMSLabelText('title'),
            },
          };
        }
      )
    ),

    getCustomizableTabs: new Selector(({ getTabs }) =>
      createSelector(
        [getTabs, getTabUrl, getVoyageSailingStatus, getIsDirect, getIsTaAccount],
        (accountTabs, getTabUrlFunction, sailingStatus, isDirect, isTaAccount) => (location, match) => {
          let tabs = accountTabs(location.pathname, match.path).slice();
          const removeTab = (tabName) => {
            const tabToRemove = getTabUrlFunction(PAGE_NAMES.ACCOUNT, tabName);
            tabs = tabs.filter((tab) => {
              return !tab.url.includes(tabToRemove);
            });
          };
          if (
            (!isDirect && !isTaAccount) ||
            sailingStatus === VOYAGE_STATUSES.CANCELED ||
            sailingStatus === VOYAGE_STATUSES.SAILING ||
            sailingStatus === VOYAGE_STATUSES.SAILED
          ) {
            removeTab(REQUEST_INVOICE);
          }
          if (!isDirect && isTaAccount) {
            removeTab(PROFILE);
            tabs = tabs.map((tab) => {
              if (tab.url.includes('invoice')) {
                return {
                  ...tab,
                  active: true,
                  default: true,
                };
              }
              return tab;
            });
          }
          return { tabs, isDirect, isTaAccount };
        }
      )
    ),
  },
});

export const fetchAccountPageContent = () => (dispatch, getState) => {
  const { receiveContent } = accountStore.creators;
  const url = buildUrl('/pages/account', ['voyage.type'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: accountStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchTabContent = (tab, refreshData = true, updateImmediately = true) => (dispatch, getState) => {
  const bookingDetails = getBookingDetails(getState());
  const { receiveTabContent } = accountStore.creators;
  const url = buildUrl(`/account/${tab}`, ['office', 'currency', 'voyage.type'], bookingDetails);
  return dispatch(
    getData({
      url,
      store: accountStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      tab,
      refreshData,
      updateImmediately,
    })
  );
};

export const submitRequestInvoice = () => (dispatch, getState) => {
  const state = getState();
  const bookings = getBookingDetails(state);
  const { firstName, lastName, updateUserType, email } = getUpdateUserData(getState());
  const bookingEmail = getUserEmailViaCSA(getState());
  const url = buildUrl('/bookings/invoice', ['office', 'currency', 'bookingNumber'], bookings);
  return dispatch(
    postData({
      url,
      values: {
        updateUserInfo: {
          lastName,
          firstName,
          updateUserType,
          email,
        },
        bookingEmail: updateUserType === USER_TYPES.CSA ? bookingEmail : email,
      },
    })
  ).then((response) => response);
};

export default accountStore;
