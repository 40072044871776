import classNames from 'classnames';
import React from 'react';
import { prepareHtml } from '../../../common/Utils';
import PropTypes from '../../../PropTypes';
import './CalendarLine.scss';

function readableHour(h, m, text, { am, pm }) {
  let hour = h;
  let suffix = am;
  if (hour >= 12) {
    if (hour > 12) {
      hour -= 12;
    }
    suffix = h === 24 ? am : pm;
  }

  return (
    <span className="time">
      <span className="hour">{hour}</span>
      <span className="minutes">{`:${m}`}</span>
      <span className="suffix" dangerouslySetInnerHTML={prepareHtml(`&nbsp;${suffix}`)} />
      {text && <span className="d-none d-xl-inline port">{text}</span>}
    </span>
  );
}
function CalendarLine({ hour, min, text, morningLabel, afternoonLabel }) {
  const time = readableHour(hour, min, text, { am: morningLabel, pm: afternoonLabel });
  return (
    <div className={classNames('row', 'calendar-line', { thick: text })}>
      <div className="calendar-line-inner">
        <div className="meta col-2">
          {time}
          {text && <span className="d-xl-none port">{text}</span>}
        </div>
        <div className="col-10">
          <hr />
        </div>
      </div>
    </div>
  );
}

CalendarLine.propTypes = {
  hour: PropTypes.number.isRequired,
  min: PropTypes.string.isRequired,
  text: PropTypes.string,
  morningLabel: PropTypes.string,
  afternoonLabel: PropTypes.string,
};

CalendarLine.defaultProps = {
  morningLabel: 'AM',
  afternoonLabel: 'PM',
  text: null,
};

export default CalendarLine;
