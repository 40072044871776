// This is the only file that should import redux-form/Field directly
// eslint-disable-next-line no-restricted-imports
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import CommonStore from '../../../common';

const { getErrors } = CommonStore.selectors;

const mapStateToProps = (state, ownProps) => ({
  errorMessages: ownProps.errorMessages || getErrors(state),
});

export default connect(mapStateToProps)(Field);
