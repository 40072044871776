import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import momenttz from 'moment-timezone';
import { createSelector } from 'reselect';
import commonStore from '../../common';
import { getData, postData } from '../../common/Api';
import {
  ALERT_CODES,
  APP_PATHS,
  BOOKING_PACKAGE_TYPE,
  BOOKING_STATUSES,
  GREAT_LAKES_VOYAGES,
  INCLUDED_STATEROOM,
  PAGE_NAMES,
  PROMOS,
  PROMO_URLS,
  USER_TYPES,
  VOYAGE_TYPE,
} from '../../common/Constants';
import modalStore from '../../common/ModalStore';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import userStore, { reloadBookings } from '../../common/UserStore';
import { buildUrl, getCmsLabel, goTo, replaceCMSTokenWithValue } from '../../common/Utils';
import { triggerLinkEvent } from '../../common/Analytics';

const {
  selectors: { getPageTabLabels, getMvjFlags, getMvjStrings },
} = commonStore;

const {
  selectors: { getModalId },
} = modalStore;

const {
  selectors: {
    getBaseTrackingData,
    getBookingDetails,
    getUsername,
    getCart,
    getVoyageSailingStatus,
    getLockoutStatus,
    getIsViewOnly,
    getUserType,
    getUserData,
    getDaysToGoDateValue,
    getLoggedInUser,
    getVoyageType,
  },
} = userStore;

const filterGreatLakesAlerts = (notifications) => {
  return notifications.filter((n) => n.alertCode !== 'EXCOPN');
};

const notificationsStore = createPageTitleDuck('notifications').extend({
  types: ['RECEIVE_NOTIFICATIONS'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_NOTIFICATIONS:
        return {
          ...state,
          notifications: {
            data: GREAT_LAKES_VOYAGES.includes(action.voyageId)
              ? filterGreatLakesAlerts(action.notifications)
              : action.notifications,
            loaded: true,
          },
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveNotifications: (notifications, voyageId) => ({
      type: types.RECEIVE_NOTIFICATIONS,
      notifications,
      voyageId,
    }),
  }),
  selectors: {
    getNotifications: (state) => get(state, 'notifications.notifications.data', []),
    getNotificationsByType: new Selector(({ getNotifications }) =>
      createSelector([getNotifications], (notifications) =>
        memoize((type) => notifications.filter((notification) => notification.type === type))
      )
    ),
    getHeaderIconData: new Selector(({ getNotifications }) =>
      createSelector([getNotifications, getCart], (notifications, cart) => {
        const allNotifications = [
          {
            items: notifications || [],
            variant: 'notification',
            ariaLabel: 'Notification',
            route: APP_PATHS.NOTIFICATIONS_CART,
          },
          {
            items: cart.items || [],
            variant: 'cart',
            ariaLabel: 'Cart',
            route: APP_PATHS.PAYMENTS,
          },
        ];

        return allNotifications;
      })
    ),
    getHeaderIcons: new Selector(({ getHeaderIconData }) =>
      createSelector(
        [
          getHeaderIconData,
          getVoyageSailingStatus,
          getLockoutStatus,
          getIsViewOnly,
          (state) => get(state, 'user.cart.NoItemsInCart'),
        ],
        (headerIconData, voyageSailingStatus, lockout, isViewOnly, NoItemsInCart) => {
          const headerIconsEnabled = (!lockout || (lockout && isViewOnly)) && voyageSailingStatus === APP_PATHS.INDEX;

          const notificationIcons = headerIconData.reduce((acc, { items, variant, route, ariaLabel }) => {
            if (items && headerIconsEnabled) {
              const count = route === APP_PATHS.PAYMENTS ? NoItemsInCart : items.filter((item) => !item.viewed).length;
              acc.push({
                count,
                onClick: goTo(route),
                variant,
                ariaLabel,
              });
            }
            return acc;
          }, []);

          return notificationIcons;
        }
      )
    ),
    getNotificationsPageContent: new Selector((selectors) => {
      const { getPageContent, getNotifications } = selectors;
      return createSelector(
        [
          getPageContent,
          (state) => getPageTabLabels(state)(PAGE_NAMES.NOTIFICATIONS),
          getNotifications,
          getModalId,
          getVoyageType,
          getMvjFlags,
          getMvjStrings,
        ],
        (content, { buttons }, notifications, modalId, voyageType, mvjFlags, mvjStrings) => {
          const items = get(content, 'sections[0].items');
          if (!items) {
            return {};
          }
          const zeroNotificationsMessage = getCmsLabel(items, 'notificationNoMessage', 'title');
          const mappedNotifications = notifications.map((notification) => {
            const {
              button: { url },
              title,
            } = notification;

            const getPromoUrl = () => {
              return voyageType === VOYAGE_TYPE.OCEAN ? PROMO_URLS.RIVER : PROMO_URLS.OCEAN;
            };
            const BndPromoUrl = getPromoUrl();
            if (mvjFlags?.showBndPromo && notification.id === PROMOS.BND_PROMO) {
              notification.longText = replaceCMSTokenWithValue(
                notification.longText,
                [
                  {
                    key: 'pastGuestOfferUrl',
                    value: `<a href=${BndPromoUrl} target="_blank">${
                      mvjStrings?.labels?.pages?.notifications?.labels?.notificationVisitWebsite || ''
                    }</a>`,
                  },
                ],
                '{{',
                '}}'
              );
            }
            return {
              ...notification,
              button: {
                text: buttons.learnMore,
              },
              title,
              text: title,
              url,
            };
          });

          const zeroNotifications = {
            text: zeroNotificationsMessage,
            className: 'zero-notifications',
          };
          const modalData = {
            printLabel: buttons.print,
            buttonText: buttons.close,
            buttonAppearance: 'secondary-blue',
          };
          if (modalId) {
            const modalAlert = mappedNotifications.find((n) => n.id === modalId) || {};
            modalData.message = modalAlert.longText;
            modalData.title = modalAlert.subtitle;
            modalData.voyageId = modalAlert.voyageId;
            modalData.notificationId = modalAlert.id;
          }
          return {
            labels: {
              back: buttons.back,
            },
            modalData,
            notifications: (mappedNotifications.length && mappedNotifications) || [zeroNotifications],
          };
        }
      );
    }),
  },
});

const {
  creators: { receiveNotifications },
} = notificationsStore;

const mapNotificationsForStore = (data) => (dispatch, getState) => {
  const notifications = isEmpty(data) ? [] : data;
  const state = getState();
  const {
    packageType,
    ship: { stateroomCategory },
    bookingStatus,
    comboBookings,
    voyage: { id: voyageId },
  } = getBookingDetails(state);
  const isSsbpIncluded = stateroomCategory === INCLUDED_STATEROOM.EXPLORER_SUITE;
  const dupRiverEXCOPN = [];
  let hasRiverEXCOPN = false;
  const mappedNotifications =
    notifications?.reduce((acc, item) => {
      if (Array.isArray(comboBookings) && comboBookings.length > 0 && item.id === ALERT_CODES.EXCOPN) {
        const booking = comboBookings.find((details) => details.voyageId === item.voyageId);
        if (booking?.voyageType === VOYAGE_TYPE.RIVER) {
          if (!hasRiverEXCOPN) {
            hasRiverEXCOPN = true;
            acc.push(item);
          } else {
            dupRiverEXCOPN.push(item);
          }
          return acc;
        }
        acc.push(item);
        return acc;
      }
      if (isSsbpIncluded && item.id === ALERT_CODES.SSBP) {
        return acc;
      }
      if (item.id === ALERT_CODES.AIRPEN && packageType === BOOKING_PACKAGE_TYPE.CRUISE_ONLY) {
        return acc;
      }
      if (bookingStatus === BOOKING_STATUSES.CONFIRMED && item.id === ALERT_CODES.DEPOSIT_DUE) {
        return acc;
      }
      acc.push(item);
      return acc;
    }, []) || [];
  if (dupRiverEXCOPN.length > 0) {
    dupRiverEXCOPN.forEach(({ alertCode, voyageId }) => {
      dispatch(dismissNotification(alertCode, voyageId));
    });
  }
  if (mappedNotifications.length > 0) {
    dispatch(receiveNotifications(mappedNotifications, voyageId));
  }
  mappedNotifications.forEach(({ alertCode }) => triggerLinkEvent({
    event: 'notification_view',
    modal_type: 'promo_notification',
    modal_name: alertCode,
    timestamp: Date.now(),
    ...getBaseTrackingData(getState()),
  }));
};

export const fetchNotifications = (refreshData = false) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const userType = getUserType(state);
  const countDown = getDaysToGoDateValue(state);
  let username = getUsername(state);
  const { passengerNumber } = getLoggedInUser(state);
  if (!passengerNumber) {
    return Promise.resolve(null);
  }

  const { comboBookings } = bookingDetails;
  let config = {
    suppressError: true,
  };
  if (comboBookings.length > 1) {
    const comboVoyageIds = comboBookings.map(({ voyageId }) => voyageId).join('|');
    config = {
      params: {
        comboVoyageIds,
      },
    };
  }

  if (userType === USER_TYPES.CSA) {
    const userData = getUserData(state);
    const { sub, userId } = userData;
    username = sub || userId;
  }

  if (!bookingDetails) {
    return Promise.resolve(null);
  }

  const buildUrlPathData = {
    ...bookingDetails,
    username,
  };

  const url = buildUrl('/alerts', ['office', 'currency', 'bookingNumber', 'username'], buildUrlPathData, {
    countDown,
    timezone: momenttz.tz.guess(),
  });

  return dispatch(
    getData({
      url,
      store: notificationsStore,
      node: 'notifications',
      creator: mapNotificationsForStore,
      refreshData,
      config,
    })
  );
};

export const fetchNotificationsPageContent = () => (dispatch, getState) => {
  const { receiveContent } = notificationsStore.creators;
  const url = buildUrl('/pages/notifications', ['voyage.type'], getBookingDetails(getState()));
  dispatch(reloadBookings());
  dispatch(fetchNotifications(true));
  dispatch(
    getData({
      url,
      store: notificationsStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const dismissNotification = (alertCode, voyageId) => (dispatch, getState) => {
  const state = getState();
  const userType = getUserType(state);
  if (userType === USER_TYPES.CSA) {
    return Promise.resolve(null);
  }

  const bookingDetails = getBookingDetails(state);
  const username = getUsername(state);
  const queryParams = {
    dismissed: true,
    viewed: true,
  };
  if (voyageId) {
    queryParams.voyageId = voyageId;
  }

  const url = buildUrl(
    '/alerts/dismiss',
    ['username', 'bookingNumber', 'alertCode'],
    {
      ...bookingDetails,
      alertCode,
      username,
    },
    queryParams
  );

  return dispatch(
    postData({
      url,
    })
  ).then(({ data: { status } }) => {
    if (status === 'success') {
      dispatch(fetchNotifications(true));
    }
  });
};
export const viewedNotification = (alertCode, voyageId) => (dispatch, getState) => {
  const state = getState();
  const userType = getUserType(state);
  if (userType === USER_TYPES.CSA) {
    return Promise.resolve(null);
  }

  const bookingDetails = getBookingDetails(state);
  const username = getUsername(state);
  const queryParams = {
    dismissed: false,
    viewed: true,
  };
  if (voyageId) {
    queryParams.voyageId = voyageId;
  }

  const url = buildUrl(
    '/alerts/dismiss',
    ['username', 'bookingNumber', 'alertCode'],
    {
      ...bookingDetails,
      alertCode,
      username,
    },
    queryParams
  );

  return dispatch(
    postData({
      url,
    })
  ).then(({ data: { status } }) => {
    if (status === 'success') {
      dispatch(fetchNotifications(true));
    }
  });
};

export const setViewedNotifications = (alertCodes = []) => (dispatch, getState) => {
  const state = getState();
  const userType = getUserType(state);
  if (userType === USER_TYPES.CSA) {
    return Promise.resolve(null);
  }

  const bookingDetails = getBookingDetails(state);
  const username = getUsername(state);
  let promises = [];
  if (alertCodes.length) {
    promises = alertCodes.map(({ id, voyageId }) => {
      const queryParams = {
        dismissed: false,
        viewed: true,
      };
      if (voyageId) {
        queryParams.voyageId = voyageId;
      }
      const url = buildUrl(
        '/alerts/dismiss',
        ['username', 'bookingNumber', 'id'],
        {
          ...bookingDetails,
          id,
          username,
        },
        queryParams
      );

      return dispatch(
        postData({
          url,
        })
      );
    });
  }
  return Promise.all(promises).then((res) => {
    if (res.every(({ data }) => data.status === 'success')) {
      dispatch(fetchNotifications(true));
    }
  });
};

export default notificationsStore;
