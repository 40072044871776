import React, { Fragment } from 'react';

import { Modal } from '..';

import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';

import './Footer.scss';

const FooterModal = ({ header, sections, type, handleModalClose, printLabel }) => (
  <Modal id={type} handleModalClose={handleModalClose} printLabel={printLabel}>
    <div className="modal-footer-mvj">
      <h3 className="h4-sm heading-modal-footer" dangerouslySetInnerHTML={prepareHtml(header)} />
      {sections.map(({ title, description }) => (
        <Fragment key={title}>
          <h5 dangerouslySetInnerHTML={prepareHtml(title)} />
          <div className="section" dangerouslySetInnerHTML={prepareHtml(description)} />
        </Fragment>
      ))}
    </div>
  </Modal>
);

FooterModal.propTypes = {
  ...PropTypes.modalData,
};

FooterModal.defaultProps = {
  handleModalClose: () => {},
  header: '',
  sections: [],
  type: '',
  printLabel: '',
};

export default FooterModal;
