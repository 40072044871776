import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import SeatMap from '@viking-eng/seat-map';
import Select from '@viking-eng/select';
import { humanReadableDuration } from '@viking-eng/utils';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import { LEGEND } from '../../common/Constants';
import EmptySeatMap from '../../pages/newAir/seats/EmptySeatMap';
import '../../pages/newAir/seats/seats.scss';
import './SeatMapModal.scss';

const SeatMapModal = (props) => {
  const {
    id: modalId,
    currentFlight,
    seatMapDataIsLoading,
    cabins,
    seatClickedId,
    setSeatClickedId,
    previewSeatsTitle,
    previewSeatsSubTitle,
    seatMapNotAvailable,
    emptySeatMapDesktop,
    emptySeatMapMobile,
    preAssignedSeat1,
    preAssignedSeat2,
    seatMapTimeOut,
    tryAgainText,
    onClickTryAgain,
    seatTimeOutCallCount,
    allowPaidSeats,
    showPaidSeats,
    updateSeatMapDataOnSegmentSelectChange,
    allFlights,
    preAssignedSeatsPax1,
    preAssignedSeatsPax2,
    showPreAssignedSeats,
  } = props;

  const formatDuration = (duration, showLongText = false) =>
    (duration && humanReadableDuration(duration, showLongText)) || '';
  const [inputValue, setValue] = useState(currentFlight.segmentNumber);
  const [flightSelectionChanged, setFlightSelectionChanged] = useState(false);
  const [legendOpen, setLegendOpen] = useState(false);
  const [preAssignedSeat1Updated, setPreAssignedSeat1Updated] = useState(preAssignedSeat1);
  const [preAssignedSeat2Updated, setPreAssignedSeat2Updated] = useState(preAssignedSeat2);

  const getFlightIndexFromSegmentNumber = (segmentNumber) => {
    return allFlights.findIndex((flight) => flight?.segment?.segmentNumber === segmentNumber);
  };

  const updatePreAssignedSeatsPax1 = (index) => {
    const newPreAssignedSeat1 = showPreAssignedSeats ? preAssignedSeatsPax1?.[index]?.seat : '';
    setPreAssignedSeat1Updated(newPreAssignedSeat1);
  };

  const updatePreAssignedSeatsPax2 = (index) => {
    const newPreAssignedSeat2 = showPreAssignedSeats ? preAssignedSeatsPax2?.[index]?.seat : '';
    setPreAssignedSeat2Updated(newPreAssignedSeat2);
  };

  const options = allFlights.map((flight, index) => ({
    value: flight?.segment?.segmentNumber,
    label: (
      <div className="flight-details-select">
        <div className="width-100">
          <div className="d-none d-md-block float-left font-weight-bold details-first-row">
            {`${flight?.segment?.departureCity} (${flight?.segment?.departureCityCode}) - ${flight?.segment?.arrivalCity} (${flight?.segment?.arrivalCityCode})`}
          </div>
          <div className="d-md-none float-left font-weight-bold mobile-flights">{`${flight?.segment?.departureCityCode} - ${flight?.segment?.arrivalCityCode}`}</div>
          {flight?.segment?.segmentNumber === inputValue && (
            <div className="selected-right-icon">
              <Icon name="checkmark" />
            </div>
          )}
          <div className="float-right filght-number font-weight-bold">{`${index + 1} of ${allFlights?.length}`}</div>
        </div>
        <div className="width-100 not-bold">
          <div className="float-left">
            <span>{`${flight?.segment?.airlineCode} ${flight?.segment?.flightNumber}`}</span>
            <span className="details-margin">
              {moment(flight?.segment?.departureDate, 'YYYY-MM-DD').format('MMM DD')}
            </span>
            <span className="details-margin">{formatDuration(flight?.segment?.duration)}</span>
            <span className="details-margin hide-mobile">{flight?.segment?.equipment}</span>
          </div>
        </div>
      </div>
    ),
  }));
  const defaultdata = {
    meta: {
      active: true,
      touched: false,
      error: 'false',
      asyncValidating: false,
      valid: false,
    },
    disabled: false,
    input: {
      value: currentFlight.segmentNumber,
    },
  };
  return (
    <Modal id={modalId} onClose={() => setValue(currentFlight.segmentNumber)}>
      <>
        <div className="seatmap-divider" />
        <div className="abe-air-seats seats-modal">
          <div className="row">
            <div className="col-12 text-center">
              <h3>{previewSeatsTitle}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center sub-title">{previewSeatsSubTitle}</div>
          </div>
          <div className="seat-map-container">
            <div>
              <Select
                input={{
                  ...defaultdata.input,
                  onChange: (value) => {
                    if (inputValue !== value) {
                      setValue(value);
                      setFlightSelectionChanged(true);
                      updateSeatMapDataOnSegmentSelectChange(value);
                      updatePreAssignedSeatsPax1(getFlightIndexFromSegmentNumber(value));
                      updatePreAssignedSeatsPax2(getFlightIndexFromSegmentNumber(value));
                    }
                  },
                  value: inputValue,
                }}
                appearanceInputText
                blurInputOnSelect
                hideSelectedOptions={false}
                openMenuOnFocus
                tabSelectsValue
                options={options}
              />
            </div>
            {seatMapDataIsLoading && (
              <div className="row spinner-container">
                <div className="col-12">
                  <Icon name="spinner" />
                </div>
              </div>
            )}
            {cabins && cabins?.length > 0 && !seatMapDataIsLoading && !seatMapNotAvailable && (
              <>
                <div className="row ">
                  <div className="col-12 legend-title">
                    <Button
                      onButtonClick={() => {
                        setLegendOpen(!legendOpen);
                      }}
                    >
                      <div className="btn-text-icon">
                        <Icon name="legend" />
                        {legendOpen ? LEGEND.TITLE_OPEN : LEGEND.TITLE_CLOSE}
                      </div>
                    </Button>
                  </div>
                </div>
                {legendOpen && (
                  <div
                    className={classNames('legend-box', {
                      'tall-box': allowPaidSeats,
                    })}
                  >
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-12">
                            <span className="legend-icon selected-icon">#</span>
                            <span className="legend-label">{LEGEND.SELECTED}</span>
                          </div>
                          <div className="col-12">
                            <span className="legend-icon available-icon" />
                            <span className="legend-label">
                              {allowPaidSeats ? LEGEND.AVAILABLE_FREE : LEGEND.AVAILABLE}
                            </span>
                          </div>
                          {allowPaidSeats && (
                            <div className="col-12 d-md-none">
                              <span className="legend-icon exit-icon" />
                              <span className="legend-label exit-label">{LEGEND.EXIT}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          {allowPaidSeats && (
                            <div className="col-12 d-flex justify-content-end">
                              <span className="legend-icon paid-seat-icon" />
                              <span className="legend-label">{LEGEND.PAID_SEAT}</span>
                            </div>
                          )}
                          <div
                            className={classNames('col-12', {
                              'd-flex justify-content-end': !allowPaidSeats,
                            })}
                          >
                            <span className="legend-icon un-available-icon">
                              <Icon name="close" className="no-click" />
                            </span>
                            <span className="legend-label">{LEGEND.UNAVAILABLE}</span>
                          </div>
                          {!allowPaidSeats && (
                            <div className="col-12 d-md-none">
                              <span className="legend-icon exit-icon exit-right" />
                              <span className="legend-label exit-label">{LEGEND.EXIT}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {seatMapNotAvailable && !seatMapDataIsLoading && (
              <div className="row">
                <div className="col-12 seat-map-container">
                  <EmptySeatMap
                    emptySeatMapDesktop={emptySeatMapDesktop}
                    emptySeatMapMobile={emptySeatMapMobile}
                    seatMapTimeOut={seatMapTimeOut}
                    tryAgainText={tryAgainText}
                    onClickTryAgain={() => onClickTryAgain(inputValue)}
                    seatTimeOutCallCount={seatTimeOutCallCount}
                  />
                </div>
              </div>
            )}
            {cabins && cabins?.length > 0 && !seatMapDataIsLoading && !seatMapNotAvailable && (
              <div className="row">
                <div className="col-12">
                  <SeatMap
                    allowPaidSeats={allowPaidSeats}
                    cabins={cabins}
                    seatSelected1={flightSelectionChanged ? preAssignedSeat1Updated : preAssignedSeat1}
                    seatSelected2={flightSelectionChanged ? preAssignedSeat2Updated : preAssignedSeat2}
                    preAssignedSeat1={flightSelectionChanged ? preAssignedSeat1Updated : preAssignedSeat1}
                    preAssignedSeat2={flightSelectionChanged ? preAssignedSeat2Updated : preAssignedSeat2}
                    seatClickedId={seatClickedId}
                    setSeatClickedId={setSeatClickedId}
                    previewSeats
                    showPaidSeats={showPaidSeats}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};

SeatMapModal.propTypes = {
  ...PropTypes.modalData,
};

export default SeatMapModal;
