import React from 'react';
import PropTypes from '../PropTypes';

const ModalBody = ({ children, className }) => (
  <div className={`card-details-modal mvj-modal ${className}`}>{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  className: PropTypes.string,
};

export default ModalBody;
