import CardSections from '@viking-eng/card-sections';
import { COMMON_CONSTANTS } from '@viking-eng/utils';
import React from 'react';
import remapCardDataBasedOnModalType from '../../common/ModalUtils';
import { getImageAttributes } from '../../common/Utils';
import { ExtensionModalMainContent, ExtensionModalSideContent, PageHeader } from '../../components';
import { ReservationModal } from '../../modals';
import PropTypes from '../../PropTypes';
import './Extensions.scss';

const { CARD_VARIANTS } = COMMON_CONSTANTS;

const mapCards = (cardsList, callback, perPersonContent) =>
  cardsList.map((cardsSection) => ({
    ...cardsSection,
    preContent: perPersonContent,
    cards: cardsSection.cards.map((card) => {
      const onButtonClick = () => {
        callback(card.id, card.typeCode);
      };
      card.subTitle = card.subtitle;
      card.images = (card.images || []).flat().map((image) => image && getImageAttributes({ image, tier: 'xs' }));

      const remappedCardsData = remapCardDataBasedOnModalType(card, {
        onPrimaryButtonClick: onButtonClick,
        onSecondaryButtonClick: onButtonClick,
        defaultClick: onButtonClick,
      });
      return remappedCardsData;
    }),
  }));

const getPerPersonContent = (labels) => (
  <div className="pre-content cards card-deck">{labels?.generic?.allPricesPerPerson}</div>
);

const Extensions = ({
  isLoading,
  title,
  subtitle,
  labels,
  extensionsPageData,
  handleCardDetailsClicked,
  modalData,
  modalToOpen,
  isFetchingCommonContent,
}) => {
  let remappedCards = [];
  let pageSubtitle = subtitle;
  const { noAvailableSubtitle, extensionCardData } = extensionsPageData;
  if (extensionCardData.length > 0) {
    const perPersonContent = getPerPersonContent(labels);
    remappedCards = mapCards(extensionsPageData.extensionCardData, handleCardDetailsClicked, perPersonContent);
  } else if (!isLoading) {
    pageSubtitle = noAvailableSubtitle;
  }

  if (!isFetchingCommonContent && modalData && modalToOpen && modalToOpen.modalType && modalToOpen.modalId) {
    handleCardDetailsClicked(modalToOpen.modalId, modalToOpen.modalType);
  }

  return (
    <div id="extensionsPage" className="extensions-page">
      <PageHeader isLoading={isLoading} hasLine title={title} subtitle={pageSubtitle} />
      {!isLoading && remappedCards.length > 0 && (
        <div>
          <CardSections sections={remappedCards} variant={CARD_VARIANTS.FIXED_WIDTH} />
        </div>
      )}

      <ReservationModal
        id="extensionsModal"
        {...modalData}
        mainContent={<ExtensionModalMainContent {...modalData} />}
        sideContent={<ExtensionModalSideContent {...modalData.sideContent} />}
      />
    </div>
  );
};

Extensions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extensionsCardData: PropTypes.shape(),
  handleCardDetailsClicked: PropTypes.func.isRequired,
  modalToOpen: PropTypes.shape({ modalType: PropTypes.string, id: PropTypes.string }),
  isFetchingCommonContent: PropTypes.bool,
  ...PropTypes.extentionsModalData,
};

Extensions.defaultProps = {
  title: '',
  subtitle: '',
  extensionsCardData: {
    preTripExtensions: [],
    postTripExtensions: [],
  },
  modalToOpen: null,
  isFetchingCommonContent: false,
};

export default Extensions;
