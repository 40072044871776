import Button from '@viking-eng/button';
import { prepareHtml } from '@viking-eng/utils';
import get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from '../../../PropTypes';
import ActionItemFormContainer from './ActionItemFormContainer';
import './SideContent.scss';

const SideContent = ({
  passengers,
  lockedDownMessage,
  date,
  handleCloseModalAndNav,
  onboardOnly,
  onboardMessage,
  showShorexTime,
  isSubShorex,
  isPrivateShorex,
}) => {
  const [showWaiverNumber, setShowWaiverNumber] = useState('');
  const showWaiverClass = `waiver-pax${showWaiverNumber}`;
  const gifLockdownData = get(lockedDownMessage, 'gifLockdown', {});
  const { gifCallToActionUrl, gifCallToActionTitle, gifValidationErrorBody, gifIncompleteLockdown } = gifLockdownData;
  const remappedPassengers = (isPrivateShorex && passengers.length > 1 ? passengers.slice(0, -1) : passengers).map(
    (passenger) => {
      if (isPrivateShorex) {
        return {
          ...passenger,
          passengerName: '',
        };
      }
      return passenger;
    }
  );
  if (onboardOnly) {
    return (
      <div className="shorex-form" data-id="shore-ex-modal-side-content">
        <div className="onboard-only-message">{onboardMessage}</div>
      </div>
    );
  }
  return (
    <div className="shorex-form" data-id="shore-ex-modal-side-content">
      {gifIncompleteLockdown && (
        <div className="validation-error-text">
          <Button appearance="link" onButtonClick={() => handleCloseModalAndNav(gifCallToActionUrl)}>
            {gifCallToActionTitle}
          </Button>{' '}
          <span>{gifValidationErrorBody}</span>
        </div>
      )}
      {lockedDownMessage.alert && (
        <p className="alert-message" dangerouslySetInnerHTML={prepareHtml(lockedDownMessage.alert)} />
      )}
      {remappedPassengers.map((passenger, i) => {
        const { passengerNumber, passengerName } = passenger;
        return (
          <div
            className={`${showWaiverClass} passenger select-time`}
            key={`shore-ex-passenger-${passengerNumber}`}
            data-id={`shore-ex-passenger-${passengerNumber}`}
          >
            <ActionItemFormContainer
              passenger={{ passengerNumber, passengerName }}
              date={date}
              showShorexTime={showShorexTime}
              paxIndex={i}
              onBookNow={(paxIndex) => setShowWaiverNumber(paxIndex)}
              hideWaiver={() => setShowWaiverNumber('')}
              isPrivateShorex={isPrivateShorex}
              isSubShorex={isSubShorex}
              passengerNumber={passengerNumber}
            />
          </div>
        );
      })}
    </div>
  );
};

SideContent.propTypes = {
  passengers: PropTypes.shorexPassengers.isRequired,
  lockedDownMessage: PropTypes.shape(),
  date: PropTypes.string.isRequired,
  handleCloseModalAndNav: PropTypes.func.isRequired,
  onboardOnly: PropTypes.bool.isRequired,
  onboardMessage: PropTypes.string,
  showShorexTime: PropTypes.bool,
};

SideContent.defaultProps = {
  lockedDownMessage: {},
  onboardMessage: '',
  showShorexTime: true,
};

export default SideContent;
