import { connect } from 'react-redux';

import commonStore from '../../common';

import CloseToSailing from './CloseToSailing';

const { getCloseToSailingModalData } = commonStore.selectors;

const mapStateToProps = (state) => ({
  closeToSailingModalData: getCloseToSailingModalData(state),
});

export default connect(mapStateToProps)(CloseToSailing);
