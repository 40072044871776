import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import PaymentSummary from '@viking-eng/payment-summary';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from '../../../PropTypes';
import { AIR_PATHS, APP_PATHS } from '../../../common/Constants';
import { goTo, prepareHtml } from '../../../common/Utils';
import AirHeader from '../../airHeader/AirHeader';
import Notification from '../../notification/Notification';
import './PaymentConfirmation.scss';

/* eslint-disable react/no-array-index-key */
const PaymentConfirmation = ({
  title,
  subtitle,
  button,
  buttonArray,
  bannerNotifications,
  isDirectBooking,
  airHeader,
  hideIcon,
  pendingSeatsWarning,
  shouldRedirectHome,
  allSeatMapsUnavailable,
  pageViewTrigger,
  updatedPaymentCheckoutSummary,
  schedulePaymentAllowed,
  scheduleAPayment,
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (pageViewTrigger) {
      pageViewTrigger(window.location.pathname);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */
  if (schedulePaymentAllowed) {
    buttonArray = [
      {
        text: button.text,
        onButtonClick: goTo(APP_PATHS.INDEX),
        appearance: 'secondary-blue',
      },
      {
        text: scheduleAPayment,
        appearance: 'secondary-blue',
        onButtonClick: goTo(`${APP_PATHS.SCHEDULE_PAYMENT}`),
      },
    ];
  }
  return (
    <>
      {shouldRedirectHome && <Redirect to={AIR_PATHS.HOME} />}
      {airHeader && <AirHeader className="done" showSubtitle={false} title={airHeader} />}
      <div className="payment-confirmation">
        <div className="row">
          <div className="col-12 body-container">
            <h2 className="title">
              {!hideIcon && (
                <span className="icon-wrapper">
                  <Icon name="check-circle" />
                </span>
              )}
              {title}
            </h2>
            {isDirectBooking && !allSeatMapsUnavailable && (
              <p className="subtitle" dangerouslySetInnerHTML={prepareHtml(subtitle)} />
            )}
            {bannerNotifications.length > 0 &&
              bannerNotifications.map((notification) => (
                <div className="payment-confirmation-ntf-wrapper" key={notification.id}>
                  <Notification text={notification.bannerNotificationMessage} icon="exclamation-mark-filled" />
                </div>
              ))}
            {pendingSeatsWarning && (
              <div className="payment-confirmation-ntf-wrapper">
                <Notification text={pendingSeatsWarning} icon="exclamation-mark-filled" />
              </div>
            )}
          </div>
          {buttonArray ? (
            <div className="col-12">
              <div className="row justify-content-center">
                {buttonArray.map((btn, index) => (
                  <div className="col-12 col-md-4">
                    <Button {...btn} className="btn-array" key={`confirm-button-${index}`}>
                      {btn.text}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="col-10 offset-1 col-lg-4 offset-lg-4">
              <Button {...button}>{button.text}</Button>
            </div>
          )}
          {updatedPaymentCheckoutSummary && (
            <div className="payment-checkout-summary col-12">
              <div className="row justify-content-center">
                <PaymentSummary
                  lineItems={updatedPaymentCheckoutSummary.lineItems}
                  discountItems={updatedPaymentCheckoutSummary.discountItems}
                  balanceItems={updatedPaymentCheckoutSummary.balanceItems}
                  seatLineItemHeader={updatedPaymentCheckoutSummary.seatLineItemHeader}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentConfirmation;

PaymentConfirmation.propTypes = {
  airHeader: PropTypes.string,
  allSeatMapsUnavailable: PropTypes.bool,
  bannerNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      bannerNotificationMessage: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  button: PropTypes.shape({
    appearance: PropTypes.string,
    onButtonClick: PropTypes.func,
    text: PropTypes.string,
  }),
  buttonArray: PropTypes.arrayOf(
    PropTypes.shape({
      appearance: PropTypes.string,
      onButtonClick: PropTypes.func,
      text: PropTypes.string,
    })
  ),
  hideIcon: PropTypes.bool,
  isDirectBooking: PropTypes.bool,
  pageViewTrigger: PropTypes.func,
  pendingSeatsWarning: PropTypes.bool.isRequired,
  shouldRedirectHome: PropTypes.bool,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updatedPaymentCheckoutSummary: PropTypes.shape({}),
  schedulePaymentAllowed: PropTypes.bool,
  scheduleAPayment: PropTypes.string,
};

PaymentConfirmation.defaultProps = {
  airHeader: '',
  allSeatMapsUnavailable: false,
  bannerNotifications: [],
  button: {},
  buttonArray: undefined,
  hideIcon: false,
  isDirectBooking: false,
  pageViewTrigger: undefined,
  shouldRedirectHome: false,
  updatedPaymentCheckoutSummary: undefined,
  schedulePaymentAllowed: false,
  scheduleAPayment: '',
};
