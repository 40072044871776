import { connect } from 'react-redux';

import commonStore from '../../../common';
import userStore from '../../../common/UserStore';

import { PAGE_NAMES, SCHEDULED_PAYMENT_STATE } from '../../../common/Constants';
import PaymentConfirmation from './PaymentConfirmation';

const { getLabels, getAllowScheduledPayment, getPageTabLabels } = commonStore.selectors;
const { getBookingDetails } = userStore.selectors;

const mapStateToProps = (state) => {
  const { modals: { paymentConfirmation: { title, subtitle } = {} } = {} } = getLabels(state);
  const { directBooking: isDirectBooking } = getBookingDetails(state);
  const pageLabelData = getPageTabLabels(state)(PAGE_NAMES.SCHEDULE_PAYMENT);
  const {
    buttons: { scheduleAPayment },
  } = pageLabelData;

  const schedulePaymentAllowed = getAllowScheduledPayment(state) === SCHEDULED_PAYMENT_STATE.ALLOWED;

  return {
    title,
    subtitle,
    isDirectBooking,
    schedulePaymentAllowed,
    scheduleAPayment,
  };
};

export default connect(mapStateToProps)(PaymentConfirmation);
