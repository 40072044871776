import Checkbox from '@viking-eng/checkbox';
import FormActions from '@viking-eng/form-actions';
import PageHeader from '@viking-eng/page-header';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Form } from 'redux-form';
import PropTypes from '../../../../PropTypes';
import { ACH, ALERT_TYPES, APP_PATHS, CREDIT_CARD, PAYMENT_METHOD_CHANGE_TYPE } from '../../../../common/Constants';
import { goTo } from '../../../../common/Utils';
import history from '../../../../common/history';
import { Field, FormACH, FormCreditCard } from '../../../../components';
import { NotificationModal } from '../../../../modals';
import './ManagePaymentMethod.scss';

const ManagePaymentMethod = ({
  labels: { cancel, submit, backButton },
  title,
  handleSubmit,
  fields: initialFields,
  message,
  changeType,
  paymentMethodType,
  forEdit,
  failedNotificationModalData,
  handleNameOnAccountChange,
  handleBankAccountChange,
  initialValues,
  form,
  pristine,
  resetChangeType,
  submitting,
  isLoading,
  submitFailed,
  valid,
  genericValidationMessage,
  country,
  disableAddAccountButton,
  restrictPaymentMessage,
  hasPreHeader,
  submitSucceeded,
}) => {
  let fields = initialFields;
  const [updated, setSubmitted] = useState(false);
  if (submitSucceeded && !updated) {
    setSubmitted(true);
  }
  if (paymentMethodType === ACH) {
    fields = {
      ...fields,
      nameOnAccount: {
        ...fields.nameOnAccount,
        onChange: (event, passengerId) => {
          handleNameOnAccountChange(passengerId);
        },
      },
    };
  }

  const errorTipMsg = submitFailed && !valid ? genericValidationMessage : '';

  const alert = {
    message: errorTipMsg || message || restrictPaymentMessage,
    type: submitFailed || restrictPaymentMessage ? ALERT_TYPES.ERROR : ALERT_TYPES.INFO,
  };

  return (
    <div className={classNames('manage-payment-method', { 'has-pre-header': hasPreHeader })}>
      <PageHeader
        title={title}
        hasHomeLink
        backButton={{
          label: backButton,
          previousPagePath: `${APP_PATHS.PAYMENTS}/payment-methods`,
        }}
        hasPrintLink={false}
        isLoading={isLoading}
        history={history}
      />
      {!isLoading && (
        <Form onSubmit={handleSubmit}>
          <Prompt
            when={pristine && forEdit}
            message={() => {
              resetChangeType();
              return true;
            }}
          />
          <NotificationModal {...failedNotificationModalData} />
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-12">
              {paymentMethodType === ACH && (
                <FormACH
                  {...fields}
                  forEdit={forEdit}
                  maskedAccountNumber={initialValues.maskedAccountNumber}
                  handleBankAccountChange={handleBankAccountChange}
                  propCountry={country}
                />
              )}
              {paymentMethodType === CREDIT_CARD && (
                <FormCreditCard {...fields} forEdit={forEdit} form={form} propCountry={country} />
              )}
              {fields.save && fields.save.placeholder && (
                <div className="additional-actions d-flex justify-content-between">
                  <Field component={Checkbox} id="saveBox" label={fields.save.placeholder} name="save" />
                </div>
              )}
              <FormActions
                primaryButton={{
                  onButtonClick: handleSubmit,
                  text: submit,
                  disabled: changeType === PAYMENT_METHOD_CHANGE_TYPE.EDIT || disableAddAccountButton || updated,
                  loading: submitting,
                }}
                history={history}
                secondaryButton={{
                  onButtonClick: goTo(`${APP_PATHS.PAYMENTS}/payment-methods`),
                  text: cancel,
                }}
                scrollOnAlert
                alert={alert}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

ManagePaymentMethod.propTypes = {
  fields: PropTypes.shape({
    ...PropTypes.creditCardFields,
  }),
  labels: PropTypes.shape({
    cancel: PropTypes.string,
    submit: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  forEdit: PropTypes.bool.isRequired,
  paymentMethodType: PropTypes.oneOf([ACH, CREDIT_CARD]).isRequired,
  failedNotificationModalData: PropTypes.notificationModalData.isRequired,
  handleNameOnAccountChange: PropTypes.func.isRequired,
  handleBankAccountChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    maskedAccountNumber: PropTypes.string,
  }).isRequired,
  form: PropTypes.string.isRequired,
  message: PropTypes.string,
  changeType: PropTypes.string,
  submitSucceeded: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  resetChangeType: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  genericValidationMessage: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  disableAddAccountButton: PropTypes.bool.isRequired,
  restrictPaymentMessage: PropTypes.string.isRequired,
  hasPreHeader: PropTypes.bool,
};

ManagePaymentMethod.defaultProps = {
  fields: {},
  message: '',
  changeType: null,
  hasPreHeader: false,
};

export default ManagePaymentMethod;
