import AlertWithLink from '@viking-eng/alert-with-link';
import BannerNotification from '@viking-eng/banner-notification';
import CartItem from '@viking-eng/cart-item';
import CartItemList from '@viking-eng/cart-item-list';
import Notifications from '@viking-eng/notifications';
import PaymentSummary from '@viking-eng/payment-summary';
import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import React, { Component } from 'react';
import {
  CART_PRICE_TIME_UPDATE_MODAL,
  CART_PRICE_UPDATE_MODAL,
  CART_TIME_UPDATE_MODAL,
  FALLBACK_IMAGE,
  MODALS,
  TIMER_STATUS,
} from '../../../common/Constants';
import { handleCmsLink, prepareHtml } from '../../../common/Utils';
import { CartPriceUpdateModal, CartSoldOutModal, CartTimeUpdateModal } from '../../../modals';
import PropTypes from '../../../PropTypes';
import './PaymentAddons.scss';
class PaymentAddons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failCount: props?.journeyCheckoutSection?.cartError ? 1 : 0,
    };

    this.soldOutAddonsPendingDeletion = false;
    this.onSoldOutAddonsRemoved = this.onSoldOutAddonsRemoved.bind(this);
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    const {
      isLoading,
      soldOutModalData: { items: soldOutItems },
      journeyCheckoutSection: { cartError },
      journeyAddonsSection: { lockedDownMessage, items: cartItems },
      emptyCart,
      handleModalOpen,
      paymentInProgress,
      refetchContentAfterTimeout,
      setProcessingTimerStatus,
      paymentProcessingTimerStatus,
    } = this.props;
    const { failCount } = this.state;
    const {
      journeyAddonsSection: { items: prevCartItems },
    } = prevProps;
    const { INIT, START, IN_PROCESS, COMPLETED } = TIMER_STATUS;

    if (lockedDownMessage && get(prevCartItems, 'length', 0) !== get(cartItems, 'length', 0) && cartItems.length > 0) {
      emptyCart(cartItems);
    } else if (soldOutItems.length > 0 && !isLoading && !this.soldOutAddonsPendingDeletion) {
      this.soldOutAddonsPendingDeletion = true;
      handleModalOpen(MODALS.CART_SOLD_OUT);
    }
    if (cartError && failCount === 0) {
      this.setState({ failCount: 1 });
    }
    if (!cartError && failCount > 0) {
      this.setState({ failCount: 0 });
    }

    if (paymentInProgress && paymentProcessingTimerStatus === INIT) {
      setProcessingTimerStatus(START);
    }
    if (paymentProcessingTimerStatus === START) {
      setProcessingTimerStatus(IN_PROCESS);
      this.timer = setTimeout(() => {
        setProcessingTimerStatus(COMPLETED);
        refetchContentAfterTimeout();
      }, 120000);
    }
    if (paymentProcessingTimerStatus === COMPLETED) {
      clearTimeout(this.timer);
    }
  }

  onSoldOutAddonsRemoved() {
    this.soldOutAddonsPendingDeletion = false;
  }

  render() {
    const {
      isLoading,
      journeyAddonsSection: { items, lockedDownMessage, viewOnly },
      journeyCheckoutSection,
      soldOutModalData,
      removeCartItem,
      ukPaymentsMessage,
      cartItemsWithPriceUpdateCodeArray,
      clearModal,
      priceHasChangedText,
      refetchContent,
      handleModalOpen,
      cartItemsTimeConflict,
      timeHasChangedText,
      timeHasChangedTitle,
      goToShorexModal,
      paymentProcessingMessage,
      paymentInProgress,
      isPaymentsDisabled,
      paymentsDisabledBanner,
    } = this.props;

    let mappedItems = [];

    const { failCount } = this.state;
    const { cartFailure, unableToLoadCart } = journeyCheckoutSection;

    cartItemsTimeConflict?.map((cartItem) => {
      const imageSrc = cartItem?.imageSrc?.length > 0 ? cartItem.imageSrc : FALLBACK_IMAGE;
      set(cartItem, 'imageSrc', imageSrc);
      return {
        cartItem,
      };
    });

    if (!_isEmpty(items)) {
      mappedItems = items.reduce((acc, item) => {
        if (viewOnly) {
          acc.push({
            ...item,
            removeButton: undefined,
          });
          return acc;
        }
        if (!item.isSoldOut) {
          let itemId = [item.itemID];
          if (item.comboData) {
            itemId = item.comboData.map((legData) => legData.itemID);
          }
          acc.push({
            ...item,
            removeButton: {
              ...item.removeButton,
              onButtonClick: () => removeCartItem(itemId),
            },
          });
        }
        return acc;
      }, []);
    }

    const cartSoldOutModal = (
      <CartSoldOutModal
        id="cartSoldOutModal"
        onAddonsRemoved={this.onSoldOutAddonsRemoved}
        handleModalOpen={() => handleModalOpen(CART_PRICE_UPDATE_MODAL.ID)}
        {...soldOutModalData}
      />
    );
    const cartItemsWithPriceUpdateMapped = mappedItems.filter((item) => {
      const itemServiceCode = cartItemsWithPriceUpdateCodeArray.filter(
        (cartItem) => cartItem?.serviceCode === item?.serviceCode && cartItem.cartItemStartTime === item.startTime
      );
      if (itemServiceCode?.[0]?.serviceCode === item.serviceCode) {
        return item;
      } else {
        return null;
      }
    });

    const cartTimeUpdateModalId =
      cartItemsWithPriceUpdateMapped?.length > 0 ? CART_TIME_UPDATE_MODAL.ID : CART_PRICE_TIME_UPDATE_MODAL.ID;

    if (ukPaymentsMessage) {
      return (
        <div className="row">
          {!isLoading && (
            <>
              <div className="col-md-7 col-sm-12 col-12 ">
                <div className="uk-payment-message">
                  <div role="none" dangerouslySetInnerHTML={prepareHtml(ukPaymentsMessage)} />
                </div>
              </div>
            </>
          )}
          {cartSoldOutModal}
        </div>
      );
    }

    return (
      <div className="row flex-row-reverse">
        {!isLoading && (
          <>
            {isPaymentsDisabled && (
              <div className="container banner-wrapper payment-alert-container">
                <BannerNotification
                  alert={{
                    alertText: paymentsDisabledBanner,
                  }}
                  icon="exclamation-mark-filled"
                />
              </div>
            )}
            {paymentInProgress && (
              <div className="container banner-wrapper payment-alert-container">
                <BannerNotification
                  alert={{
                    alertText: paymentProcessingMessage,
                  }}
                  icon="exclamation-mark-filled"
                />
              </div>
            )}
            <div className="col-md-5 col-sm-12 col-12">
              <PaymentSummary
                button={journeyCheckoutSection.button}
                balanceItems={journeyCheckoutSection.balanceItems}
                paymentStyle
                emptyCart={journeyCheckoutSection.emptyCart}
                alert={journeyCheckoutSection.alert}
              />
            </div>
            <div className="col-md-7 col-sm-12 col-12 ">
              {lockedDownMessage && _isEmpty(items) && (
                <Notifications
                  icon="calendar"
                  variant="banner"
                  notifications={[
                    {
                      button: {},
                      text: lockedDownMessage,
                    },
                  ]}
                />
              )}
              {failCount > 0 && failCount <= 3 && (
                <AlertWithLink
                  alert={unableToLoadCart?.pre}
                  alertButton={{
                    text: unableToLoadCart?.callToActionTitle,
                    onButtonClick: () =>
                      refetchContent().then((res) => {
                        if (res?.statusCode === '500') {
                          this.setState({ failCount: failCount + 1 });
                        }
                      }),
                  }}
                  alertEndText={unableToLoadCart?.post}
                />
              )}
              {failCount > 3 && (
                <AlertWithLink
                  alert={cartFailure?.pre}
                  alertButton={{
                    text: cartFailure?.callToActionTitle,
                    onButtonClick: () => handleModalOpen(MODALS.CONTACT_US),
                  }}
                  alertEndText={cartFailure?.post}
                />
              )}
              {!failCount && !lockedDownMessage && (
                <CartItemList>
                  {_isEmpty(items) || _isEmpty(mappedItems) ? (
                    <div className="empty-cart-message">
                      <div
                        onClick={handleCmsLink}
                        role="none"
                        dangerouslySetInnerHTML={prepareHtml(journeyCheckoutSection.nothingInCart)}
                      />
                    </div>
                  ) : (
                    mappedItems.map((item) => <CartItem key={item.itemID} {...item} />)
                  )}
                </CartItemList>
              )}
            </div>
          </>
        )}
        {cartSoldOutModal}
        {!isLoading && cartItemsWithPriceUpdateMapped?.length > 0 && (
          <CartPriceUpdateModal
            id={CART_PRICE_TIME_UPDATE_MODAL.ID}
            items={cartItemsWithPriceUpdateMapped}
            title={priceHasChangedText}
            onButtonClick={() => {
              clearModal();
              handleModalOpen(CART_TIME_UPDATE_MODAL.ID);
            }}
            buttonText={CART_PRICE_UPDATE_MODAL.BUTTON_TEXT}
          />
        )}
        {cartItemsTimeConflict?.length > 0 && (
          <CartTimeUpdateModal
            id={cartTimeUpdateModalId}
            items={cartItemsTimeConflict}
            title={timeHasChangedTitle}
            subtitle={timeHasChangedText}
            onButtonModifyClick={(startDate, serviceCode) => goToShorexModal(startDate, serviceCode)}
            buttonText={CART_TIME_UPDATE_MODAL.BUTTON_MODIFY_TEXT}
          />
        )}
      </div>
    );
  }
}

PaymentAddons.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  journeyAddonsSection: PropTypes.shape(PropTypes.journeyAddonList).isRequired,
  journeyCheckoutSection: PropTypes.shape({
    button: PropTypes.shape({ text: PropTypes.string }),
    lineItems: PropTypes.options,
    balanceItems: PropTypes.options,
  }).isRequired,
  soldOutModalData: PropTypes.shape({}).isRequired,
  removeCartItem: PropTypes.func.isRequired,
  emptyCart: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  ukPaymentsMessage: PropTypes.string.isRequired,
  isPaymentsDisabled: PropTypes.bool.isRequired,
  paymentsDisabledBanner: PropTypes.string.isRequired,
};

export default PaymentAddons;
