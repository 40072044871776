import React, { Component } from 'react';
import { history } from '../../common';
import { APP_PATHS, MODALS, SUB_DIV_SHOREX, SUB_DIV_SHOREX_CHARGE } from '../../common/Constants';
import { navigateTo } from '../../common/Utils';
import { PageHeader, PageMessage, ShorexCardList } from '../../components';
import PropTypes from '../../PropTypes';
import './Shorex.scss';

class Shorex extends Component {
  componentDidMount() {
    const { fetchExcursions, excursionDate } = this.props;
    fetchExcursions(excursionDate);
  }

  componentDidUpdate(prevProps) {
    const { cards, fetchExcursions, isLoading, linkedServiceId, handleCardDetailsClicked, excursionDate } = this.props;
    if (prevProps.excursionDate !== excursionDate) {
      fetchExcursions(excursionDate);
    }

    if (!isLoading && linkedServiceId && prevProps.cards.length !== cards.length) {
      const excursion = cards.find((item) => item && item.serviceCode === linkedServiceId);
      if (excursion) {
        const { id } = excursion;
        handleCardDetailsClicked(id, excursionDate);
      }
    }
  }

  render() {
    const {
      cards,
      content: { noExcursionsAvailableMessage, subtitle, title, bannerNotification },
      isLoading,
      navigationData: { days, selectPlaceholder },
      handleCardDetailsClicked,
      reservationModalData,
      resetReservationModalState,
      excursionDate,
    } = this.props;

    if (bannerNotification && !bannerNotification.alertText && bannerNotification.button) {
      const { button } = bannerNotification;
      button.onButtonClick = () => navigateTo(button.callToActionUrl);
    }

    let pageContent = null;
    if (cards && cards.length) {
      const cardsData = cards.map((data) => {
        const computeData = { ...data };
        if (computeData.primaryButton.text === 'CHECKOUT') {
          computeData.primaryButton.attributes = {};
        } else {
          computeData.primaryButton.attributes = {
            'data-toggle': 'modal',
            'data-target': `#${MODALS.SHOREX}`,
            'data-dismiss': 'modal',
          };
        }
        const subText = SUB_DIV_SHOREX.includes(data.serviceCode) ? SUB_DIV_SHOREX_CHARGE : data.primaryButton.subText;
        return {
          ...computeData,
          title: computeData.title,
          primaryButton: {
            ...computeData.primaryButton,
            subText,
            onButtonClick: () => {
              handleCardDetailsClicked(computeData.id, excursionDate);
              if (computeData.primaryButton.text === 'CHECKOUT') {
                history.push(APP_PATHS.CART);
              }
            },
          },
        };
      });
      const shorexModalSubtitle = SUB_DIV_SHOREX.includes(reservationModalData.mainContent.serviceCode)
        ? SUB_DIV_SHOREX_CHARGE
        : reservationModalData.mainContent.subtitle;
      const reservationModalDataNew = {
        mainContent: {
          ...reservationModalData.mainContent,
          subtitle: shorexModalSubtitle,
        },
      };

      pageContent = (
        <>
          <div className="shorex-cards">
            <ShorexCardList
              cards={cardsData}
              reservationModalData={reservationModalDataNew}
              onClose={resetReservationModalState}
            />
          </div>
        </>
      );
    } else if (!isLoading) {
      pageContent = (
        <div className="no-excursions-message">
          <PageMessage message={noExcursionsAvailableMessage} />
        </div>
      );
    }
    return (
      <div id="shorexPage" className="shorex-page">
        <PageHeader
          days={days}
          isLoading={isLoading}
          onDropdownChange={(value) => history.push(`${APP_PATHS.SHORE_EXCURSIONS}/${value}`)}
          selectPlaceholder={selectPlaceholder}
          subtitle={subtitle}
          title={title}
          notification={bannerNotification}
        />
        {pageContent}
      </div>
    );
  }
}

Shorex.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape()),
  content: PropTypes.shape({
    heading: PropTypes.string,
    noExcursionsAvailableMessage: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    bannerNotification: PropTypes.shape({
      alertText: PropTypes.string,
      button: PropTypes.shape({
        text: PropTypes.string,
        onButtonClick: PropTypes.func,
        callToActionUrl: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool,
  navigationData: PropTypes.itineraryNavigatorData,
  fetchExcursions: PropTypes.func,
  handleCardDetailsClicked: PropTypes.func,
  reservationModalData: PropTypes.shape({
    mainContent: PropTypes.shape(PropTypes.modalData),
    sideContent: PropTypes.shape({
      passengers: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      primaryButton: PropTypes.button,
      alert: PropTypes.string,
    }),
  }),
  resetReservationModalState: PropTypes.func,
  linkedServiceId: PropTypes.string,
  excursionDate: PropTypes.string.isRequired,
};

Shorex.defaultProps = {
  isLoading: false,
  navigationData: {},
  fetchExcursions: null,
  handleCardDetailsClicked: null,
  cards: [],
  content: {
    title: '',
  },
  reservationModalData: {
    mainContent: {},
    sideContent: {},
  },
  resetReservationModalState: null,
  linkedServiceId: '',
};

export default Shorex;
