import Button from '@viking-eng/button';
import Spinner from '@viking-eng/spinner';
import classNames from 'classnames';
import React from 'react';

import { ALERT_TYPES, DINING_DETAILS, STATUS_TYPES } from '../../common/Constants';
import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';

import './AddToCartModalConfirmation.scss';

const AddToCartModalConfirmation = ({
  passengers,
  details,
  alert,
  statusMessage,
  errorMessage,
  primaryButton,
  secondaryButton,
  diningPage,
  lockdownMessage,
  availabilityLoading,
}) => {
  let message = statusMessage;
  let messageType = STATUS_TYPES.ERROR;
  if (statusMessage && statusMessage.message) {
    ({ message, type: messageType } = statusMessage);
  }
  const subText = alert;
  const filteredDetails = details?.filter((d) => !!d);
  return (
    <div className="add-to-cart-modal-confirmation">
      {!diningPage && (
        <div className="booking-details">
          <ul className="list-unstyled">
            {/* eslint-disable react/no-array-index-key */}
            {passengers.map((passenger, i) => (
              <li key={i} className="passenger-name">
                {passenger}
              </li>
            ))}
          </ul>
          <div className="booking-details">
            {details.map((detail, i) => (
              <div className="booking-detail" key={i}>
                {detail}
              </div>
            ))}
          </div>
          {message && (
            <div
              className={classNames('status-message', {
                'validation-error-text': messageType === STATUS_TYPES.ERROR,
                'validation-message-text': messageType === STATUS_TYPES.MESSAGE,
              })}
            >
              {message}
            </div>
          )}
          {availabilityLoading ? (
            <div className="loading-spinner">
              <Spinner color="black" />
            </div>
          ) : (
            <div className="actions">
              {primaryButton.text && (
                <Button appearance="secondary-blue" {...primaryButton}>
                  {primaryButton.text}
                </Button>
              )}
              {secondaryButton.text && (
                <Button appearance="secondary-gray" {...secondaryButton}>
                  {secondaryButton.text}
                </Button>
              )}
              {(errorMessage || lockdownMessage) && (
                <div
                  className="validation-error-text status-message"
                  dangerouslySetInnerHTML={prepareHtml(errorMessage || lockdownMessage)}
                />
              )}
              {subText && subText.text && (
                <div
                  className={`text-center ${
                    subText.type === ALERT_TYPES.INFO ? 'validation-message-text' : 'validation-error-text'
                  }`}
                  dangerouslySetInnerHTML={prepareHtml(subText.text)}
                />
              )}
            </div>
          )}
        </div>
      )}
      {diningPage && (
        <div className="dining-confirmation">
          <div className="row dining-confirmation">
            <div className="col-12 col-md-8">
              {message && (
                <div
                  className={classNames('status-message', {
                    'validation-error-text': messageType === STATUS_TYPES.ERROR,
                    'validation-message-text': messageType === STATUS_TYPES.MESSAGE,
                  })}
                >
                  {message}
                </div>
              )}
            </div>
            <div className="d-none d-md-block col-md-4">
              {subText && subText.text && (
                <div
                  className={`text-center ${
                    subText.type === ALERT_TYPES.INFO ? 'remaining-reservation' : 'validation-error-text'
                  }`}
                  dangerouslySetInnerHTML={prepareHtml(subText.text)}
                />
              )}
            </div>
          </div>
          <div className="row dining-booking-details">
            <div className="col-12 col-md-8">
              <div className="col-100 passengers-details">
                {/* eslint-disable react/no-array-index-key */}
                {passengers.map((passenger, i) => {
                  const connector = i === passengers.length - 1 ? '' : ' & ';
                  return (
                    <>
                      <span key={i} className="passenger-name">
                        {passenger}
                        <span className="connector">{connector}</span>
                      </span>
                    </>
                  );
                })}
              </div>
              <div className="col-100 booking-details">
                {filteredDetails.map((detail, i) => {
                  const connector = i === filteredDetails.length - 1 || i === 0 ? '' : ' | ';

                  return (
                    detail !== DINING_DETAILS.CHEF_MENU && (
                      <>
                        <span className="booking-detail" key={i}>
                          {detail}
                          <span className="connector">{connector}</span>
                        </span>
                        {i === 0 && (
                          <span className="d-none d-md-block booking-detail-break">
                            <br />
                          </span>
                        )}
                      </>
                    )
                  );
                })}
              </div>
            </div>
            <div className="col-12 d-md-none">
              {subText && subText.text && (
                <div
                  className={`text-center ${
                    subText.type === ALERT_TYPES.INFO ? 'validation-message-text' : 'validation-error-text'
                  }`}
                  dangerouslySetInnerHTML={prepareHtml(subText.text)}
                />
              )}
            </div>
            <div className="actions col-12 col-md-4">
              {primaryButton.text && (
                <Button appearance="secondary-blue" {...primaryButton}>
                  {primaryButton.text}
                </Button>
              )}
              {secondaryButton.text && (
                <Button appearance="secondary-gray" {...secondaryButton}>
                  {secondaryButton.text}
                </Button>
              )}
              {errorMessage && (
                <div
                  className="validation-error-text status-message"
                  dangerouslySetInnerHTML={prepareHtml(errorMessage)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AddToCartModalConfirmation.propTypes = {
  primaryButton: PropTypes.button,
  details: PropTypes.arrayOf(PropTypes.string),
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
    })
  ),
  labels: PropTypes.shape({
    close: PropTypes.string,
  }),
  alert: PropTypes.string,
  statusMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
  ]),
  errorMessage: PropTypes.string,
  secondaryButton: PropTypes.button,
  diningPage: PropTypes.bool,
  availabilityLoading: PropTypes.bool,
};

AddToCartModalConfirmation.defaultProps = {
  primaryButton: {},
  secondaryButton: {},
  alert: '',
  statusMessage: '',
  errorMessage: '',
  passengers: [],
  details: [],
  labels: {
    close: '',
  },
  diningPage: false,
  availabilityLoading: false,
};

export default AddToCartModalConfirmation;
