import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import moment from 'moment';
import { createSelector } from 'reselect';
import commonStore, { userStore } from '../../common/index';
import { getData } from '../../common/Api';
import {
  APP_PATHS,
  BOOKING_STATUSES,
  BOOKING_TYPE,
  COUNTRIES,
  MVJ_FLAG_VARIABLES,
  PAGE_NAMES,
  PAYMENT_CART_ID,
  TWO_BY_ONE,
  USER_TYPES,
} from '../../common/Constants';
import { createPageContentDuck } from '../../common/ReusableDucks';
import { buildUrl, decodeCountryCodeFromCurrency, getCmsLabel, goTo, isFloatZero } from '../../common/Utils';
import paymentStore from '../payments/PaymentsStore';
import travelBookingStore from '../travelBooking/TravelBookingStore';

const { getFlagValue, getPageTabLabels, getLabels, getMvjFlags } = commonStore.selectors;
const { getBookingDetailsContent } = travelBookingStore.selectors;
const {
  getDaysToGo,
  getBalanceDueDate,
  getBookingDetails,
  getIsTaAccount,
  getCountryCodeFromCurrency,
  getUserType,
} = userStore.selectors;
const { getPaymentAmountForOption, getBookingHasScheduledPaymentPending } = paymentStore.selectors;

const homeStore = createPageContentDuck('home').extend({
  selectors: {
    getCards: (state) => get(state, 'home.content.sections[0].cards', []),
    getHomeMessages: (state) => state?.home?.content?.messages || [],
    getHomeCards: new Selector(({ getCards }) =>
      createSelector(
        [
          getCards,
          getIsTaAccount,
          getBookingDetails,
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.BOOKING_PAYMENTS_TAB_FLAG),
          getMvjFlags,
        ],
        (cards, isTaAccount, bookingDetails, bookingPaymentsTabFlag, mvjFlags) => {
          const hiddenTileUrl = mvjFlags?.showBookYourNextVoyage
            ? APP_PATHS.TRAVEL_REQUIREMENTS
            : APP_PATHS.BOOK_YOUR_NEXT_VOYAGE;
          cards = cards.filter((tile) => tile?.primaryButtonUrl !== hiddenTileUrl);

          const mappedCards = cards.map(({ id, title, primaryButtonUrl, images }) => {
            const thumbnailImage = images.find((image) => image.type === TWO_BY_ONE);
            return {
              id,
              title,
              thumbnailUrl: thumbnailImage.url,
              url: primaryButtonUrl,
            };
          });

          const isDirect = bookingDetails?.agent?.['Evo-UI-Code'] === BOOKING_TYPE.DIRECT;
          if (isTaAccount || isDirect) {
            const paymentsCartCard = mappedCards.find((card) => card.id === PAYMENT_CART_ID.PAYMENT_CART_CARD);
            if (paymentsCartCard && bookingPaymentsTabFlag) {
              paymentsCartCard.url = APP_PATHS.CRUISE_PAYMENTS;
            }
          }

          return mappedCards;
        }
      )
    ),
    getHomeFAQsText: (state) => state?.common?.content?.haveQuestionsReadOurFAQs?.title || '',
    getHomeStartGuideData: (state) => state?.home?.content?.sections?.[1]?.items || [],
    getHomeFAQsButton: (state) => state?.common?.content?.findAnswersButton?.title || '',
    getScheduleFinalPaymentNotification: (state) => {
      const getItemTitleByReference = (reference) =>
        state?.home?.content?.sections?.[0]?.items?.find((i) => i.reference === reference)?.title || '';
      return {
        text: getItemTitleByReference('notificationSchedulePayment'),
        button: {
          text: getItemTitleByReference('notificationScheduleButton'),
        },
      };
    },
    getHomePageNotifications: new Selector(({ getScheduleFinalPaymentNotification }) =>
      createSelector(
        [
          getBookingHasScheduledPaymentPending,
          (state) => getPaymentAmountForOption(state)('deposit'),
          getScheduleFinalPaymentNotification,
          getBookingDetails,
          getCountryCodeFromCurrency,
          getUserType,
          getBalanceDueDate,
        ],
        (
          isScheduledPaymentPending,
          depositDueAmount,
          scheduleFinalPaymentNotification,
          bookingDetails,
          country,
          userType,
          balanceDueDate
        ) => {
          const isDirect = bookingDetails?.agent?.['Evo-UI-Code'] === BOOKING_TYPE.DIRECT;
          const isCSA = [USER_TYPES.CSA, USER_TYPES.AIR].includes(userType);
          const isNotConfirmed = bookingDetails?.bookingStatus !== BOOKING_STATUSES.CONFIRMED;
          const today = moment();
          if (
            isFloatZero(depositDueAmount) ||
            isScheduledPaymentPending ||
            isNotConfirmed ||
            !isDirect ||
            isCSA ||
            [COUNTRIES.UNITED_KINGDOM, COUNTRIES.AUSTRALIA, COUNTRIES.NEW_ZEALAND].includes(country) ||
            today.isSameOrAfter(moment(balanceDueDate))
          ) {
            return [];
          }
          return [
            {
              ...scheduleFinalPaymentNotification,
              button: {
                ...scheduleFinalPaymentNotification.button,
                onButtonClick: goTo(APP_PATHS.SCHEDULE_PAYMENT),
              },
              clickable: true,
            },
          ];
        }
      )
    ),

    getSoldOutModalData: new Selector(() =>
      createSelector(
        [getLabels],
        ({ buttons: { save, cancel, accept, manage, saveAndAccept } = {}, generic: { on, off, necessary } = {} }) => {
          return {
            labels: {
              on,
              off,
              necessary,
            },
            buttons: {
              save,
              cancel,
              accept,
              manage,
              saveAndAccept,
            },
          };
        }
      )
    ),
    getHomePageTravelDetails: new Selector(() =>
      createSelector(
        [getBookingDetailsContent, (state) => getPageTabLabels(state)(PAGE_NAMES.HOME), getDaysToGo],
        (bookingDetails, { labels }, daysToGoValue) => {
          const getDetail = (reference, valueRegex) => ({
            title: labels[reference],
            value: getCmsLabel(bookingDetails.details, valueRegex, 'value'),
          });

          const direction = getDetail('direction', /itinerary/g);
          const dates = getDetail('dates', /cruiseDate/g);
          const guests = getDetail('guestsLabel', /guests/g);
          const bookingNumber = getDetail('bookingNumber', /bookingNumber/g);
          const daysToGo = {
            title: get(bookingDetails, 'daysToGoLabel', labels.daysToGo),
            value: daysToGoValue,
          };

          const travel = {
            direction,
            dates,
            guests,
            bookingNumber,
            daysToGo,
          };
          return travel;
        }
      )
    ),
  },
});

export const fetchHomePageContent = () => (dispatch, getState) => {
  const { receiveContent } = homeStore.creators;
  const bookings = getBookingDetails(getState());
  const { office, currency, voyage: { type = 'river' } = {} } = bookings;

  const country = decodeCountryCodeFromCurrency(currency) || office || COUNTRIES.UNITED_STATES;
  const queryParams = { country, voyageType: type };

  dispatch(
    getData({
      url: buildUrl('/start/homeContent', ['type'], { type }, queryParams),
      store: homeStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export default homeStore;
