import Button from '@viking-eng/button';
import Heading from '@viking-eng/heading';
import Image from '@viking-eng/image';
import { prepareHtml } from '@viking-eng/utils';
import get from 'lodash/get';
import React from 'react';
import { Modal } from '..';
import { ONE_BY_ONE } from '../../common/Constants';
import { getImageAttributes } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './MobileApp.scss';

class MobileAppModal extends React.Component {
  renderBodyCopy() {
    const { sections } = this.props;
    const renderCard = (card) => (
      <div className="row no-gutters" key={card.id}>
        <Heading title={card.title} />
        <div dangerouslySetInnerHTML={prepareHtml(card.detailedDescription)} />
      </div>
    );
    return sections.map((section, i) => {
      const index = `section${i}`;
      return (
        <div key={index}>
          {section.title && <div className="section-title">{section.title}</div>}
          {section.cards.map(renderCard)}
        </div>
      );
    });
  }

  renderImage() {
    const { sections, images } = this.props;
    const image = images?.[0] ? getImageAttributes({ image: images[0] }) : { mediaUrl: '', alt: '' };
    return sections.map(({ cards }) =>
      cards.map(({ id }) => (
        <Image key={id} {...image} id={id} className="img-fluid image" ratio={get(images, '[0].type', ONE_BY_ONE)} />
      ))
    );
  }

  renderButtons() {
    const { sections } = this.props;
    const openUrl = (url) => () => {
      window.open(url, '_blank');
    };
    return (
      <>
        {sections.map(({ buttons }) =>
          buttons.map(({ callToActionUrl, images }) => {
            const image = images?.[0] && { ...images[0], src: images[0].url };
            return (
              <Button
                appearance="link"
                onButtonClick={openUrl(callToActionUrl)}
                className="store-button"
                key={callToActionUrl}
              >
                <Image {...image} className="store-img" />
              </Button>
            );
          })
        )}
      </>
    );
  }

  render() {
    const { id, title, subTitle, printLabel } = this.props;
    return (
      <Modal id={id} printLabel={printLabel} title={title} subTitle={subTitle}>
        {title && (
          <div className="app-modal">
            <div className="mvj-modal">
              <div className="row no-gutters">
                <div className="col center-header">
                  <Heading title={title} subTitle={subTitle} />
                </div>
              </div>
              <div className="voyager-app-details row no-gutters d-flex justify-content-between">
                <div className="col-sm-5 col-12 app-image">{this.renderImage()}</div>
                <div className="col-sm-7 col-12">
                  {this.renderBodyCopy()}
                  <div className="row no-gutters buttons justify-content-sm-start justify-content-center">
                    {this.renderButtons()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

MobileAppModal.propTypes = {
  ...PropTypes.modalData,
};

MobileAppModal.defaultProps = {};

export default MobileAppModal;
