import BannerNotification from '@viking-eng/banner-notification';
import PaymentMethodRow from '@viking-eng/payment-method-row';
import React from 'react';

import './PaymentMethods.scss';

import { APP_PATHS, MODALS, PAYMENT_ACTION_TYPE } from '../../../common/Constants';
import { goTo } from '../../../common/Utils';
import { VouchersCredits } from '../../../components';
import { DialogModal } from '../../../modals';
import PropTypes from '../../../PropTypes';

const dialogModalId = MODALS.DIALOG;

const PaymentMethods = ({
  paymentMethods,
  removePaymentMethodModalData,
  openRemovalModal,
  handleRemovePaymentOption,
  handleCancelPaymentOptionRemove,
  isSubmitting,
  lockedDown,
  isPaymentsDisabled,
  paymentsDisabledBanner,
}) => {
  let mappedPaymentMethods = paymentMethods;
  if (paymentMethods) {
    mappedPaymentMethods = paymentMethods.map((pm) => ({
      ...pm,
      items: pm.items.map((item) => ({
        ...item,
        buttons: item.actionButtons.map((button) => {
          let attributes;
          switch (button.action) {
            case PAYMENT_ACTION_TYPE.ACH_EDIT:
              attributes = {
                onButtonClick: goTo(`${APP_PATHS.PAYMENTS}/payment-methods/ach/${item.id}`),
                disabled: lockedDown,
              };
              break;
            case PAYMENT_ACTION_TYPE.REMOVE:
              attributes = {
                onButtonClick: () => openRemovalModal(dialogModalId, item.id),
                disabled: lockedDown,
              };
              break;
            default:
              break;
          }

          return {
            ...button,
            attributes,
          };
        }),
      })),
    }));
  }

  return (
    <div className="payment-methods">
      {isPaymentsDisabled && (
        <div className="row">
          <div className="container banner-wrapper payment-alert-container">
            <BannerNotification
              alert={{
                alertText: paymentsDisabledBanner,
              }}
              icon="exclamation-mark-filled"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          {paymentMethods &&
            mappedPaymentMethods.map(({ paymentMethodsValues, items }) => (
              <div className="payment-method">
                <PaymentMethodRow rowHeader={paymentMethodsValues} savedItems={items} />
              </div>
            ))}
          <div className="payment-method">
            <VouchersCredits />
          </div>
        </div>
        <DialogModal
          id={dialogModalId}
          onConfirm={handleRemovePaymentOption}
          onCancel={handleCancelPaymentOptionRemove}
          forceAction={isSubmitting}
          {...removePaymentMethodModalData}
        />
      </div>
    </div>
  );
};

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      rowHeader: PropTypes.paymentMethodRowHeader,
      savedItems: PropTypes.arrayOf(PropTypes.paymentMethodSavedItem),
    })
  ),
  openRemovalModal: PropTypes.func.isRequired,
  handleRemovePaymentOption: PropTypes.func.isRequired,
  handleCancelPaymentOptionRemove: PropTypes.func.isRequired,
  removePaymentMethodModalData: PropTypes.shape({
    ...PropTypes.dialogModalFields,
  }),
  isSubmitting: PropTypes.bool,
  lockedDown: PropTypes.bool.isRequired,
  lockDownMessage: PropTypes.string.isRequired,
};

PaymentMethods.defaultProps = {
  paymentMethods: [],
  removePaymentMethodModalData: {},
  isSubmitting: false,
};

export default PaymentMethods;
