export const liveChatUpdate = (userBookingData) => {
  const LP_PRODUCTS = {
    BOOKING_NUMBER: 'Booking Number',
    CART_ITEM: 'Cart Item',
    GUEST_CCHID: 'Customer (cchid)',
    GUEST_NAME: 'Customer Name',
    GUEST_STEP: 'Guest Step',
    VOYAGE_ID: 'SailingId',
  };

  const addProduct = (name, value, products) => {
    if (name) {
      const category = value || '-';
      products.push({
        product: {
          name,
          category,
        },
      });
    }
  };

  const getPaymentCartTotal = (cart) => {
    let totalPrice = 0;
    for (let i = 0; i < cart.length; i++) {
      totalPrice += cart[i].totalItemPrice;
    }
    return totalPrice;
  };

  const getPaymentCartSDES = (cartItems) => {
    const paymentCart = [];
    for (let i = 0; i < cartItems.length; i++) {
      paymentCart.push(formatPaymentCartItem(cartItems[i]));
    }
    return paymentCart;
  };

  const formatPaymentCartItem = (item) => {
    const product = {
      product: {
        name: 'Payments & Cart',
        category: item.title,
        price: item.totalItemPrice,
      },
    };
    return product;
  };

  const chatCartData = {
    type: 'cart',
    total: 0,
    numItems: 0,
    products: [],
  };

  addProduct(LP_PRODUCTS.BOOKING_NUMBER, userBookingData?.bookingNumber, chatCartData.products);
  addProduct(LP_PRODUCTS.VOYAGE_ID, userBookingData?.voyage?.id, chatCartData.products);
  userBookingData?.passengers?.forEach((val) => {
    addProduct(LP_PRODUCTS.GUEST_CCHID, val.cchid, chatCartData.products);
    addProduct(LP_PRODUCTS.GUEST_NAME, `${val.firstName} ${val.lastName}`, chatCartData.products);
  });
  userBookingData?.cartItems?.forEach((val) => {
    addProduct(LP_PRODUCTS.CART_ITEM, `${val.type} - ${val.name}`, chatCartData.products);
  });

  addProduct(LP_PRODUCTS.GUEST_STEP, userBookingData?.guestStep, chatCartData.products);

  if (userBookingData?.paymentCart?.items?.length > 0) {
    let paymentCart = [];
    chatCartData.total = getPaymentCartTotal(userBookingData?.paymentCart?.items || []);
    paymentCart = getPaymentCartSDES(userBookingData?.paymentCart?.items || []);
    chatCartData.numItems = paymentCart.length;

    for (let i = 0; i < paymentCart.length; i++) {
      chatCartData.products.push(paymentCart[i]);
    }
  }

  try {
    window.lpTag.newPage(window.location.href, {
      sdes: [
        {
          type: chatCartData.type,
          total: chatCartData.total,
          numItems: chatCartData.numItems,
          products: chatCartData.products,
        },
      ],
    });
  } catch (error) {
    // do not remove
  }
};
