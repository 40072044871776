import merge from 'lodash/merge';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, isSubmitting, reduxForm, SubmissionError } from 'redux-form';
import commonStore from '../../../common';
import {
  ADDONS_CHECKOUT,
  APP_PATHS,
  CREDIT_CARD_TYPE,
  FORMS,
  MODALS,
  PAGE_NAMES,
  TAB_NAMES,
  TIMER_STATUS,
} from '../../../common/Constants';

import modalStore, { closeModalAndNavigate, setViewAndShowModal } from '../../../common/ModalStore';
import userStore, { fetchInvoicePricing } from '../../../common/UserStore';
import { withContent } from '../../../components';
import paymentsStore, {
  fetchPaymentChallengeResponse,
  fetchPaymentMethods,
  fetchTabContent,
  getPaymentMethod,
  getPaymentSubmitDisabled,
  getPaymentSummarySubmitDisabled,
  getTabNameFromCheckoutType,
  mapAndSend,
  postChallengeFailed,
  updatePaymentMethodForm,
  updateUserData,
} from '../PaymentsStore';
import { tokenize } from '../TokenEx';
import PaymentCheckout from './PaymentCheckout';

const {
  setCheckoutError,
  receiveTabContent,
  receivePaymentChallenge,
  setPaymentProcessingTimer,
} = paymentsStore.creators;
const {
  getTabContent,
  getTabTitle,
  getAccountECheckFields,
  getCreditCardFields,
  getPaymentCheckoutInitialValues,
  getCruisePaymentCheckoutSummary,
  getCartCheckoutSummary,
  getPaymentMethodOptions,
  getDefaultSavedAccount,
  getPaymentAmountOptions,
  getPaymentCheckoutBackButton,
  getPaymentFailedNotificationModalData,
  getTokenExMetaData,
  getMinDueAmount,
  isLoadingTabs,
  getCheckoutError,
  getTabContentSections,
  getCardTypeHasError,
  getIsTokenExValid,
  getUkPaymentsMessage,
  getIsTokenExLoaded,
  getIsTokenExRefreshed,
  getChallengeModalData,
  getIsPsd2,
  getChallengeTimeoutModalData,
  getIsCreditCardAvailable,
} = paymentsStore.selectors;

const { getPageTabLabels } = commonStore.selectors;

const { setInvoicePricingLoading } = userStore.creators;
const { getCountryCodeFromCurrency, getTotalDue, getInvoicePricingLoading, getUpdateUserData } = userStore.selectors;

const { clearModal } = modalStore.creators;

const formName = FORMS.PAYMENT_CHECKOUT;

const { ADDONS } = TAB_NAMES;
const { PAYMENTS_CART } = PAGE_NAMES;

const initialValues = {
  account: {
    save: false,
  },
};

const mapStateToProps = (state, { location: { pathname } = {}, type }) => {
  const tabName = getTabNameFromCheckoutType(type);
  const {
    tabs: { cruisePayments, addons },
  } = getPageTabLabels(state)(PAYMENTS_CART);

  const country = formValueSelector(formName)(state, 'account.country') || getCountryCodeFromCurrency(state);
  const { creditCardFields } = getCreditCardFields(state)({ tabName, country });
  const { accountECheckFields } = getAccountECheckFields(state)(tabName);
  const isTokenExLoaded = getIsTokenExLoaded(state);
  const isTokenExValid = getIsTokenExValid(state);
  const isTokenExRefreshed = getIsTokenExRefreshed(state);
  const tokenExMetaData = getTokenExMetaData(state);
  const { cardType } = tokenExMetaData;

  const savedAccount = formValueSelector(formName)(state, 'savedAccount');
  const paymentMethod = formValueSelector(formName)(state, 'paymentMethod');
  const paymentAmountOption = formValueSelector(formName)(state, 'paymentAmountOption');
  const validPaymentAmountOptions = Object.values(getPaymentAmountOptions(state) || []).map(({ value }) => value);

  const updateUserInfo = getUpdateUserData(state);

  const paymentAmountSelected =
    type === ADDONS_CHECKOUT ? true : validPaymentAmountOptions?.includes(paymentAmountOption);

  const paymentCheckoutSummary =
    type === ADDONS_CHECKOUT ? getCartCheckoutSummary(state) : getCruisePaymentCheckoutSummary(state);
  const { noPaymentDescription, button } = paymentCheckoutSummary;
  const ukPaymentsMessage = getUkPaymentsMessage(state)(type);
  const pageContent = getTabContent(state)(tabName);
  const cardTypeValid = getIsCreditCardAvailable(state)(cardType);

  const disableSubmit = getPaymentSubmitDisabled({
    cardTypeValid,
    isTokenExValid,
    savedAccount,
    paymentMethod,
    noPaymentDescription,
    ukPaymentsMessage,
    updateUserInfo,
  });

  const disableSummarySubmit =
    disableSubmit ||
    getPaymentSummarySubmitDisabled({
      button,
      cardType,
      isTokenExLoaded,
      isTokenExValid,
      noPaymentDescription,
      paymentAmountSelected,
      paymentMethod,
      savedAccount,
    });

  return {
    accountECheckFields,
    backButton: getPaymentCheckoutBackButton(state)(pathname),
    ccType: Object.values(CREDIT_CARD_TYPE).find((cc) => cc.tokenExCode === cardType)?.code || '',
    country,
    creditCardFields,
    disableSubmit,
    disableSummarySubmit,
    isLoading: isLoadingTabs(state) || getInvoicePricingLoading(state),
    isTokenExLoaded,
    isTokenExValid,
    isTokenExRefreshed,
    labels:
      type === ADDONS_CHECKOUT
        ? {
            accountInformation: addons.labels.selectAccount,
            paymentMethod: addons.labels.paymentMethod,
          }
        : {
            accountInformation: cruisePayments.labels.selectAccount,
            paymentAmount: cruisePayments.labels.paymentAmount,
            paymentMethod: cruisePayments.labels.paymentMethod,
          },
    paymentAmountOption: formValueSelector(formName)(state, 'paymentAmountOption'),
    paymentAmountSelected,
    paymentCheckoutSummary,
    paymentFailedNotificationModalData: getPaymentFailedNotificationModalData(state)(type),
    paymentMethod,
    savedAccount,
    savedAccounts: getPaymentMethodOptions(state)(tabName)[paymentMethod],
    title: getTabTitle(state)(tabName),
    ukPaymentsMessage,
    updateUserInfo,

    // validation
    cardTypeError: getCardTypeHasError(state),
    minDueAmount: getMinDueAmount(state),
    totalDueAmount: getTotalDue(state),

    // redux-form
    content: pageContent,
    initialValues: merge(initialValues, getPaymentCheckoutInitialValues(state)(type)),
    isSubmitting: isSubmitting(FORMS.PAYMENT_CHECKOUT)(state),

    // paymentChallenge
    challengeModalData: getChallengeModalData(state)(type),
    challengeTimeoutModalData: getChallengeTimeoutModalData(state)(type),
    isPsd2: getIsPsd2(state),
  };
};

const handlePaymentMethodChange = (value) => (dispatch, getState) => {
  const tabName = getTabNameFromCheckoutType();
  const paymentMethod = getDefaultSavedAccount(getState())(value, tabName);
  dispatch(change(formName, 'account', initialValues.account));
  return dispatch(change(formName, 'savedAccount', paymentMethod));
};

const handleOnClose = (type, step, modalId) => (dispatch, getState) => {
  const state = getState();
  // TODO: Verify Error paths work properly
  const { errorCode } = getCheckoutError(state);

  if (['408', '00408'].includes(errorCode)) {
    dispatch(setPaymentProcessingTimer(TIMER_STATUS.INIT));
    dispatch(closeModalAndNavigate(type === ADDONS_CHECKOUT ? APP_PATHS.CART : APP_PATHS.CRUISE_PAYMENTS));
    return null;
  }

  if (modalId === MODALS.PAYMENT_CHALLENGE) {
    return null;
  }
  if (modalId === MODALS.CHALLENGE_TIMEOUT) {
    dispatch(receivePaymentChallenge({}));
    dispatch(closeModalAndNavigate(type === ADDONS_CHECKOUT ? APP_PATHS.CART : APP_PATHS.CRUISE_PAYMENTS));
    return null;
  }
  if (!modalId) {
    if (['1508', '409'].includes(errorCode)) {
      dispatch(receiveTabContent({}, TAB_NAMES.ADDONS));
      dispatch(closeModalAndNavigate(APP_PATHS.CART));
    } else {
      dispatch(fetchPaymentMethods());
      dispatch(fetchTabContent(type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking'));
      dispatch(fetchTabContent(ADDONS));
    }
  }
  dispatch(receivePaymentChallenge({}));
  dispatch(clearModal());
  dispatch(setCheckoutError({}));
  return null;
};

const handleFetchContent = (type) => (dispatch, getState) => {
  const isAddonsLoaded = getTabContentSections(getState())(ADDONS);
  dispatch(fetchPaymentMethods());
  dispatch(fetchTabContent(type === ADDONS_CHECKOUT ? 'checkoutCart' : 'checkoutBooking'));
  if (isAddonsLoaded) {
    dispatch(setInvoicePricingLoading(true));
    dispatch(fetchTabContent(ADDONS)).then((cartResponse) => {
      dispatch(fetchInvoicePricing(cartResponse?.items));
    });
  }
};

const mapDispatchToProps = (dispatch, { type }) => ({
  fetchContent: () => dispatch(handleFetchContent(type)),
  handleOnClose: (step, modalId) => dispatch(handleOnClose(type, step, modalId)),
  handlePaymentMethodChange: (value) => dispatch(handlePaymentMethodChange(value)),
  handleNameOnAccountChange: (passengerId) => dispatch(updateUserData(formName, passengerId, 'account')),
  handleAccountChange: (savedAccount, paymentMethod) =>
    dispatch(updatePaymentMethodForm(savedAccount, formName, 'account', paymentMethod, initialValues)),
  handleChallengeOnCancel: () => {
    dispatch(postChallengeFailed());
    dispatch(receivePaymentChallenge({}));
    dispatch(clearModal());
  },
  handleOnSuccess: () => {
    dispatch(clearModal());
    dispatch(fetchPaymentChallengeResponse());
  },
  handleTimeout: (interval) => {
    clearInterval(interval);
    dispatch(clearModal());
    dispatch(receivePaymentChallenge({}));
    dispatch(setViewAndShowModal(MODALS.CHALLENGE_TIMEOUT));
    dispatch(postChallengeFailed());
  },
  handleModalOpen: (id) => dispatch(setViewAndShowModal(id, { id })),
  refetchContent: () => dispatch(fetchTabContent(ADDONS)),
});

const onSubmit = (values, dispatch, { type, ccType, cardTypeError, challengeModalData }) => {
  if (cardTypeError) {
    return Promise.reject(new SubmissionError({ _error: 'CardTypeInvalid' }));
  }
  const bookingCountry = dispatch((_, getState) => getCountryCodeFromCurrency(getState()));

  const promise = new Promise((resolve, reject) => {
    const rejectPromise = (message) => {
      if (message && message.errorCode) {
        dispatch(setCheckoutError(message));
        reject(new SubmissionError({ _error: message.errorDescription }));
      }
      reject(new SubmissionError({ _error: message }));
    };

    if (challengeModalData?.challengeResponse?.transStatus) {
      const { transStatus } = challengeModalData.challengeResponse;
      if (transStatus === 'N') {
        dispatch(postChallengeFailed())
          .then(() => {
            return rejectPromise('CHALLENGE FAILED');
          })
          .catch(reject);
        return;
      }
    }

    const {
      account: { token },
    } = values;
    const paymentMethod = getPaymentMethod(values);
    const submit = (tokenValue) => {
      dispatch(
        mapAndSend(
          {
            ...values,
            cardType: values.cardType || ccType,
            bookingCountry,
          },
          tokenValue,
          type,
          resolve,
          rejectPromise
        )
      )
        .then(resolve)
        .catch(reject);
    };

    if (values.noPaymentRequired) {
      submit();
    } else if (!token) {
      dispatch(tokenize(paymentMethod)).then((tokenExData) => {
        if (!tokenExData) {
          rejectPromise('Failed to generate token');
          return;
        }
        submit(tokenExData.token);
      });
    } else {
      submit(token);
    }
  });

  return promise.catch(() => {
    dispatch(setViewAndShowModal('failedNotificationModal'));
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: formName,
    onSubmit,
  })
);

export default enhance(PaymentCheckout);
