import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import get from 'lodash/get';
import React from 'react';
import WheelchairTooltip from '../../components/wheelchairTooltip/WheelchairTooltip';

import PropTypes from '../../PropTypes';
import { goTo } from '../../common/Utils';

const CallToAction = ({ attributes = {}, buttonContent, shouldRenderIcon, isPending, target }) => (
  <div className="cta-wrapper">
    <span className="booking-link-text">
      <Button appearance="text-with-icon-left" attributes={attributes} onButtonClick={goTo(target)}>
        {buttonContent}
        {shouldRenderIcon && !isPending && (
          <span className="booking-link-image" {...attributes}>
            <Icon name="open-link" />
          </span>
        )}
      </Button>
    </span>
  </div>
);

CallToAction.propTypes = {
  attributes: PropTypes.shape(),
  buttonContent: PropTypes.string,
  shouldRenderIcon: PropTypes.bool,
  isPending: PropTypes.bool,
};

CallToAction.defaultProps = {
  attributes: {},
  buttonContent: null,
  shouldRenderIcon: false,
  isPending: false,
};

const BookingDetail = ({ heading, value, link, attributes = {}, reference, isPending, isADAAccessible, adaText }) => {
  if (!value || !value.length) {
    return null;
  }

  const detail = typeof value === 'string' ? [value] : value;
  const deDupedDetail = detail.reduce((acc, item, index) => {
    const itemName = typeof item === 'string' ? item : get(item, 'value', '');
    // Do not remove duplicate guest names
    if (heading && heading.match(/guests/gi)) {
      acc.push({ itemName });
    } else if (!acc.find((entry) => entry.itemName === itemName)) {
      acc.push({ itemName, attributeIndex: index, lineLink: get(item, 'lineLink', '') });
    }
    return acc;
  }, []);

  const shouldRenderIcon = (lineLink, attributeIndex) => {
    if (['ship', 'stateroom'].includes(reference) || attributeIndex === 0 || lineLink) {
      return true;
    }
    return false;
  };

  return (
    <div className="row">
      <div className="col-md-4 col-sm-4 col-lg-3 heading small-body-copy">{heading}</div>
      <div className="col-md-8 col-sm-8 col-lg-9 detail medium-body-copy">
        {deDupedDetail.map(({ itemName, lineLink, attributeIndex }) => {
          const attrs = attributes && attributes[attributeIndex];
          const target = link || lineLink;
          const cta = (
            <CallToAction
              buttonContent={itemName}
              attributes={attrs}
              shouldRenderIcon={shouldRenderIcon(lineLink, attributeIndex)}
              isPending={isPending}
              target={target}
            />
          );
          return (
            <div key={itemName}>
              {target || attrs ? cta : <span>{itemName}</span>}
              {isADAAccessible && <WheelchairTooltip tooltipText={adaText} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

BookingDetail.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  link: PropTypes.string,
  reference: PropTypes.string,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      'data-toggle': PropTypes.string,
      'data-target': PropTypes.string,
      'data-dismiss': PropTypes.string,
    })
  ),
  isPending: PropTypes.bool,
  isADAAccessible: PropTypes.bool,
  adaText: PropTypes.string,
};

BookingDetail.defaultProps = {
  heading: null,
  value: null,
  link: null,
  attributes: null,
  reference: null,
  isPending: false,
  isADAAccessible: false,
  adaText: '',
};

export default BookingDetail;
