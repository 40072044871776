import { connect } from 'react-redux';
import { compose } from 'redux';
import { modalStore } from '../../common';
import { triggerLinkEvent } from '../../common/Analytics';
import { USER_TYPES } from '../../common/Constants';
import { setViewAndShowModal } from '../../common/ModalStore';
import userStore from '../../common/UserStore';
import { withContent } from '../../components';
import Notifications from './Notifications';
import notificationsStore, {
  dismissNotification,
  fetchNotificationsPageContent,
  viewedNotification,
} from './NotificationsStore';

const { getBaseTrackingData, getUserType, getIsTaAccount } = userStore.selectors;

const { clearModal } = modalStore.creators;
const { isLoadingPage, getNotificationsPageContent, getTitle } = notificationsStore.selectors;

const mapStateToProps = (state) => {
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);
  const pageContent = getNotificationsPageContent(state);
  const baseTrackingData = getBaseTrackingData(state);
  return {
    ...pageContent,
    title: getTitle(state),
    isLoading: isLoadingPage(state),
    hasPreHeader: isTaAccount || userType === USER_TYPES.CSA,
    baseTrackingData,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  dismissNotification: (alertCode, voyageId) => dispatch(dismissNotification(alertCode, voyageId)),
  viewedNotification: (alertCode, voyageId) => dispatch(viewedNotification(alertCode, voyageId)),
  fetchContent: () => dispatch(fetchNotificationsPageContent()),
  openModal: (type, id) => {
    triggerLinkEvent({
      event: 'modal_view',
      modal_type: 'promo_notification',
      modal_name: id,
      timestamp: Date.now(),
      ...props?.baseTrackingData,
    });
    dispatch(setViewAndShowModal(type, { id }));
  },
  closeModal: (id) => {
    triggerLinkEvent({
      event: 'modal_close',
      modal_type: 'promo_notification',
      modal_name: id,
    });
    dispatch(clearModal({ tracking: true }));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Notifications);
