import BannerNotification from '@viking-eng/banner-notification';
import React from 'react';
// import { AirPlusModal, ComparisonModal } from '../../../modals';
import { history } from '../../../common';
import { prepareHtml } from '../../../common/Utils';
import PropTypes from '../../../PropTypes';
import './airNotAvailable.scss';

const AirNotAvailable = ({
  airAvailableDateMessage,
  thankYouMessage,
  airAvailableActionsMessage,
  readOnlyFlight,
  readOnlyThankYouMessage,
  rightRezFlight,
  airAvailableDateMessageRightRez,
  noVikingAir,
  airNoVikingAirCopy,
  airNoVikingAirHeader,
  isLoading,
  noGIF,
  noGIFOverride,
  cruiseOnlyNoGIF,
  incompleteGIFText,
  incompleteGIFLongText,
  incompleteGIFBtn,
  noAirNoBookingPaymentMessage,
  noAirNoBookingPaymentBtn,
  noAirNoPayment,
  hidePaymentBanner,
}) => {
  if (!isLoading && noGIF && !noGIFOverride && !cruiseOnlyNoGIF && incompleteGIFText) {
    return (
      <>
        <div className="air-no-guest-information">
          <BannerNotification
            alert={{
              alertText: '',
              button: incompleteGIFBtn,
              alertEndText: incompleteGIFText,
              linkAlert: true,
              alertType: 'air',
            }}
            icon="exclamation-mark-filled"
            history={history}
          />
        </div>
        <div className="row">
          <div className="col-12 text" dangerouslySetInnerHTML={prepareHtml(incompleteGIFLongText)} />
        </div>
      </>
    );
  }
  if (!isLoading && (noVikingAir || noAirNoPayment) && airNoVikingAirCopy) {
    return (
      <>
        {noAirNoPayment && noAirNoBookingPaymentMessage && !hidePaymentBanner && (
          <BannerNotification
            alert={{
              alertText: noAirNoBookingPaymentMessage,
              button: noAirNoBookingPaymentBtn,
              alertEndText: '',
              linkAlert: true,
              alertType: 'air',
            }}
            icon="exclamation-mark-filled"
            history={history}
          />
        )}
        <div className="no-viking-air">
          {airNoVikingAirHeader && <h5 dangerouslySetInnerHTML={prepareHtml(airNoVikingAirHeader)} />}
          <div dangerouslySetInnerHTML={prepareHtml(airNoVikingAirCopy)} />
        </div>
      </>
    );
  }
  // eslint-disable-next-line max-len
  const thankYouText = !readOnlyFlight && !rightRezFlight ? thankYouMessage : readOnlyThankYouMessage;
  const airAvailableDateText = rightRezFlight ? airAvailableDateMessageRightRez : airAvailableDateMessage;

  return (
    <>
      {!isLoading && (
        <>
          {airAvailableDateText && (
            <BannerNotification
              alert={{
                alertText: airAvailableDateText,
              }}
              icon="calendar"
              history={history}
            />
          )}
          <div className="thank-you-header" dangerouslySetInnerHTML={prepareHtml(thankYouText)} />
          {!readOnlyFlight && !rightRezFlight && !noVikingAir && (
            <>
              <div dangerouslySetInnerHTML={prepareHtml(airAvailableActionsMessage)} />
            </>
          )}
        </>
      )}
    </>
  );
};
AirNotAvailable.propTypes = {
  airAvailableDateMessage: PropTypes.string,
  thankYouMessage: PropTypes.string,
  airAvailableActionsMessage: PropTypes.string,
  readOnlyThankYouMessage: PropTypes.string,
  readOnlyFlight: PropTypes.bool,
  rightRezFlight: PropTypes.bool,
  noVikingAir: PropTypes.bool,
  airAvailableDateMessageRightRez: PropTypes.string,
  airNoVikingAirCopy: PropTypes.string,
  airNoVikingAirHeader: PropTypes.string,
  isLoading: PropTypes.bool,
  incompleteGIFText: PropTypes.string,
  incompleteGIFLongText: PropTypes.string,
  incompleteGIFBtn: PropTypes.shape(),
  noGIF: PropTypes.bool,
  noGIFOverride: PropTypes.bool,
  cruiseOnlyNoGIF: PropTypes.bool,
  noAirNoPayment: PropTypes.bool,
  noAirNoBookingPaymentMessage: PropTypes.string,
  hidePaymentBanner: PropTypes.bool,
};

AirNotAvailable.defaultProps = {
  airAvailableDateMessage: '',
  thankYouMessage: '',
  airAvailableActionsMessage: '',
  readOnlyThankYouMessage: '',
  readOnlyFlight: false,
  rightRezFlight: false,
  noVikingAir: false,
  airAvailableDateMessageRightRez: '',
  airNoVikingAirCopy: '',
  airNoVikingAirHeader: '',
  isLoading: true,
  hidePaymentBanner: false,
  incompleteGIFText: '',
  incompleteGIFLongText: '',
  incompleteGIFBtn: null,
  noGIF: false,
  noGIFOverride: false,
  cruiseOnlyNoGIF: false,
  noAirNoPayment: false,
  noAirNoBookingPaymentMessage: '',
};
AirNotAvailable.displayName = 'AirNotAvailable';

export default AirNotAvailable;
