import { Slider } from '@material-ui/core';
import Button from '@viking-eng/button';
import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import Spinner from '@viking-eng/spinner';
import { prepareHtml } from '@viking-eng/utils';
import Compressor from 'compressorjs';
import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Form } from 'redux-form';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import {
  MODALS,
  PHOTO_UPLOAD_CONVERT_TYPE,
  PHOTO_UPLOAD_CONVERT_WIDTH,
  PHOTO_UPLOAD_CROP_SIZE,
  PHOTO_UPLOAD_EXTENSIONS,
  PHOTO_UPLOAD_MAX_SIZE,
  PHOTO_UPLOAD_MIME_TYPES,
  PHOTO_UPLOAD_STEPS,
  PHOTO_UPLOAD_ZOOM_DEFAULT,
  PHOTO_UPLOAD_ZOOM_MAX,
  PHOTO_UPLOAD_ZOOM_MIN,
} from '../../common/Constants';
import './PhotoUploadModal.scss';

const PhotoUploadModal = ({
  backLabel,
  cropAndSubmitLabel,
  cropSizeHeight,
  cropSizeWidth,
  formStep,
  getStartedLabel,
  handleCloseModal,
  handlePhotoConvert,
  handlePhotoSubmit,
  handlePhotoValidation,
  handleRedirectToCropModal,
  handleRedirectToSuccessModal,
  handleRedirectToUploadModal,
  handleSubmit,
  id,
  isImageUnknownError,
  modalData,
  openModal,
  passengerNumber,
  passengerPhotoConverted,
  returnToCheckInLabel,
  rotateLabel,
  uploadFromDeviceLabel,
  uploadNewPhotoLabel,
  zoomLabel,
}) => {
  const photoUploadModal = MODALS.PHOTO_UPLOAD_MODAL;

  const defaultPhotoState = {
    aspect: 1,
    crop: { x: 0, y: 0 },
    cropSize: { width: cropSizeWidth, height: cropSizeHeight },
    isImageFormatError: false,
    imageName: null,
    imageSrc: passengerPhotoConverted || null,
    isImageDimensionsAcceptable: true,
    isImageSizeAcceptable: true,
    isLoaded: false,
    zoom: PHOTO_UPLOAD_ZOOM_DEFAULT,
  };

  const [photoState, setPhotoState] = useState(defaultPhotoState);

  useEffect(() => {
    if (passengerPhotoConverted) {
      setPhotoState({ ...photoState, imageSrc: passengerPhotoConverted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengerPhotoConverted]);

  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  // Note: Required for large files on Mobile Safari
  // See: https://github.com/ricardo-ch/react-easy-crop/issues/91#issuecomment-766167263
  const getNormalizedFile = (file) => {
    return new Promise((resolve, reject) => {
      /* eslint-disable no-new */
      new Compressor(file, {
        maxWidth: 1000,
        maxHeight: 1000,
        success(normalizedFile) {
          resolve(normalizedFile);
        },
        error(error) {
          reject(error);
        },
      });
    });
  };

  const onSelectFile = async (event) => {
    const image = event.target.files[0];
    const isImageSizeAcceptable = image.size <= PHOTO_UPLOAD_MAX_SIZE;
    const imageName = image.name.toLowerCase();
    const imageExtension = imageName.substring(imageName.lastIndexOf('.'));
    const acceptedFormat = PHOTO_UPLOAD_EXTENSIONS.includes(imageExtension.toLowerCase());
    const isImageFormatError = !(event.target.files.length > 0 && acceptedFormat);
    setPhotoState({
      ...photoState,
      imageSrc: null,
      isImageFormatError,
      isLoaded: false,
      imageName: image.name,
      isImageSizeAcceptable,
      isImageDimensionsAcceptable: true,
    });
    if (!isImageFormatError && isImageSizeAcceptable) {
      if (imageExtension === '.heic') {
        handlePhotoConvert(
          image,
          PHOTO_UPLOAD_CONVERT_WIDTH,
          PHOTO_UPLOAD_CONVERT_TYPE,
          passengerNumber,
          modalData.imageUnknownError
        );
      } else {
        const imageDataUrl = await readFile(image);
        setPhotoState((prevPhotoState) => ({
          ...prevPhotoState,
          imageSrc: imageDataUrl,
        }));
        handleRedirectToCropModal();
      }
    }
  };

  const onCropBack = (args) => {
    setPhotoState({
      ...photoState,
      isImageFormatError: false,
      isImageDimensionsAcceptable: true,
      isImageSizeAcceptable: true,
      isLoaded: false,
      zoom: PHOTO_UPLOAD_ZOOM_DEFAULT,
    });
    handleRedirectToUploadModal(args);
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (photoState.imageSrc) {
      const croppedImage = await getCroppedImg(photoState.imageSrc, croppedAreaPixels, photoState.rotation);
      setPhotoState({ ...photoState, croppedAreaPixels, croppedImage });
    } else {
      setPhotoState({ ...photoState, croppedAreaPixels });
    }
  };

  // HACK: Cropper starts bouncing around without this debounce
  const onZoomChange = _debounce((zoom) => {
    zoom = Math.round(zoom * 10) / 10;
    setPhotoState({ ...photoState, zoom });
  }, 200);

  const onRotationChange = _debounce((rotation) => {
    setPhotoState({ ...photoState, rotation });
  }, 200);

  const onZoomUp = () =>
    photoState.zoom + PHOTO_UPLOAD_ZOOM_MIN <= PHOTO_UPLOAD_ZOOM_MAX &&
    onZoomChange(photoState.zoom + PHOTO_UPLOAD_ZOOM_MIN);

  const onZoomDown = () =>
    photoState.zoom - PHOTO_UPLOAD_ZOOM_MIN >= PHOTO_UPLOAD_ZOOM_MIN &&
    onZoomChange(photoState.zoom - PHOTO_UPLOAD_ZOOM_MIN);

  const getZoomDisabledClass = (value) => (Math.round(zoom * 10) / 10 === value ? ' disabled' : '');

  const onCropChange = (crop) => {
    setPhotoState({ ...photoState, crop });
  };

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    canvasContext.translate(safeArea / 2, safeArea / 2);
    canvasContext.rotate(getRadianAngle(rotation));
    canvasContext.translate(-safeArea / 2, -safeArea / 2);

    canvasContext.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = canvasContext.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    canvasContext.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );
    return canvas.toDataURL('image/jpeg');
  };

  const createImage = (url) => {
    const promise = new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });
    return promise;
  };

  const onClose = (event) => {
    setPhotoState(defaultPhotoState);
    handleCloseModal(event);
  };

  let formTitle;
  let formMessage;
  const primaryButton = {};
  const {
    cropMessage,
    cropTitle,
    imageDimensionsError,
    imageFormatError,
    imageSizeError,
    imageUnknownError,
    modalTitle,
    photoValid,
    samplePhotoContent,
    successMessage,
    successTitle,
    unsuccessfulMessage,
    unsuccessfulTitle,
    uploadMessage1,
    uploadMessage2,
    uploadTitle,
  } = modalData;
  const {
    aspect,
    crop,
    croppedImage,
    cropSize,
    imageName,
    imageSrc,
    isImageDimensionsAcceptable,
    isImageFormatError,
    isImageSizeAcceptable,
    isLoaded,
    rotation,
    zoom,
  } = photoState;
  // TODO: This logic should probably be part of the container.
  switch (formStep) {
    case PHOTO_UPLOAD_STEPS.GUIDELINE: {
      formTitle = modalTitle;
      primaryButton.text = getStartedLabel;
      primaryButton.onButtonClick = handleRedirectToUploadModal;
      break;
    }
    case PHOTO_UPLOAD_STEPS.UPLOAD: {
      formTitle = uploadTitle;
      break;
    }
    case PHOTO_UPLOAD_STEPS.CROP: {
      formTitle = cropTitle;
      primaryButton.text = cropAndSubmitLabel;
      primaryButton.onButtonClick = async () => {
        const photoValidationPromise = await handlePhotoValidation(croppedImage);
        let isPhotoValid = false;
        if (photoValidationPromise?.isSuccessful) {
          isPhotoValid = photoValidationPromise?.data?.photoValidation;
        }
        handlePhotoSubmit(isPhotoValid ? croppedImage : '', passengerNumber, isPhotoValid);
        handleRedirectToSuccessModal();
      };
      break;
    }
    case PHOTO_UPLOAD_STEPS.SUCCESS: {
      formTitle = photoValid ? successTitle : unsuccessfulTitle;
      formMessage = photoValid ? successMessage : unsuccessfulMessage;
      primaryButton.text = photoValid ? returnToCheckInLabel : uploadNewPhotoLabel;
      primaryButton.onButtonClick = () => {
        if (photoValid) {
          onClose();
        } else {
          onClose();
          handleRedirectToUploadModal();
          openModal(photoUploadModal, 1);
        }
      };
      break;
    }
    default:
      break;
  }

  const onMediaLoaded = ({ naturalHeight, naturalWidth, height, width }) => {
    const originalWidth = naturalWidth || width;
    const originalHeight = naturalHeight || height;
    let newHeight = originalHeight;
    let newWidth = originalWidth;
    if (newHeight < newWidth) {
      newHeight = originalWidth;
      newWidth = originalHeight;
    }
    if (newWidth < PHOTO_UPLOAD_CROP_SIZE.WIDTH || newHeight < PHOTO_UPLOAD_CROP_SIZE.HEIGHT) {
      setPhotoState({
        ...photoState,
        isImageDimensionsAcceptable: false,
        isImageSizeAcceptable: true,
      });
      handleRedirectToUploadModal();
    } else {
      setPhotoState({ ...photoState, isLoaded: true });
    }
  };

  return (
    <Modal id={id} onClose={onClose} forceAction={false}>
      <div className="photo-upload-modal">
        <div className="mvj-modal">
          <div className="row no-gutters d-flex justify-content-center">
            <div className="col-11">
              <Form className="form photo-upload-form" onSubmit={handleSubmit}>
                {formStep === PHOTO_UPLOAD_STEPS.GUIDELINE && (
                  <div className="row no-gutters photo-upload-fields">
                    <div className="title h4 h3-md">{formTitle}</div>
                    <div className="card-deck">
                      {samplePhotoContent.map(({ title, url, content }) => (
                        <div className="card" key={title}>
                          <img className="card-img-top" src={url} alt="Images" />
                          <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <hr className="separator" />
                            <p className="card-text" dangerouslySetInnerHTML={prepareHtml(content)} />
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button appearance="secondary-blue" {...primaryButton}>
                      {primaryButton.text}
                    </Button>
                  </div>
                )}
                {formStep === PHOTO_UPLOAD_STEPS.UPLOAD && (
                  <div className="row no-gutters photo-upload">
                    <div className="col-md-10">
                      <div className="title h5 h3-md">{formTitle}</div>
                      <div className="App">
                        <div>
                          <Icon name="upload" />
                          <br />
                          <label className="custom-file-upload" htmlFor="file-input">
                            <input
                              type="file"
                              accept={PHOTO_UPLOAD_MIME_TYPES.join(',')}
                              onChange={onSelectFile}
                              id="file-input"
                            />
                            {uploadFromDeviceLabel}
                          </label>
                          {isImageFormatError && (
                            <div
                              className="image-format-error"
                              dangerouslySetInnerHTML={prepareHtml(imageFormatError)}
                            />
                          )}
                          {!isImageSizeAcceptable && (
                            <div
                              className="image-format-error fo"
                              dangerouslySetInnerHTML={prepareHtml(imageSizeError)}
                            />
                          )}
                          {!isImageDimensionsAcceptable && (
                            <div
                              className="image-format-error"
                              dangerouslySetInnerHTML={prepareHtml(imageDimensionsError)}
                            />
                          )}
                          {isImageUnknownError && (
                            <div
                              className="image-format-error"
                              dangerouslySetInnerHTML={prepareHtml(imageUnknownError)}
                            />
                          )}
                        </div>
                      </div>
                      <div className="confirmation-text1" dangerouslySetInnerHTML={prepareHtml(uploadMessage1)} />
                      <div className="confirmation-text2" dangerouslySetInnerHTML={prepareHtml(uploadMessage2)} />
                    </div>
                  </div>
                )}
                {formStep === PHOTO_UPLOAD_STEPS.CROP && (
                  <div className="row no-gutters photo-upload-crop">
                    <div className="col-md-10">
                      <div className="title h5 h3-md">{formTitle}</div>
                      <div className="subtitle">{cropMessage}</div>
                      <div className="crop-container">
                        {!isLoaded && (
                          <>
                            <div className="row justify-content-center">
                              <Spinner />
                            </div>
                            <div className="row justify-content-center">{imageName}</div>
                          </>
                        )}
                        {imageSrc && (
                          <Cropper
                            aspect={aspect}
                            crop={crop}
                            cropSize={cropSize}
                            image={imageSrc}
                            maxZoom={PHOTO_UPLOAD_ZOOM_MAX}
                            minZoom={PHOTO_UPLOAD_ZOOM_MIN}
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onMediaLoaded={onMediaLoaded}
                            onRotationChange={onRotationChange}
                            rotation={rotation}
                            zoom={zoom}
                            // TODO: Find or build component with zoom that works better with Slider.
                            // Slider uses increments, but Cropper uses an exponential algorithm causing the image to zoom in and out rapidly.
                            // Tried the following:
                            // - onZoomChange event, but the image will zoom in and out rapidly.
                            // - onWheel event for crop-container (native handler required for passive events), but image disappears after zoom.
                            zoomWithScroll={false}
                          />
                        )}
                      </div>
                      <div className="row plus-minus-container">
                        <div
                          className={`col-6 minus${getZoomDisabledClass(PHOTO_UPLOAD_ZOOM_MIN)}`}
                          onClick={onZoomDown}
                          onKeyDown={onZoomDown}
                          role="button"
                          tabIndex={0}
                        >
                          &ndash;
                        </div>
                        <div
                          className={`col-6 plus${getZoomDisabledClass(PHOTO_UPLOAD_ZOOM_MAX)}`}
                          onClick={onZoomUp}
                          onKeyDown={onZoomUp}
                          role="button"
                          tabIndex={0}
                        >
                          +
                        </div>
                      </div>
                      <div className="controls">
                        <div>{zoomLabel}</div>
                        <Slider
                          aria-labelledby="Zoom"
                          classes={{ root: 'slider' }}
                          disabled={!isLoaded}
                          max={PHOTO_UPLOAD_ZOOM_MAX}
                          min={PHOTO_UPLOAD_ZOOM_MIN}
                          onChangeCommitted={(_, zoom) => onZoomChange(zoom)}
                          step={PHOTO_UPLOAD_ZOOM_MIN}
                          value={zoom}
                        />
                        <div>{rotateLabel}</div>
                        <Slider
                          aria-labelledby="Rotation"
                          classes={{ root: 'slider' }}
                          disabled={!isLoaded}
                          max={360}
                          min={0}
                          onChange={(_, rotation) => onRotationChange(rotation)}
                          step={5}
                          value={rotation}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <FormActions
                        linkButton={{
                          text: backLabel,
                          onButtonClick: onCropBack,
                        }}
                        primaryButton={{ ...primaryButton, disabled: !isLoaded }}
                      />
                    </div>
                  </div>
                )}
                {formStep === PHOTO_UPLOAD_STEPS.SUCCESS && (
                  <div className="row no-gutters photo-upload-success">
                    <div className="col-md-10">
                      <div className="title h5 h3-md">{formTitle}</div>
                      <div className="subtitle" dangerouslySetInnerHTML={prepareHtml(formMessage)} />
                    </div>
                    <Button appearance="secondary-blue" {...primaryButton}>
                      {primaryButton.text}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PhotoUploadModal.propTypes = {
  backLabel: PropTypes.string,
  cropAndSubmitLabel: PropTypes.string,
  cropEnabled: PropTypes.bool,
  cropSizeHeight: PropTypes.number,
  cropSizeWidth: PropTypes.number,
  formStep: PropTypes.string,
  getStartedLabel: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  handlePhotoSubmit: PropTypes.func.isRequired,
  handleRedirectToCropModal: PropTypes.func.isRequired,
  handleRedirectToSuccessModal: PropTypes.func.isRequired,
  handleRedirectToUploadModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  modalData: PropTypes.shape().isRequired,
  passengerNumber: PropTypes.number.isRequired,
  returnToCheckInLabel: PropTypes.string,
  rotateLabel: PropTypes.string,
  uploadFromDeviceLabel: PropTypes.string,
  zoomLabel: PropTypes.string,
};

PhotoUploadModal.defaultProps = {
  formStep: 'add',
  id: '',
};

export default PhotoUploadModal;
