import Image from '@viking-eng/image';
import ImageCarousel from '@viking-eng/image-carousel';
import { prepareHtml } from '@viking-eng/utils';
import React from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import { DINING_DETAILS } from '../../common/Constants';
import { mapModalParameters, stripHTMLTags } from '../../common/Utils';
import { ModalReservationForm } from '../../components';
import './Reservation.scss';
import ReservationDefaultMainContent from './ReservationDefaultMainContent';
import ModalHeading from './heading/ModalHeading';

const ReservationModal = (props) => {
  const {
    id: modalId,
    title,
    subtitle,
    printLabel,
    images,
    mainContent,
    sideContent,
    onClose,
    heading,
    labels,
    alert,
    alertIsUrgent,
    forceAction,
    subText,
    subTextAria,
    priceLabel,
    confirmed,
    availability,
    dining,
    reservationHeader,
    hideMakeAreservation,
    ...reservation
  } = props;
  let modalParams = mapModalParameters(props);
  modalParams = { ...modalParams, ...reservation };
  const { text = '' } = availability || {};

  const isTheRestaurant = title === DINING_DETAILS.THE_RESTAURANT;

  const sidebar = sideContent || (
    <ModalReservationForm
      {...reservation}
      labels={labels}
      alert={alert}
      alertIsUrgent={alertIsUrgent}
      modalId={modalId}
    />
  );
  const main = mainContent || <ReservationDefaultMainContent {...modalParams} hasReadMore={dining} />;
  const htmlTitle = <span dangerouslySetInnerHTML={prepareHtml(stripHTMLTags(title))} />;

  const head = heading || (
    <ModalHeading
      title={htmlTitle}
      subtitle={subText}
      subtitleAria={subTextAria}
      subTitle2={subtitle}
      alert={text}
      confirmed={confirmed}
      dining={dining}
      isTheRestaurant={isTheRestaurant}
    />
  );

  return (
    <Modal
      printLabel={printLabel}
      id={modalId}
      onClose={onClose}
      forceAction={forceAction}
      onPrint={forceAction ? () => null : undefined}
      title={title}
      subtitle={subtitle}
    >
      {title && (
        <div className="reservation-modal mvj-modal">
          {images?.length > 1 ? (
            <ImageCarousel id="pictureCarousel" className="carousel" images={images} />
          ) : (
            <Image id="pictureCarousel" className="carousel img-fluid" {...images?.[0]} />
          )}
          <div className="container content-outer">
            <div className="row no-gutters">
              {reservation?.showReservationSidebar ? (
                <>
                  {!dining && (
                    <>
                      <div className="content col-12">
                        <div className="reservation-modal-container">
                          <div className="col-12 col-md-5 reservation-modal-col reservation-modal-side-container">
                            <div className="horizontal-separator sidebar">{sidebar}</div>
                          </div>
                          <div className="col-12 col-md-7 reservation-modal-col separator">
                            {head}
                            {main}
                          </div>
                        </div>
                      </div>
                      <div className="separator d-none">
                        <div className="sidebar">{sidebar}</div>
                      </div>
                    </>
                  )}
                  {dining && (
                    <div className="dining-modal-container row">
                      <div className="col-12">
                        {head}
                        {main}
                      </div>
                      <div className="vertical-separator" />
                      {!hideMakeAreservation && (
                        <div className="col-12 make-reservation-header">{reservationHeader}</div>
                      )}
                      <div className="col-12">{sidebar}</div>
                    </div>
                  )}
                </>
              ) : (
                <div className="content col-12 col-md-12">
                  {head}
                  {main}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

ReservationModal.propTypes = {
  ...PropTypes.modalData,
  alertIsUrgent: PropTypes.bool,
  showReservationSidebar: PropTypes.bool,
};

ReservationModal.defaultProps = {
  showReservationSidebar: true,
};

export default ReservationModal;
