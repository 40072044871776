import Button from '@viking-eng/button';
import Image from '@viking-eng/image';
import PropTypes from '@viking-eng/prop-types';
import React from 'react';
import './OopsPage.scss';

const hideBrokenImage = (i) => {
  const { style } = i.target;
  style.display = 'none';
};

const CallToAction = ({ btnContent: { attributes } }) => {
  const logo = (
    <span role="none">
      <Image alt="Image" brokenImageHandler={hideBrokenImage} className="viking-logo" {...attributes} />
    </span>
  );
  return logo;
};

const OopsPage = ({ goToHome, logo, title, body }) => (
  <div id="oopsPage" className="oops-Page d-flex align-items-center justify-content-center">
    <div>
      <div className="d-flex justify-content-center mb-2">
        {logo &&
          logo.map((btn, idx) => {
            const id = `button_${idx}`;
            return <CallToAction key={id} btnContent={btn} />;
          })}
      </div>
      <h2 className="d-flex justify-content-center font-weight-bold mb-2">
        <span>{title}</span>
      </h2>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <div className="row no-gutters">
          {body.map((line) => (
            <>
              <div className="msg-text col-12">{line}</div>
            </>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button onButtonClick={goToHome} className="m-4 text-uppercase" appearance="secondary-blue">
          Back Home
        </Button>
      </div>
    </div>
  </div>
);

OopsPage.propTypes = {
  goToHome: PropTypes.func,
  logo: PropTypes.arrayOf(PropTypes.button),
};

OopsPage.defaultProps = {
  goToHome: () => null,
  logo: null,
};
export default OopsPage;
