import React from 'react';

import Button from '@viking-eng/button';
import { Modal } from '..';

import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './ConfirmationModal.scss';

const ConfirmationModal = ({ id, modalProps, confirmHandler, cancelHandler, children }) => (
  <Modal id={id} {...modalProps}>
    <div className="confirmation-modal">
      <div className="mvj-modal">
        <div className="row no-gutters d-flex justify-content-center">
          <div className="confirmation-title">{modalProps?.title}</div>
          <div className="row">
            {modalProps.message && (
              <div className="text-content d-flex flex-column align-items-center">
                <div className="confirmation-message" dangerouslySetInnerHTML={prepareHtml(modalProps.message)} />
              </div>
            )}
            {children && <>{children}</>}
            <div className="form-actions-group d-sm-block d-md-flex justify-content-center">
              <Button className="order-sm-last" appearance="secondary-blue" onButtonClick={confirmHandler}>
                {modalProps.confirmText}
              </Button>
              <Button className="order-sm-first" appearance="secondary-gray" onButtonClick={cancelHandler}>
                {modalProps.cancelText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  modalProps: PropTypes.shape().isRequired,
  confirmHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default ConfirmationModal;
