import { connect } from 'react-redux';
import { compose } from 'redux';
import { initialize } from 'redux-form';

import withReinitializeForm from './WithReinitializeForm';

const mapDispatchToProps = (dispatch, { form }) => ({
  reinitializeForm: (data, options) => dispatch(initialize(form, data, options)),
});

const wrapper = (WrappedComponent) =>
  compose(connect(null, mapDispatchToProps), withReinitializeForm)(WrappedComponent);

export default wrapper;
