import Radio from '@viking-eng/radio';
import moment from 'moment';
import React, { useState } from 'react';

import PropTypes from '../../../PropTypes';
import './ActivitiesOfInterest.scss';

import { Field, PageHeader } from '../../../components';

const ActivitiesOfInterest = ({ content, backButton, countDownFirstEncounterDate, countDownCloseThresholdDays }) => {
  const {
    title,
    subtitle,
    isLoading,
    selectPassenger,
    passengers,
    surveyClosedMessage,
    selectPassengerMessage,
  } = content;
  const [displayPax, setPaxNumber] = useState('1');

  const daysLeft = moment.duration(moment(countDownFirstEncounterDate).diff(moment())).asDays();
  const surveyClosed = daysLeft <= countDownCloseThresholdDays;

  return (
    <div className="preference-page-wrapper">
      <>
        <PageHeader hasLine backButton={backButton} title={title} isLoading={isLoading} />
        {!isLoading && (
          <div className="activities-survey">
            {surveyClosed ? (
              <div className="row no-gutters">
                <div className="col activities-survey-closed">
                  <div className="medium-body-copy" role="none">
                    {surveyClosedMessage}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="activities-survey-message">{subtitle}</div>
                <div className="activities-survey-title h3-md">{selectPassenger}</div>
                <div className="activities-passenger-select-message">{selectPassengerMessage}</div>
                {passengers?.length && (
                  <div className="passenger-container row no-gutters">
                    {passengers.map(({ name, passengerNumber }) => (
                      <div className="radio-wrapper">
                        <Field
                          key={passengerNumber}
                          id={`passenger${passengerNumber}`}
                          component={Radio}
                          type="radio"
                          value={`${passengerNumber}`}
                          groupName="passengerNumber"
                          name="passengerNumber"
                          label={name}
                          appearance="radio"
                          onChange={() => setPaxNumber(`${passengerNumber}`)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="iframe-wrapper">
                  <iframe
                    id="iFrame_1"
                    title="passenger_1_survey"
                    src={passengers?.[0].surveyUrl}
                    // width="100%" height="600px"
                    // width="100%" height="1700px"
                    width="100%"
                    height="2600px"
                    // width="100%" height="1500px"
                    // width="100%" height="600px"
                    frameBorder="0"
                    style={{ display: displayPax === '1' ? 'flex' : 'none' }}
                  />
                  {passengers?.[1] && (
                    <iframe
                      title="passenger_2_survey"
                      src={passengers?.[1].surveyUrl}
                      width="100%"
                      height="2600px"
                      frameBorder="0"
                      style={{ display: displayPax === '2' ? '' : 'none' }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </>
    </div>
  );
};

ActivitiesOfInterest.propTypes = {
  backButton: PropTypes.shape({
    label: PropTypes.string,
    previousPath: PropTypes.string,
  }),
  content: PropTypes.shape({
    checkedInData: PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        passengerNumber: PropTypes.number.isRequired,
        checkedIn: PropTypes.bool,
        guestId: PropTypes.number,
      })
    ),
    selectPassenger: PropTypes.string,
    startSurvey: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    surveyUrl: PropTypes.string,
    disabledMessage: PropTypes.string,
  }),
  countDownFirstEncounterDate: PropTypes.string,
};

ActivitiesOfInterest.defaultProps = {
  content: {},
  countDownFirstEncounterDate: '',
};

export default ActivitiesOfInterest;
