import Button from '@viking-eng/button';
import PaymentMethodRow from '@viking-eng/payment-method-row';
import { prepareHtml } from '@viking-eng/utils';
import classnames from 'classnames';
import React from 'react';
import { MODALS, PAYMENT_ACTION_TYPE } from '../../../../../common/Constants';
import { DialogModal } from '../../../../../modals';
import PropTypes from '../../../../../PropTypes';
import './OnBoardCreditCardStep.scss';

const dialogModalId = MODALS.DIALOG;

const OnBoardCreditCardStep = (props) => {
  const {
    title,
    paymentMethods,
    openModal,
    removalModalBody,
    handleCancelOnboardCCRemove,
    handleRemoveOnboardCreditCard,
    handleAddCreditCard,
    isSubmitting: isModalSubmitting,
    guestName,
    stepDescription,
    addCreditCard,
  } = props;

  const { mappedPaymentMethods, hasSavedCCPax1, hasSavedCCPax2, isSinglePax, lockedDown } = paymentMethods;
  let paymentMethod = mappedPaymentMethods;
  if (paymentMethods) {
    paymentMethod = mappedPaymentMethods.map((pm) => ({
      ...pm,
      paymentMethodsValues: {
        ...pm.paymentMethodsValues,
      },
      items: pm.items.reduce((acc, item) => {
        if (item) {
          acc.push({
            ...item,
            buttons: item.actionButtons.map((button) => {
              let attributes;
              switch (button.action) {
                case PAYMENT_ACTION_TYPE.REMOVE:
                  attributes = {
                    onButtonClick: () => openModal(dialogModalId, item.id),
                    disabled: lockedDown,
                  };
                  break;
                default:
                  break;
              }

              return {
                ...button,
                attributes,
              };
            }),
          });
        }
        return acc;
      }, []),
    }));
  }

  return (
    <div className="onboard-cc-step-wrapper">
      <div className="onboard-cc-step-title">{title}</div>
      <div className="row">
        <div className="col-12">
          {mappedPaymentMethods &&
            paymentMethod.map(({ paymentMethodsValues, items }) => {
              const remappedItems = items.filter(({ accountHolderName }) => accountHolderName === guestName);
              return (
                <div>
                  <div dangerouslySetInnerHTML={prepareHtml(stepDescription)} className="onboard-cc-step-description" />
                  {remappedItems.length === 0 && (
                    <div>
                      <div className="row no-gutters">
                        <div className="col-12 d-flex buttons flex-column-reverse flex-md-row">
                          <Button appearance="secondary-blue" onButtonClick={handleAddCreditCard}>
                            {addCreditCard}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={classnames(
                      'onboard-credit-card',
                      ((hasSavedCCPax1 && hasSavedCCPax2) || isSinglePax) && 'has-saved-cc'
                    )}
                  >
                    <PaymentMethodRow rowHeader={paymentMethodsValues} savedItems={remappedItems} isOnboardCC />
                  </div>
                </div>
              );
            })}
        </div>
        <DialogModal
          id={dialogModalId}
          onConfirm={handleRemoveOnboardCreditCard}
          onCancel={handleCancelOnboardCCRemove}
          forceAction={isModalSubmitting}
          {...removalModalBody}
        />
      </div>
    </div>
  );
};

OnBoardCreditCardStep.propTypes = {
  title: PropTypes.string,
  paymentMethods: PropTypes.shape({
    rowHeader: PropTypes.paymentMethodRowHeader,
    savedItems: PropTypes.arrayOf(PropTypes.paymentMethodSavedItem),
  }),
  openModal: PropTypes.func.isRequired,
  removalModalBody: PropTypes.shape({
    ...PropTypes.dialogModalFields,
  }),
  handleRemoveOnboardCreditCard: PropTypes.func.isRequired,
  handleCancelOnboardCCRemove: PropTypes.func.isRequired,
  handleAddCreditCard: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  guestName: PropTypes.shape({}).isRequired,
  stepDescription: PropTypes.string.isRequired,
  addCreditCard: PropTypes.string.isRequired,
};

OnBoardCreditCardStep.defaultProps = {
  title: '',
  paymentMethods: [],
  removalModalBody: {},
  isSubmitting: false,
};

export default OnBoardCreditCardStep;
