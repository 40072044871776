import React from 'react';

import { PageHeader } from '../../components';
import PropTypes from '../../PropTypes';

import RequestInvoice from './invoice/RequestInvoiceContainer';
import UserProfile from './profile/UserProfileContainer';

const Account = ({ isLoading, match: { url }, tabs, title, backButton: { label }, isDirect, isTaAccount }) => {
  const paths = [
    {
      component: UserProfile,
      path: '/profile',
    },
  ];
  if (isDirect || (!isDirect && isTaAccount)) {
    paths.push({
      component: RequestInvoice,
      path: '/request-invoice',
    });
  }
  return (
    <div id="accountPage" className="account-page">
      <PageHeader backButton={label} isLoading={isLoading} paths={paths} tabs={tabs} title={title} url={url} />
    </div>
  );
};

Account.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.routerMatch.isRequired,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  backButton: PropTypes.pageHeaderBackButton,
  isDirect: PropTypes.bool.isRequired,
  isTaAccount: PropTypes.bool.isRequired,
};

Account.defaultProps = {
  tabs: [],
  title: '',
  backButton: {
    label: '',
    previousPagePath: '',
  },
};

export default Account;
