import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, initialize } from 'redux-form';

import preferencePageWrapper from '../preferencePageWrapper/PreferencePageWrapper';
import { buildPreferencesObject } from '../PreferencesUtils';
import { FORMS, TAB_NAMES } from '../../../../common/Constants';
import { withContent, withReinitializeForm } from '../../../../components';
import onboardStore, {
  fetchPreferencesTabContent,
  updatePassengerFoodPreferences,
  handlePreferencesFormChange,
} from '../../OnboardStore';
import { reloadBookings } from '../../../../common/UserStore';

import FoodAllergies from './FoodAllergies';

const { getFoodAllergiesContent } = onboardStore.selectors;

const formName = FORMS.ALLERGIES;
const tabName = TAB_NAMES.FOOD_ALLERGIES;

const mapStateToProps = (state) => ({
  ...getFoodAllergiesContent(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchPreferencesTabContent(tabName)),
});

const onSubmit = ({ passengers }, dispatch) =>
  new Promise((resolve, reject) => {
    const selectedPreferences = buildPreferencesObject(passengers);

    dispatch((_, getState) => {
      dispatch(updatePassengerFoodPreferences(selectedPreferences))
        .then((data) => {
          dispatch(reloadBookings()).then(() => {
            const { initialValues } = getFoodAllergiesContent(getState());
            dispatch(initialize(formName, initialValues, false));
            return resolve(data);
          });
        })
        .catch(reject);
    });
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: formName,
    onSubmit,
    onChange: handlePreferencesFormChange(formName),
  }),
  withReinitializeForm,
  preferencePageWrapper
);

export default enhance(FoodAllergies);
