import React from 'react';

import PropTypes from '../../PropTypes';
import { prepareHtml } from '../../common/Utils';

import './PageMessage.scss';

const PageMessage = ({ message }) => (
  <>{message && <div className="page-message text-center" dangerouslySetInnerHTML={prepareHtml(message)} />}</>
);

PageMessage.propTypes = {
  message: PropTypes.string,
};

PageMessage.defaultProps = {
  message: null,
};

export default PageMessage;
