import ActionItem from '@viking-eng/action-item';
import Button from '@viking-eng/button';
import Checkbox from '@viking-eng/checkbox';
import Select from '@viking-eng/select';
import classNames from 'classnames';
import get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from '../../../PropTypes';
import { RESERVATION_STATUS } from '../../../common/Constants';
import { Field } from '../../../components';

const { RESERVED, IN_CART } = RESERVATION_STATUS;

const ActionItemForm = ({
  passenger: { passengerName },
  lockedDown,
  isSoldOut,
  times,
  submitting,
  selectLabel,
  primary,
  secondary,
  renderError,
  passengerStatus,
  statusMessage,
  disableField,
  isOtherSubmitting,
  isUkMessage,
  isBalancePastDue,
  showShorexTime,
  paxIndex,
  onBookNow,
  hideWaiver,
  pax1SubWaiver,
  pax2SubWaiver,
  setPax1SubWaiver,
  setPax2SubWaiver,
  modalReservationInfo,
  isPrivateShorex,
  isSubShorex,
  downloadWaiverBtnText,
  subWaiverTitle,
  subWaiverCheckBoxLabel,
  subWaiverUrl,
  passengerNumber,
  submitSubmarinWaiver,
  bookNowBtnText,
  oneReservationNeeded,
}) => {
  const shorexReservationStatus = paxIndex === 0 ? modalReservationInfo[0].state : modalReservationInfo[1].state;

  const [showWaiver, setShowWaiver] = useState(true);
  const [showSelect, setShowSelect] = useState(true);
  const [showWaiverText, setShowWaiverText] = useState(false);
  const paxSubWaiver = paxIndex === 0 ? pax1SubWaiver : pax2SubWaiver;
  const [waiverCheckBoxChecked, setWaiverCheckBoxChecked] = useState(paxSubWaiver);
  const btnDisabledDueToSubWaiver = shorexReservationStatus === 'open' && !waiverCheckBoxChecked;
  let buttons = {};

  if (isSubShorex) {
    buttons = {
      primary: primary.text && {
        ...primary,
        onButtonClick: () => {
          hideWaiver();
          if (shorexReservationStatus === 'open') {
            setShowWaiver(false);
          } else {
            setShowWaiver(true);
          }
          setShowSelect(true);
          setShowWaiverText(false);
          if (paxIndex === 0) {
            setPax1SubWaiver();
          } else {
            setPax2SubWaiver();
          }
          submitSubmarinWaiver(passengerNumber);
          primary.onButtonClick();
        },
        loading: submitting,
        disabled: lockedDown || get(primary, 'disabled', false) || isBalancePastDue || btnDisabledDueToSubWaiver,
        dataId: 'action-item-primary',
        bookNowDisabled: lockedDown || get(primary, 'disabled', false) || isBalancePastDue,
      },
      secondary: secondary.text && {
        ...secondary,
        disabled: lockedDown || submitting || get(secondary, 'disabled', false) || isBalancePastDue,
        dataId: 'action-item-secondary',
      },
    };
  } else {
    buttons = {
      primary: primary.text && {
        ...primary,
        loading: submitting,
        disabled: lockedDown || get(primary, 'disabled', false) || isBalancePastDue,
        dataId: 'action-item-primary',
      },
      secondary: secondary.text && {
        ...secondary,
        disabled: lockedDown || submitting || get(secondary, 'disabled', false) || isBalancePastDue,
        dataId: 'action-item-secondary',
      },
    };
  }

  const getReadOnly = () => {
    if (passengerStatus === IN_CART || passengerStatus === RESERVED) {
      return true;
    }
    if (times && times.length === 1) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!showShorexTime ? (
        <ActionItem title={passengerName} systemMessage={isUkMessage || renderError} buttons={buttons} />
      ) : (
        <>
          {!isSubShorex && (
            <ActionItem
              title={passengerName}
              message={statusMessage || null}
              infoMessage={isPrivateShorex ? oneReservationNeeded : ''}
              systemMessage={isUkMessage || renderError}
              buttons={buttons}
              field={
                <Field
                  component={Select}
                  name="time"
                  options={times || [{ label: 'N/A', value: null }]}
                  placeholder={selectLabel}
                  readOnly={getReadOnly()}
                  disabled={disableField || isSoldOut || isOtherSubmitting || submitting}
                  strong
                  hideError
                  dataId="action-item-time-field"
                />
              }
            />
          )}
          {isSubShorex && (
            <div
              className={classNames(`container-${paxIndex + 1} sub-waiver-container`, {
                'has-sub-download-btn': shorexReservationStatus !== 'open',
                'has-sub-download-btn-canceling': shorexReservationStatus === 'canceling',
              })}
            >
              {showWaiverText && (
                <>
                  <div className="sub-waiver-title">{subWaiverTitle}</div>
                  <Button appearance="strong-border" onButtonClick={() => window.open(subWaiverUrl, '_blank')}>
                    {downloadWaiverBtnText}
                  </Button>
                  <Checkbox
                    id="waiver-checkbox"
                    label={subWaiverCheckBoxLabel}
                    name="waiver-checkbox"
                    className="no-click"
                    input={{
                      checked: waiverCheckBoxChecked,
                      onChange: () => {
                        setWaiverCheckBoxChecked(!waiverCheckBoxChecked);
                      },
                      onBlur: () => null,
                    }}
                  />
                </>
              )}
              <ActionItem
                title={passengerName}
                message={statusMessage || null}
                systemMessage={isUkMessage || renderError}
                buttons={showWaiver && shorexReservationStatus === 'open' ? {} : buttons}
                field={
                  showSelect ? (
                    <Field
                      component={Select}
                      name="time"
                      options={times || [{ label: 'N/A', value: null }]}
                      placeholder={selectLabel}
                      readOnly={getReadOnly()}
                      disabled={disableField || isSoldOut || isOtherSubmitting || submitting}
                      strong
                      hideError
                      dataId="action-item-time-field"
                    />
                  ) : (
                    <div> </div>
                  )
                }
              />
              {shorexReservationStatus !== 'open' && (
                <Button
                  appearance="strong-border"
                  onButtonClick={() => window.open(subWaiverUrl, '_blank')}
                  className="fixed-sub-download-btn"
                  disabled={buttons.primary.disabled}
                >
                  {downloadWaiverBtnText}
                </Button>
              )}
              {showWaiver && shorexReservationStatus === 'open' && (
                <Button
                  appearance="secondary-blue"
                  className="book-now-btn"
                  onButtonClick={() => {
                    setShowWaiver(false);
                    setShowSelect(false);
                    setShowWaiverText(true);
                    onBookNow(paxIndex);
                  }}
                  disabled={buttons.primary.bookNowDisabled}
                >
                  {bookNowBtnText}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

ActionItemForm.propTypes = {
  isSoldOut: PropTypes.bool,
  lockedDown: PropTypes.bool,
  passenger: PropTypes.shape(),
  passengerStatus: PropTypes.string,
  primary: PropTypes.shape(),
  renderError: PropTypes.string,
  secondary: PropTypes.shape(),
  selectLabel: PropTypes.string.isRequired,
  statusMessage: PropTypes.string,
  submitting: PropTypes.bool,
  times: PropTypes.arrayOf(PropTypes.shape()),
  disableField: PropTypes.shape(),
  isOtherSubmitting: PropTypes.bool.isRequired,
  isUkMessage: PropTypes.string,
  isBalancePastDue: PropTypes.bool.isRequired,
  showShorexTime: PropTypes.bool,
  oneReservationNeeded: PropTypes.string,
};

ActionItemForm.defaultProps = {
  isSoldOut: false,
  lockedDown: false,
  passenger: {},
  passengerStatus: null,
  primary: {},
  renderError: null,
  secondary: {},
  statusMessage: '',
  submitting: false,
  times: [],
  disableField: {},
  isUkMessage: '',
  showShorexTime: true,
  oneReservationNeeded: '',
};

export default ActionItemForm;
