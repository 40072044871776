import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore, { userStore } from '../../../common';
import { TAB_NAMES } from '../../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../../common/ModalStore';
import { withContent, withLoadingGuard } from '../../../components';
import paymentsStore, { fetchPaymentMethods, fetchTabContent, removePaymentOption } from '../PaymentsStore';

import PaymentMethods from './PaymentMethods';

const {
  creators: { clearTabContent },
  selectors: { getPaymentMethodsSection, getRemovePaymentMethodModal, isLoadingTabs },
} = paymentsStore;
const {
  selectors: { getSubmitting, getPaymentsAllEnabled, getMvjStrings },
} = commonStore;
const {
  selectors: { getFeatureRestricted },
} = userStore;

const { ADDONS } = TAB_NAMES;
const { clearModal } = modalStore.creators;

const mapStateToProps = (state) => {
  const featureRestricted = getFeatureRestricted(state);
  return {
    isLoading: isLoadingTabs(state),
    paymentMethods: getPaymentMethodsSection(state),
    removePaymentMethodModalData: getRemovePaymentMethodModal(state),
    isSubmitting: getSubmitting(state),
    lockedDown: featureRestricted,
    isPaymentsDisabled: !getPaymentsAllEnabled(state),
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(clearTabContent(ADDONS));
    dispatch(fetchPaymentMethods());
    dispatch(fetchTabContent(TAB_NAMES.PAYMENT_METHODS));
  },
  openRemovalModal: (type, id) => {
    dispatch(setViewAndShowModal(type, { id }));
  },
  handleRemovePaymentOption: (id) => dispatch(removePaymentOption(id)),
  handleCancelPaymentOptionRemove: () => {
    dispatch(clearModal());
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent, withLoadingGuard);

export default enhance(PaymentMethods);
