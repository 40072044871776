import React, { Component } from 'react';
import Radio from '@viking-eng/radio';
import FieldValidationError from '@viking-eng/field-validation-error';
import InputGrid from '@viking-eng/input-grid';
import ResponsiveInputGrid from '@viking-eng/responsive-input-grid';

import { Field } from '../..';
import PropTypes from '../../../PropTypes';

const RadioGrid = InputGrid(Radio);
const ResponsiveRadioGrid = ResponsiveInputGrid(Radio);

class RadioGroup extends Component {
  createComponent = ({ input, meta, errorMessages, errorCodes }) => {
    const { name, options, className, direction, grids, isSubmitting } = this.props;
    const inputs = options.map((option) => ({
      groupName: name,
      label: option.label,
      id: option.id,
      input: {
        ...input,
        value: option.value,
        checked: option.value === input.value,
        disabled: option.disabled || isSubmitting,
      },
    }));
    return (
      <div className={className}>
        {grids ? (
          <ResponsiveRadioGrid
            inputData={inputs}
            grids={grids}
            meta={meta}
            errorCodes={errorCodes}
            errorMessages={errorMessages}
          />
        ) : (
          <RadioGrid
            inputData={inputs}
            direction={direction}
            meta={meta}
            errorCodes={errorCodes}
            errorMessages={errorMessages}
          />
        )}
      </div>
    );
  };

  render() {
    const { name, onChange, meta, errorMessages, errorCodes } = this.props;
    const hasError = meta && meta.touched && meta.error;
    // TODO: Find a way to force re-render RadioGroup
    return (
      <div>
        <Field name={name} component={this.createComponent} onChange={onChange} {...this.props} />
        {hasError && (
          <FieldValidationError errorCode={meta.error} errorCodes={errorCodes} errorMessages={errorMessages} />
        )}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      value: PropTypes.string,
    })
  ),
  direction: PropTypes.string,
  grids: PropTypes.shape({
    tier: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func,
  // meta should be required, it has effect on the PTC form
  meta: PropTypes.reduxFormMeta.isRequired,
  errorCodes: PropTypes.errorCodes,
  errorMessages: PropTypes.errorMessages,
  achNotAvailable: PropTypes.string,
};

RadioGroup.defaultProps = {
  className: '',
  name: '',
  options: [
    {
      label: '',
      value: '',
    },
  ],
  direction: 'vertical',
  grids: null,
  isSubmitting: false,
  onChange: () => true,
  errorCodes: null,
  errorMessages: null,
  achNotAvailable: '',
};

export default RadioGroup;
