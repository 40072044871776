import React from 'react';
import ActionItem from '@viking-eng/action-item';

import PropTypes from '../../../PropTypes';
import { RESERVATION_STATUS, APP_PATHS } from '../../../common/Constants';
import { hideAllModalsAndNavigate } from '../../../common/Utils';

const { IN_CART, OPEN, RESERVED } = RESERVATION_STATUS;
const getButton = (baseButton, status, buttonLabels) => {
  const { checkout, addToCart } = buttonLabels;
  if (status === IN_CART) {
    return {
      ...baseButton,
      text: checkout,
      onButtonClick: () => {
        hideAllModalsAndNavigate(APP_PATHS.CART);
      },
    };
  }
  if (status === OPEN) {
    return {
      ...baseButton,
      text: addToCart,
    };
  }
  if (status === RESERVED) {
    return undefined;
  }
  return undefined;
};

const AirPlusForm = ({
  handleSubmit,
  passenger,
  isReservationAllowed,
  submitting,
  error,
  isOtherSubmitting,
  modalLabels: { buttons },
}) => {
  const baseButton = {
    loading: submitting,
    onButtonClick: handleSubmit,
    disabled: !isReservationAllowed || isOtherSubmitting,
  };

  const { status, guestName, reservationMessage } = passenger;
  return (
    <ActionItem
      title={guestName}
      message={reservationMessage}
      systemMessage={error}
      buttons={{
        primary: getButton(baseButton, status, buttons),
      }}
    />
  );
};

AirPlusForm.propTypes = {
  handleSubmit: PropTypes.func,
  passenger: PropTypes.shape({
    guestName: PropTypes.string,
  }),
  isReservationAllowed: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  isOtherSubmitting: PropTypes.bool,
  modalLabels: PropTypes.shape(),
};
AirPlusForm.defaultProps = {
  handleSubmit: () => null,
  passenger: {
    guestName: '',
  },
  isReservationAllowed: true,
  submitting: false,
  error: '',
  isOtherSubmitting: false,
  modalLabels: {},
};
AirPlusForm.displayName = 'AirPlusForm';

export default AirPlusForm;
