import get from 'lodash/get';
import React from 'react';
import { ACH, ADDONS_CHECKOUT, APP_PATHS, CRUISE_CHECKOUT, PAGE_NAMES, TAB_NAMES } from '../../common/Constants';
import { goTo } from '../../common/Utils';
import { PageHeader, PaymentConfirmation } from '../../components';
import PropTypes from '../../PropTypes';
import CruisePayments from './cruisePayments/CruisePaymentsContainer';
import OnboardCreditCard from './onboardCreditCard/OnboardCreditCardContainer';
import PaymentAddonsContainer from './paymentAddons/PaymentAddonsContainer';
import PaymentCheckout from './paymentCheckout/PaymentCheckoutContainer';
import ManagePaymentMethod from './paymentMethods/managePaymentMethod/ManagePaymentMethodContainer';
import PaymentMethods from './paymentMethods/PaymentMethodsContainer';

const ManageAch = (props) => <ManagePaymentMethod {...props} paymentMethodType={ACH} />;

const Payments = ({
  isLoading,
  location: { pathname },
  match: { url },
  tabs,
  title,
  isDirect,
  isTaAccount,
  hasOnboardCC,
  labels,
}) => {
  const { buttons = {} } = labels;
  const tabLabels = get(labels, `pages.${PAGE_NAMES.PAYMENTS_CART}.tabs`, {});
  const paths = [
    {
      component: PaymentMethods,
      path: get(tabLabels, `${TAB_NAMES.PAYMENT_METHODS}.url`, ''),
      subPaths: [
        {
          component: ManageAch,
          path: '/ach/:id?',
          componentProps: { isLoading },
        },
      ],
    },
    {
      component: PaymentAddonsContainer,
      path: get(tabLabels, `${TAB_NAMES.ADDONS}.url`, ''),
      subPaths: [
        {
          component: PaymentCheckout,
          componentProps: { type: ADDONS_CHECKOUT },
          path: '/checkout',
        },
        {
          component: PaymentConfirmation,
          componentProps: {
            button: {
              text: buttons.backToHome,
              onButtonClick: goTo(APP_PATHS.INDEX),
              appearance: 'secondary-blue',
            },
          },
          path: '/payment-confirmation',
        },
      ],
    },
  ];
  if (isDirect || isTaAccount) {
    paths.push({
      component: CruisePayments,
      path: get(tabLabels, `${TAB_NAMES.CRUISE_PAYMENTS}.url`, ''),
      subPaths: [
        {
          component: PaymentCheckout,
          componentProps: { type: CRUISE_CHECKOUT },
          path: '/checkout',
        },
        {
          component: PaymentConfirmation,
          componentProps: {
            button: {
              text: buttons.backToHome,
              onButtonClick: goTo(APP_PATHS.INDEX),
              appearance: 'secondary-blue',
            },
          },
          path: '/payment-confirmation',
        },
      ],
    });
  }
  if (hasOnboardCC) {
    paths.push({
      component: OnboardCreditCard,
      path: get(tabLabels, `${TAB_NAMES.ONBOARD_CREDIT_CARD}.url`, ''),
    });
  }
  return (
    <div id="paymentsPage" className="payments-page">
      <PageHeader isLoading={isLoading} pathname={pathname} paths={paths} tabs={tabs} title={title} url={url} />
    </div>
  );
};

Payments.propTypes = {
  isLoading: PropTypes.bool,
  location: PropTypes.routerLocation,
  match: PropTypes.routerMatch,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  isDirect: PropTypes.bool,
  isTaAccount: PropTypes.bool,
  hasOnboardCC: PropTypes.bool,
  labels: PropTypes.shape({}),
};

Payments.defaultProps = {
  isLoading: false,
  location: {},
  match: {},
  tabs: [],
  title: '',
  isDirect: false,
  isTaAccount: false,
  hasOnboardCC: false,
  labels: {},
};

export default Payments;
