import ItineraryItem from '@viking-eng/itinerary-item';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { prepareHtml, stripHTMLTags } from '../../../common/Utils';
import { TimeList } from '../../../components';
import './ModalHeading.scss';

const ModalHeading = ({
  title,
  subtitle,
  itineraryData,
  detailedDate,
  timeDetails,
  labels,
  availability,
  showShorexTime,
  onboardOnly,
}) => (
  <>
    <h4
      className="reservation-title"
      data-id="shore-ex-modal-title"
      dangerouslySetInnerHTML={prepareHtml(stripHTMLTags(title))}
    />
    <h4 className="reservation-title" data-id="shore-ex-modal-subtitle">
      {subtitle}
      {!onboardOnly && (
        <span className="availability" data-id="shore-ex-modal-availability">
          {availability}
        </span>
      )}
    </h4>
    <div className="reservation-details" data-id="shore-ex-modal-details">
      {showShorexTime && <ItineraryItem {...itineraryData} />}
      {(detailedDate || timeDetails) && (
        <div className="time-details" data-id="shore-ex-modal-time-details">
          {showShorexTime && (
            <>
              {detailedDate}
              {timeDetails && timeDetails.times && <TimeList moreLabel={labels.viewAll} times={timeDetails.times} />}
            </>
          )}
          <div className="detail" data-id="shore-ex-modal-duration">
            {timeDetails.duration}
          </div>
        </div>
      )}
    </div>
  </>
);

ModalHeading.propTypes = {
  itineraryData: PropTypes.shape(PropTypes.itineraryItem),
  title: PropTypes.shape(),
  subtitle: PropTypes.string,
  availability: PropTypes.string,
  detailedDate: PropTypes.string,
  timeDetails: PropTypes.shape({
    description: PropTypes.string,
  }),
  labels: PropTypes.shape({
    viewAll: PropTypes.string,
  }),
  showShorexTime: PropTypes.bool,
};

ModalHeading.defaultProps = {
  timeDetails: null,
  detailedDate: '',
  itineraryData: {},
  title: null,
  subtitle: '',
  availability: '',
  labels: {},
  showShorexTime: true,
};

export default ModalHeading;
