import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore from '../../common';
import { APP_PATHS, OOPS_PAGE_CONTENT, VIKING_LOGO_COLOR } from '../../common/Constants';
import userStore from '../../common/UserStore';

import OopsPage from './OopsPage';

const logo = [
  {
    attributes: {
      alt: 'Logo',
      src: VIKING_LOGO_COLOR,
    },
  },
];

const {
  creators: { setIsErrorPage },
  selectors: { isHeaderContentAvailable },
} = commonStore;

const {
  creators: { setFatalAuthError, setOopsPageError },
  selectors: { getOopsError },
} = userStore;

const mapStateToProps = (state) => {
  const error = getOopsError(state);
  let pageContent = OOPS_PAGE_CONTENT.DEFAULT;
  if (error && OOPS_PAGE_CONTENT[error]) {
    pageContent = OOPS_PAGE_CONTENT[error];
  }
  return {
    isHeaderContentAvailable: isHeaderContentAvailable(state),
    logo,
    ...pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(setIsErrorPage(true));
  return {
    goToHome: () => {
      dispatch(setIsErrorPage(false));
      dispatch(setOopsPageError(null));
      dispatch(setFatalAuthError());
      window.location.href = APP_PATHS.INDEX;
    },
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(OopsPage);
