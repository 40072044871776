import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import commonStore from '../../../common';
import { FORMS } from '../../../common/Constants';
import accountStore from '../AccountStore';

import UserProfile from './UserProfile';

const { getErrors } = commonStore.selectors;
const { getUserLabels, getRelatedEmailAddress } = accountStore.selectors;

const formName = FORMS.USER_PROFILE;

const mapStateToProps = (state) => ({
  email: getRelatedEmailAddress(state),
  errors: getErrors(state),
  labels: getUserLabels(state),
});

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName,
  })
);
export default enhance(UserProfile);
