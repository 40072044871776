import Button from '@viking-eng/button';
import Heading from '@viking-eng/heading';
import React from 'react';

import PropTypes from '../../PropTypes';

import { Modal } from '..';

import './TravelDocuments.scss';

const TravelDocuments = ({ id, title, printLabel, trackingInfo, heading, subheading, labels }) => (
  <Modal id={id} printLabel={printLabel}>
    <div className="modal-traveldocuments-mvj">
      <Heading title={title} />
      <div className="row">
        <div className="col-12">
          <div className="subheading text-center">
            <p className="h4">{heading}</p>
            <p className="body">{subheading}</p>
          </div>
        </div>
        {!!trackingInfo.length && (
          <div className="col-12">
            <div className="row no-gutters header-row">
              <div className="col-6 col-md-4">{labels.trackingNumber}</div>
              <div className="col text-right text-md-left">{labels.deliveryMethod}</div>
            </div>
            {trackingInfo.map((info) => (
              <div key={info.trackingNumber} className="row no-gutters align-items-center body-row">
                <div className="col-9 col-md-4">{info.trackingNumber}</div>
                <div className="col-3 text-right text-md-left">{info.deliveryMethod}</div>
                <div className="col-12 col-md-5">
                  <Button appearance="secondary-blue" onButtonClick={() => window.open(info.trackingUrl)}>
                    {labels.trackShipment}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </Modal>
);

TravelDocuments.propTypes = {
  trackingInfo: PropTypes.arrayOf(
    PropTypes.shape({
      trackingNumber: PropTypes.string,
      trackingUrl: PropTypes.string,
      shippingMethod: PropTypes.string,
    })
  ),
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  labels: PropTypes.shape({
    trackShipment: PropTypes.string,
    trackingNumber: PropTypes.string,
    deliveryMethod: PropTypes.string,
  }),
  printLabel: PropTypes.string,
};

TravelDocuments.defaultProps = {
  trackingInfo: [],
  printLabel: '',
  title: '',
  heading: '',
  subheading: '',
  labels: {},
};

export default TravelDocuments;
