import Button from '@viking-eng/button';
import InputText from '@viking-eng/input-text';
import React from 'react';

import PropTypes from '../../../PropTypes';
import { Field } from '../../../components';

import { MSAL_CONFIG } from '../../../common/AuthConfig';
import { msalInstance } from '../../../common/Authentication';
import './UserProfile.scss';

const renderInputText = ({ label, maxLength, ...props }) => (
  <InputText placeholder={label} maxLength={maxLength} {...props} />
);

renderInputText.propTypes = {
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

renderInputText.defaultProps = {
  maxLength: null,
};

const UserProfile = ({ email, handleSubmit, labels }) => (
  <>
    <form className="user-profile-form" onSubmit={handleSubmit}>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-6">
          <Field
            name="email"
            type="email"
            component={renderInputText}
            label={labels.email}
            input={{
              value: email,
              disabled: true,
              'aria-label': labels.email,
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-6 actions">
          <Button
            onButtonClick={() => {
              msalInstance.acquireTokenRedirect({
                ...MSAL_CONFIG,
                authority: MSAL_CONFIG.auth.authorities.changePassword,
              });
            }}
            appearance="secondary-blue"
          >
            {labels.changePassword}
          </Button>
        </div>
      </div>
    </form>
  </>
);

UserProfile.propTypes = {
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmNewPassword: PropTypes.string.isRequired,
    passwordRequirement: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserProfile;
