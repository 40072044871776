import { isSubmitting, reduxForm, clearSubmitErrors, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore from '../../common/CommonStore';
import accountStore, { submitRequestInvoice } from '../../pages/account/AccountStore';
import modalStore from '../../common/ModalStore';
import { FORMS, MODALS, REQUEST_INVOICE_STEPS } from '../../common/Constants';

import RequestInvoiceModal from './RequestInvoiceModal';

const {
  creators: { updateRequestInvoiceStep },
  selectors: { getRequestInvoiceStep, getErrors },
} = commonStore;

const { getRequestInvoiceData } = accountStore.selectors;

const { clearModal } = modalStore.creators;

const mapStateToProps = (state) => {
  const modalId = MODALS.REQUEST_INVOICE_MODAL;
  const { modalData } = getRequestInvoiceData(state);
  return {
    isSubmitting: isSubmitting(FORMS.REQUEST_INVOICE)(state),
    formStep: getRequestInvoiceStep(state) || REQUEST_INVOICE_STEPS.ADD,
    id: modalId,
    errors: getErrors(state),
    modalData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(clearModal()),
  handleCloseSuccessModal: () => {
    dispatch(updateRequestInvoiceStep(REQUEST_INVOICE_STEPS.ADD));
    dispatch(reset(FORMS.REQUEST_INVOICE));
    dispatch(clearModal());
  },
  onClose: () => {
    dispatch(updateRequestInvoiceStep(REQUEST_INVOICE_STEPS.ADD));
  },
});

const onSubmit = (values, dispatch, { errors, formStep }) =>
  new Promise((reject) => {
    dispatch(clearSubmitErrors(FORMS.REQUEST_INVOICE));

    if (formStep === REQUEST_INVOICE_STEPS.ADD) {
      return (
        dispatch(submitRequestInvoice())
          // eslint-disable-next-line consistent-return
          .then((res) => {
            if (res) {
              if (res.status === 404) {
                return reject(new SubmissionError({ _error: errors.SomethingWentWrong }));
              }
            }
            dispatch(updateRequestInvoiceStep(REQUEST_INVOICE_STEPS.SUCCESS));
          })
      );
    }
    return dispatch(updateRequestInvoiceStep(REQUEST_INVOICE_STEPS.SUCCESS));
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORMS.REQUEST_INVOICE,
    enableReinitialize: true,
    onSubmit,
  })
)(RequestInvoiceModal);

export default enhance;
