import Image from '@viking-eng/image';
import React from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import { FIVE_BY_FOUR } from '../../common/Constants';
import { getImageAttributes, prepareHtml } from '../../common/Utils';
import './Contact.scss';

const ContactModal = ({ id, title, printLabel, phoneNumber, heading, image, numbers, airPhoneNumber }) => (
  <Modal id={id} printLabel={printLabel} title={title}>
    <div className="modal-contact-mvj">
      <div className="h4 h3-md heading-contact-footer" dangerouslySetInnerHTML={prepareHtml(heading)} />
      <div className="row">
        <div className="col center-header" />
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 order-2 order-sm-1">
          {image && (
            <Image
              {...getImageAttributes({ image: { url: image, alt: title }, type: FIVE_BY_FOUR })}
              ratio={FIVE_BY_FOUR}
            />
          )}
        </div>
        <div className="col-12 col-sm-6 medium-body-copy order-1 order-sm-2 content">
          <div className="h6 h3-md title" dangerouslySetInnerHTML={prepareHtml(title)} />
          <div className="subtitle" dangerouslySetInnerHTML={prepareHtml(phoneNumber)} />
          <div className="subtitle" dangerouslySetInnerHTML={prepareHtml(airPhoneNumber)} />
          <ul className="list-unstyled">
            {numbers &&
              numbers.map(({ reference, number, countryName: country }) => (
                <li key={reference}>
                  <span dangerouslySetInnerHTML={prepareHtml(number)} />{' '}
                  <span dangerouslySetInnerHTML={prepareHtml(country)} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  </Modal>
);

ContactModal.propTypes = {
  airPhoneNumber: PropTypes.string,
  heading: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      countryName: PropTypes.string,
      number: PropTypes.string,
      reference: PropTypes.string,
    })
  ),
  phoneNumber: PropTypes.string,
  printLabel: PropTypes.string,
  title: PropTypes.string,
};

ContactModal.defaultProps = {
  airPhoneNumber: '',
  heading: '',
  image: '',
  numbers: [],
  phoneNumber: '',
  printLabel: '',
  title: '',
};

export default ContactModal;
