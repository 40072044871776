import React from 'react';
import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './ExtensionModalSideContent.scss';

const ExtensionModalSideContent = ({ bookingAssistance }) => (
  <div className="extension-side-content">
    <div className="booking-assistance font-weight-bold" dangerouslySetInnerHTML={prepareHtml(bookingAssistance)} />
  </div>
);

ExtensionModalSideContent.propTypes = {
  bookingAssistance: PropTypes.string,
};

ExtensionModalSideContent.defaultProps = {
  bookingAssistance: '',
};

export default ExtensionModalSideContent;
