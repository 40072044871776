import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore from '../../common';
import { withContent } from '../../components';
import SubmarineVideo from './SubmarineVideo';
import submarineVideoStore, { fetchSubmarineVideoPageData } from './SubmarineVideoStore';

const { getTitle, getSubtitle, isLoadingPage } = submarineVideoStore.selectors;
const { getMvjStrings } = commonStore.selectors;

const mapStateToProps = (state) => {
  const mvjStrings = getMvjStrings(state);
  const pageTitle = get(mvjStrings, 'labels.pages.submarineVideo.title', '');
  return {
    isLoading: isLoadingPage(state),
    subtitle: getSubtitle(state),
    title: getTitle(state),
    pageTitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchSubmarineVideoPageData()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(SubmarineVideo);
