import Checkbox from '@viking-eng/checkbox';
import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import InputText from '@viking-eng/input-text';
import React from 'react';
import { Form } from 'redux-form';
import { Modal } from '..';
import { ADD_BOOKING_STEPS } from '../../common/Constants';
import { numericMask } from '../../common/forms/Masks';
import { checked, ERROR_CODES, required } from '../../common/forms/Validations';
import { Field, RadioGroup } from '../../components';
import PropTypes from '../../PropTypes';
import './AddBooking.scss';

const AddBookingModal = ({
  buttons,
  error,
  fields,
  formStep,
  handleCloseModal,
  handleContactUs,
  handleSubmit,
  hasPtcField,
  hideClose,
  id,
  isSubmitting,
  isViewOnly,
  labels,
  onClose,
  passengerNumber,
  passengerOptions,
  ptcData,
  submitting,
  termsUrl,
  viewOnlyMessage,
}) => {
  let checkBoxLabel;
  let title;
  const primaryButton = {};
  const secondaryButton = {};
  let alert = isViewOnly ? viewOnlyMessage : error;
  if (error === '405') {
    alert = {
      message: labels.bookingDetailsMismatch,
      alertButton: {
        text: buttons.contactUs,
        onButtonClick: () => {
          onClose();
          handleContactUs();
        },
      },
      alertEndText: labels.toAddBooking,
    };
  }

  switch (formStep) {
    case ADD_BOOKING_STEPS.ADD: {
      title = labels.addBooking;
      primaryButton.text = buttons.add;
      secondaryButton.text = buttons.cancel;
      secondaryButton.onButtonClick = () => handleCloseModal();
      checkBoxLabel = (
        <div>
          <span>{fields.iAcceptThe}</span>
          <a href={termsUrl} rel="noopener noreferrer" target="_blank" className="link">
            {fields.termsAndConditions}
          </a>
        </div>
      );
      break;
    }
    case ADD_BOOKING_STEPS.PTC: {
      title = labels.acceptPtc;
      primaryButton.text = buttons.continue;
      secondaryButton.text = buttons.cancel;
      secondaryButton.onButtonClick = () => handleCloseModal();
      checkBoxLabel = (
        <div>
          <span>{labels.iHaveReadThe}</span>
          <a href={ptcData.callToActionUrl} rel="noopener noreferrer" target="_blank" className="link">
            {labels.passengerTicketContract}
          </a>
          <span>{labels.onMyOwn}</span>
        </div>
      );
      break;
    }
    case ADD_BOOKING_STEPS.SUCCESS: {
      title = labels.bookingAdded;
      primaryButton.text = buttons.viewBooking;
      break;
    }
    default:
  }
  const termsValidation = isViewOnly ? [] : [checked];
  return (
    <Modal id={id} onClose={onClose} forceAction={isSubmitting || hideClose} title={title}>
      <div className="add-booking-modal">
        <div className="mvj-modal">
          <div className="row no-gutters d-flex justify-content-center">
            <div className="col-11">
              <div className="title h5 h3-md">{title}</div>
              <Form className="form add-booking-form" onSubmit={handleSubmit}>
                {formStep === ADD_BOOKING_STEPS.ADD && (
                  <div className="row no-gutters add-booking-fields">
                    <div className="col-md-7">
                      <div className="add-booking-form-input-wrapper">
                        <Field
                          id="bookingNumber"
                          name="bookingNumber"
                          component={InputText}
                          placeholder={fields.bookingNumber}
                          validate={[required]}
                          errorCodes={{
                            [ERROR_CODES.REQUIRED]: 'EnterBookingNumber',
                          }}
                          inputAttributes={{
                            disabled: isViewOnly,
                          }}
                          {...numericMask(10)}
                        />
                      </div>
                      <div className="add-booking-form-input-wrapper">
                        <Field
                          id="acceptTcs"
                          name="acceptTcs"
                          component={Checkbox}
                          label={checkBoxLabel}
                          validate={termsValidation}
                          errorCodes={{
                            [ERROR_CODES.REQUIRED]: 'TermsAndConditions',
                          }}
                          isDisabled={isViewOnly}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {formStep === ADD_BOOKING_STEPS.PTC && (
                  <div className="row no-gutters accept-ptc-fields">
                    <div className="col-md-8">
                      {!passengerNumber && (
                        <div className="add-booking-form-guest-select">
                          <div className="guest-select-title">{labels.pleaseSelectName}</div>
                          <RadioGroup
                            name="passengerId"
                            groupName="passengerId"
                            className="passenger-id-radio-group"
                            options={passengerOptions}
                            validate={[required]}
                            formName="passengerTicketContract"
                            errorCodes={{
                              [ERROR_CODES.REQUIRED]: 'GuestRequired',
                            }}
                          />
                        </div>
                      )}
                      {hasPtcField && (
                        <div className="add-booking-form-input-wrapper">
                          <Field
                            id="acceptPtc"
                            name="acceptPtc"
                            component={Checkbox}
                            label={checkBoxLabel}
                            validate={[checked]}
                            errorCodes={{
                              [ERROR_CODES.REQUIRED]: 'PtcRequired',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {formStep === ADD_BOOKING_STEPS.SUCCESS && (
                  <div className="row no-gutters add-booking-success">
                    <div className="col-md-10">
                      <span className="icon-wrapper">
                        <Icon name="check-circle" />
                      </span>
                      <div className="confirmation-text">{labels.successfulAdd}</div>
                    </div>
                  </div>
                )}
                <FormActions
                  primaryButton={{
                    loading: submitting,
                    disabled: isViewOnly,
                    ...primaryButton,
                  }}
                  secondaryButton={secondaryButton}
                  alert={alert}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddBookingModal.propTypes = {
  id: PropTypes.string,
  fields: PropTypes.objectOf(PropTypes.string),
  labels: PropTypes.objectOf(PropTypes.string),
  buttons: PropTypes.objectOf(PropTypes.string),
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  formStep: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  termsUrl: PropTypes.string,
  ptcData: PropTypes.ptcData.isRequired,
  hasPtcField: PropTypes.bool.isRequired,
  passengerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      passengerID: PropTypes.number,
    })
  ).isRequired,
  passengerNumber: PropTypes.number,
  isSubmitting: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  viewOnlyMessage: PropTypes.string.isRequired,
  handleContactUs: PropTypes.func.isRequired,
  hideClose: PropTypes.bool.isRequired,
};

AddBookingModal.defaultProps = {
  id: '',
  fields: {},
  labels: {},
  buttons: {},
  error: '',
  termsUrl: '',
  formStep: 'add',
  passengerNumber: null,
  isSubmitting: false,
};

export default AddBookingModal;
