import PageHeader from '@viking-eng/page-header';
import classNames from 'classnames';
import React from 'react';
import { RouteSwitch } from '..';
import PropTypes from '../../PropTypes';
import { APP_PATHS, CLOSE_TO_SAILING_OVERRIDE_PATHS, FEATURE_RESTRICTED } from '../../common/Constants';
import { setDefaultTab } from '../../common/Utils';
import history from '../../common/history';
import './MvjPageHeader.scss';

const MvjPageHeader = ({
  days,
  hasLine,
  isLoading,
  customPageLink,
  onDropdownChange,
  pathname,
  paths,
  selectPlaceholder,
  subtitle,
  tabs,
  title,
  url,
  backButton,
  pageHeaderPathDepth,
  notification,
  onPrintClick,
  hasPrintLink,
  hasPreHeader,
  featureRestrictedMessage,
  featureRestricted,
}) => {
  // Regular expression that matches path segments, e.g. "/path-text":
  // 3 or more if tabs are defined /page/tab/subpage,
  // 2 or more if no tabs are defined for this path /page/subpage.
  // This is used to control whether the PageHeader and Tabs are rendered or not
  const pathCount = pageHeaderPathDepth || (tabs ? 4 : 2);
  const pathRegex = new RegExp(`(?:/[a-z-]+){${pathCount},}`);
  const handleDropdownChange = days ? onDropdownChange : (value) => history.push(value);
  if (CLOSE_TO_SAILING_OVERRIDE_PATHS.includes(pathname) && featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING) {
    featureRestrictedMessage = null;
  }

  return (
    <>
      {!pathname.match(pathRegex) && (
        <div className={classNames('mvj-page-header', { 'has-pre-header': hasPreHeader })}>
          <div className="title">
            <PageHeader
              customPageLink={customPageLink}
              days={days}
              hasLine={hasLine}
              isLoading={isLoading}
              onDropdownChange={handleDropdownChange}
              selectPlaceholder={selectPlaceholder}
              subtitle={subtitle}
              tabs={tabs}
              title={title}
              backButton={backButton}
              notification={notification || featureRestrictedMessage}
              notificationIcon={notification ? undefined : featureRestrictedMessage?.icon}
              history={history}
              onPrintClick={onPrintClick}
              hasPrintLink={hasPrintLink}
            />
          </div>
        </div>
      )}
      {paths && <RouteSwitch history={history} paths={setDefaultTab(paths, tabs)} url={url} />}
    </>
  );
};

MvjPageHeader.propTypes = {
  days: PropTypes.itineraryDays,
  hasLine: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDropdownChange: PropTypes.func,
  pathname: PropTypes.string,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  selectPlaceholder: PropTypes.string,
  subtitle: PropTypes.string,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  url: PropTypes.string,
  backButton: PropTypes.pageHeaderBackButton,
  pageHeaderPathDepth: PropTypes.number,
  notification: PropTypes.alert,
  featureRestrictedMessage: PropTypes.shape({
    alertText: PropTypes.string,
  }),
  customPageLink: PropTypes.customPageLink,
  onPrintClick: PropTypes.func,
  hasPreHeader: PropTypes.bool,
  hasPrintLink: PropTypes.bool,
  featureRestricted: PropTypes.string,
};

MvjPageHeader.defaultProps = {
  days: null,
  hasLine: false,
  isLoading: false,
  onDropdownChange: null,
  pathname: '',
  paths: null,
  selectPlaceholder: null,
  subtitle: null,
  tabs: null,
  title: '',
  url: null,
  backButton: {
    label: 'Home',
    previousPagePath: APP_PATHS.INDEX,
  },
  pageHeaderPathDepth: null,
  notification: null,
  featureRestrictedMessage: null,
  customPageLink: null,
  onPrintClick: undefined,
  hasPreHeader: false,
  hasPrintLink: true,
  featureRestricted: undefined,
};

export default MvjPageHeader;
