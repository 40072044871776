import ItineraryCard from '@viking-eng/itinerary-card';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { APP_PATHS, FIVE_BY_FOUR, TWO_BY_ONE } from '../../../common/Constants';
import { getImageAttributes, goTo } from '../../../common/Utils';
import './CalendarList.scss';

const mapClickEventsToCard = (cards) =>
  cards.map((card) => ({
    ...card,
    subTitle: card.subtitle, // CMSv2 - UI Kit card requires subTitle
    images: (card.images || [])
      .filter((image) => [FIVE_BY_FOUR, TWO_BY_ONE].includes(image.type))
      .map((image) => getImageAttributes({ image })),
    primaryButton: {
      onButtonClick: goTo(`${APP_PATHS.ITINERARY}/${card.date}`),
      url: `${APP_PATHS.ITINERARY}/${card.date}`,
    },
  }));

const CalendarList = ({ cards }) => {
  const mappedCards = mapClickEventsToCard(cards);
  return (
    <div className="calendar-card-list">
      {mappedCards.map((card) => (
        <ItineraryCard card={card} key={card.id} />
      ))}
    </div>
  );
};

CalendarList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})),
};

CalendarList.defaultProps = {
  cards: [],
};

export default CalendarList;
