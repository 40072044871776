import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import documentStore, { fetchTabContent } from '../DocumentsStore';
import userStore from '../../../common/UserStore';
import commonStore from '../../../common';
import { withContent } from '../../../components';
import { TAB_NAMES, MVJ_FLAG_VARIABLES, FORMS } from '../../../common/Constants';

import ActivitiesOfInterest from './ActivitiesOfInterest';

const { getFirstEncounterDate } = userStore.selectors;
const { getActivitiesPageContent } = documentStore.selectors;
const { getFlagValue } = commonStore.selectors;
const { ACTIVITIES_OF_INTEREST_CLOSE_DATE } = MVJ_FLAG_VARIABLES;

const tabName = TAB_NAMES.ACTIVITIES_OF_INTEREST;

const mapStateToProps = (state) => {
  return {
    content: getActivitiesPageContent(state),
    countDownFirstEncounterDate: getFirstEncounterDate(state),
    countDownCloseThresholdDays: getFlagValue(state)(ACTIVITIES_OF_INTEREST_CLOSE_DATE),
    initialValues: {
      passengerNumber: '1',
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTabContent(tabName));
  },
});

const onSubmit = () => {};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: FORMS.ACTIVITIES_PASSENGER_SELECTOR,
    onSubmit,
    enableReinitialize: true,
  })
);

export default enhance(ActivitiesOfInterest);
