import Icon from '@viking-eng/icon';
import Spinner from '@viking-eng/spinner';
import React from 'react';
import { Modal } from '..';
import { ariaFormatter, hideModal } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './AncillariesAndBaggage.scss';

const AncillariesAndBaggageModal = ({
  title,
  id,
  flightDetails,
  ancillariesTooltipNoteTitle,
  amenitiesNotAvailable,
  baggageLink,
  baggageLinkText,
  isFlightDetailsPending,
  baggagePolicyAriaText,
}) => {
  const ancillariesText =
    flightDetails &&
    (flightDetails.wiFis ||
      flightDetails.entertainments ||
      flightDetails.powerOutlets ||
      flightDetails.beverages ||
      flightDetails.foods)
      ? ancillariesTooltipNoteTitle
      : amenitiesNotAvailable;

  let linkFunction;
  if (typeof baggageLink === 'string') {
    linkFunction = () => {
      window.open(baggageLink, '_blank', 'noopener,noreferrer');
    };
  }
  if (typeof baggageLink === 'function') {
    linkFunction = baggageLink;
  }
  const showAncilliary = (ancilliaryName, icon) =>
    flightDetails[ancilliaryName] && (
      <div className="ancillary" aria-label={ariaFormatter(flightDetails[ancilliaryName].name)}>
        <Icon name={icon} />
        {flightDetails[ancilliaryName].name}
      </div>
    );
  return (
    <Modal id={id} onClose={hideModal(id)} title={title}>
      <div className="ancillaries-modal">
        {title && (
          <>
            <h3 className="title d-none d-sm-block">{title}</h3>
            <h4 className="title d-sm-none">{title}</h4>
          </>
        )}

        <div className="row justify-content-center">
          <div className="col-ancillary">
            {isFlightDetailsPending ? (
              <div className="spinner-container">
                <Spinner />
              </div>
            ) : (
              <>
                {flightDetails && (
                  <>
                    {showAncilliary('wiFis', 'wifi')}
                    {showAncilliary('entertainments', 'entertainment')}
                    {showAncilliary('powerOutlets', 'power')}
                    {showAncilliary('beverages', 'beverage', 'alcoholic_cost')}
                    {showAncilliary('foods', 'food')}
                  </>
                )}
                <div className="note">{ancillariesText}</div>
                <div className="baggage-link">
                  <button
                    type="button"
                    role="link"
                    className="viking-button btn btn-link"
                    aria-label={baggagePolicyAriaText}
                    onClick={linkFunction}
                  >
                    {baggageLinkText}
                    <span className="open-link-col">
                      <Icon name="open-link" className="col" />
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

AncillariesAndBaggageModal.propTypes = {
  ...PropTypes.modalData,
};

export default AncillariesAndBaggageModal;
