import React from 'react';

import PropTypes from '../../../PropTypes';

import './VouchersCredits.scss';

const VouchersCredits = ({ passengers, labels }) => (
  <div className="vouchers-credits">
    <div className="row">
      <div className="col-12">
        <div className="section-title">{labels.vouchersCredits}</div>
        <div className="voucher-tooltip">{labels.helperText}</div>
        <ul className="passengers list-unstyled">
          {passengers.map((passenger) => (
            <li key={passenger.cchid}>
              <div className="line-item row">
                <div className="col-12 col-md-3 col-sm-4 name">{passenger.fullName}</div>
                <div className="col-12 col-md-3 col-sm-4 type-label">{labels.shipboardCredits}</div>
                <div className="col-12 col-md-6 col-sm-4 offset-sm-4 offset-md-0 type-value">
                  {passenger.shipboardCredit}
                </div>
              </div>
              <div className="line-item row">
                <div className="col-12 col-md-3 offset-md-3 col-sm-4 offset-sm-4 type-label">{labels.voucher}</div>
                <div className="col-12 col-md-6 offset-sm-4 offset-md-0">{passenger.otherVoucherAvailable}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default VouchersCredits;

VouchersCredits.propTypes = {
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      cchid: PropTypes.string.isRequired,
      shipboardCredit: PropTypes.string.isRequired,
      otherVoucherAvailable: PropTypes.string.isRequired,
      voucherTotal: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  labels: PropTypes.shape({
    vouchersCredits: PropTypes.string,
    shipboardCredits: PropTypes.string,
    voucher: PropTypes.string,
    helperText: PropTypes.string,
  }).isRequired,
};
