import { connect } from 'react-redux';
import { compose } from 'redux';
import { withContent } from '../..';
import paymentsStore from '../../../pages/payments/PaymentsStore';
import renderTokenExIframe from '../../../pages/payments/TokenEx';
import TokenEx from './TokenEx';

const { getTokenExMetaData } = paymentsStore.selectors;

const mapStateToProps = (state) => ({
  meta: getTokenExMetaData(state),
});

const mapDispatchToProps = (dispatch, { paymentMethod, handleBankAccountChange }) => ({
  fetchContent: () => {
    return dispatch(renderTokenExIframe(paymentMethod, handleBankAccountChange, true));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(TokenEx);
