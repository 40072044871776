import BannerNotification from '@viking-eng/banner-notification';
import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import PaymentDetails from '@viking-eng/payment-details';
import PaymentSummary from '@viking-eng/payment-summary';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { APP_PATHS, TIMER_STATUS } from '../../../common/Constants';
import { goTo, navigateTo, prepareHtml } from '../../../common/Utils';
import { DepositDue } from '../../../components';
import { ConfirmationModal } from '../../../modals';
import PropTypes from '../../../PropTypes';
import './CruisePayments.scss';

const { INIT, START, IN_PROCESS, COMPLETED } = TIMER_STATUS;

const CruisePayments = ({
  cruisePaymentsData,
  cruiseMakePaymentSection,
  paymentButtonDisabled,
  depositDueInformation,
  isCruiseImminent,
  onAlertContactUsClick,
  schedulePaymentSection,
  confirmationModalData,
  handleClose,
  handleModalOpen,
  paymentInProgress,
  paymentProcessingTimerStatus,
  paymentProcessingMessage,
  refetchContentAfterTimeout,
  setProcessingTimerStatus,
  isPaymentsDisabled,
  paymentsDisabledBanner,
}) => {
  useEffect(() => {
    if (paymentInProgress && paymentProcessingTimerStatus === INIT) {
      setProcessingTimerStatus(START);
    }
    if (paymentProcessingTimerStatus === START) {
      setProcessingTimerStatus(IN_PROCESS);
      const checkIsPaymentInProcess = setTimeout(() => {
        setProcessingTimerStatus(COMPLETED);
        refetchContentAfterTimeout();
      }, 120000);
      if (paymentProcessingTimerStatus === COMPLETED) {
        clearTimeout(checkIsPaymentInProcess);
      }
    }
  }, [paymentInProgress, paymentProcessingTimerStatus, refetchContentAfterTimeout, setProcessingTimerStatus]);

  const getAlert = (alertText) => {
    if (!alertText) {
      return alertText;
    }

    // eslint-disable-next-line no-template-curly-in-string
    const searchFor = '${CONTACT_US}';
    const index = alertText.indexOf(searchFor);
    if (index === -1) {
      return alertText;
    }

    const preText = alertText.substring(0, index);
    const postText = alertText.substring(index + searchFor.length);

    return (
      <span>
        {preText}
        <Button
          className="contact-us"
          attributes={{
            role: 'link',
          }}
          onButtonClick={onAlertContactUsClick}
          appearance="link"
        >
          contact us
        </Button>
        {postText}
      </span>
    );
  };

  const cruiseMakePaymentSectionRemapped = {
    ...cruiseMakePaymentSection,
    alert: getAlert(cruiseMakePaymentSection.alert),
    alertClass: cruiseMakePaymentSection.alertClass || '',
    button: {
      ...cruiseMakePaymentSection.button,
      attributes: {
        onButtonClick: goTo(`${APP_PATHS.PAYMENTS}/cruise-payments/checkout`),
        disabled: paymentButtonDisabled,
        loading: paymentInProgress,
      },
    },
    secondaryButton: {
      ...cruiseMakePaymentSection?.secondaryButton,
      attributes: {
        onButtonClick: goTo(`${APP_PATHS.SCHEDULE_PAYMENT}`),
        ...cruiseMakePaymentSection?.secondaryButton?.attributes,
      },
    },
  };

  const formatSchedulePaymentBody = (schedulePaymentSection?.noPaymentScheduled?.body || '')
    .replace(/<\/?!?a[^>]*>/, '|')
    .replace(/<\/a>/, '|')
    .replace(/(<([^>]+)>)/gi, '')
    .split('|');

  return (
    <div
      className={classNames('cruise-payments', {
        'cruise-imminent': isCruiseImminent,
      })}
    >
      {isPaymentsDisabled && (
        <div className="row">
          <div className="container banner-wrapper payment-alert-container">
            <BannerNotification
              alert={{
                alertText: paymentsDisabledBanner,
              }}
              icon="exclamation-mark-filled"
            />
          </div>
        </div>
      )}
      <div className="row">
        {paymentInProgress && (
          <div className="container banner-wrapper payment-alert-container">
            <BannerNotification
              alert={{
                alertText: paymentProcessingMessage,
              }}
              icon="exclamation-mark-filled"
            />
          </div>
        )}
        <div className="col-md-7 col-12 order-md-1 order-2">
          <div className="main-summary">
            <DepositDue deposit={depositDueInformation} />
          </div>
          {cruisePaymentsData.map(({ header, lineItems }) => (
            <div className="main-summary" key={header.label}>
              <PaymentDetails header={header} lineItems={lineItems} />
            </div>
          ))}
        </div>
        <div className="col-md-5 col-12 order-md-2 order-1">
          <PaymentSummary {...cruiseMakePaymentSectionRemapped} paymentStyle />
        </div>
      </div>
      {schedulePaymentSection && !paymentInProgress && !cruiseMakePaymentSection?.scheduledPaymentDisabled && (
        <div className="schedule-payment-wrapper">
          <div className="schedule-payment-title h6">{schedulePaymentSection.title}</div>
          {schedulePaymentSection?.payments.length ? (
            <div className="scheduled-payments row">
              {schedulePaymentSection.payments.map((payment) => (
                <div className="payment-section-wrapper col-12 col-md">
                  <div className="scheduled-payment-title-wrapper row no-gutters">
                    <div className="scheduled-payment-title col-12 col-md-6">{payment.label}</div>
                    <div className="scheduled-payment-title payment-amount col-12 col-md-6">{payment.amount}</div>
                  </div>
                  <div className="scheduled-payment-body" dangerouslySetInnerHTML={prepareHtml(payment.body)} />
                  <div className="scheduled-payment-action-wrapper row no-gutters">
                    <Icon name="calendar" />
                    <div className="payment-date h6-md col">{payment.date}</div>
                    <Button
                      className="edit-button"
                      attributes={{
                        role: 'link',
                        disabled: schedulePaymentSection.disableChange,
                      }}
                      onButtonClick={() => {
                        handleModalOpen('confirmationModal');
                      }}
                      appearance="link"
                    >
                      {schedulePaymentSection.changeAch}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="payment-section-wrapper">
              <div className="scheduled-payment-title h6-md">{schedulePaymentSection.noPaymentScheduled.heading}</div>
              <div className="scheduled-payment-body">
                {formatSchedulePaymentBody?.[0]?.trim()}{' '}
                <Button
                  type="button"
                  appearance="link"
                  attributes={{ role: 'link' }}
                  onButtonClick={() => navigateTo(APP_PATHS.SCHEDULE_PAYMENT)}
                >
                  {formatSchedulePaymentBody?.[1]?.trim()}
                </Button>{' '}
                {formatSchedulePaymentBody?.[2]?.trim()}
              </div>
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        id="confirmationModal"
        confirmHandler={() => {
          goTo(APP_PATHS.SCHEDULE_PAYMENT)();
          handleClose();
        }}
        cancelHandler={() => handleClose()}
        modalProps={confirmationModalData}
      />
    </div>
  );
};

const sectionType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    lineItems: PropTypes.arrayOf(PropTypes.labelValue),
  })
);

CruisePayments.propTypes = {
  cruisePaymentsData: sectionType,
  cruiseMakePaymentSection: PropTypes.shape({
    button: PropTypes.shape({ text: PropTypes.string }),
    lineItems: PropTypes.arrayOf(PropTypes.labelValue),
    balanceItems: PropTypes.arrayOf(PropTypes.labelValue),
    detailsLabel: PropTypes.string,
  }),
  paymentButtonDisabled: PropTypes.bool,
  isCruiseImminent: PropTypes.bool,
  depositDueInformation: PropTypes.objectOf(PropTypes.string),
  onAlertContactUsClick: PropTypes.func,
  confirmationModalData: PropTypes.shape(),
};

CruisePayments.defaultProps = {
  cruisePaymentsData: [],
  cruiseMakePaymentSection: {
    button: {
      text: '',
    },
    lineItems: [],
    balanceItems: [],
    detailsLabel: '',
  },
  paymentButtonDisabled: false,
  isCruiseImminent: false,
  depositDueInformation: {},
  onAlertContactUsClick: () => false,
  confirmationModalData: {},
};

export default CruisePayments;
