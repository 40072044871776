import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';

import commonStore, { requestRefund } from '../../common/CommonStore';
import { FORMS } from '../../common/Constants';
import { reloadBookings } from '../../common/UserStore';
import modalStore from '../../common/ModalStore';
import onboardStore, { fetchTabContent } from '../../pages/onboard/OnboardStore';
import { fetchNotifications } from '../../pages/notifications/NotificationsStore';

import SilverSpirits from './SilverSpirits';

const { updateReservationModalState } = commonStore.creators;
const { getDiningBeverageModalCards } = onboardStore.selectors;
const { clearModal } = modalStore.creators;

const onboardTab = 'diningBeverage';

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting(FORMS.RESERVATION)(state),
  modalData: getDiningBeverageModalCards(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateReservationModalState: (state) => dispatch(updateReservationModalState(state)),
  requestRefund: (extensionType, inventoryCode) =>
    dispatch(
      requestRefund({
        extensionType,
        inventoryCode,
        refreshFunction: fetchTabContent,
        updateModalStateFunction: updateReservationModalState,
        tab: onboardTab,
      })
    ).then(async () => {
      await dispatch(reloadBookings());
      await dispatch(fetchNotifications(true));
    }),
  closeModal: () => dispatch(clearModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SilverSpirits);
