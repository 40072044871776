import TabPage from '@viking-eng/tab-page';
import React, { Component } from 'react';
import { RESERVATION_TYPES } from '../../../common/Constants';
import remapCardDataBasedOnModalType from '../../../common/ModalUtils';
import { getModalType } from '../../../common/Utils';
import { SpaModalSideContent } from '../../../components';
import { CardDetailsModal, ReservationModal } from '../../../modals';
import PropTypes from '../../../PropTypes';

class Spa extends Component {
  addClickEventsToCard() {
    const {
      content: { sections },
      handleCardDetailsClicked,
    } = this.props;
    return sections.map((section) => ({
      ...section,
      cards: section.cards.map((card) => {
        const modalType = getModalType(card.primaryButtonUrl);
        card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle

        const onClick = () => {
          handleCardDetailsClicked(modalType, card.id);
        };

        return remapCardDataBasedOnModalType(card, {
          onPrimaryButtonClick: onClick,
          onSecondaryButtonClick: onClick,
          defaultClick: onClick,
        });
      }),
    }));
  }

  render() {
    const {
      content: { pageDescription, sections },
      modalData,
      reservationModalData: { sideContent },
      isSubmitting,
      loading,
      modalToOpen,
      handleCardDetailsClicked,
      isFetchingCommonContent,
    } = this.props;
    const remappedSections = this.addClickEventsToCard();
    sideContent.alertText =
      sections && sections.length > 1 && sections[1].bannerNotification && sections[1].bannerNotification.alertText;

    if (!isFetchingCommonContent && modalData && modalToOpen && modalToOpen.modalType && modalToOpen.modalId) {
      handleCardDetailsClicked(modalToOpen.modalType, modalToOpen.modalId);
    }
    return (
      <div className="spa">
        <TabPage sections={remappedSections} headerText={pageDescription} />
        <CardDetailsModal {...modalData} id="cardDetailsModal" />
        <ReservationModal
          {...modalData}
          id="reserveModal"
          reservationType={RESERVATION_TYPES.SPA}
          sideContent={<SpaModalSideContent {...sideContent} />}
          forceAction={isSubmitting || loading}
        />
      </div>
    );
  }
}

Spa.propTypes = {
  handleCardDetailsClicked: PropTypes.func.isRequired,
  content: PropTypes.shape({
    pageDescription: PropTypes.string,
    sections: PropTypes.cardSections,
  }),
  reservationModalData: PropTypes.shape({
    sideContent: PropTypes.shape({}),
  }),
  modalData: PropTypes.shape(PropTypes.modalData),
  isSubmitting: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  modalToOpen: PropTypes.shape({ modalType: PropTypes.string, id: PropTypes.string }),
  isFetchingCommonContent: PropTypes.bool,
};

Spa.defaultProps = {
  content: {
    pageDescription: '',
    sections: [],
  },
  modalData: {},
  reservationModalData: {
    sideContent: {},
  },
  isSubmitting: false,
  modalToOpen: null,
  isFetchingCommonContent: false,
};

export default Spa;
