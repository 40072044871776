import Duck, { Selector } from 'extensible-duck';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import set from 'lodash/set';
import { createSelector } from 'reselect';

import commonStore from './CommonStore';
import { mapCardSections, updateTabAttributes } from './Utils';

export const createDefaultDuck = (storeName) =>
  new Duck({
    namespace: 'mvj',
    store: storeName,
    types: ['END_REQUEST', 'START_REQUEST', 'RECEIVE_HOLDING_CONTENT', 'PULL_FROM_HOLDING_AREA'],
    reducer: (state, action, { types }) => {
      const updateLoadingStatus = (status) => {
        const newState = { ...state };

        if (action.node) {
          set(newState, `${action.node}.loading`, status);
        }

        return newState;
      };

      switch (action.type) {
        case types.END_REQUEST:
          return updateLoadingStatus(false);
        case types.START_REQUEST:
          return updateLoadingStatus(true);
        case types.RECEIVE_HOLDING_CONTENT:
          set(state, `holding.${action.node}`, action.content);
          return state;
        case types.PULL_FROM_HOLDING_AREA: {
          const content = get(state, `holding.${action.from}`);
          set(state, `holding.${action.from}`, undefined);
          set(state, action.to, content);
          set(state, `${action.to}.loaded`, true);
          return state;
        }
        default:
          return state;
      }
    },
    creators: ({ types }) => ({
      endRequest: (node) => ({
        type: types.END_REQUEST,
        node,
      }),
      startRequest: (node) => ({
        type: types.START_REQUEST,
        node,
      }),
      receiveHoldingContent: (node, content) => ({
        type: types.RECEIVE_HOLDING_CONTENT,
        node,
        content,
      }),
      pullFromHoldingArea: (from, to) => ({
        type: types.PULL_FROM_HOLDING_AREA,
        from,
        to: to || from,
      }),
    }),
    selectors: ({ store }) => ({
      getStoreState: (state) => get(state, store, {}),
    }),
  });

export const createPageContentDuck = (storeName) =>
  createDefaultDuck(storeName).extend({
    types: ['RECEIVE_CONTENT'],
    initialState: {},
    reducer: (state, action, { types }) => {
      switch (action.type) {
        case types.RECEIVE_CONTENT:
          return {
            ...state,
            content: {
              ...action.payload,
              loaded: true,
            },
          };
        default:
          return state;
      }
    },
    creators: ({ types }) => ({
      receiveContent: (payload) => ({
        type: types.RECEIVE_CONTENT,
        payload,
      }),
    }),
    selectors: () => ({
      getPageContent: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, ({ content = {} }) => ({
          ...content,
          sections: mapCardSections(get(content, 'sections', [])),
        }))
      ),
      getPageContentItems: new Selector(({ getPageContent }) =>
        createSelector(getPageContent, ({ sections = [{}] }) => get(sections[0], 'items', []))
      ),
      isLoadingPage: new Selector(({ getPageContent }) =>
        createSelector(getPageContent, (content) => content.loading || false)
      ),
    }),
  });

export const createPageTitleDuck = (storeName) =>
  createPageContentDuck(storeName).extend({
    selectors: {
      getSubtitle: (state) => get(state, `${storeName}.content.subtitle`),
      getTitle: (state) => get(state, `${storeName}.content.title`),
    },
  });

export const createPageTabsDuck = (storeName) =>
  createPageTitleDuck(storeName).extend({
    types: ['RECEIVE_TAB_CONTENT', 'RECEIVE_UPDATE_BOOKING_CART_RESPONSE', 'CLEAR_TAB_CONTENT'],
    reducer: (state, action, { types }) => {
      switch (action.type) {
        case types.RECEIVE_TAB_CONTENT: {
          const { node } = action;
          return {
            ...state,
            [node]: {
              ...state[node],
              content: {
                ...action.payload,
                loaded: !isEmpty(action.payload),
              },
            },
          };
        }
        case types.RECEIVE_UPDATE_BOOKING_CART_RESPONSE: {
          return {
            ...state,
            receiveBookingCartResponse: {
              isSuccessful: action.isSuccessful,
              [action.isSuccessful ? 'data' : 'error']: action.payload,
            },
          };
        }
        case types.CLEAR_TAB_CONTENT: {
          const { node } = action;
          return {
            ...state,
            [node]: {
              ...state[node],
              content: {
                loaded: false,
              },
            },
          };
        }
        case commonStore.types.CLOSE_MODAL: {
          return {
            ...state,
            modalReservations: null,
          };
        }
        default:
          return state;
      }
    },
    creators: ({ types }) => ({
      receiveTabContent: (payload, node) => ({
        type: types.RECEIVE_TAB_CONTENT,
        node,
        payload,
      }),
      receiveBookingCartResponse: (isSuccessful, modalId, payload) => ({
        type: types.RECEIVE_UPDATE_BOOKING_CART_RESPONSE,
        isSuccessful,
        payload,
        modalId,
      }),
      clearTabContent: (node) => ({
        type: types.CLEAR_TAB_CONTENT,
        node,
      }),
    }),
    selectors: ({ store }) => ({
      isLoadingTabs: new Selector(({ getStoreState, isLoadingPage }) =>
        createSelector([getStoreState, isLoadingPage], (storeState, pageLoadingStatus) => {
          const storeValues = Object.values(storeState) || [{}];
          const isLoadingTabs = storeValues.some((tab) => get(tab, 'content.loading') || get(tab, 'loading'));
          return pageLoadingStatus || isLoadingTabs;
        })
      ),
      isLoadingTab: new Selector(({ getStoreState, isLoadingPage }) =>
        createSelector([getStoreState, isLoadingPage], (storeState, pageLoadingStatus) => (tabName) => {
          const tab = get(storeState, tabName, {});
          const isLoadingTab = get(tab, 'content.loading') || get(tab, 'loading');
          return pageLoadingStatus || isLoadingTab;
        })
      ),
      getTabTitle: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) => memoize((tab) => get(storeState, `${tab}.content.title`, '')))
      ),
      getTabSubtitle: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) => memoize((tab) => get(storeState, `${tab}.content.subtitle`, '')))
      ),
      getTabs: (state) =>
        memoize(
          (pathname, pageUrl) => updateTabAttributes(get(state, `${store}.content.tabs`), pathname, pageUrl),
          (...args) => JSON.stringify(args)
        ),
      getTabContent: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) =>
          memoize((tab) => {
            const content = get(storeState, `${tab}.content`, {});
            return {
              ...content,
              sections: mapCardSections(content.sections),
            };
          })
        )
      ),
      getTabContentForm: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) =>
          memoize(
            (tab, formType) => {
              const forms = get(storeState, `${tab}.content.forms`, []);
              const form = forms.find((f) => f.formType === formType);
              if (!form) {
                return null;
              }
              return form.sections;
            },
            (...args) => JSON.stringify(args)
          )
        )
      ),
      getTabContentLabels: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) => memoize((tab) => get(storeState, `${tab}.content.labels`, [])))
      ),
      getTabContentSections: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) => memoize((tab) => get(storeState, `${tab}.content.sections`, [])))
      ),
      getFailedNotificationByReference: new Selector(({ getStoreState }) =>
        createSelector(getStoreState, (storeState) =>
          memoize(
            (tab, reference) => {
              const failedNotifications = get(storeState, `${tab}.content.failedNotification`, []);
              return failedNotifications.find((n) => n.reference === reference) || {};
            },
            (...args) => JSON.stringify(args)
          )
        )
      ),
    }),
  });
