import React from 'react';
import { mapCheckboxItems, mapRadioItems } from '../../../../common/Utils';
import { CheckboxList, RadioGroup } from '../../../../components';
import PropTypes from '../../../../PropTypes';
import './StateroomPreferences.scss';

const StateroomPreferences = ({ handleSubmit, beds, labels: { yourBeds }, preferences, passengers, lockedDown }) => {
  const responsiveGrids = [
    {
      tier: 'sm',
      rows: 2,
    },
    {
      tier: 'md',
      rows: 1,
    },
  ];

  return (
    <div className="stateroom-preferences">
      <div className="d-flex col-12 justify-content-center preferences-list">
        <div className="row col-12 col-md-10 stateroom">
          <div className="your-room">{yourBeds}</div>
        </div>
        <form onSubmit={handleSubmit} className="row col-12 col-md-10">
          <fieldset disabled={lockedDown}>
            {beds && (
              <RadioGroup
                className="row flex-md-row flex-column col-12 beds"
                name="bedsList"
                options={mapRadioItems(beds)}
                grids={responsiveGrids}
              />
            )}
            <div className="row d-flex justify-content-between col-12">
              {passengers.map((passenger, index) => {
                const mappedPreferences = preferences.map((p) => ({
                  ...p,
                  reference: `passengers[${index}].${p.reference}`,
                }));
                const items = mapCheckboxItems({
                  items: mappedPreferences,
                  labelField: 'preferenceTitle',
                  idField: 'reference',
                });
                return (
                  <div className="passenger col-md-6 col-12" key={passenger}>
                    <div className="title">{passenger}</div>
                    <div className="checkbox-list">
                      <CheckboxList groups={[items]} />
                    </div>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

StateroomPreferences.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    yourRoom: PropTypes.string.isRequired,
  }).isRequired,
  preferences: PropTypes.arrayOf(
    PropTypes.shape({
      preferenceTitle: PropTypes.string,
      reference: PropTypes.string,
    })
  ),
  beds: PropTypes.arrayOf(
    PropTypes.shape({
      bedTitle: PropTypes.string,
      reference: PropTypes.string,
    })
  ),
  passengers: PropTypes.arrayOf(PropTypes.string),
  lockedDown: PropTypes.bool,
};

StateroomPreferences.defaultProps = {
  preferences: [],
  beds: [],
  passengers: [],
  lockedDown: false,
};

export default StateroomPreferences;
