import Button from '@viking-eng/button';
import CartItem from '@viking-eng/cart-item';
import CartItemList from '@viking-eng/cart-item-list';
import moment from 'moment';
import React from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import './CartTimeUpdateModal.scss';

const CartTimeUpdateModal = ({ id, title, subtitle, buttonText, items, onButtonModifyClick }) => {
  return (
    <Modal id={id} title={title} subTitle={subtitle} forceAction>
      {items?.length && (
        <div className="cart-time-modal">
          <div className="mvj-modal">
            <div className="row no-gutters d-flex justify-content-center">
              <div className="col-11">
                {title && <div className="title">{title}</div>}
                {subtitle && <div className="message">{subtitle}</div>}
                {items && (
                  <CartItemList>
                    {items.map((item) => (
                      <div className="cart-item-container" key={item.serviceCode}>
                        <CartItem key={item.serviceCode} className="cart-item" {...item} />
                        <div className="conflict-time">
                          {moment(item.startTime).format('LT')} to
                          {moment(item.endTime).format('LT')}
                        </div>
                        <Button
                          appearance="secondary-blue"
                          onButtonClick={() => onButtonModifyClick(item.startTime, item.serviceCode)}
                          key="continue"
                        >
                          {buttonText}
                        </Button>
                      </div>
                    ))}
                  </CartItemList>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
CartTimeUpdateModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.cartItem),
  onClose: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CartTimeUpdateModal.defaultProps = {
  id: '',
  title: '',
  subtitle: '',
  button: {
    text: '',
  },
  items: [],
};

export default CartTimeUpdateModal;
