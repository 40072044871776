import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';

import { withContent } from '../../components';
import commonStore from '../../common';
import { PAGE_NAMES } from '../../common/Constants';

import Air from './Air';
import airStore, { fetchAirPageContent } from './AirStore';

const { getCustomizableTabs, getTitle, isLoadingTabs } = airStore.selectors;
const { getLabels } = commonStore.selectors;

const mapStateToProps = (state, { location, match }) => ({
  ...getCustomizableTabs(state)(location, match),
  isLoading: isLoadingTabs(state),
  title: getTitle(state),
  tabLabels: get(getLabels(state), `pages.${PAGE_NAMES.AIR}.tabs`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchAirPageContent()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Air);
