import React from 'react';
import getDisplayName from 'recompose/getDisplayName';

import PropTypes from '../../PropTypes';

const withReinitializeForm = (WrappedComponent) => {
  class WithReinitializeForm extends React.Component {
    componentDidUpdate(prevProps) {
      const { initialValues, loaded, reinitializeForm } = this.props;
      if (loaded && loaded !== prevProps.loaded) {
        reinitializeForm(initialValues, {
          keepDirty: false,
          keepSubmitSucceeded: true,
          keepValues: false,
          updateUnregisteredFields: true,
        });
      }
    }

    render() {
      const { loaded, reinitializeForm, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }

  WithReinitializeForm.propTypes = {
    // initialValues' shape is undefined in the HOC (can be different based on the wrapped form)
    initialValues: PropTypes.shape({}),
    loaded: PropTypes.bool,
    reinitializeForm: PropTypes.func.isRequired,
  };

  WithReinitializeForm.defaultProps = {
    initialValues: {},
    loaded: false,
  };

  WithReinitializeForm.displayName = `WithReinitializeForm(${getDisplayName(WrappedComponent)})`;

  return WithReinitializeForm;
};

export default withReinitializeForm;
