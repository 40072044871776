import { connect } from 'react-redux';
import { compose } from 'redux';

import onboardStore, { fetchTabContent } from '../OnboardStore';
import { withContent } from '../../../components';
import { TAB_NAMES } from '../../../common/Constants';

import Preferences from './Preferences';

const { getPreferencesContent } = onboardStore.selectors;
const tabName = TAB_NAMES.ONBOARD_PREFERENCES;

const mapStateToProps = (state) => ({
  content: getPreferencesContent(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(tabName)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Preferences);
