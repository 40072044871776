import memoize from 'lodash/memoize';

const clearMemoizedCache = async () => {
  const { default: calendarStore } = await import('../../pages/calendar/CalendarStore');
  const { default: shorexStore } = await import('../../pages/shorex/ShorexStore');
  const { default: paymentsStore } = await import('../../pages/payments/PaymentsStore');
  const { default: commonStore } = await import('../CommonStore');

  const {
    getCountry,
    getCountriesPopularFirst,
    getCountryStates: getCountryStatesSelector,
    getFooterModalContent,
    getMenuItems,
    getPageTabLabels,
    getProperty,
    getReduxFormError,
    getTabUrl,
    getFlagValue,
  } = commonStore.selectors;
  const { getDayViewCalendarEvents, getBackButton } = calendarStore.selectors;
  const {
    getAccountECheckFields,
    getCreditCardFields,
    getDefaultSavedAccount,
    getManagePaymentMethodInitialValues,
    getPaymentMethodOptions,
  } = paymentsStore.selectors;
  const { getExcursionsOnDate, isLoadingExcursions } = shorexStore.selectors;
  const memoizedSelectors = [
    getCountriesPopularFirst,
    getCountry,
    getFooterModalContent,
    getMenuItems,
    getCountryStatesSelector,
    getPageTabLabels,
    getProperty,
    getReduxFormError,
    getTabUrl,
    getDayViewCalendarEvents,
    getBackButton,
    getPaymentMethodOptions,
    getCreditCardFields,
    getAccountECheckFields,
    getManagePaymentMethodInitialValues,
    getDefaultSavedAccount,
    getExcursionsOnDate,
    isLoadingExcursions,
    getFlagValue,
  ];
  memoizedSelectors.forEach((func) => {
    // eslint-disable-next-line no-param-reassign
    func.cache = new memoize.Cache();
  });
};

export default clearMemoizedCache;
