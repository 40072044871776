import { prepareHtml } from '@viking-eng/utils';
import React from 'react';

import { RESERVATION_STATUS } from '../../../common/Constants';
import PropTypes from '../../../PropTypes';

import AirPlusFormContainer from './AirPlusFormContainer';

import './SideContent.scss';

const { RESERVED } = RESERVATION_STATUS;

const SideContent = ({
  isSubmittingArr,
  passengers,
  isReservationAllowed,
  alertText,
  extensionType,
  inventoryCode,
  pricePerPassenger,
  serviceCode,
  title,
  isUKAU,
  airPlusUKAUMessage,
}) => {
  const isAllReserved = passengers.every(({ status }) => status === RESERVED);
  return (
    <div>
      {!isUKAU ? (
        <div className="air-modal-side-content">
          {!isAllReserved && alertText && <div className="global-message validation-error-text">{alertText}</div>}
          {passengers.map((passenger) => {
            const { button, key, status, guestName, reservationMessage } = passenger;
            const isOtherSubmitting = key === 1 ? isSubmittingArr[1] : isSubmittingArr[0];
            return (
              <div key={key}>
                {isAllReserved ? (
                  <div className="passenger-name text-uppercase">{guestName}</div>
                ) : (
                  <AirPlusFormContainer
                    modalData={{
                      extensionType,
                      inventoryCode,
                      pricePerPassenger,
                      serviceCode,
                      title,
                    }}
                    alertText={alertText}
                    isOtherSubmitting={isOtherSubmitting}
                    isReservationAllowed={isReservationAllowed}
                    passenger={{
                      button,
                      key,
                      status,
                      guestName,
                      reservationMessage,
                    }}
                  />
                )}
              </div>
            );
          })}
          {isAllReserved && alertText && <div className="all-reserved validation-error-text">{alertText}</div>}
        </div>
      ) : (
        <div
          className="booking-assistance font-weight-bold"
          dangerouslySetInnerHTML={prepareHtml(airPlusUKAUMessage)}
        />
      )}
    </div>
  );
};

SideContent.propTypes = {
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      guestName: PropTypes.string.isRequired,
    })
  ),
  isReservationAllowed: PropTypes.bool,
  alertText: PropTypes.string,
  extensionType: PropTypes.string,
  inventoryCode: PropTypes.string,
  pricePerPassenger: PropTypes.number,
  serviceCode: PropTypes.string,
  title: PropTypes.string,
  isSubmittingArr: PropTypes.arrayOf(PropTypes.bool),
  isUKAU: PropTypes.bool,
  airPlusUKAUMessage: PropTypes.string,
};

SideContent.defaultProps = {
  isReservationAllowed: true,
  alertText: null,
  passengers: [],
  extensionType: '',
  inventoryCode: '',
  pricePerPassenger: null,
  serviceCode: '',
  title: '',
  isSubmittingArr: [false, false],
  isUKAU: false,
  airPlusUKAUMessage: '',
};

export default SideContent;
