import { COUNTRIES } from '../Constants';

/* eslint-disable import/prefer-default-export */
export const numbersOnly = (value) => value && value.replace(/[^\d]/, '');

export const upperCase = (value) => value && value.toUpperCase();

const normalizeNumericMaxLength = (value, maxLength) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '').slice(0, maxLength);
};

const normalizeMaxLength = (value, maxLength) => {
  if (!value) {
    return value;
  }
  return value
    .replace(/[^\w ]/g, '')
    .toUpperCase()
    .slice(0, maxLength);
};

export const normalizeUSZipCode = (value) => normalizeNumericMaxLength(value, 5);

export const normalizeZipCode = (value) => normalizeMaxLength(value, 10);

export const normalizeCAPostalCode = (value) => {
  if (!value) {
    return value;
  }
  const replaceNonAlphaNumeric = upperCase(value.replace(/[\W_]+/g, ''));
  const numbersOnly = /^[\d]/;
  if (!numbersOnly.test(value)) {
    if (replaceNonAlphaNumeric.length <= 3) {
      return replaceNonAlphaNumeric;
    }
    return replaceNonAlphaNumeric.substr(0, 6);
  }
  return null;
};

export const normalizeAUPostCode = (value) => {
  const numbersOnly = value.replace(/[\D_]+/g, '');
  if (numbersOnly.length <= 4) {
    return numbersOnly;
  }
  return numbersOnly.substr(0, 4);
};

const normalizeGBPostalCode = (value) => {
  const postCode = value
    .trimStart()
    .replace(/[^\w ]/g, '')
    .replace(/ {2}/g, ' ')
    .toUpperCase();
  const hasSpace = postCode.split(' ').length - 1 === 2;
  return hasSpace || postCode.length > 5 ? postCode.substr(0, 8).trimEnd() : postCode.substr(0, 8);
};

export const normalizeNZPostCode = (value) => {
  const numbersOnly = value.replace(/[\D_]+/g, '');
  if (numbersOnly.length <= 4) {
    return numbersOnly;
  }
  return numbersOnly.substr(0, 4);
};

export const ZipPostalCodeNormalizer = (country) => {
  switch (country) {
    case COUNTRIES.CANADA:
      return normalizeCAPostalCode;
    case COUNTRIES.AUSTRALIA:
      return normalizeAUPostCode;
    case COUNTRIES.NEW_ZEALAND:
      return normalizeNZPostCode;
    case COUNTRIES.UNITED_KINGDOM:
    case COUNTRIES.UNITED_KINGDOM_GB:
      return normalizeGBPostalCode;
    case COUNTRIES.UNITED_STATES:
      return normalizeUSZipCode;
    default:
      return normalizeZipCode;
  }
};
