import Button from '@viking-eng/button';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import classNames from 'classnames';
import React from 'react';
import { FieldArray, FormSection } from 'redux-form';

import { Field } from '../..';
import { alphanumericMask } from '../../../common/forms/Masks';
import { ERROR_CODES, validateFrequentFlyer } from '../../../common/forms/Validations';
import PropTypes from '../../../PropTypes';
import QuestionMarkTooltip from '../../questionMarkTooltip/QuestionMarkTooltip';

import './AirPreferencesForm.scss';

const FrequentFlyerGroup = ({ fields, airline, frequentFlyerNumber, addAnother, lockedDown }) => (
  <div className="row no-gutters frequent-flyer-group">
    {fields.map((frequentFlyer, index) => (
      <div className="col-12 frequent-flyer" key={`${frequentFlyer}.sequence`}>
        <div className="field-wrapper">
          <Field
            name={`${frequentFlyer}.airlineCode`}
            component={Select}
            disabled={lockedDown}
            placeholder={airline.label}
            isSearchable
            options={airline.options}
            validate={[validateFrequentFlyer]}
            errorCodes={{
              [ERROR_CODES.INVALID]: 'FrequentFlyerAirlineRequired',
              [ERROR_CODES.INVALID_DUPLICATE_AIRLINE]: 'DuplicateAirline',
            }}
          />
        </div>
        <div className="field-wrapper">
          <Field
            name={`${frequentFlyer}.frequentFlyerNumber`}
            type="text"
            component={InputText}
            placeholder={frequentFlyerNumber.label}
            maxLength={25}
            {...alphanumericMask(25)}
            validate={[validateFrequentFlyer]}
            errorCodes={{
              [ERROR_CODES.INVALID]: 'FrequentFlyerNumberRequired',
              [ERROR_CODES.INVALID_DUPLICATE_FFNUMBER]: 'DuplicateFrequentFlyerNumber',
            }}
          />
          {!index && <QuestionMarkTooltip tooltipText={frequentFlyerNumber.tooltip} />}
        </div>
      </div>
    ))}
    {fields.length < 3 && (
      <div className="col-12 d-flex justify-content-end">
        <Button disabled={lockedDown} appearance="link" onButtonClick={() => fields.push({ sequence: fields.length })}>
          {addAnother}
        </Button>
      </div>
    )}
  </div>
);

FrequentFlyerGroup.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }).isRequired,
  airline: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.options,
  }).isRequired,
  frequentFlyerNumber: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  addAnother: PropTypes.string.isRequired,
  lockedDown: PropTypes.bool,
};

FrequentFlyerGroup.defaultProps = {
  lockedDown: false,
};

const AirPreferencesFormPart = ({
  airline,
  passengerName,
  redressNumber,
  knownTravelerNumber,
  frequentFlyerNumber,
  wheelchairAssistance,
  specialMeal,
  meetAssist,
  index,
  showTooltips,
  fields,
  addAnother,
  lockedDown,
}) => (
  <FormSection name={`passengers[${index}]`}>
    <fieldset
      disabled={lockedDown}
      className={classNames('air-preferences-form', { 'force-show-tooltips': showTooltips })}
    >
      {!!passengerName && <div className="passenger-name">{passengerName}</div>}
      {fields.includes('redressNumber') && (
        <div className="field-wrapper">
          <Field
            name="redressNumber"
            type="text"
            placeholder={redressNumber.label}
            maxLength={25}
            component={InputText}
            {...alphanumericMask(25)}
          />
          <QuestionMarkTooltip tooltipText={redressNumber.tooltip} />
        </div>
      )}
      {fields.includes('knownTravelerNumber') && (
        <div className="field-wrapper">
          <Field
            name="knownTravelerNumber"
            type="text"
            component={InputText}
            placeholder={knownTravelerNumber.label}
            maxLength={15}
            {...alphanumericMask(15)}
          />
          <QuestionMarkTooltip tooltipText={knownTravelerNumber.tooltip} />
        </div>
      )}
      {fields.includes('frequentFlyers') && (
        <div className="field-wrapper">
          <FieldArray
            name="frequentFlyers"
            component={FrequentFlyerGroup}
            airline={airline}
            frequentFlyerNumber={frequentFlyerNumber}
            lockedDown={lockedDown}
            addAnother={addAnother}
          />
        </div>
      )}
      {fields.includes('wheelchair') && (
        <div className="field-wrapper">
          <Field
            name="wheelchair"
            type="text"
            component={Select}
            disabled={lockedDown}
            placeholder={wheelchairAssistance.label}
            options={wheelchairAssistance.options}
          />
          <QuestionMarkTooltip tooltipText={wheelchairAssistance.tooltip} />
        </div>
      )}
      {fields.includes('meal') && (
        <div className="field-wrapper">
          <Field
            name="meal"
            type="text"
            component={Select}
            disabled={lockedDown}
            placeholder={specialMeal.label}
            options={specialMeal.options}
          />
          <QuestionMarkTooltip tooltipText={specialMeal.tooltip} />
        </div>
      )}
      {fields.includes('meetAndAssist') && (
        <div className="field-wrapper">
          <Field
            name="meetAndAssist"
            type="text"
            component={Select}
            disabled={lockedDown}
            placeholder={meetAssist.label}
            options={meetAssist.options}
          />
          <QuestionMarkTooltip tooltipText={meetAssist.tooltip} />
        </div>
      )}
    </fieldset>
  </FormSection>
);

const optionsType = PropTypes.shape({
  label: PropTypes.string,
  options: PropTypes.options,
  tooltip: PropTypes.string,
});

AirPreferencesFormPart.propTypes = {
  airline: optionsType,
  passengerName: PropTypes.string,
  wheelchairAssistance: optionsType,
  specialMeal: optionsType,
  meetAssist: optionsType,
  redressNumber: PropTypes.shape({}),
  frequentFlyerNumber: PropTypes.shape({}),
  knownTravelerNumber: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
  showTooltips: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string),
  addAnother: PropTypes.string,
  lockedDown: PropTypes.bool,
};

AirPreferencesFormPart.defaultProps = {
  airline: { label: '', options: [], tooltip: '' },
  passengerName: '',
  wheelchairAssistance: { label: '', options: [], tooltip: '' },
  specialMeal: { label: '', options: [], tooltip: '' },
  meetAssist: { label: '', options: [], tooltip: '' },
  redressNumber: { label: '', tooltip: '' },
  frequentFlyerNumber: { label: '', tooltip: '' },
  knownTravelerNumber: { label: '', tooltip: '' },
  showTooltips: false,
  fields: [],
  addAnother: '',
  lockedDown: false,
};

export default AirPreferencesFormPart;
