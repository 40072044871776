import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearWaiter, getWaiter } from 'redux-waiter';
import commonStore, { history } from '../../../common';
import { codify } from '../../../common/Analytics';
import { fetchCommonContent } from '../../../common/CommonStore';
import {
  AIR_FLOW_NAMES,
  AIR_PATHS,
  APP_PATHS,
  COUNTRIES,
  FIVE_BY_FOUR,
  MODALS,
  PAGE_NAMES,
  RESERVATION_NUMBERS,
  RESPONSE_STATUS,
  SEATS,
  TAB_NAMES,
  VIEWERSHIP_STATUSES,
  WAITER_NAMESPACES,
} from '../../../common/Constants';
import { clearSpecificModal, setViewAndShowModal } from '../../../common/ModalStore';
import userStore, { fetchBookings } from '../../../common/UserStore';
import {
  getImageAttributes,
  getPassengerFullName,
  goTo,
  replaceCMSTokenWithValue,
  replaceToken,
  stripHTMLTags,
} from '../../../common/Utils';
import { withContent } from '../../../components';
import mvjAirStore from '../../air/AirStore';
import airStore, {
  cancelTimer,
  fetchTabContent,
  navigateTo,
  postFlightDetails,
  resetSearch,
  setAirBookingFlow,
  setPreAssignedSeats,
  updateSeatMapData,
} from '../AirStore';
import AirBooking from './AirBooking';

// import { AirPlusModal, ComparisonModal } from '../../../modals';

const {
  getLabels,
  getFetchingCommonContent,
  getPaymentsAllEnabled,
  getPaymentsCheckoutEnabled,
  getMvjStrings,
} = commonStore.selectors;
const {
  getPassengers,
  getBookingNumber,
  getBookingDetails,
  getIsTaAccount,
  getBookingLoadingFlag,
  getIsViewOnly,
} = userStore.selectors;
const {
  getChosenFlights,
  getViewershipStatuses,
  getAirCms,
  getMVJSectionCards,
  getReferenceNumber,
  getItinerariesDiffer,
  getAirPassengers,
  getAirPassengersSkipPassengerSelection,
  getIsAirViewOnly,
  getPreAssignedSeats,
  getSeatTimeOutCallCount,
  getPreviewSeatsForPaxIndex,
  getPreAssignedSeatsSplitPnr,
} = airStore.selectors;
const {
  receiveChosenFlightDetails,
  setFlightSelection,
  setAllSeatMapsUnavailable,
  setSelectedPassengerIndex,
  setChosenFlights,
  setIsAirViewOnly,
  setIsACH,
  setBookedSeats,
  setCheckoutVoucherValue,
  setSeatTimeOutCallCount,
  setPreviewSeatSForPaxIndex,
  setPreSeat1,
} = airStore.creators;
const { getAirModalData } = mvjAirStore.selectors;

const { CHANGE_SEATS, UPGRADE_CLASS } = AIR_FLOW_NAMES;

const {
  GET_AIR_SCHEDULES,
  AIR_VIEWED,
  POST_FLIGHT_DETAILS,
  GET_SAVE_SEATS,
  GET_SEARCH_REQS,
  SEATMAP,
} = WAITER_NAMESPACES;

const pageAnalyticsData = (state) => {
  return {
    page_name: 'Air Home',
    viewership_rules: getViewershipStatuses(state),
    voyage_id: get(state, 'user.bookingDetails.voyage.id', ''),
    cruise_id: get(state, 'user.bookingDetails.cruise.tridionId', ''),
    cruise_name: codify(stripHTMLTags(get(state, 'user.bookingDetails.cruise.title', ''))),
    user_type: get(state, 'user.authData.attributes.custom:userType', ''),
    user_session: get(state, 'user.analyticsSessionId', ''),
    booking_id: getBookingNumber(state),
  };
};

const mapStateToProps = (state) => {
  const cms = getAirCms(state);
  const allflights = getChosenFlights(state);
  const previewSeatsForPaxIndex = getPreviewSeatsForPaxIndex(state);
  const viewershipStatus = getViewershipStatuses(state);
  const filteredFlights = {
    ...allflights,
    passengers: (allflights.passengers || []).filter(
      (pax) => get(pax, 'flightSchedule.pre', []).length > 0 || get(pax, 'flightSchedule.post', []).length > 0
    ),
  };

  const itinerariesDiffer = getItinerariesDiffer(state);
  const passengersFlights = get(filteredFlights, ['passengers'], []);

  const isDirectBooking = get(getBookingDetails(state), 'directBooking', '');
  const bookingOffice = get(getBookingDetails(state), 'office', 'US');
  const isUKBooking = bookingOffice === COUNTRIES.UNITED_KINGDOM;
  const isAUBooking = bookingOffice === COUNTRIES.AUSTRALIA;
  const isTALoggedIn = getIsTaAccount(state);
  const noTAMessaging = isDirectBooking || isTALoggedIn;
  const isReadOnly = viewershipStatus.includes(VIEWERSHIP_STATUSES.READ_ONLY);
  const isTicketed = viewershipStatus.includes(VIEWERSHIP_STATUSES.TICKETED);
  const isViewOnly =
    (getIsViewOnly(state) &&
      !viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_PAYMENT) &&
      !viewershipStatus.includes(VIEWERSHIP_STATUSES.AIR_PENDING)) ||
    getIsAirViewOnly(state);
  const isNoVikingAir = viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_VIKING_AIR);
  const hasNoAir = viewershipStatus.includes(VIEWERSHIP_STATUSES.NO_AIR_PAX);

  const getTicketNumbers = (paxIndex) => {
    const paxPre = get(passengersFlights[paxIndex], ['flightSchedule', 'pre'], []);
    const paxPost = get(passengersFlights[paxIndex], ['flightSchedule', 'post'], []);
    const paxAllFlightsRaw = [...paxPre, ...paxPost];
    const paxAllFlights = paxAllFlightsRaw.filter((item) => item.type !== 'label');
    let allTickets = [];
    // eslint-disable-next-line array-callback-return
    paxAllFlights.map((flight) => {
      if (flight.eTicket.length) {
        allTickets = allTickets.concat(flight.eTicket);
      }
    });
    return allTickets.length > 0 ? allTickets : [];
  };
  const ticketNumbersPax1 = getTicketNumbers(0);
  const ticketNumbersPax2 = passengersFlights.length > 1 ? getTicketNumbers(1) : [];
  const abePaymentRequiredMessageDirect = get(cms.abePaymentRequired, 'subtitle', '');
  const abePaymentRequiredMessageTA = get(cms.abeTAFinalPaymentDue, 'title', '');
  const abePaymentRequiredMessage = noTAMessaging ? abePaymentRequiredMessageDirect : abePaymentRequiredMessageTA;
  const abePaymentRequiredBtn = noTAMessaging
    ? {
        text: get(cms.abePaymentRequired, 'callToActionTitle', 'Booking Payment'),
        onButtonClick: () => history.push(APP_PATHS.CRUISE_PAYMENTS),
      }
    : {};

  const passengers = getPassengers(state);
  const passengersFormatted = passengers.map((passenger) => ({
    name: getPassengerFullName(passenger).toUpperCase(),
    number: passenger.passengerNumber,
  }));
  const preAndPostFlights = passengers.reduce((acc, passenger) => {
    if (passenger.air?.pre?.length > 0) {
      acc.push(passenger.air.pre[0]);
    }
    if (passenger.air?.post?.length > 0) {
      acc.push(passenger.air.post[0]);
    }
    return acc;
  }, []);
  const isItineraryBusiness = preAndPostFlights.every((flight) => flight && flight.airClassName === 'Business');
  const referenceNumber = getReferenceNumber(state);
  const getBookingStatus = get(getBookingDetails(state), 'bookingStatus', '');
  const airPhoneNumber = get(cms.AIRPHONE, 'longText', RESERVATION_NUMBERS.US);

  const ancillariesTooltipNoteCMS = cms.ancillariesTooltipNote;
  const amenitiesNotAvailableCMS = cms.abeAmenitiesNotAvailable;
  const ancillariesTooltipNoteTitle = get(ancillariesTooltipNoteCMS, 'subtitle', 'amenites vary by class of service.');

  const amenitiesNotAvailable = get(
    amenitiesNotAvailableCMS,
    'title',
    'Amenities for this flight might not be available.'
  );

  const outboundDeviationTypes = passengersFlights.map((pax) => pax.deviationType?.outbound);
  const returnDeviationTypes = passengersFlights.map((pax) => pax.deviationType?.return);

  const hasOutboundDeviation = outboundDeviationTypes.some((type) => type !== 'NONE');
  const hasReturnDeviation = returnDeviationTypes.some((type) => type !== 'NONE');

  const outboundDeviationCMS = cms.mvaOutboundDeviationMessage;
  const returnDeviationCMS = cms.mvaReturnDeviationMessage;
  const bothDeviationCMS = cms.mvaBothDeviationMessage;

  const outboundDeviationMessage = get(outboundDeviationCMS, 'subtitle', '');
  const returnDeviationMessage = get(returnDeviationCMS, 'subtitle', '');
  const bothDeviationMessage = get(bothDeviationCMS, 'subtitle');

  const outboundDeviationReadOnlyMessage = replaceToken(
    get(cms.mvaOutboundDeviationMessageReadOnly, 'subtitle', ''),
    'PHONE',
    airPhoneNumber
  );
  const returnDeviationReadOnlyMessage = replaceToken(
    get(cms.mvaReturnDeviationMessageReadOnly, 'subtitle', ''),
    'PHONE',
    airPhoneNumber
  );
  const bothDeviationReadOnlyMessage = replaceToken(
    get(cms.mvaBothDeviationMessageReadOnly, 'subtitle', ''),
    'PHONE',
    airPhoneNumber
  );

  const flightBookedLabelCMS = cms.abeFlightBookedLabel;
  const generalFlightBookedLabelMessage = get(
    flightBookedLabelCMS,
    'subtitle',
    'We have booked your flights - you are all set.'
  );

  const isReturnOnly = get(passengersFlights[0], ['flightSchedule', 'pre'], []).length === 0;
  const isOutboundOnly = get(passengersFlights[0], ['flightSchedule', 'post'], []).length === 0;
  const oneWayWithDeviation = (isReturnOnly && hasReturnDeviation) || (isOutboundOnly && hasOutboundDeviation);

  let flightBookedLabelTitle = generalFlightBookedLabelMessage;
  let readOnlyMessage;

  if ((hasOutboundDeviation && hasReturnDeviation) || oneWayWithDeviation) {
    flightBookedLabelTitle = bothDeviationMessage;
    readOnlyMessage = bothDeviationReadOnlyMessage;
  } else if ((!isReturnOnly && hasReturnDeviation) || isOutboundOnly) {
    flightBookedLabelTitle = returnDeviationMessage;
    readOnlyMessage = returnDeviationReadOnlyMessage;
  } else if ((!isOutboundOnly && hasOutboundDeviation) || isReturnOnly) {
    flightBookedLabelTitle = outboundDeviationMessage;
    readOnlyMessage = outboundDeviationReadOnlyMessage;
  } else if (isUKBooking) {
    readOnlyMessage = get(cms.abeReadOnlyBookingUK, 'subtitle', '');
  } else if (isAUBooking) {
    readOnlyMessage = get(cms.abeReadOnlyBookingAU, 'subtitle', '');
  } else {
    readOnlyMessage = get(cms.abeReadOnlyBookingUSCA, 'subtitle', '');
  }
  const noAirKey = Object.keys(cms).find((key) => key.match(/^bookAirByPhone/g));
  const noAirMessage = get(cms, [noAirKey, 'title'], 'To book Viking Air for this passenger, please call');

  const airlineBookingTextCMS = cms.abeAirlineBookingNumber;
  const airlineBookingText = get(airlineBookingTextCMS, 'title', '');

  const abeMakeChangesWindowCMS = cms.abeMakeChangesWindow;
  const ticketedAccountMessage = replaceCMSTokenWithValue(get(abeMakeChangesWindowCMS, 'subtitle', ''), [
    { key: 'AIRPHONE', value: airPhoneNumber },
  ]);
  const ticketedAccountTitle = get(abeMakeChangesWindowCMS, 'title', '');

  const hasPaidSeatsOrDeltaMessage = get(cms.mvaHasPaidOrDeltaComfortPlus, 'title', '');

  const abeETicketNumberCMS = cms.abeETicketNumber;
  const eTicketNumberText = get(abeETicketNumberCMS, 'title', '');

  const abeFlightChangesProcessingCMS = cms.abeFlightChangesProcessing;
  const flightChangesProcessingText = get(abeFlightChangesProcessingCMS, 'title', '');

  const abeFlightInfoUpdatingCMS = cms.abeFlightInfoUpdating;
  const flightInfoUpdatingText = get(abeFlightInfoUpdatingCMS, 'title', '');

  const abeGIFRequiredCMS = cms.abeGIFRequired;
  const abeGIFRequiredOnLockCMS = cms.abeGIFRequiredOnLock;
  const incompleteGIFText = get(abeGIFRequiredCMS, 'title', '');
  const incompleteGIFLongTextUnlocked = get(abeGIFRequiredCMS, 'longText', '');
  const incompleteGIFLongTextLocked = get(abeGIFRequiredOnLockCMS, 'title', '');
  const incompleteGIFLongText = isViewOnly ? incompleteGIFLongTextLocked : incompleteGIFLongTextUnlocked;
  const incompleteGIFBtn = {
    text: get(abeGIFRequiredCMS, 'callToActionTitle', 'Guest Information Form'),
    onButtonClick: () => history.push(APP_PATHS.GIF),
  };

  const abeUpdatingCheckLaterCMS = cms.abeUpdatingCheckLater;
  const updatingCheckLaterText = get(abeUpdatingCheckLaterCMS, 'title', '');

  const detailsLinkTextCMS = cms.abeFlightDetailsBaggageFees;
  const detailsLinkText = get(detailsLinkTextCMS, 'title', 'FLIGHT DETAILS & BAGGAGE FEES');
  const layoverText = get(cms.abeConnectionTime, 'title', 'Connection Time');
  const airDetailsStatus = get(getWaiter(state, GET_AIR_SCHEDULES), 'response.status', null);

  const isLoading =
    getBookingLoadingFlag(state) ||
    getWaiter(state, GET_AIR_SCHEDULES).isPending ||
    getWaiter(state, GET_AIR_SCHEDULES).response === null ||
    getWaiter(state, AIR_VIEWED).isPending;
  const isLoadingFlightDetails = getWaiter(state, POST_FLIGHT_DETAILS).isPending;

  const isNoBookingPayment = getBookingStatus === 'OFR';
  const paymentPhoneNumber = get(cms.PHONE, 'longText');
  const noBookingPaymentMessageDirect = replaceCMSTokenWithValue(get(cms.abeNoBookingPaymentMessage, 'title', ''), [
    { key: 'AIRPHONE', value: paymentPhoneNumber },
  ]);
  const noBookingPaymentText = get(cms.abeNoBookingPaymentText, 'title', '');
  const noBookingPaymentMessageTA = get(cms.abeTADepositPastDue, 'title', '');
  const noBookingPaymentMessage = noTAMessaging ? noBookingPaymentMessageDirect : noBookingPaymentMessageTA;
  const noBookingPaymentBtn = noTAMessaging
    ? {
        text: get(cms.abeNoBookingPaymentMessage, 'callToActionTitle', 'Make A Payment'),
        onButtonClick: () => history.push(APP_PATHS.CRUISE_PAYMENTS),
      }
    : {};

  const scheduleChangeMessage = get(cms.abeScheduleChangeMessage, 'title', '');
  const scheduleChangeWithOptions = get(cms.abeScheduleChangeWithOptions, 'title', '');
  const scheduleChangeOptions = get(cms.abeGuestOptions, 'longText', '');
  const scheduleChangeWithoutOptions = get(cms.abeScheduleChangeText, 'title', '');
  const unavailableSeatTooltipText = get(
    cms.abeSeatsAutomaticallyAssigned,
    'title',
    'Seats will automatically be assigned before your journey.'
  );
  const pendingSeatTooltipText = get(
    cms.abeSeatsPending,
    'title',
    'The seats selected are in pending status. Please contact the airline directly to confirm the status.'
  );

  const getCardData = (cmsCardData, card, primaryButtonPath, id) => {
    const description = replaceCMSTokenWithValue(get(cmsCardData, 'description', ''), [
      { key: 'AIRPHONE', value: airPhoneNumber },
    ]);
    const detailedDescription = replaceCMSTokenWithValue(get(cmsCardData, 'detailedDescription', ''), [
      { key: 'AIRPHONE', value: airPhoneNumber },
    ]);
    const title = get(cmsCardData, 'title', '');
    const text = get(cmsCardData, 'primaryButtonText', '');
    const imagesRaw = get(cmsCardData, 'images', '');
    const images = imagesRaw
      ? imagesRaw.map((image) => ({
          ...image,
          src: image.url,
          className: 'img-fluid',
          tier: image.type === FIVE_BY_FOUR ? 'md' : 'xs',
        }))
      : '';

    let primaryButton = null;
    if (primaryButtonPath) {
      primaryButton = {
        primaryButton: {
          text,
          onButtonClick: () => {
            goTo(primaryButtonPath)();
          },
          attributes: {
            role: 'link',
          },
        },
      };
    } else {
      primaryButton = {
        primaryButtonText: text,
        primaryButtonUrl: '#ComparisonModal',
        secondaryButtonText: 'DETAILS',
        primaryButton: { text },
      };
    }
    const mappedImages = (images || []).map((image) => getImageAttributes({ image }));
    return {
      ...card,
      id,
      description,
      detailedDescription,
      images: mappedImages,
      thumbnailRatio: FIVE_BY_FOUR,
      imageRatio: [FIVE_BY_FOUR],
      title,
      ...primaryButton,
    };
  };

  const cards = getMVJSectionCards(state);
  const card2 = cards.find((c) => c.reference === 'vikingAir');
  const card3 = cards.find((c) => c.reference === 'Class-Upgrade');

  const airCardsRaw = [];
  airCardsRaw.push(getCardData(cms.inTheMeantime, undefined, APP_PATHS.AIR_PREFERENCES, '1'));
  airCardsRaw.push(getCardData(cms.AIRPLR, card2, null, '2'));
  airCardsRaw.push(getCardData(cms.abeClassUpgrade, card3, null, '3'));

  const getAirCards = (cardsArray) => {
    if (isTicketed) {
      return [];
    }
    if (isNoVikingAir || hasNoAir) {
      return cardsArray.filter((card) => card.title !== 'Flight Preferences' && card.title !== 'Flight Upgrades');
    }
    if (isItineraryBusiness || isReadOnly) {
      return cardsArray.filter((card) => card.title !== 'Flight Upgrades');
    }
    return cardsArray;
  };
  const airCardsFiltered = airCardsRaw.filter((card) => card.title !== '');
  const airCards = getAirCards(airCardsFiltered);
  const modalData = getAirModalData(state);

  const baggagePolicyAriaText = get(cms.BaggagePolicyTxt, 'title', '');
  const preAssignedSeatsObj = getPreAssignedSeats(state);
  const preAssignedSeatsSplitPnr = getPreAssignedSeatsSplitPnr(state);
  let preAssignedSeatsPax1;
  let preAssignedSeatsPax2;
  if (itinerariesDiffer && !previewSeatsForPaxIndex) {
    preAssignedSeatsPax1 = preAssignedSeatsSplitPnr;
  } else if (itinerariesDiffer && previewSeatsForPaxIndex) {
    preAssignedSeatsPax2 = preAssignedSeatsSplitPnr;
  } else {
    preAssignedSeatsPax1 = get(preAssignedSeatsObj, ['pax1AssignedSeats'], []);
    preAssignedSeatsPax2 = get(preAssignedSeatsObj, ['pax2AssignedSeats'], []);
  }

  const seatMapData = get(getWaiter(state, SEATMAP), 'response.data', '');
  const seatMapStatus = get(getWaiter(state, SEATMAP), 'response.status', '');
  const seatMapCabins = get(seatMapData, 'cabins', []);
  const internalCode = get(getWaiter(state, SEATMAP), 'response.data.internalCode', '');
  const allowPaidSeats = get(seatMapData, 'allowPaidSeats', false);
  const oneOrMoreSeatsAvailable = seatMapCabins.some((c) => c.availableSeats > 0);

  const seatMapTimeOut = seatMapStatus === RESPONSE_STATUS.FAILURE_ERROR_CODE_500;
  const seatMapNotAvailable =
    seatMapStatus === RESPONSE_STATUS.FAILURE_ERROR_CODE ||
    seatMapStatus === RESPONSE_STATUS.FAILURE_TIMEOUT_408 ||
    internalCode === RESPONSE_STATUS.FAILURE_NOT_FOUND ||
    seatMapStatus === RESPONSE_STATUS.FAILURE_NOT_FOUND_404 ||
    !oneOrMoreSeatsAvailable;
  const seatMapDataIsLoading = getWaiter(state, SEATMAP).isPending;
  const emptySeatMapDesktop = get(cms, ['abeSeatMapNotAvailableDesktop', 'subtitle'], '');
  const emptySeatMapMobile = get(cms, ['abeSeatMapNotAvailableMobile', 'subtitle'], '');
  const previewSeatsCMS = cms.abePreviewSeats;
  const previewSeatsTitle = get(previewSeatsCMS, 'title', '');
  const previewSeatsSubTitle = get(previewSeatsCMS, 'subtitle', '');
  const tryAgainText = get(cms, ['abeSeatmapNotLoaded', 'title'], '');

  return {
    previewSeatsForPaxIndex,
    preAssignedSeatsPax1,
    preAssignedSeatsPax2,
    tryAgainText: tryAgainText.split('[BUTTON]'),
    seatTimeOutCallCount: getSeatTimeOutCallCount(state),
    previewSeatsTitle,
    previewSeatsSubTitle,
    emptySeatMapDesktop,
    emptySeatMapMobile,
    seatMapCabins,
    allowPaidSeats,
    seatMapTimeOut,
    seatMapNotAvailable,
    seatMapDataIsLoading,
    tabLabels: get(getLabels(state), `pages.${PAGE_NAMES.NEW_AIR}.tabs`, {}),
    labels: get(getLabels(state), `pages.${PAGE_NAMES.NEW_AIR}.labels`, {}),
    viewershipStatus,
    referenceNumber,
    passengers: passengersFormatted,
    noAirPassengers: (allflights.passengers || []).reduce((acc, pax) => {
      if (get(pax, 'flightSchedule.pre', []).length === 0 && get(pax, 'flightSchedule.post', []).length === 0) {
        const bookingPassenger = passengers.find(({ passengerNumber }) => passengerNumber === pax.passengerNumber);
        if (bookingPassenger) {
          acc.push({
            name: getPassengerFullName(bookingPassenger).toUpperCase(),
            message: noAirMessage,
          });
        }
      }
      return acc;
    }, []),
    passengersFlights,
    itinerariesDiffer,
    ancillariesTooltipNoteTitle,
    amenitiesNotAvailable,
    abePaymentRequiredMessage,
    abePaymentRequiredBtn,
    flightBookedLabelTitle,
    airlineBookingText,
    isLoading,
    isLoadingFlightDetails,
    ticketNumbersPax1,
    ticketNumbersPax2,
    ticketedAccount: ticketNumbersPax1.length > 0 || ticketNumbersPax2.length > 0,
    ticketedAccountMessage,
    hasPaidSeatsOrDeltaMessage,
    eTicketNumberText,
    ticketedAccountTitle,
    getBookingStatus,
    isNoBookingPayment,
    noBookingPaymentMessage,
    noBookingPaymentText,
    noBookingPaymentBtn,
    scheduleChangeMessage,
    scheduleChangeWithOptions,
    scheduleChangeOptions,
    scheduleChangeWithoutOptions,
    flightChangesProcessingText,
    flightInfoUpdatingText,
    updatingCheckLaterText,
    readOnlyMessage,
    airCards,
    modalData,
    analyticsData: pageAnalyticsData(state),
    detailsLinkText,
    incompleteGIFText,
    incompleteGIFLongText,
    incompleteGIFBtn,
    isItineraryBusiness,
    operatedByText: SEATS.OPERATED_BY,
    seatText: SEATS.SEAT,
    paidSeatText: SEATS.PAID_SEAT,
    seatUnavailableText: SEATS.UNAVAILABLE,
    seatUnselectedText: SEATS.UNSELECTED,
    seatPendingText: SEATS.PENDING,
    unavailableSeatTooltipText,
    pendingSeatTooltipText,
    isViewOnly,
    isPaymentsDisabled: !getPaymentsAllEnabled(state) || !getPaymentsCheckoutEnabled(state),
    airDetailsStatus,
    layoverText,
    baggagePolicyAriaText,
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onBaggagePolicy: (modalId) => {
    dispatch(setViewAndShowModal(modalId));
  },
  onPreviewSeats: (segmentNumber, paxIndex, pNumber) => {
    dispatch(setPreviewSeatSForPaxIndex(paxIndex));
    dispatch(setViewAndShowModal(`m-${segmentNumber}-p-${pNumber}`));
    dispatch(updateSeatMapData(segmentNumber, null, null, paxIndex));
    dispatch((_, getState) => {
      const itinerariesDiffer = getItinerariesDiffer(getState());
      const preAssignedSeatsObj = getPreAssignedSeats(getState());
      const preAssignedSeatsPax1 = get(preAssignedSeatsObj, ['pax1AssignedSeats'], []);
      const preAssignedSeatsPax2 = get(preAssignedSeatsObj, ['pax2AssignedSeats'], []);
      if (itinerariesDiffer && paxIndex === 0) {
        dispatch(setPreSeat1(preAssignedSeatsPax1));
      }
      if (itinerariesDiffer && paxIndex > 0) {
        dispatch(setPreSeat1(preAssignedSeatsPax2));
      }
    });
  },
  onClickTryAgain: (segmentNumber) =>
    dispatch((_, getState) => {
      const seatTimeOutCallCount = getSeatTimeOutCallCount(getState()) + 1;
      dispatch(updateSeatMapData(segmentNumber));
      dispatch(setSeatTimeOutCallCount(seatTimeOutCallCount));
    }),
  updateSeatMapDataOnSegmentSelectChange: (segmentNumber, paxIndex) => {
    dispatch(updateSeatMapData(segmentNumber, null, null, paxIndex));
  },
  setAirBookingFlow: (flow) => dispatch(setAirBookingFlow(flow)),
  changeClick: (flow) => {
    dispatch(setAirBookingFlow(flow));
    if (flow === CHANGE_SEATS) {
      dispatch(setAllSeatMapsUnavailable(true));
    }
    return dispatch((_, getState) => {
      const itinerariesDiffer = getItinerariesDiffer(getState());
      if (flow === CHANGE_SEATS && !itinerariesDiffer) {
        return dispatch(navigateTo(AIR_PATHS.SEATS));
      }

      const airPassengers = getAirPassengersSkipPassengerSelection(getState());
      if (airPassengers.length === 1) {
        dispatch(resetSearch());
        const chosenFlights = getChosenFlights(getState());
        const passengers = getAirPassengers(getState());

        const airPaxId = airPassengers[0].airPassengerId;
        const filteredPassengers = passengers.filter((pax) => pax.airPassengerId === airPaxId);
        const paxIndex = passengers.findIndex((pax) => pax.airPassengerId === airPaxId) || 0;

        dispatch(setSelectedPassengerIndex(paxIndex));
        dispatch(setChosenFlights({ ...chosenFlights, passengers: filteredPassengers }));
        return dispatch(navigateTo(flow === CHANGE_SEATS ? AIR_PATHS.SEATS : AIR_PATHS.SEARCH));
      }
      return dispatch(navigateTo(AIR_PATHS.SELECT_PASSENGERS));
    });
  },
  fetchContent: async () => {
    dispatch(setIsAirViewOnly(false));
    dispatch(setIsACH(false));
    dispatch(setBookedSeats([]));
    dispatch(setCheckoutVoucherValue(0));
    dispatch(clearWaiter(GET_AIR_SCHEDULES));
    dispatch(clearWaiter(GET_SAVE_SEATS));
    dispatch(clearWaiter(`${UPGRADE_CLASS}-${GET_SEARCH_REQS}`));
    dispatch(clearWaiter(GET_SEARCH_REQS));
    dispatch(resetSearch());
    dispatch(setFlightSelection(''));
    dispatch(setAirBookingFlow(''));
    dispatch(clearSpecificModal(MODALS.AIR_LEAVE_PAGE));
    await dispatch(fetchBookings(undefined, true, true));
    await dispatch(fetchTabContent(TAB_NAMES.AIR_BOOKING));
    await dispatch((_, getState) => {
      const flightChosen = get(getChosenFlights(getState()), ['passengers'], {});
      dispatch(setPreAssignedSeats(flightChosen));
    });
  },
  onFlightDetails: async () => {
    await dispatch((_, getState) => {
      const state = getState();
      const commonContent = get(state, 'common.content', null);
      if (!getFetchingCommonContent(state) && !commonContent) {
        dispatch(fetchCommonContent());
      }
      const flightSchedulePax1 = get(getChosenFlights(state), ['passengers', 0, 'flightSchedule'], {});
      const flightSchedulePax2 = get(getChosenFlights(state), ['passengers', 1, 'flightSchedule'], {});
      const segmentsRawPax1 = [...get(flightSchedulePax1, 'pre', []), ...get(flightSchedulePax1, 'post', [])];
      const segmentsRawPax2 = [...get(flightSchedulePax2, 'pre', []), ...get(flightSchedulePax2, 'post', [])];
      const segmentsPax1 = segmentsRawPax1.filter((item) => item.type !== 'label');
      const segmentsPax2 = segmentsRawPax2.filter((item) => item.type !== 'label');
      const segmentsObjectPax1 = { passenger: 1, segments: segmentsPax1 };
      const segmentsObjectPax2 = { passenger: 2, segments: segmentsPax2 };
      const segments =
        segmentsPax2.length > 0 ? [].concat(segmentsObjectPax1, segmentsObjectPax2) : [segmentsObjectPax1];
      return dispatch(postFlightDetails(segments)).then((res) => {
        if (res.status === 200) {
          dispatch(receiveChosenFlightDetails(get(res, 'data', [])));
        }
      });
    });
  },
  handleCardDetailsClicked: (view, data) => dispatch(setViewAndShowModal(view, data)),
  cancelTimer: () => dispatch(cancelTimer()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(AirBooking);
