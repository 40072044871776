import kebabCase from 'lodash/kebabCase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';

import commonStore from '../../common';
import { APP_PATHS, FORMS, USER_TYPES } from '../../common/Constants';
import history from '../../common/history';
import userStore from '../../common/UserStore';
import { withContent } from '../../components';

import Help from './Help';
import helpStore, { fetchHelpPageContent } from './HelpStore';

const { getTitle, getCallToActionUrl, getFaqCategories, getSearchResults, isLoadingPage } = helpStore.selectors;
const { handleUpdateFAQSearchValue, updateActiveCategory } = helpStore.creators;
const { getBackButton, getIsBookingsMadeViaTA } = commonStore.selectors;
const { getUserType } = userStore.selectors;

const mapStateToProps = (state) => {
  const userType = getUserType(state);
  const isBookingsMadeViaTA = getIsBookingsMadeViaTA(state);
  const isTaBooking = userType === USER_TYPES.CONSUMER && isBookingsMadeViaTA;
  return {
    title: getTitle(state),
    callToActionUrl: getCallToActionUrl(state),
    backButton: getBackButton(state),
    searchResults: getSearchResults(state),
    isLoading: isLoadingPage(state),
    ...getFaqCategories(state),
    isTaBooking,
  };
};

const handleCategoryChange = (index, category) => (dispatch) => {
  const categorySlug = kebabCase(category.name);
  history.push(`${APP_PATHS.HELP}/${categorySlug}`);
  dispatch(updateActiveCategory(index));
  dispatch(handleUpdateFAQSearchValue(''));
  dispatch(change(FORMS.FAQ_SEARCH, 'searchValue', ''));
};

const handleDropdownChange = (name, categories) => (dispatch) => {
  const index = categories.findIndex((item) => item.name === name);
  const categorySlug = kebabCase(name);
  history.push(`${APP_PATHS.HELP}/${categorySlug}`);
  dispatch(updateActiveCategory(index));
  dispatch(handleUpdateFAQSearchValue(''));
  dispatch(change(FORMS.FAQ_SEARCH, 'searchValue', ''));
};

const handleSearchFaq = (value) => (dispatch, getState) => {
  const state = getState();
  const categories = getFaqCategories(state)?.categories;

  dispatch(handleUpdateFAQSearchValue(value));

  if (value) {
    dispatch(updateActiveCategory(categories?.length));
    history.push(`${APP_PATHS.HELP}`);
  } else {
    history.push(`${APP_PATHS.HELP}`);
    dispatch(updateActiveCategory(0));
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchHelpPageContent()),
  handleCategoryChange: (...args) => dispatch(handleCategoryChange(...args)),
  handleDropdownChange: (name, categories) => dispatch(handleDropdownChange(name, categories)),
  handleSearchFaq: (value) => dispatch(handleSearchFaq(value)),
  handleClearSearch: () => {
    dispatch(change(FORMS.FAQ_SEARCH, 'searchValue', ''));
    dispatch(handleSearchFaq(''));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Help);
