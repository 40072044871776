import classnames from 'classnames';
import React from 'react';
import { Footer } from '..';
import { VIKING_LOGO_COLOR } from '../../common/Constants';
import { ContactModal } from '../../modals';
import PropTypes from '../../PropTypes';
import './PreAuthWrapper.scss';

const PreAuthWrapper = ({ children, className, footerData, wide }) => (
  <div className={classnames('preauth-page-wrapper', className)}>
    <div className="preauth-page">
      <div className="preauth-page-header">
        <img className="logo-image preauth-page-header-image" src={VIKING_LOGO_COLOR} alt="logo" />
      </div>

      <div className="preauth-page-img-wrapper">
        <div className="preauth-form-wrapper row">
          <div
            className={classnames('preauth-form-container col-12 col-sm-8 offset-sm-2', {
              'col-md-6 offset-md-3 col-xl-4 offset-xl-4': !wide,
              'col-lg-6 offset-lg-3': wide,
              wide,
            })}
          >
            <div className="preauth-form-content-wrapper">{children}</div>
          </div>
        </div>
      </div>
    </div>
    {footerData && footerData.leftmenuItems ? <Footer {...footerData} /> : <ContactModal />}
  </div>
);

PreAuthWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.jsx, PropTypes.bool])).isRequired,
  className: PropTypes.string,
  wide: PropTypes.bool,
  footerData: PropTypes.shape({
    leftFooterLinks: PropTypes.arrayOf(PropTypes.shape({})),
    copyrightText: PropTypes.string,
  }),
};

PreAuthWrapper.defaultProps = {
  wide: false,
  className: '',
  footerData: {},
};

export default PreAuthWrapper;
