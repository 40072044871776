import Icon from '@viking-eng/icon';
import Tooltip from '@viking-eng/tooltip';
import React from 'react';

import PropTypes from '../../PropTypes';
import './QuestionMarkTooltip.scss';

const QuestionMarkTooltip = ({ tooltipText }) =>
  (tooltipText ? (
    <div className="question-mark-tooltip">
      <Tooltip tooltipText={tooltipText} position="top-end">
        <Icon name="help" />
      </Tooltip>
    </div>
  ) : null);

QuestionMarkTooltip.propTypes = {
  tooltipText: PropTypes.jsx,
};

QuestionMarkTooltip.defaultProps = {
  tooltipText: '',
};

export default QuestionMarkTooltip;
