import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  change,
  clearFields,
  clearSubmitErrors,
  formValueSelector,
  reduxForm,
  SubmissionError,
  touch,
} from 'redux-form';
import reduxFormActions from 'redux-form/lib/actions';
import commonStore from '../../common/CommonStore';
import { FORMS } from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import modalStore from '../../common/ModalStore';
import onboardStore, {
  cancelSpaReservation,
  fetchSpaAvailability,
  SPA_MODAL_STEPS,
  updateSpaReservation,
} from '../../pages/onboard/OnboardStore';
import SpaModalSideContent from './SpaModalSideContent';

const { getReservationModalInfo } = commonStore.selectors;
const { updateReservationModalState } = commonStore.creators;
const { clearModal } = modalStore.creators;
const { getLoadingFlag } = onboardStore.selectors;
const { setLoadingFlag } = onboardStore.creators;
const FORM_NAME = FORMS.BOOKING;

const mapStateToProps = (state, { serviceDurations = [] }) => {
  const { state: reservationState, metadata } = getReservationModalInfo(state);

  return {
    state: reservationState,
    reservationModalInfo: metadata,
    loading: getLoadingFlag(state),
    initialValues: {
      formStep: SPA_MODAL_STEPS.GUEST_SELECTION,
      service: serviceDurations.length === 1 ? get(serviceDurations, '0.value') : {},
    },
    serviceDurations,
    ...formValueSelector(FORMS.BOOKING)(state, 'passengerNumber', 'service', 'day', 'formStep', 'time'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSpaDateChange: (serviceCode, passengerNumber, { date, shipCode }) => {
    dispatch(setLoadingFlag(true));
    dispatch(fetchSpaAvailability(serviceCode, passengerNumber, date, shipCode)).then(({ status, availableTimes }) => {
      if (status !== 200) {
        dispatch(clearFields(FORMS.BOOKING, false, false, 'day', 'time'));
        dispatch(reduxFormActions.updateSyncErrors(FORMS.BOOKING, { day: ERROR_CODES.PROCESS_FAILED }));
        dispatch(reduxFormActions.blur(FORMS.BOOKING, 'day'));
        dispatch(touch(FORMS.BOOKING, 'day'));
        dispatch(setLoadingFlag(false));
        return;
      }
      if (!get(availableTimes, 'length', [])) {
        // no times were returned. 'touch' the field so the inline validation will display
        dispatch(touch(FORMS.BOOKING, 'time'));
      }
    });
    dispatch(clearFields(FORMS.BOOKING, false, false, 'time'));
  },
  cancelSpaReservation: (payload) => dispatch(cancelSpaReservation(payload)),
  handleSpaDurationChange: () => {
    dispatch(clearFields(FORMS.BOOKING, false, false, 'day', 'time'));
  },
  updateReservationModalState: (...args) => dispatch(updateReservationModalState(...args)),
  closeModal: () => dispatch(clearModal()),
});

const onSubmit = ({ formStep, ...values }, dispatch, { errors }) => {
  const promise = new Promise((resolve, reject) => {
    dispatch(clearSubmitErrors(FORMS.BOOKING));
    if (!values.passengerNumber) {
      return reject(new SubmissionError({ _error: errors.GuestRequired }));
    }

    if (formStep === SPA_MODAL_STEPS.GUEST_SELECTION) {
      dispatch(change(FORMS.BOOKING, 'formStep', SPA_MODAL_STEPS.BOOKING));
      // rejecting so that the form's submitted state goes back to false,
      // so we can submit again after next step
      return reject();
    }

    return dispatch(updateSpaReservation(values))
      .then(() => {
        dispatch(change(FORMS.BOOKING, 'formStep', SPA_MODAL_STEPS.CONFIRMATION));
        resolve();
      })
      .catch(({ errorCode }) => {
        const formError = {};
        /* eslint-disable no-underscore-dangle */
        switch (errorCode) {
          case '404': {
            formError._error = errors.SpaBookingError;
            break;
          }
          case '403': {
            formError._error = errors.CalendarConflict;
            break;
          }
          case '400': {
            formError._error = errors.TimeSlotNotAvailable;
            break;
          }
          case '500': {
            formError._error = errors.SpaMXPTimeoutError;
            break;
          }
          default: {
            formError._error = errors.ReservationFailed;
          }
        }
        /* eslint-enable no-underscore-dangle */
        return reject(new SubmissionError(formError));
      });
  });
  return promise;
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    onSubmit,
  })
);

export default enhance(SpaModalSideContent);
