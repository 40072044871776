import Select from '@viking-eng/select';
import React from 'react';

import { BankNumberField, Field, TokenEx } from '../..';
import PropTypes from '../../../PropTypes';
import { ACH } from '../../../common/Constants';
import { ERROR_CODES, required, validBankRoutingNumber } from '../../../common/forms/Validations';
import Address from '../address/Address';

const ACHForm = ({
  forEdit,
  nameOnAccount,
  accountType,
  maskedAccountNumber,
  bankAccount,
  bankRouting,
  institutionTransitNumber,
  handleBankAccountChange,
  noPaymentRequired,
  isSubmitting,
  propCountry,
  ...addressFields
}) => {
  const rowClass = 'row no-gutters';
  return (
    <div className="form-ach">
      {nameOnAccount && (
        <div className={rowClass}>
          <Field
            component={Select}
            name="nameOnAccount"
            validate={required}
            disabled={isSubmitting}
            errorCodes={{
              [ERROR_CODES.REQUIRED]: 'NameOnAccountRequired',
            }}
            {...nameOnAccount}
          />
        </div>
      )}
      {accountType && (
        <div className={rowClass}>
          <Field component={Select} disabled={isSubmitting} name="accountType" {...accountType} />
        </div>
      )}
      {bankRouting && (
        <div className={rowClass}>
          <BankNumberField
            validate={[required, validBankRoutingNumber]}
            errorCodes={{
              [ERROR_CODES.INVALID]: 'BankRoutingNumberInvalid',
              [ERROR_CODES.REQUIRED]: 'BankRoutingNumberRequired',
            }}
            isSubmitting={isSubmitting}
            {...bankRouting}
          />
        </div>
      )}
      {bankAccount && !noPaymentRequired && (
        <div className={rowClass}>
          <TokenEx
            {...bankAccount}
            forEdit={forEdit}
            paymentMethod={ACH}
            maskedAccountNumber={maskedAccountNumber}
            handleBankAccountChange={handleBankAccountChange}
            disabled={isSubmitting}
          />
        </div>
      )}
      <Address submitting={isSubmitting} {...addressFields} propCountry={propCountry} paymentType={ACH} />
    </div>
  );
};

ACHForm.propTypes = {
  ...PropTypes.accountECheckFields,
  handleBankAccountChange: PropTypes.func,
};

ACHForm.defaultProps = {
  handleBankAccountChange: () => null,
};

export default ACHForm;
