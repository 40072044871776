import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCardModalByPageName, handleDeepLinkModal } from '../../../common/CommonStore';
import { MODAL_URL_VARIABLE_NAMES, TAB_NAMES } from '../../../common/Constants';
import userStore from '../../../common/UserStore';
import { withContent } from '../../../components';
import onboardStore, { fetchTabContent } from '../OnboardStore';
import ShipAndCrew from './ShipAndCrew';

const { getShipCrewModalData, getTabContent } = onboardStore.selectors;
const { getLinkQueryParams } = userStore.selectors;
const tabName = TAB_NAMES.SHIP_CREW;

const mapStateToProps = (state) => {
  const content = getTabContent(state)(tabName);
  const modalPageName = get(getLinkQueryParams(state), MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE, '');
  const modalTyperFunction = (card) => card.primaryButtonUrl.replace('#', '');
  return {
    content: getTabContent(state)(tabName),
    modalData: getShipCrewModalData(state),
    modalToOpen: getCardModalByPageName(content?.sections, modalPageName, modalTyperFunction),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(tabName)),
  handleCardDetailsClicked: (type, id) => {
    return dispatch(handleDeepLinkModal(type, id, MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(ShipAndCrew);
