import Questions from '@viking-eng/questions';
import get from 'lodash/get';
import PropTypes from '../../PropTypes';
import { TAB_NAMES } from '../../common/Constants';
import { handleCmsLink } from '../../common/Utils';
import { PageHeader } from '../../components';
import AirPreferences from '../air/preferences/AirPreferencesContainer';
import AirBooking from './airBooking/AirBookingContainer';

const Air = ({
  isCruiseOnly,
  isLoading,
  match: { url },
  tabLabels,
  tabs,
  title,
  airFaq,
  airFaqTitle,
  airFaqSubTitle,
}) => {
  const paths = [
    {
      component: AirBooking,
      path: get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.url`, ''),
    },
  ];
  if (!isCruiseOnly) {
    paths.push({
      component: AirPreferences,
      path: get(tabLabels, `${TAB_NAMES.AIR_PREFERENCES}.url`, ''),
    });
  }
  return (
    <div id="newAirPage" data-id="new-air-page">
      <PageHeader paths={paths} tabs={tabs} isLoading={isLoading} title={title} url={url} hasPrintLink={false} />
      {airFaq && (
        <div className="air-faq-container" role="none" onClick={handleCmsLink}>
          <h4 className="air-faq-title text-center">{airFaqTitle}</h4>
          <div className="air-faq-subtitle text-center">{airFaqSubTitle}</div>
          <Questions questions={airFaq} />
        </div>
      )}
    </div>
  );
};

Air.propTypes = {
  isCruiseOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  match: PropTypes.routerMatch.isRequired,
  tabLabels: PropTypes.shape(),
  tabs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

Air.defaultProps = {
  isCruiseOnly: false,
  isLoading: true,
  tabLabels: {},
  tabs: [],
  title: '',
};

export default Air;
