import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  change,
  clearAsyncError,
  clearSubmitErrors,
  getFormValues,
  reduxForm,
  reset,
  stopSubmit,
  SubmissionError,
  untouch,
} from 'redux-form';

import commonStore, { modalAddToCart, updateCart } from '../../common/CommonStore';
import {
  DINING_MAX_TABLE_SIZE,
  FORMS,
  MODALS,
  RESERVATION_STATE_KEYS,
  RESERVATION_TYPES,
  TAB_NAMES,
} from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import { closeModalAndNavigate } from '../../common/ModalStore';
import { convertIsoTime, hideModal } from '../../common/Utils';
import beforeYouGoStore, { fetchBeforeYouGoPageContent } from '../../pages/beforeYouGo/BeforeYouGoStore';
import onboardStore, {
  addGuestValidation,
  fetchInviteeAvailability,
  fetchTabContent as fetchOnboardTabContent,
  getDiningAvailsForInvitedPartySize,
  updateReservation,
  validateDiningInvitee,
} from '../../pages/onboard/OnboardStore';
import { fetchTabContent as fetchPaymentsTabContent, removeJourneyAddons } from '../../pages/payments/PaymentsStore';
import { fetchExcursions } from '../../pages/shorex/ShorexStore';

import ModalReservationForm from './ModalReservationForm';

const {
  selectors: { getIsDiningOpen },
} = onboardStore;

const {
  creators: { updateReservationModalState },
  selectors: { getErrors, getReservationModalInfo },
} = commonStore;

const { OPEN, EDIT, EDITING, INVITING, RESERVING_SHARED } = RESERVATION_STATE_KEYS;
const { DINING, SHOREX, CART, PPG } = RESERVATION_TYPES;
const { ADDONS } = TAB_NAMES;

const FORM_NAME = FORMS.RESERVATION;

const mapStateToProps = (
  state,
  { availability: singlePartyAvailability, availabilityLoaded, sharedAvailability, reservationType, numberAvailable }
) => {
  const initialValues = {
    bookingNumber: '',
    lastName: '',
    addGuest: [{ number: 0 }],
  };

  const reservationModalInfo = getReservationModalInfo(state);
  if (!availabilityLoaded) {
    return {
      has2topTimesAvailable: false,
      availableDays: { options: [] },
      availableTimes: { options: [] },
      hasSharedTimesAvailable: false,
      formValues: getFormValues(FORM_NAME)(state),
      initialValues,
      reservationModalInfo,
      invitees: {},
      errors: getErrors(state),
    };
  }
  const availability = reservationModalInfo.state === RESERVING_SHARED ? sharedAvailability : singlePartyAvailability;
  let availableDays;
  let availableTimes;
  let selectedDay;
  let selectedTime;
  if (reservationType === DINING && availability) {
    let { options } = availability;
    const inviteeAvailability = get(reservationModalInfo, 'metadata.availability');
    if (inviteeAvailability) {
      options = inviteeAvailability;
    }
    availableDays = {
      placeholder: availability.dayPlaceholder,
      options,
    };
    availableTimes = {
      placeholder: availability.timePlaceholder,
      options: [],
    };
    availability.options.forEach(({ label, menuLabel }) => ({
      label: label.push(menuLabel),
    }));
    const { reservationDate, reservationTime } = getFormValues(FORM_NAME)(state) || {};
    if (reservationDate) {
      selectedDay = availableDays.options.find((o) => o.value === reservationDate);
      if (selectedDay) {
        availableTimes.options = selectedDay.availableTimes.map(({ value, label }) => ({
          label: convertIsoTime(label, 'LT'),
          value,
        }));
      }
    }
    if (reservationDate && reservationTime) {
      selectedTime = availableTimes.options.find((o) => o.value === reservationTime);
    }
  }

  const has2topTimesAvailable = (singlePartyAvailability?.options?.length ?? 0) > 0;
  const hasSharedTimesAvailable = (sharedAvailability?.options?.length ?? 0) > 0;
  const noReservationsRemaining = numberAvailable === 0;
  const isDiningOpen = getIsDiningOpen(state);

  const inviteGuestFormCompleted = getFormValues(FORM_NAME)(state)?.addGuest?.every((val) => {
    return val.bookingNumber && val.lastName;
  });

  return {
    availableDays,
    availableTimes,
    has2topTimesAvailable,
    hasSharedTimesAvailable,
    formValues: getFormValues(FORM_NAME)(state),
    initialValues,
    reservationModalInfo,
    invitees: get(reservationModalInfo, 'metadata.invitees', {}),
    errors: getErrors(state),
    inviteGuestFormCompleted,
    selectedDay,
    selectedTime,
    noReservationsRemaining,
    isDiningOpen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleReservationDateChange: () => {
    dispatch(change(FORM_NAME, 'reservationTime', ''));
    dispatch(untouch(FORM_NAME, 'reservationTime'));
  },
  handleCancel: (reservationModalInfo) => {
    dispatch(updateReservationModalState(EDIT, reservationModalInfo.metadata));
    dispatch(stopSubmit(FORM_NAME));
  },
  resetReservationForm: () => {
    dispatch(updateReservationModalState());
    dispatch(reset(FORM_NAME));
  },
  updateReservationModalState: (...args) => dispatch(updateReservationModalState(...args)),
  handleClear: () => {
    dispatch(clearSubmitErrors(FORM_NAME));
    dispatch(clearAsyncError(FORM_NAME, 'lastName'));
  },
  handleCloseModalAndNav: (url) => dispatch(closeModalAndNavigate(url)),
});

const onSubmit = (
  values,
  dispatch,
  {
    reservationType,
    reservationModalInfo,
    reference,
    errors,
    modalId,
    labels,
    availability: singlePartyAvailability,
    sharedAvailability,
    reservationState,
  }
) =>
  new Promise((resolve, reject) => {
    dispatch(clearSubmitErrors(FORM_NAME));
    const { state: modalState } = reservationModalInfo;

    switch (reservationType) {
      case DINING: {
        switch (modalState) {
          case INVITING: {
            // Add validation for fields here
            return dispatch(addGuestValidation(values)).then(async ({ fieldErrors, valid }) => {
              if (fieldErrors) {
                // Throw validation error here
                const err = { addGuest: fieldErrors?.addGuest, _error: fieldErrors?.error };
                return reject(new SubmissionError(err));
              }
              const { guests, passengerCount } = valid;
              if (document && document.activeElement) {
                document.activeElement.blur();
              }
              // validate each of the invitees and get total party size
              const promises = [];
              guests.forEach((invitee) => {
                promises.push(
                  dispatch(validateDiningInvitee(invitee, reference))
                    .then((guestData) => ({
                      ...guestData,
                      bookingId: guestData.bookingID,
                    }))
                    .catch((err) => {
                      const { message } = err;
                      let asyncError;
                      const formError = {};
                      try {
                        asyncError = JSON.parse(message);
                      } catch (e) {
                        asyncError = {
                          errorDescription: message,
                        };
                      }
                      switch (asyncError.errorCode) {
                        case ERROR_CODES.OVER_MAX_TABLE_SIZE: {
                          /* eslint-disable-next-line no-underscore-dangle */
                          formError._error = labels.overMaxTableSize;
                          break;
                        }
                        default: {
                          formError.lastName = asyncError.errorDescription;
                        }
                      }
                      return resolve(formError);
                    })
                );
              });
              const results = await Promise.all(promises);

              let forceDisableSubmitBtn = false;
              let guestError = '';
              const formErrors = results.reduce((acc, guest) => {
                if (guest?.error?.errorCode) {
                  // 2301 = Dining reservations have been closed
                  forceDisableSubmitBtn = forceDisableSubmitBtn || guest.error.errorCode === '2301';
                  acc[guest.number] = {
                    lastName: guest.error.errorDescription,
                  };
                  guestError = guest.error.errorDescription;
                }
                return acc;
              }, []);

              // Post form errors from invitee validation to correct field
              if (formErrors.length) {
                dispatch(
                  updateReservationModalState(INVITING, {
                    ...reservationModalInfo.metadata,
                    forceDisableSubmitBtn,
                  })
                );
                const err = {
                  addGuest: formErrors,
                  _error: guestError,
                };
                return reject(new SubmissionError(err));
              }

              const partySize = results.reduce((acc, guestData) => {
                if (guestData?.passengers) {
                  acc += guestData.passengers.length;
                }
                return acc;
              }, passengerCount);

              if (partySize > DINING_MAX_TABLE_SIZE) {
                return reject(
                  new SubmissionError({
                    _error: errors[ERROR_CODES.OVER_MAX_TABLE_SIZE],
                  })
                );
              }
              const inviteeAvails = await dispatch(getDiningAvailsForInvitedPartySize(guests, reference, partySize));
              if (inviteeAvails.error) {
                return reject(new SubmissionError({ _error: inviteeAvails.error }));
              } else {
                return dispatch(fetchInviteeAvailability(inviteeAvails, results, reference, partySize))
                  .then((data) => {
                    if (data.errorCode && data.invitees?.length) {
                      return reject(new SubmissionError({ _error: data.errorDescription }));
                    } else if (data.error) {
                      return reject(new SubmissionError({ _error: data.error }));
                    }
                    return reject();
                  })
                  .catch((err) => {
                    return reject(new SubmissionError({ _error: err }));
                  });
              }
            });
          }
          default: {
            const willingToShare = reservationModalInfo.state === RESERVING_SHARED;

            // reservationModalInfo.metadata.availability contains invite availability if invite option is selected
            let availabilityOptions = reservationModalInfo?.metadata?.availability;
            if (!availabilityOptions) {
              availabilityOptions = willingToShare ? sharedAvailability?.options : singlePartyAvailability?.options;
            }
            return dispatch(updateReservation(values, reference, availabilityOptions, willingToShare))
              .then(resolve)
              .catch(reject);
          }
        }
      }
      case SHOREX:
        switch (modalState) {
          case EDITING: {
            let promise;
            const {
              date,
              item: { itemID: cartItemId, forPassenger },
              passengerIndex,
            } = reservationModalInfo.metadata;

            if (forPassenger.every(({ inCart }) => inCart)) {
              const overrides = {
                [`forPassenger${passengerIndex + 1}`]: false,
              };
              promise = dispatch(updateCart({ itemId: cartItemId, overrides }));
            } else {
              promise = dispatch(removeJourneyAddons([cartItemId]));
            }

            if (promise) {
              return promise
                .then(() => {
                  const promises = [
                    dispatch(
                      fetchExcursions({
                        date,
                        refreshData: true,
                      })
                    ),
                    dispatch(fetchPaymentsTabContent(ADDONS)),
                  ];
                  Promise.all(promises).then(() => {
                    hideModal(MODALS.SHOREX);
                    resolve();
                  });
                })
                .catch(() =>
                  reject(
                    new SubmissionError({
                      _error: get(errors, ERROR_CODES.UPDATE_FAILED),
                    })
                  )
                );
            }

            break;
          }
          default:
            break;
        }
        return resolve();
      case CART: {
        switch (reservationState) {
          case OPEN:
            return dispatch(modalAddToCart(onboardStore, 'diningBeverage', fetchOnboardTabContent)).then((response) => {
              if (Array.isArray(response) ? !response.every((res) => res.isSuccessful) : !response.isSuccessful) {
                return reject(
                  new SubmissionError({
                    _error: get(errors, 'AddToCartFailed'),
                  })
                );
              }
              return resolve(response);
            });
          default:
            break;
        }
        return resolve();
      }
      case PPG: {
        switch (modalState) {
          case OPEN:
            return dispatch(modalAddToCart(beforeYouGoStore, '', fetchBeforeYouGoPageContent)).then((response) => {
              if (!response.isSuccessful) {
                return reject(
                  new SubmissionError({
                    _error: get(errors, 'AddToCartFailed'),
                  })
                );
              }
              return resolve(response);
            });
          default:
            break;
        }
        return resolve();
      }
      default:
        switch (modalState) {
          case EDIT:
            dispatch(updateReservationModalState(EDITING, reservationModalInfo.metadata));
            return resolve();
          case EDITING: {
            const { itemID } = reservationModalInfo.metadata.item;
            return dispatch(removeJourneyAddons([itemID]))
              .then(() => {
                dispatch(fetchPaymentsTabContent(ADDONS)).then(() => {
                  hideModal(modalId);
                });
              })
              .catch(() =>
                reject(
                  new SubmissionError({
                    _error: get(errors, ERROR_CODES.UPDATE_FAILED),
                  })
                )
              );
          }
          default: {
            // unknown/unhandled case, don't do anything
            return resolve(false);
          }
        }
    }
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    onSubmit,
  })
);

export default enhance(ModalReservationForm);
