import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import Spinner from '@viking-eng/spinner';

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { EVO_USER_TYPES, MODALS, PAYMENT_STEPS, USER_TYPES } from '../../../common/Constants';
import { prepareHtml } from '../../../common/Utils';
import { PageHeader } from '../../../components';
import { ChallengeTimeoutModal, PaymentChallengeModal } from '../../../modals';
import PropTypes from '../../../PropTypes';
import './PaymentCheckout.scss';

const AlertWithLink = lazy(() => import('@viking-eng/alert-with-link'));
const NotificationModal = lazy(() => import('../../../modals/notification/Notification'));
const PaymentAmountContainer = lazy(() => import('../paymentAmount/PaymentAmountContainer'));
const PaymentCheckoutMethods = lazy(() => import('./PaymentCheckoutMethods'));
const PaymentCheckoutSummary = lazy(() => import('./PaymentCheckoutSummary'));

const PaymentCheckout = ({
  accountECheckFields,
  backButton,
  country,
  creditCardFields,
  disableSubmit,
  form,
  handleAccountChange,
  handleNameOnAccountChange,
  handleOnClose,
  handlePaymentMethodChange,
  handleSubmit,
  hidePageHeader,
  isLoading,
  labels,
  pageViewTrigger,
  paymentAmountSelected,
  paymentCheckoutSummary,
  paymentFailedNotificationModalData,
  paymentMethod,
  paymentMethodOptions,
  previousLink,
  savedAccount,
  savedAccounts,
  submitFailed,
  submitting,
  tcLink,
  title,
  ukPaymentsMessage,
  valid,
  challengeModalData,
  handleChallengeOnCancel,
  handleOnSuccess,
  handleTimeout,
  challengeTimeoutModalData,
  handleChallengeResponseFailure,
  isTokenExRefreshed,
  handleModalOpen,
  refetchContent,
  updateUserInfo,
}) => {
  useEffect(() => {
    if (pageViewTrigger) {
      pageViewTrigger(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paymentStep, setPaymentStep] = useState(PAYMENT_STEPS.INIT);
  const [failCount, setFailCount] = useState(0);

  const challengeResponse = challengeModalData?.challengeResponse;
  if (paymentStep === PAYMENT_STEPS.POST_CHALLENGE && challengeResponse?.transStatus && !submitting) {
    setPaymentStep(PAYMENT_STEPS.FINAL);
    handleSubmit();
  }
  useEffect(() => {
    if (challengeResponse?.error) {
      handleChallengeResponseFailure();
    }
  }, [challengeResponse, handleChallengeResponseFailure]);

  const {
    balanceItems,
    button,
    discountItems,
    genericValidationMessage,
    paymentCancelledMessage,
    lineItems,
    noPaymentDescription,
    secureCheckout,
    tokenExRefreshedMessage,
    cartFailure,
    unableToLoadCart,
    cartError,
    restrictPaymentMessage,
  } = paymentCheckoutSummary;

  const submitDisabled = disableSubmit || !valid || cartError;

  if (cartError && failCount === 0) {
    setFailCount(failCount + 1);
  }
  if (!cartError && failCount > 0) {
    setFailCount(0);
  }

  const getErrorMessage = () => {
    if (submitFailed && !valid) {
      return genericValidationMessage;
    }
    if (isTokenExRefreshed) {
      return tokenExRefreshedMessage;
    }
    if (updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA]) {
      return restrictPaymentMessage;
    }
    return '';
  };

  let errorTipMsg = getErrorMessage();

  if (paymentStep === PAYMENT_STEPS.CANCELED) {
    errorTipMsg = paymentCancelledMessage;
  }

  labels = {
    ...labels,
    previousStep: labels.previousStep || 'PREVIOUS STEP',
  };

  const SHOW_PAYMENT_AMOUNT = labels.paymentAmount && !ukPaymentsMessage;

  const paymentIsProcessing =
    submitting || [PAYMENT_STEPS.CHALLENGE, PAYMENT_STEPS.POST_CHALLENGE].includes(paymentStep);
  return (
    <div id="paymentsCheckout" className="payments-checkout">
      <Suspense fallback={null}>
        <NotificationModal
          onClose={() => {
            handleOnClose(paymentStep);
            setPaymentStep(PAYMENT_STEPS.INIT);
          }}
          {...paymentFailedNotificationModalData}
        />
      </Suspense>
      {!hidePageHeader && (
        <PageHeader isLoading={isLoading && paymentStep === PAYMENT_STEPS.INIT} title={title} backButton={backButton} />
      )}
      {hidePageHeader && isLoading && (
        <div className="row justify-content-center">
          <Spinner />
        </div>
      )}
      {(!isLoading || paymentStep !== PAYMENT_STEPS.INIT) && (
        <div className="row">
          <div className="d-xs-flex d-md-none col-12">
            <Suspense fallback={null}>
              <PaymentCheckoutSummary
                balanceItems={balanceItems}
                button={button}
                disableSubmit={submitDisabled}
                discountItems={discountItems}
                errorTipMsg={errorTipMsg}
                handleSubmit={handleSubmit}
                labels={labels}
                lineItems={lineItems}
                secureCheckout={secureCheckout}
                submitting={paymentIsProcessing}
                tcLink={tcLink}
              />
            </Suspense>
          </div>
          <div className="col-12 col-md-6 col-lg-7">
            {SHOW_PAYMENT_AMOUNT && (
              <div className="step">
                <div className="step-title">{labels.paymentAmount}</div>
                <Suspense fallback={null}>
                  <PaymentAmountContainer isSubmitting={paymentIsProcessing} />
                </Suspense>
              </div>
            )}
            {!noPaymentDescription && !failCount && (
              <>
                <Suspense fallback={null}>
                  <PaymentCheckoutMethods
                    accountECheckFields={accountECheckFields}
                    country={country}
                    creditCardFields={creditCardFields}
                    form={form}
                    handleAccountChange={handleAccountChange}
                    handleNameOnAccountChange={handleNameOnAccountChange}
                    handlePaymentMethodChange={handlePaymentMethodChange}
                    labels={labels}
                    noPaymentDescription={noPaymentDescription}
                    paymentAmountSelected={paymentAmountSelected}
                    paymentMethod={paymentMethod}
                    paymentMethodOptions={paymentMethodOptions}
                    savedAccount={savedAccount}
                    savedAccounts={savedAccounts}
                    submitting={paymentIsProcessing}
                    ukPaymentsMessage={ukPaymentsMessage}
                  />
                </Suspense>
              </>
            )}
            {noPaymentDescription && !failCount && (
              <>
                {typeof noPaymentDescription === 'object' ? (
                  <>
                    <p className="no-payment-required-title">{noPaymentDescription.title}</p>
                    <p className="no-payment-required-message noPaymentRequired">{noPaymentDescription.subtitle}</p>
                    <p className="no-payment-required-message noPaymentRequired">{noPaymentDescription.longText}</p>
                  </>
                ) : (
                  <div className="noPaymentRequired" dangerouslySetInnerHTML={prepareHtml(noPaymentDescription)} />
                )}
              </>
            )}
            {failCount > 0 && failCount <= 3 && cartFailure && (
              <AlertWithLink
                alert={unableToLoadCart?.pre}
                alertButton={{
                  text: unableToLoadCart?.callToActionTitle,
                  onButtonClick: () =>
                    refetchContent().then((res) => {
                      if (res?.statusCode === '500') {
                        setFailCount(failCount + 1);
                      }
                    }),
                }}
                alertEndText={unableToLoadCart?.post}
              />
            )}
            {failCount > 3 && unableToLoadCart && (
              <AlertWithLink
                alert={cartFailure?.pre}
                alertButton={{
                  text: cartFailure?.callToActionTitle,
                  onButtonClick: () => handleModalOpen(MODALS.CONTACT_US),
                }}
                alertEndText={cartFailure?.post}
              />
            )}
          </div>
          <div className="d-md-flex d-none col-12 col-md-6 col-lg-5">
            <div className="sidebar">
              <Suspense fallback={null}>
                <PaymentCheckoutSummary
                  balanceItems={balanceItems}
                  button={button}
                  disableSubmit={submitDisabled}
                  discountItems={discountItems}
                  errorTipMsg={errorTipMsg}
                  handleSubmit={() => {
                    if ([PAYMENT_STEPS.INIT, PAYMENT_STEPS.CANCELED, PAYMENT_STEPS.TIMEOUT].includes(paymentStep)) {
                      setPaymentStep(PAYMENT_STEPS.CHALLENGE);
                    }
                    return handleSubmit();
                  }}
                  labels={labels}
                  lineItems={lineItems}
                  secureCheckout={secureCheckout}
                  showError={!!errorTipMsg}
                  submitting={paymentIsProcessing}
                  tcLink={tcLink}
                />
              </Suspense>
              {previousLink && (
                <div className="d-flex justify-content-center">
                  <Button className="text-center" appearance="link" onButtonClick={previousLink}>
                    {labels.previousStep}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="d-xs-flex d-md-none col-12">
            <Button
              appearance="secondary-blue"
              className="sm-submit-btn"
              disabled={submitDisabled}
              loading={paymentIsProcessing}
              onButtonClick={() => {
                if ([PAYMENT_STEPS.INIT, PAYMENT_STEPS.CANCELED, PAYMENT_STEPS.TIMEOUT].includes(paymentStep)) {
                  setPaymentStep(PAYMENT_STEPS.CHALLENGE);
                }
                return handleSubmit();
              }}
            >
              {button.text}
            </Button>
            {secureCheckout && (
              <div className="text-center align-items-center justify-content-center secure-checkout">
                <Icon name="lock" />
                {secureCheckout}
              </div>
            )}
            {!paymentIsProcessing && errorTipMsg && (
              <Suspense fallback={null}>
                <AlertWithLink alert={errorTipMsg} />
              </Suspense>
            )}
            {previousLink && (
              <div className="d-flex justify-content-center">
                <Button className="text-center prev-link" appearance="link" onButtonClick={previousLink}>
                  {labels.previousStep}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <PaymentChallengeModal
        {...challengeModalData}
        handleOnLoad={() => {
          setPaymentStep(PAYMENT_STEPS.PRE_CHALLENGE);
        }}
        handleCancel={() => {
          handleChallengeOnCancel();
          setPaymentStep(PAYMENT_STEPS.CANCELED);
        }}
        handleSuccess={() => {
          handleOnSuccess();
          setPaymentStep(PAYMENT_STEPS.POST_CHALLENGE);
        }}
        handleTimeout={(interval) => {
          setPaymentStep(PAYMENT_STEPS.TIMEOUT);
          handleTimeout(interval);
        }}
      />
      <ChallengeTimeoutModal
        {...challengeTimeoutModalData}
        id={MODALS.CHALLENGE_TIMEOUT}
        handleOnClose={() => handleOnClose(paymentStep, MODALS.CHALLENGE_TIMEOUT)}
      />
    </div>
  );
};

PaymentCheckout.propTypes = {
  accountECheckFields: PropTypes.shape(PropTypes.accountECheckFields).isRequired,
  backButton: PropTypes.pageHeaderBackButton,
  content: PropTypes.shape({}),
  country: PropTypes.string,
  creditCardFields: PropTypes.shape({
    ...PropTypes.creditCardFields,
  }),
  disableSubmit: PropTypes.bool,
  form: PropTypes.string.isRequired,
  handleAccountChange: PropTypes.func.isRequired,
  handleNameOnAccountChange: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handlePaymentMethodChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hidePageHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  labels: PropTypes.shape({
    accountInformation: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string,
    paymentMethod: PropTypes.string.isRequired,
  }).isRequired,
  pageViewTrigger: PropTypes.func,
  paymentAmountSelected: PropTypes.bool,
  paymentCheckoutSummary: PropTypes.shape({
    balanceItems: PropTypes.options,
    button: PropTypes.shape({ text: PropTypes.string }),
    discountItems: PropTypes.options,
    lineItems: PropTypes.options,
    noPaymentDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    secureCheckout: PropTypes.string,
  }),
  paymentFailedNotificationModalData: PropTypes.notificationModalData.isRequired,
  paymentMethod: PropTypes.paymentMethod,
  paymentMethodOptions: PropTypes.arrayOf(PropTypes.shape()),
  previousLink: PropTypes.func,
  savedAccount: PropTypes.string,
  savedAccounts: PropTypes.shape({ helperLabel: PropTypes.string, options: PropTypes.options }),
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  tcLink: PropTypes.func,
  title: PropTypes.string,
  ukPaymentsMessage: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  validPaymentAmountOptions: PropTypes.arrayOf(PropTypes.string),
  challengeModalData: PropTypes.shape({
    id: PropTypes.string,
    iFrameUrl: PropTypes.string,
    labels: PropTypes.shape({
      title: PropTypes.string,
      cancel: PropTypes.string,
      warning: PropTypes.string,
    }),
  }),
  handleChallengeOnCancel: PropTypes.func.isRequired,
  isTokenExRefreshed: PropTypes.bool,
};

PaymentCheckout.defaultProps = {
  backButton: undefined,
  content: {},
  country: '',
  creditCardFields: {},
  disableSubmit: true,
  hidePageHeader: false,
  pageViewTrigger: undefined,
  paymentAmountSelected: true,
  paymentCheckoutSummary: {
    button: { text: '' },
    lineItems: [],
    balanceItems: [],
    discountItems: [],
    secureCheckout: '',
  },
  paymentMethod: null,
  paymentMethodOptions: undefined,
  previousLink: null,
  savedAccount: null,
  savedAccounts: { helperLabel: null, options: [] },
  tcLink: null,
  title: '',
  challengeModalData: null,
  isLoading: false,
};

export default PaymentCheckout;
