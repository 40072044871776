import React from 'react';
import { handleCmsLink, prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './TravelProtectionModalSideContent.scss';

const TravelProtectionModalSideContent = ({ sideContentData: { regionalBookingPhoneNumber, planDetailsLink } }) => (
  <div className="travel-protection-side-content booking-assistance">
    <div className="title" role="none" dangerouslySetInnerHTML={prepareHtml(regionalBookingPhoneNumber)} />
    <div
      role="none"
      className="plan-details-link"
      onClick={handleCmsLink}
      dangerouslySetInnerHTML={prepareHtml(planDetailsLink)}
    />
  </div>
);

TravelProtectionModalSideContent.propTypes = {
  sideContentData: PropTypes.shape({
    title: PropTypes.string,
    planDetailsLink: PropTypes.string,
  }),
};

TravelProtectionModalSideContent.defaultProps = {
  sideContentData: {},
};

export default TravelProtectionModalSideContent;
