import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore, { userStore } from '../../../../common';
import { APP_PATHS, CCI_STEPS, MODALS, TAB_NAMES, TAB_PATHS } from '../../../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../../../common/ModalStore';
import { navigateTo } from '../../../../common/Utils';
import { withContent } from '../../../../components';
import paymentsStore, { fetchOnboardCC, removeOnboardCreditCard } from '../../../payments/PaymentsStore';
import documentStore, {
  fetchCheckInPageContent,
  fetchGifContent,
  fetchTabContent,
  hasValidPhoto,
  postComfortCheckIn,
} from '../../DocumentsStore';
import GuestCheckIn from './GuestCheckIn';

const { getPassengerNames } = userStore.selectors;
const { clearModal } = modalStore.creators;
const { setPreviousPage } = commonStore.creators;

const {
  creators: {
    setCheckInSubmitting,
    setComfortCheckInSubmitFailed,
    updateNextLocation: gifUpdateNextLocation,
    updateSelectedGuest,
  },
  selectors: {
    getComfortCheckInFormSteps,
    getComfortCheckInPassenger,
    getComfortCheckInStep,
    getCheckInSubmitting,
    getComfortCheckingTabContent,
    getCheckInFailed,
  },
} = documentStore;

const {
  creators: { updateNextLocation: paymentUpdateNextLocation },
  selectors: { getOnboardCreditCardSection },
} = paymentsStore;

const { clearCheckInPassenger, setComfortCheckInStep } = documentStore.creators;

const tabName = TAB_NAMES.COMFORT_CHECK_IN;

const mapStateToProps = (state) => {
  const passengerNames = getPassengerNames(state);
  const passengerNumber = getComfortCheckInPassenger(state);
  const stepNumber = getComfortCheckInStep(state);
  return {
    ...getComfortCheckInFormSteps(state),
    content: getComfortCheckingTabContent(state),
    guestName: passengerNames[passengerNumber - 1],
    paymentMethods: getOnboardCreditCardSection(state),
    passengerNumber,
    stepNumber,
    submitting: getCheckInSubmitting(state),
    submitFailed: getCheckInFailed(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTabContent(tabName));
    dispatch(fetchGifContent());
    dispatch(fetchOnboardCC());
    dispatch(fetchCheckInPageContent());
  },
  handleCciStepChange: (stepNumber) => {
    dispatch(setComfortCheckInStep(stepNumber));
  },
  handleBack: () => {
    dispatch(clearCheckInPassenger());
    navigateTo(APP_PATHS.COMFORT_CHECK_IN);
  },
  openModal: (type, id) => {
    dispatch(setViewAndShowModal(type, { id }));
  },
  handleCancelOnboardCCRemove: () => {
    dispatch(clearModal());
  },
  handleRemoveOnboardCreditCard: () => dispatch(removeOnboardCreditCard()),
  onComplete: () => {
    dispatch(setCheckInSubmitting(true));
    dispatch(postComfortCheckIn()).then((res) => {
      dispatch(setCheckInSubmitting(false));
      if (res.isSuccessful) {
        if (res.data.length === 1) {
          if (res.data && res.data[0] && res.data[0].success) {
            dispatch(setComfortCheckInStep(CCI_STEPS.PERSONAL_INFO));
            dispatch(clearCheckInPassenger());
            dispatch(setPreviousPage({ from: APP_PATHS.BEFORE_YOU_GO }));
            navigateTo(APP_PATHS.COMFORT_CHECK_IN);
          } else {
            dispatch(setComfortCheckInSubmitFailed(true));
            return res;
          }
        } else if (res.data.every((leg) => leg.success)) {
          dispatch(setComfortCheckInSubmitFailed(false));
          dispatch(setComfortCheckInStep(CCI_STEPS.PERSONAL_INFO));
          dispatch(clearCheckInPassenger());
          dispatch(setPreviousPage({ from: APP_PATHS.BEFORE_YOU_GO }));
          navigateTo(APP_PATHS.COMFORT_CHECK_IN);
        } else if (res.data.every((leg) => leg.errorCode)) {
          dispatch(setComfortCheckInSubmitFailed(true));
          return res;
        } else if (!res?.data?.[0]?.success) {
          dispatch(setComfortCheckInSubmitFailed(true));
        } else {
          dispatch(setComfortCheckInSubmitFailed(false));
          dispatch(setComfortCheckInStep(CCI_STEPS.PERSONAL_INFO));
          dispatch(clearCheckInPassenger());
          dispatch(setPreviousPage({ from: APP_PATHS.BEFORE_YOU_GO }));
          navigateTo(APP_PATHS.COMFORT_CHECK_IN);
        }
      }
      if (!res.isSuccessful) {
        dispatch(setComfortCheckInSubmitFailed(true));
      }
      return res;
    });
  },
  handlePhotoValidation: () => dispatch(hasValidPhoto()),
  handleShowModal: () => dispatch(setViewAndShowModal(MODALS.CONTACT_US)),
  handleEditGif: (passengerNumber) => {
    dispatch(gifUpdateNextLocation(APP_PATHS.COMFORT_CHECK_IN_FORM));
    dispatch(updateSelectedGuest(passengerNumber));
    const guestInfoTabPath = `${APP_PATHS.DOCUMENTS}/${TAB_PATHS.documents.guestInformation}`;
    navigateTo(guestInfoTabPath);
  },
  handleAddCreditCard: () => {
    dispatch(paymentUpdateNextLocation(APP_PATHS.COMFORT_CHECK_IN_FORM));
    navigateTo(APP_PATHS.ONBOARD_CREDIT_CARD);
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(GuestCheckIn);
