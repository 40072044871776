import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { SubmissionError } from 'redux-form';
import { createSelector } from 'reselect';
import { getData } from '../../common/Api';
import commonStore, {
  addExcursionToCart,
  removeExcursionFromCart,
  removeIncludedExcursion,
  updateBookingExcursions,
} from '../../common/CommonStore';
import {
  ADDONS_CHECKOUT,
  CART_COUNT_INPUTS,
  FEATURE_RESTRICTED,
  FLAG_NAMES,
  MODALS,
  PASSENGERS_RESERVATION_STATUS,
  REGIONAL_SHORT_DATES,
  RESERVATION_STATE_KEYS,
  RESERVATION_STATUS,
  SHOREX_ACTION_TYPES,
  SHOREX_MODAL_ACTIONS,
  STATUS_TYPES,
  TIME_ZONE_ABBREVIATIONS,
  TWO_BY_ONE,
  VOYAGE_TYPE,
} from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import userStore from '../../common/UserStore';
import {
  buildUrl,
  createCalendarDate,
  findImageSrc,
  formatMoney,
  getAttributeDescription,
  getCarouselImageArray,
  getCmsLabel,
  getEvoErrorCode,
  getFallbackImage,
  getImageAttributes,
  getReservationStatuses,
  mapShorexIcons,
  matchItineraryItem,
  replaceCMSTokenWithValue,
  replaceToken,
} from '../../common/Utils';
import { clearAvailabilityDiningData } from '../onboard/OnboardStore';

const {
  selectors: {
    getLabels,
    getBookingPassengers,
    getErrors,
    getModalLabels,
    getExcursionVoyageId,
    getIsCurrentCountryPaymentBlocked,
    getViewOnlyContent,
    getIsUKAUNZ,
    getItineraryNavigationData,
    getCartRedirectModifyModal,
    getFlagValue,
    getPaymentsAllEnabled,
    getPaymentsCheckoutEnabled,
    getMvjStrings,
  },
  types: { CART_UPDATED },
} = commonStore;
const {
  selectors: { getModalId },
  creators: { clearModal },
} = modalStore;
const { setCartRedirectModifyModal } = commonStore.creators;
const {
  getBookingDetails,
  getPassengers,
  getPassengerNumber,
  getCountryCodeFromCurrency,
  getItinerary,
  getIsApprovedForPurchases,
  isAllGifCompleted,
  isGifOverride,
  getCalendarId,
  getFeatureRestricted,
  isSinglePassenger,
  getCartId,
  getIsDirect,
  getIsBalancePastDue,
  getVoyageType,
  getItineraryLabels,
  getItineraryDate,
} = userStore.selectors;
const { receiveBookings, updateCartItemCount } = userStore.creators;

const INITIAL_CONFLICT_STATUS = {
  calendarConflict: {
    1: {},
    2: {},
  },
};

const getPassengerTimes = (times, soldOutParenthesis, isSinglePax) =>
  times.map(({ end, inventoryCode, isSoldOut: isOptionSoldOut, start, availableCount }) => ({
    isDisabled: isOptionSoldOut || (availableCount === 1 && !isSinglePax),
    label: `${start.label} \u2013 ${end.label}${
      times.length > 1 && (isOptionSoldOut || (!isSinglePax && availableCount === 1)) ? ` ${soldOutParenthesis}` : ''
    }`,
    value: inventoryCode,
  }));

export const ERROR_CODE_MAPPING = {
  [ERROR_CODES.INVALID]: 'ShoreExRequired',
};

export const findExcursion = (excursions, inventoryCode, date) =>
  excursions.find((excursion) => {
    const { times } = excursion.modal;
    return (
      times.some((time) => time.inventoryCode === inventoryCode && time.fromDate === date) ||
      excursion.landingDay?.displayDate === date
    );
  });

const getConflictTimeFrame = (modal, inventoryCode) => {
  let code = inventoryCode;

  // This currently checks only time for excursions offered at one time
  if (!code) {
    code = modal.times[0].inventoryCode;
  }
  const { fromDate, toDate, times } = modal;
  const { start, end } = times.find((t) => t.inventoryCode === code);
  const startTime = createCalendarDate(fromDate, start.value);
  const endTime = createCalendarDate(toDate, end.value);
  return { startTime, endTime, inventoryCode: code };
};

const getShorexMessage = (items, reference, fallback = {}) =>
  items.find((item) => item.reference === reference) || fallback;

const ALERT_LOCATIONS = {
  BANNER: 'banner',
  MODAL: 'modal',
};

const shorexStore = createPageTitleDuck('shorex').extend({
  types: [
    'RECEIVE_EXCURSIONS',
    'RECEIVE_UPDATE_BOOKING_CART_RESPONSE',
    'UPDATE_RESERVE_BUTTON_STATUS',
    'RECEIVE_CONFLICT_STATUS',
    'UPDATING_MODAL_STATE',
    'UPDATE_SHORE_EX_MODAL_STATE',
    'CLEAR_EXCURSIONS_DAY',
  ],
  initialState: INITIAL_CONFLICT_STATUS,
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.UPDATE_SHORE_EX_MODAL_STATE: {
        const key = get(action, 'metadata.passengerIndex');
        return {
          ...state,
          reservationModal:
            key !== null && key !== undefined && !Number.isNaN(key)
              ? {
                  ...state.reservationModal,
                  [key]: {
                    metadata: action.metadata,
                    state: action.state,
                  },
                }
              : {},
        };
      }
      case types.RECEIVE_EXCURSIONS:
        return {
          ...state,
          totalExcursionsCount: action.payload.totalExcursionsCount,
          excursions: {
            ...state.excursions,
            [action.date]: {
              excursions: action.payload.excursions,
              voyageId: action.payload.voyageId,
              loaded: true,
            },
          },
        };
      case types.RECEIVE_UPDATE_BOOKING_CART_RESPONSE:
        return {
          ...state,
          receiveBookingCartResponse: {
            isSuccessful: action.isSuccessful,
            [action.isSuccessful ? 'data' : 'error']: action.payload,
          },
          isReserveButtonActive: true,
        };
      case types.UPDATE_RESERVE_BUTTON_STATUS:
        return {
          ...state,
          isReserveButtonActive: action.isReserveButtonActive,
        };
      // TODO: revisit to see if this is needed since we are now getting excursion data back on
      // shoreEx cart updates, and other cart items do not impact calendar
      case CART_UPDATED:
        return {
          ...state,
          excursions: {},
        };
      case types.UPDATING_MODAL_STATE:
        return {
          ...state,
          updatingModalData: action.payload,
        };
      case types.CLEAR_EXCURSIONS_DAY:
        return {
          ...state,
          excursions: {
            ...state.excursions,
            [action.date]: {
              loaded: false,
            },
          },
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveExcursions: (payload, date) => ({
      type: types.RECEIVE_EXCURSIONS,
      payload,
      date,
    }),
    receiveBookingCartResponse: (isSuccessful, payload) => ({
      type: types.RECEIVE_UPDATE_BOOKING_CART_RESPONSE,
      isSuccessful,
      payload,
    }),
    updateReserveButtonStatus: (isReserveButtonActive) => ({
      type: types.UPDATE_RESERVE_BUTTON_STATUS,
      isReserveButtonActive,
    }),
    updateModalStateFlag: (payload) => ({
      type: types.UPDATING_MODAL_STATE,
      payload,
    }),
    updateShoreExModalState: (state, metadata) => ({
      type: types.UPDATE_SHORE_EX_MODAL_STATE,
      metadata,
      state,
    }),
    clearExcursionsDay: (date) => ({
      type: types.CLEAR_EXCURSIONS_DAY,
      date,
    }),
  }),
  selectors: {
    getShoreExModalInfo: (state) => get(state, 'shorex.reservationModal', {}),
    getExcursionsData: (state) => get(state, 'shorex.excursions', {}),
    getTotalExcursionsCount: (state) => get(state, 'shorex.totalExcursionsCount'),
    isReserveButtonActive: (state) => get(state, 'shorex.isReserveButtonActive', true),
    getCalendarConflict: (state) => get(state, 'shorex.calendarConflict'),
    getUpdatingModalStateFlag: (state) => get(state, 'shorex.updatingModalData', false),
    getCalendarContent: (state) => get(state, 'calendar.itinerary.content', {}),
    getLockedDownMessage: new Selector((selectors) => {
      const { getCalendarContent, getPageContent, getTotalExcursionsCount } = selectors;
      return createSelector(
        [
          getPageContent,
          getBookingDetails,
          getTotalExcursionsCount,
          isAllGifCompleted,
          getIsBalancePastDue,
          isGifOverride,
          getIsApprovedForPurchases,
          getFeatureRestricted,
          getViewOnlyContent,
          getCountryCodeFromCurrency,
          getIsDirect,
          getVoyageType,
          getItinerary,
          getCalendarContent,
        ],
        (
          content,
          bookingDetails,
          excursionsCount,
          allGifCompleted,
          isBalancePastDue,
          gifOverride,
          isApprovedForPurchases,
          featureRestricted,
          { bannerMessage: viewOnlyMessage },
          country,
          isDirect,
          voyageType,
          itinerary,
          calendarContent
        ) => {
          if (excursionsCount === undefined) {
            return null;
          }
          const date = window.location.pathname.split('/').pop();
          const excursionDate = date.split('-').length === 3 ? date : itinerary[0].date;
          const excursionDay = itinerary.find((i) => i.date === excursionDate);
          const dayVoyageType = excursionDay?.voyageType || voyageType;

          const items = get(content, 'sections[0].items', []);
          const calendarItems = get(calendarContent, 'sections[1].items', []);
          const today = moment();
          const embarkYear = moment(bookingDetails.voyage.embarkDate).get('year');
          let excursionOpenDate = moment(bookingDetails.excursionsOpenDateTz);
          // Confirm what message to use for Combo bookings
          const isOcean = voyageType === VOYAGE_TYPE.OCEAN;
          const isRiver = voyageType === VOYAGE_TYPE.RIVER;
          const isOceanRiver = voyageType === VOYAGE_TYPE.MIXED;
          const isExpedition = voyageType === VOYAGE_TYPE.EXPEDITION;
          const isMississippi = voyageType === VOYAGE_TYPE.MISSISSIPPI;

          const localExcursionOpenDate = moment(bookingDetails.excursionsOpenDateTz, 'YYYY-MM-DD HH:mm:ss');
          const localExcursionOpenTime = localExcursionOpenDate.format('h:mm a').toUpperCase();
          const comboOpenDate = null;
          let comboOpenString = 'comboOpenDateRiver';

          const oceanExcursionsRestricted = today.isBefore(moment(bookingDetails.excursionsOpenDateTz));
          const alertMessages = [];
          const gifIncompleteLockdown = !gifOverride && !allGifCompleted;
          /*
          Priority for alert message to be displayed to user:
            0. View Only: cannot modify booking // To be added
            1. GIF Incomplete
            2. Close to sailing: cannot modify booking
            3. User not approved for purchase
            4. Balance Past Due
            5. Check if ShoreEx is open (single cruise)
            6. Check if ShoreEx is open (combo)
          */
          if (featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY) {
            // Case 0: View Only Mode
            alertMessages.push({
              message: { title: viewOnlyMessage, reference: FEATURE_RESTRICTED.VIEW_ONLY },
              placement: [ALERT_LOCATIONS.MODAL],
              lockedDownFlag: true,
              priority: 2,
            });
          }
          if (gifIncompleteLockdown) {
            // Case 1: GIF incomplete
            alertMessages.push({
              lockedDownFlag: true,
              priority: 0,
            });
          }
          if (featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING) {
            //  Case 2: close to Sailing
            alertMessages.push({
              message:
                items.length > 0
                  ? { ...getShorexMessage(items, 'shoreExcursionsLessThanSevenDaysAway') }
                  : { ...getShorexMessage(calendarItems, 'shoreExcursionsLessThanSevenDaysAway') },
              lockedDownFlag: true,
              placement: [ALERT_LOCATIONS.MODAL],
              priority: 1,
            });
          }
          if (!isApprovedForPurchases) {
            // Case 3: Not approved for Purchase
            alertMessages.push({
              message: isDirect
                ? { ...getShorexMessage(items, 'shoreExcursionsInvalidStatus') }
                : { ...getShorexMessage(items, 'invalidStatusTA') },
              lockedDownFlag: true,
              priority: 3,
            });
          }
          if (isBalancePastDue) {
            // Case 4: Balance Past Due
            alertMessages.push({
              message: isDirect
                ? { ...getShorexMessage(items, 'finalPaymentRequired') }
                : { ...getShorexMessage(items, 'finalPaymentRequiredTA') },
              lockedDownFlag: true,
              priority: 4,
            });
          }
          if (isRiver && excursionsCount === 0) {
            // Case 5: ShoreEx not available (single river)
            excursionOpenDate = moment([embarkYear - 1, 9]);
            alertMessages.push({
              message: { ...getShorexMessage(items, 'shoreExcursionsShorexOpenDateRiver') },
              lockedDownFlag: true,
              priority: 5,
            });
          }
          if (
            (isOcean || isExpedition || isMississippi) &&
            today.isBefore(moment(bookingDetails.excursionsOpenDateTz))
          ) {
            // Case 5: check if shoreEx is open (single ocean)
            alertMessages.push({
              message: { ...getShorexMessage(items, 'shoreExcursionsShorexOpenDateTz') },
              lockedDownFlag: true,
              priority: 5,
            });
          }
          if (isOceanRiver) {
            // Case 6: check if shoreEx is open (combo)
            const { comboBookings } = bookingDetails;
            const firstRiverLeg = comboBookings.find((booking) => booking.voyageType === VOYAGE_TYPE.RIVER);
            const riverEmbarkYear = moment(firstRiverLeg.embarkDate).get('year');
            // for river cruises, shoreEx open in November the year before embarkation
            const riverOpenDate = moment([riverEmbarkYear - 1, 10]);
            const riverOpen = today.isAfter(moment(riverOpenDate)) || excursionsCount !== 0;
            const shoreExNotOpen = excursionsCount === 0;
            if (riverOpen || moment(riverOpenDate).isAfter(excursionOpenDate)) {
              comboOpenString = 'comboOpenDateOcean';
            } else {
              excursionOpenDate = riverOpenDate;
            }
            if (shoreExNotOpen) {
              const comboMessage = { ...getShorexMessage(items, 'shoreExOpenCombo') };
              comboMessage.title = replaceCMSTokenWithValue(comboMessage.title, [
                {
                  key: 'COMBO_OPEN_DATE',
                  value: getShorexMessage(items, comboOpenString)?.title,
                },
              ]);
              alertMessages.push({
                message: comboMessage,
                lockedDownFlag: true,
                priority: 6,
              });
            } else if (excursionsCount !== 0 && oceanExcursionsRestricted) {
              alertMessages.push({
                message:
                  dayVoyageType === VOYAGE_TYPE.OCEAN ? { ...getShorexMessage(items, 'shoreExPartialOpen') } : '',
                lockedDownFlag: dayVoyageType === VOYAGE_TYPE.OCEAN,
                priority: 6,
              });
            }
          }
          alertMessages.sort((a, b) => a.priority - b.priority);
          const defaultPlacement = [ALERT_LOCATIONS.BANNER, ALERT_LOCATIONS.MODAL];
          const { message = null, lockedDownFlag = false, placement } = alertMessages[0] || {};

          if (message || (!gifOverride && !allGifCompleted)) {
            return {
              placement: placement || defaultPlacement,
              lockedDownFlag,
              text:
                message &&
                replaceCMSTokenWithValue(message.title, [
                  {
                    key: 'SHOREX_OPEN_DATE',
                    value: localExcursionOpenDate.format('LL').replace(' ', '\u00a0'),
                  },
                  {
                    key: 'ShoreExOpenDate',
                    value: localExcursionOpenDate.format('LL').replace(' ', '\u00a0'),
                  },
                  {
                    key: 'YEAROFCRUISE',
                    value: embarkYear,
                  },
                  {
                    key: 'YEARBEFORECRUISE',
                    value: embarkYear - 1,
                  },
                  {
                    key: 'TIME',
                    value: `${localExcursionOpenTime} (${TIME_ZONE_ABBREVIATIONS[country]})`,
                  },
                  {
                    key: 'ComboOpenDate',
                    value: comboOpenDate,
                  },
                ]),
              id: message && message.reference,
              gifLockdown: {
                gifCallToActionUrl: getCmsLabel(items, 'guestInformationFormButton', 'callToActionUrl'),
                gifCallToActionTitle: getCmsLabel(items, 'guestInformationFormButton', 'title'),
                gifValidationErrorBody: getCmsLabel(items, 'shorexGuestInformationFormTxt', 'title'),
                gifIncompleteLockdown,
              },
            };
          }
          return null;
        }
      );
    }),
    getShorexDays: new Selector(() =>
      createSelector(
        [getItineraryNavigationData, getVoyageType, getIsUKAUNZ, getItineraryLabels],
        (getNavigationData, voyageType, isUKAU, itineraryLabels) =>
          memoize((navDate, path) => {
            const navigationData = getNavigationData(navDate, path);
            const { days } = navigationData;
            if (days?.length && voyageType === VOYAGE_TYPE.EXPEDITION) {
              const isSamePort = (previousDay, targetDay) => {
                return previousDay?.city && previousDay?.city === targetDay?.city;
              };
              let groupEndDay = null;
              const lastDayInGroup = (startIndex) => {
                groupEndDay = startIndex;
                const { city } = days[startIndex];
                for (let dayNum = startIndex + 1; dayNum < days?.length; dayNum += 1) {
                  const day = days[dayNum];
                  if (day.city !== city || !day.isExpeditionLanding) {
                    groupEndDay = dayNum - 1;
                    return null;
                  }
                }
                return days[groupEndDay];
              };
              const condensedDays = days.reduce((acc, day, index) => {
                if (groupEndDay && groupEndDay >= index) {
                  return acc;
                }
                const previousDay = acc[acc.length - 1];
                const samePort = isSamePort(previousDay, day);
                if (!day?.isExpeditionLanding || !samePort) {
                  acc.push(day);
                  return acc;
                }
                if (previousDay && previousDay?.isExpeditionLanding && day?.isExpeditionLanding && samePort) {
                  lastDayInGroup(index - 1);
                  const endDay = days[groupEndDay];
                  previousDay.heading = itineraryLabels.days;
                  previousDay.number = `${previousDay.number} - ${endDay.number}`;
                  const startDate = moment(previousDay.date);
                  const endDate = moment(endDay.date);
                  const dateFormat = isUKAU ? `ddd, ${REGIONAL_SHORT_DATES.EU}` : `ddd, ${REGIONAL_SHORT_DATES.NA}`;
                  const newLabel = [
                    `${itineraryLabels.days} ${previousDay.number} \u2013 ${previousDay.city}`,
                    `${startDate.format(dateFormat)} to ${endDate.format(dateFormat)}`,
                  ];
                  previousDay.label = newLabel;
                  previousDay.displayDate = `${startDate.format(dateFormat)} to ${endDate.format(dateFormat)}`;
                } else {
                  acc.push(day);
                }
                return acc;
              }, []);
              return {
                ...navigationData,
                days: condensedDays,
              };
            }
            return navigationData;
          })
      )
    ),
    getShorexContent: new Selector(({ getPageContent, getTitle, getLockedDownMessage }) =>
      createSelector(
        [
          getPageContent,
          getTitle,
          getLockedDownMessage,
          (state) => getPaymentsAllEnabled(state),
          (state) => getPaymentsCheckoutEnabled(state),
          (state) => getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
        ],
        (
          content,
          title,
          lockedDownMessage,
          isPaymentsAllEnabled,
          isPaymentsCheckoutEnabled,
          paymentsDisabledBanner
        ) => {
          const items = get(content, 'sections[0].items', []);
          const messageItem = getShorexMessage(items, 'shoreExcursionsNoShorex');
          const subTitleItem = getShorexMessage(items, 'shoreExcursionsSubhead');
          const nonLandingDaySubtitle = getShorexMessage(items, 'shoreExcursionsNonLandingSubhead')?.subtitle;
          const landingDayBannerMessage = getShorexMessage(items, 'shoreExcursionsLandingAlert')?.title;
          const landingDayAlert = landingDayBannerMessage && {
            alertText: landingDayBannerMessage,
            id: 'shoreExcursionsLandingAlert',
          };
          const gifLockdown = get(lockedDownMessage, 'gifLockdown', {});
          const {
            gifCallToActionUrl,
            gifCallToActionTitle,
            gifValidationErrorBody,
            gifIncompleteLockdown,
          } = gifLockdown;
          const isPaymentsDisabled = !isPaymentsAllEnabled || !isPaymentsCheckoutEnabled;

          let bannerNotification = null;
          if (!lockedDownMessage && isPaymentsDisabled) {
            bannerNotification = {
              alertText: paymentsDisabledBanner,
              id: 'paymentLockdown',
            };
          }
          if (lockedDownMessage && lockedDownMessage.placement.includes(ALERT_LOCATIONS.BANNER)) {
            if (gifIncompleteLockdown && !lockedDownMessage.text) {
              bannerNotification = {
                alertText: null,
                button: {
                  text: gifCallToActionTitle,
                  callToActionUrl: gifCallToActionUrl,
                  appearance: 'link',
                },
                alertEndText: gifValidationErrorBody,
                linkAlert: true,
                id: 'gifLockdown',
                alertType: 'air',
              };
            } else {
              bannerNotification = {
                alertText: lockedDownMessage.text,
                id: lockedDownMessage.reference,
              };
            }
          }

          return {
            alert: lockedDownMessage && lockedDownMessage.text,
            bannerNotification,
            gifLockdown,
            landingDayAlert,
            noExcursionsAvailableMessage: messageItem.title,
            nonLandingDaySubtitle,
            subtitle: subTitleItem.subtitle || subTitleItem.subTitle, // CMSv2
            title,
          };
        }
      )
    ),
    getAllExcursions: new Selector(({ getExcursionsData }) =>
      createSelector(getExcursionsData, (excursionsData) =>
        Object.entries(excursionsData).reduce((acc, [date, { excursions, voyageId }]) => {
          if (!excursions) {
            return acc;
          }
          acc.push(
            ...excursions.map((excursion) => ({
              date,
              voyageId,
              ...excursion,
            }))
          );
          return acc;
        }, [])
      )
    ),
    isLoadingExcursions: new Selector(({ getExcursionsOnDate, isLoadingPage: getPageLoadingStatus }) =>
      createSelector([getExcursionsOnDate, getPageLoadingStatus], (getExcursions, isLoadingPage) =>
        memoize((date) => {
          const { isLoading } = getExcursions(date);
          return isLoading || isLoadingPage;
        })
      )
    ),
    getExcursionsOnDate: new Selector(({ getExcursionsData, getPageContent }) =>
      createSelector(
        [
          getExcursionsData,
          getItinerary,
          getLabels,
          getCountryCodeFromCurrency,
          isSinglePassenger,
          getPageContent,
          (state) => getModalLabels(state)(MODALS.RESERVATION_MODAL),
        ],
        (excursionsData, itinerary, labels, countryCode, isSinglePax, content, { labels: { inCart } }) =>
          memoize((date) => {
            const itineraryObject = itinerary.find((i) => i.date === date) || itinerary[0];
            if (!itineraryObject) {
              return null;
            }

            const { date: excursionDate } = itineraryObject;
            const items = get(content, 'sections[0].items', []);

            const excursionDay = get(excursionsData, `[${excursionDate}]`, {});
            const { excursions = [], loading } = excursionDay;
            if (loading && !excursions.length) {
              return {
                isLoading: true,
              };
            }
            return {
              excursions: excursions.map(
                ({
                  alert,
                  id,
                  images,
                  isPrivateShorex,
                  modal,
                  primaryButtonSubtext,
                  primaryButtonText,
                  reservationStatus,
                  title,
                }) => {
                  const { SOME_IN_CART, SOME_RESERVED } = PASSENGERS_RESERVATION_STATUS;
                  const { attributes, doublePrice, effortLevel, fromDate, serviceCode, singlePrice, timeOfDay } = modal;
                  const statuses = getReservationStatuses(reservationStatus);
                  // check if there is at least one reserved excursion
                  const isReserved = statuses.includes(SOME_RESERVED);
                  // check if there is at least one IN CART excursion
                  const isInCart = statuses.includes(SOME_IN_CART);
                  const isOnBoardOnly = modal.onboardOnly;

                  let status = alert;
                  status = isInCart ? getShorexMessage(items, 'removeFromCartButton', inCart)?.subtitle : status;
                  status = isReserved ? get(labels, 'generic.reservationConfirmed', '') : status;
                  if (!isSinglePax && status === '1 LEFT' && !isPrivateShorex) {
                    status = 'SOLD OUT';
                  }
                  return {
                    alert: isOnBoardOnly ? '' : status,
                    id,
                    serviceCode,
                    imageBadgeText: getAttributeDescription(attributes, 'PA'),
                    infoIcons: [
                      {
                        name: 'activity',
                        label: effortLevel,
                      },
                      {
                        name: 'clock',
                        label: timeOfDay.join('/'),
                      },
                    ],
                    primaryButton: {
                      text: primaryButtonText,
                      subText: replaceCMSTokenWithValue(primaryButtonSubtext, [
                        { key: 'PRICE', value: formatMoney(isSinglePax ? singlePrice : doublePrice, 0, countryCode) },
                      ]),
                    },
                    itineraryData: matchItineraryItem(itinerary, fromDate),
                    images: (images || []).map((img) =>
                      getImageAttributes({
                        image: { src: findImageSrc(img, TWO_BY_ONE) },
                        type: TWO_BY_ONE,
                        tier: 'xs',
                      })
                    ),
                    title,
                  };
                }
              ),
            };
          })
      )
    ),
    getSideContentModalInitialValues: new Selector(({ getAllExcursions }) =>
      createSelector(
        [
          getPassengers,
          getModalId,
          getAllExcursions,
          (state) => getModalLabels(state)(MODALS.RESERVATION_MODAL),
          (state, { passengerNumber }) => passengerNumber,
          (state, { date }) => date,
          (state, { date }) => getItineraryDate(state)(date),
          isSinglePassenger,
        ],
        (
          passengers,
          modalId,
          allExcursions,
          { labels: { soldOutParenthesis } },
          passengerNumber,
          date,
          itineraryDate,
          isSinglePax
        ) => {
          if (!modalId) {
            return {
              time: null,
            };
          }
          const excursions = allExcursions.filter((ex) => ex.date === date || ex.modal?.fromDate === date);
          const {
            title,
            modal: { times, serviceCode },
            reservationStatus,
          } = excursions.find((element) => element.id === modalId);
          if (!title) {
            return { time: null };
          }

          const { IN_CART, RESERVED } = RESERVATION_STATUS;
          const passengerTimes = getPassengerTimes(times, soldOutParenthesis, isSinglePax);

          if (times.length === 1) {
            return {
              time: get(passengerTimes, [0, 'value']),
            };
          }

          if (reservationStatus[passengerNumber - 1] === RESERVED) {
            const targetPax = passengers.find((pax) => pax.passengerNumber === passengerNumber);
            const { value } = times.reduce((acc, item) => {
              if (item.cartItemId[passengerNumber - 1]) {
                return passengerTimes.find((time) => time.value === item.inventoryCode);
              }
              return acc;
            }, {});
            if (value) {
              return { time: value };
            }
            const timeIds = passengerTimes.map((time) => time.value);

            const targetExcurs = targetPax.excursions.filter(
              (ex) => ex.serviceCode === serviceCode && ex.voyageId === itineraryDate?.voyageId
            );
            let targetEx = null;
            if (targetExcurs.length === 1) {
              targetEx = targetExcurs[0];
            } else {
              targetEx = targetExcurs.find((ex) => timeIds.includes(ex.invoiceCode));
            }
            if (targetEx) {
              const paxTime = passengerTimes.find((time) => time.value === targetEx.invoiceCode);
              return {
                time: paxTime ? paxTime.value : null,
              };
            }
          }
          if (reservationStatus[passengerNumber - 1] === IN_CART) {
            const paxTime = times.find((time) => time.cartItemId[passengerNumber - 1]);
            return {
              time: paxTime ? paxTime.inventoryCode : null,
            };
          }
          return { time: null };
        }
      )
    ),
    getIsOnboardOnly: new Selector(({ getAllExcursions, getPageContent }) =>
      createSelector([getModalId, getAllExcursions, getPageContent], (modalId, excursions, content) => {
        if (!modalId) {
          return {};
        }
        const items = get(content, 'sections[0].items', []);
        const excursion = excursions.filter((ex) => ex.id === modalId)[0];
        const onboardOnly = get(excursion, 'modal.onboardOnly', false);
        return {
          onboardOnly,
          onboardMessage: getShorexMessage(items, 'onboardOnlyMessage')?.title,
        };
      })
    ),
    getShorexCards: new Selector((selectors) => {
      const { getAllExcursions, isReserveButtonActive } = selectors;
      return createSelector(
        [
          getItinerary,
          getModalId,
          getAllExcursions,
          getBookingPassengers,
          isReserveButtonActive,
          getCountryCodeFromCurrency,
          (state) => getModalLabels(state)(MODALS.RESERVATION_MODAL),
          isSinglePassenger,
          (state, date) => date,
          getIsUKAUNZ,
          (state) => getFlagValue(state)(FLAG_NAMES.FILTER_LANDING_DAYS),
        ],
        (
          itinerary,
          modalId,
          allExcursions,
          bookingPassengers,
          reserveButtonActive,
          countryCode,
          { buttons: { reserve, print, viewAll }, durationUnit, labels: { perBooking, perPerson, included } },
          isSinglePax,
          date,
          isUKAUNZ,
          filterLandingDays
        ) => {
          if (!modalId) {
            return {
              title: null,
            };
          }
          const excursion = findExcursion(allExcursions, modalId, date);
          if (!excursion) {
            return null;
          }
          const {
            id,
            alert,
            modal,
            primaryButtonSubtext,
            title,
            images,
            specialTourTypes,
            effortLevel,
            landingDay,
            isPrivateShorex,
          } = excursion;
          const serviceCode = modal?.serviceCode || '';
          let alertMessage = alert;
          if (!isSinglePax && alert === '1 LEFT' && !isPrivateShorex) {
            alertMessage = 'SOLD OUT';
          }
          if (!title) {
            return null;
          }
          const subtitleRaw = isSinglePax ? modal.singlePrice : modal.doublePrice;
          const subtitle = subtitleRaw
            ? replaceCMSTokenWithValue(isPrivateShorex ? perBooking : perPerson, [
                { key: 'PRICE', value: formatMoney(subtitleRaw, 0, countryCode) },
              ])
            : included;
          let modalImages = getCarouselImageArray({
            images,
            imageRatioPriorities: [TWO_BY_ONE],
          });
          // we didn't get a modal images from evolution, so we set a "fake"
          // one here to allow the image's fallback error handling to work.
          if (!modalImages.length) {
            modalImages = [getFallbackImage(TWO_BY_ONE)];
          }

          const itineraryData = matchItineraryItem(itinerary, modal.fromDate);
          let displayDate = itineraryData.date;
          if (filterLandingDays && landingDay?.displayDate) {
            itineraryData.isLandingDay = true;
            displayDate = landingDay.displayDate;
          }
          const specialtyTourTypeValue = specialTourTypes && specialTourTypes.length > 0 ? specialTourTypes : [];
          return {
            disclaimers: modal.disclaimers,
            id,
            title,
            subtitle,
            availability: alertMessage,
            itineraryData,
            infoIcons: mapShorexIcons([{ displayName: effortLevel.displayName }, ...specialtyTourTypeValue]),
            detailedDate: moment(displayDate).format(
              isUKAUNZ ? `dddd, ${REGIONAL_SHORT_DATES.EU}` : `dddd, ${REGIONAL_SHORT_DATES.NA}`
            ),
            timeDetails: {
              times: modal.times,
              duration: `${modal.duration} ${get(
                durationUnit,
                `${modal.unit}.${modal.duration > 1 ? 'plural' : 'singular'}`,
                ''
              )}`,
            },
            sections: modal.sections,
            primaryButton: {
              disabled: !((bookingPassengers.passenger1 || bookingPassengers.passenger2) && reserveButtonActive),
              text: reserve,
              subText: replaceCMSTokenWithValue(primaryButtonSubtext, [
                { key: 'PRICE', value: formatMoney(modal.singlePrice, 0, countryCode) },
              ]),
            },
            printLabel: print,
            alert: alertMessage,
            serviceCode,
            images: modalImages,
            labels: { viewAll },
            effortLevel,
            subText: modal.subtitle.replace(/\s/g, ' '),
            isPrivateShorex,
            overland: modal?.overland,
            overlandExcursion: modal?.overlandExcursion || {},
          };
        }
      );
    }),
    getShorexModalData: new Selector((selectors) => {
      const { getShorexCards } = selectors;
      return createSelector([getShorexCards], (mainContent) => {
        return {
          mainContent: {
            ...mainContent,
            images: (mainContent?.images || []).map((image) => getImageAttributes({ image })),
          },
        };
      });
    }),
    getShorexSideContentForPassenger: new Selector(
      ({ getAllExcursions, getLockedDownMessage, getShoreExModalInfo, getPageContent }) =>
        createSelector(
          [
            getModalId,
            getAllExcursions,
            getPassengers,
            getShoreExModalInfo,
            getCountryCodeFromCurrency,
            getLockedDownMessage,
            isSinglePassenger,
            (state, passengerNumber, invCode, date) => getItineraryDate(state)(date),
            (state) => getModalLabels(state)(MODALS.RESERVATION_MODAL),
            (state, passengerNumber, selectedInventoryCode, date) => ({
              passengerNumber,
              selectedInventoryCode,
              date,
            }),
            (state) => getIsCurrentCountryPaymentBlocked(state)(ADDONS_CHECKOUT),
            getPageContent,
            getIsBalancePastDue,
            (state) => getCartRedirectModifyModal(state),
            (state) => getBookingDetails(state),
            (state) => getErrors(state),
            (state) => getPaymentsAllEnabled(state),
            (state) => getPaymentsCheckoutEnabled(state),
          ],
          (
            modalId,
            allExcursions,
            passengers,
            modalInfo,
            countryCode,
            lockedDownMessages,
            singlePax,
            itineraryDay,
            {
              buttons: {
                cancelConfirm,
                cancelReject,
                reserve,
                addToCart: addToCartLabel,
                cancelReservation,
                viewCalendar: viewCalendarLabel,
                removeFromCart: removeFromCartLabel,
              },
              labels: {
                soldOutParenthesis,
                inCart,
                reservationConfirmed,
                itineraryConflict: itineraryConflictLabel,
                itineraryConflictInCart,
                onlyTimeOffered,
                yourSelectedTime,
                selectTime,
                reservationTime,
                areYouSureCancel,
                oneReservationNeeded,
              },
            },
            { passengerNumber, selectedInventoryCode, date },
            isPaymentBlocked,
            content,
            isBalancePastDue,
            cartRedirectModifyModal,
            bookingDetails,
            errors,
            isPaymentsAllEnabled,
            isPaymentsCheckoutEnabled
          ) => {
            const passengerIndex = passengerNumber - 1;
            const modalState = get(modalInfo, [passengerIndex, 'state']);
            const metadata = get(modalInfo, [passengerIndex, 'metadata']);
            const otherPassenger = passengerIndex === 0 ? 1 : 0;
            const otherPassengerState = get(modalInfo, [otherPassenger, 'state']);
            const items = get(content, 'sections[0].items', []);

            if (!modalId) {
              return null;
            }
            const excursionsOnDate = allExcursions.filter((ex) => ex.date === date || ex.modal?.fromDate === date);
            const excursion = findExcursion(excursionsOnDate, modalId, date);
            if (!excursion) {
              return null;
            }

            let isShoreExRestricted = false;
            let foundShoreEx = 0;
            let restrictedLimit = null;
            const restrictedShorexes = allExcursions.filter(
              (ex) => ex.restricted && ex?.modal?.serviceCode === metadata?.serviceCode
            );
            const inCartShorexes = allExcursions.filter(
              (ex) => ex.reservationStatus[passengerIndex] === RESERVATION_STATUS.IN_CART
            );
            const bookedShorexes = bookingDetails?.passengers[passengerIndex]?.excursions;

            restrictedShorexes?.forEach((ex) => {
              if (ex?.date === date) {
                restrictedLimit = ex?.restrictedLimit;
                const booked = bookedShorexes?.filter((e) => e?.serviceCode === ex?.modal?.serviceCode) || [];
                const inCart = inCartShorexes?.filter((e) => e?.modal?.serviceCode === ex?.modal?.serviceCode) || [];
                foundShoreEx = (booked.length || 0) + (inCart.length || 0);
              }
            });

            if (foundShoreEx > 0 && restrictedLimit) {
              isShoreExRestricted = foundShoreEx >= restrictedLimit;
            }

            let gifIncomplete = false;
            if (lockedDownMessages) {
              const { gifLockdown } = lockedDownMessages;
              if (gifLockdown.gifIncompleteLockdown) {
                gifIncomplete = true;
              }
            }
            const lockedDown = otherPassengerState === RESERVATION_STATE_KEYS.CANCELING;

            const { CANCELING } = RESERVATION_STATE_KEYS;
            const { IN_CART, OPEN, RESERVED } = RESERVATION_STATUS;

            const {
              alert,
              isIncluded,
              isPrivateShorex,
              modal: { extensionType, fromDate, times, isSoldOut },
              reservationStatus,
            } = excursion;

            const availableInventoryCodes = times.map(({ inventoryCode }) => inventoryCode);

            const isMultipleTimes = times.length > 1;
            const targetPassenger = passengers.find((pax) => passengerNumber === pax.passengerNumber);
            if (!targetPassenger) {
              return null;
            }
            const passengerConflictStatus =
              !isMultipleTimes && times[0].passengerConflicts && times[0].passengerConflicts[passengerIndex];

            const cartItemId = !isMultipleTimes && times[0].cartItemId && times[0].cartItemId[passengerIndex];

            const paxTime = times.map((num) => ({
              inventoryCode: num.inventoryCode,
              conflict: num.passengerConflicts[passengerIndex],
            }));

            const primary = {};
            const selectedTime = paxTime.find((timing) => timing.inventoryCode === selectedInventoryCode);
            const { excursions } = targetPassenger;
            const passengerTimes = getPassengerTimes(times, soldOutParenthesis, singlePax);
            const secondary = {};
            let statusMessage = null;
            const metadataRemapped = metadata;
            const isSuccessfulCancellationStatus = false;

            // Update with actual value to handle conflict state
            let { inventoryCode } = times[0];
            const idx = passengerNumber - 1;
            const passengerStatus = reservationStatus[idx];
            let selectLabel = selectTime;
            let hasConflict;
            let isSoldOutFlag = isSoldOut;

            const inCartMessage = getShorexMessage(items, 'removeFromCartButton')?.subtitle || inCart;

            switch (passengerStatus) {
              case IN_CART: {
                primary.text = removeFromCartLabel;
                primary.actionType = SHOREX_ACTION_TYPES.REMOVE;
                selectLabel = isMultipleTimes ? yourSelectedTime : onlyTimeOffered;
                statusMessage = {
                  text:
                    cartRedirectModifyModal?.length && itineraryConflictInCart
                      ? itineraryConflictInCart
                      : inCartMessage,
                  type: STATUS_TYPES.ERROR,
                };
                switch (modalState) {
                  case CANCELING: {
                    primary.text = removeFromCartLabel;
                    primary.actionType = SHOREX_ACTION_TYPES.REMOVE;
                    break;
                  }
                  default: {
                    primary.actionType = SHOREX_ACTION_TYPES.REMOVE;
                    primary.text = removeFromCartLabel;
                    statusMessage = {
                      text:
                        cartRedirectModifyModal?.length && itineraryConflictInCart
                          ? itineraryConflictInCart
                          : inCartMessage,
                      type: STATUS_TYPES.ERROR,
                    };
                  }
                }
                break;
              }
              case OPEN: {
                primary.actionType = SHOREX_ACTION_TYPES.OPEN;

                primary.text = isIncluded ? reserve : addToCartLabel;
                if (isIncluded) {
                  primary.text = reserve;
                } else if (!isPaymentBlocked && !isIncluded) {
                  primary.text = addToCartLabel;
                } else {
                  primary.text = '';
                }
                selectLabel = isMultipleTimes ? selectTime : onlyTimeOffered;

                const hasTimeConflict =
                  (selectedTime && selectedTime.conflict && isMultipleTimes) ||
                  (!isMultipleTimes && passengerConflictStatus);

                const allowTimeConflict = !isEmpty(excursion.landingDay);

                if (hasTimeConflict && !allowTimeConflict) {
                  statusMessage = {
                    text: itineraryConflictLabel,
                    type: STATUS_TYPES.ERROR,
                  };
                  primary.text = viewCalendarLabel;
                  hasConflict = true;
                } else if (isShoreExRestricted) {
                  statusMessage = {
                    text: replaceToken(errors?.ShoreExRestricted, 'RESTRICTION_LIMIT', restrictedLimit),
                    type: STATUS_TYPES.ERROR,
                  };
                } else {
                  statusMessage = null;
                  hasConflict = false;
                }
                if (!singlePax && alert === '1 LEFT' && !isPrivateShorex) {
                  isSoldOutFlag = true;
                }
                primary.disabled =
                  (isSoldOutFlag && !hasConflict) ||
                  (lockedDownMessages && lockedDownMessages.lockedDownFlag && !hasConflict) ||
                  isShoreExRestricted;
                if ((primary.text === addToCartLabel || primary.text === reserve) && gifIncomplete) {
                  primary.disabled = true;
                } else if (primary.text === addToCartLabel && (!isPaymentsAllEnabled || !isPaymentsCheckoutEnabled)) {
                  primary.disabled = true;
                }
                break;
              }
              case RESERVED: {
                switch (modalState) {
                  case CANCELING:
                    primary.actionType = SHOREX_ACTION_TYPES.CANCEL_YES;
                    primary.text = cancelConfirm;

                    secondary.actionType = SHOREX_ACTION_TYPES.CANCEL_NO;
                    secondary.text = cancelReject;

                    statusMessage = {
                      text: areYouSureCancel,
                      type: STATUS_TYPES.ERROR,
                    };
                    selectLabel = reservationTime;
                    break;
                  default:
                    primary.actionType = SHOREX_ACTION_TYPES.CANCEL;
                    primary.text = cancelReservation;

                    statusMessage = {
                      text: reservationConfirmed,
                      type: STATUS_TYPES.MESSAGE,
                    };
                    selectLabel = reservationTime;
                    primary.disabled = lockedDownMessages && lockedDownMessages.lockedDownFlag && !hasConflict;
                    break;
                }

                const { invoiceCode } = excursions.find((e) => availableInventoryCodes.includes(e.invoiceCode)) || {};

                if (invoiceCode) {
                  inventoryCode = invoiceCode;
                }
                break;
              }
              default:
                break;
            }

            if (!selectedInventoryCode) {
              primary.disabled = true;
            }

            let disableField;
            if (!lockedDown) {
              disableField = gifIncomplete && hasConflict ? false : lockedDownMessages;
            }
            if (isShoreExRestricted) {
              disableField = true;
            }
            const ukReservationMessage = replaceCMSTokenWithValue(getCmsLabel(items, 'olbNotAvailableUk', 'title'), [
              { key: 'PHONE', value: getCmsLabel(items, 'PHONE', 'longText') },
            ]);
            const isUkMessage = isPaymentBlocked && !isIncluded ? ukReservationMessage : null;
            return {
              inventoryCode,
              passengerStatus,
              statusMessage,
              isSuccessfulCancellationStatus,
              passengerNumber,
              fromDate,
              lockedDown,
              alert: lockedDownMessages || alert,
              times: passengerTimes,
              isSoldOut: isSoldOutFlag,
              extensionType,
              primary,
              secondary,
              isMultipleTimes,
              selectLabel,
              metadataRemapped,
              cartItemId,
              hasConflict,
              disableField,
              isUkMessage,
              isBalancePastDue,
              isPrivateShorex,
              oneReservationNeeded,
            };
          }
        )
    ),
  },
});

const {
  creators: { receiveContent, receiveExcursions, pullFromHoldingArea, updateShoreExModalState },
  selectors: { getExcursionsData, getAllExcursions, getTotalExcursionsCount },
} = shorexStore;

const { ADD, REMOVE } = SHOREX_MODAL_ACTIONS;

export const fetchExcursions = ({
  date,
  refreshData,
  updateImmediately = true,
  fromHolding,
  isModalFromCalendar = false,
}) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const remappedBookingDetails = {
    ...bookingDetails,
    calendarId: bookingDetails.calendarId || getCalendarId(state),
  };
  const itinerary = getItinerary(state);

  let comboIds;
  if (remappedBookingDetails.comboBookings.length) {
    comboIds = remappedBookingDetails.comboBookings.map(({ shipCode, invoice }) => `${shipCode}${invoice}`).join('|');
  }
  const excursionDate = date || itinerary[0].date;
  if (fromHolding) {
    return dispatch(pullFromHoldingArea(`excursions[${excursionDate}]`));
  }
  const itineraryDay = itinerary.find((day) => day.date === excursionDate);
  const isExpeditionLanding = itineraryDay?.isExpeditionLanding || false;

  const voyageNameBase64 = bookingDetails?.cruise ? window.btoa(bookingDetails.cruise.name).replace(/={1,2}$/, '') : '';
  const queryParams = {
    voyageName: voyageNameBase64,
    isExpeditionLanding,
    isModalFromCalendar,
  };
  if (comboIds) {
    queryParams.comboIds = comboIds;
  }

  const url = buildUrl(
    '/excursions',
    ['office', 'currency', 'bookingNumber', 'voyageType', 'excursionDate', 'calendarId', 'shipCode'],
    {
      ...remappedBookingDetails,
      shipCode: remappedBookingDetails.ship.shipCode,
      excursionDate,
      startDate: itinerary[0].date,
      voyageType: (itineraryDay?.voyageType || '').toUpperCase(),
    },
    {
      ...queryParams,
    }
  );
  return dispatch(
    getData({
      url,
      store: shorexStore,
      node: `excursions[${excursionDate}]`,
      creator: receiveExcursions,
      tab: excursionDate,
      refreshData,
      updateImmediately,
    })
  );
};

export const fetchShorexPageContent = (date, refreshData) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const voyageType = getVoyageType(state);

  const url = buildUrl(
    '/pages/shoreExcursions',
    ['voyageType'],
    { voyageType },
    {
      country: bookingDetails.office,
    }
  );
  const fetchContent = dispatch(
    getData({
      url,
      store: shorexStore,
      node: 'content',
      creator: receiveContent,
      refreshData,
    })
  );

  if (date) {
    dispatch(fetchExcursions({ date, refreshData }));
  }

  return fetchContent;
};

const buildUpdateBookingPayload = (values, passengerNumber, excursions) => (dispatch, getState) => {
  const state = getState();
  const { calendarId, passengers } = getBookingDetails(state);
  const excursionsMap = excursions.map(({ modal, reservationStatus }) => {
    const { serviceCode, times, unit, duration } = modal;
    return {
      unit,
      duration,
      times,
      serviceCode,
      reservationStatus,
    };
  });

  const excursion = excursions.find((element) => element.id === getModalId(state));
  const { id, date, modal, title, subTitle, subtitle } = excursion;
  const { serviceCode, doublePrice, singlePrice, extensionType, fromDate } = modal;

  const { time } = values;

  const conflictTimeFrame = values ? getConflictTimeFrame(modal, time) : getConflictTimeFrame(modal, id);

  const bookingConfig = {
    payload: {
      title,
      subTitle: subtitle || subTitle, // CMSv2
      inventoryCode: id,
      doublePrice,
      singlePrice,
      extensionType,
      passengerNumber,
      excursions: excursionsMap,
      date: fromDate || date,
    },
  };

  const item = {
    forPassenger1: passengerNumber === 1,
    forPassenger2: passengerNumber === 2,
    serviceCode,
    pricePerPassenger: passengers.length > 1 ? doublePrice : singlePrice,
    ...conflictTimeFrame,
  };
  bookingConfig.payload.calendar = {
    calendarId,
    items: [
      {
        ...item,
        description: title,
      },
    ],
  };

  return bookingConfig;
};

const buildAddToCartPayload = ({ time }, passengerNumber, excursions) => (dispatch, getState) => {
  const state = getState();
  const { calendarId, passengers, voyage } = getBookingDetails(state);
  const excursionsMap = excursions.map(({ modal, reservationStatus }) => {
    const { serviceCode, times, unit, duration } = modal;
    return {
      unit,
      duration,
      times,
      serviceCode,
      reservationStatus,
    };
  });

  const excursion = excursions.find((element) => element.id === getModalId(state));
  const { id, date, modal, title, voyageId } = excursion;
  const pricePerPassengerTokenizedSingle = modal?.singlePriceTokenized;
  const pricePerPassengerTokenizedDouble = modal?.doublePriceTokenized;
  const { serviceCode, doublePrice, singlePrice, extensionType, times, duration, unit } = modal;
  const conflictTimeFrame = time ? getConflictTimeFrame(modal, time) : getConflictTimeFrame(modal, id);
  const timeBlock = times.find(({ inventoryCode }) => inventoryCode === time);
  const otherInCart = timeBlock ? timeBlock.cartItemId.some((itemId) => itemId) : false;

  return {
    payload: {
      calendarId,
      duration,
      extensionType,
      forPassenger1: passengerNumber === 1,
      forPassenger2: passengerNumber === 2,
      pricePerPassenger: passengers.length > 1 ? doublePrice : singlePrice,
      pricePerPassengerTokenized: otherInCart ? pricePerPassengerTokenizedDouble : pricePerPassengerTokenizedSingle,
      serviceCode,
      title,
      unit,
      date,
      voyageId: voyageId || voyage.id,
      ...conflictTimeFrame,
      excursions: excursionsMap,
    },
    otherInCart,
  };
};

const handleShoreExSuccess = ({ response, excursion, time, passengerNumber, action }) => (dispatch, getState) => {
  const state = getState();
  const { date, modal } = excursion;
  const { serviceCode, singlePrice, extensionType } = modal;
  // Transform response data for shoreEx store update
  const resExcursions = get(response, 'data.excursions', []);
  const totalExcursionsCount = getTotalExcursionsCount(state);
  const currExcursions = get(getExcursionsData(state), [date, 'excursions'], []);
  const excursionVoyageId = getExcursionVoyageId(state)(date);
  const remappedExcursions = currExcursions.reduce((acc, ex) => {
    // Check for fromDate === date match in case 2 excursions share the same serviceCode
    // (typically seen with combo bookings)
    const matchEx = resExcursions.find(
      (actionEx) =>
        actionEx.serviceCode === ex.modal.serviceCode && actionEx.times.some((time) => time.fromDate === date)
    );
    if (matchEx) {
      const { reservationStatus, ...restMatchEx } = matchEx;
      acc.push({
        ...ex,
        modal: {
          ...ex.modal,
          ...restMatchEx,
        },
        reservationStatus,
      });
    } else {
      acc.push(ex);
    }
    return acc;
  }, []);
  // Clear Dining data to force refetch of data on next visit to dining tab
  // since dining tab call receives all available times, available times need to be refreshed
  // after shoreEx is added or removed from cart or booking
  dispatch(clearAvailabilityDiningData());

  // Update shoreEx excursion list
  dispatch(
    receiveExcursions(
      {
        excursions: remappedExcursions,
        totalExcursionsCount,
        voyageId: excursionVoyageId,
      },
      date
    )
  );

  // Transform response data for user.bookingDetails store update
  const booking = getBookingDetails(state);
  const { duration, unit, toDate, times } = modal;
  const { title } = excursion;
  const timeMatch = times.find((t) => time === t.inventoryCode);
  const remappedBooking = {
    ...booking,
    passengers: booking.passengers.map((pax) => {
      if (pax.passengerNumber === passengerNumber) {
        if (action === ADD) {
          const excursionObj = {
            invoiceCode: get(timeMatch, 'inventoryCode'),
            serviceCode,
            description: title,
            fromDate: moment(date).format(),
            toDate: moment(toDate).format(),
            price: singlePrice,
            startTime: get(timeMatch, 'start.value'),
            duration,
            unit,
            extensionType,
            voyageId: excursionVoyageId,
            isWaitList: false,
            endTime: get(timeMatch, 'end.value'),
          };

          return {
            ...pax,
            excursions: [...pax.excursions, excursionObj],
          };
        }
        return {
          ...pax,
          excursions: [
            ...pax.excursions.filter((ex) => ex.voyageId !== excursionVoyageId || ex.serviceCode !== serviceCode),
          ],
        };
      }
      return pax;
    }),
  };

  // Update excursions in passenger booking details
  dispatch(receiveBookings({ booking: remappedBooking }));
};

export const updateShoreExBooking = (bookingFormValues, passengerNumber, date) => (dispatch, getState) => {
  const state = getState();
  const excursions = getAllExcursions(state).filter((ex) => ex.date === date || ex.modal?.fromDate === date);
  const excursion = excursions.find((element) => element.id === getModalId(state));
  const { isIncluded } = excursion;

  const { time } = bookingFormValues;
  const bookingConfig = isIncluded
    ? dispatch(buildUpdateBookingPayload(bookingFormValues, passengerNumber, excursions))
    : dispatch(buildAddToCartPayload(bookingFormValues, passengerNumber, excursions));

  const request = isIncluded ? updateBookingExcursions : addExcursionToCart;

  return dispatch(request(bookingConfig)).then((response) => {
    if (response.isSuccessful) {
      dispatch(
        handleShoreExSuccess({
          response,
          excursion,
          time,
          passengerNumber,
          action: ADD,
        })
      );

      return response;
    }

    const {
      AddToCartFailed,
      ReservationFailed,
      EvoErrorIncludedShorex,
      EvoErrorOfSoldOut,
      EvoErrorEmailRequired,
      ShoreExRestricted,
    } = getErrors(state);

    const restrictedLimit = excursion?.restricted ? excursion?.restrictedLimit : null;

    let error = [];
    const advisoryCode = getEvoErrorCode(response.data);
    if (response.status === 400 && advisoryCode === '1508') {
      error = EvoErrorOfSoldOut;
    } else if (response.status === 400 && advisoryCode === '1514') {
      error = EvoErrorIncludedShorex;
    } else if (response.status === 500 && advisoryCode === '1515' && restrictedLimit > 0) {
      error = replaceToken(ShoreExRestricted, 'RESTRICTION_LIMIT', restrictedLimit);
    } else if (response.status === 400 && advisoryCode === '2003') {
      error = EvoErrorEmailRequired;
    } else {
      error = isIncluded ? ReservationFailed : AddToCartFailed;
    }

    return Promise.reject(
      new SubmissionError({
        _error: error,
      })
    );
  });
};

export const removeShoreExFromCart = (values, passengerNumber, fromDate) => (dispatch, getState) => {
  const state = getState();
  const cartId = getCartId(state);
  const bookingDetails = getBookingDetails(state);
  const { calendarId, passengers } = bookingDetails;
  const currentPassengerNumber = getPassengerNumber(state)(passengers);
  const excursions = getAllExcursions(state).filter((ex) => ex.date === fromDate);
  const excursion = excursions.find((element) => element.id === getModalId(state));
  const bookingConfig = dispatch(buildAddToCartPayload(values, passengerNumber, excursions));
  const {
    payload: {
      title,
      endTime,
      extensionType,
      inventoryCode,
      pricePerPassenger,
      serviceCode,
      startTime,
      date,
      excursions: mappedExcursions,
      voyageId,
    },
  } = bookingConfig;
  const { cartItemId } = excursion.modal.times.find((item) => item.inventoryCode === inventoryCode);
  const itemId = cartItemId[passengerNumber - 1];
  const finalPayload = {
    cartId,
    calendarId,
    inventoryCode,
    excursions: mappedExcursions,
  };
  const bothPassengersInCart = passengers.length > 1 ? cartItemId.every((id) => id === itemId) : false;
  if (bothPassengersInCart) {
    finalPayload.put = {
      addedBy: currentPassengerNumber,
      description: title,
      endTime,
      extensionType,
      forPassenger1: passengerNumber !== 1,
      forPassenger2: passengerNumber !== 2,
      inventoryCode,
      isWaitlist: false,
      itemId,
      pricePerPassenger,
      saveForLater: false,
      serviceCode,
      startTime,
      voyageId,
    };
  } else {
    finalPayload.delete = [itemId];
  }

  return dispatch(removeExcursionFromCart(finalPayload, date)).then(async (response) => {
    if (response.isSuccessful) {
      dispatch(
        handleShoreExSuccess({
          response,
          excursion,
          time: values.time,
          passengerNumber,
          action: REMOVE,
        })
      );
      if (!bothPassengersInCart) {
        dispatch(updateCartItemCount(CART_COUNT_INPUTS.DECREASE));
      }
    }
    return response;
  });
};

// removeShoreExFromCart() requires the itinerary to be loaded. This function does not.
export const deleteSinglePaxShoreExFromCart = (itemId, inventoryCode, startDate) => (dispatch, getState) => {
  const state = getState();
  const cartId = getCartId(state);
  const bookingDetails = getBookingDetails(state);
  const { calendarId } = bookingDetails;
  const finalPayload = {
    cartId,
    calendarId,
    inventoryCode,
    excursions: [],
    delete: [itemId],
  };

  return dispatch(removeExcursionFromCart(finalPayload, startDate)).then((response) => {
    if (response.isSuccessful) {
      dispatch(updateCartItemCount(CART_COUNT_INPUTS.DECREASE));
    }
    return response;
  });
};

export const cancelExcursion = (values, modalInfo) => (dispatch, getState) => {
  const state = getState();
  const { fromDate } = modalInfo.metadata;
  const allExcursions = getAllExcursions(state);
  const excursions = allExcursions.filter((ex) => ex.date === fromDate || ex.modal?.fromDate === fromDate);
  const excursion = excursions.find((element) => element.id === getModalId(state));
  const {
    metadata: { passengerIndex },
  } = modalInfo;
  const passengerNumber = passengerIndex + 1;
  const { time } = values;
  const bookingConfig = dispatch(buildUpdateBookingPayload(values, passengerNumber, excursions));

  return dispatch(removeIncludedExcursion(bookingConfig)).then(async (response) => {
    if (response.isSuccessful) {
      dispatch(
        handleShoreExSuccess({
          response,
          excursion,
          time,
          passengerNumber,
          action: REMOVE,
        })
      );
    }
    return response;
  });
};

export const handleOpenExcursionModal = (id, date) => (dispatch, getState) => {
  const state = getState();
  const passengers = getPassengers(state);
  const excursions = getExcursionsData(state);
  const excursionOnDate = get(excursions, `${date}.excursions`, []);
  const excursion = excursionOnDate.find((item) => item.id === id);
  dispatch(setViewAndShowModal('shorexModal', { id }));
  passengers.forEach((pax, index) => {
    const reservationStatus = get(excursion, `reservationStatus[${index}]`, RESERVATION_STATE_KEYS.OPEN).replace(
      ' ',
      '_'
    );
    dispatch(
      updateShoreExModalState(RESERVATION_STATE_KEYS[reservationStatus], {
        ...excursion.modal,
        passengerIndex: index,
      })
    );
  });
};

export const handleCloseExcursionModal = () => (dispatch, getState) => {
  const state = getState();
  const passengers = getPassengers(state);
  dispatch(clearModal());
  passengers.forEach(() => {
    dispatch(updateShoreExModalState());
  });
  dispatch(setCartRedirectModifyModal(''));
};

export default shorexStore;
