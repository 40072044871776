import React from 'react';
import { RESERVATION_STATE_KEYS, RESERVATION_TYPES } from '../../common/Constants';
import { goTo } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import { ReservationModal } from '../index';

const SilverSpirits = (props) => {
  const { modalData, updateReservationModalState, requestRefund, isSubmitting, closeModal } = props;
  const {
    title,
    reference,
    primaryButton,
    secondaryButton,
    reservationState,
    extensionType,
    inventoryCode,
  } = modalData;

  const resetModalState = () => updateReservationModalState();

  if (title && reference === 'SSBP') {
    if (primaryButton.callToActionUrl) {
      primaryButton.onButtonClick = () => {
        goTo(primaryButton.callToActionUrl)();
        closeModal();
      };
    } else {
      const { CANCELED, CANCELING, RESERVED } = RESERVATION_STATE_KEYS;

      let primaryButtonClickFunction;
      switch (reservationState) {
        case RESERVED:
          primaryButtonClickFunction = () => updateReservationModalState(CANCELING);
          break;
        case CANCELING:
          primaryButton.attributes = {
            type: 'button',
          };
          primaryButtonClickFunction = () => requestRefund(extensionType, inventoryCode);
          secondaryButton.onButtonClick = resetModalState;
          break;
        case CANCELED:
          primaryButtonClickFunction = () => {
            resetModalState();
            closeModal();
          };
          break;
        default:
          break;
      }
      primaryButton.onButtonClick = primaryButtonClickFunction;
    }
  }

  return (
    <ReservationModal
      {...modalData}
      id="addToCartModal"
      onClose={resetModalState}
      reservationType={RESERVATION_TYPES.CART}
      forceAction={isSubmitting}
    />
  );
};

SilverSpirits.propTypes = {
  modalData: PropTypes.shape(PropTypes.modalData),
  updateReservationModalState: PropTypes.func.isRequired,
  addToCart: PropTypes.func,
  requestRefund: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

SilverSpirits.defaultProps = {
  modalData: {},
  addToCart: null,
  isSubmitting: false,
};

export default SilverSpirits;
