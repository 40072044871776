import Card from '@viking-eng/card';
import Heading from '@viking-eng/heading';
import unescape from 'lodash/unescape';
import React, { Component } from 'react';
import { Modal } from '..';
import { FIVE_BY_FOUR } from '../../common/Constants';
import { getImageAttributes } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './CardDetails.scss';

class CardDetailsModal extends Component {
  renderSections() {
    const { sections, displayCardGroups } = this.props;

    const renderCard = (card) => {
      if (card.images) {
        card.images = card.images
          .filter((img) => img.type === FIVE_BY_FOUR)
          .map((img) => getImageAttributes({ image: img }));
      }
      return (
        <div className="row no-gutters" key={card.id}>
          <Card card={card} />
        </div>
      );
    };

    if (displayCardGroups) {
      return sections.map((section, i) => {
        const index = `section${i}`;
        return (
          <div className="grouped-section" key={index}>
            {section.title && <div className="section-title">{section.title}</div>}
            {section.cards.map(renderCard)}
          </div>
        );
      });
    }

    return sections.map(renderCard);
  }

  render() {
    const { id, title, subTitle, subtitle, printLabel } = this.props;
    const unescapedTitle = unescape(title);
    return (
      <Modal id={id} printLabel={printLabel} title={unescapedTitle} subTitle={subTitle}>
        <div className="card-details-modal mvj-modal">
          <div className="row no-gutters modal-heading">
            <div className="col center-header">
              <Heading title={unescapedTitle} subTitle={subTitle || subtitle} />
            </div>
          </div>
          {this.renderSections()}
        </div>
      </Modal>
    );
  }
}

CardDetailsModal.propTypes = {
  printLabel: PropTypes.string,
  displayCardGroups: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      longText: PropTypes.string,
    })
  ),
  ...PropTypes.modalData,
};

CardDetailsModal.defaultProps = {
  printLabel: '',
  displayCardGroups: false,
  sections: [],
};

export default CardDetailsModal;
