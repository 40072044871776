import React from 'react';
import { history } from '../../common';
import { APP_PATHS, PATH_DIRECTORY } from '../../common/Constants';
import { CalendarDay, CalendarList, PageHeader } from '../../components';
import PropTypes from '../../PropTypes';

class Calendar extends React.Component {
  componentWillUnmount() {
    const { handleCalendarClear } = this.props;
    handleCalendarClear();
  }

  render() {
    const {
      backButton,
      isLoading,
      title,
      match: { url },
      navigationData: { days, selectPlaceholder },
      location: { pathname },
      customPageLink,
      landingDayNotification: { bannerNotification },
    } = this.props;
    const summaryPath = '/list';
    const paths = [
      {
        component: CalendarList,
        path: summaryPath,
      },
      {
        component: CalendarDay,
        path: '/:date',
        isDefault: true,
      },
    ];

    let attributes = {};
    if (!pathname.endsWith(summaryPath)) {
      attributes = {
        days,
        onDropdownChange: (value) => history.push(`${APP_PATHS.ITINERARY}/${value}`),
        selectPlaceholder,
      };
    }

    return (
      <div id="calendarPage" className="calendar-page">
        <PageHeader
          backButton={backButton}
          isLoading={isLoading}
          title={title}
          paths={paths}
          url={url}
          pathname={pathname}
          pageHeaderPathDepth={4}
          {...attributes}
          customPageLink={customPageLink}
          notification={bannerNotification}
        />
      </div>
    );
  }
}

Calendar.propTypes = {
  backButton: PropTypes.pageHeaderBackButton,
  isLoading: PropTypes.bool,
  match: PropTypes.routerMatch.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  navigationData: PropTypes.itineraryNavigatorData.isRequired,
  title: PropTypes.string,
  customPageLink: PropTypes.customPageLink,
  handleCalendarClear: PropTypes.func.isRequired,
  landingDayNotification: PropTypes.shape({
    bannerNotification: PropTypes.shape({
      alertText: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

Calendar.defaultProps = {
  backButton: {
    label: 'Home',
    previousPagePath: PATH_DIRECTORY,
  },
  isLoading: true,
  title: '',
  customPageLink: null,
  landingDayNotification: {
    bannerNotification: null,
  },
};

export default Calendar;
