import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore, { handleDeepLinkModal } from '../../common/CommonStore';
import { MODAL_URL_VARIABLE_NAMES, PAGE_NAMES } from '../../common/Constants';
import { withContent } from '../../components';

import Documents from './Documents';
import documentsStore, { fetchDocumentsPageContent } from './DocumentsStore';

const {
  getCustomizableSections,
  getTitle,
  isLoadingPage,
  getInformationModalData,
  getPassportVisaModalData,
} = documentsStore.selectors;
const { getLabels } = commonStore.selectors;

const mapStateToProps = (state, { location }) => ({
  isLoading: isLoadingPage(state, location.pathname),
  content: getCustomizableSections(state),
  modalData: getPassportVisaModalData(state),
  informationModalData: getInformationModalData(state),
  title: getTitle(state),
  tabLabels: get(getLabels(state), `pages.${PAGE_NAMES.GUEST_INFORMATION}.tabs`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchDocumentsPageContent()),
  handleCardDetailsClicked: (type, id) => {
    return dispatch(handleDeepLinkModal(type, id, MODAL_URL_VARIABLE_NAMES.GIF_CODE));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Documents);
