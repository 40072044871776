import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting, reduxForm } from 'redux-form';
import commonStore from '../../common/CommonStore';
import { FORMS, MODALS, PHOTO_UPLOAD_CROP_SIZE, PHOTO_UPLOAD_STEPS } from '../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../common/ModalStore';
import documentsStore, { convertPhoto, postPhotoValidation } from '../../pages/documents/DocumentsStore';
import PhotoUploadModal from './PhotoUploadModal';

const {
  selectors: { getErrors },
} = commonStore;

const {
  creators: { updatePhotoUploadStep, updatePassengerPhoto },
  selectors: { getImageUnknownError, getPhotoUploadStep },
} = documentsStore;

const { getComfortCheckInFormSteps } = documentsStore.selectors;

const { clearModal } = modalStore.creators;

const mapStateToProps = (state) => {
  const modalId = MODALS.PHOTO_UPLOAD_MODAL;
  const { modalData, buttons, passengerPhotoConverted } = getComfortCheckInFormSteps(state);
  return {
    backLabel: buttons.back,
    cropAndSubmitLabel: buttons.cropAndSubmit,
    cropSizeHeight: PHOTO_UPLOAD_CROP_SIZE.HEIGHT,
    cropSizeWidth: PHOTO_UPLOAD_CROP_SIZE.WIDTH,
    errors: getErrors(state),
    formStep: getPhotoUploadStep(state) || PHOTO_UPLOAD_STEPS.GUIDELINE,
    getStartedLabel: buttons.getStarted,
    id: modalId,
    isImageUnknownError: getImageUnknownError(state),
    isSubmitting: isSubmitting(FORMS.GUEST_CHECK_IN_FORM)(state),
    modalData,
    passengerPhotoConverted,
    returnToCheckInLabel: buttons.returnToCheckIn,
    uploadNewPhotoLabel: buttons.uploadNewPhoto,
    rotateLabel: buttons.rotate,
    uploadFromDeviceLabel: buttons.uploadFromDevice,
    zoomLabel: buttons.zoom,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => {
    dispatch(clearModal());
    return dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.GUIDELINE));
  },
  handleRedirectToUploadModal: () => dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.UPLOAD)),
  handleRedirectToCropModal: () => dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.CROP)),
  handleRedirectToSuccessModal: () => dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.SUCCESS)),
  handlePhotoSubmit: (croppedImage, passengerNumber, photoValid) =>
    dispatch(updatePassengerPhoto(croppedImage, passengerNumber, photoValid)),
  handlePhotoConvert: (file, width, type) => {
    dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.CROP));
    return dispatch(
      convertPhoto({
        file,
        handleError: () => dispatch(updatePhotoUploadStep(PHOTO_UPLOAD_STEPS.UPLOAD, true)),
        type,
        width,
      })
    );
  },
  handlePhotoValidation: (photo) => dispatch(postPhotoValidation(photo)),
  openModal: (type, id) => {
    dispatch(setViewAndShowModal(type, { id }));
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORMS.GUEST_CHECK_IN_FORM,
    enableReinitialize: true,
    onSubmit: () => null,
  })
)(PhotoUploadModal);

export default enhance;
