/* eslint-disable no-case-declarations */
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import commonStore, { modalStore } from '../../common';
import { getData, postData, putData } from '../../common/Api';
import {
  ACTIVITIES_SURVEY_VARIABLES,
  APP_PATHS,
  COMFORT_CHECK_IN_STEPS,
  COUNTRIES,
  COUNTRIES_NATIONALITY,
  DAYS_TO_GO,
  DEFAULT_DATE_FORMAT,
  EMAIL_TEMPLATE_IDS,
  EVO_ACTIVITIES_SURVEY_CODE,
  FEATURE_RESTRICTED,
  FIND_COUNTRY_MAPPING,
  FORMS,
  HEALTH_SURVEY_QUESTION_TYPES,
  MVJ_FLAG_VARIABLES,
  PAGE_NAMES,
  PATIENT_TYPES,
  REGIONAL_LONG_DATES,
  REGIONAL_SHORT_DATES,
  TAB_NAMES,
  USER_TYPES,
} from '../../common/Constants';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import userStore from '../../common/UserStore';
import {
  buildUrl,
  convertDate,
  convertStringToStartCase,
  getCmsLabel,
  getFormFields,
  getPassengerAbbrevName,
  mapStatesToOptions,
  navigateTo,
  replaceCMSTokenWithValue,
} from '../../common/Utils';
import { phoneMask, postalCodeMask } from '../../common/forms/Masks';
import { normalizeCountryCode } from '../../common/forms/Validations';
import PaymentsStore from '../payments/PaymentsStore';

const {
  HEALTH_QUESTIONNAIRE_ID,
  HEALTH_SURVEY_OPEN_DATE,
  BND_HEALTH_SURVEY,
  FIT_TO_TRAVEL_FLAG,
  ACTIVITIES_OF_INTEREST_FLAG,
} = MVJ_FLAG_VARIABLES;

const {
  GUEST_INFORMATION,
  TRAVEL_DOCUMENTS,
  COMFORT_CHECK_IN,
  HEALTH_SURVEY,
  FIT_TO_TRAVEL,
  ACTIVITIES_OF_INTEREST,
} = TAB_NAMES;
const {
  getMvjProperties,
  getMvjStrings,
  getLabels,
  getTrackingTools,
  getPassengerTicketContract,
  getPageTabLabels,
  getCountries,
  getCountriesPopularFirst,
  getCountryStates,
  getSubmitting,
  getErrors,
  getIsUKAUNZ,
  getFlagValue,
  getDisplayComfortCheckIn,
  getHasOnboardCreditCard,
  getPreviousPage,
} = commonStore.selectors;
const {
  getBookingDetails,
  getCountryCodeFromCurrency,
  getDaysToGo,
  getDocumentation,
  getUpdateUserData,
  getFeatureRestricted,
  getSessionId,
  getPassengerNames,
  getVoyageType,
  getUserType,
  getAuthData,
  getIsMississippi,
  getLoggedInUser,
  getPassengerNumber,
  getIsCloseToDepartureDate,
  getUserData,
} = userStore.selectors;
const { getModalData } = modalStore.selectors;
const { getOnboardCreditCardSection } = PaymentsStore.selectors;
const formName = FORMS.GUEST_INFO_FORM;
const BIRTHDATE_FORMAT = DEFAULT_DATE_FORMAT.YEAR_FIRST;
const PASSPORT_FORMAT = DEFAULT_DATE_FORMAT.YEAR_FIRST;
const LICENSE_FORMAT = DEFAULT_DATE_FORMAT.YEAR_FIRST;

export const GIF_DATE_FIELDS = [
  { name: 'birthdate', format: BIRTHDATE_FORMAT },
  { name: 'passportExpiresDate', format: PASSPORT_FORMAT },
  { name: 'passportIssuedDate', format: PASSPORT_FORMAT },
  { name: 'expiresOnLicense', format: LICENSE_FORMAT },
];

const documentsStore = createPageTabsDuck('documents').extend({
  types: [
    'RECEIVE_GIF_DATA',
    'RECEIVE_CHECK_IN_PAGE_CONTENT',
    'UPDATE_SELECTED_GUEST',
    'UPDATE_NEXT_GUEST',
    'UPDATE_NEXT_LOCATION',
    'SET_COMFORT_CHECK_IN_PASSENGER',
    'SET_COMFORT_CHECK_IN_STEP',
    'SET_HEALTH_SURVEY_CHECK_IN_PASSENGER',
    'CLEAR_CHECK_IN_PASSENGER',
    'RECEIVE_COMFORT_CHECK_IN_STATUS',
    'PHOTO_UPLOAD_STEP',
    'SET_CHECK_IN_PHOTO',
    'SET_CHECK_IN_SUBMITTING',
    'SET_CONVERTED_PHOTO',
    'RECEIVE_HEALTH_SURVEY',
    'CHECK_IN_COMBO_ERROR',
    'SET_CHECK_IN_SUBMIT_FAILED',
    'SET_CHECK_IN_FETCH_FAILED',
  ],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_GIF_DATA:
        return {
          ...state,
          guestInformation: {
            guests: action.payload,
            selectedGuest: get(state, 'guestInformation.selectedGuest', action.payload[0]?.passengerID),
          },
        };
      case types.RECEIVE_CHECK_IN_PAGE_CONTENT:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            checkInPageContent: {
              ...action.payload,
            },
          },
        };
      case types.UPDATE_SELECTED_GUEST:
        return {
          ...state,
          guestInformation: {
            ...state.guestInformation,
            selectedGuest: action.selectedGuest,
          },
        };
      case types.UPDATE_NEXT_GUEST:
        return {
          ...state,
          guestInformation: {
            ...state.guestInformation,
            nextGuest: action.nextGuest,
          },
        };
      case types.UPDATE_NEXT_LOCATION:
        return {
          ...state,
          guestInformation: {
            ...state.guestInformation,
            nextLocation: action.nextLocation,
          },
        };
      case types.SET_COMFORT_CHECK_IN_PASSENGER:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            passengerNumber: action.passengerNumber,
          },
        };
      case types.SET_COMFORT_CHECK_IN_STEP:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            stepNumber: action.stepNumber,
          },
        };
      case types.SET_HEALTH_SURVEY_CHECK_IN_PASSENGER:
        return {
          ...state,
          healthSurvey: {
            ...state.healthSurvey,
            passengerNumber: action.passengerNumber,
          },
        };
      case types.CLEAR_CHECK_IN_PASSENGER:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            passengerNumber: null,
          },
        };
      case types.RECEIVE_COMFORT_CHECK_IN_STATUS:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            checkInStatus: action.payload,
          },
        };
      case types.PHOTO_UPLOAD_STEP:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            checkInStatus: action.payload,
            photoUploadStep: action.step,
            isImageUnknownError: action.isError,
          },
        };
      case types.SET_CHECK_IN_PHOTO:
        const passengerPhotos = get(state, 'comfortCheckIn.passengerPhotos', []);
        const passengerPhoto = passengerPhotos.find((item) => item.passengerNumber === action.passengerNumber);
        if (passengerPhoto) {
          passengerPhoto.photo = action.photo;
          passengerPhoto.photoValid = action.photoValid;
        } else {
          passengerPhotos.push({
            photo: action.photo,
            passengerNumber: action.passengerNumber,
            photoValid: action.photoValid,
          });
        }
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            passengerPhotos,
          },
        };
      case types.SET_CONVERTED_PHOTO:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            isImageUnknownError: false,
            passengerPhotoConverted: action.photo,
          },
        };
      case types.SET_CHECK_IN_SUBMITTING:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            submitting: action.submitting,
          },
        };
      case types.SET_CHECK_IN_SUBMIT_FAILED:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            submitFailed: action.submitFailed,
          },
        };
      case types.SET_CHECK_IN_FETCH_FAILED:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            checkInStatus: {
              ...state.comfortCheckIn.checkInStatus,
              mxpError: action.mxpError,
            },
          },
        };
      // TODO: after update for fetching multiple legs of check in status
      // This should be removed in favor of checking if legs have a mix of checked in and not
      case types.CHECK_IN_COMBO_ERROR:
        return {
          ...state,
          comfortCheckIn: {
            ...state.comfortCheckIn,
            comboError: {
              ...state.comfortCheckIn.comboError,
              ...action.error,
            },
          },
        };
      case types.RECEIVE_HEALTH_SURVEY:
        return {
          ...state,
          healthSurvey: {
            ...state.healthSurvey,
            questionContent: {
              ...action.payload,
              loaded: true,
            },
          },
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveGifData: (payload) => ({
      type: types.RECEIVE_GIF_DATA,
      payload,
    }),
    receiveCheckInPageContent: (payload) => ({
      type: types.RECEIVE_CHECK_IN_PAGE_CONTENT,
      payload,
    }),
    updateSelectedGuest: (selectedGuest) => ({
      type: types.UPDATE_SELECTED_GUEST,
      selectedGuest,
    }),
    updateNextGuest: (nextGuest) => ({
      type: types.UPDATE_NEXT_GUEST,
      nextGuest,
    }),
    updateNextLocation: (nextLocation) => ({
      type: types.UPDATE_NEXT_LOCATION,
      nextLocation,
    }),
    setComfortCheckInPassenger: (passengerNumber) => ({
      type: types.SET_COMFORT_CHECK_IN_PASSENGER,
      passengerNumber,
    }),
    setComfortCheckInStep: (stepNumber) => ({
      type: types.SET_COMFORT_CHECK_IN_STEP,
      stepNumber,
    }),
    setHealthSurveyCheckInPassenger: (passengerNumber) => ({
      type: types.SET_HEALTH_SURVEY_CHECK_IN_PASSENGER,
      passengerNumber,
    }),
    clearCheckInPassenger: () => ({
      type: types.CLEAR_CHECK_IN_PASSENGER,
    }),
    receiveComfortCheckInStatus: (payload) => ({
      type: types.RECEIVE_COMFORT_CHECK_IN_STATUS,
      payload,
    }),
    updatePhotoUploadStep: (step, isError) => ({
      type: types.PHOTO_UPLOAD_STEP,
      step,
      isError,
    }),
    updateConvertedPhoto: (photo) => ({
      type: types.SET_CONVERTED_PHOTO,
      photo,
    }),
    updatePassengerPhoto: (photo, passengerNumber, photoValid) => ({
      type: types.SET_CHECK_IN_PHOTO,
      photo,
      passengerNumber,
      photoValid,
    }),
    setCheckInSubmitting: (submitting) => ({
      type: types.SET_CHECK_IN_SUBMITTING,
      submitting,
    }),
    setComfortCheckInSubmitFailed: (submitFailed) => ({
      type: types.SET_CHECK_IN_SUBMIT_FAILED,
      submitFailed,
    }),
    setComfortCheckInFetchFailed: (mxpError) => ({
      type: types.SET_CHECK_IN_FETCH_FAILED,
      mxpError,
    }),
    setCheckInComboError: (error) => ({
      type: types.CHECK_IN_COMBO_ERROR,
      error,
    }),
    receiveHealthSurvey: (payload) => ({
      type: types.RECEIVE_HEALTH_SURVEY,
      payload,
    }),
  }),
  selectors: {
    isLoadingGif: new Selector(({ getStoreState }) =>
      createSelector(getStoreState, (storeState) => get(storeState, `${GUEST_INFORMATION}.loading`, false))
    ),
    getGifPassengers: (state) => get(state, `documents.${GUEST_INFORMATION}.guests`, []),
    getCheckInPageContent: (state) => get(state, `documents.${COMFORT_CHECK_IN}.checkInPageContent`, []),
    getImageUnknownError: (state) => get(state, 'documents.comfortCheckIn.isImageUnknownError'),
    getSelectedGuest: (state) => get(state, `documents.${GUEST_INFORMATION}.selectedGuest`, 1),
    getNextGuest: (state) => get(state, `documents.${GUEST_INFORMATION}.nextGuest`, null),
    getNextLocation: (state) => get(state, `documents.${GUEST_INFORMATION}.nextLocation`, null),
    getPhotoUploadStep: (state) => get(state, 'documents.comfortCheckIn.photoUploadStep'),
    getGifCountriesSelected: (state) => formValueSelector(formName)(state, 'country', 'emergencyContactCountryCode'),
    getGifErrors: new Selector(({ getGifCountriesSelected }) =>
      createSelector(
        [getMvjProperties, getGifCountriesSelected, getCountryCodeFromCurrency],
        ({ errors = {} }, countriesSelected, defaultCountry) => {
          const regionalErrors = {};
          // converting NZ to AU since they follow same validation rules
          Object.entries(countriesSelected).forEach(([field, countryCode]) => {
            let code = countryCode === 'NZ' ? 'AU' : countryCode;
            // if no country is selected, default to the country of booking errors
            if (!code) {
              code = defaultCountry;
            }
            // If country is selected and not a primary country,
            // this sets to use a generic message for country specific errors
            if (!Object.values(COUNTRIES).includes(code)) {
              code = 'XX';
            }
            const errorMessages = {};
            Object.entries(errors).forEach(([errorCode, messages]) => {
              const { message } =
                messages.find((m) => {
                  const { countries } = m;
                  return !countries || countries.includes(code);
                }) || {};
              errorMessages[errorCode] = message;
            });

            regionalErrors[field] = errorMessages;
          });

          return {
            guestErrors: regionalErrors.country,
            emergencyContactErrors: regionalErrors.emergencyContactCountryCode,
          };
        }
      )
    ),
    getPhoneMasks: new Selector(({ getGifCountriesSelected }) =>
      createSelector([getGifCountriesSelected], (countriesSelected) => {
        const { country, emergencyContactCountryCode } = countriesSelected || {};
        return {
          guestPhoneMask: phoneMask(country),
          emergencyContactPhoneMask: phoneMask(emergencyContactCountryCode),
        };
      })
    ),
    getPostalCodeMasks: new Selector(({ getGifCountriesSelected }) =>
      createSelector(getGifCountriesSelected, (countriesSelected) => {
        const { country, emergencyContactCountryCode } = countriesSelected || {};
        return {
          guestZipMask: postalCodeMask(country),
          emergencyContactZipMask: postalCodeMask(emergencyContactCountryCode),
        };
      })
    ),
    getGuestSelectorLabels: new Selector(() =>
      createSelector(
        [(state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, GUEST_INFORMATION)],
        ({ labels: { selectGuest, pending, completed } }) => {
          return {
            title: selectGuest,
            pending,
            complete: completed,
          };
        }
      )
    ),

    getGifFormPlaceholders: new Selector(({ getPageContent }) =>
      createSelector(
        [getPageContent, getCountryCodeFromCurrency, getIsMississippi],
        ({ forms, sections }, defaultCountry, isMississippi) => {
          const formSections = forms?.[0]?.sections || [];

          const htmlNamesGuestFields = {
            first: 'firstName',
            middle: 'middleName',
            last: 'lastName',
            suffix: 'suffix',
            title: 'title',
            address1: 'address1',
            address2: 'address2',
            city: 'city',
            country: 'country',
            email: 'email',
            gender: 'gender',
            birthdate: 'birthdate',
            phone: 'phoneNumber',
            mobile: 'mobile',
            state: 'state',
            zip: 'zip',
          };

          const htmlNamesPassportFields = {
            nationality: 'nationality',
            passportNumber: 'passportNumber',
            birthPlace: 'countryOfBirth',
            passportIssuer: 'passportIssued',
            passportIssuedDate: 'issuedOn',
            passportExpiresDate: 'expiresOn',
          };

          const htmlNamesLicenseFields = {
            licenseNumber: 'licenseNumber',
            licenseIssuedState: 'stateLicense',
            licenseExpiresDate: 'expiresOnLicense',
          };

          const htmlNamesTravelInsuranceFields = {
            insuranceProvider: 'insuranceProvider',
            insurancePolicyNumber: 'insurancePolicyNumber',
            insuranceProviderTelephone: 'insuranceProviderTelephone',
          };

          const htmlNamesContactFields = {
            emergencyContact: 'fullName',
            emergencyContactRelationship: 'relationshipToGuest',
            emergencyContactDayPhone: 'phoneNumber',
            emergencyContactAddress1: 'address1',
            emergencyContactAddress2: 'address2',
            emergencyContactCity: 'city',
            emergencyContactZip: 'zip',
            emergencyContactCountryCode: 'country',
            emergencyContactState: 'state',
          };
          const guestFields = getFormFields(
            htmlNamesGuestFields,
            formSections.find((form) => form?.title === 'GUEST INFORMATION')
          );

          return {
            guest: {
              ...guestFields,
              tooltip: getCmsLabel(sections[0]?.items, 'requiredFieldIndicatorLabel'),
            },
            passport: getFormFields(
              htmlNamesPassportFields,
              formSections.find((form) => form?.title === 'PASSPORT INFORMATION')
            ),
            travel: getFormFields(
              htmlNamesTravelInsuranceFields,
              formSections.find((form) => form?.title === 'TRAVEL INSURANCE INFORMATION')
            ),
            license:
              isMississippi && defaultCountry === COUNTRIES.UNITED_STATES
                ? getFormFields(
                    htmlNamesLicenseFields,
                    formSections.find((form) => form?.title === 'DRIVER&#39;S LICENSE INFORMATION')
                  )
                : null,
            emergencyContact: getFormFields(
              htmlNamesContactFields,
              formSections.find((form) => form?.title === 'EMERGENCY CONTACT')
            ),
          };
        }
      )
    ),
    getRegionalGifFields: new Selector(({ getGifCountriesSelected }) =>
      createSelector(
        [getGifCountriesSelected, getCountryCodeFromCurrency, getMvjProperties, getMvjStrings],
        (
          { country: guestCountryCode, emergencyContactCountryCode },
          defaultCountry,
          { bookingCountries },
          { labels }
        ) => {
          const getRegionalLabels = (countryCode) => {
            const formCountry =
              bookingCountries?.find((country) => country.code === countryCode) ||
              bookingCountries?.find((country) => country.code === defaultCountry);
            const { countryConstants } = formCountry || {};

            return {
              state: labels?.forms[countryConstants?.stateProvinceLabelKey] || 'State',
              zip: labels?.forms[countryConstants?.zipPostalCodeLabelKey] || 'Zip Code',
            };
          };
          return {
            guest: getRegionalLabels(normalizeCountryCode(guestCountryCode, 'UK')),
            emergencyContact: getRegionalLabels(normalizeCountryCode(emergencyContactCountryCode, 'UK')),
          };
        }
      )
    ),
    getFormActionLabels: new Selector(() =>
      createSelector(
        [(state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, GUEST_INFORMATION)],
        ({ buttons: { cancel, update }, labels: { gifUpdatedLabel } }) => ({
          cancel,
          update,
          alert: gifUpdatedLabel,
        })
      )
    ),
    getConfirmationModalLabels: new Selector(({ getPageContent }) =>
      createSelector([getPageContent, getLabels], (content, { buttons: { back, yesContinue } = {} }) => {
        const getLabel = (reference) => getCmsLabel(content?.sections, reference);
        return {
          buttons: [
            { id: 'cancel', text: back },
            { id: 'confirm', text: yesContinue },
          ],
          message: getLabel('documentsYouSureSubtitleLabel'),
          title: getLabel('documentsYouSureTitleLabel'),
        };
      })
    ),
    getGifInitialValues: new Selector(({ getGifPassenger, getSelectedGuest }) =>
      createSelector(
        [getGifPassenger, getSelectedGuest, getCountryCodeFromCurrency],
        (getPassenger, selectedGuest, defaultCountry) => {
          const passenger = getPassenger(selectedGuest);
          if (!passenger || !Object.keys(passenger)?.length) {
            return null;
          }
          return {
            ...passenger,
            passengerID: `${selectedGuest}`,
            defaultCountry,
          };
        }
      )
    ),
    getGifPassengerSelectorData: new Selector(({ getGifPassengers }) =>
      createSelector(getGifPassengers, (passengers) =>
        passengers.map(({ first, GIFCompleted, last, passengerID, middle }) => ({
          isCompleted: GIFCompleted,
          name: getPassengerAbbrevName({ firstName: first, middle, lastName: last }),
          passengerID,
        }))
      )
    ),
    getGifPassenger: new Selector(({ getGifPassengers }) =>
      createSelector(
        getGifPassengers,
        getIsUKAUNZ,
        getCountryCodeFromCurrency,
        getCountryStates,
        (passengers, isUKAUNZ, countryCode, getCountryStatesFunction) =>
          memoize((passengerID) => {
            const foundPassenger = passengers.find((passenger) => `${passenger.passengerID}` === `${passengerID}`);
            if (!foundPassenger) {
              return {};
            }

            const MIXED_CASE_FIELDS = [
              'first',
              'last',
              'middle',
              'address1',
              'address2',
              'city',
              'emergencyContact',
              'emergencyContactRelationship',
              'emergencyContactAddress1',
              'emergencyContactAddress2',
              'emergencyContactCity',
            ];

            const stateProvs = getCountryStatesFunction(countryCode) || [];
            const state = stateProvs.find((stp) =>
              [stp.abbreviation, stp.evoAbbreviation].filter((stp) => stp !== undefined).includes(foundPassenger.state)
            );

            const emergencyContactState = stateProvs.find((stp) =>
              [stp.abbreviation, stp.evoAbbreviation]
                .filter((stp) => stp !== undefined)
                .includes(foundPassenger.emergencyContactState)
            );

            const passenger = {
              ...foundPassenger,
              emergencyContactState: emergencyContactState
                ? emergencyContactState.abbreviation
                : foundPassenger.emergencyContactState,
              state: state ? state.abbreviation : foundPassenger.state,
              zip: foundPassenger.zip.replace(/ /g, ''),
            };

            GIF_DATE_FIELDS.forEach(({ format, name }) => {
              const date = passenger[name];
              // 19000101 is used as a filler date in Evolution, so needs to be removed on FE
              if (date) {
                if (date === '19000101') {
                  passenger[name] = undefined;
                } else {
                  passenger[name] = convertDate({ date, fromFormat: format, toFormat: DEFAULT_DATE_FORMAT.NA });
                  passenger[name] = convertDate({
                    date,
                    fromFormat: format,
                    toFormat: isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA,
                  });
                }
              }
            });

            MIXED_CASE_FIELDS.forEach((name) => {
              const str = passenger[name];
              if (str) {
                passenger[name] = convertStringToStartCase(str, true);
              }
            });
            return {
              ...passenger,
              passengerID: `${passenger.passengerID}`,
            };
          })
      )
    ),
    getCountryOptions: new Selector(({ getGifInitialValues }) =>
      createSelector([getGifInitialValues, getCountriesPopularFirst], (initialValues, getPopularCountries) => {
        const countries = {
          alpha2: getPopularCountries('twoLetterCode'),
          alpha3: getPopularCountries('threeLetterCode'),
        };
        return memoize(
          (name, base = 3) => {
            if (initialValues[name]) {
              return initialValues[name].length === 2 ? countries.alpha2 : countries.alpha3;
            }
            return countries[`alpha${base}`];
          },
          (...args) => JSON.stringify(args)
        );
      })
    ),
    getCountryName: new Selector(() =>
      createSelector([getCountries], (countries) =>
        memoize((countryCode) => {
          if (!countryCode) {
            return null;
          }
          const countryCodeLength = countryCode.length === 2 ? 'twoLetterCode' : 'threeLetterCode';
          const country = countries.find((countryObject) =>
            [countryObject[countryCodeLength], FIND_COUNTRY_MAPPING[countryCode]].includes(countryCode)
          );
          return convertStringToStartCase(get(country, 'label', ''));
        })
      )
    ),
    getStatesByCountry: new Selector(() =>
      createSelector(
        [
          (state) => formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'emergencyContactCountryCode', 'country'),
          getCountryStates,
        ],
        ({ emergencyContactCountryCode, country }, countryStates) => ({
          emergencyStates: mapStatesToOptions(countryStates(emergencyContactCountryCode) || []),
          states: mapStatesToOptions(countryStates(country) || []),
        })
      )
    ),
    getPassportVisaModalData: new Selector((selectors) => {
      const { getCustomizableSections } = selectors;
      return createSelector(
        [getLabels, getModalData, getCustomizableSections, getBookingDetails, getCountryCodeFromCurrency],
        (labels, { id: modalId }, content, bookingDetails) => {
          const defaultValues = {
            title: '',
            subtitle: '',
            callToActionTitle: '',
            callToActionUrl: '',
            longText: '',
            button: {},
          };
          if (!modalId) {
            return defaultValues;
          }

          const { buttons: { print } = {} } = labels;
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const cards = get(content?.sections.find(findSection), 'cards', null);
          const findByReference = (list, reference) =>
            list.find((item) => (item.reference ? item.reference.match(reference) : null));

          if (!cards) {
            return defaultValues;
          }

          const {
            modal: { title, subtitle, sections },
          } = cards.find(findCard);

          const russianLabel = findByReference(sections, /^documentsVisasRussiaLabel/g);
          const chinaLabel = findByReference(sections, /^documentsVisasChinaLabel/g);
          const southEastAsiaLabel = findByReference(sections, /^documentsVisasSoutheastAsiaLabel/g);
          const europeanLabel = findByReference(sections, /^documentsVisasEuropeLabel/g);
          const visaGenServiceButton = findByReference(sections, /^documentsVisasGenVisaServiceButton/g);
          const visaInviteLetterLabel = findByReference(sections, /^documentsVisasInviteLetterLabel/g);
          const passPortApplication = findByReference(sections, /^documentsPassportApplicationButton/g);
          const countryVoyageData = {
            China: chinaLabel,
            Russia: russianLabel,
            Vietnam: southEastAsiaLabel,
          };
          const { itinerary } = bookingDetails;
          const { journeyDays } = itinerary;

          // Create a new version of the cta with the extra buttons and onConfirm keys
          const mapCTAButton = (object) => ({
            ...object,
            buttons: [{ id: 'confirm', text: object.callToActionTitle }],
            onConfirm: () => window.open(object.callToActionUrl, '_blank'),
          });
          const mappedVisaGenServiceButton = visaGenServiceButton && mapCTAButton(visaGenServiceButton);
          const mappedVisaInviteLetterLabel = visaInviteLetterLabel && mapCTAButton(visaInviteLetterLabel);

          // Default visaModalData
          let visaModalData = {
            ...europeanLabel,
            callToActionGroup: visaGenServiceButton ? [mappedVisaGenServiceButton] : [],
          };

          /*
            Custom messaging for Russia, Southeast Asia and China
            Find the cruise location and determine the reference to use
            and map custom buttons with onConfirm functions
          */
          journeyDays.forEach((day) => {
            const { countryName } = day;
            const approvedCCallToAction =
              countryName === 'Vietnam'
                ? [mappedVisaGenServiceButton]
                : [mappedVisaInviteLetterLabel, mappedVisaGenServiceButton];

            if (countryVoyageData[countryName]) {
              visaModalData = {
                ...countryVoyageData[countryName],
                callToActionGroup: visaGenServiceButton ? approvedCCallToAction : [],
              };
            }
          });

          const sectionData = passPortApplication || visaModalData;

          const { callToActionUrl, callToActionTitle, longText, callToActionGroup = null } = sectionData;

          return {
            buttons: [{ id: 'confirm', text: callToActionTitle }],
            message: subtitle,
            title,
            copy: longText,
            printLabel: print,
            callToActionGroup,
            onConfirm: () => window.open(callToActionUrl, '_blank'),
          };
        }
      );
    }),
    getInformationModalData: new Selector((selectors) => {
      const { getCustomizableSections } = selectors;
      return createSelector([getLabels, getModalData, getCustomizableSections], (labels, { id: modalId }, content) => {
        const defaultValues = {
          longDescription: '',
          title: '',
          sections: [],
          printLabel: '',
        };
        if (!modalId) {
          return defaultValues;
        }

        const { buttons: { print } = {} } = labels;
        const findCard = (element) => element.id === modalId;
        const findSection = (section) => section.cards.find(findCard);
        const cards = get(content?.sections.find(findSection), 'cards', null);

        if (!cards) {
          return defaultValues;
        }

        const {
          modal: { title, subtitle, sections },
        } = cards.find(findCard);

        return {
          longDescription: subtitle,
          title,
          sections,
          printLabel: print,
        };
      });
    }),
    getComfortCheckInPassenger: (state) => get(state, 'documents.comfortCheckIn.passengerNumber', null),
    getComfortCheckInStep: (state) => get(state, 'documents.comfortCheckIn.stepNumber', 0),
    getComfortCheckInStatus: (state) => get(state, 'documents.comfortCheckIn.checkInStatus', []),
    getSurveyCheckInStatus: new Selector(() =>
      createSelector([getBookingDetails], ({ passengers }) =>
        passengers.map(({ surveys = [] }) =>
          surveys.reduce((acc, survey) => {
            acc[survey] = true;
            return acc;
          }, {})
        )
      )
    ),
    getHealthSurveyPassengerNumber: (state) => get(state, 'documents.healthSurvey.passengerNumber', null),
    getComfortCheckInStatusLoading: (state) => get(state, 'documents.comfortCheckIn.checkInStatus.loading'),
    getCheckInSubmitting: (state) => get(state, 'documents.comfortCheckIn.submitting', false),
    getComfortCheckInSubmitFailed: (state) => get(state, 'documents.comfortCheckIn.submitFailed', false),
    getCheckInComboError: (state) => get(state, 'documents.comfortCheckIn.comboError', {}),
    getCheckInFailed: (state) => get(state, 'documents.comfortCheckIn.submitFailed', false),
    getComfortCheckingTabContent: new Selector(
      ({ getCardContent, getTabContent, getComfortCheckInStatus, getGifPassengerSelectorData }) =>
        createSelector(
          [
            (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, TAB_NAMES.COMFORT_CHECK_IN),
            (state) => getTabContent(state)(TAB_NAMES.COMFORT_CHECK_IN),
            getComfortCheckInStatus,
            getFeatureRestricted,
            getGifPassengerSelectorData,
            (state) => getCardContent(state)('comfortCheckInCard'),
          ],
          (
            {
              buttons: { checkInNow, printBoardingPass, send, guestInfoForm },
              labels: {
                days,
                hours,
                minutes,
                seconds,
                checkedIn,
                or,
                emailBoardingPass,
                emailAddress,
                unableToCompleteOne,
                unableToCompleteAny,
                customerService,
                toCompleteCheckIn,
                emailSent,
                selectPassenger,
                checkInCompleteGifPre,
                checkInCompleteGifPost,
              },
            },
            content,
            guestCheckInStatuses,
            featureRestricted,
            gifPassengerSelectorData,
            cardContent
          ) => {
            if (!content?.loaded) {
              return {
                title: cardContent?.title,
              };
            }
            const { subtitle, pageDescription, loaded } = content;
            const messages = content?.sections?.[0]?.items || [];
            let checkInStatusError = '';
            if (get(guestCheckInStatuses, 'mxpError', null)) {
              checkInStatusError = getCmsLabel(messages, 'mxpUnavailable', 'title');
            }
            const disabledMessage = null;

            return {
              title: cardContent?.title || content?.title,
              checkInAvailable: getCmsLabel(messages, 'checkInAvailableCopy', 'title'),
              selectPassenger,
              checkInNow,
              checkedInData: {
                title: checkedIn,
                body: getCmsLabel(messages, 'checkInAlertTxt', 'title'),
                or,
                emailBoardingPass,
                send,
                emailAddress,
                printBoardingPass,
              },
              passengers: gifPassengerSelectorData.map(({ name, isCompleted }, index) => ({
                name,
                passengerNumber: index + 1,
                checkedIn: get(guestCheckInStatuses, `[${index}].checkedIn`, false),
                guestId: get(guestCheckInStatuses, `[${index}].MXPGuestId`, null),
                gifCompleted: isCompleted,
              })),
              subtitle,
              pageDescription,
              days,
              hours,
              minutes,
              seconds,
              loaded,
              unableToCompleteOne,
              unableToCompleteAny,
              customerService,
              emailSent,
              toCompleteCheckIn,
              checkInStatusError,
              disabledMessage,
              isViewOnly: featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY,
              cciClosedMessage: getCmsLabel(messages, 'checkInClosed', 'title'),
              cciClosedDueToGifLockdownMessage: getCmsLabel(messages, 'checkInClosedDueToGifLockdown', 'title'),
              incompleteGifError: {
                alertText: getCmsLabel(messages, 'checkInCompleteGifPre', 'subtitle') || checkInCompleteGifPre,
                buttonText: guestInfoForm,
                alertEndText: getCmsLabel(messages, 'checkInCompleteGifPost', 'subtitle') || checkInCompleteGifPost,
              },
            };
          }
        )
    ),
    getCardContent: new Selector(({ getCustomizableSections }) =>
      createSelector([getCustomizableSections], (content) =>
        memoize((reference) => {
          const { sections } = content;
          const targetCard = sections[0]?.cards.find((card) => {
            return card?.reference === reference;
          });

          return targetCard || {};
        })
      )
    ),
    getHealthSurveyTabContent: new Selector(({ getCardContent, getTabContent, getSurveyCheckInStatus }) =>
      createSelector(
        [
          getBookingDetails,
          (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, HEALTH_SURVEY),
          (state) => getTabContent(state)(HEALTH_SURVEY),
          getPassengerNames,
          getSurveyCheckInStatus,
          getUserType,
          (state) => getFlagValue(state)(HEALTH_QUESTIONNAIRE_ID),
          (state) => getFlagValue(state)(HEALTH_SURVEY_OPEN_DATE),
          (state) => getCardContent(state)('healthSurveyCard'),
        ],
        (
          { bookingNumber },
          { labels: { selectPassenger }, buttons: { startSurvey } },
          content,
          passengerNames,
          guestCheckInStatuses,
          userType,
          healthSurveyId,
          healthSurveyOpenDate,
          cardContent
        ) => {
          if (!content?.loaded) {
            return {
              title: cardContent?.title,
            };
          }
          const messages = content?.sections?.[0]?.items || [];
          let disableTACSAMessage;
          if (userType === USER_TYPES.CSA || userType === USER_TYPES.TA) {
            disableTACSAMessage = getCmsLabel(messages, 'guestInformationTAHealthSurvey', 'title');
          }
          const { title, subtitle, loaded } = content;

          return {
            title,
            subtitle,
            banner: replaceCMSTokenWithValue(getCmsLabel(messages, 'guestInformationHealthSurveyBanner', 'title'), [
              { key: 'HEALTH_SURVEY', value: healthSurveyOpenDate },
            ]),
            description: replaceCMSTokenWithValue(
              getCmsLabel(messages, 'guestInformationHealthSurveyBodyCopy', 'title'),
              [{ key: 'HEALTH_SURVEY', value: healthSurveyOpenDate }]
            ),
            checkInAvailable: getCmsLabel(messages, 'guestInformationHealthSurveyCompleteSurvey', 'title'),
            selectPassenger,
            startSurvey,
            checkedInData: {
              title: getCmsLabel(messages, 'guestInformationCompleted', 'title'),
              body: getCmsLabel(messages, 'guestInformationHealthSurveyThankYou', 'title'),
            },
            passengers: passengerNames.map((name, index) => ({
              name,
              passengerNumber: index + 1,
              checkedIn: get(guestCheckInStatuses, `[${index}].${healthSurveyId}`, false),
              guestId: `${bookingNumber}${index + 1}`,
            })),
            disableTACSAMessage,
            surveyClosedMessage: getCmsLabel(messages, 'guestInformationHealthSurveyClosed', 'title'),
            loaded,
          };
        }
      )
    ),
    getFitToTravelPageContent: new Selector(({ getCardContent, getTabContent, getSurveyCheckInStatus }) =>
      createSelector(
        [
          (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, FIT_TO_TRAVEL),
          (state) => getTabContent(state)(FIT_TO_TRAVEL),
          getPassengerNames,
          getSurveyCheckInStatus,
          (state) => getCardContent(state)('fitToTravelCard'),
        ],
        (
          { labels: { selectPassenger, complete }, buttons: { submitSurvey } },
          content,
          passengerNames,
          checkInStatuses,
          cardContent
        ) => {
          const { title, subtitle, loading, loaded, sections } = content;
          const messages = sections?.[0]?.items || [];
          if (!title || !loaded) {
            return {
              title: cardContent?.title,
              isLoading: loading || !loaded,
            };
          }

          return {
            title,
            subtitle,
            loaded,
            isLoading: loading || !loaded,
            selectPassenger,
            passengers: passengerNames.map((pax, index) => ({
              name: pax,
              passengerNumber: index + 1,
              checkedIn: checkInStatuses?.[index]?.EXPFIT,
            })),
            checkedInData: {
              title: complete,
              body: getCmsLabel(messages, 'fitToTravelSurveyThankYou', 'title'),
            },
            submitSurvey,
            surveyUrl: getCmsLabel(messages, 'fitToTravelSurveyUrl', 'title'),
            disabledMessage: getCmsLabel(messages, 'surveyClosed', 'title'),
          };
        }
      )
    ),
    getActivitiesPageContent: new Selector(({ getCardContent, getTabContent, getQualtricsSurveyUrl }) =>
      createSelector(
        [
          (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, ACTIVITIES_OF_INTEREST),
          (state) => getTabContent(state)(ACTIVITIES_OF_INTEREST),
          getPassengerNames,
          getQualtricsSurveyUrl,
          (state) => getCardContent(state)('activitiesOfInterestCard'),
        ],
        ({ labels: { selectPassenger } }, content, passengerNames, getSurveyUrl, cardContent) => {
          const { title, subtitle, loading, loaded, sections } = content;
          const messages = sections?.[0]?.items || [];
          if (!title || !loaded) {
            return {
              title: cardContent?.title,
              isLoading: loading || !loaded,
            };
          }

          return {
            title,
            subtitle,
            loaded,
            isLoading: loading || !loaded,
            selectPassenger,
            passengers: passengerNames.map((pax, index) => ({
              name: pax,
              passengerNumber: index + 1,
              surveyUrl: getSurveyUrl(index + 1),
            })),
            surveyClosedMessage: getCmsLabel(messages, 'surveyWindowClosed', 'title'),
            selectPassengerMessage: getCmsLabel(messages, 'passengerSelectMessage', 'title'),
          };
        }
      )
    ),
    getQualtricsSurveyUrl: new Selector(() =>
      createSelector(
        [getBookingDetails, getLoggedInUser, getCountryCodeFromCurrency],
        ({ bookingNumber, voyage: { id: voyageId } = {}, passengers }, { email }, country) =>
          memoize((passengerNumber) => {
            const pax = passengers.find((pax) => pax.passengerNumber === passengerNumber);
            const completed = pax?.surveys?.includes(EVO_ACTIVITIES_SURVEY_CODE);
            return buildUrl(
              ACTIVITIES_SURVEY_VARIABLES.IFRAME_URL,
              ['surveyCode'],
              { surveyCode: ACTIVITIES_SURVEY_VARIABLES.SURVEY_CODE },
              {
                booking_number: bookingNumber,
                bkg_guest_id: `${bookingNumber}${passengerNumber}`,
                country,
                Sailing_ID: voyageId,
                guest_id: pax?.airPassengerId,
                guest_first_name: pax?.firstName,
                guest_last_name: pax?.lastName,
                guest_email: email,
                cch_contact_id: pax?.cchid,
                survey_taken: completed,
              }
            );
          })
      )
    ),
    getPassengerPhoto: new Selector(({ getStoreState, getComfortCheckInPassenger }) =>
      createSelector([getStoreState, getComfortCheckInPassenger], (storeState, passengerNumber) => {
        const passengerPhotos = get(storeState, 'comfortCheckIn.passengerPhotos', []);
        const passengerPhoto = passengerPhotos.find((item) => item.passengerNumber === passengerNumber);
        if (passengerPhoto) {
          const { photo } = passengerPhoto;
          return photo;
        }
        return '';
      })
    ),
    getPassengerPhotoValidation: new Selector(({ getStoreState, getComfortCheckInPassenger }) =>
      createSelector([getStoreState, getComfortCheckInPassenger], (storeState, passengerNumber) => {
        const passengerPhotos = get(storeState, 'comfortCheckIn.passengerPhotos', []);
        const passengerPhoto = passengerPhotos.find((item) => item.passengerNumber === passengerNumber);
        if (passengerPhoto) {
          const { photoValid } = passengerPhoto;
          return photoValid;
        }
        return false;
      })
    ),
    getComfortCheckInFormSteps: new Selector(
      ({
        getComfortCheckInPassenger,
        getGifPassenger,
        getCheckInPageContent,
        getCountryName,
        getPassengerPhoto,
        getPassengerPhotoValidation,
      }) =>
        createSelector(
          [
            getComfortCheckInPassenger,
            getGifPassenger,
            getCheckInPageContent,
            (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, TAB_NAMES.COMFORT_CHECK_IN),
            getCountryName,
            getBookingDetails,
            getModalData,
            getSubmitting,
            (state) => getPassengerPhoto(state),
            getOnboardCreditCardSection,
            getCountryCodeFromCurrency,
            getVoyageType,
            getErrors,
            getIsUKAUNZ,
            (state) => state?.documents?.comfortCheckIn?.passengerPhotoConverted,
            getIsMississippi,
            getHasOnboardCreditCard,
            getIsCloseToDepartureDate,
            getFeatureRestricted,
            getDaysToGo,
            (state) => getPassengerPhotoValidation(state),
          ],
          (
            passengerNumber,
            getGifPassengerData,
            checkInPageContent,
            { buttons, labels, title: formTitle },
            getCountryFromCode,
            bookingDetails,
            id,
            submitting,
            passengerPhoto,
            onboardCreditCardSection,
            countryCode,
            voyageType,
            errors,
            isUKAUNZ,
            passengerPhotoConverted,
            isMississippi,
            hasOnboardCC,
            isCloseToDeparture,
            featureRestricted,
            daysToGo,
            passengerPhotoValidation
          ) => {
            const formatDate = (date, shortDates) => {
              const longFormat = `${shortDates}, Y`;
              if (isUKAUNZ) {
                return date ? moment(date, DEFAULT_DATE_FORMAT.EU).format(longFormat) : 'N/A';
              }

              return date ? moment(date, DEFAULT_DATE_FORMAT.NA).format(longFormat) : 'N/A';
            };
            const gifData = getGifPassengerData(passengerNumber);
            if (!gifData || !checkInPageContent) {
              return null;
            }
            const { journeyDays = {} } = get(bookingDetails, 'itinerary', {});

            const messages = get(checkInPageContent, 'sections[0].items', []);

            const countryFields = ['countryOfBirth', 'nationality', 'passportIssueCountry'];
            const dateFields = ['passportIssueDate', 'passportExpirationDate', 'expiresOnLicense'];

            const steps = [COMFORT_CHECK_IN_STEPS.PERSONAL_INFO, COMFORT_CHECK_IN_STEPS.CRUISE_INFO];

            const formatTitle = (title) => title?.replace(':', '');
            const sectionKey = {
              [COMFORT_CHECK_IN_STEPS.PERSONAL_INFO]: {
                personalInfo: {
                  title: labels.personalInfo,
                  content: {
                    firstName: 'first',
                    lastName: 'last',
                    gender: 'gender',
                    countryOfBirth: 'birthPlace',
                    nationality: 'nationality',
                  },
                },
                passportInfo: {
                  title: labels.passportInfo,
                  subtitle:
                    featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING
                      ? {}
                      : {
                          text: getCmsLabel(messages, 'editPassportInfo', 'subtitle'),
                          buttonText: buttons.guestInfoForm,
                          endText: '.',
                        },
                  content: {
                    passportNumber: 'passportNumber',
                    passportIssueDate: 'passportIssuedDate',
                    passportIssueCountry: 'passportIssuer',
                    passportExpirationDate: 'passportExpiresDate',
                  },
                },
                licenseInfo: {
                  title: labels.licenseInfo,
                  content: {
                    licenseNumber: 'licenseNumber',
                    stateLicense: 'stateLicense',
                    expiresOnLicense: 'expiresOnLicense',
                  },
                },
                photo: {
                  title: labels.photo,
                  card: {
                    id: '1',
                    description: getCmsLabel(messages, 'photoInfo', 'title'),
                    primaryButton: {
                      text: buttons.uploadPhoto,
                    },
                    guidelineButton: {
                      alertButtonText: getCmsLabel(messages, 'photoGuidelines', 'title'),
                      onClick: () => {
                        return {};
                      },
                    },
                    loading: false,
                    photoUrl: passengerPhoto,
                  },
                },
              },
              [COMFORT_CHECK_IN_STEPS.CRUISE_INFO]: {
                cruiseInfo: {
                  title: labels.cruiseInfo,
                  content: {
                    ship: 'shipName',
                    itineraryName: 'cruise.title',
                    bookingNumber: 'bookingNumber',
                    cruiseDate: 'cruiseDatesValue',
                    startPort: 'itinerary.journeyDays[0].description',
                    endPort: `itinerary.journeyDays[${journeyDays.length - 1}].description`,
                  },
                },
              },
            };

            if (
              !isMississippi ||
              ![COUNTRIES.UNITED_STATES].includes(countryCode) ||
              ![COUNTRIES_NATIONALITY.UNITED_STATES].includes(gifData?.nationality)
            ) {
              delete sectionKey[COMFORT_CHECK_IN_STEPS.PERSONAL_INFO].licenseInfo;
            }

            const { startDate, endDate } = get(bookingDetails, 'voyage', {});
            const cruiseDateformat = isUKAUNZ ? REGIONAL_LONG_DATES.EU : REGIONAL_LONG_DATES.NA;
            const cruiseDatesValue = replaceCMSTokenWithValue(labels.cruiseDateMessage, [
              { key: 'startDate', value: moment(startDate).format(cruiseDateformat) },
              { key: 'endDate', value: moment(endDate).format(cruiseDateformat) },
            ]);

            const contentData = {
              [COMFORT_CHECK_IN_STEPS.PERSONAL_INFO]: gifData,
              [COMFORT_CHECK_IN_STEPS.CRUISE_INFO]: {
                ...bookingDetails,
                cruiseDatesValue,
                shipName: convertStringToStartCase(get(bookingDetails, 'ship.shipName', '')),
              },
            };

            const mapContent = (sectionContent, data) => {
              if (!sectionContent) {
                return null;
              }
              const fields = Object.entries(sectionContent).map(([labelName, propertyName]) => {
                let value = get(data, propertyName);
                if (countryFields.includes(labelName)) {
                  value = getCountryFromCode(value);
                }
                if (dateFields.includes(labelName)) {
                  value = formatDate(value, isUKAUNZ ? REGIONAL_SHORT_DATES.EU : REGIONAL_SHORT_DATES.NA);
                }
                return {
                  key: labels[labelName],
                  value: value || 'N/A',
                };
              });
              return fields;
            };

            const buildButtons = () => {
              const primaryButton = {
                text: buttons.next,
              };
              const secondaryButton = {
                text: buttons.previous,
              };
              return {
                primaryButton,
                secondaryButton,
              };
            };

            const buildSection = (stepName) => {
              const sections = [];
              const sectionKeys = Object.keys(sectionKey[stepName]);
              sectionKeys.forEach((key) => {
                const { title, subtitle, content: sectionContent, card } = sectionKey[stepName][key];
                const section = {};
                section.title = title;
                section.subtitle = subtitle;
                section.card = card;
                section.content = mapContent(sectionContent, contentData[stepName]);
                sections.push(section);
              });
              return sections;
            };

            const getStepError = (stepname) => {
              let photoMessage = '';
              if (stepname === COMFORT_CHECK_IN_STEPS.PERSONAL_INFO) {
                if (passengerPhoto === '') {
                  photoMessage = get(errors, 'PhotoRequired', '');
                } else if (!passengerPhotoValidation) {
                  photoMessage = get(errors, 'PhotoRequirementsRequired', '');
                }
              }
              return photoMessage;
            };

            const formSteps = steps.map((stepName, index) => ({
              id: index + 1,
              stepName,
              stepTitle: formatTitle(labels[stepName]),
              ...buildButtons(index),
              sections: buildSection(stepName),
              errorMessage: getStepError(stepName),
            }));
            const acceptableImageSet = getCmsLabel(messages, 'acceptablePhotoTitle', 'images[0]');
            const unAcceptableImageSet = getCmsLabel(messages, 'notAcceptablePhotoTitle', 'images[0]');
            const isCloseToDepartureOnboardCC = daysToGo < DAYS_TO_GO.ONBOARD_CREDIT_CARD;
            if (hasOnboardCC && !isCloseToDepartureOnboardCC) {
              formSteps.push({
                id: formSteps.length + 1,
                stepName: COMFORT_CHECK_IN_STEPS.ONBOARD_CC_INFO,
                stepTitle: labels.onboardCreditCard,
                stepSubtitle: labels.onboardCreditCard,
                stepDescription: getCmsLabel(messages, 'onboardPurchasesCc', 'subtitle'),
                ...buildButtons(formSteps.length),
                onboardCreditCardSection,
              });
            }
            return {
              formTitle,
              formSteps,
              onCompleteText: buttons.completeCheckIn,
              removalModalBody: {
                entityId: id,
                message: getCmsLabel(messages, 'removeCc', 'subtitle'),
                buttons: [
                  { id: 'cancel', text: buttons.noRemoveCard },
                  { id: 'confirm', text: buttons.yesRemoveCard },
                ],
                submitting,
              },
              modalData: {
                entityId: id,
                modalTitle: labels.photoGuideline,
                photoValid: passengerPhotoValidation,
                samplePhotoContent: [
                  {
                    title: labels.acceptable,
                    url: acceptableImageSet && acceptableImageSet.url,
                    content: getCmsLabel(messages, 'acceptablePhotoTitle', 'subtitle'),
                  },
                  {
                    title: labels.notAcceptable,
                    url: unAcceptableImageSet && unAcceptableImageSet.url,
                    content: getCmsLabel(messages, 'notAcceptablePhoto', 'subtitle'),
                  },
                ],
                uploadTitle: labels.uploadPhoto,
                uploadMessage1: getCmsLabel(messages, 'photoMinimumSize', 'title'),
                uploadMessage2: getCmsLabel(messages, 'photoMaximumSize', 'title'),
                cropTitle: labels.cropPhoto,
                cropMessage: getCmsLabel(messages, 'photoMinimumSize', 'title'),
                successTitle: labels.uploadSuccess,
                successMessage: labels.uploadSuccessMessage,
                unsuccessfulTitle: labels.uploadUnsuccessful,
                unsuccessfulMessage: labels.uploadUnsuccessfulMessage,
                submitting,
                imageDimensionsError: get(errors, 'imageDimensionsError', ''),
                imageFormatError: get(errors, 'imageFormatError', ''),
                imageSizeError: get(errors, 'imageSizeError', ''),
                imageUnknownError: get(errors, 'imageUnknownError', ''),
              },
              buttons,
              passengerPhotoConverted,
            };
          }
        )
    ),
    getTravelDocumentsModalData: new Selector(({ getTabContentSections }) =>
      createSelector(
        [
          getLabels,
          getDocumentation,
          getTrackingTools,
          getBookingDetails,
          getModalData,
          getCountryCodeFromCurrency,
          (state) => getTabContentSections(state)(TRAVEL_DOCUMENTS),
          getIsUKAUNZ,
        ],
        (
          { buttons: { print } = {} },
          documentation,
          trackingLinks,
          { voyage: { startDate } },
          { id: modalId },
          country,
          travelDocuments,
          isUKAUNZ
        ) => {
          const defaultValues = {
            title: '',
          };
          if (!modalId) {
            return defaultValues;
          }
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const cards = get(travelDocuments.find(findSection), 'cards', null);

          if (!cards) {
            return defaultValues;
          }

          const { title, modal = {} } = cards.find(findCard);
          const { shippedOn, trackingInformationList, deliveryMethod } = documentation;
          let trackingInfo;
          let heading;
          let subheading;
          let shippedBy;
          if ([COUNTRIES.UNITED_STATES, COUNTRIES.CANADA].includes(country) && shippedOn && trackingInformationList) {
            trackingInfo = trackingInformationList.map((info) => ({
              ...info,
              deliveryMethod,
              trackingUrl: replaceCMSTokenWithValue(trackingLinks[deliveryMethod], [
                { key: 'TRACKING_NUMBER', value: info.trackingNumber },
              ]),
            }));
            heading = getCmsLabel(modal.sections, 'documentsTravelDocsShippedLabel');
            subheading = getCmsLabel(modal.sections, 'documentsTravelDocsShippedLabel', 'subtitle');
          } else {
            shippedBy = moment(startDate)
              .subtract(21, 'days')
              .format(isUKAUNZ ? 'DD MMMM, YYYY' : 'MMMM DD, YYYY')
              .replace(/\s/g, '\u00A0');
            heading = replaceCMSTokenWithValue(getCmsLabel(modal.sections, 'documentsTravelDocsIssuedDateLabel'), [
              { key: 'DOCS_ISSUED_DATE', value: shippedBy },
            ]);
            subheading = getCmsLabel(modal.sections, 'documentsTravelDocsIssuedDateLabel', 'subtitle');
          }
          return {
            title,
            labels: {
              trackShipment: getCmsLabel(modal.sections, 'documentsTrackShipmentButton', 'callToActionTitle'),
              trackingNumber: getCmsLabel(modal.sections, 'documentsTrackingNumberLabel'),
              deliveryMethod: getCmsLabel(modal.sections, 'documentsDeliveryMethodLabel'),
            },
            heading,
            subheading,
            shippedOn,
            trackingInfo,
            printLabel: print,
          };
        }
      )
    ),
    getDisplayFitToTravel: new Selector(() =>
      createSelector(
        [getBookingDetails, (state) => getFlagValue(state)(FIT_TO_TRAVEL_FLAG)],
        ({ fitToTravelRequired }, fitToTravelFlag) => {
          return fitToTravelFlag && fitToTravelRequired;
        }
      )
    ),
    getDisplayActivitiesOfInterest: new Selector(() =>
      createSelector(
        [getBookingDetails, (state) => getFlagValue(state)(ACTIVITIES_OF_INTEREST_FLAG)],
        ({ marketCode }, activitiesOfInterestFlag) => {
          return activitiesOfInterestFlag && ACTIVITIES_SURVEY_VARIABLES.MARKET_CODES.includes(marketCode);
        }
      )
    ),
    getCustomizableSections: new Selector(({ getPageContent, getDisplayFitToTravel, getDisplayActivitiesOfInterest }) =>
      createSelector(
        [
          getPageContent,
          getDisplayComfortCheckIn,
          (state) => getFlagValue(state)(BND_HEALTH_SURVEY),
          (state) => getPageTabLabels(state)(),
          getDisplayFitToTravel,
          getDisplayActivitiesOfInterest,
          getPreviousPage,
        ],
        (
          content,
          displayComfortCheckIn,
          displayHealthSurvey,
          { buttons },
          displayFitToTravel,
          displayActivitiesOfInterest,
          previousPage
        ) => {
          const cardToRemove = [];

          const { sections, ...newContent } = content;
          newContent.backButton = {
            label: buttons.back,
            previousPagePath: window.location.href.includes(APP_PATHS.COMFORT_CHECK_IN)
              ? APP_PATHS.BEFORE_YOU_GO
              : previousPage?.from || APP_PATHS.DOCUMENTS,
          };
          newContent.sections = [];
          if (!displayComfortCheckIn) {
            cardToRemove.push('comfortCheckInCard');
          }
          if (!displayHealthSurvey) {
            cardToRemove.push('healthSurveyCard');
          }
          if (!displayFitToTravel) {
            cardToRemove.push('fitToTravelCard');
          }
          if (!displayActivitiesOfInterest) {
            cardToRemove.push('activitiesOfInterestCard');
          }

          if (newContent.loaded) {
            const messages = get(content, 'sections.0.messages', []);
            const sectionCards = get(content, 'sections.0.cards', []);
            newContent.sections.push({ cards: [], messages });
            sectionCards?.forEach((card) => {
              let hideCard = false;
              hideCard = cardToRemove.findIndex((r) => card?.reference?.includes(r)) !== -1;
              if (!hideCard) {
                newContent.sections[0].cards.push(card);
              }
            });
          }

          return newContent;
        }
      )
    ),
    getBoardingPassData: new Selector(({ getGifPassenger, getTabContent }) =>
      createSelector(
        [
          getBookingDetails,
          getGifPassenger,
          (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, TAB_NAMES.COMFORT_CHECK_IN),
          (state, passengerNumber) => passengerNumber,
          (state) => getTabContent(state)(TAB_NAMES.COMFORT_CHECK_IN),
          getIsUKAUNZ,
          getCountryCodeFromCurrency,
          getIsMississippi,
        ],
        (
          {
            bookingNumber,
            ship: { shipName, stateroomNumber },
            cruise: { name: cruiseName },
            itinerary: { journeyDays },
          },
          getGifPassengerData,
          { labels },
          passengerNumber,
          checkInPageContent,
          isUKAUNZ,
          countryCode,
          isMississippi
        ) => {
          if (!passengerNumber) {
            return null;
          }

          const start = journeyDays[0];
          const end = journeyDays[journeyDays.length - 1];
          const dateFormat = isUKAUNZ ? REGIONAL_LONG_DATES.EU : DEFAULT_DATE_FORMAT.NA_SLASH;
          const embarkDate = moment(start.date).format(dateFormat);
          const disembarkDate = moment(end.date).format(dateFormat);
          const gifData = getGifPassengerData(passengerNumber);
          const messages = checkInPageContent?.sections?.[0]?.items || [];

          const cruiseLabelMap = {
            firstName: 'first',
            lastName: 'last',
            bookingNumber: 'bookingNumber',
            passportNumber: 'passportNumber',
            licenseNumber: 'licenseNumber',
            ship: 'shipName',
            embarking: 'embarkDate',
            disembarking: 'disembarkDate',
            stateroomNumber: 'stateroomNumber',
          };

          const cruiseData = {
            ...gifData,
            embarkDate,
            disembarkDate,
            shipName,
            stateroomNumber,
            cruiseName,
            bookingNumber,
          };

          if (!isMississippi || ![COUNTRIES.UNITED_STATES].includes(countryCode)) {
            delete cruiseLabelMap.licenseNumber;
          }

          return {
            route: {
              label: 'Route',
              value: `${start.cityName}, ${start.countryName} (${journeyDays[0].portCode}) -> ${end.cityName}, ${
                end.countryName
              } (${journeyDays[journeyDays.length - 1].portCode})`,
            },
            logoUrl: get(
              messages.find((item) => item.reference === 'comfortCheckInLogo'),
              'images[0].url'
            ),
            sections: [
              {
                pre: `${start.cityName} (${start.countryName})`,
                post: `${end.cityName} (${end.countryName})`,
                iconName: 'cruise-ship',
                content: Object.keys(cruiseLabelMap).map((key) => ({
                  label: labels[key] ? labels[key].replace(':', '').toUpperCase() : labels[key],
                  value: cruiseData[cruiseLabelMap[key]] ? cruiseData[cruiseLabelMap[key]] : 'N/A',
                })),
              },
            ],
            postText: getCmsLabel(messages, 'boardingPassPostText', 'title'),
            journey: cruiseData?.cruiseName,
          };
        }
      )
    ),
    getQuestionnaireContent: (state) => get(state, 'documents.healthSurvey.questionContent', null),
    getQuestionnaireLoaded: (state) => get(state, 'documents.healthSurvey.questionContent.loaded'),
    getHealthQuestionnaireContent: new Selector(({ getQuestionnaireContent, getTabContent }) =>
      createSelector(
        [
          getQuestionnaireContent,
          (state) => getTabContent(state)(HEALTH_SURVEY),
          (state) => getPageTabLabels(state)(PAGE_NAMES.GUEST_INFORMATION, HEALTH_SURVEY),
        ],
        (
          questionsContent,
          tabContent,
          { labels: { yes, no, question: questionLabel, confirmation }, buttons: { next, previous, submit } }
        ) => {
          const questions = get(questionsContent, 'questions', null);
          if (!tabContent) {
            return null;
          }

          const { title } = tabContent;
          if (!questions) {
            return {
              title,
            };
          }

          const messages = tabContent?.sections?.[0]?.items || [];
          const mappedQuestions = questions.reduce((acc, question, index) => {
            const { type, code, attributes, name, grouping } = question;
            if (HEALTH_SURVEY_QUESTION_TYPES[question?.type]) {
              acc.push({
                questionNumber: index + 1,
                type,
                code,
                attributes,
                title: `${questionLabel} ${index + 1}`,
                body: name,
                grouping,
              });
            }
            return acc;
          }, []);

          return {
            questions: mappedQuestions,
            guestAgreement: {
              text: getCmsLabel(messages, 'healthSurveyUserAgreement', 'title'),
            },
            title,
            labels: {
              yes,
              no,
              question: questionLabel,
              confirmation,
              next,
              previous,
              submit,
            },
          };
        }
      )
    ),
  },
});

const {
  creators: {
    receiveContent,
    receiveGifData,
    receiveTabContent,
    receiveCheckInPageContent,
    receiveComfortCheckInStatus,
    receiveHealthSurvey,
    setComfortCheckInFetchFailed,
    updateConvertedPhoto,
    updateNextLocation,
    updateSelectedGuest,
  },
  selectors: {
    getGifPassengers,
    getBoardingPassData,
    getComfortCheckInPassenger,
    getPassengerPhoto,
    getPassengerPhotoValidation,
    getHealthSurveyPassengerNumber,
    getQuestionnaireContent,
    getNextLocation,
  },
} = documentsStore;

export const fetchDocumentsPageContent = () => (dispatch, getState) => {
  const state = getState();
  let country = getCountryCodeFromCurrency(state);
  // convert GB to UK for CMS country name
  country = country === 'GB' ? 'UK' : country;
  const bookingDetails = getBookingDetails(state);
  const encodedCruiseName = encodeURIComponent(bookingDetails?.cruise?.name) || '';
  const queryParams = { country, itinerary: encodedCruiseName };
  const url = buildUrl('/documentsCards', ['office', 'voyage.type'], bookingDetails, queryParams);
  dispatch(
    getData({
      url,
      store: documentsStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchTabContent = (tab) => (dispatch, getState) => {
  const state = getState();
  let country = getCountryCodeFromCurrency(state);

  country = country === COUNTRIES.UNITED_KINGDOM ? 'UK' : country;

  const url = buildUrl(`/pages/${tab}`, ['voyage.type'], getBookingDetails(getState()), {
    country,
  });

  return dispatch(
    getData({
      url,
      store: documentsStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      tab,
    })
  );
};

export const resumeCciIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const cciPassenger = getComfortCheckInPassenger(state);
  if (cciPassenger !== null) {
    navigateTo(APP_PATHS.COMFORT_CHECK_IN_FORM);
  }
};

export const gotoNextLocationIfNeeded = () => (dispatch, getState) => {
  const nextLocation = getNextLocation(getState());
  if (nextLocation) {
    navigateTo(nextLocation);
    dispatch(updateNextLocation(null));
  }
};

export const fetchGifContent = () => (dispatch, getState) => {
  const state = getState();

  const bookingDetails = getBookingDetails(state);
  const { lastName } = get(bookingDetails, 'passengers[0]', {});

  const url = buildUrl(
    '/gif',
    ['office', 'currency', 'voyage.id', 'bookingNumber', 'ship.stateroomNumber', 'lastName'],
    {
      ...bookingDetails,
      lastName: btoa(lastName),
    },
    {
      sessionId: getSessionId(state),
    }
  );

  return dispatch(
    getData({
      url,
      node: GUEST_INFORMATION,
      store: documentsStore,
      creator: receiveGifData,
    })
  );
};

export const gifSelectLoggedInGuest = () => (dispatch, getState) => {
  const state = getState();
  const { passengers } = getBookingDetails(state);
  const currentPassengerNumber = getPassengerNumber(state)(passengers);
  dispatch(updateSelectedGuest(currentPassengerNumber));
  return currentPassengerNumber;
};

export const submitGif = (values, selectedGuest) => (dispatch, getState) => {
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);

  const bookingDetails = getBookingDetails(state);
  const { lastName } = get(bookingDetails, 'passengers[0]', {});

  const url = buildUrl(
    '/gif/put',
    ['office', 'currency', 'voyage.id', 'bookingNumber', 'ship.stateroomNumber', 'lastName'],
    {
      ...bookingDetails,
      lastName: btoa(lastName),
    }
  );

  const passengers = getGifPassengers(state).map((passenger) => {
    if (`${passenger.passengerID}` === `${selectedGuest}`) {
      return values;
    }
    return passenger;
  });

  return new Promise((resolve, reject) => {
    dispatch(
      putData({
        url,
        values: {
          updateUserInfo,
          passengers,
        },
      })
    ).then((response) => {
      if (response.isSuccessful) {
        return resolve(response);
      }
      return reject();
    });
  });
};

export const handleContractOpen = () => (dispatch, getState) => {
  const ptcData = getPassengerTicketContract(getState());
  const pdfUrl = ptcData.callToActionUrl;
  window.open(pdfUrl, '_blank');
};

export const getComfortCheckInStatus = () => (dispatch, getState) => {
  const state = getState();
  const {
    bookingNumber,
    office,
    currency,
    passengers,
    voyage: { type: voyageType },
    ship: { shipCode }
  } = getBookingDetails(state);

  // the mapUrlVoyageType in API.js remaps MISSISSIPPI voyage types to RIVER
  const queryParams = {
    voyageType,
    shipCode
  };

  const url = buildUrl(
    '/bookings/mxpguest',
    ['office', 'currency', 'bookingNumber', 'passengerCount'],
    {
      bookingNumber,
      office,
      currency,
      passengerCount: passengers.length,
    },
    { ...queryParams }
  );

  return dispatch(
    getData({
      url,
      node: `${COMFORT_CHECK_IN}.checkInStatus`,
      store: documentsStore,
      creator: receiveComfortCheckInStatus,
    })
  ).then((res) => {
    if (res && res.data && res.data.errorCode) {
      dispatch(setComfortCheckInFetchFailed(res.data));
    }
    return res;
  });
};

export const fetchCheckInPageContent = () => (dispatch, getState) => {
  const url = buildUrl('/pages/comfortCheckIn', ['voyage.type'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: documentsStore,
      node: `${COMFORT_CHECK_IN}.checkInPageContent`,
      creator: receiveCheckInPageContent,
    })
  );
};

export const postComfortCheckIn = () => (dispatch, getState) => {
  const state = getState();
  const {
    bookingNumber,
    ship: { shipCode },
    comboBookings,
    voyage,
  } = getBookingDetails(state);
  const passengerNumber = getComfortCheckInPassenger(state);
  const guestPicture = getPassengerPhoto(state);
  const formattedPicture = guestPicture.replace('data:image/jpeg;base64,', '');

  const payload = [];
  if (comboBookings.length) {
    comboBookings.forEach(({ invoice, shipCode: code, voyageType }) => {
      payload.push({
        voyageType,
        bookingReference: Number(`${invoice}${passengerNumber}`),
        flightInfo: [],
        guestPicture: formattedPicture,
        shipCode: code,
        MXPGuestId: Number(`${invoice}${passengerNumber}`),
      });
    });
  } else {
    payload.push({
      voyageType: voyage.type,
      bookingReference: Number(`${bookingNumber}${passengerNumber}`),
      flightInfo: [],
      guestPicture: formattedPicture,
      shipCode,
      MXPGuestId: Number(`${bookingNumber}${passengerNumber}`),
    });
  }

  return dispatch(
    postData({
      url: '/bookings/checkin',
      values: payload,
    })
  );
};

export const postPhotoValidation = (photo) => (dispatch) => {
  const guestPicture = photo;

  return dispatch(
    postData({
      url: '/bookings/validate-photo',
      values: {
        photo: guestPicture,
      },
    })
  );
};

export const convertPhoto = ({ file, handleError, type, width }) => async (dispatch, getState) => {
  const API_S3_SIGNED_URL = '/bookings/s3-signed-url';
  const API_CONVERT_PHOTO_URL = '/bookings/convert-photo';
  const DATA_URI_PREFIX = `data:image/${type === 'jpg' ? 'jpeg' : type};base64,`;
  try {
    // 1 - Get S3 Predefined URL
    const s3SignedResponse = await postData({
      isHandleErrorResponse: false,
      url: API_S3_SIGNED_URL,
      values: { contentType: 'image/heic', extension: 'heic' },
    })(dispatch, getState);
    if (s3SignedResponse.isSuccessful) {
      // 2 - Upload file to S3 using Predefined URL
      const uploadResponse = await fetch(s3SignedResponse.data.url, {
        method: 'PUT',
        body: file,
      });
      // 3 - Call to convert and get file
      if (uploadResponse.ok) {
        const convertResponse = await postData({
          isHandleErrorResponse: false,
          url: API_CONVERT_PHOTO_URL,
          values: { fileName: s3SignedResponse.data.fileName, width, type },
        })(dispatch, getState);
        if (convertResponse?.data?.file) {
          dispatch(updateConvertedPhoto(`${DATA_URI_PREFIX}${convertResponse.data.file}`));
        } else {
          dispatch(handleError());
        }
      } else {
        throw new Error('Error uploading file to S3 Bucket using Predefined URL.');
      }
    } else {
      throw new Error('Error getting S3 Predefined URL.');
    }
  } catch {
    dispatch(handleError());
  }
};

export const postBoardingPassEmail = (passengerNumber, email) => (dispatch, getState) => {
  const state = getState();
  const { bookingNumber, office, passengers } = getBookingDetails(state);
  const { firstName, lastName } = passengers.find((passenger) => passenger.passengerNumber === passengerNumber) || {};
  const boardingPassData = getBoardingPassData(state, passengerNumber);
  const country = getCountryCodeFromCurrency(state);
  const isMississippi = getIsMississippi(state) && [COUNTRIES.UNITED_STATES].includes(country);
  const {
    route: { value: route },
    sections,
    journey,
  } = boardingPassData;
  const { pre, post, content } = get(sections, '[0]', {});
  const replacementValues = [
    {
      token: 'ROUTE',
      value: route,
    },
    {
      token: 'FROM',
      value: pre,
    },
    {
      token: 'TO',
      value: post,
    },
    {
      token: 'MXP_GUEST_ID',
      value: `${bookingNumber}${passengerNumber}`,
    },
    {
      token: 'YOUR_JOURNEY',
      value: journey,
    },
    ...content.map((item) => ({
      token: item.label.replace(' ', '_'),
      value: item.value,
    })),
  ];

  const queryParams = {
    attachPDF: true,
  };

  const url = buildUrl(
    '/email',
    ['office', 'templateId'],
    {
      office,
      templateId: isMississippi ? EMAIL_TEMPLATE_IDS.BOARDING_PASS_MSP : EMAIL_TEMPLATE_IDS.BOARDING_PASS,
    },
    queryParams
  );

  return dispatch(
    postData({
      url,
      values: {
        firstName,
        lastName,
        email,
        doNotSendToConsumer: false,
        replacementValues,
      },
    })
  ).then((res) => {
    return res;
  });
};

export const hasValidPhoto = () => (dispatch, getState) => {
  const state = getState();
  const passengerNumber = getComfortCheckInPassenger(state);
  const photo = getPassengerPhoto(state, passengerNumber);
  const photoValid = getPassengerPhotoValidation(state, passengerNumber);
  return !!photo && photoValid;
};

export const fetchHealthSurveyQuestion = () => (dispatch, getState) => {
  const state = getState();
  const voyageType = getVoyageType(state);
  const healthSurveyId = getFlagValue(state)(HEALTH_QUESTIONNAIRE_ID);
  const url = buildUrl('/bookings/health-questionnaires', ['voyageType', 'questionnaireId'], {
    voyageType,
    questionnaireId: healthSurveyId,
  });

  return dispatch(
    getData({
      url,
      node: `${HEALTH_SURVEY}.questionContent`,
      store: documentsStore,
      creator: receiveHealthSurvey,
    })
  );
};

export const postHealthSurvey = (values) => (dispatch, getState) => {
  const state = getState();
  const {
    cruise,
    bookingNumber,
    passengers,
    ship: { shipCode },
    voyage: { id: voyageId, embarkDate },
    office,
    currency,
    comboBookings,
  } = getBookingDetails(state);
  const passengerNumber = getHealthSurveyPassengerNumber(state);
  const content = getQuestionnaireContent(state);
  const { code: questionnaireId } = content;
  const voyageType = comboBookings?.length ? comboBookings?.[0]?.voyageType.toUpperCase() : getVoyageType(state);
  const authData = getAuthData(state);
  let userType = null;
  let accountEmail = null;
  let sub = null;
  let givenName = null;
  let familyName = null;

  if (authData?.attributes) {
    ({ userType, email: accountEmail, sub, given_name: givenName, family_name: familyName } = authData.attributes);
  }
  if (authData?.idTokenClaims) {
    const userData = getUserData(state);
    ({ userType, email: accountEmail, given_name: givenName, family_name: familyName, sub } = userData);
    const { uniqueId } = authData;
    // If sub exists, account was previously cognito and we want to still get that correct data.
    // If no sub exists, this is a new azure account, and we will be using the uniqueId in place of the sub moving forwards
    sub = sub || uniqueId;
  }

  const cruiseName64 = window.btoa(cruise.name).replace(/={1,2}$/, '');

  const { firstName, lastName, gender, birthDate, email, airPassengerId } = passengers.find(
    (pass) => pass.passengerNumber === passengerNumber
  );

  const url = buildUrl(
    '/bookings/health-questionnaires',
    ['voyageType', 'questionnaireId', 'answersLiteral', 'bookingNumber', 'passengerNumber'],
    {
      voyageType,
      questionnaireId,
      answersLiteral: 'answers',
      bookingNumber,
      passengerNumber,
    }
  );

  return dispatch(
    postData({
      url,
      values: {
        completedBy: {
          code: accountEmail,
          name: `${givenName} ${familyName}`,
        },
        patientDemographics: {
          dob: moment(birthDate).format('YYYY-MM-DD'),
          firstName,
          gender,
          lastName,
          paxNum: passengerNumber,
          passengerId: airPassengerId,
          userId: sub,
          updateUserType: userType,
          email,
        },
        date: moment().format(),
        questions: values,
        vesselCode: shipCode,
        patientType: PATIENT_TYPES.GUEST,
        voyageCode: voyageId,
        office,
        currency,
        cruiseName: cruiseName64,
        embarkDate,
      },
    })
  );
};

export default documentsStore;
