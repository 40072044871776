import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FORMS } from '../../../common/Constants';
import helpStore from '../../../pages/help/HelpStore';
import SearchBar from './SearchBar';

const {
  selectors: { getSearchBarLabels },
} = helpStore;

const mapStateToProps = (state) => {
  const labels = getSearchBarLabels(state);

  return {
    ...labels,
    initialValues: {
      searchValue: '',
    },
  };
};

const onSubmit = ({ searchValue }, dispatch, { handleSearchFaq }) => {
  return handleSearchFaq(searchValue);
};

const mapDispatchToProps = () => ({});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORMS.FAQ_SEARCH,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    onSubmit,
  })
);

export default enhance(SearchBar);
