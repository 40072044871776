import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import InputText from '@viking-eng/input-text';
import React from 'react';
import { Form } from 'redux-form';
import { Field } from '../..';
import PropTypes from '../../../PropTypes';
import './SearchBar.scss';

const SearchBar = ({ fieldLabel, handleSubmit, ariaLabel, pristine, handleClear }) => {
  return (
    <div className="search-bar">
      <Form className="form faq-search-form center" onSubmit={handleSubmit} noValidate>
        <div className="search-row row no-gutters">
          <div className="form-input-wrapper text-right search-field col">
            <Field component={InputText} placeholder={fieldLabel} name="searchValue" />
            {!pristine && (
              <span className="close-wrapper">
                <Button onButtonClick={handleClear} attributes={{ 'aria-label': 'clear search' }}>
                  <Icon name="close" />
                </Button>
              </span>
            )}
          </div>
          <div className="form-submit">
            <Button onButtonClick={handleSubmit} attributes={{ 'aria-label': ariaLabel }}>
              <Icon name="search" />
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

SearchBar.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};

export default SearchBar;
