import Button from '@viking-eng/button';
import CartItem from '@viking-eng/cart-item';
import CartItemList from '@viking-eng/cart-item-list';
import React, { Component } from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import './CartSoldOut.scss';

/* eslint-disable react/no-array-index-key */
class CartSoldOutModal extends Component {
  constructor(props) {
    super(props);
    this.closeHandler = this.closeHandler.bind(this);
    this.state = {
      isClosing: false,
    };
  }

  closeHandler = async () => {
    const { isClosing } = this.state;
    if (!isClosing) {
      this.setState({ isClosing: true });
      const { items, onClose, handleModalOpen } = this.props;
      const addonIds = items.reduce((acc, item) => {
        if (item?.comboData?.length > 0) {
          acc.push(...item.comboData.map((i) => i.itemID));
        } else {
          acc.push(item.itemID);
        }
        return acc;
      }, []);
      await onClose(addonIds);
      this.setState({ isClosing: false });
      handleModalOpen(); // Open the price change modal if it's rendered
    }
  };

  render() {
    const { id, title, subtitle, button, items } = this.props;
    const { isClosing } = this.state;
    return (
      <Modal id={id} forceAction title={title} subTitle={subtitle}>
        {subtitle && items.length && (
          <div className="cart-sold-out-modal">
            <div className="mvj-modal">
              <div className="row no-gutters d-flex justify-content-center">
                <div className="col-11">
                  <div className="title h5 h3-md">{title}</div>
                  <div className="message">{subtitle}</div>
                  {items && (
                    <CartItemList>
                      {items.map((item, i) => (
                        <CartItem key={i} className="cart-item" {...item} />
                      ))}
                    </CartItemList>
                  )}
                  <div className="row no-gutters d-flex flex-column">
                    <div className="d-flex flex-row justify-content-center buttons">
                      <Button
                        appearance="secondary-blue"
                        onButtonClick={this.closeHandler}
                        key="continue"
                        disabled={isClosing}
                      >
                        {button.text}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

CartSoldOutModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.cartItem),
  onClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  onAddonsRemoved: PropTypes.func,
};

CartSoldOutModal.defaultProps = {
  id: '',
  title: '',
  subtitle: '',
  button: {
    text: '',
  },
  items: [],
  onAddonsRemoved: null,
};

export default CartSoldOutModal;
