import { connect } from 'react-redux';

import paymentsStore from '../../../pages/payments/PaymentsStore';

import VouchersCredits from './VouchersCredits';

const { getPassengerVouchersCreditsContent } = paymentsStore.selectors;

const mapStateToProps = (state) => ({
  ...getPassengerVouchersCreditsContent(state),
});

export default connect(mapStateToProps)(VouchersCredits);
