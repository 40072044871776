import Radio from '@viking-eng/radio';
import classNames from 'classnames';
import React from 'react';

import PropTypes from '../../PropTypes';
import { MODALS } from '../../common/Constants';
import { showModal } from '../../common/Utils';

import { Field } from '..';

import './GuestSelector.scss';

const dialogModalId = MODALS.DIALOG;

const RadioLabel = ({ name, isCompleted, labels }) => (
  <div>
    <span className="guest-selector-name">{`${name} `}</span>
    <span className={classNames('guest-selector-status', { pending: !isCompleted })}>
      {isCompleted ? labels.complete : labels.pending}
    </span>
  </div>
);

RadioLabel.propTypes = {
  name: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  labels: PropTypes.guestSelectorLabels.isRequired,
};

const GuestSelector = ({ dirty, guests, handleGuestChange, updateNextGuest, labels }) => (
  <div className="form guest-selector-form">
    <div className="row guest-selector-section-header-wrapper">
      <div className="label guest-selector-section-header col-lg-10">{labels.title}</div>
    </div>
    <div className="row form-section-fields">
      <div className="col-lg-10">
        {guests.map(({ isCompleted, name, passengerID }) => (
          <Field
            key={passengerID}
            id={`passenger${passengerID}`}
            component={Radio}
            type="radio"
            value={`${passengerID}`}
            groupName="passengerID"
            name="passengerID"
            label={<RadioLabel name={name} isCompleted={isCompleted} labels={labels} />}
            appearance="radio"
            onChange={() => {
              if (dirty) {
                updateNextGuest(passengerID, dirty);
                showModal(dialogModalId);
              } else {
                handleGuestChange(passengerID);
              }
            }}
          />
        ))}
      </div>
    </div>
  </div>
);

GuestSelector.propTypes = {
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      isCompleted: PropTypes.bool,
      name: PropTypes.string,
      passengerID: PropTypes.number,
    })
  ),
  handleGuestChange: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  updateNextGuest: PropTypes.func.isRequired,
  labels: PropTypes.guestSelectorLabels.isRequired,
};

GuestSelector.defaultProps = {
  guests: [],
};

export default GuestSelector;
