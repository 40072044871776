import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import React from 'react';
import PropTypes from '../../PropTypes';
import './PreAuthTitle.scss';

const PreAuthTitle = ({ onButtonClick, text, isSubmitting }) => (
  <h5 className="login-subheader d-flex align-items-center justify-content-center">
    {onButtonClick && (
      <Button
        appearance="icon"
        attributes={{
          'aria-label': 'back',
        }}
        className="back"
        onButtonClick={onButtonClick}
        disabled={isSubmitting}
      >
        <Icon name="prev-bold" />
      </Button>
    )}
    {text}
  </h5>
);

PreAuthTitle.propTypes = {
  onButtonClick: PropTypes.func,
  text: PropTypes.jsx,
  isSubmitting: PropTypes.bool,
};

PreAuthTitle.defaultProps = {
  onButtonClick: null,
  text: null,
  isSubmitting: false,
};

export default PreAuthTitle;
