import { connect } from 'react-redux';
import { USER_TYPES } from '../common/Constants';
import modalStore from '../common/ModalStore';
import userStore from '../common/UserStore';
import MvjModal from './MvjModal';

const {
  selectors: { getModal },
  creators: { clearModal },
} = modalStore;

const { getUserType, getIsTaAccount } = userStore.selectors;

const mapStateToProps = (state, { id = '' }) => {
  const modal = getModal(state);
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);
  return {
    isOpen: modal.isActive && id === modal.view,
    hasPreHeader: isTaAccount || userType === USER_TYPES.CSA,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleModalClose: () => dispatch(clearModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MvjModal);
