import Button from '@viking-eng/button';
import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import classNames from 'classnames';
import React from 'react';
import { Modal } from '..';
import { NOTIFICATION_TYPES } from '../../common/Constants';
import { hideModal, prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './Dialog.scss';

const getButtonText = (buttons, id) => {
  const button = buttons.find((btn) => btn.id === id) || {};
  return button.text;
};

class DialogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { failed: false };
  }

  resetModal = () => {
    this.setState({ failed: false });
  };

  confirmHandler = (customOnConfirm) => {
    const { id, onConfirm, entityId } = this.props;
    const customConfirmIsFunction = typeof customOnConfirm === 'function';

    const onConfirmFunction = customConfirmIsFunction ? customOnConfirm : onConfirm;
    if (typeof onConfirmFunction === 'function') {
      this.resetModal();

      const confirm = onConfirmFunction(entityId);

      if (confirm && confirm.then) {
        confirm.then((result) => {
          if (result === NOTIFICATION_TYPES.FAILURE) {
            this.setState({ failed: true });
          } else {
            hideModal(id);
          }
        });
      } else {
        hideModal(id);
      }
    }
  };

  cancelHandler = (customOnCancel) => {
    const { id, onCancel } = this.props;
    const customCancelIsFunction = typeof customOnCancel === 'function';
    const onCancelFunction = customCancelIsFunction ? customOnCancel : onCancel;
    if (typeof onCancelFunction === 'function') {
      onCancelFunction();
    }
    this.resetModal();
    hideModal(id);
  };

  // TODO: [MR-3863] Replace the buttons array with an object (turn id into the key)

  render() {
    const {
      id,
      isPassportsVisasModal,
      title,
      HTMLTitle,
      message,
      copy,
      failMessage,
      buttons,
      submitting,
      printLabel,
      callToActionGroup,
      forceAction,
      icon,
      modalClassName,
    } = this.props;
    const { failed } = this.state;
    return (
      <Modal id={id} onClose={this.cancelHandler} printLabel={printLabel} forceAction={forceAction} title={title}>
        {(title || HTMLTitle || message || copy) && (
          <div className={classNames('confirm-modal', { [modalClassName]: modalClassName })}>
            <div className="mvj-modal">
              <div className="row no-gutters d-flex justify-content-center">
                <div className="col">
                  <div className="text-content">
                    {title && <div className={classNames('title h4 h3-md', { 'title-only': !message })}>{title}</div>}
                    {HTMLTitle && (
                      <div
                        className={classNames('title h4 h3-md', { 'title-only': !message })}
                        dangerouslySetInnerHTML={prepareHtml(HTMLTitle)}
                      />
                    )}
                    {copy && <div className="message" dangerouslySetInnerHTML={prepareHtml(copy)} />}
                    {icon && <Icon name={icon} />}
                    {message && <div className="message" dangerouslySetInnerHTML={prepareHtml(message)} />}
                  </div>
                  <div className="form-actions-group">
                    {isPassportsVisasModal && (
                      <div className="row passport-visa-dialog justify-content-center align-items-center">
                        {callToActionGroup ? (
                          callToActionGroup.map((cta) => (
                            <div className="col-12 col-md-6 d-flex buttons flex-column-reverse">
                              <Button
                                appearance="secondary-blue"
                                onButtonClick={() => {
                                  this.resetModal();
                                  const { onConfirm } = cta;
                                  this.confirmHandler(onConfirm);
                                }}
                              >
                                {getButtonText(cta.buttons, 'confirm')}
                              </Button>
                            </div>
                          ))
                        ) : (
                          <div />
                        )}
                      </div>
                    )}

                    {callToActionGroup && !isPassportsVisasModal ? (
                      callToActionGroup.map((cta) => (
                        <FormActions
                          secondaryButton={{
                            onButtonClick: () => {
                              this.resetModal();
                              const { onCancel } = cta;
                              this.cancelHandler(onCancel);
                            },
                            text: getButtonText(cta.buttons, 'cancel'),
                          }}
                          primaryButton={{
                            onButtonClick: () => {
                              const { onConfirm } = cta;
                              this.confirmHandler(onConfirm);
                            },
                            text: getButtonText(cta.buttons, 'confirm'),
                            loading: submitting,
                          }}
                          alert={failed && failMessage}
                        />
                      ))
                    ) : (
                      <FormActions
                        secondaryButton={{
                          onButtonClick: this.cancelHandler,
                          text: getButtonText(buttons, 'cancel'),
                          disabled: submitting,
                        }}
                        primaryButton={{
                          onButtonClick: this.confirmHandler,
                          text: getButtonText(buttons, 'confirm'),
                          loading: submitting,
                        }}
                        alert={failed && failMessage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

DialogModal.propTypes = {
  ...PropTypes.dialogModalFields,
};

DialogModal.defaultProps = {
  entityId: 0,
  failMessage: '',
  buttons: [],
  onCancel: () => true,
  forceAction: false,
};

export default DialogModal;
