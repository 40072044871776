import get from 'lodash/get';
import { connect } from 'react-redux';
import commonStore from '../../common/CommonStore';
import PreAuthWrapper from './PreAuthWrapper';

const { getLabels } = commonStore.selectors;

const mapStateToProps = (state) => {
  const { footer = {} } = get(getLabels(state), 'pages.preLogin.loginPage', {});

  return {
    footerData: {
      leftMenuItems: footer.items,
      leftMenuHeader: footer.itemsTitle,
      ...footer,
    },
  };
};

export default connect(mapStateToProps)(PreAuthWrapper);
