import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { userStore } from '../../common';
import commonStore, { getCardModalByPageName } from '../../common/CommonStore';
import { MODAL_URL_VARIABLE_NAMES } from '../../common/Constants';
import { setViewAndShowModal } from '../../common/ModalStore';
import { withContent } from '../../components';
import Extensions from './Extensions';
import extensionsStore, { fetchExtensionsPageContent } from './ExtensionsStore';

const {
  getTitle,
  getSubtitle,
  getExtensionsCardData,
  getExtensionsModalData,
  isLoadingExtensions,
} = extensionsStore.selectors;
const { getFetchingCommonContent, getLabels } = commonStore.selectors;
const { receiveQueryParams } = userStore.creators;
const { getLinkQueryParams } = userStore.selectors;

const mapStateToProps = (state) => {
  const extensionsPageData = getExtensionsCardData(state);
  const modalPageName = get(getLinkQueryParams(state), MODAL_URL_VARIABLE_NAMES.EXTENSION_CODE, '');
  const modalData = getExtensionsModalData(state);
  return {
    extensionsPageData,
    isLoading: isLoadingExtensions(state),
    title: getTitle(state),
    subtitle: getSubtitle(state),
    labels: getLabels(state),
    modalData,
    modalToOpen: getCardModalByPageName(extensionsPageData?.extensionCardData, modalPageName, (card) => card.typeCode),
    isFetchingCommonContent: getFetchingCommonContent(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchExtensionsPageContent()),
  handleCardDetailsClicked: (id, type) => {
    dispatch(receiveQueryParams({ extensionCode: '' }));
    dispatch(setViewAndShowModal('extensionsModal', { id, type }));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Extensions);
