import Hero from '@viking-eng/hero';
import React from 'react';
import './MvjHero.scss';

const MvjHero = (props) => (
  <div className="mvj-hero">
    <Hero {...props} />
  </div>
);

export default MvjHero;
