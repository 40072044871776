import { LogLevel } from '@azure/msal-browser';

const env = process.env.NODE_ENV === 'development' ? process.env : window && window.env;

const B2C_DOMAIN = env.REACT_APP_B2C_DOMAIN || '';
const B2C_TENANT_ID = env.REACT_APP_B2C_TENANT_ID || '';

// Azure B2C
const B2C_POLICIES = Object.freeze({
  SISU: 'B2C_1A_PASSWORDLESSSISU',
  SISU_PWD: 'B2C_1A_SIGNINSIGNUP',
  CHG_PWD: 'B2C_1A_PASSWORDCHANGE',
  CSA_ACCESS: 'B2C_1A_IMPERSONATE',
});

const B2C_AUTHORITY_BASE = `https://${B2C_DOMAIN}/${B2C_TENANT_ID}`;
// const B2C_AUTHORITY = `https://${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CSA_ACCESS}/oauth2/v2.0/authorize?client_id=${B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=%2Fmyjourney&scope=openid&response_type=id_token&prompt=login`;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const MSAL_B2C_POLICIES = {
  names: {
    signUpSignIn: B2C_POLICIES.SISU,
    signUpSignInWithPwd: B2C_POLICIES.SISU_PWD,
    changePassword: B2C_POLICIES.CHG_PWD,
  },
  authorities: {
    signUpSignIn: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU}`,
    },
    signUpSignInWithPwd: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU_PWD}`,
    },
    changePassword: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_PWD}`,
    },
    impersonation: {
      authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CSA_ACCESS}`,
    },
  },
  authorityDomain: B2C_DOMAIN,
};

const MSAL_SCOPES = env.REACT_APP_MSAL_SCOPES
  ? JSON.parse(env.REACT_APP_MSAL_SCOPES)
  : ['https://authdevninerealms.onmicrosoft.com/mvj-api/profile.read', 'profile', 'offline_access'];

export const MSAL_CONFIG = Object.freeze({
  auth: {
    clientId: env.REACT_APP_AUTH_CLIENT_ID || '3fc56d66-bd9f-4dfd-8ed1-bf28a2df0fae',
    authority: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU_PWD}`,
    authorities: {
      signUpSignIn: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU_PWD}`,
      passwordless: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.SISU}`,
      changePassword: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CHG_PWD}`,
      impersonation: `${B2C_AUTHORITY_BASE}/${B2C_POLICIES.CSA_ACCESS}`,
    },
    scopes: MSAL_SCOPES,
    redirectUri: '/myjourney',
    knownAuthorities: [B2C_DOMAIN],
    postLogoutRedirectUri: '/myjourney',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  scopes: MSAL_SCOPES,
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.log(message);
            break;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.log(message);
            break;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.log(message);
            break;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.log(message);
            break;
          default:
        }
      },
    },
  },
});

export const LOGIN_REQUEST = { scopes: MSAL_SCOPES };

export const API_CONFIG = {
  scopes: MSAL_SCOPES,
  uri: B2C_AUTHORITY_BASE,
};
