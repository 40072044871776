import { connect } from 'react-redux';
import { compose } from 'redux';

import { withContent } from '../../components';

import Onboard from './Onboard';
import onboardStore, { fetchOnboardPageContent } from './OnboardStore';

const { getTabs, getTitle, isLoadingTabs } = onboardStore.selectors;

const mapStateToProps = (state, { location, match }) => ({
  isLoading: isLoadingTabs(state),
  tabs: getTabs(state)(location.pathname, match.path),
  title: getTitle(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchOnboardPageContent()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Onboard);
