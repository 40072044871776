import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import commonStore, { userStore } from '../../common';
import { getData } from '../../common/Api';
import { COUNTRIES, FAQ_CATEGORIES, FAQ_CATEGORIES_LOGIN, PAGE_NAMES, VOYAGE_TYPE } from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import { buildUrl, decodeCountryCodeFromCurrency, decodeHtmlEntities } from '../../common/Utils';

const {
  selectors: { getBookingDetails },
} = userStore;

const {
  selectors: { getPageTabLabels },
} = commonStore;

const helpStore = createPageTitleDuck('help').extend({
  types: ['UPDATE_ACTIVE_CATEGORY', 'UPDATE_FAQ_SEARCH_VALUE'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.UPDATE_ACTIVE_CATEGORY: {
        return {
          ...state,
          activeCategoryIndex: action.categoryIndex,
          activeQuestionIndex: -1,
        };
      }
      case types.UPDATE_FAQ_SEARCH_VALUE: {
        return {
          ...state,
          searchValue: action.value,
        };
      }
      default: {
        return state;
      }
    }
  },
  creators: ({ types }) => ({
    updateActiveCategory: (index) => ({
      type: types.UPDATE_ACTIVE_CATEGORY,
      categoryIndex: index,
    }),
    handleUpdateFAQSearchValue: (value) => ({
      type: types.UPDATE_FAQ_SEARCH_VALUE,
      value,
    }),
  }),
  selectors: {
    getActiveCategoryIndex: (state) => get(state, 'help.activeCategoryIndex', 0),
    getSectionsItems: (state) => get(state, 'help.content.sections[0].items[0]', {}),
    getCallToActionUrl: new Selector(({ getSectionsItems }) =>
      createSelector(getSectionsItems, (items) => items.callToActionUrl)
    ),
    getSearchValue: (state) => state?.help?.searchValue,
    getSearchBarLabels: new Selector(() =>
      createSelector([(state) => getPageTabLabels(state)(PAGE_NAMES.HELP)], ({ labels }) => {
        return {
          ariaLabel: labels.search,
          fieldLabel: labels.searchLabel,
        };
      })
    ),
    getFaqCategories: new Selector(({ getPageContent, getActiveCategoryIndex }) =>
      createSelector(
        [getPageContent, getActiveCategoryIndex, (state) => getPageTabLabels(state)(PAGE_NAMES.HELP)],
        (content, activeCategoryIndex, { labels }) => {
          const rawSections = get(content, 'sections', []);
          const loginFaqSections = Object.values(FAQ_CATEGORIES_LOGIN).map((section) => section.title);

          let sortedSections = [];
          if (rawSections.length > 0) {
            // Move Health & Safety and Login FAQs to the bottom for now
            const remainingSections = rawSections.filter(
              (section) => ![FAQ_CATEGORIES.HEALTH_SAFETY, ...loginFaqSections].includes(section.title)
            );
            if (remainingSections?.length > 0) {
              sortedSections = sortFAQByOrder(remainingSections, true);
            }
            const authSections = rawSections.filter((section) => loginFaqSections.includes(section.title));
            if (authSections?.length) {
              authSections.forEach((authSection) => {
                sortedSections.push(authSection);
              });
            }
            const healthAndSafetySection = rawSections.find(
              (section) => section.title === FAQ_CATEGORIES.HEALTH_SAFETY
            );
            if (healthAndSafetySection) {
              sortedSections.push(healthAndSafetySection);
            }
          }
          const categories = sortedSections.map((section, sectionIndex) => {
            const questions = sortFAQByOrder(section.questions || [], true).map((item, ind) => ({
              question: item.title,
              answer: item.longText,
              id: ind,
            }));
            return {
              active: sectionIndex === activeCategoryIndex,
              name: section.title,
              questions,
            };
          });
          return {
            categories,
            heading: labels.tableOfContents,
          };
        }
      )
    ),
    getSearchResults: new Selector(({ getSearchValue, getFaqCategories }) =>
      createSelector(
        [getFaqCategories, (state) => getPageTabLabels(state)(PAGE_NAMES.HELP), getSearchValue],
        ({ categories }, { labels }, searchValue) => {
          if (!searchValue) {
            return null;
          }
          const { results } = labels;

          const totalQuestions = categories.reduce((acc, { questions }) => {
            const escapeRegex = function escapeRegExp(string) {
              return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            };
            const regex = new RegExp(escapeRegex(searchValue), 'i');
            const filteredQuestions = questions.filter(
              ({ answer, question }) =>
                decodeHtmlEntities(answer).match(regex) || decodeHtmlEntities(question).match(regex)
            );
            filteredQuestions.forEach((item) => {
              const alreadyExists = acc.find(({ question }) => question === item.question);
              if (!alreadyExists) {
                acc.push({
                  ...item,
                  id: acc.length,
                });
              }
            });
            return acc;
          }, []);
          return {
            name: `${totalQuestions.length} ${results}`,
            questions: totalQuestions,
          };
        }
      )
    ),
  },
});

export const sortFAQByOrder = (sourceArray, useAlpha) =>
  sourceArray
    .sort((a, b) => {
      const last = sourceArray.length - 1;
      const order = (Number.isInteger(b.order) ? b.order : last) - (Number.isInteger(a.order) ? a.order : last);
      if (order === 0 && useAlpha) {
        return (b.title || '').replace(/(<([^>]+)>)/, '') < (a.title || '').replace(/(<([^>]+)>)/, '') ? 1 : -1;
      }
      if (order === 0) {
        return 0;
      }
      return order > 0 ? -1 : 1;
    })
    .map((item, idx) => ({ ...item, order: idx }));

export const fetchHelpPageContent = () => (dispatch, getState) => {
  const { receiveContent } = helpStore.creators;
  const bookingDetails = getBookingDetails(getState());
  const url = buildUrl('/pages/faq', ['voyage.type'], bookingDetails, {
    country: decodeCountryCodeFromCurrency(bookingDetails.currency) || bookingDetails.office || COUNTRIES.UNITED_STATES,
    voyageType: bookingDetails?.voyage?.type || VOYAGE_TYPE.RIVER,
  });
  dispatch(
    getData({
      url,
      store: helpStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export default helpStore;
