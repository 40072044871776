import Notifications from '@viking-eng/notifications';
import Spinner from '@viking-eng/spinner';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { COMFORT_CHECK_IN_WINDOW } from '../../../common/Constants';
import { prepareHtml } from '../../../common/Utils';
import { PageHeader } from '../../../components';
import PropTypes from '../../../PropTypes';
import './ComfortCheckIn.scss';
import PassengerSelector from './passengerSelector/PassengerSelectorContainer';

class ComfortCheckIn extends Component {
  constructor() {
    super();
    this.componentRef = [];
    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      cciClosed: undefined,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { countDown, countDownCloseThresholdHours } = this.props;
      const now = moment();
      const minContactDate = moment(countDown, 'YYYY-MM-DDThh:mm:ss');
      const cciOpen = minContactDate.subtract(COMFORT_CHECK_IN_WINDOW, 'days');
      const diffCciOpen = moment.duration(cciOpen.diff(now));
      const diffCciClosed = moment.duration(moment(countDown).diff(now));
      const daysCciOpen = Math.floor(diffCciOpen.asDays());
      const daysCciClosed = Math.floor(diffCciClosed.asDays());
      const cciClosed = daysCciClosed * 24 < countDownCloseThresholdHours;
      const timer = moment.utc(cciOpen - now);
      const hours = timer.format('HH');
      const minutes = timer.format('mm');
      const seconds = timer.format('ss');
      const totalMilli = diffCciOpen.asMilliseconds();

      this.setState({ days: daysCciOpen, hours, minutes, seconds, totalMilli, cciClosed });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getPassengerSelector(passenger) {
    const {
      isCloseToDepartureDate,
      content: {
        checkedInData,
        checkInNow,
        disabledMessage,
        checkInStatusError,
        incompleteGifError,
        cciClosedMessage,
        cciClosedDueToGifLockdownMessage,
        isViewOnly,
      },
    } = this.props;
    const { cciClosed } = this.state;

    let closed = cciClosed;
    let closedMessage = cciClosedMessage;

    if (!closed && isCloseToDepartureDate && !passenger.gifCompleted) {
      closed = true;
      closedMessage = cciClosedDueToGifLockdownMessage;
    }

    return (
      <PassengerSelector
        passenger={passenger}
        checkedInData={checkedInData}
        checkInNow={checkInNow}
        startSurveyDisabled={
          !!disabledMessage || !!checkInStatusError || !passenger.gifCompleted || closed || isViewOnly
        }
        cciClosed={closed}
        cciClosedMessage={closedMessage}
        checkInStatusError={checkInStatusError}
        incompleteGifError={incompleteGifError}
      />
    );
  }

  render() {
    const { days, hours, minutes, seconds, totalMilli } = this.state;
    const { content, checkInStatusLoading, backButton } = this.props;
    const { loaded, title, subtitle } = content;
    const isLoading = days === undefined || checkInStatusLoading || !loaded;

    let comfortCheckInContent = null;

    if (!isLoading) {
      if (totalMilli <= 0) {
        const { checkInAvailable, selectPassenger, passengers, disabledMessage } = content;
        comfortCheckInContent = (
          <div className="comfort-check-in">
            {disabledMessage && (
              <Notifications
                icon="notification"
                notifications={[
                  {
                    button: {},
                    text: disabledMessage,
                  },
                ]}
                variant="banner"
              />
            )}
            <div className="check-in-message">{checkInAvailable}</div>
            <div className="check-in-title h3 h5-sm">{selectPassenger}</div>
            {passengers.length > 0 ? (
              <div
                className={classNames('passenger-container row no-gutters', {
                  'col-md-6': passengers.length === 1,
                })}
              >
                {passengers.map((passenger) => this.getPassengerSelector(passenger))}
              </div>
            ) : (
              <div className="spinner-container">
                <Spinner />
              </div>
            )}
          </div>
        );
      } else {
        const { pageDescription } = content;
        comfortCheckInContent = (
          <div className="comfort-check-in count-down">
            <div className="countdown-title">{subtitle}</div>
            <div className="countdown-wrapper">
              <div className="countdown-item">
                {days}
                <span>{content.days}</span>
              </div>
              <div className="countdown-item">
                {hours}
                <span>{content.hours}</span>
              </div>
              <div className="countdown-item">
                {minutes}
                <span>{content.minutes}</span>
              </div>
              <div className="countdown-item">
                {seconds}
                <span>{content.seconds}</span>
              </div>
            </div>
            <div className="countdown-description" dangerouslySetInnerHTML={prepareHtml(pageDescription)} />
          </div>
        );
      }
    }
    return (
      <div className="preference-page-wrapper">
        <>
          <PageHeader hasLine backButton={backButton} title={title} isLoading={isLoading} />
          {comfortCheckInContent}
        </>
      </div>
    );
  }
}

ComfortCheckIn.propTypes = {
  content: PropTypes.shape({
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        passengerNumber: PropTypes.number,
      })
    ),
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
    checkInAvailable: PropTypes.string,
    checkInNow: PropTypes.string,
    loaded: PropTypes.bool,
  }),
  countDown: PropTypes.string,
  countDownCloseThresholdHours: PropTypes.number.isRequired,
  checkInStatusLoading: PropTypes.bool.isRequired,
};

ComfortCheckIn.defaultProps = {
  content: {},
  countDown: '',
};

export default ComfortCheckIn;
