import InputText from '@viking-eng/input-text';
import React from 'react';

import { Field, FieldWrapper } from '../..';
import PropTypes from '../../../PropTypes';

import './BankNumber.scss';

const BankNumber = ({
  images,
  description,
  fieldAttributes: { mask, ...otherFieldAttributes },
  isSubmitting,
  ...additionalFieldProps
}) => (
  <div className="form-bank-number">
    <FieldWrapper description={description} images={images}>
      <Field
        component={InputText}
        inputAttributes={{ disabled: isSubmitting, inputMode: 'numeric' }}
        {...otherFieldAttributes}
        {...additionalFieldProps}
        {...mask}
      />
    </FieldWrapper>
  </div>
);

BankNumber.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})),
  description: PropTypes.string,
  fieldAttributes: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isSubmitting: PropTypes.bool,
};

BankNumber.defaultProps = {
  images: [],
  description: null,
  isSubmitting: false,
};

export default BankNumber;
