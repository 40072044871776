import { connect } from 'react-redux';
import { compose } from 'redux';

import accountStore, { fetchTabContent } from '../AccountStore';
import { withContent } from '../../../components';
import { TAB_NAMES } from '../../../common/Constants';
import { setViewAndShowModal } from '../../../common/ModalStore';

import RequestInvoice from './RequestInvoice';

const { isLoadingTabs, getRequestInvoiceData } = accountStore.selectors;

const mapStateToProps = (state) => ({
  requestInvoiceData: getRequestInvoiceData(state),
  isLoading: isLoadingTabs(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTabContent(TAB_NAMES.REQUEST_INVOICE));
  },
  openRequestInvoiceModal: (type, id) => {
    dispatch(setViewAndShowModal(type, { id }));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(RequestInvoice);
