import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { userStore } from '../../common/index';
import { getData } from '../../common/Api';
import { ALLOW_MISSISSIPPI, COUNTRIES, USER_TYPES, VOYAGE_STATUSES, VOYAGE_TYPE } from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import {
  buildUrl,
  getMarketingSiteForCountry,
  replaceCMSTokenWithValue,
  sessionStorageService,
} from '../../common/Utils';
import history from '../../common/history';

const {
  getBookingDetails,
  getCountryCodeFromCurrency,
  getIsCruiseExceptionUK,
  getSplashPageLink,
  getUserType,
  getVoyageType,
} = userStore.selectors;

const splashPageStore = createPageTitleDuck('splashPage').extend({
  selectors: {
    getSplashPageCMSContent: (state) => get(state, 'splashPage.content', {}),
    getSplashPageContent: new Selector(({ getSplashPageCMSContent }) =>
      createSelector(
        [getSplashPageCMSContent, getSplashPageLink, getBookingDetails, getCountryCodeFromCurrency, getUserType],
        (splashPageContent, splashPageLink, { voyage: { type: voyageType } = {} }, countryCode, userType) => {
          const { sections, subtitle, title } = splashPageContent;
          let ctaIsImage = false;
          let cta = [];
          let pageCopy = {};

          if (sections && sections[0].items) {
            const filteredItems =
              sections &&
              sections[0].items.filter(
                (item) =>
                  !item.reference ||
                  (item.reference === 'addBookingButton' && userType !== USER_TYPES.TA) ||
                  item.reference === 'bookYourNextVoyageButton'
              );
            /*
            The splash pages makes a call for the data on a per page basis.
            This code only needs to find the block that contains a reference key,
            not necessarily a matching reference string.
          */
            if (splashPageLink === VOYAGE_STATUSES.COMING_SOON && voyageType === VOYAGE_TYPE.MISSISSIPPI) {
              const comingSoonLabel =
                sections && sections[0].items.find((item) => item.reference === 'comingSoonLabel');
              pageCopy = comingSoonLabel.longText;
            } else if (
              splashPageLink === VOYAGE_STATUSES.COMING_SOON ||
              splashPageLink === VOYAGE_STATUSES.SAILED ||
              splashPageLink === VOYAGE_STATUSES.NO_BOOKINGS
            ) {
              pageCopy = subtitle;
            } else {
              pageCopy = sections[0].items.find((item) => item.reference !== 'undefined').longText || '';
            }

            cta = filteredItems.map((item) => {
              ctaIsImage = (item.images && item.images.length > 0) || false;

              let { callToActionUrl } = item;
              if (item.reference === 'bookYourNextVoyageButton' && !callToActionUrl) {
                const marketingSiteUrl = getMarketingSiteForCountry(
                  countryCode || sessionStorageService('getItem', 'originCountry')
                );
                callToActionUrl = marketingSiteUrl;
              }

              const src = get(item, 'images[0].url', null);
              const alt = get(item, 'images[0].alt', '');
              const ctaText = get(item, 'callToActionTitle', '');

              const buttonAction = () => {
                const regEx = /^http/g;
                const isHttp = callToActionUrl.match(regEx);
                const buttonClick = isHttp ? window.open(callToActionUrl, '_blank') : history.push(callToActionUrl);
                return buttonClick;
              };
              let attributes = {};
              if (callToActionUrl === '/add-booking') {
                attributes = {
                  'data-toggle': 'modal',
                  'data-target': '#addBookingModal',
                  'data-dismiss': 'modal',
                  onClick: undefined,
                  modalVersionTwo: true,
                };
              }

              return {
                text: ctaText,
                appearance: 'secondary-blue',
                attributes: {
                  alt,
                  src,
                  title: ctaText,
                  onClick: () => {
                    buttonAction();
                  },
                  ...attributes,
                },
              };
            });
          }
          let bodyText = pageCopy;
          if (splashPageLink === VOYAGE_STATUSES.COMING_SOON && sections && sections[0].items) {
            bodyText = replaceCMSTokenWithValue(
              bodyText,
              sections[0].items.map((v) => ({
                key: v.reference,
                value: v.longText,
              }))
            );
          }
          return {
            ctaIsImage,
            title,
            bodyText,
            cta,
          };
        }
      )
    ),
  },
});

export const fetchSplashPageContent = (pathname) => (dispatch, getState) => {
  const { receiveContent } = splashPageStore.creators;
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const isCruiseExceptionUK = getIsCruiseExceptionUK(state);
  const country = getCountryCodeFromCurrency(state) || COUNTRIES.UNITED_STATES;
  let voyageType = getVoyageType(state) || VOYAGE_TYPE.OCEAN;
  if (voyageType === VOYAGE_TYPE.MIXED) {
    voyageType = VOYAGE_TYPE.OCEAN;
  }
  const { SAILING, SAILED, CANCELED, NO_BOOKINGS } = VOYAGE_STATUSES;
  const { EXPEDITION, MISSISSIPPI } = VOYAGE_TYPE;
  const pages = {
    [SAILING]: 'cruisingSplashPage',
    [SAILED]: 'noActiveBookings',
    [CANCELED]: 'bookingCancelled',
    [NO_BOOKINGS]: 'noActiveBookings',
  };
  // TODO: This is temporarly update to get coming soon page content
  let pageName;
  const type = bookingDetails?.voyage?.type;
  if (
    (voyageType === EXPEDITION && ![SAILING, SAILED, CANCELED, NO_BOOKINGS].includes(pathname)) ||
    (type === MISSISSIPPI && !ALLOW_MISSISSIPPI) ||
    isCruiseExceptionUK
  ) {
    pageName = 'comingSoonVXP';
    voyageType = EXPEDITION;
  } else {
    pageName = pages[pathname];
  }

  const endpoint = buildUrl(
    '/pages',
    ['pageName', 'voyageType'],
    {
      voyageType,
      pageName,
    },
    {
      country: country || COUNTRIES.UNITED_STATES,
    }
  );

  dispatch(
    getData({
      url: endpoint,
      store: splashPageStore,
      node: 'splashPage',
      creator: receiveContent,
    })
  );
};

export default splashPageStore;
