import FaqPage from '@viking-eng/faq-page';
import PropTypes from '@viking-eng/prop-types';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { CHAT_HOVER_TEXT, FAQ_CATEGORIES } from '../../common/Constants';
import { handleCmsLink } from '../../common/Utils';
import { PageHeader } from '../../components';
import ChatAvailableButton from '../../components/chatAvailable/ChatAvailableButton';
import SearchBar from '../../components/forms/searchBar/SearchBarContainer';
import './Help.scss';

const findDeeplinkCategory = (categorySlug, categories = []) => {
  const categoryIndex = categories.findIndex((c) => kebabCase(c.name) === categorySlug);
  const category = categories[categoryIndex];
  return [categoryIndex, category];
};

class Help extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      match: { params },
      categories,
      handleCategoryChange,
    } = this.props;
    if (!prevProps.categories.length && categories.length) {
      const category = findDeeplinkCategory(params.category, categories);
      if (category[0] >= 0) {
        handleCategoryChange(...category);
      }
    }
  }

  render() {
    const {
      match: { url },
      title,
      backButton: { label },
      categories,
      handleCategoryChange,
      handleDropdownChange,
      heading,
      searchResults,
      isLoading,
      handleSearchFaq,
      handleClearSearch,
      isTaBooking,
    } = this.props;
    const filterOutPaymentUrlQuestion = (arr) => {
      return arr?.reduce((acc, q) => {
        if (!q?.answer?.includes(FAQ_CATEGORIES.PAYMENTS_URL)) {
          acc.push(q);
        }
        return acc;
      }, []);
    };
    const categoriesFiltered = categories.map((item) => {
      const { questions } = item;
      if (item.name === FAQ_CATEGORIES.TOP_QUESTIONS && isTaBooking) {
        const filteredQuestion = filterOutPaymentUrlQuestion(questions);
        return {
          ...item,
          questions: filteredQuestion,
        };
      } else {
        return item;
      }
    });
    const searchResultsQuestions = searchResults?.questions;
    const searchResultsQuestionsFiltered = filterOutPaymentUrlQuestion(searchResultsQuestions);
    const searchResultsCountFilteredArr = searchResults?.name?.split(' ');
    const searchResultsCountFiltered = `${searchResultsQuestionsFiltered?.length} ${searchResultsCountFilteredArr?.[1]}`;

    const searchResultsFiltered =
      isTaBooking && searchResults?.questions?.length > 0
        ? { name: searchResultsCountFiltered, questions: searchResultsQuestionsFiltered }
        : searchResults;

    return (
      <div id="helpPage" className="help-page">
        <PageHeader backButton={label} hasHomeLink hasLine title={title} url={url} isLoading={isLoading} />
        <div role="none" onClick={handleCmsLink}>
          {!isLoading && (
            <>
              <SearchBar handleSearchFaq={handleSearchFaq} handleClear={handleClearSearch} />
              <FaqPage
                categories={categoriesFiltered}
                searchResults={searchResultsFiltered}
                contentsHeading={heading}
                onCategoryChange={handleCategoryChange}
                onDropdownChange={handleDropdownChange}
                contentUnderTableOfContents={
                  <ChatAvailableButton
                    className="under-toc d-none d-md-block"
                    tooltip={CHAT_HOVER_TEXT}
                    hideIfUnavailable
                  />
                }
                contentUnderQuestions={
                  <ChatAvailableButton className="under-qs d-md-none" tooltip={CHAT_HOVER_TEXT} hideIfUnavailable />
                }
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

Help.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      category: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.string,
  heading: PropTypes.string,
  backButton: PropTypes.pageHeaderBackButton,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  handleCategoryChange: PropTypes.func.isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  searchResults: PropTypes.shape({
    name: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      })
    ),
  }),
  handleSearchFaq: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
};

Help.defaultProps = {
  title: '',
  heading: '',
  backButton: {
    label: '',
    previousPagePath: '',
  },
  categories: [],
  searchResults: null,
};
export default Help;
