import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import moment from 'moment';
import { createSelector } from 'reselect';
import commonStore, { modalStore, userStore } from '../../common';
import { getData, putData } from '../../common/Api';
import { addToCart } from '../../common/CommonStore';
import {
  AIR_CARDS,
  AIR_PLUS_REFERENCE,
  AIR_PLUS_RESERVATION_STATES,
  BOOKING_PACKAGE_TYPE,
  COUNTRIES,
  FORMS,
  INCLUDED_STATEROOM,
  NONE,
  PAGE_NAMES,
  PASSENGERS_RESERVATION_STATUS,
  REGIONAL_LONG_DATES,
  RESERVATION_STATUS,
  TAB_NAMES,
  TWO_BY_ONE,
} from '../../common/Constants';
import { ERROR_CODES } from '../../common/forms/Validations';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import { reloadBookings } from '../../common/UserStore';
import {
  buildUrl,
  convertStringToStartCase,
  formatMoney,
  getCarouselImageArray,
  getCmsLabel,
  getPageTabUrl,
  getPassengerAbbrevName,
  getReservationStatuses,
  getTabReference,
  replaceCMSTokenWithValue,
  sortAlphaByField,
} from '../../common/Utils';

const { UNITED_KINGDOM, AUSTRALIA } = COUNTRIES;
const { AIR } = PAGE_NAMES;
const { AIR_BOOKING, AIR_PREFERENCES, TRANSFERS } = TAB_NAMES;
const {
  selectors: { getErrors, getPageTabLabels, getReduxFormError, getReservationModalInfo, getTabUrl, getIsUKAUNZ },
  types: { CART_UPDATED },
} = commonStore;

const {
  selectors: {
    getBookingDetails,
    getCountryCodeFromCurrency,
    getSessionId,
    getIsApprovedForPurchases,
    getDaysToGo,
    getUpdateUserData,
    getFeatureRestricted,
    getPassengerNames,
    getIsDirect,
  },
} = userStore;

const { getModalData } = modalStore.selectors;

export const ERROR_CODE_MAPPING = {
  [ERROR_CODES.INVALID]: 'GuestRequired',
  [ERROR_CODES.XHR_FAILED]: 'AddToCartFailed',
};

const getHasAirPlusForPassenger = (passenger) => {
  const { hasAirPlus } = passenger;

  if (typeof hasAirPlus === 'boolean') {
    return hasAirPlus;
  }

  if (hasAirPlus === AIR_PLUS_RESERVATION_STATES.NONE) {
    return false;
  }

  // Requested, Confirmed, Booked, etc.
  return true;
};

const getMessage = (messages = [], reference, variable) => {
  let text = getCmsLabel(messages, reference, 'text');
  if (variable) {
    text = replaceCMSTokenWithValue(text, [variable]);
  }
  return text;
};

const mapReservationStatusToMessage = ({
  passengerIndex,
  reservationStatus,
  allOpen,
  errorMessage,
  messages,
  isAirInCart,
}) => {
  const { OPEN, RESERVED } = RESERVATION_STATUS;
  if (reservationStatus === RESERVED) {
    return getMessage(messages, 'reservationConfirmedLabel');
  }
  if (reservationStatus === OPEN && !allOpen) {
    return errorMessage;
  }
  if (isAirInCart[passengerIndex]) {
    return getMessage(messages, 'vikingAirPlusInCartRequiresPayment');
  }
  return '';
};

const airStore = createPageTabsDuck('air').extend({
  types: ['RECEIVE_AIR_PREFERENCES'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_AIR_PREFERENCES:
        return {
          ...state,
          data: action.payload,
          loaded: true,
        };
      case CART_UPDATED:
        return {
          ...state,
          airBooking: null,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveAirPreferences: (payload) => ({
      type: types.RECEIVE_AIR_PREFERENCES,
      payload,
    }),
  }),
  selectors: {
    getAirBookingCards: new Selector(({ getTabContent, getAirScheduleData }) =>
      createSelector(
        [(state) => getTabContent(state)(TAB_NAMES.AIR_BOOKING), getBookingDetails, getAirScheduleData],
        (tabContent, { packageType, airViewership }, airSchedule) => {
          const content = tabContent;
          let sectionCards = get(content, 'sections.0.cards', []);
          const cardToRemove = [];
          if (content.loaded) {
            switch (packageType) {
              case BOOKING_PACKAGE_TYPE.CRUISE_ONLY: {
                cardToRemove.push(AIR_CARDS.AIR_PLUS_CARD_REFERENCE);
                cardToRemove.push(AIR_CARDS.CLASS_UPGRADE_CARD_REFERENCE);
                break;
              }
              case BOOKING_PACKAGE_TYPE.PACKAGE: {
                if (!airViewership) {
                  cardToRemove.push(AIR_CARDS.VIKING_AIR_CARD_REFERENCE);
                } else {
                  cardToRemove.push(AIR_CARDS.VIKING_AIR_CARD_REFERENCE);
                  cardToRemove.push(AIR_CARDS.AIR_PLUS_CARD_REFERENCE);
                  cardToRemove.push(AIR_CARDS.CLASS_UPGRADE_CARD_REFERENCE);
                }
                break;
              }
              default:
            }
          }

          // TODO: Display message saying when itinerary is available
          // (when no upgrade cards are available but air is included)
          sectionCards = sectionCards
            .filter((card) => !cardToRemove.includes(card.reference))
            .map((card) => ({ ...card, subTitle: card.subtitle }));
          set(content, 'sections.0.cards', sectionCards);
          if (airSchedule.referenceNumber) {
            set(content, 'sections.0.cards', []);
          }
          const finalCards = get(content, 'sections[0].cards');
          if (finalCards && finalCards.length === 0) {
            set(content, 'sections[0].title', undefined);
          }
          return content;
        }
      )
    ),
    getAirScheduleData: new Selector(() =>
      createSelector(
        [
          getBookingDetails,
          (state) => get(state, `air.${AIR_BOOKING}.content`, {}),
          (state) => getPageTabLabels(state)(AIR, AIR_BOOKING),
          getBookingDetails,
          getIsUKAUNZ,
        ],
        (
          booking,
          content,
          {
            buttons: { manageFlight },
            labels: {
              inclusiveAir,
              airPlus,
              referenceNum: referenceNumberLabel,
              stopover,
              flight,
              departs,
              arrives,
              passengers: passengersLabel,
              seats,
              eTicketNum,
              airlineBookingNumber,
              unavailable,
            },
          },
          { ship: { stateroomCategory } },
          isUKAUNZ
        ) => {
          const passengerNames = booking.passengers.map((passenger) => ({
            fullName: `${passenger.firstName} ${passenger.middle} ${passenger.lastName} ${passenger.suffix}`,
            passengerNumber: passenger.passengerNumber,
          }));
          const airSchedules = get(content, 'airSchedules', []);
          const messages = get(content, 'messages', []);
          let segmentDate;
          const longDateFormat = isUKAUNZ ? REGIONAL_LONG_DATES.EU : REGIONAL_LONG_DATES.NA;
          const { passengers, ...airSchedulesByDate } = airSchedules
            .map((segment) => ({
              ...segment,
              flight: {
                ...segment.flight,
                flightNumber: getMessage(messages, 'airFlightNumber', {
                  key: 'FLIGHT_NUMBER',
                  value: segment.flight.flightNumber,
                }),
                airlineBookingNumber: `${airlineBookingNumber}: ${segment.flight.airlineBookingNumber}`,
              },
              departs: {
                ...segment.departs,
                date: moment(segment.departs.date).format(longDateFormat),
                time: moment(`${segment.departs.date} ${segment.departs.time}`).format('hh:mm A'),
              },
              arrives: {
                ...segment.arrives,
                date: moment(segment.arrives.date).format(longDateFormat),
                time: moment(`${segment.arrives.date} ${segment.arrives.time}`).format('hh:mm A'),
              },
              passengers: segment.passengers.map((segmentPassenger) => ({
                ...segmentPassenger,
                name: passengerNames.find(
                  (bookingPassenger) =>
                    /* eslint-disable-next-line implicit-arrow-linebreak */
                    bookingPassenger.passengerNumber === segmentPassenger.passengerNumber
                ).fullName,
              })),
              seats: segment.seats.map((seat) => ({
                ...seat,
                airClassDescription: convertStringToStartCase(seat.airClassDescription),
                seat: seat.seat.trim()
                  ? getMessage(messages, 'airSeatNumber', { key: 'SEAT_NUMBER', value: seat.seat })
                  : '',
              })),
            }))
            .reduce(
              (acc, schedule) => {
                if (!schedule.isSegment) {
                  segmentDate = schedule.departs.date;
                }
                if (!acc[segmentDate]) {
                  acc[segmentDate] = [schedule];
                } else {
                  acc[segmentDate].push(schedule);
                }
                schedule.passengers.forEach((passenger) => {
                  if (!acc.passengers[passenger.passengerNumber] && passenger.eTicket) {
                    acc.passengers.push(passenger);
                  }
                });
                return acc;
              },
              { passengers: [] }
            );

          const eTicketNumbers = passengerNames.map(({ fullName, passengerNumber }) => {
            const { eTicket = unavailable } = passengers.find((p) => p.passengerNumber === passengerNumber) || {};
            return eTicket
              ? eTicket.split('\\').map((number) => ({
                  name: fullName,
                  eTicket: `${eTicketNum} ${number} \u2013 ${fullName}`,
                }))
              : {};
          });
          const referenceNumber = get(airSchedules, '[0].flight.referenceNumber');
          let pageDescription = getMessage(messages, 'airNoVikingAirCopy');
          if (booking.packageType === BOOKING_PACKAGE_TYPE.CRUISE_ONLY) {
            pageDescription = getMessage(messages, 'airNoVikingAirCopy');
          } else if (booking.packageType === BOOKING_PACKAGE_TYPE.PACKAGE && booking.airViewership) {
            pageDescription = getMessage(messages, 'airWithVikingAirCopy');
          } else if (booking.packageType === BOOKING_PACKAGE_TYPE.PACKAGE && !booking.airViewership) {
            pageDescription = getMessage(messages, 'airWithVikingAirNoQaCopy');
          }
          if (
            stateroomCategory === INCLUDED_STATEROOM.EXPLORER_SUITE ||
            stateroomCategory === INCLUDED_STATEROOM.VERANDA_SUITE
          ) {
            pageDescription = pageDescription.replace('Your purchase of ', '');
          }

          /*
          To add footer text, just add the following to the returned object:
          footerText: getMessage(messages, 'airBookingDisclaimer'),
        */

          const passengerHasAirPlus = booking.passengers.filter(getHasAirPlusForPassenger).some((p) => !!p);
          return {
            pageDescription,
            pageDescriptionBackUp: getMessage(messages, 'airItineraryUnavailable'),
            referenceNumber: referenceNumber && `${referenceNumberLabel}: ${referenceNumber}`,
            byDate: airSchedulesByDate,
            labels: {
              manageFlight,
              seats,
              passengers: passengersLabel,
              departs,
              arrives,
              flight,
              stopover,
              title: passengerHasAirPlus ? airPlus : inclusiveAir,
            },
            eTicketNumbers: eTicketNumbers.flat(),
          };
        }
      )
    ),
    getAirModalData: new Selector((selectors) => {
      const { getTabContent } = selectors;
      return createSelector(
        [
          getDaysToGo,
          getModalData,
          (state) => getTabContent(state)(AIR_BOOKING),
          getCountryCodeFromCurrency,
          getReduxFormError,
          getReservationModalInfo,
          getIsApprovedForPurchases,
          (state) => getPageTabLabels(state)(AIR, AIR_BOOKING),
          (state) => get(state, 'user.cart.isAirInCart'),
          getBookingDetails,
          getIsDirect,
        ],
        (
          daysToGo,
          modalData,
          { sections: airBookingSections },
          countryCode,
          getErrorMessage,
          { state: modalState, metadata: modalMetadata },
          isApprovedForPurchase,
          { buttons, labels },
          isAirInCart,
          { passengers, ship },
          isDirect
        ) => {
          const { print } = buttons;
          const { stateroomCategory } = ship || {};
          const { perPerson, airPlusNotAvailable, reservationConfirmed, airPlusIncluded } = labels;
          const errorMessage = getErrorMessage(ERROR_CODE_MAPPING, FORMS.BOOKING);
          const defaultValues = {
            title: '',
            subtitle: '',
            sections: [],
          };

          const modalId = modalData.id;
          if (!modalId) {
            return defaultValues;
          }
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const cards = get(airBookingSections.find(findSection), 'cards', null) || (modalData.modal && [modalData]);

          if (!cards) {
            return defaultValues;
          }
          const isAirPlusIncluded =
            stateroomCategory === INCLUDED_STATEROOM.EXPLORER_SUITE ||
            stateroomCategory === INCLUDED_STATEROOM.VERANDA_SUITE;

          const {
            id,
            reference,
            images,
            modal: { title, sections, variables, singlePrice, serviceCode, extensionType, inventoryCode },
            reservationStatus,
          } = cards.find(findCard);

          let bookingModalContent = {};
          let isReservationAllowed = true;
          let lockdownMessage;
          const airPlusUKAU = getMessage(variables, 'airPlusMessage');
          const airPlusUKAUMessage = replaceCMSTokenWithValue(
            airPlusUKAU,
            variables.map((v) => ({ key: v.reference, value: v.text }))
          );
          let subtitle;
          switch (reference) {
            case AIR_CARDS.AIR_PLUS_CARD_REFERENCE:
              subtitle = getMessage(variables, 'contactReservations');
              break;
            case AIR_CARDS.CLASS_UPGRADE_CARD_REFERENCE:
              subtitle = getMessage(variables, 'classUpgradesCopy');
              break;
            default:
              subtitle = getMessage(variables, 'vikingAirContact');
              break;
          }

          if (reference === AIR_PLUS_REFERENCE) {
            const modalImages = getCarouselImageArray({
              images: [images],
              imageRatioPriorities: [TWO_BY_ONE],
            });
            let subText = getMessage(variables, 'vikingAirPlusIncludedLabel');
            if (!isApprovedForPurchase) {
              isReservationAllowed = false;
              lockdownMessage = isDirect
                ? getMessage(variables, 'airAirPlusInvalidStatus')
                : getMessage(variables, 'invalidStatusTA');
            }
            if (singlePrice) {
              subText = replaceCMSTokenWithValue(getMessage(variables, 'vikingAirPlusPricePerPerson'), [
                {
                  key: 'VIKING_AIR_PLUS_PRICE',
                  value: formatMoney(singlePrice, 0, countryCode),
                },
              ]);
            }
            if (daysToGo <= 65) {
              isReservationAllowed = false;
              lockdownMessage = airPlusNotAvailable;
            }
            const { EVERY_OPEN, EVERY_RESERVED } = PASSENGERS_RESERVATION_STATUS;
            const statuses = getReservationStatuses(reservationStatus);

            // check if the excursion is OPEN for all the guests
            const allOpen = statuses.includes(EVERY_OPEN);
            const allReserved = statuses.includes(EVERY_RESERVED);

            const displayPassengers = passengers.reduce((acc, passenger, idx) => {
              let status = get(reservationStatus, `[${idx}]`, '');
              if (isAirPlusIncluded) {
                status = RESERVATION_STATUS.RESERVED;
              }
              const statusMessage = mapReservationStatusToMessage({
                passengerIndex: idx,
                reservationStatus: status,
                allOpen,
                allReserved,
                messages: variables,
                errorMessage,
                isAirInCart,
              });

              acc.push({
                key: passenger.passengerNumber,
                guestName: getPassengerAbbrevName(passenger),
                reservationMessage: statusMessage,
                status,
                reservedExcursion: {
                  singlePrice,
                },
              });

              return acc;
            }, []);
            let alertText = lockdownMessage;
            if (allReserved || (inventoryCode && inventoryCode === '995' && singlePrice === 0)) {
              alertText = reservationConfirmed;
            }
            bookingModalContent = {
              images: modalImages,
              sections: sections.map(({ longText, ...section }) => ({
                ...section,
                description: longText,
              })),
              subtitle,
              subText: !allReserved ? subText : '',
              variables,
              sideContent: {
                title,
                serviceCode,
                extensionType,
                inventoryCode,
                pricePerPassenger: singlePrice,
                subText: !allReserved ? subText : '',
                modalState,
                metadata: modalMetadata,
                passengers: displayPassengers,
                isReservationAllowed,
                alertText: isAirPlusIncluded ? airPlusIncluded : alertText,
                isUKAU: countryCode === COUNTRIES.UNITED_KINGDOM || countryCode === COUNTRIES.AUSTRALIA,
                airPlusUKAUMessage,
              },
            };
          }
          let airPlusSubText;
          if (isAirPlusIncluded) {
            airPlusSubText = getMessage(variables, 'vikingAirPlusIncludedLabel');
          } else {
            airPlusSubText = replaceCMSTokenWithValue(perPerson, [
              { key: 'PRICE', value: formatMoney(singlePrice, 0, countryCode) },
            ]);
          }
          if (countryCode === COUNTRIES.UNITED_KINGDOM || countryCode === COUNTRIES.AUSTRALIA) {
            airPlusSubText = '';
          }
          return {
            id,
            title,
            subtitle: replaceCMSTokenWithValue(
              subtitle,
              variables.map((v) => ({ key: v.reference, value: v.text }))
            ),
            sections,
            printLabel: print,
            ...bookingModalContent,
            subText: airPlusSubText,
          };
        }
      );
    }),
    getAvailablePreferencesTabContent: new Selector((selectors) => {
      const { getTabContent } = selectors;
      return createSelector(
        [
          (state) => getPageTabLabels(state)(AIR, AIR_PREFERENCES),
          getErrors,
          getBookingDetails,
          (state) => getTabContent(state)(AIR_PREFERENCES),
          getFeatureRestricted,
          getPassengerNames,
        ],
        (
          { buttons: { addAnother, cancel, update }, labels },
          errorsMessages,
          { passengers },
          tabContent,
          featureRestricted,
          passengerNames
        ) => {
          const {
            loaded,
            availableSeatings: availablePassengerSeating = [],
            seperateSeating: isSeperatePassengerSeating = false,
            ...availablePreferences
          } = tabContent;
          const title = get(availablePreferences.forms, '[0].title');
          const passengerPreferences = {};

          const findSeatByReference = (list, key, ref) => list.find((item) => item[key] === ref);
          const isSinglePassenger = passengers.length === 1;
          const seatings = get(availablePreferences.forms, '[0].fields', []);
          const passengerSeating = availablePassengerSeating.map((seat) =>
            findSeatByReference(seatings, 'htmlName', seat.seating)
          );

          let availableSeatings = [];
          if (passengerSeating[0]) {
            availableSeatings = passengerSeating.map(({ label = '', imageUrl, htmlName = '' }) => ({
              label,
              imageUrl,
              id: htmlName,
            }));
          }

          const labelValuePreferences = [
            {
              name: 'airline',
              label: 'name',
              value: 'code',
            },
            {
              name: 'wheelchairAssistance',
              label: 'description',
              value: 'code',
            },
            {
              name: 'specialMeal',
              label: 'description',
              value: 'code',
            },
            {
              name: 'meetAssist',
              label: 'meetAndAssist',
              value: 'meetAndAssist',
            },
            {
              name: 'redressNumber',
            },
            {
              name: 'frequentFlyerNumber',
            },
            {
              name: 'knownTravelerNumber',
            },
          ];

          if (Array.isArray(availablePreferences.forms)) {
            labelValuePreferences.forEach(({ label, name, value }) => {
              availablePreferences.forms.forEach(({ fields }) => {
                fields.forEach((field) => {
                  if (field.htmlName === name.toLowerCase()) {
                    const sourceData = field.options;
                    if (Array.isArray(sourceData)) {
                      if (name === 'airline') {
                        sortAlphaByField(sourceData, 'name');
                      }
                      const noneElement = {
                        label: NONE,
                        value: null,
                      };
                      passengerPreferences[name] = {
                        label: field.label,
                        options: [
                          ...(name !== 'airline' ? [noneElement] : []),
                          ...sourceData.map((data) => ({
                            label: name === 'specialMeal' ? convertStringToStartCase(data[label]) : data[label],
                            value: data[value],
                          })),
                        ],
                        tooltip: field.tooltip || '',
                      };
                    } else {
                      passengerPreferences[name] = {
                        // fallback for cases where data is partially absent due to Core returning timeout with 200 status (AWS)
                        ...(label || value ? { options: [] } : {}),
                        label: field.label,
                        tooltip: field.tooltip || '',
                      };
                    }
                  }
                });
              });
            });
          }

          const initialValues = {
            passengers: [],
          };

          // init values
          const preferencesInits = [];
          passengers.forEach(({ airPreferences }, index) => {
            const passengerInits = {};
            if (airPreferences) {
              if (index === 0) {
                merge(initialValues, { seat: airPreferences.seat });
              }
              passengerInits.redressNumber = airPreferences.redress;
              passengerInits.knownTravelerNumber = airPreferences.knownTravelerNumber;
              if (Array.isArray(airPreferences.frequentFlyers)) {
                if (airPreferences.frequentFlyers.length) {
                  const filteredFFNs = airPreferences.frequentFlyers.filter(
                    (val, idx, arr) => arr.findIndex((t) => t.airlineCode === val.airlineCode) === idx
                  );
                  const frequentFlyerNumber = filteredFFNs[0];
                  passengerInits.frequentFlyerNumber = frequentFlyerNumber.frequentFlyerNumber;
                  passengerInits.airline = frequentFlyerNumber.airlineCode;

                  passengerInits.frequentFlyers = filteredFFNs;
                } else {
                  passengerInits.frequentFlyers = [{ sequence: 1 }];
                }
              }
              passengerInits.meal = airPreferences.meal;
              passengerInits.meetAndAssist = airPreferences.meetAndAssist;
              passengerInits.wheelchair = airPreferences.wheelchair;
              passengerInits.seat = airPreferences.seat;
            } else {
              passengerInits.frequentFlyers = [{ sequence: 1 }];
            }
            preferencesInits.push(passengerInits);
          });

          merge(initialValues, { passengers: preferencesInits });

          // Add dash option for frequent flyer airlines to de-select
          if (passengerPreferences.airline && passengerPreferences.airline.options) {
            passengerPreferences.airline.options = [
              {
                label: '\u2013',
                value: '',
              },
              ...passengerPreferences.airline.options,
            ];
          }

          return {
            initialValues,
            loaded,
            availablePreferences: {
              availableSeatings,
              passengerPreferences,
            },
            passengerNames,
            labels: {
              cancel,
              update,
              success: labels.airPreferencesUpdatedMessage,
              addAnother,
              error: errorsMessages.AirUpdateFailed,
            },
            title,
            lockedDown: featureRestricted,
            isSinglePassenger,
            isSeperatePassengerSeating,
          };
        }
      );
    }),
    getCustomizableTabs: new Selector(({ getTabs }) =>
      createSelector(
        [getBookingDetails, getTabs, getCountryCodeFromCurrency, getTabUrl],
        ({ packageType }, airTabs, countryCode, getTabUrlFunction) => (location, match) => {
          const tabs = airTabs(location.pathname, match.path);
          // check for package type to hide air preferences for cruise only bookings
          const isCruiseOnly = packageType === BOOKING_PACKAGE_TYPE.CRUISE_ONLY;
          let tabToRemove;
          // find index to remove by tab's name
          // TODO: use reference or ID to remove tabs
          if (isCruiseOnly || [AUSTRALIA, UNITED_KINGDOM].includes(countryCode)) {
            tabToRemove = getTabUrlFunction(AIR, AIR_PREFERENCES);
          } else {
            tabToRemove = getTabUrlFunction(AIR, TRANSFERS);
          }

          const index = tabs.findIndex((t) => t.url.includes(tabToRemove));

          if (index !== -1) {
            tabs.splice(index, 1);
          }
          return { tabs, isCruiseOnly };
        }
      )
    ),
  },
});

export const fetchAirPageContent = () => (dispatch, getState) => {
  const { receiveContent } = airStore.creators;
  const url = buildUrl('/pages/air', ['voyage.type'], getBookingDetails(getState()));
  dispatch(
    getData({
      url,
      store: airStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const fetchTabContent = (tab, refreshData) => (dispatch, getState) => {
  const tabUrl = getPageTabUrl(AIR, tab);
  const tabName = getTabReference(tabUrl);

  const { receiveTabContent } = airStore.creators;
  const bookingDetails = getBookingDetails(getState());
  const { office, currency } = bookingDetails;
  const data = {
    ...bookingDetails,
    airPlusPassenger1: false,
    airPlusPassenger2: false,
  };

  // hasAirPlus can be a boolean or string (i.e. "None", "Requested"),
  // we need to convert this state to a boolean for MT.
  bookingDetails.passengers.forEach((passenger) => {
    data[`airPlusPassenger${passenger.passengerNumber}`] = getHasAirPlusForPassenger(passenger);
  });
  const url = buildUrl(
    `/air/${office}/${currency}/${tabName}`,
    ['voyage.type', 'bookingNumber', 'packageType', 'airPlusPassenger1', 'airPlusPassenger2', 'ship.shipCode'],
    data
  );
  return dispatch(
    getData({
      url,
      store: airStore,
      node: `${tab}.content`,
      creator: receiveTabContent,
      refreshData,
      tab,
    })
  );
};

const mapAirPreferences = (preferences, { voyage, passengers }) => ({
  voyageId: voyage.id,
  passengers: passengers.map((passenger, index) => {
    const { title, passengerNumber, firstName, lastName, gender } = passenger;
    let seatMapped = '';
    let frequentFlyersMapped = [];
    let knownTravelerNumberMapped = '';
    let redressNumberMapped = '';
    let meetAndAssistMapped = '';
    let mealMapped = '';
    let wheelchairMapped = '';

    if (preferences[index]) {
      const { seat, frequentFlyers, knownTravelerNumber, redressNumber, meetAndAssist, meal, wheelchair } = preferences[
        index
      ];

      seatMapped = seat || seatMapped;
      frequentFlyersMapped = frequentFlyers || frequentFlyersMapped;
      knownTravelerNumberMapped = knownTravelerNumber || knownTravelerNumberMapped;
      redressNumberMapped = redressNumber || redressNumberMapped;
      meetAndAssistMapped = meetAndAssist || meetAndAssistMapped;
      mealMapped = meal || mealMapped;
      wheelchairMapped = wheelchair || wheelchairMapped;
    }

    return {
      passengerNumber,
      title,
      gender,
      firstName,
      lastName,
      middleName: '',
      suffix: '',
      airPreferences: {
        seat: seatMapped,
        redressNumber: redressNumberMapped,
        frequentFlyers: frequentFlyersMapped,
        knownTravelerNumber: knownTravelerNumberMapped,
        meetAndAssist: meetAndAssistMapped,
        meal: mealMapped,
        wheelchair: wheelchairMapped,
      },
    };
  }),
});

export const updatePassengerAirPreferences = (preferences) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);

  const sessionId = getSessionId(state);

  const url = buildUrl('/air/put/preferences', ['voyage.type', 'bookingNumber'], bookingDetails);
  const payload = {
    ...mapAirPreferences(preferences, bookingDetails),
    sessionId,
    updateUserInfo: getUpdateUserData(state),
  };

  return dispatch(
    putData({
      url,
      values: payload,
    })
  ).then(async (response) => {
    if (response.isSuccessful) {
      await dispatch(reloadBookings());
    }
    return response;
  });
};

export const addAirPlusToCart = (values) => (dispatch, getState) => {
  const bookingDetails = getBookingDetails(getState());
  return dispatch(
    addToCart({
      payload: {
        ...values,
        calendarId: bookingDetails.calendarId,
        saveForLater: false,
        voyageID: bookingDetails.voyage.id,
        isWaitlist: false,
      },
    })
  );
};
export default airStore;
