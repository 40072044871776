import Button from '@viking-eng/button/lib/Button';
import FormActions from '@viking-eng/form-actions';
import React from 'react';
import { PreAuthWrapper } from '..';
import { APP_PATHS, FOOTER_MODALS } from '../../common/Constants';
import history from '../../common/history';
import { getCmsLabel, prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './PreAuthInterstitial.scss';

class AuthInterstitial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  componentDidMount() {
    const {
      location: { pathname },
      variables,
    } = this.props;

    switch (pathname) {
      case APP_PATHS.VERIFY_EMAIL: {
        const username = getCmsLabel(variables, 'USER_EMAIL', 'value', 'key');
        if (!username) {
          history.push(APP_PATHS.INDEX);
        }
        break;
      }
      case APP_PATHS.FORGOT_EMAIL_RECOVERED: {
        const emails = getCmsLabel(variables, 'EMAILS', 'value', 'key');
        if (!emails) {
          history.push(APP_PATHS.INDEX);
        }
        break;
      }
      case APP_PATHS.FORGOT_PASSWORD_EXPIRED:
      case APP_PATHS.EMAIL_EXPIRED: {
        const username = getCmsLabel(variables, 'EMAIL', 'value', 'key');
        if (!username) {
          history.push(APP_PATHS.INDEX);
        }
        break;
      }
      default:
    }
  }

  setSubmittingState(submitting) {
    this.setState({ submitting });
  }

  handleClick = () => {
    const { labels } = this.props;
    this.setState({ error: null });
    this.setSubmittingState(true);

    if (labels.callToActionUrl) {
      history.push(labels.callToActionUrl);
    }
  };

  render() {
    const { labels, handleModalOpen } = this.props;
    const { error, submitting } = this.state;
    if (!labels) {
      return null;
    }
    const body = null;

    return (
      <PreAuthWrapper className="preauth-interstitial" wide>
        <div className="header-wrapper">
          <h5 className="subheader">{labels.title}</h5>
        </div>
        {labels.text && <div className="content" dangerouslySetInnerHTML={prepareHtml(labels.text)} />}
        {body && (
          <div className="content">
            <div>{body.weFound}</div>
            {body.emails.map((email) => (
              <div>{email}</div>
            ))}
            <div>
              {body.stillUnsure}
              <Button
                attributes={{
                  role: 'link',
                }}
                appearance="link"
                onButtonClick={() => handleModalOpen(FOOTER_MODALS.CONTACT)}
              >
                {body.contactUs}
              </Button>
            </div>
          </div>
        )}
        <div className="interstitial-actions">
          {labels.callToActionTitle && (
            <FormActions
              primaryButton={{
                loading: submitting,
                text: labels.callToActionTitle,
                onButtonClick: this.handleClick,
              }}
              alert={error}
            />
          )}
        </div>
      </PreAuthWrapper>
    );
  }
}

AuthInterstitial.propTypes = {
  location: PropTypes.routerLocation.isRequired,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  labels: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    text: PropTypes.string,
    callToActionTitle: PropTypes.string,
  }),
  handleModalOpen: PropTypes.func.isRequired,
};

AuthInterstitial.defaultProps = {
  labels: null,
};

export default AuthInterstitial;
