import { prepareHtml } from '@viking-eng/utils';
import React from 'react';
import { Modal } from '..';
import PropTypes from '../../PropTypes';
import './CloseToSailing.scss';

const CloseToSailingModal = ({ id, closeToSailingModalData: { printLabel, title, body } }) => (
  <Modal id={id} printLabel={printLabel} title={title}>
    {title && (
      <div className="close-to-sailing-modal mvj-modal">
        <div className="row no-gutters modal-heading">
          <div className="title">
            <span>{title}</span>
          </div>
        </div>
        <div className="horizontal-separator" />
        <div className="modal-body" dangerouslySetInnerHTML={prepareHtml(body)} />
      </div>
    )}
  </Modal>
);

CloseToSailingModal.propTypes = {
  id: PropTypes.string.isRequired,
  closeToSailingModalData: PropTypes.shape({
    printLabel: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

CloseToSailingModal.defaultProps = {
  closeToSailingModalData: {
    printLabel: '',
    title: '',
    body: '',
  },
};

export default CloseToSailingModal;
