import Heading from '@viking-eng/heading';
import unescape from 'lodash/unescape';
import React from 'react';
import PropTypes from '../../PropTypes';

import './ModalHeader.scss';

const ModalHeader = ({ title, description }) => {
  const unescapedTitle = unescape(title);
  return (
    <div className="row no-gutters modal-heading">
      <div className="col center-header">
        <Heading title={unescapedTitle} subTitle={description} />
      </div>
    </div>
  );
};

ModalHeader.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ModalHeader;
