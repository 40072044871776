import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore, { userStore } from '../../common';
import { GEORGIAN_BAY_SHOREX, VOYAGE_TYPE } from '../../common/Constants';
import { getPagePath } from '../../common/Utils';
import { withContent } from '../../components';
import Shorex from './Shorex';
import shorexStore, {
  fetchExcursions,
  fetchShorexPageContent,
  handleCloseExcursionModal,
  handleOpenExcursionModal,
} from './ShorexStore';

const { getItinerary, getLinkQueryParams, getVoyageType, getIsGreatLakes } = userStore.selectors;
const {
  selectors: { getExcursionsOnDate, getShorexContent, getShorexModalData, isLoadingExcursions, getShorexDays },
} = shorexStore;

const {
  selectors: { getPaymentsAllEnabled, getPaymentsCheckoutEnabled, getMvjStrings },
} = commonStore;

const getDayContent = ({ content, isExpeditionLanding, voyageType, isGeorgianBayshorex, isGreatLakes }) => {
  if (voyageType === VOYAGE_TYPE.EXPEDITION) {
    if (isExpeditionLanding) {
      if (!content.bannerNotification && !(isGeorgianBayshorex && isGreatLakes)) {
        content.bannerNotification = content.landingDayAlert;
      }
    } else {
      content.subtitle = content.nonLandingDaySubtitle;
    }
  }
  return content;
};

const mapStateToProps = (state, { location: { pathname } }) => {
  const itinerary = getItinerary(state);
  const isGreatLakes = getIsGreatLakes(state);
  const date = getPagePath(pathname);
  const excursionDate = date.split('-').length === 3 ? date : itinerary[0].date;
  const isExpeditionLanding = itinerary.find((day) => day.date === excursionDate)?.isExpeditionLanding;
  const cityName = itinerary.find((day) => day.date === excursionDate)?.cityName;
  const isGeorgianBayshorex = cityName === GEORGIAN_BAY_SHOREX;
  const cards = getExcursionsOnDate(state)(date).excursions;
  const linkedServiceId = get(getLinkQueryParams(state), 'shoreExCode', '');
  const content = getShorexContent(state);
  const voyageType = getVoyageType(state);
  const navigationData = getShorexDays(state)(excursionDate, '/myjourney/shore-excursions');

  const { days } = navigationData;
  const activeDay = days.find((day) => day.active);
  if (!activeDay && days.length) {
    const selectedDay = moment(date, 'YYYY-MM-DD');
    if (isExpeditionLanding) {
      const daysBeforeSelection = days.filter((d) => moment(d.date, 'YYYY-MM-DD').isBefore(selectedDay)) || [];
      if (daysBeforeSelection.length > 0) {
        daysBeforeSelection[daysBeforeSelection.length - 1].active = true;
      } else {
        days[0].active = true;
      }
    } else {
      days[0].active = true;
    }
  }

  return {
    cards,
    content: getDayContent({ content, isExpeditionLanding, voyageType, isGeorgianBayshorex, isGreatLakes }),
    isLoading: isLoadingExcursions(state)(excursionDate),
    navigationData,
    reservationModalData: getShorexModalData(state, excursionDate),
    linkedServiceId,
    excursionDate,
    isPaymentsDisabled: !getPaymentsAllEnabled(state) || !getPaymentsCheckoutEnabled(state),
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch, { location: { pathname } }) => {
  let navDate = getPagePath(pathname);
  if (!moment(navDate).isValid()) {
    navDate = null;
  }
  return {
    fetchContent: () => dispatch(fetchShorexPageContent(navDate)),
    fetchExcursions: (date) => dispatch(fetchExcursions({ date })),
    handleCardDetailsClicked: (id, date) => {
      dispatch(handleOpenExcursionModal(id, date));
    },
    resetReservationModalState: () => dispatch(handleCloseExcursionModal()),
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Shorex);
