import Button from '@viking-eng/button';
import Spinner from '@viking-eng/spinner';
import React from 'react';

import './RequestInvoice.scss';

import { MODALS } from '../../../common/Constants';
import { RequestInvoiceModal } from '../../../modals';
import PropTypes from '../../../PropTypes';

const requestInvoiceModal = MODALS.REQUEST_INVOICE_MODAL;

const RequestInvoice = ({ openRequestInvoiceModal, requestInvoiceData, isLoading }) => {
  const { content } = requestInvoiceData;
  return (
    <div>
      {isLoading ? (
        <div className="row justify-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          <form className="request-invoice-form">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 col-md-6 col-lg-4" />
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 col-md-6 col-lg-4 actions">
                <div className="invoice-title h5 h4-sm">{content.title}</div>
                <div className="description-content">{content.requestInvoiceMessage}</div>
                <Button
                  className="invoice-button"
                  onButtonClick={() => openRequestInvoiceModal(requestInvoiceModal, 1)}
                  appearance="secondary-blue"
                >
                  {content.button}
                </Button>
                <RequestInvoiceModal id={MODALS.REQUEST_INVOICE_MODAL} />
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

RequestInvoice.propTypes = {
  openRequestInvoiceModal: PropTypes.func.isRequired,
  requestInvoiceData: PropTypes.shape({
    ...PropTypes.dialogModalFields,
  }),
  isLoading: PropTypes.bool.isRequired,
};

RequestInvoice.defaultProps = {
  requestInvoiceData: {},
};

export default RequestInvoice;
