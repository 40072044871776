import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import commonStore from '../../../../common/CommonStore';
import { APP_PATHS, CCI_STEPS, FORMS, MODALS, MVJ_FLAG_VARIABLES, TAB_PATHS } from '../../../../common/Constants';
import { setViewAndShowModal } from '../../../../common/ModalStore';
import { navigateTo } from '../../../../common/Utils';
import documentStore, { postBoardingPassEmail } from '../../DocumentsStore';
import PassengerSelector from './PassengerSelector';

const { getComfortCheckingTabContent, getGifPassenger, getCheckInComboError } = documentStore.selectors;
const { setComfortCheckInPassenger, setComfortCheckInStep, updateSelectedGuest } = documentStore.creators;

const { getFlagValue } = commonStore.selectors;

const mapStateToProps = (state, { passenger: { passengerNumber } }) => {
  return {
    content: getComfortCheckingTabContent(state),
    checkInComboError: get(getCheckInComboError(state), `passenger${passengerNumber}`, false),
    form: `${FORMS.BOARDING_PASS}-${passengerNumber}`,
    initialValues: {
      email: get(getGifPassenger(state)(passengerNumber), 'email', ''),
    },
    isEmailEnabled: getFlagValue(state)(MVJ_FLAG_VARIABLES.CCI_EMAIL_ENABLED),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleCheckInNow: (passengerNumber) => {
    dispatch(setComfortCheckInStep(CCI_STEPS.PERSONAL_INFO));
    dispatch(setComfortCheckInPassenger(passengerNumber));
    navigateTo(APP_PATHS.COMFORT_CHECK_IN_FORM);
  },
  handleShowModal: () => dispatch(setViewAndShowModal(MODALS.CONTACT_US)),
  handleGifRedirect: (passengerNumber) => {
    dispatch(updateSelectedGuest(passengerNumber));
    navigateTo(`${APP_PATHS.DOCUMENTS}/${TAB_PATHS.documents.guestInformation}`);
  },
});

const onSubmit = ({ email }, dispatch, { passenger: { passengerNumber } }) =>
  new Promise((resolve, reject) =>
    dispatch(postBoardingPassEmail(passengerNumber, email)).then((res) => {
      if (res && res.isSuccessful) {
        return resolve();
      }
      return reject(res);
    })
  );

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    onSubmit,
  })
);

export default enhance(PassengerSelector);
