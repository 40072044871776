import FormActions from '@viking-eng/form-actions';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import classNames from 'classnames';
import React from 'react';
import { Form } from 'redux-form';
import PropTypes from '../../../PropTypes';
import { COUNTRIES, MODALS } from '../../../common/Constants';
import { prepareHtml } from '../../../common/Utils';
import { dateMask, licenseNumberMask, passportNumberMask, stateMask } from '../../../common/forms/Masks';
import { ZipPostalCodeNormalizer } from '../../../common/forms/Normalizations';
import {
  ERROR_CODES,
  licenseExpired,
  licenseWillExpireSoon,
  passportWillExpireSoon,
  passportWillExpireSoonUKAU,
  phoneNumberValidation,
  validAddressLine1,
  validAddressLine2,
  validAge,
  validBirthDate,
  validBirthDateUKAU,
  validEmergencyAddressLine1,
  validEmergencyAddressLine2,
  validEmergencyContactName,
  validEmergencyContactRelation,
  validGifCity,
  validGifEmail,
  validGifEmailLength,
  validGifEmergencyState,
  validGifPostalCode,
  validGifState,
  validInsurancePolicyNumberLength,
  validInsuranceProviderNameLength,
  validLicenseExpirationDate,
  validLicenseNumber,
  validLicenseWillExpire,
  validPassportExpirationDate,
  validPassportExpirationDateUKAU,
  validPassportIssueDate,
  validPassportIssueDateUKAU,
  validPassportNumber,
  validPassportWillExpire,
  validPassportWillExpireUKAU,
} from '../../../common/forms/Validations';
import './GuestInfoForm.scss';
import OnBlurField from './onBlurField/OnBlurFieldContainer';

const {
  INVALID_EMAIL_FORMAT,
  INVALID_EMAIL_LENGTH,
  INVALID_BIRTHDATE_FORMAT,
  INVALID_BIRTHDATE_FORMAT_UK_AU,
  INVALID_DATE_AGE,
  INVALID_INSURANCE_POLICY_NUMBER_LENGTH,
  INVALID_INSURANCE_PROVIDER_LENGTH,
  INVALID_LICENSE_EXPIRES,
  INVALID_LICENSE_EXPIRED,
  INVALID_LICENSE_NUMBER,
  INVALID_LICENSE_WILL_EXPIRE,
  INVALID_PASSPORT_ISSUED,
  INVALID_PASSPORT_ISSUED_UK_AU,
  INVALID_PASSPORT_EXPIRES,
  INVALID_PASSPORT_EXPIRES_UK_AU,
  INVALID_PASSPORT_WILL_EXPIRE,
  INVALID_PASSPORT_NUMBER,
  INVALID_PASSPORT_NUMBER_LENGTH,
  INVALID_ADDRESS_LINE_1,
  INVALID_ADDRESS_LINE_2,
  INVALID_CITY_LENGTH,
  INVALID_EMERGENCY_NAME_FORMAT,
  INVALID_EMERGENCY_ADDRESS_LINE_1,
  INVALID_EMERGENCY_ADDRESS_LINE_2,
  INVALID_EMERGENCY_RELATION_FORMAT,
  INVALID_PHONE_NUMBER,
  INVALID_PHONE_NUMBER_US_CA,
  INVALID_PHONE_NUMBER_UK_AU,
  INVALID_MOBILE_NUMBER,
  INVALID_MOBILE_NUMBER_US_CA,
  INVALID_MOBILE_NUMBER_UK_AU,
  INVALID_ZIP_FORMAT_US,
  INVALID_ZIP_FORMAT_CA,
  INVALID_ZIP_FORMAT_UK,
  INVALID_ZIP_FORMAT_AUNZ,
  INVALID_ZIP_FORMAT,
  INVALID_ZIP_LENGTH,
  STATE_REQUIRED,
  STATE_REQUIRED_US,
  STATE_REQUIRED_CA,
  STATE_REQUIRED_UK,
  STATE_REQUIRED_AU,
  STATE_REQUIRED_NZ,
  WARNING_LICENSE_EXPIRES_SOON,
  WARNING_PASSPORT_EXPIRES_SOON,
} = ERROR_CODES;

const dialogModalId = MODALS.DIALOG;

const STATE_ERRORCODES = {
  [STATE_REQUIRED]: STATE_REQUIRED,
  [STATE_REQUIRED_US]: STATE_REQUIRED_US,
  [STATE_REQUIRED_CA]: STATE_REQUIRED_CA,
  [STATE_REQUIRED_UK]: STATE_REQUIRED_UK,
  [STATE_REQUIRED_AU]: STATE_REQUIRED_AU,
  [STATE_REQUIRED_NZ]: STATE_REQUIRED_NZ,
};

const ZIP_POSTAL_ERRORCODES = {
  [INVALID_ZIP_FORMAT_US]: INVALID_ZIP_FORMAT_US,
  [INVALID_ZIP_FORMAT_CA]: INVALID_ZIP_FORMAT_CA,
  [INVALID_ZIP_FORMAT_UK]: INVALID_ZIP_FORMAT_UK,
  [INVALID_ZIP_FORMAT_AUNZ]: INVALID_ZIP_FORMAT_AUNZ,
  [INVALID_ZIP_FORMAT]: INVALID_ZIP_FORMAT,
  [INVALID_ZIP_LENGTH]: INVALID_ZIP_LENGTH,
};

const PHONE_NUMBER_ERRORCODES = {
  [INVALID_PHONE_NUMBER]: INVALID_PHONE_NUMBER,
  [INVALID_PHONE_NUMBER_US_CA]: INVALID_PHONE_NUMBER_US_CA,
  [INVALID_PHONE_NUMBER_UK_AU]: INVALID_PHONE_NUMBER_UK_AU,
};

const MOBILE_NUMBER_ERRORCODES = {
  [INVALID_MOBILE_NUMBER]: INVALID_MOBILE_NUMBER,
  [INVALID_MOBILE_NUMBER_US_CA]: INVALID_MOBILE_NUMBER_US_CA,
  [INVALID_MOBILE_NUMBER_UK_AU]: INVALID_MOBILE_NUMBER_UK_AU,
};

const GuestInfoFormSection = ({ children, heading, tooltip }) => (
  <>
    <div className="row guest-info-section-header-wrapper">
      <div className="label col-lg-10">
        <div
          className={classNames('guest-info-section-header', { 'with-tooltip': tooltip })}
          dangerouslySetInnerHTML={prepareHtml(heading)}
        />
        {tooltip && <div className="guest-info-section-tooltip">{tooltip}</div>}
      </div>
    </div>
    <div className="row form-section-fields">{children}</div>
  </>
);

GuestInfoFormSection.propTypes = {
  children: PropTypes.jsx.isRequired,
  heading: PropTypes.string,
  tooltip: PropTypes.string,
};

GuestInfoFormSection.defaultProps = {
  heading: null,
  tooltip: '',
};

const FormColumn = ({ children }) => <div className="col-lg-5 col-md-6 col-xs-12">{children}</div>;

FormColumn.propTypes = {
  children: PropTypes.jsx.isRequired,
};

const DateField = ({ errors, name, placeholder, disabled, isUKAUNZ, required = true }) => {
  let validation;
  let warn = [];
  const errorCodes = {
    [INVALID_BIRTHDATE_FORMAT]: INVALID_BIRTHDATE_FORMAT,
    [INVALID_BIRTHDATE_FORMAT_UK_AU]: INVALID_BIRTHDATE_FORMAT_UK_AU,
    [INVALID_DATE_AGE]: INVALID_DATE_AGE,
    [INVALID_PASSPORT_ISSUED_UK_AU]: INVALID_PASSPORT_ISSUED_UK_AU,
    [INVALID_PASSPORT_ISSUED]: INVALID_PASSPORT_ISSUED,
    [INVALID_PASSPORT_EXPIRES]: INVALID_PASSPORT_EXPIRES,
    [INVALID_PASSPORT_EXPIRES_UK_AU]: INVALID_PASSPORT_EXPIRES_UK_AU,
    [INVALID_PASSPORT_WILL_EXPIRE]: INVALID_PASSPORT_WILL_EXPIRE,
    [WARNING_PASSPORT_EXPIRES_SOON]: WARNING_PASSPORT_EXPIRES_SOON,
  };

  switch (name) {
    case 'birthdate':
      validation = [isUKAUNZ ? validBirthDateUKAU : validBirthDate, validAge];
      break;
    case 'passportIssuedDate':
      if (required) {
        validation = [isUKAUNZ ? validPassportIssueDateUKAU : validPassportIssueDate];
      }
      break;
    case 'passportExpiresDate':
      validation = isUKAUNZ
        ? [validPassportExpirationDateUKAU, validPassportWillExpireUKAU]
        : [validPassportExpirationDate, validPassportWillExpire];
      warn = [isUKAUNZ ? passportWillExpireSoonUKAU : passportWillExpireSoon];
      break;
    default:
      break;
  }
  return (
    <OnBlurField
      name={name}
      component={InputText}
      inputAttributes={{ 'aria-label': placeholder, disabled }}
      placeholder={placeholder}
      validate={validation}
      warn={warn}
      errorCodes={errorCodes}
      errorMessages={errors}
      required={required}
      {...dateMask}
    />
  );
};

const errorsType = PropTypes.objectOf(PropTypes.string);

DateField.propTypes = {
  errors: errorsType.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isUKAUNZ: PropTypes.bool.isRequired,
};

class GuestInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getCountryOptions();
  }

  componentDidUpdate(prevProps) {
    const { popularCountries, passengerID } = this.props;
    if (passengerID !== prevProps.passengerID || popularCountries.length !== prevProps.popularCountries.length) {
      this.getCountryOptions();
    }
  }

  getCountryOptions = () => {
    const { getCountryOptions } = this.props;

    this.setState({
      countryOptions: getCountryOptions('country', 2),
      nationalityOptions: getCountryOptions('nationality', 3),
      birthPlaceOptions: getCountryOptions('birthPlace', 2),
      passportIssuerOptions: getCountryOptions('passportIssuer', 3),
      emergencyContactCountryOptions: getCountryOptions('emergencyContactCountryCode', 2),
    });
  };

  render() {
    const {
      emergencyStates,
      errors,
      guestErrors,
      emergencyContactErrors,
      handleSubmit,
      states,
      pristine,
      dirty,
      phoneMasks,
      zipMasks,
      selectedCountries: { country, emergencyContactCountryCode },
      mainCountries,
      placeholders: { emergencyContact, guest, license, passport, travel },
      formActionLabels,
      regionalLabels,
      resetFields,
      alert,
      isSailingSoonLockdown,
      asyncValidating,
      submitting,
      titles,
      genders,
      isUKAUNZ,
      showModal,
      isMississippi,
      isUSGuest,
      isPhoneRequired,
      isPassportRequired,
      guestInfoCountry,
      emergencyContactCountry,
      autoSelectState,
    } = this.props;
    const {
      countryOptions,
      nationalityOptions,
      emergencyContactCountryOptions,
      birthPlaceOptions,
      passportIssuerOptions,
    } = this.state;

    if (states?.length === 1) {
      autoSelectState('state', states[0].value);
    }
    if (emergencyStates?.length === 1) {
      autoSelectState('emergencyContactState', emergencyStates[0].value);
    }

    return (
      <Form onSubmit={handleSubmit} className="guest-info-form-container">
        <GuestInfoFormSection heading={guest.title} tooltip={guest.tooltip}>
          <FormColumn>
            <OnBlurField
              disabled
              name="title"
              component={Select}
              placeholder={guest.fields.title}
              options={titles}
              required
            />
            <OnBlurField
              name="first"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.first, disabled: true }}
              placeholder={guest.fields.first}
              required
            />
            <OnBlurField
              name="middle"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.middle, disabled: true }}
              placeholder={guest.fields.middle}
            />
            <OnBlurField
              name="last"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.last, disabled: true }}
              placeholder={guest.fields.last}
              required
            />
            <OnBlurField
              name="suffix"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.suffix, disabled: true }}
              placeholder={guest.fields.suffix}
            />
            <div className="d-none d-md-block">
              <OnBlurField
                name="address1"
                component={InputText}
                inputAttributes={{ 'aria-label': guest.fields.address1, disabled: isSailingSoonLockdown }}
                placeholder={guest.fields.address1}
                required
                validate={validAddressLine1}
                errorCodes={{
                  [INVALID_ADDRESS_LINE_1]: INVALID_ADDRESS_LINE_1,
                }}
                errorMessages={guestErrors}
              />
            </div>
            <div className="d-none d-md-block">
              <OnBlurField
                name="address2"
                component={InputText}
                inputAttributes={{ 'aria-label': guest.fields.address2, disabled: isSailingSoonLockdown }}
                placeholder={guest.fields.address2}
                validate={validAddressLine2}
                errorCodes={{
                  [INVALID_ADDRESS_LINE_2]: INVALID_ADDRESS_LINE_2,
                }}
                errorMessages={guestErrors}
              />
            </div>
            <OnBlurField
              name="nationality"
              component={Select}
              disabled={isSailingSoonLockdown}
              placeholder={passport.fields.nationality}
              options={nationalityOptions}
              isSearchable
              required
            />
          </FormColumn>
          <FormColumn>
            <OnBlurField
              name="email"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.email, disabled: isSailingSoonLockdown }}
              placeholder={guest.fields.email}
              validate={[validGifEmail, validGifEmailLength]}
              errorCodes={{
                [INVALID_EMAIL_FORMAT]: INVALID_EMAIL_FORMAT,
                [INVALID_EMAIL_LENGTH]: INVALID_EMAIL_LENGTH,
              }}
              errorMessages={guestErrors}
              normalize={(value) => value && value.replace(/ /g, '')}
            />
            <OnBlurField
              name="gender"
              component={Select}
              disabled
              placeholder={guest.fields.gender}
              options={genders}
              required
            />
            <DateField
              name="birthdate"
              placeholder={guest.fields.birthdate}
              errors={guestErrors}
              disabled={isSailingSoonLockdown}
              isUKAUNZ={isUKAUNZ}
            />
            <OnBlurField
              name="phone"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.phone, disabled: isSailingSoonLockdown }}
              placeholder={guest.fields.phone}
              required={isPhoneRequired}
              validate={phoneNumberValidation}
              errorCodes={PHONE_NUMBER_ERRORCODES}
              errorMessages={guestErrors}
              {...phoneMasks.guestPhoneMask}
            />
            <OnBlurField
              name="mobile"
              component={InputText}
              inputAttributes={{ 'aria-label': guest.fields.mobile, disabled: isSailingSoonLockdown }}
              placeholder={guest.fields.mobile}
              validate={phoneNumberValidation}
              errorCodes={MOBILE_NUMBER_ERRORCODES}
              errorMessages={guestErrors}
              {...phoneMasks.guestPhoneMask}
            />
            <OnBlurField
              name="country"
              component={Select}
              disabled={isSailingSoonLockdown}
              placeholder={guest.fields.country}
              options={countryOptions}
              onChange={() => resetFields('state', 'zip')}
              isSearchable
              required
              errorMessages={guestErrors}
            />
            <div className="d-md-none">
              <OnBlurField
                name="address1"
                component={InputText}
                inputAttributes={{ 'aria-label': guest.fields.address1, disabled: isSailingSoonLockdown }}
                placeholder={guest.fields.address1}
                required
                validate={validAddressLine1}
                errorCodes={{
                  [INVALID_ADDRESS_LINE_1]: INVALID_ADDRESS_LINE_1,
                }}
                errorMessages={guestErrors}
              />
            </div>
            <div className="d-md-none">
              <OnBlurField
                name="address2"
                component={InputText}
                inputAttributes={{ 'aria-label': guest.fields.address2, disabled: isSailingSoonLockdown }}
                placeholder={guest.fields.address2}
                validate={validAddressLine2}
                errorCodes={{
                  [INVALID_ADDRESS_LINE_2]: INVALID_ADDRESS_LINE_2,
                }}
                errorMessages={guestErrors}
              />
            </div>
            <div className="city-zip row no-gutters">
              <div className="half-width">
                <OnBlurField
                  name="zip"
                  classes="text-uppercase"
                  component={InputText}
                  inputAttributes={{ 'aria-label': regionalLabels.guest.zip, disabled: isSailingSoonLockdown }}
                  placeholder={regionalLabels.guest.zip}
                  validate={validGifPostalCode}
                  errorCodes={ZIP_POSTAL_ERRORCODES}
                  errorMessages={guestErrors}
                  required={[COUNTRIES.UNITED_STATES, COUNTRIES.CANADA].includes(country)}
                  {...zipMasks.guestZipMask}
                  normalize={ZipPostalCodeNormalizer(guestInfoCountry)}
                />
              </div>
              <div className="half-width">
                <OnBlurField
                  name="city"
                  component={InputText}
                  inputAttributes={{ 'aria-label': guest.fields.city, disabled: isSailingSoonLockdown }}
                  placeholder={guest.fields.city}
                  required
                  validate={validGifCity}
                  errorCodes={{
                    [INVALID_CITY_LENGTH]: INVALID_CITY_LENGTH,
                  }}
                  errorMessages={guestErrors}
                />
              </div>
            </div>
            {country && mainCountries.includes(country) ? (
              <OnBlurField
                name="state"
                component={Select}
                placeholder={regionalLabels.guest.state}
                options={states}
                required={mainCountries.includes(country)}
                validate={validGifState}
                errorCodes={STATE_ERRORCODES}
                isSearchable
                disabled={isSailingSoonLockdown}
              />
            ) : (
              <OnBlurField
                name="state"
                component={InputText}
                inputAttributes={{ 'aria-label': regionalLabels.guest.state }}
                placeholder={regionalLabels.guest.state}
                {...stateMask(30)}
              />
            )}
          </FormColumn>
        </GuestInfoFormSection>
        <GuestInfoFormSection heading={passport.title}>
          <FormColumn>
            <OnBlurField
              name="passportNumber"
              component={InputText}
              inputAttributes={{ 'aria-label': passport.fields.passportNumber, disabled: isSailingSoonLockdown }}
              placeholder={passport.fields.passportNumber}
              required={isPassportRequired}
              validate={validPassportNumber}
              errorCodes={{
                [INVALID_PASSPORT_NUMBER]: INVALID_PASSPORT_NUMBER,
                [INVALID_PASSPORT_NUMBER_LENGTH]: INVALID_PASSPORT_NUMBER_LENGTH,
              }}
              {...passportNumberMask(12)}
            />
            <OnBlurField
              name="birthPlace"
              component={Select}
              disabled={isSailingSoonLockdown}
              placeholder={passport.fields.birthPlace}
              options={birthPlaceOptions}
              isSearchable
              required={false}
            />
            <DateField
              name="passportExpiresDate"
              placeholder={passport.fields.passportExpiresDate}
              errors={errors}
              disabled={isSailingSoonLockdown}
              isUKAUNZ={isUKAUNZ}
              required={isPassportRequired}
            />
          </FormColumn>
          <FormColumn>
            <OnBlurField
              name="passportIssuer"
              component={Select}
              disabled={isSailingSoonLockdown}
              placeholder={passport.fields.passportIssuer}
              options={passportIssuerOptions}
              isSearchable
              required={isPassportRequired}
            />
            <DateField
              name="passportIssuedDate"
              placeholder={passport.fields.passportIssuedDate}
              errors={errors}
              disabled={isSailingSoonLockdown}
              isUKAUNZ={isUKAUNZ}
              required={false}
            />
          </FormColumn>
        </GuestInfoFormSection>
        {isMississippi && isUSGuest && (
          <GuestInfoFormSection heading={license.title}>
            <FormColumn>
              <OnBlurField
                name="licenseNumber"
                component={InputText}
                inputAttributes={{ 'aria-label': license.fields.licenseNumber, disabled: isSailingSoonLockdown }}
                placeholder={license.fields.licenseNumber}
                validate={validLicenseNumber}
                errorCodes={{
                  [INVALID_LICENSE_NUMBER]: INVALID_LICENSE_NUMBER,
                }}
                errorMessages={errors}
                {...licenseNumberMask}
              />
              <OnBlurField
                name="stateLicense"
                component={Select}
                placeholder={license.fields.licenseIssuedState}
                options={states}
                isSearchable
                disabled={!country || !mainCountries.includes(country) || isSailingSoonLockdown}
              />
            </FormColumn>
            <FormColumn>
              <OnBlurField
                name="expiresOnLicense"
                component={InputText}
                inputAttributes={{ 'aria-label': license.fields.licenseExpiresDate, disabled: isSailingSoonLockdown }}
                placeholder={license.fields.licenseExpiresDate}
                validate={[licenseExpired, licenseWillExpireSoon, validLicenseExpirationDate, validLicenseWillExpire]}
                errorCodes={{
                  [INVALID_LICENSE_EXPIRES]: INVALID_LICENSE_EXPIRES,
                  [INVALID_LICENSE_EXPIRED]: INVALID_LICENSE_EXPIRED,
                  [INVALID_LICENSE_WILL_EXPIRE]: INVALID_LICENSE_WILL_EXPIRE,
                  [WARNING_LICENSE_EXPIRES_SOON]: WARNING_LICENSE_EXPIRES_SOON,
                }}
                errorMessages={errors}
                {...dateMask}
              />
            </FormColumn>
          </GuestInfoFormSection>
        )}
        <GuestInfoFormSection heading={travel.title}>
          <FormColumn>
            <OnBlurField
              name="insProviderName"
              component={InputText}
              inputAttributes={{ 'aria-label': travel.fields.insuranceProvider, disabled: isSailingSoonLockdown }}
              placeholder={travel.fields.insuranceProvider}
              validate={validInsuranceProviderNameLength}
              errorCodes={{
                [INVALID_INSURANCE_PROVIDER_LENGTH]: INVALID_INSURANCE_PROVIDER_LENGTH,
              }}
              normalize={(value) => value.trimStart()}
            />
            <OnBlurField
              name="insPolicyNumber"
              component={InputText}
              inputAttributes={{ 'aria-label': travel.fields.insurancePolicyNumber, disabled: isSailingSoonLockdown }}
              placeholder={travel.fields.insurancePolicyNumber}
              validate={validInsurancePolicyNumberLength}
              errorCodes={{
                [INVALID_INSURANCE_POLICY_NUMBER_LENGTH]: INVALID_INSURANCE_POLICY_NUMBER_LENGTH,
              }}
              normalize={(value) => value.trim()}
            />
          </FormColumn>
          <FormColumn>
            <OnBlurField
              name="insProviderPhone"
              component={InputText}
              inputAttributes={{
                'aria-label': travel.fields.insuranceProviderTelephone,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={travel.fields.insuranceProviderTelephone}
              validate={phoneNumberValidation}
              errorCodes={PHONE_NUMBER_ERRORCODES}
              errorMessages={guestErrors}
              {...phoneMasks.guestPhoneMask}
            />
          </FormColumn>
        </GuestInfoFormSection>
        <GuestInfoFormSection heading={emergencyContact.title}>
          <FormColumn>
            <OnBlurField
              name="emergencyContact"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContact,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContact}
              required
              validate={validEmergencyContactName}
              errorCodes={{
                [INVALID_EMERGENCY_NAME_FORMAT]: INVALID_EMERGENCY_NAME_FORMAT,
              }}
              errorMessages={emergencyContactErrors}
            />
            <div className="d-md-none">
              <OnBlurField
                name="emergencyContactCountryCode"
                component={Select}
                disabled={isSailingSoonLockdown}
                placeholder={emergencyContact.fields.emergencyContactCountryCode}
                options={emergencyContactCountryOptions}
                onChange={() => resetFields('emergencyContactState', 'emergencyContactZip')}
                isSearchable
                required
                errorMessages={emergencyContactErrors}
              />
            </div>
            <OnBlurField
              name="emergencyContactAddress1"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContactAddress1,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContactAddress1}
              required
              validate={validEmergencyAddressLine1}
              errorCodes={{
                [INVALID_EMERGENCY_ADDRESS_LINE_1]: INVALID_EMERGENCY_ADDRESS_LINE_1,
              }}
              errorMessages={emergencyContactErrors}
            />
            <OnBlurField
              name="emergencyContactAddress2"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContactAddress2,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContactAddress2}
              validate={validEmergencyAddressLine2}
              errorCodes={{
                [INVALID_EMERGENCY_ADDRESS_LINE_2]: INVALID_EMERGENCY_ADDRESS_LINE_2,
              }}
              errorMessages={emergencyContactErrors}
            />
            <div className="d-md-none">
              <OnBlurField
                name="emergencyContactZip"
                classes="text-uppercase"
                component={InputText}
                inputAttributes={{ 'aria-label': regionalLabels.emergencyContact.zip, disabled: isSailingSoonLockdown }}
                placeholder={regionalLabels.emergencyContact.zip}
                required={[COUNTRIES.UNITED_STATES, COUNTRIES.CANADA].includes(country)}
                validate={validGifPostalCode}
                errorCodes={ZIP_POSTAL_ERRORCODES}
                errorMessages={emergencyContactErrors}
                {...zipMasks.emergencyContactZipMask}
                normalize={ZipPostalCodeNormalizer(emergencyContactCountry)}
              />
            </div>
            <OnBlurField
              name="emergencyContactCity"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContactCity,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContactCity}
              required
              validate={validGifCity}
              errorCodes={{
                [INVALID_CITY_LENGTH]: INVALID_CITY_LENGTH,
              }}
              errorMessages={emergencyContactErrors}
            />
            {emergencyContactCountryCode && mainCountries.includes(emergencyContactCountryCode) ? (
              <OnBlurField
                name="emergencyContactState"
                component={Select}
                placeholder={regionalLabels.emergencyContact.state}
                options={emergencyStates}
                errorMessages={emergencyContactErrors}
                required={mainCountries.includes(emergencyContactCountryCode)}
                isSearchable
                validate={validGifEmergencyState}
                errorCodes={STATE_ERRORCODES}
                disabled={
                  isSailingSoonLockdown ||
                  !emergencyContactCountryCode ||
                  !mainCountries.includes(emergencyContactCountryCode)
                }
              />
            ) : (
              <OnBlurField
                name="emergencyContactState"
                component={InputText}
                inputAttributes={{
                  'aria-label': regionalLabels.emergencyContact.state,
                }}
                placeholder={regionalLabels.emergencyContact.state}
                {...stateMask(30)}
              />
            )}
          </FormColumn>
          <FormColumn>
            <div className="d-none d-md-block">
              <OnBlurField
                name="emergencyContactCountryCode"
                component={Select}
                disabled={isSailingSoonLockdown}
                placeholder={emergencyContact.fields.emergencyContactCountryCode}
                options={emergencyContactCountryOptions}
                onChange={() => resetFields('emergencyContactState', 'emergencyContactZip')}
                isSearchable
                required
                errorMessages={emergencyContactErrors}
              />
            </div>
            <div className="d-none d-md-block">
              <OnBlurField
                name="emergencyContactZip"
                classes="text-uppercase"
                component={InputText}
                inputAttributes={{ 'aria-label': regionalLabels.emergencyContact.zip, disabled: isSailingSoonLockdown }}
                placeholder={regionalLabels.emergencyContact.zip}
                required={[COUNTRIES.UNITED_STATES, COUNTRIES.CANADA].includes(country)}
                validate={validGifPostalCode}
                errorCodes={ZIP_POSTAL_ERRORCODES}
                errorMessages={emergencyContactErrors}
                {...zipMasks.emergencyContactZipMask}
                normalize={ZipPostalCodeNormalizer(emergencyContactCountry)}
              />
            </div>
            <OnBlurField
              name="emergencyContactDayPhone"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContactDayPhone,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContactDayPhone}
              required
              validate={phoneNumberValidation}
              errorCodes={PHONE_NUMBER_ERRORCODES}
              errorMessages={emergencyContactErrors}
              {...phoneMasks.emergencyContactPhoneMask}
            />
            <OnBlurField
              name="emergencyContactRelationship"
              component={InputText}
              inputAttributes={{
                'aria-label': emergencyContact.fields.emergencyContactRelationship,
                disabled: isSailingSoonLockdown,
              }}
              placeholder={emergencyContact.fields.emergencyContactRelationship}
              required
              validate={validEmergencyContactRelation}
              errorCodes={{
                [INVALID_EMERGENCY_RELATION_FORMAT]: INVALID_EMERGENCY_RELATION_FORMAT,
              }}
              errorMessages={emergencyContactErrors}
            />
          </FormColumn>
        </GuestInfoFormSection>
        <FormActions
          secondaryButton={{
            onButtonClick: () => {
              if (dirty) {
                showModal(dialogModalId);
              }
            },
            text: formActionLabels.cancel,
            disabled: pristine,
          }}
          primaryButton={{
            disabled: pristine,
            loading: asyncValidating || submitting,
            text: formActionLabels.update,
          }}
          scrollOnAlert
          alert={alert}
        />
      </Form>
    );
  }
}

const maskProps = {
  pattern: PropTypes.string,
  maskDefinition: PropTypes.shape({}),
  guide: PropTypes.bool,
};

GuestInfoForm.propTypes = {
  getCountryOptions: PropTypes.func.isRequired,
  emergencyStates: PropTypes.options.isRequired,
  errors: errorsType.isRequired,
  guestErrors: errorsType.isRequired,
  emergencyContactErrors: errorsType.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  states: PropTypes.options.isRequired,
  dirty: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  alert: PropTypes.objectOf(PropTypes.string).isRequired,
  isSailingSoonLockdown: PropTypes.bool.isRequired,
  phoneMasks: PropTypes.shape({
    guestPhoneMask: PropTypes.shape(maskProps),
    emergencyContactPhoneMask: PropTypes.shape(maskProps),
  }).isRequired,
  zipMasks: PropTypes.shape({
    guestZipMask: PropTypes.shape(maskProps),
    emergencyContactZipMask: PropTypes.shape(maskProps),
  }).isRequired,
  selectedCountries: PropTypes.shape({
    country: PropTypes.string,
    emergencyContactCountryCode: PropTypes.string,
  }).isRequired,
  mainCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholders: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.objectOf(PropTypes.string),
    })
  ).isRequired,
  formActionLabels: PropTypes.shape({
    cancel: PropTypes.string,
    update: PropTypes.string,
  }).isRequired,
  regionalLabels: PropTypes.objectOf(
    PropTypes.shape({
      state: PropTypes.string,
      zip: PropTypes.string,
    })
  ).isRequired,
  resetFields: PropTypes.func.isRequired,
  asyncValidating: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  popularCountries: PropTypes.arrayOf(
    PropTypes.shape({
      twoLetterCode: PropTypes.string,
      label: PropTypes.string,
      threeLetterCode: PropTypes.string,
    })
  ).isRequired,
  passengerID: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUKAUNZ: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  isMississippi: PropTypes.bool.isRequired,
  isUSGuest: PropTypes.bool.isRequired,
  guestInfoCountry: PropTypes.string,
  emergencyContactCountry: PropTypes.string,
  autoSelectState: PropTypes.func,
};

export default GuestInfoForm;
