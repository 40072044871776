import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import React from 'react';
import { Field } from '../..';
import PropTypes from '../../../PropTypes';
import { ACH } from '../../../common/Constants';
import { ZipPostalCodeNormalizer } from '../../../common/forms/Normalizations';
import {
  ERROR_CODES,
  required,
  requiredPostalCode,
  requiredState,
  validAddressLine,
  validCity,
  validPostalCode,
} from '../../../common/forms/Validations';

const {
  INVALID_ZIP_FORMAT_US,
  INVALID_ZIP_FORMAT_CA,
  INVALID_ZIP_FORMAT_UK,
  INVALID_ZIP_FORMAT_AUNZ,
  INVALID_ZIP_FORMAT,
  INVALID,
  REQUIRED,
  ZIP_REQUIRED_US,
  ZIP_REQUIRED_CA,
  ZIP_REQUIRED_UK,
  ZIP_REQUIRED_AUNZ,
  STATE_REQUIRED_US,
  STATE_REQUIRED_CA,
  STATE_REQUIRED_UK,
  STATE_REQUIRED_AU,
  STATE_REQUIRED_NZ,
} = ERROR_CODES;
const rowClass = 'row no-gutters';

const ZIP_POSTAL_ERRORCODES = {
  [INVALID_ZIP_FORMAT_US]: INVALID_ZIP_FORMAT_US,
  [INVALID_ZIP_FORMAT_CA]: INVALID_ZIP_FORMAT_CA,
  [INVALID_ZIP_FORMAT_UK]: INVALID_ZIP_FORMAT_UK,
  [INVALID_ZIP_FORMAT_AUNZ]: INVALID_ZIP_FORMAT_AUNZ,
  [INVALID_ZIP_FORMAT]: INVALID_ZIP_FORMAT,
  [ZIP_REQUIRED_US]: ZIP_REQUIRED_US,
  [ZIP_REQUIRED_CA]: ZIP_REQUIRED_CA,
  [ZIP_REQUIRED_UK]: ZIP_REQUIRED_UK,
  [ZIP_REQUIRED_AUNZ]: ZIP_REQUIRED_AUNZ,
  [REQUIRED]: 'PostalCodeRequired',
};

const STATE_ERRORCODES = {
  [STATE_REQUIRED_US]: STATE_REQUIRED_US,
  [STATE_REQUIRED_CA]: STATE_REQUIRED_CA,
  [STATE_REQUIRED_UK]: STATE_REQUIRED_UK,
  [STATE_REQUIRED_AU]: STATE_REQUIRED_AU,
  [STATE_REQUIRED_NZ]: STATE_REQUIRED_NZ,
  [REQUIRED]: 'StateRequired',
};

const Address = ({
  disabled,
  addressLine1,
  addressLine2,
  city,
  country,
  state,
  zip,
  submitting,
  propCountry,
  resetFields,
  paymentType,
}) => (
  <>
    {country && paymentType === ACH ? (
      <Field component={Select} disabled name="country" isSearchable {...country} />
    ) : (
      <div className={rowClass}>
        <Field
          component={Select}
          disabled={disabled}
          name="country"
          isSearchable
          onChange={() => resetFields('account.state', 'account.zip')}
          validate={[required]}
          errorCodes={{
            [REQUIRED]: 'required',
          }}
          {...country}
        />
      </div>
    )}
    {addressLine1 && (
      <div className={rowClass}>
        <Field
          component={InputText}
          name="addressLine1"
          validate={[required, validAddressLine]}
          inputAttributes={{ disabled: disabled || submitting }}
          errorCodes={{
            [INVALID]: 'AddressLine1Invalid',
            [REQUIRED]: 'AddressLine1Required',
          }}
          {...addressLine1}
        />
      </div>
    )}
    {addressLine2 && (
      <div className={rowClass}>
        <Field
          component={InputText}
          name="addressLine2"
          validate={validAddressLine}
          inputAttributes={{ disabled: disabled || submitting }}
          errorCodes={{
            [INVALID]: 'AddressLine2Invalid',
          }}
          {...addressLine2}
        />
      </div>
    )}
    {(zip || city) && (
      <div className={rowClass}>
        {zip && (
          <div className="half-width">
            <Field
              classes="text-uppercase"
              component={InputText}
              name="zip"
              validate={[requiredPostalCode, validPostalCode]}
              inputAttributes={{ disabled: disabled || submitting }}
              errorCodes={ZIP_POSTAL_ERRORCODES}
              {...zip}
              normalize={ZipPostalCodeNormalizer(propCountry)}
            />
          </div>
        )}
        {city && (
          <div className="half-width">
            <Field
              component={InputText}
              name="city"
              validate={[required, validCity]}
              inputAttributes={{ disabled: disabled || submitting }}
              errorCodes={{
                [INVALID]: 'CityInvalid',
                [REQUIRED]: 'CityRequired',
              }}
              {...city}
            />
          </div>
        )}
      </div>
    )}
    {state && (
      <div className={rowClass}>
        <Field
          component={Select}
          name="state"
          isSearchable
          validate={state.options?.length === 0 ? null : requiredState}
          disabled={disabled || submitting || state.options?.length === 0}
          errorCodes={STATE_ERRORCODES}
          {...state}
        />
      </div>
    )}
  </>
);

Address.propTypes = {
  addressLine1: PropTypes.inputField,
  addressLine2: PropTypes.inputField,
  city: PropTypes.inputField,
  country: PropTypes.selectField,
  state: PropTypes.selectField,
  zip: PropTypes.inputField,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

Address.defaultProps = {
  addressLine1: null,
  addressLine2: null,
  city: null,
  country: null,
  state: null,
  zip: null,
  submitting: false,
  disabled: false,
};

export default Address;
