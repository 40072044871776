import React from 'react';
import get from 'lodash/get';

import PropTypes from '../../PropTypes';
import { PageHeader } from '../../components';
import { TAB_NAMES } from '../../common/Constants';

import AirBooking from './airBooking/AirBookingContainer';
import Transfers from './transfers/TransfersContainer';
import AirPreferences from './preferences/AirPreferencesContainer';

const Air = ({ isLoading, match: { url }, tabs, title, isCruiseOnly, tabLabels }) => {
  const paths = [
    {
      component: AirBooking,
      path: get(tabLabels, `${TAB_NAMES.AIR_BOOKING}.url`, ''),
    },
    {
      component: Transfers,
      path: get(tabLabels, `${TAB_NAMES.TRANSFERS}.url`, ''),
    },
  ];
  if (!isCruiseOnly) {
    paths.push({
      component: AirPreferences,
      path: get(tabLabels, `${TAB_NAMES.AIR_PREFERENCES}.url`, ''),
    });
  }

  return (
    <div id="airPage" className="air-page">
      <PageHeader isLoading={isLoading} paths={paths} tabs={tabs} title={title} url={url} />
    </div>
  );
};

Air.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.routerMatch.isRequired,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  isCruiseOnly: PropTypes.bool,
  tabLabels: PropTypes.shape({}),
};

Air.defaultProps = {
  tabs: [],
  title: '',
  isCruiseOnly: false,
  tabLabels: {},
};

export default Air;
