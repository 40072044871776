import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { COUNTRIES, DRIVERS_LICENSE_REGEX } from '../Constants';

const numeric = {
  9: {
    regExp: /[0-9]/,
  },
};

const dollarMask = createNumberMask({
  prefix: '$',
  decimalPlaces: 2,
});

const poundMask = createNumberMask({
  prefix: '£',
  decimalPlaces: 2,
});

export const currencyMask = (country) => {
  switch (country) {
    case COUNTRIES.UNITED_KINGDOM:
      return poundMask;
    case COUNTRIES.AUSTRALIA:
    case COUNTRIES.CANADA:
    case COUNTRIES.UNITED_STATES:
    default:
      return dollarMask;
  }
};

export const institutionTransitMask = createTextMask({
  pattern: '999-99999',
  maskDefinitions: numeric,
  placeholder: 'X',
});

export const alphanumericMask = (length) =>
  createTextMask({
    pattern: 'A'.repeat(length),
    guide: false,
    maskDefinitions: {
      A: {
        regExp: /[A-Za-z0-9]/,
      },
    },
  });

export const numericMask = (length) =>
  createTextMask({
    pattern: '9'.repeat(length),
    guide: false,
  });

export const cvvMask = (cardType) => (cardType === 'AX' ? numericMask(4) : numericMask(3));

export const postalCodeMask = (country) => {
  let config;
  switch (country) {
    case COUNTRIES.UNITED_STATES:
      config = {
        pattern: '99999',
        maskDefinition: numeric,
        guide: false,
      };
      break;
    case COUNTRIES.CANADA:
      config = {
        pattern: 'A9A-9A9',
        maskDefinition: {
          ...numeric,
          A: {
            regExp: /[A-Za-z]/,
          },
        },
        guide: false,
      };
      break;
    case COUNTRIES.UNITED_KINGDOM_GB:
    case COUNTRIES.UNITED_KINGDOM:
      config = {
        pattern: 'X'.repeat(8),
        maskDefinitions: {
          X: {
            regExp: /[A-Za-z0-9 ]/,
          },
        },
        guide: false,
      };
      break;
    case COUNTRIES.AUSTRALIA:
    case 'NZ':
      config = {
        pattern: '9999',
        maskDefinitions: numeric,
        guide: false,
      };
      break;
    default:
      config = {
        pattern: 'X'.repeat(10),
        maskDefinitions: {
          X: {
            regExp: /[A-Za-z0-9 ]/,
          },
        },
        guide: false,
      };
      break;
  }

  return createTextMask(config);
};

export const dateMask = createTextMask({
  pattern: '99/99/9999',
  maskDefinitions: numeric,
  guide: false,
});

export const passportNumberMask = (length) =>
  createTextMask({
    pattern: 'X'.repeat(length),
    maskDefinitions: {
      X: {
        regExp: /[A-Za-z0-9]/,
      },
    },
    guide: false,
  });

export const licenseNumberMask = createTextMask({
  pattern: 'X'.repeat(32),
  maskDefinitions: {
    X: {
      regExp: DRIVERS_LICENSE_REGEX,
    },
  },
  guide: false,
});

export const phoneMask = (country) => {
  let config;
  switch (country) {
    case COUNTRIES.AUSTRALIA:
    case COUNTRIES.UNITED_KINGDOM:
    case COUNTRIES.UNITED_KINGDOM_GB:
      config = {
        pattern: `X${'9'.repeat(15)}`,
        maskDefinitions: {
          ...numeric,
          X: {
            regExp: /[0-9+]/,
          },
        },
        guide: false,
      };
      break;
    case COUNTRIES.UNITED_STATES:
    case COUNTRIES.CANADA:
      config = {
        pattern: '999-999-9999',
        maskDefinitions: numeric,
        guide: false,
      };
      break;
    default:
      config = {
        pattern: '9'.repeat(20),
        maskDefinitions: numeric,
        guide: false,
      };
      break;
  }

  return createTextMask(config);
};

export const stateMask = (length) =>
  createTextMask({
    pattern: 'A'.repeat(length),
    guide: false,
    maskDefinitions: {
      A: {
        regExp: /[A-Za-z0-9 -]/,
      },
    },
  });
