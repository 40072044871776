import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore from '../../common';
import { withContent } from '../../components';

import Payments from './Payments';
import paymentsStore, { fetchPaymentsPageContent } from './PaymentsStore';

const { getCustomizableTabs, getTitle, isLoadingOnboardCC, isTabLoading } = paymentsStore.selectors;
const { getLabels } = commonStore.selectors;

const mapStateToProps = (state, { location, match }) => {
  return {
    ...getCustomizableTabs(state)(location, match),
    isLoading: isTabLoading(state)(location.pathname),
    isOnboardCCLoading: isLoadingOnboardCC(state),
    title: getTitle(state),
    labels: getLabels(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchPaymentsPageContent()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Payments);
