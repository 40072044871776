import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import moment from 'moment';
import { createSelector } from 'reselect';
import { triggerLinkEvent } from './Analytics';
import { deleteData, getData, getRequestConfig, handleAdvisoryCode, postData, putData } from './Api';
import { MSAL_CONFIG } from './AuthConfig';
import { msalInstance } from './Authentication';
import {
  AIR_PLUS_REFERENCE,
  ALLOW_EXPEDITIONS,
  ALLOW_MISSISSIPPI,
  APP_INSIGHTS_TRACK_TYPE,
  APP_PATHS,
  BOOKING_STATUS_FOR_PURCHASE,
  BOOKING_STATUSES,
  CART_COUNT_INPUTS,
  CRUISE_EXCEPTIONS_UK,
  DAYS_TO_GO,
  DB_DATE_FORMAT,
  EVO_HEADER,
  EVO_USER_TYPES,
  EXTENSION_TYPES,
  FEATURE_RESTRICTED,
  GDS_CODES,
  GREAT_LAKES_CRUISES,
  LOCK_CODES,
  LOCK_TYPES,
  OOPS_PAGE_ERRORS,
  RESPONSE_STATUS,
  SERVICE_CODES,
  TA_PORTAL_URL,
  USER_TYPES,
  UTM_EVENT_NAMES,
  VOYAGE_STATUSES,
  VOYAGE_TYPE,
} from './Constants';
import history from './history';
import { createDefaultDuck } from './ReusableDucks';
import {
  base64EncodeString,
  buildUrl,
  debugLog,
  decodeCountryCodeFromCurrency,
  getPassengerAbbrevName,
  logAdvisoryCode,
  navigateTo,
  safeStringCompare,
} from './Utils';

const { INCREASE } = CART_COUNT_INPUTS;
const { removeSessionInfo, logger, updateCustomDimensions } = appInsightsHelper;

const getDaysToGoDateHelper = (bookingDetails) => {
  const { guestDepartureDate = '' } = bookingDetails;
  const bookingDetailsAreAvailable = bookingDetails.itinerary && bookingDetails.itinerary.journeyDays.length;

  if (bookingDetailsAreAvailable) {
    const {
      itinerary: { journeyDays },
    } = bookingDetails;
    const firstItineraryDay = journeyDays[0].date;
    /*
            The guestDepartureDate value contains either the pre-extension departure date or
            the first itinerary date. Leaving in the check for firstItineraryDay in the event
            guestDepartureDate is not available for any reason.
          */
    const actualTripStartDate = guestDepartureDate || firstItineraryDay;
    return actualTripStartDate;
  }

  return '';
};

const userStore = createDefaultDuck('user').extend({
  types: [
    'LOCKOUT',
    'LOGOUT',
    'RECEIVE_ACCESS_TOKEN',
    'RECEIVE_AUTH_DATA',
    'RECEIVE_BOOKINGS',
    'RECEIVE_CART',
    'RECEIVE_FATAL_ERROR',
    'RECEIVE_INVOICE_PRICING',
    'RECEIVE_REDIRECT_QUERY',
    'RECEIVE_TA_BOOKING_NUMBER',
    'RECEIVE_USER_DATA',
    'SET_AUTH_LOADING_STATUS',
    'SET_FATAL_ERROR',
    'SET_INVOICE_PRICING_LOADED',
    'SET_LOADING_BOOKING_FLAG',
    'SET_MIGRATE_FLAG',
    'SET_MIGRATION_DEEP_LINK',
    'SET_OOPS_PAGE_ERROR',
    'UPDATE_ADD_BOOKING_DATA',
    'UPDATE_ADD_BOOKING_STATUS',
    'UPDATE_CART_ITEM_COUNT',
    'UPDATE_EMAIL',
    'UPDATE_LOCKED_STATUS',
    'VIEW_ONLY',
    'SET_IMPERSONATION_DATA',
    'SET_ANALYTICS_SESSION_ID',
  ],
  initialState: { fatalAuthError: false },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_AUTH_DATA:
        return {
          ...state,
          authData: {
            agentBookingNumber: state.authData?.agentBookingNumber,
            ...action.payload,
          },
        };
      case types.RECEIVE_FATAL_ERROR:
        return {
          ...state,
          fatalAuthError: true,
        };
      case types.RECEIVE_ACCESS_TOKEN:
        return {
          ...state,
          authData: {
            ...state.authData,
            accessToken: action.payload,
          },
        };
      case types.RECEIVE_TA_BOOKING_NUMBER:
        return {
          ...state,
          authData: {
            ...state.authData,
            agentBookingNumber: action.payload,
          },
        };
      case types.RECEIVE_BOOKINGS:
        return {
          ...state,
          bookingDetails: {
            ...action.payload.booking,
            loaded: true,
          },
        };
      case types.RECEIVE_CART: {
        const condensedCartItemsCount =
          action.payload.items &&
          action.payload.items.reduce((acc, item) => {
            // Combobookings require a cart item for each leg for SSBP & PPG.
            // Condense them so they appear as 1 item.
            // Else include voyageId with the key to account for shoreex's with matching serviceCodes on different legs.
            const condensedServiceCodes = [SERVICE_CODES.PPG, SERVICE_CODES.SSBP];
            const key = condensedServiceCodes.includes(item.serviceCode?.toUpperCase())
              ? item.serviceCode
              : `${item.serviceCode}-${item.voyageId}`;
            acc.add(key);
            return acc;
          }, new Set()).size;
        return {
          ...state,
          cart: {
            cartID: action.payload.cartID,
            loaded: true,
            isPPGInCart:
              action.payload.items && action.payload.items.some((item) => item.serviceCode === SERVICE_CODES.PPG),
            isAirInCart:
              action.payload.items &&
              action.payload.items.reduce((acc, item) => {
                if (item.serviceCode === AIR_PLUS_REFERENCE) {
                  acc.push(item.forPassenger1 ? item.itemID : null, item.forPassenger2 ? item.itemID : null);
                }
                return acc;
              }, []),
            NoItemsInCart: condensedCartItemsCount,
          },
        };
      }
      case types.UPDATE_ADD_BOOKING_DATA:
        return {
          ...state,
          addBookingData: action.payload,
        };
      case types.UPDATE_ADD_BOOKING_STATUS:
        return {
          ...state,
          addBookingLoading: action.payload,
        };
      case types.UPDATE_EMAIL:
        return {
          ...state,
          login: {
            ...state.login,
            email: (action.payload || '').toLowerCase(),
          },
        };
      case types.SET_MIGRATE_FLAG:
        return {
          ...state,
          migrate: action.migrate,
        };
      case types.RECEIVE_REDIRECT_QUERY:
        return {
          ...state,
          link: {
            queryParams: action.payload,
            path: window.location.pathname,
          },
        };
      case types.UPDATE_LOCKED_STATUS:
        return {
          ...state,
          lockedStatus: action.payload ? LOCK_TYPES.UNLOCK : LOCK_TYPES.LOCK,
        };
      case types.SET_AUTH_LOADING_STATUS:
        return {
          ...state,
          authLoading: action.state,
        };
      case types.LOGOUT:
        return {
          ...state,
          lockout: false,
          viewOnly: false,
        };
      case types.LOCKOUT:
        return {
          ...state,
          lockout: action.state,
        };
      case types.VIEW_ONLY:
        return {
          ...state,
          viewOnly: action.state,
        };
      case types.UPDATE_CART_ITEM_COUNT:
        let cartCountChange;
        if (typeof action.input === 'number') {
          cartCountChange = action.input;
        } else {
          cartCountChange = action.input === INCREASE ? 1 : -1;
        }
        return {
          ...state,
          cart: {
            ...state.cart,
            NoItemsInCart: Math.max(state?.cart?.NoItemsInCart + cartCountChange, 0),
          },
        };
      case types.SET_LOADING_BOOKING_FLAG:
        return {
          ...state,
          isLoadingBooking: action.payload,
        };
      case types.SET_INVOICE_PRICING_LOADED:
        return {
          ...state,
          invoicePricingLoading: action.payload,
        };
      case types.RECEIVE_INVOICE_PRICING:
        const { passengers } = action.payload;

        const remappedPassengers = state?.bookingDetails?.passengers.map((pax) => {
          const paxInvoice = (passengers || []).find(
            (passenger) => parseInt(passenger.passengerNumber, 10) === pax.passengerNumber
          );
          if (paxInvoice) {
            const { appliedSBCVoucherTotal, appliedOtherVoucherTotal } = paxInvoice;
            return {
              ...pax,
              appliedSBCVoucherTotal,
              appliedOtherVoucherTotal,
            };
          }
          return pax;
        });
        return {
          ...state,
          bookingDetails: {
            ...state.bookingDetails,
            passengers: remappedPassengers,
          },
        };
      case types.SET_OOPS_PAGE_ERROR:
        return {
          ...state,
          oopsPageError: action.payload,
        };
      case types.SET_MIGRATION_DEEP_LINK:
        return {
          ...state,
          migration: {
            redirectPath: action.payload,
          },
        };
      case types.SET_IMPERSONATION_DATA:
        return {
          ...state,
          impersonation: {
            bookingNumber: action.payload.id,
            passengerNumber: action.payload.pax,
          },
        };
      case types.RECEIVE_USER_DATA:
        return {
          ...state,
          userData: {
            ...action.payload,
            loaded: true,
          },
        };
      case types.SET_FATAL_ERROR:
        return {
          ...state,
          fatalAuthError: false,
        };
      case types.SET_ANALYTICS_SESSION_ID:
        return {
          ...state,
          analyticsSessionId: action.payload,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    logout: () => ({
      type: types.LOGOUT,
    }),
    lockout: (state) => ({
      type: types.LOCKOUT,
      state,
    }),
    viewOnly: (state) => ({
      type: types.VIEW_ONLY,
      state,
    }),
    receiveAccessToken: (payload) => ({
      type: types.RECEIVE_ACCESS_TOKEN,
      payload,
    }),
    receiveTABookingNumber: (payload) => ({
      type: types.RECEIVE_TA_BOOKING_NUMBER,
      payload,
    }),
    receiveAuthData: (payload) => ({
      type: types.RECEIVE_AUTH_DATA,
      payload,
    }),
    receiveFatalError: () => ({
      type: types.RECEIVE_FATAL_ERROR,
    }),
    receiveBookings: (payload) => ({
      type: types.RECEIVE_BOOKINGS,
      payload,
    }),
    receiveCart: (payload) => ({
      type: types.RECEIVE_CART,
      payload,
    }),
    updateAddBookingData: (payload) => ({
      type: types.UPDATE_ADD_BOOKING_DATA,
      payload,
    }),
    updateChangeBookingStatus: (payload) => ({
      type: types.UPDATE_ADD_BOOKING_STATUS,
      payload,
    }),
    updateUserEmail: (payload) => ({
      type: types.UPDATE_EMAIL,
      payload,
    }),
    setMigrateFlag: (migrate) => ({
      type: types.SET_MIGRATE_FLAG,
      migrate,
    }),
    receiveQueryParams: (payload) => ({
      type: types.RECEIVE_REDIRECT_QUERY,
      payload,
    }),
    updateLockedStatus: (unlocked) => ({
      type: types.UPDATE_LOCKED_STATUS,
      payload: unlocked,
    }),
    setAuthLoadingStatus: (payload) => ({
      type: types.SET_AUTH_LOADING_STATUS,
      state: payload,
    }),
    updateCartItemCount: (input) => ({
      type: types.UPDATE_CART_ITEM_COUNT,
      input,
    }),
    setBookingLoadingFlag: (loading) => ({
      type: types.SET_LOADING_BOOKING_FLAG,
      payload: loading,
    }),
    setInvoicePricingLoading: (payload) => ({
      type: types.SET_INVOICE_PRICING_LOADED,
      payload,
    }),
    receiveInvoicePricing: (payload) => ({
      type: types.RECEIVE_INVOICE_PRICING,
      payload,
    }),
    setOopsPageError: (error) => ({
      type: types.SET_OOPS_PAGE_ERROR,
      payload: error,
    }),
    setMigrationDeepLink: (payload) => ({
      type: types.SET_MIGRATION_DEEP_LINK,
      payload,
    }),
    setImpersonationData: (payload) => ({
      type: types.SET_IMPERSONATION_DATA,
      payload,
    }),
    receiveUserData: (payload) => ({
      type: types.RECEIVE_USER_DATA,
      payload,
    }),
    setFatalAuthError: (error) => ({
      type: types.SET_FATAL_ERROR,
      payload: error,
    }),
    setAnalyticsSessionId: (payload) => ({
      type: types.SET_ANALYTICS_SESSION_ID,
      payload,
    }),
  }),
  selectors: {
    getMigrationDeepLink: (state) => state?.user?.migration?.redirectPath,
    getUserData: (state) => state?.user?.userData,
    getBookingNumber: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], ({ bookingNumber }) => bookingNumber)
    ),
    getLockoutStatus: (state) => get(state, 'user.lockout', false),
    getAuthData: (state) => get(state, 'user.authData', {}),
    getEmail: new Selector(({ getAuthData, getUserEmail }) =>
      createSelector([getAuthData, getUserEmail], (authData, email) => {
        const bookingEmail = get(authData, 'attributes.email', '');
        return bookingEmail || email;
      })
    ),
    getUserEmail: (state) => get(state, 'user.login.email', ''),
    getIdToken: new Selector(({ getAuthData }) =>
      createSelector([getAuthData], (authData) => {
        const token = authData?.accessToken;
        return token;
      })
    ),
    getAccessToken: (state) => state?.user?.authData?.accessToken,
    getUserType: new Selector(({ getAuthData }) =>
      createSelector([getAuthData], (authData) => {
        const { UserType, impersonatorEmail } = authData?.idTokenClaims || {};
        return impersonatorEmail ? USER_TYPES.CSA : UserType;
      })
    ),
    getMigrateFlag: (state) => get(state, 'user.migrate', false),
    getUserBookings: new Selector(({ getAuthData, getIsTaAccount, getUserData, getUserType, getImpersonationData }) =>
      createSelector(
        [getAuthData, getIsTaAccount, getUserData, getUserType, getImpersonationData],
        (authData, isTa, userData, userType, impersonationData) => {
          if (!authData) {
            return [];
          }
          let bookings = userData?.bookings || [];

          if (isTa) {
            bookings = [
              { bookingId: `${authData?.agentBookingNumber}`, passengerNumber: 1, ptcAccepted: true, lastViewed: true },
            ];
          }
          if (userType === USER_TYPES.CSA) {
            bookings = [
              {
                bookingId: `${impersonationData?.bookingNumber}`,
                passengerNumber: 1,
                ptcAccepted: true,
                lastViewed: true,
              },
            ];
          }
          return bookings;
        }
      )
    ),
    getActiveUserBookings: new Selector(({ getUserBookings }) => createSelector(
      [getUserBookings],
      (userBookings) => {
        return userBookings.filter(({ bookingStatus }) => bookingStatus !== BOOKING_STATUSES.PAST);
      }
    )),
    getAgent: (state) => get(state, 'user.bookingDetails.agent'),
    getPassengers: (state) => get(state, 'user.bookingDetails.passengers', []),
    getItineraryLabels: (state) => get(state, 'user.bookingDetails.itinerary.labels', {}),
    getItinerary: (state) => get(state, 'user.bookingDetails.itinerary.journeyDays', []),
    getBalanceDue: (state) => get(state, 'user.bookingDetails.payments.outstandingBalanceDue', 0),
    getBalanceDueDate: (state) => get(state, 'user.bookingDetails.payments.balanceDueDate'),
    getTotalDue: (state) => get(state, 'user.bookingDetails.payments.totalDue', 0),
    getDocumentation: (state) => get(state, 'user.bookingDetails.documentation'),
    getComboBookings: (state) => get(state, 'user.bookingDetails.comboBookings', []),
    getAddBookingData: (state) => get(state, 'user.addBookingData', {}),
    getChangeBookingStatus: (state) => get(state, 'user.addBookingLoading', false),
    getBookingStatus: (state) => state?.user?.bookingDetails?.bookingStatus,
    getCalendarId: (state) => get(state, 'calendar.calendarItems.calendarId'),
    getLinkQueryParams: (state) => get(state, 'user.link.queryParams', {}),
    getLoginPathUrl: (state) => get(state, 'user.link.path', ''),
    getLockedStatus: (state) => get(state, 'user.lockedStatus'),
    getLockedCode: (state) => state?.user?.bookingDetails?.lockedCode || null,
    getIsViewOnly: (state) => get(state, 'user.viewOnly', false),
    getBookingLoadingFlag: (state) => get(state, 'user.isLoadingBooking', false),
    isAuthLoading: (state) => get(state, 'user.authLoading'),
    isCartLoading: (state) =>
      state?.user?.cart?.loading === true || state?.payments?.cartItemsWhenAdded?.loading === true,
    getIsDirect: (state) => get(state, 'user.bookingDetails.directBooking'),
    getFatalAuthError: (state) => get(state, 'user.fatalAuthError', false),
    getInvoicePricingLoading: (state) => state?.user?.invoicePricingLoading,
    getIsBalancePastDue: new Selector(({ getBalanceDue, getBalanceDueDate }) =>
      createSelector([getBalanceDue, getBalanceDueDate], (balanceDue, balanceDueDate) => {
        const today = moment();
        const isBalancePastDue = today.isAfter(balanceDueDate) && balanceDue > 0;
        return isBalancePastDue;
      })
    ),
    getIsTaAccount: new Selector(({ getUserType }) =>
      createSelector([getUserType], (userType) => userType === USER_TYPES.TA)
    ),
    getIsApprovedForPurchases: new Selector(({ getBookingDetails }) =>
      // Add in flag for locked status to impact this for view only mode?
      createSelector([getBookingDetails], ({ bookingStatus }) => BOOKING_STATUS_FOR_PURCHASE.includes(bookingStatus))
    ),
    getCloseToSailingAlerted: new Selector(({ getUserBookings, getBookingDetails }) =>
      createSelector([getUserBookings, getBookingDetails], (userBookings, { bookingNumber }) => {
        const currentBooking = userBookings.find((booking) => booking.bookingId === bookingNumber);
        return get(currentBooking, 'closeToSailingAlerted', false);
      })
    ),
    getVoyageType: new Selector(({ getComboBookings, getBookingDetails }) =>
      createSelector([getComboBookings, getBookingDetails], (comboBookings, { voyage: { type: voyageType } = {} }) => {
        let voyageTypes = [voyageType];
        // TODO remove this once we have plans for handling mississippi cruises
        if (voyageType === VOYAGE_TYPE.MISSISSIPPI) {
          return VOYAGE_TYPE.RIVER;
        }
        if (comboBookings.length) {
          voyageTypes = comboBookings.map((combo) => combo.voyageType);
        }
        if (voyageTypes.includes(VOYAGE_TYPE.RIVER) && voyageTypes.includes(VOYAGE_TYPE.OCEAN)) {
          return VOYAGE_TYPE.MIXED;
        }
        return voyageTypes.pop();
      })
    ),
    getIsCruiseExceptionUK: new Selector(({ getBookingDetails, getCountryCodeFromCurrency }) =>
      createSelector([getBookingDetails, getCountryCodeFromCurrency], ({ voyage: { id } = {} }, countryCode) => {
        return CRUISE_EXCEPTIONS_UK.includes(id) && countryCode === 'UK';
      })
    ),
    getUsername: new Selector(({ getAuthData }) =>
      createSelector([getAuthData], (authData) => {
        const username = authData?.uniqueId;
        return username;
      })
    ),
    getItineraryDate: new Selector(({ getItinerary }) =>
      createSelector(getItinerary, (itinerary) => memoize((date) => itinerary.find((i) => i.date === date)))
    ),
    getProgramId: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], (bookingDetails) => {
        if (!get(bookingDetails, 'loaded')) {
          return '';
        }
        const { voyage, office } = bookingDetails;
        const { embarkationDate, embarkPort, disembarkPort } = voyage;
        return `${moment(embarkationDate).format('YY')}${embarkPort}${disembarkPort}${office}`;
      })
    ),
    getHeaders: new Selector(({ getIdToken, getAgent, getBookingDetails, getIsTaAccount, getLoggedInUser }) =>
      createSelector(
        [getIdToken, getAgent, getBookingDetails, getIsTaAccount, getLoggedInUser],
        (accessToken, agent, bookingDetails = {}, isTaAccount, loggedInUser) => {
          const headers = {
            directBooking: bookingDetails.directBooking,
            ...agent,
            [EVO_HEADER.GDS_CODE]: isTaAccount ? GDS_CODES.TA : GDS_CODES.CONSUMER,
          };

          if (accessToken) {
            headers.Authorization = accessToken;
          }
          if (!isTaAccount && !(headers[EVO_HEADER.AGENT_EMAIL] || '').trim()) {
            headers[EVO_HEADER.AGENT_EMAIL] = loggedInUser?.email;
          }

          if (isTaAccount || ['undefined', undefined].includes(headers.passengerNumber)) {
            headers.passengerNumber = 1;
          }

          return { ...headers };
        }
      )
    ),
    getPassenger: new Selector(({ getPassengers }) =>
      createSelector([getPassengers], (passengers) =>
        memoize((passengerId) => {
          const findCallback = (passenger) => passenger.passengerNumber === Number(passengerId);
          return passengers.find(findCallback) || {};
        })
      )
    ),
    getBookingDetails: (state) => get(state, 'user.bookingDetails', {}),
    getCart: (state) => get(state, 'user.cart', {}),
    getCartId: (state) => get(state, 'user.cart.cartID'),
    getCartItems: (state) => get(state, 'user.cart.items', []),
    getPassengerInitialValues: new Selector(({ getPassengers }) =>
      createSelector([getPassengers], (passengers) => {
        const initialValue = {};
        passengers.forEach((passenger, index) => {
          initialValue[`passenger${index + 1}`] = true;
        });
        return initialValue;
      })
    ),
    getPassengerNames: new Selector(({ getPassengers }) =>
      createSelector(getPassengers, (passengers) => passengers.map((passenger) => getPassengerAbbrevName(passenger)))
    ),
    getLoggedInUser: new Selector(({ getAuthData, getIsTaAccount, getBookingDetails, getUserData }) =>
      createSelector(
        [getAuthData, getIsTaAccount, getBookingDetails, getUserData],
        ({ idTokenClaims = {} }, isTa, { bookingNumber, passengers = [] }, userData) => {
          const { bookings, firstName, lastName } = userData || {};
          let ret = {};
          let currentBooking = null;
          const { given_name: first, family_name: last, email } = idTokenClaims;
          ret = {
            email,
            firstName: firstName || first,
            lastName: lastName || last,
          };
          if (Array.isArray(bookings) && bookings.length > 0 && passengers.length > 0) {
            currentBooking = bookings.find((b) => b.bookingId === bookingNumber);
          }
          if (currentBooking) {
            let { passengerNumber } = currentBooking;
            if (isTa && passengerNumber === undefined) {
              passengerNumber = 1;
            }
            return { ...ret, passengerNumber };
          }
          return ret;
        }
      )
    ),
    getCountryCodeFromCurrency: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], ({ currency } = {}) => decodeCountryCodeFromCurrency(currency))
    ),
    getCurrency: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], ({ currency } = {}) => currency)
    ),
    isAllGifCompleted: new Selector(({ getPassengers }) =>
      createSelector(getPassengers, (passengers) => passengers.every(({ GIFCompleted }) => GIFCompleted))
    ),
    isGifOverride: new Selector(({ getPassengers }) =>
      createSelector([getPassengers], (passengers) => {
        let gifOverride = passengers.reduce((acc, item) => {
          if (item.gifOverride === 'Y') {
            acc.push(true);
          }
          return acc;
        }, []);
        gifOverride = gifOverride.length === passengers.length;

        return gifOverride;
      })
    ),
    isSinglePassenger: new Selector(({ getPassengers }) =>
      createSelector([getPassengers], (passengers) => passengers.length === 1)
    ),
    getDaysToGoDateValue: new Selector(({ getBookingDetails, getFirstEncounterDate }) =>
      createSelector([getBookingDetails, getFirstEncounterDate], (bookingDetails = {}, firstEncounterDate) => {
        return firstEncounterDate || getDaysToGoDateHelper(bookingDetails);
      })
    ),
    getDaysToGo: new Selector(({ getDaysToGoDateValue }) =>
      createSelector([getDaysToGoDateValue], (daysToGoDateValue) =>
        Math.ceil(moment.duration(moment(daysToGoDateValue).diff(moment())).asDays())
      )
    ),
    getEmbarkDateValue: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], ({ voyage: { embarkDate } }) => embarkDate)
    ),

    getFirstEncounterDate: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], ({ passengers }) => {
        const minBookingSet = new Set();
        // extracting unique mincontactDate of passenger by converting it to miliseconds & adding it to Set
        passengers?.forEach((passengerData) => {
          const { minContactDate } = passengerData;
          if (minContactDate) {
            minBookingSet.add(new Date(minContactDate).getTime());
          }
        });

        // sorting so that the first value will get smallest date among all date present
        const uniqueMinBookingDate = Array.from(minBookingSet).sort();

        // converting back to the original date format recieved.
        const firstEncounterDate =
          uniqueMinBookingDate.length !== 0 ? moment(uniqueMinBookingDate[0]).format('YYYY-MM-DDTHH:mm:ss+00:00') : '';
        return firstEncounterDate;
      })
    ),

    getIsCloseToDepartureDate: new Selector(({ getDaysToGo }) =>
      createSelector(getDaysToGo, (daysToGo) => daysToGo <= DAYS_TO_GO.DEFAULT)
    ),
    getFeatureRestricted: new Selector(({ getIsCloseToDepartureDate, getIsViewOnly }) =>
      createSelector([getIsCloseToDepartureDate, getIsViewOnly], (isCloseToDepartureDate, isViewOnly) => {
        if (isCloseToDepartureDate) {
          return FEATURE_RESTRICTED.CLOSE_TO_SAILING;
        }
        if (isViewOnly) {
          return FEATURE_RESTRICTED.VIEW_ONLY;
        }
        return null;
      })
    ),
    getVoyageSailingStatus: new Selector(
      ({ getBookingDetails, getDaysToGoDateValue, getVoyageType, getIsCruiseExceptionUK }) =>
        createSelector(
          [getBookingDetails, getDaysToGoDateValue, getVoyageType, getIsCruiseExceptionUK],
          (bookingDetails, daysToGoDateValue, voyageType, isCruiseExceptionUK) => {
            if (bookingDetails && bookingDetails.voyage) {
              const {
                bookingStatus,
                voyage: { endDate, type },
              } = bookingDetails;
              const today = moment();
              const voyageStart = moment(daysToGoDateValue);
              const voyageEnd = moment(endDate);
              const isCurrentlySailing = today.isBetween(voyageStart, voyageEnd, 'days', '[]');
              const isSailingOver = today.isAfter(voyageEnd);
              const isCanceled = bookingStatus === 'CXL';

              /*
                Update the VOYAGE_STATUSES constant with a new key/vaue if another
                voyage status needs to be handled here
              */
              if (
                (voyageType === VOYAGE_TYPE.EXPEDITION && !ALLOW_EXPEDITIONS) ||
                (type === VOYAGE_TYPE.MISSISSIPPI && !ALLOW_MISSISSIPPI) ||
                isCruiseExceptionUK
              ) {
                return VOYAGE_STATUSES.COMING_SOON;
              }
              if (isCanceled) {
                return VOYAGE_STATUSES.CANCELED;
              }
              if (isCurrentlySailing) {
                return VOYAGE_STATUSES.SAILING;
              }
              if (isSailingOver) {
                return VOYAGE_STATUSES.SAILED;
              }
            }
            return VOYAGE_STATUSES.FUTURE;
          }
        )
    ),
    getSplashPageLink: new Selector(
      ({
        getBookingDetails,
        getAddBookingData,
        getActiveUserBookings,
        getUserType,
        getVoyageSailingStatus,
        getVoyageType,
        getIsCruiseExceptionUK,
        getUserData,
      }) =>
        createSelector(
          [
            getBookingDetails,
            getAddBookingData,
            getActiveUserBookings,
            getAuthData,
            getUserType,
            getVoyageSailingStatus,
            getVoyageType,
            getIsCruiseExceptionUK,
            getUserData,
          ],
          (
            bookingDetails,
            addBookingDetails,
            userBookings,
            authData,
            userType,
            sailingStatus,
            voyageType,
            isCruiseExceptionUK,
            userData
          ) => {
            /*
              sailingStatus also determines the route which is used to fetch
              data in the SplashPageStore fetchSplashPageContent() function.
            */
            if (!userData?.email) {
              return null;
            }

            const bookings = userType === USER_TYPES.TA ? [{ bookingId: authData?.agentBookingNumber }] : userBookings;
            const type = bookingDetails?.voyage?.type;
            if (userType && bookings.length === 0) {
              return VOYAGE_STATUSES.NO_BOOKINGS;
            }

            if (
              bookings.length === 0 ||
              (bookingDetails?.loaded && !bookingDetails.bookingNumber && addBookingDetails?.bookingID)
            ) {
              return VOYAGE_STATUSES.NO_BOOKINGS;
            }

            if (
              (voyageType === VOYAGE_TYPE.EXPEDITION && !ALLOW_EXPEDITIONS) ||
              (type === VOYAGE_TYPE.MISSISSIPPI && !ALLOW_MISSISSIPPI) ||
              isCruiseExceptionUK
            ) {
              return VOYAGE_STATUSES.COMING_SOON;
            }
            const { FUTURE } = VOYAGE_STATUSES;

            return sailingStatus !== FUTURE ? sailingStatus : null;
          }
        )
    ),
    getPassengerNumber: new Selector(({ getBookingDetails, getUserData }) =>
      createSelector([getBookingDetails, getUserData], ({ passengers = [], bookingNumber }, userData = {}) =>
        memoize((passengerData = passengers, addBooking) => {
          const { firstName, lastName, bookings: userBookings = [] } = userData;
          const userBooking = userBookings.find((booking) => booking.bookingId === bookingNumber) || {};
          if (userBooking.passengerNumber && !addBooking) {
            return userBooking.passengerNumber;
          }
          // for bookings with only 1 passenger
          if (passengerData.length === 1) {
            return passengerData[0].passengerNumber;
          }
          const filteredPassengers =
            firstName &&
            lastName &&
            passengerData.filter(
              (passenger) =>
                passenger.firstName === firstName.toUpperCase() && passenger.lastName === lastName.toUpperCase()
            );
          if (filteredPassengers && filteredPassengers.length) {
            return filteredPassengers.length === 1 ? filteredPassengers[0].passengerNumber : null;
          }
          return null;
        })
      )
    ),
    getUpdateUserData: new Selector(({ getUserType, getUserData }) =>
      createSelector([getUserType, getUserData], (userType, userData) => {
        const { email, firstName, lastName } = userData || {};
        return {
          updateUserType: EVO_USER_TYPES[userType],
          firstName,
          lastName,
          email,
        };
      })
    ),
    getSessionId: new Selector(({ getAuthData }) =>
      createSelector([getAuthData], (authData) => {
        let sessionId = null;
        // Should we use CSA's own uniqueID here?
        //  if we do, can EVO invalidate the customer's lock if they have one and replace it with the CSA lock
        sessionId = authData.uniqueId;
        return sessionId;
      })
    ),
    getUserEmailViaCSA: new Selector(({ getAuthData }) =>
      createSelector(getAuthData, (authData) => {
        return authData?.idTokenClaims?.email;
      })
    ),
    showTPCard: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], (bookingDetails = {}) => {
        if (bookingDetails.passengers && bookingDetails.passengers.length > 0) {
          const { passengers, saleDate, depositPaymentDate } = bookingDetails;
          const passengersWithInsurance = passengers.some((x) => x.hasInsurance === true);
          if (passengersWithInsurance) {
            return true;
          }
          let purchaseDate = moment(saleDate);
          if (depositPaymentDate) {
            purchaseDate = moment(depositPaymentDate);
          }
          const days = moment().diff(purchaseDate, 'days');
          return days < 15;
        }
        return false;
      })
    ),
    isExpedition: new Selector(({ getVoyageType }) =>
      createSelector(getVoyageType, (voyageType) => {
        return voyageType === VOYAGE_TYPE.EXPEDITION;
      })
    ),
    getIsGreatLakes: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], (bookingDetails) => {
        const name = get(bookingDetails, 'cruise.name', '');
        return GREAT_LAKES_CRUISES.includes(name);
      })
    ),
    getProtectionAmount: new Selector(({ getPassengers }) =>
      createSelector([getPassengers], (passengers) => {
        const protectionAmount = passengers.reduce((acc, passenger) => {
          const { insuranceAmount, insurancePaidDate } = passenger;
          if (!insurancePaidDate && typeof insuranceAmount === 'number') {
            return acc + insuranceAmount;
          }
          return acc;
        }, 0);

        return protectionAmount;
      })
    ),
    getIsMississippi: new Selector(({ getBookingDetails }) =>
      createSelector(
        [getBookingDetails],
        ({ voyage: { type: voyageType } = {} } = {}) => voyageType === VOYAGE_TYPE.MISSISSIPPI
      )
    ),
    getHasMississippiLeg: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], (details) => {
        return (
          details?.ship?.shipCode === 'MSP' ||
          details?.comboBookings?.some((booking) => booking.shipCode?.toUpperCase() === 'MSP')
        );
      })
    ),
    getHasLegOfVoyageType: new Selector(({ getBookingDetails }) =>
      createSelector([getBookingDetails], (details) => (...voyageTypes) => {
        if (!details) {
          return false;
        }
        const upperCaseVoyageTypes = voyageTypes.map((type) => type.toUpperCase());
        return [details.voyage?.type]
          .concat(details.comboBookings?.map((booking) => booking.voyageType) || [])
          .some((voyageType) => upperCaseVoyageTypes.includes(voyageType.toUpperCase()));
      })
    ),
    getOopsError: (state) => state?.user?.oopsPageError,
    getImpersonationData: (state) => state?.user?.impersonation,
    getBaseTrackingData: new Selector(({ getBookingNumber, getCountryCodeFromCurrency }) =>
      createSelector([getBookingNumber, getCountryCodeFromCurrency], (bookingNumber, officeCode) => {
        return {
          booking_id: bookingNumber,
          office_code: officeCode,
        };
      })
    ),
  },
});

const {
  creators: {
    lockout,
    pullFromHoldingArea,
    receiveAuthData,
    receiveBookings,
    receiveCart,
    receiveFatalError,
    receiveInvoicePricing,
    receiveTABookingNumber,
    receiveUserData,
    setAuthLoadingStatus,
    setBookingLoadingFlag,
    setInvoicePricingLoading,
    setImpersonationData,
    setMigrationDeepLink,
    setOopsPageError,
    updateLockedStatus,
    viewOnly,
  },
  selectors: {
    getAddBookingData,
    getAuthData,
    getBookingDetails,
    getEmail,
    getIsTaAccount,
    getLinkQueryParams,
    getLoggedInUser,
    getLoginPathUrl,
    getMigrateFlag,
    getMigrationDeepLink,
    getPassengerNumber,
    getSessionId,
    getUserBookings,
    getUserData,
    getUsername,
    getUserType,
    getImpersonationData,
  },
} = userStore;

export const fetchBookings = (bookingId, refreshData = false, updateImmediately = true, fromHolding, passengerId) => (
  dispatch,
  getState
) => {
  if (fromHolding) {
    return dispatch(pullFromHoldingArea('bookingDetails.booking', 'bookingDetails'));
  }
  const bookingNumber = bookingId || get(getBookingDetails(getState()), 'bookingNumber');
  const params = passengerId ? { passengerId } : {};
  const url = buildUrl('/bookings/get', ['bookingNumber'], { bookingNumber }, params);
  return dispatch(
    getData({
      url,
      store: userStore,
      node: 'bookingDetails',
      creator: receiveBookings,
      updateImmediately,
      refreshData,
    })
  );
};

export const signOut = () => (dispatch, getState) => {
  const state = getState();
  const isTaAccount = getIsTaAccount(state);
  dispatch(setImpersonationData({}));

  if (isTaAccount) {
    return window.location.replace(TA_PORTAL_URL);
  }

  return msalInstance.logoutRedirect();
};

const handleLockOutCode = (lockedCode, lockId) => (dispatch, getState) => {
  const sessionId = getSessionId(getState());
  const userType = getUserType(getState());
  const lockOut =
    lockedCode.match(LOCK_CODES.LOCK_CODE_04) ||
    (userType === EVO_USER_TYPES.CSA && lockedCode.match(LOCK_CODES.LOCK_CODE_03));
  const isLocked = (lockId && sessionId.substring(sessionId.length - 10) !== lockId) || lockOut;
  // do not trigger lock state if lockId matches within sessionId
  // Hierarchy of lock codes:
  /*
  1) Lock04 - All cases lock out splash page
  2) Lock03 - CSA lockout, user allowed access unless other code present
  3) Lock01 or Lock02: user and CSA view only if not lock owner
  */

  if (isLocked) {
    // New logic for Lock Codes
    if (lockOut) {
      // redirect to unavailable page
      dispatch(viewOnly(false));
      dispatch(lockout(true));
      navigateTo(APP_PATHS.CURRENTLY_UNAVAILABLE);
    } else {
      // set viewOnly mode
      dispatch(updateLockedStatus(true));
      dispatch(viewOnly(true));
      dispatch(lockout(false));
    }
  }
};

export const refreshLockStatus = () => (dispatch, getState) => {
  const state = getState();
  const booking = getBookingDetails(state);
  const sessionId = getSessionId(state) || '';
  const {
    bookingNumber,
    office,
    currency,
    ship: { shipCode } = {},
    passengers: [{ lastName }] = [{}],
    guestDepartureDate,
  } = booking;

  const payload = {
    office,
    currency,
    shipCode,
    lastName,
    guestDepartureDate,
  };

  const url = buildUrl('/booking', ['bookingNumber', 'type', 'sessionId'], {
    bookingNumber,
    type: LOCK_TYPES.REFRESH,
    sessionId,
  });

  return dispatch(
    putData({
      url,
      values: payload,
    })
  ).then((response) => {
    const { lockedCode, lockId } = response?.data;
    const isLocked = lockedCode && sessionId.substring(sessionId.length - 10) !== lockId;

    if (!response.isSuccessful) {
      const userType = getUserType(state);
      if (userType === USER_TYPES.CSA || userType === USER_TYPES.AIR) {
        // TODO: determine if we need to invalidate any data when redirecting CSA or TA
        // back to respective portals
      } else if (isLocked) {
        dispatch(handleLockOutCode(lockedCode, lockId));
      } else {
        dispatch(viewOnly(true));
        dispatch(lockout(true));
      }
    }
    if (
      response.isSuccessful &&
      response.data &&
      response.data.errorCode &&
      response.data.errorCode !== '409' &&
      !lockedCode
    ) {
      dispatch(viewOnly(true));
      dispatch(lockout(true));
    }
    if (isLocked) {
      dispatch(handleLockOutCode(lockedCode));
    }
  });
};

export const verifyLockUnlockStatus = ({
  type,
  signOutFlag,
  bookingId,
  isOriginBookingId,
  traceparent,
  tracestate,
  passengerNumber,
  inviteeLastName,
}) => (dispatch, getState) => {
  const state = getState();
  const booking = getBookingDetails(state);
  const { bookingNumber = bookingId } = booking;
  const payload = {};
  if (type === LOCK_TYPES.UNLOCK) {
    const {
      office,
      currency,
      ship: { shipCode } = {},
      passengers: [{ lastName }] = [{}],
      guestDepartureDate,
    } = booking;
    payload.office = office;
    payload.currency = currency;
    payload.shipCode = shipCode;
    payload.lastName = inviteeLastName || lastName;
    payload.guestDepartureDate = guestDepartureDate;
  }
  dispatch(lockout(false));
  let lockUnlockBookingId;
  lockUnlockBookingId = isOriginBookingId ? bookingNumber : bookingId;
  if (bookingId === undefined) {
    lockUnlockBookingId = bookingNumber;
  }
  lockUnlockBookingId = lockUnlockBookingId ? `${lockUnlockBookingId}`.trim() : lockUnlockBookingId;
  const sessionId = getSessionId(state) || '';
  const url = buildUrl(`/booking/${lockUnlockBookingId}/${type}/${sessionId}`);
  if (signOutFlag) {
    dispatch(setAuthLoadingStatus(true));
  }
  if (!booking?.bookingNumber) {
    dispatch(setBookingLoadingFlag(true));
  }
  if (!lockUnlockBookingId && type === LOCK_TYPES.LOCK) {
    dispatch(setBookingLoadingFlag(false));
    dispatch(updateLockedStatus(false));
    return Promise.resolve();
  }
  if (!lockUnlockBookingId) {
    if (signOutFlag) {
      dispatch(signOut());
    }
    dispatch(viewOnly(false));
    dispatch(lockout(true));
    dispatch(setBookingLoadingFlag(false));
    dispatch(updateLockedStatus(false));
    return null;
  }

  return dispatch(
    putData({
      url,
      values: payload,
      config: {
        traceparent,
        tracestate,
        headers: {
          passengerNumber,
        },
      },
    })
  ).then(async (response) => {
    const { statusCode } = response?.data || {};
    if (statusCode === '205') {
      // update dynamoDB with bookingStatus of past
      const userBookings = getUserBookings(state);
      let nextActiveBooking = null;
      const remappedBookings = userBookings.map((booking) => {
        const matchedBooking = booking?.bookingId === bookingId;
        if (matchedBooking) {
          return {
            ...booking,
            bookingStatus: BOOKING_STATUSES.PAST,
            pastBooking: true,
            lastViewed: false,
          };
        }
        if (!nextActiveBooking && booking.bookingStatus !== BOOKING_STATUSES.PAST) {
          nextActiveBooking = booking.bookingId;
          return {
            ...booking,
            lastViewed: true,
          };
        }
        return booking;
      });
      await dispatch(updateUserData({ bookings: remappedBookings }));

      if (nextActiveBooking) {
        return dispatch(verifyLockUnlockStatus({
          type,
          bookingId,
          isOriginBookingId,
          traceparent,
          tracestate,
          passengerNumber,
        }));
      } else {
        navigateTo(APP_PATHS.NO_ACTIVE_BOOKINGS);
        dispatch(setBookingLoadingFlag(false));
        return null;
      }
    }

    const { lockedCode, lockId } = response?.data?.booking || {};
    if (
      signOutFlag ||
      (type === LOCK_TYPES.LOCK &&
        !response.isSuccessful &&
        ![LOCK_CODES.LOCK_CODE_01, LOCK_CODES.LOCK_CODE_02].includes(lockedCode))
    ) {
      const userType = getUserType(state);
      if (userType === USER_TYPES.CSA || userType === USER_TYPES.AIR) {
        // TODO: determine if we need to invalidate any data when redirecting CSA or TA
        // back to respective portals
      }
      if (signOutFlag) {
        removeSessionInfo();
        dispatch(signOut());
      } else {
        dispatch(viewOnly(false));
        dispatch(lockout(true));
      }
    }

    if (type === LOCK_TYPES.LOCK && response.isSuccessful) {
      const email = getEmail(state);
      updateCustomDimensions({
        accountId: response.data?.bookingNumber,
        email,
      });
      // Sometimes we see no cruise object returned when CMS isn't fully published
      if (response.data?.booking?.cruise?.error) {
        logger({
          type: APP_INSIGHTS_TRACK_TYPE.TRACE,
          name: 'Missing Cruise Data',
          message: 'No cruise info returned when locking booking',
          severity: SeverityLevel.Warning,
          logData: {
            cruiseName: response.data?.booking?.cruise?.name,
          },
        });
      }

      if (lockedCode) {
        dispatch(handleLockOutCode(lockedCode, lockId));
      }

      if (response.data && response.data.errorCode && response.data.errorCode !== '409' && !lockedCode) {
        // default fallback for lock error
        dispatch(viewOnly(false));
        dispatch(lockout(true));
      } else if (lockedCode) {
        dispatch(handleLockOutCode(lockedCode, lockId));
      } else {
        dispatch(viewOnly(false));
        dispatch(lockout(false));
      }
    }

    const responseBooking = get(response, 'data.booking', null);
    if (responseBooking) {
      dispatch(receiveBookings(response.data));
    }

    dispatch(setBookingLoadingFlag(false));
    dispatch(updateLockedStatus(response.isSuccessful));
    return response;
  });
};

export const fetchCart = (refreshData = false, updateImmediately) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  if (bookingDetails?.voyage) {
    const { comboBookings, voyage } = bookingDetails || {};
    const comboVoyageIds = comboBookings?.map(({ voyageId }) => voyageId).join('|');
    const formattedComboBookings = comboBookings
      .reduce((acc, combo) => {
        acc.push(`${combo.invoice}:${combo.shipCode}`);
        return acc;
      }, [])
      .join('|');

    const formatDate = (dateTime) => dateTime && dateTime.split('T')[0];
    const params = {
      shipId: get(bookingDetails, 'ship.shipCode'),
      voyageIds: comboVoyageIds || get(voyage, 'id', ''),
      startDate: formatDate(voyage?.startDate),
      endDate: formatDate(voyage?.endDate),
      comboBookings: formattedComboBookings,
    };

    return dispatch(
      getData({
        url: buildUrl(
          '/cart',
          ['office', 'currency', 'bookingNumber', 'voyage.id'],
          {
            ...bookingDetails,
          },
          params
        ),
        store: userStore,
        node: 'cart',
        creator: receiveCart,
        refreshData,
        updateImmediately,
      })
    ).then((res) => {
      if (res?.data?.errorCode === '409') {
        dispatch(setOopsPageError(OOPS_PAGE_ERRORS.DUPLICATE_CART));
        navigateTo(APP_PATHS.OOPS_PAGE);
      }
    });
  }
  return {};
};

export const reloadBookings = (bookingId, updateImmediately = true, fromHolding) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const bookingNumber = bookingId || bookingDetails?.bookingNumber;
  dispatch(fetchCart(true, updateImmediately));
  return dispatch(fetchBookings(bookingNumber, true, updateImmediately, fromHolding));
};

export const validateBookingEmail = (email) => (dispatch) =>
  dispatch(
    getData({
      url: buildUrl('/email/validated', ['email'], { email: btoa(email) }),
      store: userStore,
    })
  ).catch((err) => err);

export const handleUpdateLoggedInUser = ({ authData, bookingId, receivedBookings }) => (dispatch, getState) => {
  const state = getState();
  const userData = getUserData(state);
  const userBookings = getUserBookings(state);

  if (!bookingId && !receivedBookings) {
    dispatch(receiveAuthData(authData));
    return Promise.resolve();
  }
  // update DynamoDB history here
  const { userType } = getUserType(state);
  const bookings = receivedBookings || userBookings;

  if (!bookings?.length) {
    if (userType !== USER_TYPES.TA) {
      return dispatch(updateUserData({ bookings: [] })).then((response) => {
        return Promise.resolve({ userData: response });
      });
    }
    return Promise.resolve({ userData });
  }

  const newBookings = bookings.map((booking) => {
    if (booking.bookingId === bookingId) {
      return {
        ...booking,
        lastViewed: true,
      };
    }
    return {
      ...booking,
      lastViewed: false,
    };
  });
  // sort by departureDate descending
  newBookings.sort((a, b) => new Date(a?.departureDate) - new Date(b?.departureDate));

  // Set passengerNumber to 1 for TA users to bypass PTC and Migration Page
  if (userType === USER_TYPES.TA) {
    newBookings[0].passengerNumber = 1;
  } else if (
    JSON.stringify(userBookings) !== JSON.stringify(newBookings) ||
    newBookings?.some(({ removeBooking }) => removeBooking)
  ) {
    // update history to reflect change
    return dispatch(updateUserData({ bookings: newBookings })).then((response) => {
      return Promise.resolve({ userData: response });
    });
  }
  return Promise.resolve({ userData: { ...userData, bookings: newBookings } });
};

export const handlePtcAcceptance = ({ versionNumber, passengerId, addBooking }) => (dispatch, getState) => {
  const state = getState();
  const userName = getUsername(state);
  const { passengers } = getBookingDetails(state);
  const userType = getUserType(state);
  const addBookingData = getAddBookingData(state);
  let { bookingNumber } = {};
  if (addBooking) {
    ({ bookingID: bookingNumber } = addBookingData);
  } else {
    ({ bookingNumber } = getBookingDetails(state));
  }
  // TODO: update MT to return string
  // cast to string as Core/ MT is returning number
  bookingNumber = `${bookingNumber}`;
  // update userData with passengerNumber
  return new Promise((resolve, reject) => {
    if (userType === USER_TYPES.CONSUMER) {
      try {
        const userData = getUserData(state);
        const authData = getAuthData(state);
        const userBookings = getUserBookings(state);
        let updatedBookings = [];
        if (addBooking) {
          const { bookingStatus, cruiseName, departureDate, voyageID } = addBookingData;
          updatedBookings = [
            {
              bookingId: bookingNumber,
              bookingStatus,
              cruiseName,
              departureDate,
              lastViewed: false,
              passengerNumber: passengerId,
              ptcAccepted: { date: moment.utc().format(DB_DATE_FORMAT.LONG), versionNumber, userName },
              voyageId: voyageID,
            },
          ];
        } else {
          // Update userData with passengerNumber
          updatedBookings = userBookings.map((booking) => {
            if (booking?.bookingId === bookingNumber) {
              return {
                ...booking,
                passengerNumber: passengerId,
                ptcAccepted: { date: moment.utc().format(DB_DATE_FORMAT.LONG), versionNumber, userName },
              };
            }
            return booking;
          });
        }
        const updatedUserData = {
          ...userData,
          bookings: updatedBookings,
        };
        let firstName = '';
        const allowNameChange =
          (!userData?.bookings?.length && addBooking) || (userData?.bookings.length === 1 && !addBooking);
        if (allowNameChange) {
          if (passengers && !addBooking) {
            ({ firstName } = passengers.find(({ passengerNumber }) => passengerNumber === passengerId));
          } else if (addBooking) {
            ({ firstName } = addBookingData?.passengers.find(({ passengerNumber }) => passengerNumber === passengerId));
          } else {
            // Error here as this case should not happen
            logAdvisoryCode({
              advisoryCode: '51192',
              message: 'Error in Client logic when accepting PTC and allowing name change',
              logData: {
                passengerId,
                passengers,
                addBooking,
                addBookingData,
                userData,
                authData,
              },
            });
            setOopsPageError(OOPS_PAGE_ERRORS.DEFAULT);
            return navigateTo(APP_PATHS.OOPS_PAGE);
          }
          if (firstName && !safeStringCompare(firstName, userData?.firstName)) {
            updatedUserData.firstName = firstName;
            updatedUserData.userId = authData?.uniqueId;
            updatedUserData.isPTC = true;
          }
        }

        return dispatch(updateUserData(updatedUserData, addBooking, null, updatedUserData?.isPTC)).then((res) => {
          triggerLinkEvent({
            event_name: UTM_EVENT_NAMES.ACCOUNT_CREATION,
            booking_id: bookingNumber,
            cognito_customer_id: userData.userId,
          });
          return resolve({ ...res });
        });
      } catch (err) {
        return reject(err);
      }
    }
    return resolve();
  });
};

export const handleAutoAcceptPtc = ({ country, passengerNumber, addBooking, ptcRef }) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const userBookings = getUserBookings(state);
  const { bookingNumber } = bookingDetails;
  const currentBooking = userBookings.find((booking) => booking.bookingId === bookingNumber);
  const office = country || get(bookingDetails, 'office', '');
  const shouldPostPTC = addBooking || !currentBooking?.passengerNumber;
  const passengerId = passengerNumber || getPassengerNumber(state)();
  if (passengerId && office === 'UK' && shouldPostPTC) {
    return dispatch(
      handlePtcAcceptance({
        versionNumber: 'NA',
        passengerId,
        addBooking,
      })
    ).then(() => Promise.resolve({ passengerId }));
  }
  // auto accept PTC if booking has passengerNumber but no ptcAccepted property in new userData table
  if (currentBooking?.passengerNumber && !currentBooking?.ptcAccepted) {
    return dispatch(
      handlePtcAcceptance({
        versionNumber: ptcRef,
        passengerId: currentBooking.passengerNumber,
        addBooking,
      })
    ).then(() => Promise.resolve({ passengerId }));
  }
  return Promise.resolve();
};

export const validateBooking = (bookingNumber, includeFirstName = false) => (dispatch, getState) => {
  const { lastName, firstName } = getLoggedInUser(getState());
  const htmlEncodedLastName = encodeURIComponent(lastName);
  const htmlEncodedFirstName = encodeURIComponent(firstName);
  const queryParams = {};
  if (includeFirstName) {
    queryParams.firstName = htmlEncodedFirstName;
  }
  const url = buildUrl(
    '/auth/validatebooking',
    ['lastName', 'bookingNumber'],
    {
      lastName: htmlEncodedLastName,
      bookingNumber,
    },
    queryParams
  );
  return dispatch(
    getData({
      url,
      store: userStore,
    })
  );
};

export const validateUserBookings = (authData, bookingData) => (dispatch, getState) => {
  const state = getState();
  const userType = getUserType(state);
  const bookings = bookingData || getUserBookings(state);
  if (userType && userType !== USER_TYPES.CONSUMER) {
    const bookingId = bookings?.[0]?.bookingId;
    return Promise.resolve({ bookingId, validatedAuthData: authData, validatedBookings: bookings });
  }
  const migrate = getMigrateFlag(state);
  const { firstName, lastName } = getLoggedInUser(state);
  let bookingId;
  if (bookings.length === 0) {
    return Promise.resolve({ validatedAuthData: authData });
  }
  const params = {};
  if (!migrate) {
    params.firstName = firstName;
  }
  const url = buildUrl('/auth/validatebookings', ['lastName'], { lastName }, params);
  return dispatch(
    postData({
      url,
      store: userStore,
      values: bookings,
    })
  ).then((res) => {
    const { data } = res;
    // TODO Better error handling
    // on timeout, no data comes back for valid bookings, default to using the first
    // bookingId from the non validated bookings
    if (!data || data.errorCode) {
      logger({
        type: APP_INSIGHTS_TRACK_TYPE.TRACE,
        name: 'Validate Booking Error',
        message: 'validatebookings() failed',
        severity: SeverityLevel.Warning,
        logData: {
          bookings: JSON.stringify(bookings),
          errorCode: data?.errorCode || '(N/A)',
          errorDescription: data?.errorDescription || '(N/A)',
        },
      });
      return Promise.resolve({ bookingId: bookings[0].bookingId, validatedAuthData: authData });
    }
    debugLog('MSAL DATA', JSON.stringify(data));
    if (data?.length) {
      ({ bookingId } = data?.find((booking) => booking.lastViewed) || data[0] || {});
    }
    // update user Data to reflect valid bookings
    if (data.length !== bookings.length || JSON.stringify(data) !== JSON.stringify(bookings)) {
      const updatedBookings = bookings.map((inBooking) => {
        const matchedBooking = data?.find((validBooking) => validBooking.bookingId === inBooking.bookingId);
        return matchedBooking || { ...inBooking, removeBooking: true };
      });
      return dispatch(handleUpdateLoggedInUser({ authData, bookingId, receivedBookings: updatedBookings })).then(
        (response) => {
          debugLog(`MS: ${JSON.stringify(response)}`);
          return { bookingId, validatedBookings: response?.userData?.bookings };
        }
      );
    }
    return Promise.resolve({
      bookingId,
      validatedBookings: data,
    });
  });
};

export const getAuthTokens = (accountId) => (dispatch) => {
  const url = buildUrl('/auth/get', ['accountId'], { accountId });
  return new Promise((resolve) =>
    dispatch(
      getData({
        url,
        store: userStore,
      })
    ).then((res) => resolve(res))
  );
};

export const deleteAuthTokens = (accountId) => (dispatch) => {
  const url = buildUrl('/auth/delete', ['accountId'], { accountId });
  return dispatch(
    deleteData({
      url,
    })
  );
};

export const getDeepLinkExcursionDate = (shoreExCode) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  if (!shoreExCode) {
    return Promise.resolve();
  }
  const url = buildUrl(
    '/excursions',
    ['office', 'currency', 'bookingNumber', 'serviceCodeLiteral', 'shoreExCode', 'ship.shipCode'],
    {
      ...bookingDetails,
      serviceCodeLiteral: 'serviceCodes',
      shoreExCode,
    }
  );

  return dispatch(
    getData({
      url,
      store: userStore,
    })
  );
};

export const handleDeepLink = () => (dispatch, getState) => {
  const state = getState();
  const redirectPath = getMigrationDeepLink(state);
  const redirectParams = getLinkQueryParams(state);
  const loginPathUrl = getLoginPathUrl(state);
  const { shoreExCode, path } = redirectParams;
  const loginPathSections = loginPathUrl.split('/').filter((segment) => !!segment);

  if (shoreExCode) {
    dispatch(getDeepLinkExcursionDate(shoreExCode)).then((data) => {
      if (data && data.date) {
        history.push(`${APP_PATHS.SHORE_EXCURSIONS}/${data.date}`);
      }
    });
  } else if (redirectPath) {
    setMigrationDeepLink(undefined);
    history.push(redirectPath);
  } else if (path) {
    history.push(unescape(path));
  } else if (
    loginPathSections.length &&
    !loginPathSections.every((segment) => segment === 'login' || segment === 'myjourney')
  ) {
    history.push(loginPathUrl);
  }
};

export const addBookingGetPassengerNumber = (passengersData) => (dispatch, getState) => {
  const state = getState();
  const addBookingData = getAddBookingData(state);
  const passengers = passengersData || addBookingData.passengers;
  const passengerId = getPassengerNumber(getState())(passengers, true);
  return Promise.resolve(passengerId);
};

export const refreshIdTokenExpiration = () => (dispatch) => {
  const refreshRequest = { scopes: MSAL_CONFIG.auth.scopes };
  debugLog('MSAL: acquire token: 2');
  return msalInstance
    .acquireTokenSilent(refreshRequest)
    .then((res) => {
      dispatch(receiveAuthData(res));
      // store token
    })
    .catch(() => {
      // Handle error, maybe trigger a new login
    });
};

export const closeToSailingAlertSeen = () => (dispatch, getState) => {
  const state = getState();
  const userData = getUserData(state);
  const { bookings } = userData;
  const { bookingNumber } = getBookingDetails(state);
  const updatedBookings = bookings.map((booking) => {
    if (booking.bookingId === bookingNumber) {
      return {
        ...booking,
        closeToSailingAlerted: true,
      };
    }
    return booking;
  });
  const updatedUserData = {
    ...userData,
    bookings: updatedBookings,
  };
  return dispatch(updateUserData(updatedUserData));
};

export const validateAndUpdateLoggedInUser = (authData, userData) => async (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  if (!bookingDetails?.bookingNumber) {
    dispatch(setBookingLoadingFlag(true));
  }
  const { bookingId: currentBookingId, validatedBookings } = await dispatch(validateUserBookings(authData, userData));
  if (!currentBookingId) {
    dispatch(setBookingLoadingFlag(false));
    return Promise.resolve({
      currentBookingId,
      validatedBookings,
    });
  }
  return dispatch(
    handleUpdateLoggedInUser({
      authData,
      bookingId: currentBookingId,
      receivedBookings: validatedBookings,
    })
  ).then((updatedUserData) => {
    dispatch(setBookingLoadingFlag(false));
    return Promise.resolve({
      currentBookingId,
      validatedBookings: updatedUserData?.bookings || validatedBookings,
    });
  });
};

export const fetchInvoicePricing = (cart = []) => (dispatch, getState) => {
  const state = getState();
  const {
    attn,
    bookingNumber,
    office,
    currency,
    packageType,
    rateCode,
    email,
    passengers,
    voyage,
    ship,
  } = getBookingDetails(state);
  const { stateroomCategory, stateroomNumber } = ship;
  const { id: voyageId } = voyage;

  const url = buildUrl('/abe/pricing', ['office', 'currency', 'voyageId', 'numberOfPassengers', 'invoiceLiteral'], {
    office,
    currency,
    voyageId,
    numberOfPassengers: passengers.length,
    invoiceLiteral: 'invoice',
  });

  const cartMappedPassengers = passengers.map((pax) => {
    const { HOTEL, LAND_PACKAGE, SHOREX, GRATITUDE_OR_SSBP } = EXTENSION_TYPES;
    const { passengerNumber } = pax;
    const mappedExtensions = [];
    cart.forEach((item) => {
      const forPax = item?.forPassenger?.[passengerNumber - 1];
      if (forPax && forPax?.inCart) {
        const ext = {
          extensionCode: item.inventoryCode,
          // numberOfNights is only required for Hotel and Land package extensions per EVO rules
          numberOfNights: [HOTEL, LAND_PACKAGE].includes(item.extensionType) ? item?.numberOfNights : 0,
          TypeCode: item.extensionType,
          extensionTypeCode: item.extensionType,
          roomType: stateroomCategory,
          extensionPrice: item.totalItemPrice,
        };

        if ([SHOREX, GRATITUDE_OR_SSBP].includes(item.extensionType)) {
          ext.voyageId = item.voyageId || voyageId;
        }

        mappedExtensions.push(ext);
      }
    });
    return {
      ...pax,
      extensions: mappedExtensions,
      // TPP is currently not allowed to be added to cart and is call in only
      travelProtection: pax.hasInsurance,
    };
  });

  const invoicePayload = {
    voyage,
    bookingId: bookingNumber,
    rateCode,
    stateroomCategory,
    stateroomNumber,
    numberOfPassengers: passengers.length,
    attn,
    email,
    sendInvoice: false,
    packageType,
    passengers: cartMappedPassengers,
  };

  return dispatch(
    postData({
      url,
      values: invoicePayload,
    })
  ).then((res) => {
    // TODO: remove this step once MVA returns errors not under status code 200
    if (res?.data?.errorCode || !res?.data?.passengers) {
      // redirect to oops page
      navigateTo(APP_PATHS.OOPS_PAGE);
      return Promise.reject(res.data);
    }
    dispatch(receiveInvoicePricing(res.data));
    dispatch(setInvoicePricingLoading(false));
    return Promise.resolve(res);
  });
};

export const postUserData = () => (dispatch, getState) => {
  const state = getState();

  let postBody = {};
  const {
    idTokenClaims: { given_name: firstName, family_name: lastName, email, sub, BookingNumber: bookingNumber },
  } = getAuthData(state);

  const bookingIds = bookingNumber ? [bookingNumber] : [];

  postBody = {
    email,
    firstName,
    lastName,
    sub,
    bookingIds,
  };

  const url = buildUrl('/auth/user/create');

  return dispatch(
    postData({
      url,
      values: postBody,
    })
  )
    .then((res) => {
      // Verify post was success, nothing else to do
      return res;
    })
    .catch((error) => {
      // Add error handling if userData entry cannot be created
      return error;
    });
};

export const fetchUserData = (email) => async (dispatch, getState) => {
  const state = getState();
  const userType = getUserType(state);

  // Get auth data
  const authData = getAuthData(state);

  let userEmail = email;
  if (authData?.idTokenClaims?.UserType === USER_TYPES.TA) {
    const { email, given_name: firstName, family_name: lastName } = authData.idTokenClaims;
    const userData = {
      bookings: [
        { bookingId: `${authData?.agentBookingNumber}`, passengerNumber: 1, ptcAccepted: true, lastViewed: true },
      ],
      email,
      firstName,
      lastName,
    };
    dispatch(receiveUserData(userData));
    return userData;
  }

  if (!email) {
    userEmail = authData?.idTokenClaims?.email;
  }

  const url = buildUrl('/auth/user/get', ['email'], {
    email: base64EncodeString(userEmail),
  });

  return dispatch(
    getData({
      url,
      store: userStore,
      node: 'userData',
      refreshData: true,
    })
  ).then((res) => {
    if (!res || res.status !== RESPONSE_STATUS.SUCCESS_200) {
      if (res?.data?.advisoryCode) {
        const requestConfig = getRequestConfig({ state, timestamp: true, url });
        handleAdvisoryCode({
          advisoryCode: res?.data?.advisoryCode,
          url,
          message: res?.data?.errorDescription,
          requestConfig,
        });
      }
      dispatch(receiveFatalError());
      navigateTo(APP_PATHS.OOPS_PAGE);
      return res;
    }
    if (userType === USER_TYPES.CSA) {
      const { bookingNumber, passengerNumber } = getImpersonationData(state);
      const booking = res.bookings?.find((booking) => booking?.bookingId === bookingNumber);
      if (booking) {
        booking.passengerNumber = passengerNumber;
      }
    }
    // if no userData: post new userData to create
    if (!res.email) {
      // post userData
      return dispatch(postUserData()).then(({ data = {} }) => {
        if (data?.userData) {
          dispatch(receiveUserData(data.userData));
          return data.userData;
          // I believe this case will no longer be triggered.
          // Account would be created with no active bookings
        } else if (Number(data.statusCode) === RESPONSE_STATUS.FAILURE_ERROR_CODE_424) {
          if (data?.advisoryCode) {
            const requestConfig = getRequestConfig({ state, timestamp: true, url });
            handleAdvisoryCode({
              advisoryCode: data?.advisoryCode,
              url,
              message: data?.errorDescription,
              requestConfig,
            });
          }
          dispatch(receiveFatalError());
          dispatch(setOopsPageError(OOPS_PAGE_ERRORS.ALREADY_BOOKED));
          navigateTo(APP_PATHS.OOPS_PAGE);
          return data;
        }
        return data;
      });
    } else if (!res?.userId && authData?.uniqueId) {
      return dispatch(
        updateUserData(
          {
            ...res,
            userId: authData.uniqueId,
          },
          false,
          res
        )
      ).then((res) => {
        return res.userData;
      });
    }

    // If userData: add to redux store
    if (res?.email) {
      delete res.status;
      dispatch(receiveUserData(res));
    }

    return res;
  });
};

export const fetchUserId = (email, bookingId) => async (dispatch, getState) => {
  const state = getState();
  const url = buildUrl('/auth/user/get', ['email', 'bookingId'], {
    email,
    bookingId,
  });

  return dispatch(
    getData({
      url,
      store: userStore,
      node: 'userData',
      refreshData: true,
    })
  ).then((res) => {
    if (!res?.isSuccessful) {
      if (res?.data?.advisoryCode) {
        const requestConfig = getRequestConfig({ state, timestamp: true, url });
        handleAdvisoryCode({
          advisoryCode: res?.data?.advisoryCode,
          url,
          message: res?.data?.errorDescription,
          requestConfig,
        });
      }
      dispatch(receiveFatalError());
      navigateTo(APP_PATHS.OOPS_PAGE);
      return res;
    }
    return res;
  });
};

export const updateUserData = (modifiedUserData, addBooking, interimData, skipUpdate) => (dispatch, getState) => {
  const state = getState();
  const userData = interimData || getUserData(state);
  const userType = getUserType(state);
  if (userType === USER_TYPES.TA) {
    return Promise.resolve(userData);
  }

  const updatedData = { ...modifiedUserData };
  const payload = {
    email: userData?.email,
  };
  if (modifiedUserData?.isPTC) {
    payload.userId = modifiedUserData?.userId;
    payload.isPTC = true;
    delete updatedData.userId;
    delete updatedData.isPTC;
  }

  const updateData = Object.keys(userData).reduce((acc, key) => {
    if (
      (typeof userData[key] === 'object' && JSON.stringify(userData[key]) !== JSON.stringify(updatedData[key])) ||
      (typeof userData[key] === 'string' && !safeStringCompare(userData[key], updatedData[key])) ||
      (!['object', 'string'].includes(typeof userData[key]) && userData[key] !== updatedData[key])
    ) {
      acc[key] = updatedData[key];
    }
    return acc;
  }, {});

  Object.keys(updatedData).forEach((key) => {
    if (!Object.keys(userData).includes(key)) {
      updateData[key] = updatedData[key];
    }
  });

  payload.updateData = updateData;
  // update to not send if nothing is being updated

  const url = buildUrl('/auth/user/update');
  return dispatch(
    putData({
      url,
      values: payload,
    })
  ).then((res) => {
    if (!res?.isSuccessful) {
      if (res?.data?.advisoryCode) {
        const requestConfig = getRequestConfig({ state, timestamp: true, url });
        handleAdvisoryCode({
          advisoryCode: res?.data?.advisoryCode,
          url,
          message: res?.data?.errorDescription,
          requestConfig,
        });
      }
      dispatch(receiveFatalError());
      navigateTo(APP_PATHS.OOPS_PAGE);
      return res;
    }
    const userData = res?.data?.response;
    dispatch(receiveUserData(userData));
    // update redux with the new data returned from DynamoDB
    if (!addBooking && !skipUpdate) {
      return dispatch(fetchUserData(userData.email));
    }
    return Promise.resolve();
  });
};

export const handleAzureAuth = (authData) => async (dispatch, getState) => {
  const data = authData || getAuthData(getState());
  const { email, UserType, sub } = data?.idTokenClaims || {};
  let tokenData = {};
  if (UserType === USER_TYPES.TA) {
    tokenData = await dispatch(getAuthTokens(sub));
    if (tokenData.status === 404 || tokenData.data?.errorCode || !tokenData.bookingNumber) {
      window.location.replace(TA_PORTAL_URL);
      return tokenData;
    }

    dispatch(receiveTABookingNumber(tokenData.bookingNumber));
    dispatch(deleteAuthTokens(sub));
  }

  const fetchData = await dispatch(fetchUserData(email));
  return { ...fetchData, tokenData };
};

export default userStore;
