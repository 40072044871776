import { connect } from 'react-redux';
import { compose } from 'redux';

import airStore, { fetchTabContent } from '../AirStore';
import { withContent } from '../../../components';
import { TAB_NAMES } from '../../../common/Constants';

import Transfers from './Transfers';

const { getTabContent } = airStore.selectors;

const mapStateToProps = (state) => ({
  content: getTabContent(state)(TAB_NAMES.TRANSFERS),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(TAB_NAMES.TRANSFERS)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Transfers);
