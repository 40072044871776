import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';

import airStore, { fetchTabContent } from '../AirStore';
import { withContent } from '../../../components';
import { setViewAndShowModal } from '../../../common/ModalStore';
import { TAB_NAMES, FORMS } from '../../../common/Constants';
import userStore from '../../../common/UserStore';

import AirBooking from './AirBooking';

const { getAirModalData, getAirBookingCards, getAirScheduleData } = airStore.selectors;
const { getPassengers } = userStore.selectors;

const mapStateToProps = (state) => {
  const passengers = getPassengers(state);
  const isSubmittingArr = passengers.map(({ passengerNumber }) =>
    isSubmitting(`${FORMS.BOOKING}-${passengerNumber}`)(state)
  );
  const isLocked = isSubmittingArr.some((submitting) => submitting);

  return {
    isSubmitting: isLocked,
    airSchedules: getAirScheduleData(state),
    content: getAirBookingCards(state),
    modalData: getAirModalData(state),
    isSubmittingArr,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(TAB_NAMES.AIR_BOOKING)),
  handleCardDetailsClicked: (type, id) => dispatch(setViewAndShowModal(type, { id })),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(AirBooking);
