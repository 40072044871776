import PropTypes from '@viking-eng/prop-types';
import { ACH, CREDIT_CARD, NOTIFICATION_TYPES } from './common/Constants';

const {
  arrayOf,
  bool,
  button,
  func,
  icon,
  itineraryDays,
  labelValue,
  number,
  shape,
  string,
  oneOf,
  customPageLink,
} = PropTypes;

const inputField = shape({
  placeholder: string,
});

const selectField = shape({
  disabled: bool,
  name: string,
  options: arrayOf(labelValue),
  placeholder: string,
});

const cartItem = shape({
  description: arrayOf(string),
  removeButton: shape({
    text: string,
  }),
  imageSrc: string,
  itemId: number,
  price: string,
  title: string,
});

const preferencesGroups = arrayOf(
  shape({
    groupName: string,
    items: arrayOf(
      shape({
        label: string,
        value: string,
        id: string,
      })
    ),
  })
);

const modalData = {
  alert: string,
  id: string,
  infoIcons: arrayOf(icon),
  longDescription: string,
  printLabel: string,
  onPrint: func,
  primaryButton: button,
  sections: arrayOf(
    shape({
      longText: string,
    })
  ),
  onClose: func,
  subtitle: string,
  title: string,
  isReservationAllowed: bool,
};

const MvjPropTypes = {
  ...PropTypes,

  bookingModalData: {
    alert: string,
    grids: arrayOf(
      shape({
        tier: string,
        rows: number,
      })
    ),
    id: string,
    names: arrayOf(string),
    inputData: arrayOf(
      shape({
        id: string,
        name: string,
        label: string,
      })
    ),
    primaryButton: button,
    onClose: func,
    subtitle: string,
    title: string,
  },

  cards: arrayOf(
    shape({
      alert: string,
      id: string,
      type: string,
      typeCode: string,
      description: string,
      primaryButton: button,
      secondaryButton: button,
      subtitle: string,
      title: string,
      images: arrayOf(
        shape({
          id: string,
          mediaUrl: string,
          type: string,
          imageTitle: string,
          mediaType: string,
          alt: string,
        })
      ),
    })
  ),

  creditCardFields: {
    cardNumber: inputField,
    cardType: selectField,
    name: inputField,
    expiryMonth: selectField,
    expiryYear: selectField,
  },

  inputField,

  journeyAddonList: {
    items: arrayOf(cartItem),
  },

  cartItem,

  customPageLink,

  modalData,

  itineraryNavigatorData: shape({
    days: itineraryDays,
    selectPlaceholder: string,
  }),

  notificationModalData: shape({
    message: string,
    title: string,
    buttonText: string,
    type: oneOf(Object.values(NOTIFICATION_TYPES)),
  }),

  options: arrayOf(labelValue),

  pageHeaderBackButton: shape({
    label: string,
    previousPagePath: string,
  }),

  seating: shape({
    label: string,
    imageUrl: string,
  }),

  shorexModalData: shape({
    mainContent: shape(modalData),
    sideContent: shape({
      alert: string,
      passengers: arrayOf(
        shape({
          key: number,
          name: string,
        })
      ),
      primaryButton: button,
    }),
  }),

  shorexPassengers: arrayOf(
    shape({
      availabilityMessage: string,
      isCheckable: bool,
      name: string,
      passengerNumber: number,
      priceMessage: string,
      primaryButton: button,
      renderError: string,
      secondaryButton: button,
      statusMessage: string,
      time: string,
      times: labelValue,
    })
  ),

  preferences: arrayOf(
    shape({
      columnName: string,
      items: preferencesGroups,
    })
  ),

  preferencesGroups,

  routerLocation: shape({
    hash: string,
    key: string,
    pathname: string,
    search: string,
  }),

  routerMatch: shape({
    isDefault: bool,
    isExact: bool,
    path: string,
    url: string,
  }),

  accountECheckFields: {
    nameOnAccount: selectField,
    accountType: selectField,
    bankAccount: inputField,
    bankRouting: inputField,
  },

  paymentMethod: oneOf([ACH, CREDIT_CARD]),

  selectField,

  dialogModalFields: {
    id: string,
    onConfirm: func,
    onCancel: func,
    entityId: number,
    message: string,
    buttons: arrayOf(
      shape({
        text: string,
        onButtonClick: func,
      })
    ),
    failMessage: string,
    forceAction: bool,
  },

  extentionsModalData: shape({
    bookingAssistance: string,
    extend: shape({
      content: arrayOf(shape({})),
      title: string,
    }),
    extensionsSections: arrayOf(
      shape({
        content: arrayOf(
          shape({
            description: string,
          })
        ),
        heading: string,
      })
    ),
    id: string,
    images: arrayOf(
      shape({
        alt: string,
        caption: string,
        mediaUrl: string,
        type: string,
      })
    ),
    priceFrom: string,
    print: string,
    modalHeader: string,
    subtitle: string,
    title: string,
  }),

  loginFields: PropTypes.shape({
    username: string,
    password: string,
  }),

  history: shape({
    push: func.isRequired,
  }),

  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      contentUrl: PropTypes.string,
      altText: PropTypes.string,
      newBrowserTab: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })
  ),

  guestSelectorLabels: shape({
    title: string.isRequired,
    complete: string.isRequired,
    pending: string.isRequired,
  }),

  itineraryDate: shape({
    value: string.isRequired,
  }),

  ptcData: shape({
    application: arrayOf(string),
    callToActionTitle: string,
    callToActionUrl: string,
    country: arrayOf(string),
    id: string,
    mediaType: string,
    reference: string,
    title: string,
    voyageType: arrayOf(string),
  }),
};

export default MvjPropTypes;
