import React, { Fragment } from 'react';

import PropTypes from '../../PropTypes';
import './DepositDue.scss';

const DepositDue = ({ deposit }) => (
  <>
    {deposit.depositDue && (
      <div className="depsit-due-container">
        <div className="payment-details">
          <div className="header row no-gutters deposit-due">
            <div className="col-sm-8 col-12">{deposit.headerText}</div>
            <div className="col-sm-4 col-12 value">{deposit.depositDue}</div>
          </div>
        </div>
      </div>
    )}
  </>
);

DepositDue.defaultProps = {
  deposit: {},
};

DepositDue.propTypes = {
  deposit: PropTypes.objectOf(PropTypes.string),
};
export default DepositDue;
