import { connect } from 'react-redux';
import { compose } from 'redux';

import commonStore, { userStore } from '../../common';
import { PAGE_NAMES } from '../../common/Constants';
import { setViewAndShowModal } from '../../common/ModalStore';
import { withContent } from '../../components';

import TravelBooking from './TravelBooking';
import travelBookingStore, { fetchTravelBookingPageContent, handleContractOpen } from './TravelBookingStore';

const {
  selectors: { getPageTabLabels },
} = commonStore;
const { getBookingDetailsContent, getTitle, getTravelBookingCardData, isLoadingPage } = travelBookingStore.selectors;
const { showTPCard } = userStore.selectors;

const mapStateToProps = (state) => {
  const {
    labels: { adaAccessibleStateroom, details },
  } = getPageTabLabels(state)(PAGE_NAMES.BOOKING_SUMMARY);
  return {
    content: getBookingDetailsContent(state),
    labels: {
      adaAccessibleStateroom,
      details,
    },
    isLoading: isLoadingPage(state),
    title: getTitle(state),
    travelBookingCardData: getTravelBookingCardData(state),
    showTPCard: showTPCard(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTravelBookingPageContent());
  },
  handleContractOpen: () => dispatch(handleContractOpen()),
  handleModalOpen: (type, id) => dispatch(setViewAndShowModal(type, { id })),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(TravelBooking);
