import React from 'react';
import PropTypes from '../../PropTypes';
import { PageHeader } from '../../components';
import './Onboard.scss';
import DiningBeverage from './diningBeverage/DiningBeverageContainer';
import Enrichment from './enrichment/EnrichmentContainer';
import Preferences from './preferences/PreferencesContainer';
import FoodAllergies from './preferences/foodAllergies/FoodAllergiesContainer';
import StateroomPreferences from './preferences/stateroomPreferences/StateroomPreferencesContainer';
import ShipAndCrew from './shipAndCrew/ShipAndCrewContainer';
import Spa from './spa/SpaContainer';
import Stateroom from './stateroom/StateroomContainer';
// eslint-disable-next-line no-unused-vars
// import SpecialOccasions from './preferences/specialOccasions/SpecialOccasionsContainer';

const Onboard = ({ isLoading, location: { pathname }, match: { url }, tabs, title }) => {
  const paths = [
    {
      component: DiningBeverage,
      path: '/dining-beverage',
    },
    {
      component: Enrichment,
      path: '/enrichment',
    },
    {
      component: Preferences,
      path: '/preferences',
      subPaths: [
        {
          component: StateroomPreferences,
          path: '/stateroom-preferences',
        },
        {
          component: FoodAllergies,
          path: '/allergies',
        },
      ],
    },
    {
      component: ShipAndCrew,
      path: '/ship-crew',
    },
    {
      component: Spa,
      path: '/spa',
    },
    {
      component: Stateroom,
      path: '/stateroom',
    },
  ];
  return (
    <div id="onboardPage" className="onboard-page">
      <PageHeader isLoading={isLoading} pathname={pathname} paths={paths} tabs={tabs} title={title} url={url} />
    </div>
  );
};

Onboard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.routerLocation.isRequired,
  match: PropTypes.routerMatch.isRequired,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
};

Onboard.defaultProps = {
  tabs: [],
  title: '',
};

export default Onboard;
