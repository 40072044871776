import React from 'react';
import getDisplayName from 'recompose/getDisplayName';

import PropTypes from '../../PropTypes';

const withContent = (WrappedComponent) => {
  class WithContent extends React.Component {
    componentDidMount() {
      const { fetchContent } = this.props;
      fetchContent();
    }

    render() {
      const { fetchContent, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }
  WithContent.propTypes = {
    fetchContent: PropTypes.func.isRequired,
  };

  WithContent.defaultProps = {};
  WithContent.displayName = `WithContent(${getDisplayName(WrappedComponent)})`;

  return WithContent;
};

export default withContent;
