import Checkbox from '@viking-eng/checkbox';
import InputGrid from '@viking-eng/input-grid';
import React from 'react';

import { Field } from '..';

import PropTypes from '../../PropTypes';

import './CheckboxList.scss';

const CheckboxField = ({ id, label }) => <Field component={Checkbox} id={id} label={label} name={id} />;

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const CheckBoxGrid = InputGrid(CheckboxField);

const CheckboxList = ({ groups }) => (
  <div className="checkbox-list">
    {groups.map(({ groupName, items }, index) => (
      <div key={`${index + 1}${groupName}`} className="checkbox-group">
        <div className="checkboxes">
          <Field component={CheckBoxGrid} inputData={items} label={groupName} />
        </div>
      </div>
    ))}
  </div>
);

CheckboxList.propTypes = {
  groups: PropTypes.preferencesGroups.isRequired,
};

export default CheckboxList;
