import React from 'react';

import PropTypes from '../../../PropTypes';

class DefaultRouteSetter extends React.Component {
  componentDidMount() {
    const { implicitPath, updateImplicitPath } = this.props;
    updateImplicitPath(implicitPath);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

DefaultRouteSetter.propTypes = {
  children: PropTypes.jsx.isRequired,
  implicitPath: PropTypes.string,
  updateImplicitPath: PropTypes.func.isRequired,
};

DefaultRouteSetter.defaultProps = {
  implicitPath: null,
};

export default DefaultRouteSetter;
