import Button from '@viking-eng/button';
import { prepareHtml } from '@viking-eng/utils';
import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from '..';

import './ChallengeTimeout.scss';

const ChallengeTimeout = ({ id, title, message, button, handleOnClose, isModalOpen }) => {
  return (
    <div className="challenge-timeout-modal">
      {isModalOpen && (
        <Modal ariaHideApp={false} forceAction id={id} isOpen={isModalOpen} onClose={() => handleOnClose()}>
          <div className="challenge-timeout-modal">
            <div className="mvj-modal">
              <div className="title text-center h3" dangerouslySetInnerHTML={prepareHtml(title)} />
              <div className="message" dangerouslySetInnerHTML={prepareHtml(message)} />
              <Button
                appearance="secondary-blue"
                onButtonClick={() => {
                  handleOnClose();
                }}
              >
                {button?.buttonText}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

ChallengeTimeout.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  button: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonAppearance: PropTypes.string,
  }).isRequired,
  handleOnClose: PropTypes.func.isRequired,
};

export default ChallengeTimeout;
