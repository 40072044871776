import { connect } from 'react-redux';
import { hasSubmitFailed, stopSubmit } from 'redux-form';

import { FORMS } from '../../../../common/Constants';
import OnBlurField from '../../../forms/onBlurField/OnBlurField';

const formName = FORMS.GUEST_INFO_FORM;

const handleOnBlur = () => (dispatch, getState) => {
  const submitFailed = hasSubmitFailed(formName)(getState());
  if (submitFailed) {
    dispatch(stopSubmit(formName));
  }
};

const mapDispatchToProps = (dispatch) => ({
  handleOnBlur: () => dispatch(handleOnBlur()),
});

export default connect(null, mapDispatchToProps)(OnBlurField);
