import Button from '@viking-eng/button';
import React, { Fragment } from 'react';

import PropTypes from '../../PropTypes';

import './TimeList.scss';

export default class TimeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  expand = () => {
    this.setState({
      expanded: true,
    });
  };

  render() {
    const { expanded } = this.state;
    const { times, moreLabel } = this.props;
    let displayTimes = times;
    const displayTimesView = (len) => displayTimes.slice(0, len);
    if (!expanded) {
      if (times.length === 3) {
        displayTimes = displayTimesView(3);
      } else {
        displayTimes = displayTimesView(2);
      }
    }
    return (
      <div data-id="time-list">
        {displayTimes.map((time, i) => (
          <Fragment key={time.start.value}>
            <span className="time">{time.start.label}</span>
            {i !== displayTimes.length - 1 && <span className="divider"> / </span>}
          </Fragment>
        ))}
        {!expanded && times.length > 3 && (
          <>
            <span className="divider"> / </span>
            <Button appearance="link" onButtonClick={this.expand}>
              {moreLabel}
            </Button>
          </>
        )}
      </div>
    );
  }
}

TimeList.propTypes = {
  times: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.shape({
        label: PropTypes.string,
      }),
    })
  ),
  moreLabel: PropTypes.string.isRequired,
};

TimeList.defaultProps = {
  times: [],
};
