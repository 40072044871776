import React from 'react';
import TabPage from '@viking-eng/tab-page';

import PropTypes from '../../../PropTypes';

const Transfers = ({ content: { pageDescription, sections } }) => (
  <div className="transfers">
    <TabPage sections={sections} headerText={pageDescription} />
  </div>
);

Transfers.propTypes = {
  content: PropTypes.shape({
    sections: PropTypes.cardSections,
    pageDescription: PropTypes.string,
  }),
};

Transfers.defaultProps = {
  content: {
    sections: [],
    pageDescription: null,
  },
};

export default Transfers;
