import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formsReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { getWaiter, reducer as waiterReducer } from 'redux-waiter';
import commonStore, { modalStore, userStore } from './common';
import {
  AIR_SESSION_EXPIRE_DURATION,
  AIR_SESSION_WARNING_DURATION,
  AIR_WAITER_NAMESPACES,
  ENVIRONMENT_CODE,
  FORMS,
} from './common/Constants';
import {
  accountStore,
  airStore,
  beforeYouGoStore,
  calendarStore,
  documentsStore,
  extensionsStore,
  helpStore,
  homeStore,
  loginFaqsStore,
  newAirStore,
  notificationsStore,
  onboardStore,
  paymentsStore,
  schedulePaymentStore,
  shorexStore,
  splashPageStore,
  submarineVideoStore,
  travelBookingStore,
  travelStore,
} from './pages';
import { expireSearchSession, warnUserOfExpiredSession } from './pages/newAir/AirStore';

const devToolsEnvironments = ['local', 'dev', 'qa'];

const stores = [
  accountStore,
  airStore,
  beforeYouGoStore,
  calendarStore,
  commonStore,
  documentsStore,
  extensionsStore,
  helpStore,
  loginFaqsStore,
  homeStore,
  modalStore,
  newAirStore,
  notificationsStore,
  onboardStore,
  paymentsStore,
  shorexStore,
  splashPageStore,
  submarineVideoStore,
  travelBookingStore,
  userStore,
  travelStore,
  schedulePaymentStore,
];

const baseState = {
  form: {},
  waiter: {},
  account: {},
  air: {},
  beforeYouGo: {},
  calendar: {},
  common: {
    requestCount: 0,
  },
  documents: {},
  extensions: {},
  help: {},
  notifications: {},
  onboard: {},
  payments: {},
  travelStore: {},
  schedulePayment: {},
  shorex: {
    calendarConflict: {
      1: {},
      2: {},
    },
  },
  splashPage: {},
  submarineVideo: {},
  travelBooking: {},
};

const getReducers = () => {
  const reducers = {};
  stores.forEach(({ store, reducer }) => {
    reducers[store] = reducer;
  });
  return reducers;
};

const formsPersistConfig = {
  key: 'form',
  storage: storageSession,
  whitelist: [FORMS.AIR_SEARCH],
};

const waiterPersistConfig = {
  key: 'waiter',
  storage: storageSession,
  whitelist: [...Object.values(AIR_WAITER_NAMESPACES)],
};

const rootReducer = (state, action) => {
  // For use when changing accounts and needing to reset store.
  let appState;
  switch (action.type) {
    case commonStore.types.CLEAR_STATE:
      appState = {
        ...state,
        ...baseState,
      };
      break;
    case commonStore.types.LOGOUT:
      appState = undefined;
      break;
    default:
      appState = state;
  }

  return appReducer(appState, action);
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['newAir'],
  blacklist: ['user'],
};

const userPersistConfig = {
  key: 'user',
  storage: storageSession,
  whitelist: ['migration', 'impersonation'],
};

if (userStore?.reducer) {
  userStore.reducer = persistReducer(userPersistConfig, userStore.reducer);
}

const appReducer = combineReducers({
  form: persistReducer(formsPersistConfig, formsReducer),
  waiter: persistReducer(waiterPersistConfig, waiterReducer),
  ...getReducers(),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const { GET_SEARCH_FLIGHTS } = AIR_WAITER_NAMESPACES;

const airBookingSearchTimer = (store) => {
  let expirationTimeoutId;
  let warningTimeoutId;
  return (next) => (action) => {
    if (action.type === 'AIR_BOOKING_TIMER_SET') {
      clearTimeout(expirationTimeoutId);
      clearTimeout(warningTimeoutId);
      const waiter = getWaiter(store.getState(), GET_SEARCH_FLIGHTS, {});
      if (!action.payload) {
        if (waiter && waiter.endTime > 0 && waiter.endTime) {
          // current time in ms
          const currentTime = Date.now().valueOf();
          // time remaining in ms
          const remainingTime = (waiter.endTime || 0) + AIR_SESSION_EXPIRE_DURATION - currentTime;
          // set expiration timer
          expirationTimeoutId = setTimeout(
            () => {
              clearTimeout(expirationTimeoutId);
              clearTimeout(warningTimeoutId);
              store.dispatch(expireSearchSession());
            },
            remainingTime > 0 ? remainingTime : 0
          );

          // if more than warning time is left
          if (remainingTime > AIR_SESSION_WARNING_DURATION) {
            // set warning timer
            warningTimeoutId = setTimeout(() => {
              clearTimeout(warningTimeoutId);
              store.dispatch(warnUserOfExpiredSession());
            }, remainingTime - AIR_SESSION_WARNING_DURATION);
          }
        }
      }
    }
    if (action.type === 'AIR_BOOKING_TIMER_CANCEL') {
      clearTimeout(expirationTimeoutId);
      clearTimeout(warningTimeoutId);
    }
    return next(action);
  };
};

export default function configureStore(preloadedState) {
  const middlewares = [thunk, airBookingSearchTimer];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composeEnhancers = devToolsEnvironments.includes(ENVIRONMENT_CODE) ? composeWithDevTools : compose;
  const store = createStore(persistedReducer, preloadedState, composeEnhancers(...enhancers));
  return store;
}
