import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import React from 'react';
import PropTypes from '../../PropTypes';
import './PassengerSelector.scss';

class PassengerSelector extends React.Component {
  constructor() {
    super();
    this.componentRef = [];
  }

  render() {
    const {
      passenger: { name, passengerNumber, checkedIn },
      startSurvey,
      submitSurvey,
      checkedInData,
      handleOnClick,
      disabled,
      disabledMessage,
    } = this.props;

    if (!passengerNumber) {
      return null;
    }

    const showDisabledMessage = disabled && disabledMessage;

    let buttonContent = (
      <>
        <Button
          appearance="secondary-blue"
          onButtonClick={() => {
            handleOnClick();
          }}
          disabled={disabled}
          className={showDisabledMessage ? 'with-disabled-msg' : ''}
        >
          {startSurvey || submitSurvey}
        </Button>
        {showDisabledMessage && <div className="disabled-message">{disabledMessage}</div>}
      </>
    );

    if (checkedIn) {
      buttonContent = (
        <div>
          <div className="checked-in-alert">
            <div className="checked-in-title">
              <Icon name="checkmark" />
              {checkedInData.title}
            </div>
            <div className="checked-in-message">{checkedInData.body}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="passenger-name-wrapper col-md col-sm-12" key={`passenger_${passengerNumber}`}>
        <div className="passenger-name h4">{name}</div>
        <div className="button-wrapper">{buttonContent}</div>
      </div>
    );
  }
}

PassengerSelector.propTypes = {
  passenger: PropTypes.shape({
    name: PropTypes.string.isRequired,
    passengerNumber: PropTypes.number.isRequired,
    checkedIn: PropTypes.bool,
  }).isRequired,
  startSurvey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  checkedInData: PropTypes.shape({}).isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

PassengerSelector.defaultProps = {
  disabled: false,
};

export default PassengerSelector;
