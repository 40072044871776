import { userStore } from '../../common';
import { getData } from '../../common/Api';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import { buildUrl, decodeCountryCodeFromCurrency } from '../../common/Utils';

const { getBookingDetails } = userStore.selectors;

const submarineVideoStore = createPageTitleDuck('submarineVideo').extend({
  types: ['SET_SUBMARINE_VIDEO_PAGE_CONTENT'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_SUBMARINE_VIDEO_PAGE_CONTENT:
        return {
          ...state,
          content: action.data,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setSubmarineVideoPageData: (data) => ({
      type: types.SET_SUBMARINE_VIDEO_PAGE_CONTENT,
      data,
    }),
  }),
});

const { setSubmarineVideoPageData } = submarineVideoStore.creators;
export const fetchSubmarineVideoPageData = () => (dispatch, getState) => {
  const bookings = getBookingDetails(getState());
  const country = decodeCountryCodeFromCurrency(bookings.currency);
  const url = buildUrl('/pages/submarineVideo', ['voyage.type'], { ...bookings }, { country });

  return dispatch(
    getData({
      url,
      store: submarineVideoStore,
      node: 'content',
      creator: setSubmarineVideoPageData,
    })
  );
};

export default submarineVideoStore;
