import { connect } from 'react-redux';

import paymentsStore from '../../../pages/payments/PaymentsStore';

import PaymentMethod from './PaymentMethod';

const { getPaymentMethodFormData } = paymentsStore.selectors;

const mapStateToProps = (state, props) => {
  const { bankDraft, draftDiscount, options, achAvailable, achNotAvailable } = getPaymentMethodFormData(state);

  return {
    bankDraft,
    draftDiscount,
    options: props.options || options,
    achAvailable,
    achNotAvailable,
  };
};

export default connect(mapStateToProps)(PaymentMethod);
