import Button from '@viking-eng/button';
import IconGroup from '@viking-eng/icon-group';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { prepareHtml } from '../../../common/Utils';

const MainContent = ({
  disclaimers,
  effortLevel,
  infoIcons,
  isSubShorex,
  overland,
  overlandExcursion,
  sections,
  subSafetyVideoUrl,
  subSafetyVideoText,
  subText,
}) => {
  const grids = [
    {
      tier: 'xs',
      columns: 1,
    },
    {
      tier: 'sm',
      columns: 1,
    },
    {
      tier: 'md',
      columns: 2,
    },
  ];
  return (
    <>
      {infoIcons && (
        <div className="row icons">
          <div className="col">
            <IconGroup icons={infoIcons} grid={grids} />
          </div>
        </div>
      )}
      {subText && <div className="sub-text" dangerouslySetInnerHTML={prepareHtml(subText)} />}
      {isSubShorex && (
        <div className="sub-safety-video">
          <Button appearance="link" onButtonClick={() => window.open(subSafetyVideoUrl, '_blank')}>
            {subSafetyVideoText}
          </Button>
        </div>
      )}
      {sections.map(({ id, longText, shortText }) => (
        <div key={id} className="description" dangerouslySetInnerHTML={prepareHtml(`${shortText} ${longText}`)} />
      ))}
      {overland &&
        overlandExcursion?.days?.map(({ description, header }) => (
          <>
            <div key={header} className="header" dangerouslySetInnerHTML={prepareHtml(header)} />
            <div key={header} className="description" dangerouslySetInnerHTML={prepareHtml(description)} />
          </>
        ))}
      {disclaimers && disclaimers.length > 0 && (
        <>
          <hr />
          {disclaimers?.map(({ disclaimer }) => (
            <div key={disclaimer} className="description" dangerouslySetInnerHTML={prepareHtml(disclaimer)} />
          ))}
        </>
      )}
      {effortLevel?.description && effortLevel?.displayName && (
        <>
          <hr />
          <div
            className="effort-disclaimer"
            dangerouslySetInnerHTML={prepareHtml(`${effortLevel?.displayName} - ${effortLevel?.description}`)}
          />
        </>
      )}
    </>
  );
};

MainContent.propTypes = {
  disclaimers: PropTypes.arrayOf(
    PropTypes.shape({
      disclaimer: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  infoIcons: PropTypes.arrayOf(PropTypes.icon),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
};

MainContent.defaultProps = {
  disclaimers: [],
  infoIcons: null,
  sections: [],
};

export default MainContent;
