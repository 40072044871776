import PropTypes from '@viking-eng/prop-types';
import React from 'react';
import './AirHeader.scss';

const AirHeader = ({
  step,
  title,
  totalSteps,
  showSubtitle,
  shortMessage = '',
  longMessage,
  showSteps = true,
  className,
}) => (
  /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
  <div className={`air-header-container ${className}`} tabIndex="0">
    <div className="title col-auto text-center h5 h3-md">{title}</div>
    <div className="text-center">
      <div className="airheader-subtitle h5">
        {showSubtitle && showSteps && (
          <>
            <div className="d-none d-md-block">{`Step ${step} of ${totalSteps}: ${longMessage}`}</div>
            <div className="d-md-none">{`Step ${step} of ${totalSteps}: ${shortMessage}`}</div>
          </>
        )}
        {showSubtitle && !showSteps && (
          <>
            <div className="d-none d-md-block">{longMessage}</div>
            <div className="d-md-none">{shortMessage}</div>
          </>
        )}
        {!showSubtitle && <div className="airheader-subtitle-placeholder" />}
      </div>
    </div>
  </div>
);

AirHeader.propTypes = {
  title: PropTypes.string,
  showSubtitle: PropTypes.bool,
  shortMessage: PropTypes.string,
  longMessage: PropTypes.string,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
  showSteps: PropTypes.bool,
  className: PropTypes.string,
};

AirHeader.defaultProps = {
  title: '',
  showSubtitle: true,
  shortMessage: '',
  longMessage: '',
  totalSteps: null,
  step: null,
  showSteps: true,
  className: '',
};

export default AirHeader;
