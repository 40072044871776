import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore from '../../../common';
import {
  APP_PATHS,
  CART_PRICE_TIME_UPDATE_MODAL,
  SERVICE_CODES,
  SHOREX_GAP_MINUTES,
  TAB_NAMES,
  VOYAGE_TYPE,
} from '../../../common/Constants';
import history from '../../../common/history';
import modalStore, { setViewAndShowModal } from '../../../common/ModalStore';
import userStore from '../../../common/UserStore';
import { getCmsLabel } from '../../../common/Utils';
import { withContent } from '../../../components';
import { fetchCalendarItems } from '../../calendar/CalendarStore';
import { fetchTabContent as fetchOnboardTabContent } from '../../onboard/OnboardStore';
import paymentsStore, {
  clearCartAfterTimeout,
  fetchTabContent,
  getCartItems,
  removeJourneyAddons,
} from '../PaymentsStore';
import PaymentAddons from './PaymentAddons';

const {
  creators: { handleSuccessfulCartUpdate, setCartRedirectModifyModal },
  selectors: {
    isModalOpen,
    getReservationHasConflict,
    getPaymentsAllEnabled,
    getPaymentsCheckoutEnabled,
    getMvjStrings,
  },
} = commonStore;
const {
  creators: { updateRemovingFlag, clearTabContent, setPaymentProcessingTimer },
  selectors: {
    getJourneyCheckoutSection,
    getJourneyAddonsSection,
    getSoldOutModalData,
    isLoadingTabs,
    getUkPaymentsMessage,
    getCartItemsWhenAdded,
    getCartItemsWhenUpdated,
    getPriceHasChangedText,
    getAllCalendarItemsAddons,
    getTabContent,
    getIsPaymentProcessing,
    getPaymentProcessingTimer,
  },
} = paymentsStore;

const {
  selectors: { getVoyageType, isCartLoading },
} = userStore;

const { ADDONS, DINING_BEVERAGE, CRUISE_PAYMENTS } = TAB_NAMES;
const { clearModal } = modalStore.creators;
const mapStateToProps = (state) => {
  const allCalendarItems = getAllCalendarItemsAddons(state).items;
  const cartItemsWhenAdded = getCartItemsWhenAdded(state);
  const cartItemsWhenUpdated = getCartItemsWhenUpdated(state);
  const cartItemsCodes = cartItemsWhenUpdated?.map((item) => {
    return { serviceCode: item.serviceCode, startTime: item.startTime };
  });
  const cartItemsPrices = cartItemsCodes?.map((item) => {
    const cartItemWhenAdded = cartItemsWhenAdded?.filter(
      (itemAdded) => itemAdded.serviceCode === item.serviceCode && itemAdded.startTime === item.startTime
    );
    const cartItemWhenUpdated = cartItemsWhenUpdated?.filter(
      (itemAUpdated) => itemAUpdated.serviceCode === item.serviceCode && itemAUpdated.startTime === item.startTime
    );
    return {
      ...item,
      cartItemWhenAddedPrice: cartItemWhenAdded?.[0]?.pricePerPassenger,
      cartItemWhenUpdatedPrice: cartItemWhenUpdated?.[0]?.pricePerPassenger,
      cartItemStartTime: cartItemWhenAdded?.[0]?.startTime,
    };
  });
  const cartItemsWithPriceUpdateCodeArray = cartItemsPrices.filter(
    (item) => item.cartItemWhenAddedPrice !== item.cartItemWhenUpdatedPrice
  );

  const { priceHasChangedText } = getPriceHasChangedText(state);
  const timeHasChangedText = getReservationHasConflict(state)?.subtitle;
  const timeHasChangedTitle = getReservationHasConflict(state)?.title;

  const hasConflict = (itemStartTime, itemEndTime, serviceCode, forPassenger) => {
    const isNonConflictingItem = (code) => [SERVICE_CODES.PPG, SERVICE_CODES.SSBP].includes(code);
    if (isNonConflictingItem(serviceCode)) {
      return [];
    }
    const itemforPassenger1 = forPassenger.find((p) => p?.id === 1).inCart;
    const itemforPassenger2 = forPassenger.find((p) => p?.id === 2).inCart;
    const hasTimeOverlap = (startTime1, endTime1, startTime2, endTime2) => {
      const endTimeWithGap = moment(endTime1).add(SHOREX_GAP_MINUTES, 'm').format('YYYY-MM-DDTHH:mm:ss');
      return (
        startTime1 === startTime2 ||
        (startTime1 >= startTime2 && startTime1 <= endTime2) ||
        (endTime1 >= startTime2 && startTime1 <= endTime2) ||
        (startTime2 >= startTime1 && startTime2 <= endTime1) ||
        (moment(startTime1, 'YYYY-MM-DDTHH:mm:ss').isSame(moment(startTime2, 'YYYY-MM-DDTHH:mm:ss')) &&
          moment(endTimeWithGap).isAfter(moment(startTime2)))
      );
    };
    const conflictArr = allCalendarItems?.filter(
      (element) =>
        element.serviceCode !== serviceCode &&
        !isNonConflictingItem(element.serviceCode) &&
        !isNonConflictingItem(serviceCode) &&
        hasTimeOverlap(element.startTime, element.endTime, itemStartTime, itemEndTime) &&
        ((element.forPassenger1 && itemforPassenger1) || (element.forPassenger2 && itemforPassenger2))
    );
    return conflictArr;
  };
  const conflictsRaw = cartItemsWhenUpdated?.reduce((acc, item) => {
    const itemConflict = hasConflict(item.startTime, item.endTime, item.serviceCode, item.forPassenger);
    if (itemConflict.length > 0) {
      acc.push([item, itemConflict[0]]);
    }
    return acc;
  }, []);

  const journeyAddonsSectionItems = getJourneyAddonsSection(state).items;
  const conflicts = conflictsRaw?.[0]?.map((item) => {
    const journeyAddonsSectionItem = journeyAddonsSectionItems.find(
      (element) => element.serviceCode === item.serviceCode && element.startTime === item.startTime
    );
    const mappedItem = journeyAddonsSectionItem || item;
    return mappedItem;
  });

  const pageContent = getTabContent(state)(CRUISE_PAYMENTS);

  return {
    isLoading:
      isLoadingTabs(state) || get(state, 'onboard.diningBeverage.content.loading', false) || isCartLoading(state),
    journeyAddonsSection: getJourneyAddonsSection(state),
    journeyCheckoutSection: getJourneyCheckoutSection(state),
    soldOutModalData: getSoldOutModalData(state),
    isModalOpen: isModalOpen(state),
    ukPaymentsMessage: getUkPaymentsMessage(state)(ADDONS),
    cartItemsWithPriceUpdateCodeArray,
    priceHasChangedText,
    timeHasChangedText,
    timeHasChangedTitle,
    cartItemsTimeConflict: conflicts,
    paymentInProgress: getIsPaymentProcessing(state),
    paymentProcessingMessage: getCmsLabel(pageContent.labels || [], 'paymentProcessingAlertMessage'),
    paymentProcessingTimerStatus: getPaymentProcessingTimer(state),
    isPaymentsDisabled: !getPaymentsAllEnabled(state) || !getPaymentsCheckoutEnabled(state),
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch) => {
  const fetchContent = async () => {
    await dispatch((_, getState) => {
      const voyageType = getVoyageType(getState());
      // Remove addons from redux.
      dispatch(clearTabContent(ADDONS));
      dispatch(fetchOnboardTabContent(DINING_BEVERAGE, true, true)).then(() => {
        dispatch(fetchTabContent(ADDONS));
      });
      if (![VOYAGE_TYPE.RIVER].includes(voyageType)) {
        dispatch(fetchOnboardTabContent(TAB_NAMES.SPA, true));
      }
      dispatch(getCartItems());
      dispatch(fetchCalendarItems(true));
      dispatch(setViewAndShowModal(CART_PRICE_TIME_UPDATE_MODAL.ID));
      const pageContent = getTabContent(getState())(ADDONS);
      if (pageContent?.sections?.length === 0) {
        dispatch(fetchTabContent(CRUISE_PAYMENTS));
      }
    });
  };
  return {
    fetchContent,
    removeCartItem: (itemId) => {
      dispatch(updateRemovingFlag(itemId));
      dispatch(removeJourneyAddons(itemId))
        // eslint-disable-next-line max-len
        .then(() =>
          dispatch(fetchTabContent(ADDONS)).then(() => {
            dispatch(handleSuccessfulCartUpdate());
            dispatch(updateRemovingFlag());
          })
        )
        .catch(() => dispatch(updateRemovingFlag()));
    },
    emptyCart: async (items) => {
      const itemIds = items.map(({ itemID }) => itemID);
      await dispatch(removeJourneyAddons(itemIds));
      dispatch(handleSuccessfulCartUpdate());
      fetchContent();
    },
    clearModal: () => dispatch(clearModal()),
    goToShorexModal: (startDate, serviceCode) => {
      const shorexDate = startDate.split('T')[0];
      dispatch(setCartRedirectModifyModal(serviceCode));
      history.push(`${APP_PATHS.ITINERARY}/${shorexDate}`);
    },
    handleModalOpen: (id) => dispatch(setViewAndShowModal(id, { id })),
    setProcessingTimerStatus: (timerStatus) => dispatch(setPaymentProcessingTimer(timerStatus)),
    refetchContent: () => dispatch(fetchTabContent(ADDONS)),
    refetchContentAfterTimeout: () => {
      dispatch(clearCartAfterTimeout()).then(() => {
        dispatch(fetchTabContent(CRUISE_PAYMENTS));
        dispatch(fetchTabContent(ADDONS, true, true, false, true));
      });
    },
  };
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);
export default enhance(PaymentAddons);
