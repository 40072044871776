// TODO: Move error handling (AKA isModalOpen) from common store to modal store. (MR-10330 - Tech debt)
import get from 'lodash/get';
import { createDefaultDuck } from './ReusableDucks';
import { navigateTo } from './Utils';

const initialState = {
  view: '',
  isActive: false,
  data: {},
};

const modalStore = createDefaultDuck('modal').extend({
  types: ['SHOW_MODAL', 'HIDE_MODAL', 'SET_MODAL_VIEW', 'SET_MODAL_DATA', 'CLEAR_MODAL_VIEW', 'CLEAR_MODAL'],
  initialState,
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SHOW_MODAL:
        return {
          ...state,
          isActive: true,
        };
      case types.HIDE_MODAL:
        return {
          ...state,
          isActive: false,
        };
      case types.CLEAR_MODAL_VIEW:
        return {
          ...state,
          view: initialState.view,
        };
      case types.CLEAR_MODAL:
        return initialState;
      case types.SET_MODAL_VIEW:
        return {
          ...state,
          view: action.payload,
        };
      case types.SET_MODAL_DATA:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    showModal: () => ({
      type: types.SHOW_MODAL,
    }),
    hideModal: () => ({
      type: types.HIDE_MODAL,
    }),
    setModalView: (view) => ({
      type: types.SET_MODAL_VIEW,
      payload: view,
    }),
    setModalData: (data) => ({
      type: types.SET_MODAL_DATA,
      payload: data,
    }),
    clearModalView: () => ({
      type: types.CLEAR_MODAL_VIEW,
    }),
    clearModal: (payload) => ({
      type: types.CLEAR_MODAL,
      action: payload
    }),
  }),
  selectors: {
    getModalData: (state) => get(state, 'modal.data', {}),
    getModalIsActive: (state) => get(state, 'modal.isActive', false),
    getModalView: (state) => get(state, 'modal.view', ''),
    getModal: (state) => get(state, 'modal', {}),
    getModalId: (state) => get(state, 'modal.data.id', null),
  },
});

const { setModalView, setModalData, showModal, clearModal } = modalStore.creators;
const { getModalIsActive, getModalView } = modalStore.selectors;

export const setViewAndShowModal = (view, data, hideOthers) => (dispatch, getState) => {
  if (hideOthers && getModalIsActive(getState())) {
    dispatch(clearModal());
  }
  dispatch(setModalView(view));
  dispatch(setModalData(data));
  dispatch(showModal());
};

export const closeModalAndNavigate = (url) => (dispatch) => {
  dispatch(clearModal());
  navigateTo(url);
};

export const clearSpecificModal = (modalView) => (dispatch, getState) => {
  const currentModal = getModalView(getState());
  if (currentModal === modalView) {
    dispatch(clearModal());
  }
};

export default modalStore;
