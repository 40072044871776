import { connect } from 'react-redux';

import commonStore from '../../common';

import FooterModal from './Footer';

const { getFooterModalContent } = commonStore.selectors;

const mapStateToProps = (state, { type }) => ({
  ...getFooterModalContent(state)(type),
  type: `${type}-modal`,
});

export default connect(mapStateToProps)(FooterModal);
