import ResponsiveImage from '@viking-eng/responsive-image';
import React from 'react';

import PropTypes from '../../../PropTypes';

import './FieldWrapper.scss';

const FieldWrapper = ({ children, description, images }) => (
  <div className="field-wrapper">
    {children}
    {description && <div className="description">{description}</div>}
    {images.length > 0 && (
      <div className="image">
        <ResponsiveImage images={images} />
      </div>
    )}
  </div>
);

FieldWrapper.propTypes = {
  children: PropTypes.jsx.isRequired,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(PropTypes.image)),
};

FieldWrapper.defaultProps = {
  description: null,
  images: [],
};

export default FieldWrapper;
