import Image from '@viking-eng/image';
import PropTypes from '@viking-eng/prop-types';
import React from 'react';
import { prepareHtml } from '../../common/Utils';
import './VoyageNotAvailPage.scss';

const hideBrokenImage = (i) => {
  const { style } = i.target;
  style.display = 'none';
};

const CallToAction = ({ btnContent: { attributes } }) => {
  const logo = (
    <span role="none">
      <Image alt="Image" brokenImageHandler={hideBrokenImage} className="viking-logo" {...attributes} />
    </span>
  );
  return logo;
};

const VoyageNotAvailPage = ({ logo, content }) => (
  <div
    id="voyageNotAvailablePage"
    className="voyage-not-available-page d-flex align-items-center justify-content-center"
  >
    <div>
      <div className="d-flex justify-content-center mb-2">
        {logo &&
          logo.map((btn, idx) => {
            const id = `button_${idx}`;
            return <CallToAction key={id} btnContent={btn} />;
          })}
      </div>
      <h2 className="d-flex justify-content-center font-weight-bold mb-2 title">
        <span>{content.title}</span>
      </h2>
      <div className="msg-text" dangerouslySetInnerHTML={prepareHtml(content.subtitle)} />
    </div>
  </div>
);

VoyageNotAvailPage.propTypes = {
  logo: PropTypes.arrayOf(PropTypes.button),
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};

VoyageNotAvailPage.defaultProps = {
  logo: null,
  content: {},
};

export default VoyageNotAvailPage;
