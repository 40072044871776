import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getData } from '../../common/Api';
import { PRE_TRAVEL_MISSISSIPPI_CARDS, VOYAGE_TYPE } from '../../common/Constants';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import userStore from '../../common/UserStore';
import { buildUrl } from '../../common/Utils';

const baseTravelStore = createPageTabsDuck('travel');

const { getBookingDetails, getVoyageType } = userStore.selectors;

const travelStore = baseTravelStore.extend({
  reducer: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  selectors: {
    getSections: new Selector(({ getPageContent }) =>
      createSelector([getBookingDetails, getPageContent], ({ office, voyage: { type } }, content) => {
        const { sections, ...mappedContent } = content;
        mappedContent.sections = [];

        if (mappedContent.loaded) {
          const messages = get(content, 'sections.0.messages', []);
          const sectionCards = get(content, 'sections.0.cards', []);
          mappedContent.sections.push({ cards: [], messages });
          sectionCards?.forEach((card) => {
            let hideCard = false;
            if (type === VOYAGE_TYPE.MISSISSIPPI) {
              hideCard = !PRE_TRAVEL_MISSISSIPPI_CARDS[office].cards.includes(card?.reference);
            }
            if (!hideCard && (!card?.modal.sections || card?.modal?.sections?.length)) {
              mappedContent.sections[0].cards.push(card);
            }
          });
        }

        return mappedContent;
      })
    ),
  },
});

const { receiveContent } = travelStore.creators;

export const fetchTravelRequirementsContent = () => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const voyageType = getVoyageType(state);
  const {
    comboBookings,
    office,
    currency,
    voyage: { id: voyageId },
  } = bookingDetails;
  const voyageIds = comboBookings.map(({ shipCode, invoice }) => `${shipCode}${invoice}`).join('|') || voyageId;
  const encodedCruiseName = encodeURIComponent(bookingDetails?.cruise?.name) || '';
  const url = buildUrl(
    `/travelRequirements/${office}/${currency}`,
    ['voyageType', 'bookingNumber', 'ship.shipCode', 'cruiseName'],
    {
      ...bookingDetails,
      voyageType,
      cruiseName: encodedCruiseName,
    },
    {
      voyageIds: encodeURIComponent(voyageIds),
    }
  );
  return dispatch(
    getData({
      url,
      store: travelStore,
      node: 'content',
      creator: receiveContent,
      refreshData: true,
    })
  );
};

export default travelStore;
