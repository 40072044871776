import { connect } from 'react-redux';

import commonStore from '../../common';
import { FOOTER_MODALS } from '../../common/Constants';

import ContactModal from './Contact';

const { getFooterModalContent } = commonStore.selectors;

const mapStateToProps = (state) => ({
  ...getFooterModalContent(state)(FOOTER_MODALS.CONTACT),
  id: `${FOOTER_MODALS.CONTACT}-modal`,
});

export default connect(mapStateToProps)(ContactModal);
