import Button from '@viking-eng/button';
import classNames from 'classnames';
import React from 'react';
import PropTypes from '../../PropTypes';
import './ReservationGuestWidget.scss';

const ReservationGuestWidget = ({
  guestName,
  reservationTime,
  reservationMessage,
  reservationMessageIsUrgent,
  messageLink,
  isCheckable,
  button,
  checkbox,
  dropdown,
  timesSlots,
  conflictStatus,
  sameExcursionInCart,
  error,
  loading,
}) => (
  <div className="reservation-guest-widget">
    <div className={classNames('guest', { checkbox })}>
      {/* TODO: use checkbox label prop for guest name in Shorex Reservations */}
      {guestName && !isCheckable && <div className="name">{guestName}</div>}
      {isCheckable && checkbox && !reservationTime && <div className="checkbox">{checkbox}</div>}
    </div>
    {timesSlots.length > 1 && !reservationTime && !sameExcursionInCart && (
      <div className="reservation-time">{dropdown}</div>
    )}
    {reservationTime && <div className="time">{reservationTime}</div>}

    {reservationMessage && !conflictStatus && (
      <div
        className={classNames({
          'reservation-message': !error,
          'validation-error-text': error,
          'non-urgent': !reservationMessageIsUrgent,
        })}
      >
        {reservationMessage}
      </div>
    )}
    {messageLink && messageLink.text && (
      <div className="reservation-message">
        <Button appearance="link" {...messageLink}>
          {messageLink.text}
        </Button>
      </div>
    )}
    {button && !conflictStatus && (
      <>
        <div className="reservation-button">
          <Button appearance="secondary-blue" loading={loading} {...button}>
            {button.text}
          </Button>
        </div>
      </>
    )}
  </div>
);

ReservationGuestWidget.propTypes = {
  guestName: PropTypes.string,
  reservationTime: PropTypes.string,
  reservationMessage: PropTypes.string,
  reservationMessageIsUrgent: PropTypes.bool,
  isCheckable: PropTypes.bool,
  checkbox: PropTypes.jsx,
  button: PropTypes.button,
  dropdown: PropTypes.jsx,
  conflictStatus: PropTypes.bool,
  timesSlots: PropTypes.arrayOf(PropTypes.string),
  sameExcursionInCart: PropTypes.bool,
  messageLink: PropTypes.shape({}),
  error: PropTypes.bool,
  loading: PropTypes.bool,
};

ReservationGuestWidget.defaultProps = {
  guestName: null,
  reservationTime: null,
  reservationMessage: null,
  reservationMessageIsUrgent: true,
  button: null,
  checkbox: null,
  dropdown: null,
  isCheckable: false,
  conflictStatus: false,
  timesSlots: [],
  sameExcursionInCart: false,
  messageLink: {},
  error: false,
  loading: false,
};

export default ReservationGuestWidget;
