import React from 'react';

import { Modal } from '..';

import Button from '@viking-eng/button';
import TimeLeft from '@viking-eng/time-left';

import { AIR_SESSION_EXPIRE_DURATION } from '../../common/Constants';
import PropTypes from '../../PropTypes';

import './AirSessionWarningModal.scss';

const AirSessionWarningModal = ({
  modalProps,
  airSearchResultsTimestamp,
  confirmHandler,
  cancelHandler,
  newSearchText,
  continueText,
}) => (
  <Modal {...modalProps}>
    <div className="air-session-warning-modal">
      <div className="mvj-modal">
        <div className="row no-gutters d-flex justify-content-center">
          <div className="col">
            <div className="text-content d-flex flex-column align-items-center">
              <div className="h4 h3-md">{modalProps.message}</div>
              {airSearchResultsTimestamp && <TimeLeft time={airSearchResultsTimestamp + AIR_SESSION_EXPIRE_DURATION} />}
            </div>
            <div className="form-actions-group d-sm-block d-md-flex justify-content-center">
              <Button className="order-sm-last" appearance="secondary-blue" onButtonClick={cancelHandler}>
                {continueText}
              </Button>
              <Button className="order-sm-first" appearance="secondary-gray" onButtonClick={confirmHandler}>
                {newSearchText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

AirSessionWarningModal.propTypes = {
  modalProps: PropTypes.shape().isRequired,
  airSearchResultsTimestamp: PropTypes.number.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  newSearchText: PropTypes.string,
  continueText: PropTypes.string,
};

AirSessionWarningModal.defaultProps = {
  newSearchText: 'NEW SEARCH',
  continueText: 'CONTINUE',
};

export default AirSessionWarningModal;
