import Button from '@viking-eng/button';
import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import InputText from '@viking-eng/input-text';
import Select from '@viking-eng/select';
import Spinner from '@viking-eng/spinner';
import classNames from 'classnames';
import React from 'react';
import { FieldArray, Form } from 'redux-form';
import { Field, ReservationGuestWidget } from '..';
import PropTypes from '../../PropTypes';
import { DINING_MAX_TABLE_SIZE, RESERVATION_STATE_KEYS, RESERVATION_TYPES } from '../../common/Constants';
import { prepareHtml } from '../../common/Utils';
import { numericMask } from '../../common/forms/Masks';
import { ERROR_CODES, required } from '../../common/forms/Validations';
import './ModalReservationForm.scss';

const { DINING } = RESERVATION_TYPES;

const InviteGuestGroup = ({ error, fields, formSubmitting, labels, handleClear }) => (
  <div className="invite-guest-group">
    {fields.length < 5 && (
      <div className="d-none d-md-block more-guests-link">
        <Button
          appearance="link"
          disabled={formSubmitting}
          onButtonClick={() => fields.push({ number: fields.length })}
        >
          {labels.addGuests}
        </Button>
      </div>
    )}
    {fields.map((booking, index) => (
      <div className="row" key={`${booking}`.number}>
        <Button
          className={classNames({
            'd-none': fields?.length <= 1,
            'd-block': fields?.length > 1,
          })}
          appearance="icon"
          onButtonClick={() => fields.remove(index)}
        >
          <Icon name="close" />
        </Button>
        <div className="guest-booking-number col-12 col-md-6">
          <Field
            name={`${booking}.bookingNumber`}
            type="text"
            component={InputText}
            placeholder={labels.bookingNumber}
            errorCodes={{
              [ERROR_CODES.REQUIRED]: 'BookingNumberRequired',
            }}
            maxLength={7}
            {...numericMask(10)}
            onChange={(e) => {
              handleClear();
              const { onChange } = numericMask(10);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
        <div className="guest-name col-12 col-md-6">
          <Field
            name={`${booking}.lastName`}
            type="text"
            component={InputText}
            placeholder={labels.lastName}
            errorCodes={{
              [ERROR_CODES.REQUIRED]: 'LastNameRequired',
              [ERROR_CODES.DUPLICATE_INVITEE]: ERROR_CODES.DUPLICATE_INVITEE,
            }}
            maxLength={40}
          />
        </div>
        {error?.length && (
          <div className="col-12 d-flex justify-content-start">
            <div>{error}</div>
          </div>
        )}
      </div>
    ))}
    {fields.length < 5 && (
      <div className="col-12 d-md-none d-flex justify-content-start">
        <Button appearance="link" onButtonClick={() => fields.push({ number: fields.length })}>
          {labels.addGuests}
        </Button>
      </div>
    )}
  </div>
);

InviteGuestGroup.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }).isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    inviteGuests: PropTypes.string,
    bookingNumber: PropTypes.string,
    lastName: PropTypes.string,
    inviteMoreGuests: PropTypes.string,
    addGuests: PropTypes.string,
  }),
  handleClear: PropTypes.func.isRequired,
};

InviteGuestGroup.defaultProps = {
  labels: {},
};

const ModalReservationForm = ({
  guests,
  formValues,
  availableTimes,
  availableDays,
  has2topTimesAvailable,
  hasSharedTimesAvailable,
  labels,
  handleReservationDateChange,
  handleSubmit,
  error,
  alert,
  alertIsUrgent,
  reservationType,
  isReservationAllowed,
  isPpg,
  isSsbp,
  isSsbpIncluded,
  time,
  lockdownMessage,
  submitting,
  submitFailed,
  reservationModalInfo,
  handleCancel,
  subText,
  updateReservationModalState,
  invitees,
  resetReservationForm,
  handleClear,
  gifLockdown,
  handleCloseModalAndNav,
  ukOlbMessage,
  inviteGuestFormCompleted,
  selectedDay,
  selectedTime,
  isSsbpSoldout,
  noReservationsRemaining,
  isDiningOpen,
  availabilityLoading,
  ...reservation
}) => {
  const reservationMessage = error || alert;
  const reservationMessageIsUrgent = error || alertIsUrgent;
  let buttonAlert;
  const { primaryButton, secondaryButton } = reservation;
  const { gifCallToActionUrl, gifCallToActionTitle, gifValidationErrorBody, gifIncompleteLockdown } = gifLockdown;
  const reservationAllowed = !isReservationAllowed && !isPpg && !isSsbp;
  const someDiningOptionsUnavailable = !has2topTimesAvailable || !hasSharedTimesAvailable;

  switch (reservationModalInfo.state) {
    case RESERVATION_STATE_KEYS.RESERVING:
    case RESERVATION_STATE_KEYS.RESERVING_SHARED: {
      secondaryButton.onButtonClick = () => {
        if (Object.keys(invitees).length) {
          updateReservationModalState(RESERVATION_STATE_KEYS.INVITING, {});
        } else {
          resetReservationForm();
        }
      };
      break;
    }
    case RESERVATION_STATE_KEYS.INVITING: {
      secondaryButton.onButtonClick = () => {
        resetReservationForm();
      };
      break;
    }
    case RESERVATION_STATE_KEYS.EDITING: {
      secondaryButton.onButtonClick = () => {
        handleCancel(reservationModalInfo);
      };
      break;
    }
    default: {
      break;
    }
  }

  // TODO: move this logic into the selector
  if (reservationType === DINING) {
    buttonAlert = alert;
  } else {
    buttonAlert = guests.length ? null : reservationMessage;
  }

  const timeSelectDisabled = !formValues.reservationDate || reservationAllowed;
  const timeValidations = [];
  if (!timeSelectDisabled) {
    timeValidations.push(required);
  }
  if (ukOlbMessage) {
    return (
      <div className="reservation-form">
        <div className="uk-olb-message" dangerouslySetInnerHTML={prepareHtml(ukOlbMessage)} />
      </div>
    );
  }
  let content = (
    <fieldset disabled={reservationAllowed}>
      {gifIncompleteLockdown && (
        <div className="validation-error-text">
          <Button appearance="link" onButtonClick={() => handleCloseModalAndNav(gifCallToActionUrl)}>
            {gifCallToActionTitle}
          </Button>{' '}
          <span>{gifValidationErrorBody}</span>
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        {guests.map((guest, index) => (
          <ReservationGuestWidget
            key={guest}
            guestName={guest}
            reservationMessage={
              reservationType !== DINING && index + 1 === guests.length && !submitFailed ? reservationMessage : null
            }
            reservationMessageIsUrgent={reservationMessageIsUrgent}
            reservationTime={time}
          />
        ))}
        {availableDays?.options?.length > 0 && (
          <Field
            name="reservationDate"
            component={Select}
            {...availableDays}
            onChange={handleReservationDateChange}
            disabled={reservationAllowed}
            validate={[required]}
            errorCodes={{
              [ERROR_CODES.REQUIRED]: 'DayRequired',
            }}
            title={selectedDay ? selectedDay?.label[0] : availableDays?.placeholder}
          />
        )}
        {availableTimes?.options?.length > 0 && (
          <Field
            name="reservationTime"
            component={Select}
            {...availableTimes}
            disabled={timeSelectDisabled}
            validate={timeValidations}
            errorCodes={{
              [ERROR_CODES.REQUIRED]: 'TimeRequired',
            }}
            title={selectedTime ? selectedTime?.label : availableTimes?.placeholder}
          />
        )}

        <div
          className={classNames('reservation-actions', {
            'ssbp-reservation-actions': isSsbp && (isSsbpIncluded || isSsbpSoldout),
          })}
        >
          <FormActions
            stacked
            primaryButton={{
              ...primaryButton,
              loading: submitting,
            }}
            secondaryButton={{
              ...secondaryButton,
              disabled: submitting,
            }}
            subText={subText}
            alert={buttonAlert}
          />
        </div>
        {error && !submitting && (
          <div className="validation-error-text small-body-copy" dangerouslySetInnerHTML={prepareHtml(error)} />
        )}
        {lockdownMessage && (
          <div className="dining-alerts-container">
            <div
              className="validation-error-text small-body-copy"
              dangerouslySetInnerHTML={prepareHtml(lockdownMessage)}
            />
          </div>
        )}
      </Form>
    </fieldset>
  );

  if (reservationType === DINING) {
    switch (reservationModalInfo.state) {
      case RESERVATION_STATE_KEYS.RESERVING:
      case RESERVATION_STATE_KEYS.RESERVING_SHARED: {
        const is2TopReservation = reservationModalInfo.state === RESERVATION_STATE_KEYS.RESERVING;
        content = (
          <fieldset disabled={reservationAllowed}>
            {gifIncompleteLockdown && (
              <div className="validation-error-text">
                <Button appearance="link" onButtonClick={() => handleCloseModalAndNav(gifCallToActionUrl)}>
                  {gifCallToActionTitle}
                </Button>{' '}
                <span>{gifValidationErrorBody}</span>
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              {guests.map((guest, index) => (
                <ReservationGuestWidget
                  key={guest}
                  guestName={guest}
                  reservationMessage={
                    reservationType !== DINING && index + 1 === guests.length && !submitFailed
                      ? reservationMessage
                      : null
                  }
                  reservationMessageIsUrgent={reservationMessageIsUrgent}
                  reservationTime={time}
                />
              ))}
              <div className="row table-for-two-container">
                <div className="col-12 col-md-4 table-for-two-info">
                  <div className="w-100">
                    <h6>{is2TopReservation ? labels.tableForTwoHeader : labels.sharedTables}</h6>
                    <div className="w-100">
                      {is2TopReservation ? labels.tableForTwoSubText : labels.shareTablesSubText}
                    </div>
                  </div>
                  <div className="d-none d-md-block table-for-two-party-size">{subText}</div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    {availableDays && (
                      <div className="col-12 col-md-6 table-for-two-select-day">
                        <Field
                          name="reservationDate"
                          component={Select}
                          {...availableDays}
                          onChange={handleReservationDateChange}
                          disabled={reservationAllowed}
                          validate={[required]}
                          errorCodes={{
                            [ERROR_CODES.REQUIRED]: 'DayRequired',
                          }}
                          title={selectedDay ? selectedDay?.label[0] : availableDays?.placeholder}
                        />
                      </div>
                    )}
                    {availableTimes && (
                      <div className="col-12 col-md-6 table-for-two-select-time">
                        <Field
                          name="reservationTime"
                          component={Select}
                          {...availableTimes}
                          disabled={timeSelectDisabled}
                          validate={timeValidations}
                          errorCodes={{
                            [ERROR_CODES.REQUIRED]: 'TimeRequired',
                          }}
                          title={selectedTime ? selectedTime?.label : availableTimes?.placeholder}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames('row reservation-actions table-for-two-btn-container row align-items-end', {
                      'ssbp-reservation-actions': isSsbp && (isSsbpIncluded || isSsbpSoldout),
                    })}
                  >
                    <div className="col-12 table-for-two-form-btn">
                      <FormActions
                        multipleButtons
                        primaryButton={{
                          ...primaryButton,
                          loading: submitting,
                        }}
                        secondaryButton={{
                          ...secondaryButton,
                          disabled: submitting,
                        }}
                        alert={buttonAlert}
                      />
                    </div>
                    <div className="d-md-none col-12 table-for-two-party-size">{subText}</div>
                  </div>
                  {error && !submitting && (
                    <div
                      className="validation-error-text small-body-copy"
                      dangerouslySetInnerHTML={prepareHtml(error)}
                    />
                  )}
                </div>
              </div>
            </Form>
          </fieldset>
        );
        break;
      }
      case RESERVATION_STATE_KEYS.INVITING: {
        content = (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="invite-guest-info">{labels.inviteGuestLabel}</div>
                <div className="small-body-copy">{labels.inviteGuestMessage}</div>
              </div>
              <div className="col-12 col-md-8">
                {guests.length < DINING_MAX_TABLE_SIZE && (
                  <div className="reservation-guests w-100">
                    <div className="field-wrapper">
                      <FieldArray
                        name="addGuest"
                        component={InviteGuestGroup}
                        labels={labels}
                        handleClear={handleClear}
                        formSubmitting={submitting}
                      />
                    </div>
                  </div>
                )}
                <div className="more-guests-buttons">
                  <FormActions
                    multipleButtons
                    primaryButton={{
                      ...primaryButton,
                      loading: !primaryButton.disabled && submitting,
                      disabled:
                        reservationModalInfo.metadata?.forceDisableSubmitBtn || !inviteGuestFormCompleted || error,
                      subText: '',
                    }}
                    secondaryButton={{
                      ...secondaryButton,
                      disabled: secondaryButton.disabled || submitting,
                      subText: '',
                    }}
                    subText={submitting ? labels.inviteGuestsFetchMessage : ''}
                    alert={alert}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
        break;
      }
      default: {
        content = (
          <>
            {availabilityLoading ? (
              <div className="loading-spinner">
                <Spinner color="black" />
              </div>
            ) : (
              <Form onSubmit={handleSubmit}>
                {someDiningOptionsUnavailable && (
                  <div className="dining-alerts-container">
                    {lockdownMessage && <div className="validation-error-text small-body-copy">{lockdownMessage}</div>}
                  </div>
                )}

                <FormActions
                  multipleButtons
                  primaryButton={{
                    ...primaryButton,
                    subText: labels.tableForTwoSubText,
                    disabled:
                      primaryButton.disabled || !isReservationAllowed || !has2topTimesAvailable || !isDiningOpen,
                    onButtonClick: () => updateReservationModalState(RESERVATION_STATE_KEYS.RESERVING),
                  }}
                  primaryButton2={{
                    ...primaryButton,
                    text: labels.sharedTables,
                    subText: labels.shareTablesSubText,
                    disabled:
                      primaryButton.disabled || !isReservationAllowed || !hasSharedTimesAvailable || !isDiningOpen,
                    onButtonClick: () => updateReservationModalState(RESERVATION_STATE_KEYS.RESERVING_SHARED),
                  }}
                  primaryButton3={{
                    ...secondaryButton,
                    subText: labels.inviteGuestSubText,
                    disabled:
                      secondaryButton.disabled || !isReservationAllowed || noReservationsRemaining || !isDiningOpen,
                    appearance: 'secondary-blue',
                    onButtonClick: () => updateReservationModalState(RESERVATION_STATE_KEYS.INVITING),
                  }}
                />
              </Form>
            )}
          </>
        );
      }
    }
  }
  return <div className="reservation-form">{content}</div>;
};

ModalReservationForm.propTypes = {
  resetReservationForm: PropTypes.func.isRequired,
  invitees: PropTypes.objectOf(
    PropTypes.shape({
      bookingId: PropTypes.number,
      passengers: PropTypes.arrayOf(PropTypes.string),
      office: PropTypes.string,
      currency: PropTypes.string,
    })
  ),
  subText: PropTypes.string,
  updateReservationModalState: PropTypes.func.isRequired,
  guests: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
  handleReservationDateChange: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    reservationDate: PropTypes.string,
    inviteGuests: PropTypes.bool,
  }),
  availableDays: PropTypes.shape({
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf({
      label: PropTypes.shape([]),
      value: PropTypes.string,
    }),
  }),
  availableTimes: PropTypes.shape({
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf({
      label: PropTypes.shape([]),
      value: PropTypes.string,
    }),
  }),
  allowGuests: PropTypes.bool,
  labels: PropTypes.shape({
    inviteGuests: PropTypes.string,
    bookingNumber: PropTypes.string,
    lastName: PropTypes.string,
    inviteMoreGuests: PropTypes.string,
    addGuests: PropTypes.string,
  }),
  primaryButton: PropTypes.button.isRequired,
  secondaryButton: PropTypes.button,
  alert: PropTypes.string,
  alertIsUrgent: PropTypes.bool,
  reservationModalInfo: PropTypes.shape({
    metadata: PropTypes.shape({
      passengers: PropTypes.arrayOf(PropTypes.string),
      details: PropTypes.arrayOf(PropTypes.string),
    }),
    state: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reservationType: PropTypes.string,
  isReservationAllowed: PropTypes.bool,
  isPpg: PropTypes.bool,
  isSsbp: PropTypes.bool,
  isSsbpIncluded: PropTypes.bool,
  time: PropTypes.string,
  lockdownMessage: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  gifLockdown: PropTypes.shape({
    gifCallToActionUrl: PropTypes.string,
    gifCallToActionTitle: PropTypes.string,
    gifValidationErrorBody: PropTypes.string,
    gifIncompleteLockdown: PropTypes.bool,
  }),
  handleCloseModalAndNav: PropTypes.func.isRequired,
  ukOlbMessage: PropTypes.string,
  has2topTimesAvailable: PropTypes.bool,
  hasSharedTimesAvailable: PropTypes.bool,
  inviteGuestFormCompleted: PropTypes.bool,
  selectedDay: PropTypes.shape({
    availableTimes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    label: PropTypes.arrayOf(PropTypes.string),
    menuLabel: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedTime: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  isSsbpSoldout: PropTypes.bool,
  availabilityLoading: PropTypes.bool,
};

ModalReservationForm.defaultProps = {
  guests: [],
  invitees: {},
  subText: '',
  error: '',
  alert: '',
  alertIsUrgent: true,
  allowGuests: false,
  formValues: {},
  labels: {},
  availableDays: null,
  availableTimes: null,
  secondaryButton: {},
  reservationType: null,
  isReservationAllowed: true,
  isPpg: false,
  isSsbp: false,
  isSsbpIncluded: false,
  time: null,
  lockdownMessage: null,
  submitFailed: null,
  gifLockdown: {},
  ukOlbMessage: '',
  has2topTimesAvailable: false,
  hasSharedTimesAvailable: false,
  inviteGuestFormCompleted: false,
  selectedDay: null,
  selectedTime: null,
  isSsbpSoldout: false,
  availabilityLoading: false,
};

export default ModalReservationForm;
