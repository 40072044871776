import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';
import commonStore, { getCardModalByPageName, handleDeepLinkModal } from '../../../common/CommonStore';
import { FORMS, MODAL_URL_VARIABLE_NAMES, PAGE_NAMES, TAB_NAMES } from '../../../common/Constants';
import userStore from '../../../common/UserStore';
import { withContent } from '../../../components';
import onboardStore, { fetchTabContent } from '../OnboardStore';
import DiningBeverage from './DiningBeverage';

const {
  creators: { updateReservationModalState },
} = commonStore;
const { getIsMississippi } = userStore.selectors;
const { getDiningBeverageModalCards, getDiningTabContent } = onboardStore.selectors;
const { getLinkQueryParams } = userStore.selectors;
const { getPageTabLabels } = commonStore.selectors;

const tabName = TAB_NAMES.DINING_BEVERAGE;

const mapStateToProps = (state) => {
  const { labels } = getPageTabLabels(state)(PAGE_NAMES.ONBOARD_EXPERIENCE, TAB_NAMES.DINING_BEVERAGE) || {};
  const content = getDiningTabContent(state);
  const modalPageName = get(getLinkQueryParams(state), MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE, '');
  return {
    content,
    modalData: getDiningBeverageModalCards(state),
    isSubmitting: isSubmitting(FORMS.RESERVATION)(state),
    isMississippi: getIsMississippi(state),
    modalToOpen: getCardModalByPageName(content?.sections, modalPageName),
    labels,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTabContent(tabName));

    // TODO: Uncomment when "realtime" dining menu is available.
    // dispatch(fetchRestaurantMenu());
  },
  handleCardDetailsClicked: (type, id, shipId) =>
    dispatch(handleDeepLinkModal(type, id, MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE, shipId)),
  resetModalState: () => dispatch(updateReservationModalState()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(DiningBeverage);
