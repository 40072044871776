import AlertWithLink from '@viking-eng/alert-with-link';
import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import InputText from '@viking-eng/input-text';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { email as emailValidator, ERROR_CODES, required } from '../../../../common/forms/Validations';
import { Field } from '../../../../components';
import PropTypes from '../../../../PropTypes';
import BoardingPass from '../boardingPass/BoardingPassContainer';
import './PassengerSelector.scss';

class PassengerSelector extends React.Component {
  constructor() {
    super();
    this.componentRef = [];
  }

  render() {
    const {
      cciClosed,
      cciClosedMessage,
      checkedInData,
      checkInComboError,
      checkInNow,
      checkInStatusError,
      content,
      handleCheckInNow,
      handleGifRedirect,
      handleShowModal,
      handleSubmit,
      incompleteGifError,
      isEmailEnabled,
      passenger: { checkedIn, gifCompleted, guestId, name, passengerNumber },
      startSurveyDisabled,
      submitSucceeded,
      submitting,
    } = this.props;

    const { customerService, emailSent, toCompleteCheckIn, unableToCompleteOne } = content;

    const checkInError = (
      <AlertWithLink
        alert={unableToCompleteOne}
        alertButton={{
          text: customerService,
          onButtonClick: () => handleShowModal(),
        }}
        alertEndText={toCompleteCheckIn}
      />
    );
    if (!passengerNumber) {
      return null;
    }

    let buttonContent = (
      <div>
        <Button
          appearance="secondary-blue"
          attributes={{
            disabled: startSurveyDisabled,
          }}
          onButtonClick={() => {
            handleCheckInNow(passengerNumber);
          }}
          className={cciClosed ? 'with-disabled-msg' : ''}
        >
          {checkInNow}
        </Button>
        {checkInStatusError && gifCompleted && <div className="mxp-error">{checkInStatusError}</div>}
        {!gifCompleted && !cciClosed && (
          <div className="gif-incomplete-error">
            <AlertWithLink
              alert={incompleteGifError.alertText}
              alertButton={{
                text: incompleteGifError.buttonText,
                onButtonClick: () => handleGifRedirect(passengerNumber),
              }}
              alertEndText={incompleteGifError.alertEndText}
            />
          </div>
        )}
        {cciClosed && <div className="disabled-message">{cciClosedMessage}</div>}
      </div>
    );

    if (checkedIn && guestId) {
      buttonContent = (
        <div>
          <div className="checked-in-alert">
            <div className="checked-in-title">
              <Icon name="checkmark" />
              {checkedInData.title}
            </div>
            <div className="checked-in-message">{checkedInData.body}</div>
          </div>
          <ReactToPrint
            trigger={() => (
              <button type="button" className="viking-button btn btn-secondary-blue">
                {checkedInData.printBoardingPass}
              </button>
            )}
            content={() => this.componentRef[passengerNumber]}
          />
          {isEmailEnabled && (
            <>
              <div className="or-divider row no-gutters">
                <div className="or-horizontal-line col" />
                <div className="or-text col">{checkedInData.or}</div>
                <div className="or-horizontal-line col" />
              </div>
              <div className="email-title">{checkedInData.emailBoardingPass}</div>
              <div className="form email-boarding-pass row no-gutters">
                <div className="email-field-wrapper col">
                  <Field
                    name="email"
                    component={InputText}
                    type="email"
                    inputAttributes={{ 'aria-label': checkedInData.emailAddress }}
                    placeholder={checkedInData.emailAddress}
                    validate={[required, emailValidator]}
                    errorCodes={{
                      [ERROR_CODES.REQUIRED]: ERROR_CODES.EMAIL_REQUIRED,
                      [ERROR_CODES.INVALID_EMAIL_FORMAT]: ERROR_CODES.INCORRECT_EMAIL,
                    }}
                  />
                </div>
                <div className="form-submit-wrapper col-3">
                  <Button
                    loading={submitting}
                    appearance="secondary-blue"
                    attributes={{ type: 'submit' }}
                    onButtonClick={handleSubmit}
                  >
                    {checkedInData.send}
                  </Button>
                </div>
              </div>
              {submitSucceeded && emailSent && <div className="email-sent">{emailSent}</div>}
            </>
          )}
          {checkInComboError && <div className="combo-error">{checkInError}</div>}
          <div className="d-none">
            <div
              ref={(el) => {
                this.componentRef[passengerNumber] = el;
              }}
            >
              <BoardingPass passengerNumber={passengerNumber} guestId={guestId} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="passenger-name-wrapper col-md col-sm-12" key={`passenger_${passengerNumber}`}>
        <div className="passenger-name h4">{name}</div>
        <div className="button-wrapper">{buttonContent}</div>
      </div>
    );
  }
}

PassengerSelector.propTypes = {
  cciClosed: PropTypes.bool.isRequired,
  cciClosedMessage: PropTypes.string.isRequired,
  checkedInData: PropTypes.shape({}).isRequired,
  checkInComboError: PropTypes.bool.isRequired,
  checkInNow: PropTypes.string.isRequired,
  checkInStatusError: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  handleCheckInNow: PropTypes.func.isRequired,
  handleGifRedirect: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  incompleteGifError: PropTypes.string.isRequired,
  isEmailEnabled: PropTypes.bool,
  passenger: PropTypes.shape({
    checkedIn: PropTypes.bool,
    guestId: PropTypes.number,
    name: PropTypes.string.isRequired,
    passengerNumber: PropTypes.number.isRequired,
  }).isRequired,
  startSurveyDisabled: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

PassengerSelector.defaultProps = {};

export default PassengerSelector;
