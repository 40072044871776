import Button from '@viking-eng/button';
import IconGroup from '@viking-eng/icon-group';
import React, { useState } from 'react';
import PropTypes from '../../PropTypes';
import { DINING_DETAILS, MODAL_CHARS_IN_ONE_LINE } from '../../common/Constants';
import { prepareHtml } from '../../common/Utils';

const reservationModalsWithSectionReferences = ['SSBP', 'GRAT'];
const modalSectionReferences = ['ssbpMenu', 'ppgModal'];

const ReservationDefaultMainContent = ({ infoIcons = [], reference, sections = [], hasReadMore = false }) => {
  const [showMoreButton, setShowMoreButton] = useState(true);
  const readMoreToggle = showMoreButton ? DINING_DETAILS.READ_MORE : DINING_DETAILS.READ_LESS;

  const isRefSection = reservationModalsWithSectionReferences.includes(reference);
  const filteredSections = sections.filter(
    (section) =>
      (section.description || section.longText) && (!isRefSection || modalSectionReferences.includes(section.reference))
  );
  const sectionTexts = filteredSections.map((section) => section.description || section.longText);
  const showReadMore =
    hasReadMore &&
    (sectionTexts.length > 1 || (sectionTexts.length > 0 && sectionTexts[0].length > MODAL_CHARS_IN_ONE_LINE));

  return (
    <>
      {infoIcons && !!infoIcons.length && (
        <div className="row icons">
          <div className="col">
            <IconGroup icons={infoIcons} />
          </div>
        </div>
      )}
      <div className={`section-content ${showReadMore && showMoreButton ? 'short' : 'full'}`}>
        {sectionTexts.map((section) => (
          <div className="description" dangerouslySetInnerHTML={prepareHtml(section)} />
        ))}
      </div>
      {showReadMore && (
        <Button onButtonClick={() => setShowMoreButton(!showMoreButton)} className="modal-show-more-button">
          {readMoreToggle}
        </Button>
      )}
    </>
  );
};

ReservationDefaultMainContent.propTypes = {
  reference: PropTypes.string,
  hasReadMore: PropTypes.bool,
  infoIcons: PropTypes.arrayOf(PropTypes.icon),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
};

export default ReservationDefaultMainContent;
