import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import { prepareHtml } from '@viking-eng/utils';
import React from 'react';

import PropTypes from '../../PropTypes';

import { Modal } from '..';

import { NOTIFICATION_TYPES } from '../../common/Constants';
import { triggerLinkEvent } from '../../common/Analytics';

import './Notification.scss';

const NotificationModal = ({ id, title, message, type, buttonText, align, buttonAppearance, printLabel, onClose, trackingData = {} }) => {
  const clickHandler = (e) => {
    const el = e.target.closest('B');
    if (el && e.currentTarget.contains(el)) {
      const nodes = el?.childNodes;
      let link = null;
      nodes.forEach((node) => {
        if (node?.localName === 'a') {
          link = node;
        }
        });
      const text = link?.innerText;
        triggerLinkEvent({
          event: 'modal_click',
          modal_type: 'promo_notification',
          modal_name: id,
          cta_name: text,
          link_type: 'button',
          ...trackingData
        });
    }
  };
  return (
    <Modal id={id} onClose={onClose} printLabel={printLabel}>
      {(title || message) && (
        <div className="notification-modal">
          <div className="mvj-modal">
            <div className="row no-gutters d-flex content">
              <div className="title text-center" dangerouslySetInnerHTML={prepareHtml(title)} />
              <div className="notification-icon">
                {type === NOTIFICATION_TYPES.FAILURE && (
                  <span className="error">
                    <Icon name="exclamation-mark-filled" />
                  </span>
                )}
                {type === NOTIFICATION_TYPES.SUCCESS && (
                  <span className="success">
                    <Icon name="check-circle" />
                  </span>
                )}
              </div>
              {message && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  onClick={clickHandler}
                  className={`message text-${align} col-12 col-md-6`}
                  dangerouslySetInnerHTML={prepareHtml(message)}
                />
              )}
              <Button
                appearance={buttonAppearance}
                onButtonClick={() => {
                  onClose();
                }}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

NotificationModal.propTypes = {
  ...PropTypes.modalData,
};

NotificationModal.defaultProps = {
  align: 'center',
  buttonAppearance: 'secondary-gray',
};

export default NotificationModal;
