import Button from '@viking-eng/button';
import React from 'react';
import PropTypes from '../../PropTypes';
import { MODALS } from '../../common/Constants';
import { DialogModal } from '../../modals';
import './AirNav.scss';

const { EXIT_SEAT_SELECTED_MODAL } = MODALS;

const AirNav = ({
  nav,
  primaryClick,
  secondaryClick,
  searchReqPending,
  searchCriteriaStatus,
  isSearchPath,
  exitSeatSelectedModal,
  acceptExitSeat,
  clearModal,
}) => {
  return (
    <>
      {(!isSearchPath || (!searchReqPending && searchCriteriaStatus === 200 && isSearchPath)) && (
        <div className="air-nav-container align-items-center">
          <Button className="sec-btn" onButtonClick={secondaryClick} appearance="link" disabled={nav.secondaryDisabled}>
            {nav.secondaryLabel}
          </Button>
          <Button
            className="prim-btn"
            onButtonClick={primaryClick}
            appearance="secondary-blue"
            loading={nav.primaryProcessing}
            disabled={nav.primaryDisabled}
          >
            {nav.primaryLabel}
          </Button>
          <DialogModal
            forceAction
            modalClassName="exit-seat-modal"
            id={EXIT_SEAT_SELECTED_MODAL}
            {...exitSeatSelectedModal}
            onCancel={clearModal}
            onConfirm={() => {
              acceptExitSeat();
              primaryClick();
            }}
          />
        </div>
      )}
    </>
  );
};

AirNav.propTypes = {
  nav: PropTypes.shape(),
  primaryClick: PropTypes.func,
  secondaryClick: PropTypes.func,
  searchReqPending: PropTypes.bool,
  searchCriteriaStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSearchPath: PropTypes.bool,
  exitSeatSelectedModal: PropTypes.shape(),
  clearModal: PropTypes.func,
  acceptExitSeat: PropTypes.func,
};
AirNav.defaultProps = {
  primaryClick: () => null,
  nav: {},
  secondaryClick: () => null,
  searchReqPending: true,
  searchCriteriaStatus: '',
  isSearchPath: false,
  exitSeatSelectedModal: {},
  clearModal: () => null,
  acceptExitSeat: () => null,
};
AirNav.displayName = 'AirNav';

export default AirNav;
