import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore from '../../../common';
import { COMFORT_CHECK_IN_WINDOW, MVJ_FLAG_VARIABLES, TAB_NAMES } from '../../../common/Constants';
import userStore from '../../../common/UserStore';
import { withContent } from '../../../components';
import documentStore, {
  fetchGifContent,
  fetchTabContent,
  getComfortCheckInStatus,
  resumeCciIfNeeded,
} from '../DocumentsStore';
import ComfortCheckIn from './ComfortCheckIn';

const { getIsCloseToDepartureDate, getFirstEncounterDate } = userStore.selectors;
const { getComfortCheckingTabContent, getComfortCheckInStatusLoading } = documentStore.selectors;
const { getFlagValue } = commonStore.selectors;
const { CCI_CLOSE_HOURS } = MVJ_FLAG_VARIABLES;

const tabName = TAB_NAMES.COMFORT_CHECK_IN;

const mapStateToProps = (state) => {
  return {
    content: getComfortCheckingTabContent(state),
    countDown: getFirstEncounterDate(state),
    countDownCloseThresholdHours: getFlagValue(state)(CCI_CLOSE_HOURS),
    checkInStatusLoading: getComfortCheckInStatusLoading(state),
    isCloseToDepartureDate: getIsCloseToDepartureDate(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch((_, getState) => {
      const state = getState();
      const cciOpenDate = moment(getFirstEncounterDate(state), 'YYYY-MM-DDThh:mm:ss').subtract(
        COMFORT_CHECK_IN_WINDOW,
        'days'
      );
      dispatch(fetchTabContent(tabName)).then(() => dispatch(resumeCciIfNeeded()));
      if (moment().isSameOrAfter(cciOpenDate)) {
        dispatch(getComfortCheckInStatus());
      }
      dispatch(fetchGifContent());
    });
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(ComfortCheckIn);
