import React from 'react';
import QRCode from 'react-qr-code';
import PropTypes from '../../../../PropTypes';
import { AIR_LABELS, APP_PATHS } from '../../../../common/Constants';
import { navigateTo } from '../../../../common/Utils';
import './BoardingPass.scss';

const RenderSection = ({ content, sectionName }) => (
  <div className="section" key={sectionName}>
    <div className="content-wrapper row no-gutters">
      {content.map(({ label, value }) => (
        <div className="content-item col-3" key={label}>
          <div className="item-label">{label}</div>
          <div className="item-value">{value}</div>
        </div>
      ))}
    </div>
  </div>
);

RenderSection.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionName: PropTypes.string.isRequired,
};

const BoardingPass = ({ route, sections, logoUrl, guestId, postText, journey }) => {
  if (!sections) {
    navigateTo(APP_PATHS.COMFORT_CHECK_IN);
  }
  const QR_SIZE = 200;
  return (
    <div className="boarding-pass-wrapper">
      <div className="boarding-pass">
        <div className="route-wrapper row no-gutters">
          <div className="viking-header">
            <img className="logo-image" src={logoUrl} alt="logo" />
          </div>
          <div className="boarding-pass-header">{AIR_LABELS.BoardingPass}</div>
          <div className="qr-code">
            <QRCode
              size={QR_SIZE}
              style={{ height: 'auto', width: '160px', maxWidth: '160px', paddingRight: '40px', paddingTop: '24px' }}
              value={`${guestId}`}
              className="qr-image"
            />
          </div>
        </div>
        {sections && (
          <>
            <div className="sections-wrapper">
              <div className="journey-text">{journey}</div>
              <div className="content-wrapper row no-gutters">
                <div className="content-item col-12">
                  <div className="item-label">{route.label}</div>
                  <div className="item-value">{route.value}</div>
                </div>
              </div>
              {sections.map((section) => (
                <RenderSection {...section} />
              ))}
              <div className="boarding-pass-post-text">{postText}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

BoardingPass.propTypes = {
  route: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  logoUrl: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  guestId: PropTypes.number.isRequired,
  postText: PropTypes.string.isRequired,
};

BoardingPass.defaultProps = {
  route: {},
  sections: null,
};

export default BoardingPass;
