import { connect } from 'react-redux';
import { USER_TYPES } from '../../common/Constants';
import commonStore from '../../common/CommonStore';
import userStore from '../../common/UserStore';
import MvjPageHeader from './MvjPageHeader';

const { getUserType, getIsTaAccount, getFeatureRestricted } = userStore.selectors;
const { getFeatureRestrictedMessage } = commonStore.selectors;

const mapStateToProps = (state) => {
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);
  const hasPreHeader = isTaAccount || userType === USER_TYPES.CSA || userType === USER_TYPES.AIR;

  const alertText = getFeatureRestrictedMessage(state);
  return {
    hasPreHeader,
    featureRestrictedMessage: alertText ? { alertText, icon: 'notification' } : null,
    featureRestricted: getFeatureRestricted(state),
  };
};

export default connect(mapStateToProps)(MvjPageHeader);
