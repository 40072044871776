import { connect } from 'react-redux';
import { compose } from 'redux';

import documentStore, { fetchTabContent } from '../DocumentsStore';
import userStore from '../../../common/UserStore';
import commonStore from '../../../common';
import { withContent } from '../../../components';
import { TAB_NAMES, MVJ_FLAG_VARIABLES } from '../../../common/Constants';

import FitToTravel from './FitToTravel';

const { getFirstEncounterDate } = userStore.selectors;
const { getFitToTravelPageContent } = documentStore.selectors;
const { getFlagValue } = commonStore.selectors;
const { FIT_TO_TRAVEL_CLOSE_DATE } = MVJ_FLAG_VARIABLES;

const tabName = TAB_NAMES.FIT_TO_TRAVEL;

const mapStateToProps = (state) => {
  return {
    content: getFitToTravelPageContent(state),
    countDownFirstEncounterDate: getFirstEncounterDate(state),
    countDownCloseThresholdDays: getFlagValue(state)(FIT_TO_TRAVEL_CLOSE_DATE),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => {
    dispatch(fetchTabContent(tabName));
  },
  handleSelectPassenger: (url) => {
    window.open(url, '_blank');
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(FitToTravel);
