import Button from '@viking-eng/button';
import CalendarEvent from '@viking-eng/calendar-event';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { APP_PATHS, EXTENSION_TYPES } from '../../../common/Constants';
import { goTo, stripHTMLTags } from '../../../common/Utils';

const CalendarEntry = ({ item, passengers, itineraryDate, passengerIndex, onClick, ...rest }) => {
  if (!item || !item.data.endTime || !item.data[`forPassenger${passengerIndex + 1}`]) {
    return null;
  }

  const mappedItem = { ...item };
  const unconfirmed = mappedItem?.data?.[`isInCartPax${passengerIndex + 1}`];
  const hideButton = !unconfirmed;

  if (mappedItem.button && unconfirmed) {
    mappedItem.button.onButtonClick = (e) => {
      e.stopPropagation();
      goTo(APP_PATHS.CART)();
    };
  }
  let itemComponent = (
    <CalendarEvent
      title={stripHTMLTags(mappedItem.data.description)}
      time={mappedItem.displayTime}
      type={mappedItem.data.extensionType === EXTENSION_TYPES.SHOREX ? 'shorex' : 'onboard'}
      button={hideButton ? null : mappedItem.button}
      unconfirmed={unconfirmed}
      {...rest}
    />
  );
  if (onClick) {
    itemComponent = (
      <Button onButtonClick={() => onClick(mappedItem, { passengers, itineraryDate }, passengerIndex)}>
        {itemComponent}
      </Button>
    );
  }
  return (
    <div className="event-wrapper" style={mappedItem.style} key={mappedItem.data.itemId}>
      {itemComponent}
    </div>
  );
};

CalendarEntry.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      endTime: PropTypes.string,
      extensionType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  passengers: PropTypes.arrayOf(PropTypes.string).isRequired,
  itineraryDate: PropTypes.itineraryDate.isRequired,
  passengerIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

CalendarEntry.defaultProps = {
  onClick: null,
};

export default CalendarEntry;
