import FormActions from '@viking-eng/form-actions';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Form } from 'redux-form';
import PropTypes from '../../../PropTypes';
import { triggerPageView } from '../../../common/Analytics';
import { ALERT_TYPES } from '../../../common/Constants';
import { AirPreferencesFormPart, SeatingPreference } from '../../../components';
import './AirPreferences.scss';

const AirPreferences = ({
  availablePreferences: { availableSeatings, passengerPreferences },
  passengerNames,
  labels: { cancel, update, success, addAnother },
  title,
  handleSubmit,
  handleCancel,
  submitSucceeded,
  pristine,
  submitting,
  lockedDown,
  isSinglePassenger,
  isSeperatePassengerSeating,
  pageAnalytics,
  error,
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    triggerPageView(window.location.pathname, 'AIR', pageAnalytics);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const renderLowerHalf = (index) => (
    <AirPreferencesFormPart
      {...passengerPreferences}
      index={index}
      showTooltips={index + 1 === passengerNames.length}
      fields={['wheelchair', 'meal', 'meetAndAssist']}
      lockedDown={lockedDown}
    />
  );
  let alert;
  if (submitSucceeded) {
    alert = { type: ALERT_TYPES.INFO, message: success };
  } else if (error) {
    alert = { type: ALERT_TYPES.ERROR, message: error };
  }
  return (
    <Form onSubmit={handleSubmit} className="air-preferences-form">
      <div className="row col-12">
        {!isSinglePassenger && !isSeperatePassengerSeating && (
          <SeatingPreference lockedDown={lockedDown} seatings={availableSeatings} header={title} />
        )}
        <div className={classNames('common-section', { 'text-center': isSinglePassenger })}>
          <div className="row justify-content-center">
            {passengerNames.map((name, index) => (
              <div className="col-md-6 col-xs-12 per-user" key={name}>
                <AirPreferencesFormPart
                  {...passengerPreferences}
                  passengerName={name}
                  index={index}
                  showTooltips={index + 1 === passengerNames.length}
                  fields={['redressNumber', 'knownTravelerNumber', 'frequentFlyers', 'seat']}
                  addAnother={addAnother}
                  lockedDown={lockedDown}
                />
                <div className="d-md-none">{name && renderLowerHalf(index)}</div>
              </div>
            ))}
          </div>
          <div className="row d-none d-md-flex justify-content-center">
            {passengerNames.map((name, index) => (
              <div className="col-md-6 col-xs-12 per-user" key={name}>
                {name && renderLowerHalf(index)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <FormActions
        alert={alert}
        scrollOnAlert
        primaryButton={{ loading: submitting, text: update, disabled: pristine }}
        secondaryButton={{ onButtonClick: handleCancel, text: cancel }}
      />
    </Form>
  );
};

AirPreferences.propTypes = {
  availablePreferences: PropTypes.shape({
    availableSeatings: PropTypes.arrayOf(PropTypes.seating),
    passengerPreferences: PropTypes.shape({}),
  }),
  passengerNames: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.shape({
    update: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    addAnother: PropTypes.string.isRequired,
  }),
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  lockedDown: PropTypes.bool,
  isSinglePassenger: PropTypes.bool,
  isSeperatePassengerSeating: PropTypes.bool,
  pageAnalytics: PropTypes.shape(),
  error: PropTypes.string,
};

AirPreferences.defaultProps = {
  passengerNames: [],
  availablePreferences: {},
  labels: {
    update: '',
    cancel: '',
    addAnother: '',
  },
  title: '',
  lockedDown: false,
  isSinglePassenger: false,
  isSeperatePassengerSeating: false,
  pageAnalytics: {},
  error: null,
};

export default AirPreferences;
