import { prepareHtml } from '@viking-eng/utils';
import classNames from 'classnames';
import React from 'react';
import PropTypes from '../../../PropTypes';

const ModalHeading = ({ title, subtitle, subtitleAria, subTitle2, alert, confirmed, dining, isTheRestaurant }) => (
  <div
    className={classNames({
      'dining-modal-header': dining,
      'the-restaurant': isTheRestaurant,
    })}
  >
    {title && <h4 className="reservation-modal-title">{title}</h4>}
    {subtitle && (
      <h4 className="reservation-modal-subtitle">
        <span aria-label={subtitleAria} dangerouslySetInnerHTML={prepareHtml(subtitle)} />
        {!confirmed && alert && <span className="availability">{alert}</span>}
      </h4>
    )}
    {subTitle2 && <div className="sub-title" dangerouslySetInnerHTML={prepareHtml(subTitle2)} />}
  </div>
);

ModalHeading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  subtitleAria: PropTypes.string,
  subTitle2: PropTypes.string,
  alert: PropTypes.string,
  confirmed: PropTypes.bool,
  dining: PropTypes.bool,
  isTheRestaurant: PropTypes.bool,
};

ModalHeading.defaultProps = {
  title: '',
  subtitle: '',
  subtitleAria: '',
  subTitle2: '',
  alert: '',
  confirmed: false,
  dining: false,
  isTheRestaurant: false,
};

export default ModalHeading;
