import Button from '@viking-eng/button';
import CardSections from '@viking-eng/card-sections';
import Icon from '@viking-eng/icon';
import Image from '@viking-eng/image';
import React, { Component } from 'react';
import PropTypes from '../../PropTypes';
import { THREE_BY_TWO } from '../../common/Constants';
import remapCardDataBasedOnModalType from '../../common/ModalUtils';
import { prepareHtml } from '../../common/Utils';
import { PageHeader, TravelProtectionModalSideContent } from '../../components';
import { PictureModal, ReservationModal, SilverSpiritsModal } from '../../modals';
import BookingDetail from './BookingDetail';
import './TravelBooking.scss';

const SideContent = ({ sideContentData: { regionalBookingPhoneNumber, planDetailsLink } }) => (
  <div className="travel-protection-side-content">
    <div className="title">{regionalBookingPhoneNumber}</div>
    <div className="plan-details-link" dangerouslySetInnerHTML={prepareHtml(planDetailsLink)} />
  </div>
);

SideContent.propTypes = {
  sideContentData: PropTypes.shape({
    title: PropTypes.string,
    planDetailsLink: PropTypes.string,
  }),
};

SideContent.defaultProps = {
  sideContentData: {},
};

class TravelBooking extends Component {
  addModalOpen() {
    const { travelBookingCardData, handleModalOpen, showTPCard, handleContractOpen } = this.props;
    return travelBookingCardData.map((section) => ({
      ...section,
      cards: (showTPCard ? section.cards : section.cards.filter((x) => x.reference !== 'travelProtection')).map(
        (card) => {
          let onClick;
          onClick = () => {
            handleModalOpen('travelProtection', card.id);
          };

          const references = ['SSBP', 'travelProtection', 'passengerTicketContractCard'];
          if (card.reference === 'passengerTicketContractCard') {
            onClick = handleContractOpen;
          }

          if (references.includes(card.reference)) {
            return remapCardDataBasedOnModalType(card, {
              onPrimaryButtonClick: onClick,
              onSecondaryButtonClick: onClick,
              defaultClick: onClick,
            });
          }
          return card;
        }
      ),
    }));
  }

  render() {
    const {
      content: { details, cruiseName, daysToGo, daysToGoLabel, images, pictureModalData, travelProtectionModalData },
      isLoading,
      labels,
      title,
      showTPCard,
      handleModalOpen,
    } = this.props;

    const { alt, src } = images[0];
    const remappedSections = this.addModalOpen();

    return (
      <div id="travelBookingPage" className="travel-booking">
        <PageHeader hasLine isLoading={isLoading} title={title} />
        {!isLoading && (
          <>
            <div className="booking-details-section">
              <div className="cruise-name h3-md h5">{cruiseName}</div>
              <div className="boxes float-md-right">
                <div className="box">
                  <div className="days-to-go text-center">
                    <div className="align-self-center w-100">
                      <div className="days">{daysToGo}</div>
                      <div className="label">{daysToGoLabel}</div>
                    </div>
                  </div>
                </div>
                <div className="box right">
                  <Button className="map" onButtonClick={() => handleModalOpen('bookingMapModal')}>
                    <Image src={src} alt={alt} className="img-fluid map-thumbnail" ratio={THREE_BY_TWO} />
                    <div className="btn-details">
                      <Button appearance="icon">
                        <span>{labels.details}</span>
                        <Icon name="next-bold" />
                      </Button>
                    </div>
                  </Button>
                </div>
              </div>
              <div className="row no-gutters booking-details">
                <div className="container">
                  {details.map((detail) => (
                    <BookingDetail key={detail.heading} adaText={labels.adaAccessibleStateroom} {...detail} />
                  ))}
                </div>
              </div>
            </div>
            <CardSections sections={remappedSections} />
          </>
        )}
        <PictureModal id="bookingMapModal" {...pictureModalData} isImageFullSize />
        <SilverSpiritsModal />
        {showTPCard && (
          <ReservationModal
            {...travelProtectionModalData}
            id="travelProtection"
            sideContent={<TravelProtectionModalSideContent {...travelProtectionModalData} />}
          />
        )}
      </div>
    );
  }
}

TravelBooking.propTypes = {
  content: PropTypes.shape({
    cruiseName: PropTypes.string,
    daysToGo: PropTypes.number,
    daysToGoLabel: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        ...PropTypes.image,
      })
    ),
    pictureModalData: PropTypes.shape(PropTypes.modalData),
    travelProtectionModalData: PropTypes.shape(PropTypes.modalData),
  }),
  labels: PropTypes.shape({
    details: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  travelBookingCardData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      cards: PropTypes.cards,
    })
  ),
  title: PropTypes.string,
  handleModalOpen: PropTypes.func.isRequired,
  showTPCard: PropTypes.bool,
  handleContractOpen: PropTypes.func.isRequired,
  handleCloseModalAndNav: PropTypes.func.isRequired,
};

TravelBooking.defaultProps = {
  content: {},
  labels: {},
  travelBookingCardData: [],
  title: '',
  showTPCard: false,
};

export default TravelBooking;
