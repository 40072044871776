import React from 'react';
import { PageHeader } from '../../components';
import PropTypes from '../../PropTypes';
import './SubmarineVideo.scss';

const SubmarineVideo = ({ isLoading, title, pageTitle }) => {
  return (
    <div id="submarineVideoPage" className="submarine-video-page">
      <PageHeader hasLine hasPrintLink={false} isLoading={isLoading} title={pageTitle} />
      {!isLoading && (
        <>
          <div className="row no-gutters">
            <div className="col-sm-12" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </>
      )}
    </div>
  );
};

SubmarineVideo.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    buttons: PropTypes.objectOf(PropTypes.string),
  }),
  pageTitle: PropTypes.string,
  title: PropTypes.string,
};

SubmarineVideo.defaultProps = {
  labels: {},
  pageTitle: '',
  title: '',
};

export default SubmarineVideo;
