import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import PropTypes from '../../../../PropTypes';
import { ALERT_TYPES, APP_PATHS } from '../../../../common/Constants';
import { goTo } from '../../../../common/Utils';
import history from '../../../../common/history';
import { PageHeader } from '../../../../components';
import './PreferencePageWrapper.scss';

const preferencePageWrapper = (WrappedComponent) => {
  const PreferencePageWrapper = (props) => {
    const {
      handleSubmit,
      labels: { cancel, submit, success, backButton },
      submitting,
      subtitle,
      title,
      dirty,
      submitFailed,
      submitSucceeded,
      error,
    } = props;
    const alert = {
      type: ALERT_TYPES.INFO,
    };
    if (submitSucceeded && !dirty) {
      alert.message = success;
    } else if (submitFailed && error) {
      alert.type = ALERT_TYPES.ERROR;
      alert.message = error;
    }
    return (
      <div className="preference-page-wrapper">
        {!title && (
          <div className="loading-container text-center">
            <span className="loading-icon spinner-container">
              <Icon name="spinner" />
            </span>
          </div>
        )}
        {title && (
          <>
            <PageHeader
              hasHomeLink
              backButton={{
                label: backButton,
                previousPagePath: APP_PATHS.ONBOARD_EXPERIENCE_PREFERENCES,
              }}
              subtitle={subtitle}
              title={title}
            />
            <WrappedComponent {...props} />
            <FormActions
              primaryButton={{
                loading: submitting,
                onButtonClick: handleSubmit,
                text: submit,
                disabled: !dirty,
              }}
              secondaryButton={{
                onButtonClick: goTo(APP_PATHS.ONBOARD_EXPERIENCE_PREFERENCES),
                text: cancel,
              }}
              scrollOnAlert
              alert={!submitting && alert}
              history={history}
            />
          </>
        )}
      </div>
    );
  };

  PreferencePageWrapper.propTypes = {
    error: PropTypes.string,
    submitFailed: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    labels: PropTypes.shape({
      cancel: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      backButton: PropTypes.string,
    }).isRequired,
    submitting: PropTypes.bool.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  };

  PreferencePageWrapper.defaultProps = {
    error: '',
    subtitle: null,
    title: null,
  };

  PreferencePageWrapper.displayName = `PreferencePageWrapper(${getDisplayName(WrappedComponent)})`;

  return PreferencePageWrapper;
};

export default preferencePageWrapper;
