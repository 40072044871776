/* eslint-disable import/prefer-default-export */
// Util files don't have a default export

export const buildPreferencesObject = (passengers) => {
  const preferences = [[], []];

  if (Array.isArray(passengers)) {
    passengers.reduce((acc, passenger, idx) => {
      if (passenger) {
        Object.entries(passenger).reduce((pAcc, [reference, isSelected]) => {
          if (isSelected) {
            pAcc[idx].push({
              serviceCode: reference,
            });
          }

          return pAcc;
        }, acc);
      }

      return acc;
    }, preferences);
  }

  return preferences;
};
