import Heading from '@viking-eng/heading';
import React, { Fragment } from 'react';
import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './ExtensionModalMainContent.scss';

const ExtensionModalMainContent = ({ sections, disclaimerSection }) => (
  <div className="extension-modal-main-content">
    {sections?.map(({ content, heading }) => (
      <div className="description" key={heading}>
        <Heading title={heading} />
        {content?.map((contentSection) => (
          <Fragment key={contentSection.heading}>
            {contentSection.heading && (
              <div className="content-section-heading" dangerouslySetInnerHTML={prepareHtml(contentSection.heading)} />
            )}
            <div className="description-body-text" dangerouslySetInnerHTML={prepareHtml(contentSection.description)} />
          </Fragment>
        ))}
      </div>
    ))}
    {disclaimerSection?.map(({ content, heading }) => (
      <div className="disclaimer-description" key={heading}>
        {content?.map((contentSection) => (
          <div className="disclaimer-body-text" dangerouslySetInnerHTML={prepareHtml(contentSection.description)} />
        ))}
      </div>
    ))}
  </div>
);

ExtensionModalMainContent.defaultProps = {
  sections: [],
};

ExtensionModalMainContent.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          description: PropTypes.string,
        })
      ),
    })
  ),
  disclaimerSection: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          description: PropTypes.string,
        })
      ),
    })
  ),
};

export default ExtensionModalMainContent;
