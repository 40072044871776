import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';
import commonStore, { getCardModalByPageName, handleDeepLinkModal } from '../../../common/CommonStore';
import { FORMS, MODAL_URL_VARIABLE_NAMES, TAB_NAMES } from '../../../common/Constants';
import userStore from '../../../common/UserStore';
import { withContent } from '../../../components';
import onboardStore, { fetchTabContent } from '../OnboardStore';
import Spa from './Spa';

const { getFetchingCommonContent } = commonStore.selectors;
const { getSpaTabContent, getSpaModalCards, getSpaModalContent, getLoadingFlag } = onboardStore.selectors;
const { getLinkQueryParams } = userStore.selectors;

const mapStateToProps = (state) => {
  const content = getSpaTabContent(state);
  const modalPageName = get(getLinkQueryParams(state), MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE, '');
  return {
    content,
    modalData: getSpaModalCards(state),
    reservationModalData: {
      ...getSpaModalContent(state),
    },
    isSubmitting: isSubmitting(FORMS.BOOKING)(state),
    loading: getLoadingFlag(state),
    modalToOpen: getCardModalByPageName(content?.sections, modalPageName),
    isFetchingCommonContent: getFetchingCommonContent(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(TAB_NAMES.SPA)),
  handleCardDetailsClicked: (type, id) =>
    dispatch(handleDeepLinkModal(type, id, MODAL_URL_VARIABLE_NAMES.ONBOARD_CODE)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Spa);
