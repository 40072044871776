import TabPage from '@viking-eng/tab-page';
import React from 'react';
import { TAB_NAMES } from '../../../common/Constants';
import PropTypes from '../../../PropTypes';

const Preferences = ({ content: { sections, pageDescription } }) => {
  const mappedSections = sections.reduce((acc, detail) => {
    const { cards, title } = detail;

    const cardFilter = cards.filter((card) => card.title !== TAB_NAMES.SPECIAL_OCCASIONS_TITLE) || {};
    cards.forEach((card) => {
      card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle
    });
    acc.push({
      ...detail,
      cards: cardFilter,
      title: title.trim(),
    });
    return acc;
  }, []);

  return (
    <div className="preferences">
      <TabPage sections={mappedSections} headerText={pageDescription} />
    </div>
  );
};

Preferences.propTypes = {
  content: PropTypes.shape({
    sections: PropTypes.cardSections,
    pageDescription: PropTypes.string,
  }),
};

Preferences.defaultProps = {
  content: {
    sections: [],
    pageDescription: null,
  },
};

export default Preferences;
