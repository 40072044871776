import Heading from '@viking-eng/heading';
import ImageGrid from '@viking-eng/image-grid';
import Table from '@viking-eng/table';
import get from 'lodash/get';
import React, { Fragment } from 'react';
import { Modal } from '..';
import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import './Comparison.scss';

const renderSection = ({ id, longText, items }, sectionTitle, variables) => {
  // eslint-disable-next-line no-param-reassign
  items = items && items.filter((i) => i.title !== 'undefined');
  if (!items) {
    // regular ol' text
    return <div className="section" key={id} dangerouslySetInnerHTML={prepareHtml(longText)} />;
  }
  if (items.every((i) => i.images)) {
    items.forEach((i) => {
      i.callToActionURL = i.callToActionUrl; // CMSv2 - TODO: update UI Kit after discussion with other products
    });
    // images
    return <ImageGrid items={items} key={id} />;
  }

  let columns = [];
  const defaultItem = variables && variables.find((item) => item.reference === 'airVikingAirPlusBodyCopy');
  // if there is longText in items[0], we need to assume that our `items.items` is actually `items`
  if (get(defaultItem, 'text')) {
    columns = [
      {
        title: sectionTitle,
        items: variables.map((i) => i.text),
      },
    ];
  } else {
    // if it's not regular text or image grid, for we're assuming it's a table.
    columns = items.reduce((acc, item) => {
      if (item.title && item.items) {
        acc.push({
          title: item.title,
          items: item.items ? item.items.map((i) => i.longText) : [],
        });
      }
      return acc;
    }, []);
  }

  return (
    <Fragment key={id}>
      <Table className="d-none d-md-table" columns={columns} />
      {columns.map((col) => (
        <Table className="d-table d-md-none" key={col.title} columns={[col]} />
      ))}
    </Fragment>
  );
};

renderSection.propTypes = {
  id: PropTypes.string.isRequired,
  longText: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({ longText: PropTypes.string })),
      callToActionUrl: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({})), // TODO: Figure out the actual shape
    })
  ),
};

renderSection.defaultProps = {
  items: null,
  longText: '',
};

const ComparisonModal = ({ id, title, subtitle, sections, printLabel, variables }) => (
  <Modal id={id} printLabel={printLabel} name={id} title={title} subtitle={subtitle}>
    <div className="comparison-modal mvj-modal">
      <div className="heading-outer row no-gutters">
        <div className="col center-header">
          <Heading title={title} subTitle={subtitle} />
        </div>
      </div>
      {sections.map((section) => renderSection(section, title, variables))}
    </div>
  </Modal>
);

ComparisonModal.propTypes = {
  printLabel: PropTypes.string,
  ...PropTypes.modalData,
};

ComparisonModal.defaultProps = {
  printLabel: '',
};

export default ComparisonModal;
