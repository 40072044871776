import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import App from './App';
import commonStore, { userStore } from './common';
import { hashSession } from './common/Analytics';
import { fetchCommonContent, updatePreviousPage } from './common/CommonStore';
import { APP_PATHS, FEATURE_RESTRICTED, LOCK_TYPES, MODALS } from './common/Constants';
import modalStore, { setViewAndShowModal } from './common/ModalStore';
import {
  closeToSailingAlertSeen,
  fetchBookings,
  fetchCart,
  fetchUserId,
  handleAutoAcceptPtc,
  handleAzureAuth,
  handleDeepLink,
  refreshIdTokenExpiration,
  refreshLockStatus,
  validateAndUpdateLoggedInUser,
  verifyLockUnlockStatus,
} from './common/UserStore';
import { convertStringToStartCase, navigateTo } from './common/Utils';
import documentStore from './pages/documents/DocumentsStore';
import loginFaqStore from './pages/loginFaqs/LoginFaqsStore';
import airStore from './pages/newAir/AirStore';
import { fetchNotifications } from './pages/notifications/NotificationsStore';
import paymentStore, { removeJourneyAddons } from './pages/payments/PaymentsStore';

const {
  selectors: {
    getActiveRequestCount,
    getHeroImages,
    getLoadedStatus,
    isErrorPage,
    isHeaderContentAvailable,
    isFooterContentAvailable,
    getPassengerTicketContract,
  },
} = commonStore;
const {
  creators: {
    receiveAccessToken,
    receiveAuthData,
    receiveQueryParams,
    setMigrationDeepLink,
    setAuthType,
    updateUserEmail,
    setImpersonationData,
    setAnalyticsSessionId,
    setOopsPageError,
  },
  selectors: {
    getBookingDetails,
    getBookingNumber,
    getActiveUserBookings,
    getAuthData,
    getImpersonationData,
    getSplashPageLink,
    getCountryCodeFromCurrency,
    getChangeBookingStatus,
    getFatalAuthError,
    getLockedStatus,
    isExpedition,
    isAuthLoading,
    getLockoutStatus,
    getFeatureRestricted,
    getCloseToSailingAlerted,
    getEmail,
    getBookingLoadingFlag,
    getUserType,
    getUserData,
  },
} = userStore;
const { getModalView } = modalStore.selectors;
const {
  selectors: { getAirData },
} = airStore;
const {
  creators: { updateNextLocation: gifUpdateNextLocation },
} = documentStore;
const {
  creators: { updateNextLocation: paymentUpdateNextLocation },
  selectors: { getCartItemsWhenUpdated },
} = paymentStore;

const { isLoadingPage: isLoadingLoginFaq } = loginFaqStore.selectors;

const mapStateToProps = (state) => {
  const featureRestricted = getFeatureRestricted(state);
  const closeToSailingAlerted = getCloseToSailingAlerted(state);
  const modalView = getModalView(state);
  const lockout = getLockoutStatus(state);
  // prettier-ignore
  const alertUserCloseToDeparture = featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING
    && !closeToSailingAlerted
    && !modalView
    && !lockout;

  const bookingData = getBookingDetails(state);

  const cartItems = getCartItemsWhenUpdated(state);
  const userType = getUserType(state);

  const segments = window.location.pathname.split('/').slice(2);
  // TODO: Use CMS to get this data
  const pageName = segments?.map((seg) => convertStringToStartCase(seg.replace(/-/g, ' '))).join(' - ') || '';

  return {
    activeRequestCount: getActiveRequestCount(state),
    loadedStatus: getLoadedStatus(state),
    isPreAuthLoading: get(state, 'common.content.loading', true),
    heroImages: getHeroImages(state),
    heroTitle: get(bookingData, 'cruise.name', ''),
    userBookingData: getActiveUserBookings(state),
    bookingData,
    authData: getAuthData(state),
    splashPageLink: getSplashPageLink(state),
    countryCode: getCountryCodeFromCurrency(state),
    changeBooking: getChangeBookingStatus(state),
    lockStatus: getLockedStatus(state),
    isErrorPage: isErrorPage(state),
    isHeaderContentAvailable: isHeaderContentAvailable(state),
    isFooterContentAvailable: isFooterContentAvailable(state),
    isExpedition: isExpedition(state),
    authLoading: isAuthLoading(state),
    lockout,
    alertUserCloseToDeparture,
    isViewOnly: featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY,
    airData: getAirData(state),
    email: getEmail(state),
    bookingLoading: getBookingLoadingFlag(state),
    cruiseInfoError: bookingData?.cruise?.error,
    cartItems,
    pageName,
    userType,
    userData: getUserData(state),
    unAuthorizedLoading: isLoadingLoginFaq(state),
    fatalAuthError: getFatalAuthError(state),
    impersonationData: getImpersonationData(state),
    ptc: getPassengerTicketContract(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: (message) =>
    dispatch(
      verifyLockUnlockStatus({
        type: LOCK_TYPES.UNLOCK,
        message,
        signOutFlag: true,
        isOriginBookingId: false,
      })
    ),
  fetchNotifications: () => dispatch(fetchNotifications()),
  fetchBookings: (bookingId) => dispatch(fetchBookings(bookingId)),
  verifyLockUnlockStatus: (type) => dispatch(verifyLockUnlockStatus(type)),
  refreshLockStatus: () => dispatch(refreshLockStatus()),
  fetchCommonContent: () => dispatch(fetchCommonContent()),
  fetchCart: (closeToDepartureDate) => {
    dispatch(fetchCart()).then((res) => {
      if (closeToDepartureDate && res) {
        const { items = [] } = res;
        if (items?.length > 0) {
          const addonIds = items.map((item) => item.itemID);
          dispatch(removeJourneyAddons(addonIds));
          return {
            ...res,
            items: [],
          };
        }
      }
      return res;
    });
  },
  autoAcceptPtc: (country, passengerNumber, ptcRef) => {
    return dispatch(handleAutoAcceptPtc({ country, passengerNumber, ptcRef }));
  },
  handleQueryParams: (queryParams) => dispatch(receiveQueryParams(queryParams)),
  handleDeepLink: () => dispatch(handleDeepLink()),
  refreshIdTokenExpiration: () => dispatch(refreshIdTokenExpiration()),
  handleModalOpen: (type) => {
    dispatch(setViewAndShowModal(type));
    if (type === MODALS.CLOSE_TO_SAILING) {
      dispatch(closeToSailingAlertSeen());
    }
  },
  clearGIFNextLocation: () => dispatch(gifUpdateNextLocation(null)),
  clearPaymentNextLocation: () => dispatch(paymentUpdateNextLocation(null)),
  handleValidateAndUpdateLoggedInUser: (authData, userData) =>
    dispatch(validateAndUpdateLoggedInUser(authData, userData)),
  updatePreviousPage: (path) => dispatch(updatePreviousPage(path)),
  handleDeepLinkRedirect: (location) => {
    const isDeepLink = location?.pathname?.split('/').length > 2;
    if (isDeepLink) {
      dispatch(setMigrationDeepLink(location.pathname));
    }
  },
  setAuthType: (type) => dispatch(setAuthType(type)),
  handleAzureAuth: (authData) => dispatch(handleAzureAuth(authData)),
  handleReceiveAuthData: (authData) => {
    const { email } = authData?.idTokenClaims;
    if (email) {
      dispatch(updateUserEmail(email));
    }
    return dispatch(receiveAuthData({ ...authData, attributes: { ...authData.attributes, email } }));
  },
  handleReceiveAccessToken: (accessToken) => dispatch(receiveAccessToken(accessToken)),
  fetchUserId: (email, bookingId) => dispatch(fetchUserId(email, bookingId)),
  handleImpersonationData: (id, pax) => dispatch(setImpersonationData({ id, pax })),
  setAnalyticsSessionId: () => {
    dispatch(async (_, getState) => {
      const state = getState();
      const userType = getUserType(state);
      const bookingNumber = getBookingNumber(state);
      const hash = await hashSession({ userType, bookingId: bookingNumber });
      dispatch(setAnalyticsSessionId(hash));
    });
  },
  handleErrorRedirect: (errorPage) => (dispatch) => {
    dispatch(setOopsPageError(errorPage));
    navigateTo(APP_PATHS.OOPS_PAGE);
  },
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(App);
