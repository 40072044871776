import get from 'lodash/get';
import React from 'react';
import { ReservationModal } from '..';
import { MODALS } from '../../common/Constants';
import { mapModalParameters } from '../../common/Utils';
import PropTypes from '../../PropTypes';
import Heading from './heading/ModalHeading';
import MainContent from './mainContent/MainContent';
import SideContent from './sideContent/SideContentContainer';

const Shorex = ({
  data: { mainContent },
  onClose,
  isLocked,
  isSubShorex,
  subSafetyVideoText,
  subSafetyVideoUrl,
  onboardOnly,
}) => {
  if (!mainContent) {
    return null;
  }
  const date = get(mainContent, 'itineraryData.date');

  const modalParams = mapModalParameters(mainContent, 'shorex');
  const { isLandingDay } = mainContent?.itineraryData || { isLandingDay: false };
  const showShorexTime = !isLandingDay;
  const headingComponent = <Heading {...modalParams} showShorexTime={showShorexTime} onboardOnly={onboardOnly} />;
  const mainContentComponent = (
    <MainContent
      {...modalParams}
      isSubShorex={isSubShorex}
      subSafetyVideoText={subSafetyVideoText}
      subSafetyVideoUrl={subSafetyVideoUrl}
    />
  );
  const sideContentComponent = (
    <SideContent
      date={date}
      showShorexTime={showShorexTime}
      isPrivateShorex={mainContent?.isPrivateShorex}
      isSubShorex={isSubShorex}
    />
  );

  return (
    <ReservationModal
      {...mainContent}
      id={MODALS.SHOREX}
      heading={headingComponent}
      mainContent={mainContentComponent}
      sideContent={sideContentComponent}
      onClose={onClose}
      forceAction={isLocked}
    />
  );
};

Shorex.propTypes = {
  data: PropTypes.shorexModalData,
  id: PropTypes.string,
  onClose: PropTypes.func,
  isLocked: PropTypes.bool,
};

Shorex.defaultProps = {
  data: {
    mainContent: {},
    sideContent: {},
  },
  id: '',
  onClose: () => false,
  isLocked: false,
};

export default Shorex;
