import React from 'react';

import { RESERVATION_STATE_KEYS, RESERVATION_TYPES, STATUS_TYPES } from '../../common/Constants';
import PropTypes from '../../PropTypes';

import { AddToCartModalConfirmation, ModalReservationForm } from '..';

const { RESERVED, CANCELED, CANCELING, EDITING } = RESERVATION_STATE_KEYS;

const DiningModalSideContent = ({
  labels,
  guests,
  alert,
  modalAlert,
  lockdownMessage,
  reference,
  reservationModalInfo,
  availability,
  sharedAvailability,
  updateReservationModalState,
  primaryButton,
  secondaryButton,
  state,
  cancelReservation,
  isReservationAllowed,
  diningMessage,
  closeModal,
  onClose,
  diningPage,
  numberAvailable,
  availabilityLoaded,
  fetchModalData,
  availabilityLoading,
  shipId,
  cardVoyageType,
}) => {
  if (!availabilityLoaded && !availabilityLoading) {
    fetchModalData(reference, guests.length, shipId, cardVoyageType);
  }

  const buttons = {
    primary: primaryButton,
    secondary: secondaryButton,
  };
  let content;

  switch (state) {
    case CANCELED:
    case CANCELING: {
      buttons.primary.onButtonClick = () => {
        if (state === CANCELED) {
          updateReservationModalState();
          closeModal();
          onClose();
        } else {
          cancelReservation({ reference, ...reservationModalInfo, labels, shipId });
        }
      };

      if (buttons.secondary.text) {
        buttons.secondary.onButtonClick = () => {
          updateReservationModalState(EDITING, {
            ...reservationModalInfo,
            statusMessage: null,
            errorMessage: null,
          });
        };
      }

      content = (
        <AddToCartModalConfirmation
          {...reservationModalInfo}
          primaryButton={buttons.primary}
          alert={state === CANCELED ? modalAlert : ''}
          secondaryButton={buttons.secondary}
          availabilityLoading={availabilityLoading}
          dining
        />
      );

      break;
    }
    case RESERVED:
    case EDITING: {
      if (state === EDITING) {
        buttons.primary.onButtonClick = () => {
          updateReservationModalState(CANCELING, reservationModalInfo);
        };
      } else {
        buttons.primary.onButtonClick = () => {
          updateReservationModalState();
          closeModal();
        };
      }

      content = (
        <AddToCartModalConfirmation
          {...reservationModalInfo}
          primaryButton={{
            ...buttons.primary,
            disabled: buttons.primary.disabled || (lockdownMessage && state !== RESERVED),
          }}
          secondaryButton={buttons.secondary}
          statusMessage={{
            message: labels.confirmed,
            type: STATUS_TYPES.MESSAGE,
          }}
          alert={alert}
          alertType={alert.type || ''}
          lockdownMessage={lockdownMessage}
          diningPage={diningPage}
          availabilityLoading={availabilityLoading}
        />
      );
      break;
    }
    default:
      content = (
        <ModalReservationForm
          alert={alert}
          lockdownMessage={lockdownMessage}
          guests={guests}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          availability={availability}
          sharedAvailability={sharedAvailability}
          allowGuests
          labels={labels}
          reservationType={RESERVATION_TYPES.DINING}
          reference={reference}
          isReservationAllowed={isReservationAllowed}
          subText={diningMessage}
          numberAvailable={numberAvailable}
          availabilityLoaded={availabilityLoaded}
          availabilityLoading={availabilityLoading}
        />
      );
      break;
  }

  return <div className="dining-modal-side-content">{content}</div>;
};

DiningModalSideContent.propTypes = {
  diningMessage: PropTypes.string,
  isReservationAllowed: PropTypes.bool.isRequired,
  error: PropTypes.string,
  primaryButton: PropTypes.button,
  secondaryButton: PropTypes.button,
  alert: PropTypes.string,
  modalAlert: PropTypes.string,
  updateReservationModalState: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    close: PropTypes.string,
    cancelReservation: PropTypes.string,
    noDoNotCancel: PropTypes.string,
    yesCancel: PropTypes.string,
    confirmed: PropTypes.string,
  }),
  guests: PropTypes.arrayOf(PropTypes.string),
  reference: PropTypes.string.isRequired,
  reservationModalInfo: PropTypes.shape({}),
  availability: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        menuLabel: PropTypes.string,
      })
    ),
    dayPlaceholder: PropTypes.string,
    timePlaceholder: PropTypes.string,
  }).isRequired,
  state: PropTypes.string,
  cancelReservation: PropTypes.func.isRequired,
  lockdownMessage: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  tableForTwoText: PropTypes.string,
  diningPage: PropTypes.bool,
};

DiningModalSideContent.defaultProps = {
  guests: [],
  reservationModalInfo: {},
  diningMessage: '',
  error: '',
  alert: '',
  modalAlert: '',
  primaryButton: {},
  secondaryButton: {},
  labels: {},
  lockdownMessage: null,
  state: '',
  onClose: () => {},
  tableForTwoText: '',
  diningPage: false,
};

export default DiningModalSideContent;
