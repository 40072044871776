import FormActions from '@viking-eng/form-actions';
import Icon from '@viking-eng/icon';
import { prepareHtml } from '@viking-eng/utils';
import React from 'react';
import { Form } from 'redux-form';

import PropTypes from '../../PropTypes';

import { Modal } from '..';

import { REQUEST_INVOICE_STEPS } from '../../common/Constants';
import './RequestInvoiceModal.scss';

const RequestInvoiceModal = ({
  id,
  submitting,
  error,
  formStep,
  handleSubmit,
  onClose,
  isSubmitting,
  handleCloseSuccessModal,
  modalData,
}) => {
  let title;
  const primaryButton = {};
  const secondaryButton = {};
  const { buttons, modalTitle, message, successTitle, successMessage } = modalData;
  switch (formStep) {
    case REQUEST_INVOICE_STEPS.ADD: {
      title = modalTitle;
      primaryButton.text = buttons.confirm;
      secondaryButton.text = buttons.cancel;
      secondaryButton.disabled = isSubmitting;
      secondaryButton.onButtonClick = () => handleCloseSuccessModal();
      break;
    }
    case REQUEST_INVOICE_STEPS.SUCCESS: {
      title = successTitle;
      primaryButton.text = buttons.close;
      primaryButton.loading = false;
      primaryButton.onButtonClick = () => handleCloseSuccessModal();
      break;
    }
    default:
  }
  return (
    <Modal id={id} onClose={onClose} forceAction={isSubmitting}>
      <div className="request-invoice-modal">
        <div className="mvj-modal">
          <div className="row no-gutters d-flex justify-content-center">
            <div className="col-11">
              <Form className="form request-invoice-form" onSubmit={handleSubmit}>
                {formStep === REQUEST_INVOICE_STEPS.ADD && (
                  <div className="row no-gutters request-invoice-fields">
                    <div className="title h5 h3-md">{title}</div>
                    <div
                      className="request-invoice-form-input-wrapper"
                      dangerouslySetInnerHTML={prepareHtml(message)}
                    />
                  </div>
                )}
                {formStep === REQUEST_INVOICE_STEPS.SUCCESS && (
                  <div className="row no-gutters request-invoice-success">
                    <div className="col-md-10">
                      <div className="title h5 h3-md">{title}</div>
                      <span className="icon-wrapper">
                        <Icon name="check-circle" />
                      </span>
                      <div className="confirmation-text">{successMessage}</div>
                    </div>
                  </div>
                )}
                <FormActions
                  primaryButton={{
                    loading: submitting,
                    ...primaryButton,
                  }}
                  secondaryButton={secondaryButton}
                  alert={error}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RequestInvoiceModal.propTypes = {
  id: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  formStep: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleCloseSuccessModal: PropTypes.func.isRequired,
  modalData: PropTypes.shape().isRequired,
};

RequestInvoiceModal.defaultProps = {
  id: '',
  error: '',
  formStep: 'add',
  isSubmitting: false,
};

export default RequestInvoiceModal;
