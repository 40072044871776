import React from 'react';

import PropTypes from '../../PropTypes';
import { MODALS } from '../../common/Constants';

import SideContent from './sideContent/SideContent';

import { ReservationModal } from '..';

const AirPlus = ({ data, onClose, isSubmitting, isSubmittingArr }) => {
  const { sideContent = {} } = data;
  const sideContentComponent = <SideContent {...sideContent} isSubmittingArr={isSubmittingArr} />;
  return (
    <ReservationModal
      {...data}
      sideContent={sideContentComponent}
      id={MODALS.AIR_PLUS}
      name={MODALS.AIR_PLUS}
      onClose={onClose}
      forceAction={isSubmitting}
    />
  );
};

AirPlus.propTypes = {
  isSubmitting: PropTypes.bool,
  data: PropTypes.shape(PropTypes.modalData),
  onClose: PropTypes.func,
  isSubmittingArr: PropTypes.arrayOf(PropTypes.bool),
};

AirPlus.defaultProps = {
  isSubmitting: false,
  data: {},
  onClose: () => false,
  isSubmittingArr: [false, false],
};

export default AirPlus;
