import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting } from 'redux-form';
import commonStore, { userStore } from '../../../common';
import {
  CALENDAR_MODAL_STATES,
  DEFAULT_DATE_FORMAT,
  EXTENSION_TYPES,
  FEATURE_RESTRICTED,
  FORMS,
  MODALS,
  PAGE_NAMES,
  REGIONAL_SHORT_DATES,
  RESERVATION_STATE_KEYS,
  TAB_NAMES,
} from '../../../common/Constants';
import { setViewAndShowModal } from '../../../common/ModalStore';
import { convertStringToStartCase, getCmsLabel, getPagePath, replaceCMSTokenWithValue } from '../../../common/Utils';
import calendarStore, { fetchCalendarItems, fetchItineraryContent } from '../../../pages/calendar/CalendarStore';
import onboardStore, { fetchTabContent as fetchOnboardTabContent } from '../../../pages/onboard/OnboardStore';
import shorexStore, {
  fetchExcursions,
  findExcursion,
  handleCloseExcursionModal,
  handleOpenExcursionModal,
} from '../../../pages/shorex/ShorexStore';
import withContent from '../../content/WithContent';
import withLoadingGuard from '../../withLoadingGuard/WithLoadingGuard';
import CalendarDay from './CalendarDay';

const { DINING_BEVERAGE } = TAB_NAMES;
const { getFeatureRestricted, getVoyageType, getPassengerNames, getItineraryDate, getItinerary } = userStore.selectors;
const {
  getDiningBeverageModalCards,
  getSpaModalContent,
  getSpaModalCards,
  getTabContent: getOnboardTabContent,
} = onboardStore.selectors;
const { getDayViewCalendarEvents, getUpsellItemsData, isLoadingCalendar, getModalToOpen } = calendarStore.selectors;
const { updateCalendarModalStatus, updateModalToOpen } = calendarStore.creators;

const { getPageTabLabels, getReservationModalInfo, getIsUKAUNZ, getCartRedirectModifyModal } = commonStore.selectors;
const { updateReservationModalState, setCartRedirectModifyModal } = commonStore.creators;
const { getShorexModalData, getExcursionsData } = shorexStore.selectors;

const mapStateToProps = (state, { location: { pathname } }) => {
  const cartRedirectModifyModal = getCartRedirectModifyModal(state);
  const {
    labels: { onboardEvent, shoreEvent },
  } = getPageTabLabels(state)(PAGE_NAMES.CALENDAR);
  const journeyDays = getItinerary(state);
  const voyageType = getVoyageType(state);
  const start = get(journeyDays, '0.date');
  const date = pathname.split('/').filter((p) => p).length > 2 ? getPagePath(pathname) : start;
  const modalToOpen = getModalToOpen(state);
  const diningBeverageContent = getOnboardTabContent(state)(DINING_BEVERAGE);
  const spaContent = getOnboardTabContent(state)(TAB_NAMES.SPA);
  const calendarItems = getDayViewCalendarEvents(state)(date);
  const shorexEvents = calendarItems.events.filter((evt) => evt.data.extensionType === EXTENSION_TYPES.SHOREX);
  const multiDayEventStartDates = uniqBy(shorexEvents, 'startDate').map((e) => e.data.startDate);

  const reservationSubmitting = get(getReservationModalInfo(state), 'metadata.submitting', false);
  return {
    cartRedirectModifyModal,
    calendarItems,
    shorexDatesToFetch: Array.from(new Set([date].concat(multiDayEventStartDates))),
    excursionData: getExcursionsData(state),
    diningLoaded: {
      loading: diningBeverageContent.loading,
      loaded: diningBeverageContent.loaded,
    },
    spaLoaded: {
      loading: spaContent.loading,
      loaded: spaContent.loaded,
    },
    modalToOpen,
    isLoading: isLoadingCalendar(state),
    passengers: getPassengerNames(state),
    itineraryDate: getItineraryDate(state)(date),
    shoreEventLabel: shoreEvent,
    onboardEventLabel: onboardEvent,
    upsellItems: getUpsellItemsData(state)(date),
    isUpsellBarDisabled: getFeatureRestricted(state) === FEATURE_RESTRICTED.CLOSE_TO_SAILING,
    modalData: {
      dining: getDiningBeverageModalCards(state),
      shorex: getShorexModalData(state, date),
      spa: {
        // TODO: why are these different selectors?
        modalData: getSpaModalCards(state),
        ...getSpaModalContent(state),
      },
    },
    // Submitting state for dining or spa modals
    isSubmitting: reservationSubmitting || isSubmitting(FORMS.RESERVATION)(state) || isSubmitting(FORMS.BOOKING)(state),
    voyageType,
  };
};

const mapReservationPassengers = (allPassengers, guests) => {
  if (guests && guests.length > 0) {
    guests.reduce((acc, group) => {
      group?.passengers?.forEach((pax) => {
        acc.push(pax);
      });
      return acc;
    }, allPassengers);
  }
};

const findCardInSections = (sections, finder) => {
  const section = sections.find((s) => s.cards.find(finder));
  if (section) {
    return section.cards.find(finder) || {};
  }
  return {};
};

const { ERROR, ERROR_RESERVATION, LOADING } = CALENDAR_MODAL_STATES;
const { DINE, SHOREX, SPA } = EXTENSION_TYPES;

const handleEditClicked = (item, passengers, itineraryDate, passengerIndex) => (dispatch, getState) => {
  const { EDITING } = RESERVATION_STATE_KEYS;
  const state = getState();
  const featureRestricted = getFeatureRestricted(state);
  const isUKAUNZ = getIsUKAUNZ(state);

  let request;
  switch (item.data.extensionType) {
    case DINE: {
      const data = getOnboardTabContent(state)(DINING_BEVERAGE);
      const startTime = moment(item.data.startTime);
      const { shipCode } = itineraryDate;
      const shipSections = shipCode ? data.sections.filter((s) => s.shipId === shipCode) : data.sections;
      const card = findCardInSections(shipSections, (c) => `${shipCode}${c.reference}` === item.data.serviceCode);
      const { currentReservations } = card.modal;
      const currentReservation = currentReservations.find(
        (x) => x.date === startTime.format(DEFAULT_DATE_FORMAT.YEAR_FIRST) && x.restaurantCode === item.data.serviceCode
      );
      if (!currentReservation) {
        dispatch(updateCalendarModalStatus(item.data.serviceCode, passengerIndex, ERROR_RESERVATION));
      } else {
        const { inviteeDetail, inviterDetail } = currentReservation;
        let allPassengers = [];
        mapReservationPassengers(allPassengers, [...inviteeDetail, ...inviterDetail]);

        allPassengers = allPassengers.map(({ firstName, lastName }) =>
          convertStringToStartCase(`${firstName} ${lastName}`)
        );
        const guests = passengers.concat(allPassengers);

        dispatch(
          updateReservationModalState(EDITING, {
            passengers: guests,
            details: [
              itineraryDate.value,
              startTime.format(isUKAUNZ ? `dddd, ${REGIONAL_SHORT_DATES.EU}` : `dddd, ${REGIONAL_SHORT_DATES.NA}`),
              startTime.format('LT'),
            ],
            item: {
              ...item.data,
              specDine: currentReservation?.specDine || false,
            },
          })
        );

        dispatch(setViewAndShowModal(MODALS.RESERVATION, { id: card.id, shipId: itineraryDate.shipCode }));
      }
      break;
    }
    case SPA: {
      const data = getOnboardTabContent(state)(SPA);
      const startTime = moment(item.data.startTime);

      // eslint-disable-next-line max-len
      const findService = (services, serviceCode) => services.find((duration) => duration.serviceCode === serviceCode);
      const spaCardFinder = (c) => {
        const serviceDurations = get(c, 'modal.serviceDurations');
        if (serviceDurations) {
          return findService(serviceDurations, item.data.serviceCode);
        }
        return false;
      };
      const { messages } = data.sections.find((s) => s.cards.find(spaCardFinder));
      const card = findCardInSections(data.sections, spaCardFinder);
      // eslint-disable-next-line max-len
      const { cmsDuration } = findService(card.modal.serviceDurations, item.data.serviceCode) || {};
      const durationLabel = getCmsLabel(messages, 'durationLabel', 'text');
      const messageSpaWithin7DaysSailing = getCmsLabel(messages, 'messageSpaWithin7DaysSailing', 'text');
      dispatch(
        updateReservationModalState(EDITING, {
          passengers: passengers.slice(passengerIndex, passengerIndex + 1),
          details: [
            itineraryDate.value,
            startTime.format(isUKAUNZ ? `ddd, ${REGIONAL_SHORT_DATES.EU}` : `ddd, ${REGIONAL_SHORT_DATES.NA}`),
            startTime.format('LT'),
            replaceCMSTokenWithValue(durationLabel, [{ key: 'DURATION', value: cmsDuration }]),
          ],
          item: item.data,
          lockdownMessage:
            featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING ? messageSpaWithin7DaysSailing : '',
        })
      );
      dispatch(setViewAndShowModal(MODALS.RESERVATION, { id: card.id }));
      break;
    }
    case SHOREX: {
      const { inventoryCode, startDate } = item.data;
      const excursions = get(getExcursionsData(state), `${startDate}.excursions`, []);
      if (excursions.length) {
        const { id } = findExcursion(excursions, inventoryCode, startDate) || {};

        if (id) {
          dispatch(handleOpenExcursionModal(id, startDate));
        }
      }
      break;
    }
    default:
      break;
  }
  if (request) {
    request
      .then(() => {
        dispatch(updateCalendarModalStatus());
      })
      .catch(() => {
        dispatch(updateCalendarModalStatus(item.data.serviceCode, passengerIndex, ERROR));
      });
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchItineraryContent('itinerary')),
  fetchDiningContent: () => dispatch(fetchOnboardTabContent(DINING_BEVERAGE, true, true, true)),
  fetchSpaData: () => dispatch(fetchOnboardTabContent(TAB_NAMES.SPA, true)),
  fetchExcursions: (date) =>
    dispatch(
      fetchExcursions({
        date,
        // TODO: Research better approach to this flag.
        // Then this flag is true we get incorrect modal cases for Cart and ShoreX pages
        isModalFromCalendar: false,
      })
    ),
  onEdit: (item, { passengers, itineraryDate }, passengerIndex) => {
    dispatch(updateCalendarModalStatus(item?.data?.serviceCode, passengerIndex, LOADING));
    dispatch(updateModalToOpen(item, passengers, itineraryDate, passengerIndex));
  },
  handleModalOpen: ({ item, passengers, itineraryDate, passengerIndex }) => {
    dispatch(updateCalendarModalStatus());
    dispatch(handleEditClicked(item, passengers, itineraryDate, passengerIndex));
    dispatch(updateModalToOpen());
  },
  onModalClose: (extensionType) => {
    if (extensionType === SHOREX) {
      dispatch(handleCloseExcursionModal());
    }
    dispatch(updateReservationModalState());
    dispatch(fetchCalendarItems(true));
  },
  setCartRedirectModifyModal: (value) => dispatch(setCartRedirectModifyModal(value)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent, withLoadingGuard);

export default enhance(CalendarDay);
