import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, SubmissionError, clearSubmitErrors } from 'redux-form';

import commonStore from '../../../common';
import { FORMS, TAB_NAMES, MODALS } from '../../../common/Constants';
import { addAirPlusToCart, fetchTabContent as fetchAirTabContent } from '../../../pages/air/AirStore';

import AirPlusForm from './AirPlusForm';

const { AIR_BOOKING } = TAB_NAMES;

const {
  selectors: { getErrors, getModalLabels },
} = commonStore;

const mapStateToProps = (state, { passenger: { key } }) => ({
  errors: getErrors(state),
  form: `${FORMS.BOOKING}-${key}`,
  modalLabels: getModalLabels(state)(MODALS.RESERVATION_MODAL),
});

const mapDispatchToProps = (dispatch, { passenger: { key } }) => ({
  clearSubmitErrors: () => dispatch(clearSubmitErrors(`${FORMS.BOOKING}-${key}`)),
});

const onSubmit = (
  values,
  dispatch,
  {
    passenger: { key },
    modalData: { pricePerPassenger, title, extensionType, serviceCode, inventoryCode },
    error,
    form,
    errors,
  }
) =>
  new Promise((resolve, reject) => {
    if (error) {
      dispatch(clearSubmitErrors(form));
    }

    const payload = {
      forPassenger1: key === 1 || false,
      forPassenger2: key === 2 || false,
      title,
      pricePerPassenger,
      extensionType,
      serviceCode,
      inventoryCode,
    };

    return dispatch(addAirPlusToCart(payload)).then(async ({ isSuccessful }) => {
      if (isSuccessful) {
        await dispatch(fetchAirTabContent(AIR_BOOKING, true));
        return resolve(true);
      }

      return reject(new SubmissionError({ _error: errors.AddToCartFailed }));
    });
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    onSubmit,
    enableReinitialize: true,
  })
);

export default enhance(AirPlusForm);
