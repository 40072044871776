import { connect } from 'react-redux';
import commonStore from '../../common';
import { MODALS } from '../../common/Constants';
import ViewOnlyModal from './ViewOnly';

const { getViewOnlyModalContent } = commonStore.selectors;

const mapStateToProps = (state) => {
  return {
    ...getViewOnlyModalContent(state),
    id: MODALS.VIEW_ONLY,
  };
};

export default connect(mapStateToProps)(ViewOnlyModal);
