import React from 'react';
import { mapCheckboxItems } from '../../../../common/Utils';
import { CheckboxList } from '../../../../components';
import PropTypes from '../../../../PropTypes';
import './FoodAllergies.scss';

const FoodAllergies = ({ handleSubmit, passengerNames, preferences, lockedDown }) => (
  <div className="food-allergies">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10">
        <form onSubmit={handleSubmit}>
          <fieldset disabled={lockedDown}>
            <div className="row">
              {passengerNames.map((passenger, index) => {
                const mappedPreferences = preferences.map((pref) =>
                  mapCheckboxItems({
                    items: pref.items.map((item) => ({
                      ...item,
                      reference: `passengers[${index}].${item.reference}`,
                    })),
                    labelField: 'subtitle',
                    idField: 'reference',
                    groupName: pref.groupName,
                  })
                );
                return (
                  <div className="col-xs-12 col-sm-6" key={passenger}>
                    <h5 className="passenger-name">{passenger}</h5>
                    <CheckboxList groups={mappedPreferences} />
                  </div>
                );
              })}
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
);

FoodAllergies.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  passengerNames: PropTypes.arrayOf(PropTypes.string),
  preferences: PropTypes.preferences,
  lockedDown: PropTypes.bool,
};

FoodAllergies.defaultProps = {
  passengerNames: [],
  preferences: [],
  lockedDown: false,
};

export default FoodAllergies;
