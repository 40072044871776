import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import get from 'lodash/get';

import { EXTENSION_TYPES, MODALS, RESERVATION_STATE_KEYS, SHOREX_ACTION_TYPES } from '../../../common/Constants';
import { hideModal } from '../../../common/Utils';
import PropTypes from '../../../PropTypes';

const { CANCEL, CANCEL_NO, CANCEL_YES, REMOVE, CLOSE, OPEN, EDIT, UPDATE } = SHOREX_ACTION_TYPES;

const withConnector = (WrappedComponent) => {
  class WithConnector extends React.Component {
    addActionToButton = (button, { inventoryCode, time }, passengerIndex) => {
      const {
        clearSubmitErrors,
        error,
        extensionType,
        fromDate,
        handleSubmit,
        modalReservationInfo,
        updateReservationModalState,
        passengerStatus,
        cartItemId,
        removeCartItem,
      } = this.props;
      if (!button) {
        return null;
      }
      const metadata = get(modalReservationInfo, [passengerIndex, 'metadata']);
      const mappedButton = { ...button };

      let additionalAction = () => true;
      switch (button.actionType) {
        case CANCEL:
          additionalAction = () => {
            updateReservationModalState(RESERVATION_STATE_KEYS.CANCELING, {
              ...metadata,
              extensionType,
              fromDate,
              inventoryCode,
              passengerIndex,
              time,
            });
          };
          break;
        case EDIT:
        case CANCEL_NO:
          additionalAction = () => {
            updateReservationModalState(RESERVATION_STATE_KEYS[passengerStatus], metadata);
          };
          break;
        case CANCEL_YES:
        case UPDATE:
        case OPEN:
          additionalAction = handleSubmit;
          mappedButton.isSubmit = true;
          break;
        case REMOVE:
          additionalAction = () => {
            removeCartItem(cartItemId);
          };
          additionalAction = handleSubmit;
          mappedButton.isSubmit = true;
          break;
        case CLOSE:
          additionalAction = () => hideModal(MODALS.SHOREX);
          break;
        default:
          break;
      }

      mappedButton.onButtonClick = () => {
        if (error) {
          clearSubmitErrors();
        }
        additionalAction();
      };

      return mappedButton;
    };

    render() {
      const { error, passenger, primary, secondary, submitting, ...props } = this.props;

      const passengerIndex = passenger.passengerNumber - 1;

      const mappedPrimaryButton = this.addActionToButton(primary, passenger, passengerIndex);
      const mappedSecondaryButton = this.addActionToButton(secondary, passenger, passengerIndex);

      let renderError;
      if (mappedPrimaryButton && mappedPrimaryButton.isSubmit) {
        renderError = error;
      }

      const newProps = {
        passenger,
        primary: mappedPrimaryButton,
        renderError,
        secondary: mappedSecondaryButton,
        submitting,
      };

      return <WrappedComponent {...props} {...newProps} />;
    }
  }

  WithConnector.propTypes = {
    clearSubmitErrors: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    extensionType: PropTypes.oneOf(Object.values(EXTENSION_TYPES)),
    fromDate: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    modalReservationInfo: PropTypes.shape({
      metadata: PropTypes.shape({
        passengerIndex: PropTypes.number,
      }),
    }),
    passenger: PropTypes.shape(),
    primary: PropTypes.shape(),
    secondary: PropTypes.shape(),
    submitting: PropTypes.bool.isRequired,
    updateReservationModalState: PropTypes.func.isRequired,
    removeCartItem: PropTypes.func.isRequired,
    bookingPassengers: PropTypes.shape({
      forPassenger1: PropTypes.bool,
      forPassenger2: PropTypes.bool,
    }),
    passengerStatus: PropTypes.string.isRequired,
    cartItemId: PropTypes.number,
  };

  WithConnector.defaultProps = {
    error: false,
    extensionType: EXTENSION_TYPES.SHOREX,
    fromDate: null,
    modalReservationInfo: {},
    bookingPassengers: {},
    passenger: {},
    primary: {},
    secondary: {},
    cartItemId: null,
  };

  WithConnector.displayName = `WithConnector(${getDisplayName(WrappedComponent)})`;

  return WithConnector;
};

export default withConnector;
