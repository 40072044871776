import Icon from '@viking-eng/icon';
import Tooltip from '@viking-eng/tooltip';
import React from 'react';

import PropTypes from '../../PropTypes';
import './WheelchairTooltip.scss';

const WheelchairTooltip = ({ tooltipText }) =>
  (tooltipText ? (
    <div className="wheelchair-tooltip">
      <Tooltip tooltipText={tooltipText} position="top-right">
        <Icon name="wheelchairAlt" />
      </Tooltip>
    </div>
  ) : null);

WheelchairTooltip.propTypes = {
  tooltipText: PropTypes.jsx,
};

WheelchairTooltip.defaultProps = {
  tooltipText: '',
};

export default WheelchairTooltip;
