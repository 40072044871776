import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ENVIRONMENT_CODE, UTM_COUNTRY_CODE, UTM_PAGE_TYPES, UTM_PAGE_TYPE_DEFAULT, UTM_SITE_CODE } from './Constants';
import { sessionStorageService } from './Utils';
import history from './history';

const initializeTagData = (tagData) => {
  fireAdobeEvent({
    ...tagData,
    ...getCommonAttributes(),
    event: 'page_data_initialized',
    event_name: 'page_data_initialized',
    acdl_event: tagData.acdl_event,
  });
};

const getCommonAttributes = () => {
  return {
    domain_country_code: UTM_COUNTRY_CODE,
    environment_code: ENVIRONMENT_CODE,
    site_code: UTM_SITE_CODE,
    origin_country_code: sessionStorageService('getItem', 'originCountry'),
  };
};

const getPageTypeFromPath = (pathname, fallback) => {
  let match = fallback;
  Object.keys(UTM_PAGE_TYPES).forEach((pageType) => {
    const basePath = `/${pathname.split('/')[1]}`;
    const matchingPaths = UTM_PAGE_TYPES[pageType];
    if (matchingPaths.includes(pathname) || matchingPaths.includes(basePath)) {
      match = pageType;
    }
  });

  return match;
};

// Function `codify` must match marketing site
const codify = (value) => {
  if (typeof value === 'undefined') {
    return '';
  }
  if (value == null) {
    return '';
  }
  return value
    .replace(/[^a-zA-Z0-9À-ž-\d\s]/gi, '')
    .replace(/[^a-zA-Z0-9À-ž-\d]/gi, '_')
    .replace('__', '_')
    .toLowerCase();
};

const sha1 = async (message) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const hashSession = ({ bookingId, userType }) => {
  return sha1(JSON.stringify({ bookingId, userType }));
};

const cleanData = (data) =>
  Object.keys(data)
    .sort()
    .reduce((acc, key) => {
      if (!!data[key] || [0, '0'].includes(data[key])) {
        return { ...acc, [key]: data[key] };
      }
      return acc;
    }, {});

const fireAdobeEvent = (tagData) => {
  const adobeEvent = cleanData({
    ...tagData,
    event: tagData.event || tagData.event_name,
  });
  if (!Array.isArray(window.adobeDataLayer)) {
    window.adobeDataLayer = [];
  }
  window.adobeDataLayer.push(adobeEvent);
};

const fireViewEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const fireLinkEvent = (inputTagData) => {
  const tagData = cleanData(inputTagData);
  fireAdobeEvent(tagData);
};

const triggerPageView = (path = '/', pageTypeFallback = UTM_PAGE_TYPE_DEFAULT, additionalAttributes) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  if (attributes && attributes.event_name) {
    delete attributes.event_name;
  }
  const pageType = getPageTypeFromPath(path, pageTypeFallback);
  const pageId = codify(window.location.pathname.replace('/myjourney', ''));
  const pageName = window.document.title;
  const eventData = {
    acdl_event: 'view',
    page_id: pageId,
    page_name: pageName || '',
    page: path || '',
    page_type: pageType || pageTypeFallback,
    ...attributes,
    event: 'spa_page_view',
    event_name: 'spa_page_view',
  };
  fireViewEvent(eventData);
};

/* eslint-disable camelcase */
const triggerLinkEvent = ({ event_name, ...additionalAttributes }) => {
  const attributes = additionalAttributes && JSON.parse(JSON.stringify(additionalAttributes).replace(/:null/gi, ':""'));
  const eventData = {
    acdl_event: 'link',
    event_name,
    ...getCommonAttributes(),
    ...attributes,
  };
  fireLinkEvent(eventData);
};
/* eslint-enable camelcase */

const reactPlugin = new ReactPlugin(history);

export { codify, getCommonAttributes, hashSession, initializeTagData, reactPlugin, triggerLinkEvent, triggerPageView };
