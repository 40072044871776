import TabPage from '@viking-eng/tab-page';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { RESERVATION_TYPES, SERVICE_CODES } from '../../../common/Constants';
import remapCardDataBasedOnModalType from '../../../common/ModalUtils';
import { getModalType } from '../../../common/Utils';
import { DiningModalSideContent } from '../../../components';
import { CardDetailsModal, ReservationModal, SilverSpiritsModal } from '../../../modals';
import './DiningBeverage.scss';

class DiningBeverage extends React.Component {
  addClickEventsToCard() {
    const {
      content: { sections },
      handleCardDetailsClicked,
      isMississippi,
    } = this.props;
    return sections?.map((section) => ({
      ...section,
      cards: section?.cards?.map((card) => {
        const modalType = getModalType(card.primaryButtonUrl);
        card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle
        const hideMenuButtons = isMississippi && ![SERVICE_CODES.SSBP].includes(card?.reference);
        const onClick = () => {
          if (card.primaryButtonUrl.startsWith('http')) {
            return window.open(card.primaryButtonUrl);
          }
          return handleCardDetailsClicked(modalType, card.id, section?.shipId);
        };
        const hidePrimaryButtonInCards = {
          ...card,
          primaryButton: { text: null, onButtonClick: null },
          primaryButtonUrl: null,
        };
        const remapCard = hideMenuButtons ? hidePrimaryButtonInCards : card;
        return remapCardDataBasedOnModalType(remapCard, {
          onPrimaryButtonClick: hideMenuButtons ? null : onClick,
          onSecondaryButtonClick: hideMenuButtons ? null : onClick,
          defaultClick: hideMenuButtons ? null : onClick,
        });
      }),
    }));
  }

  render() {
    const {
      content: { pageDescription },
      modalData,
      resetModalState,
      isSubmitting,
      modalToOpen,
      handleCardDetailsClicked,
      labels,
    } = this.props;
    const remappedSections = this.addClickEventsToCard();

    const { modalType, primaryButton, title } = modalData;

    if (title) {
      switch (modalType) {
        case '#ReserveModal':
          primaryButton.attributes = {
            type: 'submit',
          };
          break;
        default:
          primaryButton.onButtonClick = () => {
            throw new Error(`unknown modalType: ${modalType}`);
          };
      }
    }

    if (modalToOpen && modalToOpen.modalType && modalToOpen.modalId) {
      handleCardDetailsClicked(modalToOpen.modalType, modalToOpen.modalId);
    }

    return (
      <div className="dining-beverage">
        <TabPage sections={remappedSections} headerText={pageDescription} />
        <CardDetailsModal {...modalData} id="cardDetailsModal" dining />
        <ReservationModal
          {...modalData}
          id="reserveModal"
          allowGuests
          reservationType={RESERVATION_TYPES.DINING}
          onClose={resetModalState}
          sideContent={<DiningModalSideContent {...modalData} diningPage />}
          hideMakeAreservation={modalData.reservationState === 'reserved'}
          forceAction={isSubmitting}
          dining
          reservationHeader={labels.makeAreservation}
        />
        <SilverSpiritsModal />
      </div>
    );
  }
}

DiningBeverage.propTypes = {
  resetModalState: PropTypes.func.isRequired,
  diningModalLabels: PropTypes.shape({
    inviteGuests: PropTypes.string,
    bookingNumber: PropTypes.string,
    lastName: PropTypes.string,
    inviteMoreGuests: PropTypes.string,
  }),
  handleCardDetailsClicked: PropTypes.func.isRequired,
  content: PropTypes.shape({
    sections: PropTypes.cardSections,
    pageDescription: PropTypes.string,
  }),
  modalData: PropTypes.shape(PropTypes.modalData),
  isSubmitting: PropTypes.bool,
  modalToOpen: PropTypes.shape({ modalType: PropTypes.string, id: PropTypes.string }),
};

DiningBeverage.defaultProps = {
  diningModalLabels: {},
  content: {
    sections: [],
    pageDescription: null,
  },
  modalData: {},
  isSubmitting: false,
  modalToOpen: null,
};

export default DiningBeverage;
