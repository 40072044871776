import moment from 'moment';
import React from 'react';
import PropTypes from '../../../PropTypes';
import { PageHeader, PassengerSelector } from '../../../components';
import './FitToTravel.scss';

const FitToTravel = ({
  content,
  backButton,
  handleSelectPassenger,
  countDownFirstEncounterDate,
  countDownCloseThresholdDays,
}) => {
  const { title, subtitle, isLoading, selectPassenger, passengers, surveyUrl } = content;

  const daysLeft = moment.duration(moment(countDownFirstEncounterDate).diff(moment())).asDays();
  const surveyClosed = daysLeft <= countDownCloseThresholdDays;
  return (
    <div className="preference-page-wrapper">
      <>
        <PageHeader hasLine backButton={backButton} title={title} isLoading={isLoading} />
        {!isLoading && (
          <div className="fit-to-travel-survey">
            <div className="fit-to-travel-survey-message">{subtitle}</div>
            <div className="fit-to-travel-survey-title h3 h5-sm">{selectPassenger}</div>
            {passengers?.length && (
              <div className="passenger-container row no-gutters">
                {passengers.map((passenger) => (
                  <PassengerSelector
                    passenger={passenger}
                    {...content}
                    disabled={surveyClosed}
                    handleOnClick={() => handleSelectPassenger(surveyUrl)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

FitToTravel.propTypes = {
  backButton: PropTypes.shape({
    label: PropTypes.string,
    previousPath: PropTypes.string,
  }),
  content: PropTypes.shape({
    checkedInData: PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string,
    }),
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        passengerNumber: PropTypes.number.isRequired,
        checkedIn: PropTypes.bool,
        guestId: PropTypes.number,
      })
    ),
    selectPassenger: PropTypes.string,
    startSurvey: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    surveyUrl: PropTypes.string,
    disabledMessage: PropTypes.string,
  }),
  countDownFirstEncounterDate: PropTypes.string,
  countDownThresholdDays: PropTypes.number,
  handleSelectPassenger: PropTypes.func,
};

FitToTravel.defaultProps = {
  content: {},
  countDownFirstEncounterDate: '',
};

export default FitToTravel;
