import React, { Fragment } from 'react';

import PropTypes from '../../../PropTypes';
import RadioGroup from '../../formInputs/radioGroup/RadioGroup';

import './PaymentMethod.scss';

const PaymentMethod = ({
  bankDraft,
  draftDiscount,
  onChange,
  options,
  achAvailable,
  achNotAvailable,
  isSubmitting,
}) => {
  const radioOptions = options;
  if (achAvailable) {
    const achLabel = (
      <>
        <div className="faux-label">{bankDraft}</div>
        <div className="faux-label save-ach">{draftDiscount}</div>
        {achNotAvailable && <div className="faux-label save-ach-notAvailable">{achNotAvailable}</div>}
      </>
    );
    radioOptions[0].label = achLabel;
  }

  return (
    <RadioGroup
      className="select-payment-method"
      name="paymentMethod"
      onChange={onChange}
      options={radioOptions}
      isSubmitting={isSubmitting}
    />
  );
};

PaymentMethod.propTypes = {
  bankDraft: PropTypes.string,
  draftDiscount: PropTypes.string,
  onChange: PropTypes.func,
  achAvailable: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  achNotAvailable: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

PaymentMethod.defaultProps = {
  bankDraft: '',
  draftDiscount: '',
  onChange: () => {},
  achNotAvailable: '',
  isSubmitting: false,
};

export default PaymentMethod;
