// Warns user for {PAYMENT_CHALLENGE_COUNTDOWN_SECONDS} seconds that they will be redirected for payment validation.
// After countdown, the iframe will load the challenge URL which will have security prompts.
// Upon submitting security prompts, the underlying site will a attempt a redirect,
// but instead of redirecting the modal will be closed.
import Button from '@viking-eng/button';
import Icon from '@viking-eng/icon';
import { useWindowSize } from '@viking-eng/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  CHALLENGE_CLOSE_TRIGGER,
  PAYMENT_CHALLENGE_COUNTDOWN_SECONDS,
  PAYMENT_CHALLENGE_TIMEOUT_SECONDS,
} from '../../common/Constants';
import { getPaymentChallengeIframeSize } from '../../common/Utils';

import { Modal } from '..';

import './PaymentChallenge.scss';

const PaymentChallenge = ({ isModalOpen, labels, handleCancel, handleSuccess, id, handleTimeout, iFrameUrl }) => {
  const [counter, setCounter] = useState(PAYMENT_CHALLENGE_COUNTDOWN_SECONDS);
  const [timeoutCounter, setTimeoutCounter] = useState(null);
  const [iFrameLoadCount, setIFrameLoadCount] = useState(0);
  const [closeTrigger, setCloseTrigger] = useState(null);
  const windowSize = useWindowSize();
  const intervalHandle = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setCounter(PAYMENT_CHALLENGE_COUNTDOWN_SECONDS);
    intervalHandle.current = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(intervalHandle.current);
          return prevCounter;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => {
      clearTimeout(intervalHandle.current);
      setCounter(PAYMENT_CHALLENGE_COUNTDOWN_SECONDS);
    };
  }, [isModalOpen]);

  const { height, width } = getPaymentChallengeIframeSize({ width: windowSize.width });

  const timeoutIntervalHandle = useRef(null);
  const checkIframeInterval = useRef(null);
  const formHtml = useRef(null);
  useEffect(() => {
    if (iFrameLoadCount === 2 && counter === 0) {
      checkIframeInterval.current = setInterval(() => {
        try {
          formHtml.current = formRef?.current?.contentWindow?.document?.body?.innerHTML;
          if (formHtml.current?.includes('Success')) {
            setCloseTrigger(CHALLENGE_CLOSE_TRIGGER.SUCCESS);
            clearInterval(checkIframeInterval.current);
          }
          if (formHtml.current?.includes('Failure')) {
            setCloseTrigger(CHALLENGE_CLOSE_TRIGGER.CANCEL);
            clearInterval(checkIframeInterval.current);
          }
        } catch {
          // do not remove
        }
      }, 1000);

      setTimeoutCounter(PAYMENT_CHALLENGE_TIMEOUT_SECONDS);
      timeoutIntervalHandle.current = setInterval(() => {
        setTimeoutCounter((prevTimeoutCounter) => {
          if (prevTimeoutCounter === 0) {
            handleTimeout(timeoutIntervalHandle.current);
            return null;
          }
          return prevTimeoutCounter - 1;
        });
      }, 1000);
    }
  }, [iFrameLoadCount, handleTimeout, counter, formHtml]);

  const clearIntervalHandles = () => {
    clearTimeout(intervalHandle.current);
    clearTimeout(timeoutIntervalHandle.current);
    clearTimeout(checkIframeInterval.current);
    setTimeoutCounter(null);
  };

  const onLoad = () => {
    setIFrameLoadCount(iFrameLoadCount + 1);
  };

  const formatTime = (time) => {
    const minutes = `0${Math.floor(time / 60)}`;
    const seconds = time % 60 < 10 ? `0${time % 60}` : time % 60;
    return `${minutes}:${seconds}`;
  };

  const TimerHeader = () => {
    if (iFrameLoadCount > 1) {
      return (
        <div className="timer-header">
          {'Your transaction will expire in: '}
          <span className="count-down">{formatTime(timeoutCounter)}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="payment-challenge">
      {isModalOpen && (
        <Modal
          ariaHideApp={false}
          forceAction
          id={id}
          isOpen={isModalOpen}
          onClose={() => {
            clearIntervalHandles();
            setIFrameLoadCount(0);
          }}
          style={{
            content: {
              width,
            },
          }}
          header={TimerHeader}
        >
          <>
            {counter === 0 ? (
              <iframe
                onLoad={() => onLoad()}
                src={isModalOpen ? iFrameUrl : null}
                ref={formRef}
                style={{ border: 'none', width, height }}
                title={labels.title}
              />
            ) : (
              <div style={{ width, height }} className="p-3">
                <div className="icon-wrapper">
                  <Icon name="payment-challenge" />
                </div>
                <div className="redirect-message">{labels.redirectMessage}</div>
              </div>
            )}
            <div className="button-wrapper">
              <Button
                appearance="link"
                className="mx-auto"
                onButtonClick={() => {
                  clearIntervalHandles();
                  setIFrameLoadCount(0);
                  if (!closeTrigger) {
                    handleCancel();
                  } else {
                    handleSuccess();
                  }
                }}
              >
                {closeTrigger === CHALLENGE_CLOSE_TRIGGER.SUCCESS ? labels.close : labels.cancel}
              </Button>
            </div>
          </>
        </Modal>
      )}
    </div>
  );
};

PaymentChallenge.propTypes = {
  iFrameUrl: PropTypes.string,
  isModalOpen: PropTypes.bool,
  labels: PropTypes.shape({
    cancel: PropTypes.string,
    title: PropTypes.string,
    warning: PropTypes.string,
  }),
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleTimeout: PropTypes.func.isRequired,
};

PaymentChallenge.defaultProps = {
  isModalOpen: false,
};

export default PaymentChallenge;
