import Modal from '@viking-eng/modal/lib/v2';
import React from 'react';
import PropTypes from '../PropTypes';
import { printElement } from '../common/Utils/index';

const MvjModal = ({
  children,
  forceAction,
  handleModalClose,
  isImageFullSize,
  onClose,
  printLabel,
  subtitle,
  title,
  header,
  hasPreHeader,
  ...attrs
}) => {
  // TODO: Remove one of the two redundant props: handleModalClose and onClose.
  // Refactor any referenced code to use one or the other
  const handleClose = (event) => {
    /* eslint-disable no-unused-expressions */
    handleModalClose && handleModalClose(event);
    onClose && onClose(event);
  };

  const onPrint = () => {
    printElement({
      selector: '.mvj-modal-inner',
      title: `${title}${subtitle ? ` - ${subtitle}` : ''}`,
    });
  };

  return (
    <Modal
      {...attrs}
      appElement={document.getElementById('root')}
      forceAction={forceAction}
      onClose={handleClose}
      onPrint={onPrint}
      printLabel={printLabel}
      header={header}
      hasPreHeader={hasPreHeader}
    >
      <div className={`mvj-modal-inner ${isImageFullSize ? 'img-full' : ''}`}>{children}</div>
    </Modal>
  );
};

MvjModal.propTypes = {
  ...PropTypes.modalData,
  isImageFullSize: PropTypes.bool,
  handleModalClose: PropTypes.func.isRequired,
  hasPreHeader: PropTypes.bool,
};

MvjModal.defaultProps = {
  isImageFullSize: false,
  hasPreHeader: false,
};

export default MvjModal;
