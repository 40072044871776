import React from 'react';
import getDisplayName from 'recompose/getDisplayName';

import PropTypes from '../../PropTypes';

const withLoadingGuard = (WrappedComponent) => {
  const WithLoadingGuard = ({ isLoading, ...props }) => (
    <>{!isLoading && <WrappedComponent isLoading={isLoading} {...props} />}</>
  );

  WithLoadingGuard.propTypes = {
    isLoading: PropTypes.bool,
  };

  WithLoadingGuard.defaultProps = {
    isLoading: true,
  };

  WithLoadingGuard.displayName = `WithLoadingGuard(${getDisplayName(WrappedComponent)})`;

  return WithLoadingGuard;
};

export default withLoadingGuard;
