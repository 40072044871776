import CardSections from '@viking-eng/card-sections';
import get from 'lodash/get';
import React from 'react';
import PropTypes from '../../PropTypes';
import { FIVE_BY_FOUR, MODALS, TAB_NAMES, TWO_BY_ONE } from '../../common/Constants';
import remapCardDataBasedOnModalType from '../../common/ModalUtils';
import { getModalType } from '../../common/Utils';
import { PageHeader } from '../../components';
import { DialogModal, InformationModal } from '../../modals';
import './Documents.scss';
import ActivitiesOfInterest from './activitiesOfInterest/ActivitiesOfInterestContainer';
import ComfortCheckIn from './comfortCheckIn/ComfortCheckInContainer';
import GuestCheckIn from './comfortCheckIn/guestCheckIn/GuestCheckInContainer';
import FitToTravel from './fitToTravel/FitToTravelContainer';
import GuestInfo from './guestInfo/GuestInfoContainer';

const Documents = ({
  isLoading,
  match: { url },
  content,
  title,
  tabLabels,
  location: { pathname },
  handleCardDetailsClicked,
  modalData,
  informationModalData,
}) => {
  const { pageDescription, sections, backButton } = content;
  const paths = [
    {
      component: GuestInfo,
      componentProps: {
        backButton,
      },
      path: get(tabLabels, `${TAB_NAMES.GUEST_INFORMATION}.url`, ''),
    },
    {
      component: ComfortCheckIn,
      componentProps: {
        backButton,
      },
      path: get(tabLabels, `${TAB_NAMES.COMFORT_CHECK_IN}.url`, ''),
      subPaths: [
        {
          component: GuestCheckIn,
          componentProps: { isLoading },
          path: '/check-in',
        },
      ],
    },
    {
      component: FitToTravel,
      componentProps: {
        backButton,
      },
      path: get(tabLabels, `${TAB_NAMES.FIT_TO_TRAVEL}.url`, ''),
    },
    {
      component: ActivitiesOfInterest,
      componentProps: {
        backButton,
      },
      path: get(tabLabels, `${TAB_NAMES.ACTIVITIES_OF_INTEREST}.url`, ''),
    },
  ];

  const cardModals = ['visasCard', 'passportsCard'];
  const mappedSections = sections.map((section) => ({
    ...section,
    cards: section?.cards.map((card) => {
      if (cardModals.includes(card?.reference)) {
        const modalType = getModalType(`#${MODALS.DIALOG}`);
        card.subTitle = card.subtitle; // CMSv2 - UI Kit card requires subTitle
        const onClick = () => {
          handleCardDetailsClicked(modalType, card.id);
        };
        return remapCardDataBasedOnModalType(
          { ...card, primaryButtonUrl: `#${MODALS.DIALOG}` },
          {
            onPrimaryButtonClick: onClick,
            onSecondaryButtonClick: onClick,
            defaultClick: onClick,
          }
        );
      }
      card.images = (card.images || []).filter((img) => [FIVE_BY_FOUR, TWO_BY_ONE].includes(img.type));
      if (['informationForYourCruiseCard'].includes(card?.reference)) {
        const openModal = () => {
          const modalType = getModalType(card.primaryButtonUrl);
          handleCardDetailsClicked(modalType, card.id);
        };
        const onClick = card.primaryButton.onButtonClick || openModal;
        return remapCardDataBasedOnModalType(card, {
          onPrimaryButtonClick: onClick,
          onSecondaryButtonClick: onClick,
          defaultClick: onClick,
        });
      }
      return card;
    }),
  }));

  return (
    <div id="documentsPage" className="documents-page">
      <PageHeader
        isLoading={isLoading}
        paths={paths}
        hasLine
        title={title}
        pageHeaderPathDepth={3}
        pathname={pathname}
        url={url}
      />
      {pathname === url && (
        <>
          <CardSections sections={mappedSections} headerText={pageDescription} />
          <DialogModal id={MODALS.DIALOG} {...modalData} isPassportsVisasModal />
          <InformationModal {...informationModalData} id="informationForYourCruiseModal" />
        </>
      )}
    </div>
  );
};

Documents.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.routerMatch.isRequired,
  tabs: PropTypes.tabs,
  title: PropTypes.string,
  tabLabels: PropTypes.shape({}),
  location: PropTypes.routerLocation,
};

Documents.defaultProps = {
  tabs: [],
  title: '',
  tabLabels: {},
  location: {},
};

export default Documents;
