import Icon from '@viking-eng/icon';
import React from 'react';

import { prepareHtml } from '../../common/Utils';
import PropTypes from '../../PropTypes';

import './Notification.scss';

const Notification = ({ text, icon }) => (
  <div className="ntf-banner row no-gutters">
    <div className="ntf-icon col-auto">
      <Icon name={icon} />
    </div>
    <div className="col">
      <div className="ntf-row row no-gutters align-items-center">
        <div className="ntf-text col-md col-12 text-left" role="none" dangerouslySetInnerHTML={prepareHtml(text)} />
      </div>
    </div>
  </div>
);

Notification.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
};
Notification.defaultProps = {
  text: null,
  icon: null,
};
Notification.displayName = 'Notification';

export default Notification;
