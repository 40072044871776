import { connect } from 'react-redux';
import { compose } from 'redux';

import withContent from '../../content/WithContent';
import calendarStore, { fetchItineraryContent } from '../../../pages/calendar/CalendarStore';

import CalendarList from './CalendarList';

const { getAllCards } = calendarStore.selectors;

const mapStateToProps = (state) => ({
  cards: getAllCards(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchItineraryContent('itinerarySummary')),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(CalendarList);
