import { connect } from 'react-redux';
import { compose } from 'redux';
import commonStore from '../../../common';
import {
  CRUISE_CHECKOUT,
  EVO_USER_TYPES,
  FEATURE_RESTRICTED,
  MODALS,
  TAB_NAMES,
  USER_TYPES,
} from '../../../common/Constants';
import modalStore, { setViewAndShowModal } from '../../../common/ModalStore';
import userStore, { fetchBookings } from '../../../common/UserStore';
import { getCmsLabel } from '../../../common/Utils';
import { withContent, withLoadingGuard } from '../../../components';
import paymentsStore, { fetchTabContent } from '../PaymentsStore';
import CruisePayments from './CruisePayments';

const { CRUISE_PAYMENTS, ADDONS } = TAB_NAMES;
const {
  getCruisePayments,
  getCruiseMakePaymentSection,
  getDepositDueInformation,
  getPaymentMethodFormData,
  isLoadingTabs,
  getSchedulePaymentSection,
  getConfirmationModalData,
  getIsPaymentProcessing,
  getTabContent,
  getPaymentProcessingTimer,
} = paymentsStore.selectors;

const { setPaymentProcessingTimer } = paymentsStore.creators;

const { getTotalDue, getIsCloseToDepartureDate, getUpdateUserData, getFeatureRestricted } = userStore.selectors;

const {
  selectors: { getIsCurrentCountryPaymentBlocked, getPaymentsAllEnabled, getPaymentsCheckoutEnabled, getMvjStrings },
} = commonStore;

const { clearModal } = modalStore.creators;

const mapStateToProps = (state) => {
  const pageContent = getTabContent(state)(CRUISE_PAYMENTS);

  const { achNotAvailable } = getPaymentMethodFormData(state);
  const isCruiseImminent = getIsCloseToDepartureDate(state);
  const updateUserInfo = getUpdateUserData(state);
  const featureRestricted = getFeatureRestricted(state);
  const isPaymentsDisabled = !getPaymentsAllEnabled(state) || !getPaymentsCheckoutEnabled(state);
  const paymentButtonDisabled =
    isPaymentsDisabled ||
    isCruiseImminent ||
    getTotalDue(state) <= 0 ||
    getIsCurrentCountryPaymentBlocked(state)(CRUISE_CHECKOUT) ||
    updateUserInfo?.updateUserType === EVO_USER_TYPES[USER_TYPES.CSA] ||
    getIsPaymentProcessing(state) ||
    featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY;

  return {
    cruisePaymentsData: getCruisePayments(state),
    isLoading: isLoadingTabs(state),
    cruiseMakePaymentSection: getCruiseMakePaymentSection(state),
    depositDueInformation: getDepositDueInformation(state),
    paymentButtonDisabled,
    isCruiseImminent,
    isPaymentsDisabled,
    achNotAvailable,
    schedulePaymentSection: getSchedulePaymentSection(state),
    confirmationModalData: getConfirmationModalData(state),
    paymentInProgress: getIsPaymentProcessing(state),
    paymentProcessingMessage: getCmsLabel(pageContent.labels || [], 'paymentProcessingAlertMessage'),
    paymentProcessingTimerStatus: getPaymentProcessingTimer(state),
    paymentsDisabledBanner: getMvjStrings(state)?.labels?.generic?.paymentsDisabled || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(CRUISE_PAYMENTS)),
  onAlertContactUsClick: () => dispatch(setViewAndShowModal(MODALS.CONTACT_US)),
  handleClose: () => dispatch(clearModal()),
  handleModalOpen: (id) => dispatch(setViewAndShowModal(id)),
  setProcessingTimerStatus: (timerStatus) => dispatch(setPaymentProcessingTimer(timerStatus)),
  refetchContentAfterTimeout: () => {
    dispatch(fetchTabContent(CRUISE_PAYMENTS));
    dispatch(fetchTabContent(ADDONS));
    dispatch(fetchBookings());
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent, withLoadingGuard);

export default enhance(CruisePayments);
