import ButtonWithAlert from '@viking-eng/button-with-alert';
import Icon from '@viking-eng/icon';
import Radio from '@viking-eng/radio';
import Select from '@viking-eng/select';
import React from 'react';
import { Form } from 'redux-form';
import { AddToCartModalConfirmation, Field } from '..';
import { RESERVATION_STATE_KEYS, STATUS_TYPES } from '../../common/Constants';
import { ERROR_CODES, required } from '../../common/forms/Validations';
import { prepareHtml } from '../../common/Utils';
import { SPA_MODAL_STEPS } from '../../pages/onboard/OnboardStore';
import PropTypes from '../../PropTypes';
import './SpaModalSideContent.scss';

const { EDITING, CANCELING, CANCELED } = RESERVATION_STATE_KEYS;

const SpaModalSideContent = ({
  passengers,
  primaryButton,
  secondaryButton,
  error,
  alert,
  handleSubmit,
  itinerary,
  service,
  passengerNumber,
  day,
  availableTimes,
  handleSpaDateChange,
  formStep,
  reservationDetails,
  lockedDown,
  serviceDurations,
  handleSpaDurationChange,
  labels,
  submitting,
  state,
  reservationModalInfo,
  updateReservationModalState,
  cancelSpaReservation,
  loading,
  alertText,
  closeModal,
  onClose,
  isSpaAvailable,
  disableButton,
}) => {
  let primaryButtonWithClick;
  let onButtonClick;
  const { serviceCode } = service;
  const buttons = {
    primary: primaryButton,
    secondary: secondaryButton,
  };
  switch (formStep) {
    case SPA_MODAL_STEPS.CONFIRMATION:
      onButtonClick = () => {
        closeModal();
      };
      break;
    default: {
      onButtonClick = handleSubmit;
    }
  }

  if (primaryButton) {
    primaryButtonWithClick = {
      ...primaryButton,
      onButtonClick,
      disabled: lockedDown || disableButton,
      loading: submitting,
    };
  }
  const passenger = passengerNumber && <div className="passenger">{passengers[passengerNumber - 1].label}</div>;
  const loadingSpinner = loading && (
    <div className="container text-center">
      <span className="loading-icon">
        <Icon name="spinner" />
      </span>
    </div>
  );
  let content;

  switch (state) {
    case CANCELED:
    case CANCELING: {
      buttons.primary.onButtonClick = () => {
        if (state === CANCELED) {
          updateReservationModalState();
          closeModal();
          onClose();
        } else {
          cancelSpaReservation({ ...reservationModalInfo, labels });
        }
      };

      if (buttons.secondary.text) {
        buttons.secondary.onButtonClick = () => {
          updateReservationModalState(EDITING, {
            ...reservationModalInfo,
            statusMessage: null,
          });
        };
      }

      content = (
        <AddToCartModalConfirmation
          {...reservationModalInfo}
          primaryButton={buttons.primary}
          alert={state === CANCELED ? alert : ''}
          secondaryButton={buttons.secondary}
        />
      );

      break;
    }
    case EDITING: {
      buttons.primary.onButtonClick = () => {
        updateReservationModalState(CANCELING, reservationModalInfo);
      };
      content = (
        <AddToCartModalConfirmation
          {...reservationModalInfo}
          primaryButton={buttons.primary}
          statusMessage={{
            message: labels.reservationConfirmed,
            type: STATUS_TYPES.MESSAGE,
          }}
          alert={alert}
        />
      );
      break;
    }
    default: {
      let timeValidation = null;
      if (day) {
        if (availableTimes.length) {
          timeValidation = required;
        } else {
          timeValidation = () => ERROR_CODES.SPA_NO_AVAILABILITY;
        }
      }
      content = (
        <Form onSubmit={handleSubmit} className="spa-modal-side-content">
          <div className="error-external p-0" dangerouslySetInnerHTML={prepareHtml(alertText)} />
          <fieldset disabled={lockedDown || !isSpaAvailable}>
            {formStep === SPA_MODAL_STEPS.GUEST_SELECTION &&
              passengers.map(({ label }, i) => (
                <Field
                  key={label}
                  id={`passenger${i + 1}`}
                  component={Radio}
                  type="radio"
                  value={`${i + 1}`}
                  groupName="passengerNumber"
                  name="passengerNumber"
                  label={label}
                  appearance="checkbox"
                  reverse
                />
              ))}
            {formStep === SPA_MODAL_STEPS.BOOKING && (
              <>
                {passenger}
                {serviceDurations.length > 1 && (
                  <Field
                    component={Select}
                    disabled={!passengerNumber}
                    placeholder={labels.selectDuration}
                    options={serviceDurations}
                    name="service"
                    onChange={handleSpaDurationChange}
                    validate={required}
                    errorCodes={{
                      [ERROR_CODES.REQUIRED]: 'DurationRequired',
                    }}
                  />
                )}
                <Field
                  component={Select}
                  disabled={!serviceCode}
                  placeholder={labels.selectDay}
                  options={itinerary}
                  name="day"
                  onChange={(e, data) => handleSpaDateChange(serviceCode, passengerNumber, data)}
                  validate={serviceCode ? required : null}
                  errorCodes={{
                    [ERROR_CODES.REQUIRED]: 'DayRequired',
                    [ERROR_CODES.PROCESS_FAILED]: ERROR_CODES.PROCESS_FAILED,
                  }}
                />
                <Field
                  component={Select}
                  disabled={!day || !availableTimes.length}
                  placeholder={labels.selectTime}
                  options={availableTimes}
                  name="time"
                  validate={timeValidation}
                  errorCodes={{
                    [ERROR_CODES.REQUIRED]: 'TimeRequired',
                    [ERROR_CODES.SPA_NO_AVAILABILITY]: ERROR_CODES.SPA_NO_AVAILABILITY,
                  }}
                />
              </>
            )}
            {formStep === SPA_MODAL_STEPS.CONFIRMATION && (
              <>
                <div>{passenger}</div>
                <div>{reservationDetails.title}</div>
                <div>{reservationDetails.date}</div>
                <div>{reservationDetails.time}</div>
                <div>{reservationDetails.duration}</div>
              </>
            )}
            {loadingSpinner}
            <div className="reservation-actions">
              <ButtonWithAlert primaryButton={primaryButtonWithClick} />
            </div>
          </fieldset>
          {(error || alert) && <div className="error-external">{error || alert}</div>}
        </Form>
      );
    }
  }

  return content;
};

SpaModalSideContent.propTypes = {
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.string,
  primaryButton: PropTypes.button,
  secondaryButton: PropTypes.button,
  handleSubmit: PropTypes.func.isRequired,
  alert: PropTypes.string,
  handleSpaDateChange: PropTypes.func.isRequired,
  service: PropTypes.shape({
    serviceCode: PropTypes.string,
  }),
  itinerary: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      description: PropTypes.string,
      dayValue: PropTypes.string,
    })
  ),
  availableTimes: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
    })
  ).isRequired,
  passengerNumber: PropTypes.string,
  day: PropTypes.shape({
    value: PropTypes.string,
  }),
  formStep: PropTypes.string,
  reservationDetails: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    duration: PropTypes.string,
  }).isRequired,
  lockedDown: PropTypes.bool.isRequired,
  serviceDurations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.shape({
        serviceCode: PropTypes.string,
        duration: PropTypes.number,
      }),
      label: PropTypes.string,
    })
  ),
  handleSpaDurationChange: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    selectDuration: PropTypes.string.isRequired,
    selectDay: PropTypes.string.isRequired,
    selectTime: PropTypes.string.isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  state: PropTypes.string,
  reservationModalInfo: PropTypes.shape({
    item: PropTypes.shape({}),
  }),
  updateReservationModalState: PropTypes.func.isRequired,
  cancelSpaReservation: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

SpaModalSideContent.defaultProps = {
  formStep: '',
  passengerNumber: null,
  day: null,
  itinerary: [],
  passengers: [],
  serviceDurations: [],
  service: {},
  error: '',
  alert: '',
  loading: false,
  primaryButton: {},
  secondaryButton: {},
  onClose: () => {},
};

export default SpaModalSideContent;
