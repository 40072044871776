import { connect } from 'react-redux';
import { compose } from 'redux';

import onboardStore, { fetchTabContent } from '../OnboardStore';
import { withContent } from '../../../components';
import { TAB_NAMES } from '../../../common/Constants';

import Enrichment from './Enrichment';

const { getEnrichmentTabContent } = onboardStore.selectors;
const tabName = TAB_NAMES.ENRICHMENT;

const mapStateToProps = (state) => ({
  enrichmentContent: getEnrichmentTabContent(state),
});
const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(tabName)),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withContent);

export default enhance(Enrichment);
