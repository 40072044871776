import { connect } from 'react-redux';
import commonStore, { modalStore } from '../../common';
import onboardStore, { cancelReservation, fetchRestaurantData } from '../../pages/onboard/OnboardStore';
import DiningModalSideContent from './DiningModalSideContent';

const {
  creators: { updateReservationModalState },
  selectors: { getReservationModalInfo },
} = commonStore;

const { clearModal } = modalStore.creators;

const {
  creators: { updateAvailabilityLoading },
  selectors: { getDiningBeverageModalCards },
} = onboardStore;

const mapStateToProps = (state) => {
  const { state: reservationState, metadata } = getReservationModalInfo(state);

  return {
    ...getDiningBeverageModalCards(state),
    state: reservationState,
    reservationModalInfo: metadata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchModalData: (code, partySize, shipId, voyageType) => {
    dispatch(updateAvailabilityLoading(true, code, shipId, voyageType));
    dispatch(fetchRestaurantData(code, partySize, shipId));
  },
  updateReservationModalState: (...args) => dispatch(updateReservationModalState(...args)),
  cancelReservation: (payload) => dispatch(cancelReservation(payload)),
  closeModal: () => dispatch(clearModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiningModalSideContent);
