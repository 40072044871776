import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearSubmitErrors, getFormValues, isSubmitting, reduxForm, SubmissionError } from 'redux-form';
import commonStore from '../../../common';
import { getFriendlyError, postPassengerSurvey } from '../../../common/CommonStore';
import { APP_PATHS, FORMS, RESERVATION_STATE_KEYS, SURVEY_CODES, USER_TYPES } from '../../../common/Constants';
import { ERROR_CODES } from '../../../common/forms/Validations';
import userStore from '../../../common/UserStore';
import { goTo } from '../../../common/Utils';
import { removeJourneyAddons } from '../../../pages/payments/PaymentsStore';
import shorexStore, {
  cancelExcursion,
  handleCloseExcursionModal,
  removeShoreExFromCart,
  updateShoreExBooking,
} from '../../../pages/shorex/ShorexStore';
import ActionItemForm from './ActionItemForm';
import withConnector from './ActionItemFormConnector';

const { RESERVED, OPEN, CANCELING, IN_CART } = RESERVATION_STATE_KEYS;
const { getPassengers, getIsTaAccount, getUserType } = userStore.selectors;
const {
  selectors: { getErrors, getPax1SubWaiver, getPax2SubWaiver, getSubmarineWaiver, getLabels },
  creators: { setPax1SubWaiver, setPax2SubWaiver },
} = commonStore;

const {
  creators: { updateShoreExModalState },
  selectors: {
    getShoreExModalInfo,
    getShorexSideContentForPassenger,
    getSideContentModalInitialValues,
    getUpdatingModalStateFlag,
  },
} = shorexStore;
const mapStateToProps = (state, { passenger, date }) => {
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);
  const mainUser = userType === USER_TYPES.CONSUMER && !isTaAccount;

  const allLabels = getLabels(state);
  const bookNowBtnText = get(allLabels, ['buttons', 'bookNow'], 'BOOK NOW');
  const pax1SubWaiver = getPax1SubWaiver(state);
  const pax2SubWaiver = getPax2SubWaiver(state);
  const submarineWaiverCMSData = getSubmarineWaiver(state);
  const downloadWaiverBtnText = submarineWaiverCMSData?.callToActionTitle;
  const subWaiverTitle = submarineWaiverCMSData?.longText;
  const subWaiverCheckBoxLabel = mainUser ? submarineWaiverCMSData?.title : submarineWaiverCMSData.subtitle;
  const subWaiverUrl = submarineWaiverCMSData?.callToActionUrl;
  const { passengerNumber } = passenger;
  const FORM_NAME = `booking-${passengerNumber}`;
  const { time: selectedInventoryCode } = getFormValues(FORM_NAME)(state) || {};
  const passengers = getPassengers(state) || [];
  const isOtherSubmitting = passengers
    .filter((pax) => pax.passengerNumber !== passengerNumber)
    .map((pax) => isSubmitting(`${FORMS.BOOKING}-${pax.passengerNumber}`)(state))
    .some((submitting) => submitting);
  // eslint-disable-next-line max-len
  const sideContent = getShorexSideContentForPassenger(state, passengerNumber, selectedInventoryCode, date);
  return {
    ...sideContent,
    initialValues: getSideContentModalInitialValues(state, { passengerNumber, date }),
    errors: getErrors(state),
    modalReservationInfo: getShoreExModalInfo(state),
    isModalStateUpdating: getUpdatingModalStateFlag(),
    form: `${FORMS.BOOKING}-${passengerNumber}`,
    lockedDown: sideContent?.lockedDown || isOtherSubmitting,
    isOtherSubmitting,
    pax1SubWaiver,
    pax2SubWaiver,
    downloadWaiverBtnText,
    subWaiverTitle,
    subWaiverCheckBoxLabel,
    subWaiverUrl,
    bookNowBtnText,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateReservationModalState: (...args) => dispatch(updateShoreExModalState(...args)),
  removeCartItem: (itemId) => {
    dispatch(removeJourneyAddons(itemId));
  },
  setPax1SubWaiver: () => dispatch(setPax1SubWaiver(true)),
  setPax2SubWaiver: () => dispatch(setPax2SubWaiver(true)),
  submitSubmarinWaiver: (paxNum) => dispatch(postPassengerSurvey(paxNum, SURVEY_CODES.SUBMARINE_WAIVER)),
});

const onChange = (values, dispatch, { form, error }) => {
  if (error) {
    dispatch(clearSubmitErrors(form));
  }
};

const onSubmit = (values, dispatch, props) =>
  new Promise((resolve, reject) => {
    const { errors, metadataRemapped = {}, fromDate, passengerNumber, hasConflict, modalReservationInfo } = props;
    const passengerIndex = passengerNumber - 1;
    const modalInfo = modalReservationInfo[passengerIndex];
    switch (modalReservationInfo[passengerIndex].state) {
      case CANCELING:
        return dispatch(cancelExcursion(values, modalInfo))
          .then((response) => {
            if (response.isSuccessful) {
              dispatch(updateShoreExModalState(OPEN, { ...metadataRemapped, passengerIndex }));
              resolve();
            } else {
              let error = get(errors, ERROR_CODES.CANCEL_FAILED);
              dispatch((_, getState) => {
                const state = getState();
                if (response.status === 400) {
                  error = getFriendlyError(state, response.data);
                }
                reject(
                  new SubmissionError({
                    _error: error,
                  })
                );
              });
            }
          })
          .catch(() => {
            reject(
              new SubmissionError({
                _error: get(errors, ERROR_CODES.UPDATE_FAILED),
              })
            );
          });
      case IN_CART:
        return dispatch(removeShoreExFromCart(values, passengerNumber, fromDate))
          .then((response) => {
            if (response.isSuccessful) {
              dispatch(
                updateShoreExModalState(OPEN, {
                  ...metadataRemapped,
                  passengerIndex,
                })
              );
              resolve();
            } else {
              reject(
                new SubmissionError({
                  _error: get(errors, ERROR_CODES.REMOVE_FAILED),
                })
              );
            }
          })
          .catch(() => {
            reject(
              new SubmissionError({
                _error: get(errors, ERROR_CODES.UPDATE_FAILED),
              })
            );
          });
      default:
        if (hasConflict) {
          goTo(`${APP_PATHS.ITINERARY}/${fromDate}`)();
          dispatch(handleCloseExcursionModal());
          return resolve('CONFLICT');
        }
        return dispatch(updateShoreExBooking(values, passengerNumber, fromDate))
          .then((response) => {
            if (response.isSuccessful) {
              const { singlePrice, doublePrice } = modalInfo.metadata || {};
              const status = (singlePrice === doublePrice) === 0 ? RESERVED : IN_CART;
              dispatch(
                updateShoreExModalState(status, {
                  ...metadataRemapped,
                  passengerIndex,
                })
              );
              resolve();
            }
          })
          .catch(reject);
    }
  });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    onChange,
    onSubmit,
  }),
  withConnector
);

export default enhance(ActionItemForm);
