import { connect } from 'react-redux';

import commonStore from '../../common/CommonStore';
import { setViewAndShowModal } from '../../common/ModalStore';
import userStore from '../../common/UserStore';

import { msalInstance } from '../../common/Authentication';
import { APP_PATHS } from '../../common/Constants';
import MvjFooter from './MvjFooter';

const { getFooterLinks, getFooterCallViking, isFooterContentAvailable } = commonStore.selectors;
const { getBookingDetails } = userStore.selectors;

const mapStateToProps = (state) => {
  const footerAvailable = isFooterContentAvailable(state);
  const d = new Date();
  const year = d.getFullYear();
  const footerCopyText = `<p>&copy; Viking Cruises, ${year} All Rights Reserved.</p>`;
  const defaultFooterLinks = {
    copyrightText: footerCopyText,
    items: [
      { altText: 'Privacy Policy', text: 'Privacy Policy', className: '', linkUrl: '/privacy' },
      { altText: 'Cookie Policy', text: 'Cookie Policy', className: 'ot-sdk-cookie-policy', linkUrl: '#' },
    ],
  };
  const footerLinks = footerAvailable ? getFooterLinks(state) : defaultFooterLinks;

  const PHONE_NUMBER_PATHS = [APP_PATHS.LOGIN_FAQS];
  if (PHONE_NUMBER_PATHS.includes(window.location.pathname)) {
    const bookingDetails = getBookingDetails(state);
    const { office } = bookingDetails;
    const geolocationData = window?.OneTrust?.getGeolocationData();
    const userCountry = geolocationData?.country || 'US';
    const footerCallViking = getFooterCallViking(state);
    const isLoggedIn = !!msalInstance.getActiveAccount();
    const localNumber = isLoggedIn
      ? footerCallViking?.vikingNumbers?.[office]
      : footerCallViking?.vikingNumbers?.[userCountry];
    const { callViking } = footerCallViking;

    return { ...footerLinks, callViking, localNumber };
  }
  return {
    ...footerLinks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleModalOpen: (modalId = '') => {
    const id = modalId.replace('#', '');
    dispatch(setViewAndShowModal(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MvjFooter);
