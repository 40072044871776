// eslint-disable-next-line no-unused-vars
import Button from '@viking-eng/button';
import { useEffect, useRef, useState } from 'react';
import PropTypes from '../../PropTypes';
import { CHAT_BUTTON_POLL_TIME } from '../../common/Constants';
import { getChatContainerElements } from '../../common/Utils';
import { BUTTON_LOOK } from './ChatAvailableButtonContent';

const ChatAvailableButton = ({ onButtonClick, className, hideIfUnavailable, tooltip }) => {
  const intervalHandle = useRef(null);
  const [isAvailable, setAvailable] = useState((getChatContainerElements() || []).length > 0 || !hideIfUnavailable);
  useEffect(() => {
    intervalHandle.current = setInterval(() => {
      setAvailable((getChatContainerElements() || []).length > 0 || !hideIfUnavailable);
    }, CHAT_BUTTON_POLL_TIME);

    return () => {
      clearTimeout(intervalHandle.current);
    };
  }, [hideIfUnavailable]);

  return isAvailable ? (
    <div className={className}>
      <Button
        attributes={{
          'aria-label': tooltip,
        }}
        onButtonClick={(e) => {
          const containers = getChatContainerElements();
          (containers || []).forEach((c) => {
            if (c?.click) {
              c.click();
            }
          });
          if (onButtonClick) {
            onButtonClick(e);
          }
        }}
        disabled={!isAvailable}
      >
        <div className="d-md-none d-lg-block">{BUTTON_LOOK.STANDARD}</div>
        <div className="d-none d-md-block d-lg-none">{BUTTON_LOOK.COMPACT}</div>
      </Button>
    </div>
  ) : (
    <></>
  );
};

ChatAvailableButton.propTypes = {
  className: PropTypes.string,
  hideIfUnavailable: PropTypes.bool,
  tooltip: PropTypes.string,
};

ChatAvailableButton.displayName = 'ChatAvailableButton';

export default ChatAvailableButton;
