/* eslint-disable react/jsx-filename-extension */
// Disabling because create-react-app requires the entry point be named index.js
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import LoadingPage from '@viking-eng/loading-page';
import { JIRA_ISSUE_CONFIG, appInsightsHelper } from '@viking-eng/telemetry';
import { nanoid } from 'nanoid';
import 'normalize.css';
import React from 'react';
import { render } from 'react-dom';
import { FlagsProvider } from 'react-feature-flags';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import AppContainer from './AppContainer';
import configureStore from './ConfigureStore';
import commonStore, { history } from './common';
import { reactPlugin } from './common/Analytics';
import { MSAL_CONFIG } from './common/AuthConfig';
import { msalInstance } from './common/Authentication';
import {
  APP_INSIGHTS,
  APP_INSIGHTS_TRACK_TYPE,
  APP_PATHS,
  APP_TITLE,
  ENVIRONMENT_CODE,
  FLAGS,
  MVJ_FLAG_VARIABLES,
  PROACTIVE_CHAT_TIMER,
} from './common/Constants';
import { debugLog, navigateTo } from './common/Utils';
import './common/polyfill';

const { logger } = appInsightsHelper;
const { initWeb } = appInsightsHelper;
const { getFlagValue, getMaintenanceModeActive } = commonStore.selectors;

const store = configureStore();
let proactiveIntervalId;
store.subscribe(() => {
  const state = store.getState();

  const enableInteractiveChat = getFlagValue(state)(MVJ_FLAG_VARIABLES?.ENABLE_INTERACTIVE_CHAT);
  const isMaintenanceModeActive = getMaintenanceModeActive(state);
  if (isMaintenanceModeActive) {
    navigateTo(APP_PATHS.SYSTEM_MAINTENANCE);
  }
  proactiveIntervalId = setInterval(() => {
    const proactiveDiv = document.querySelector('.proactive-chat');
    if (proactiveDiv?.classList && !proactiveDiv.classList.contains('open') && enableInteractiveChat) {
      proactiveDiv.classList.add('open');
    }
    clearInterval(proactiveIntervalId);
  }, PROACTIVE_CHAT_TIMER);
});

const persistor = persistStore(store);

document.title = APP_TITLE;

try {
  if (window.sessionStorage && APP_INSIGHTS?.CONNECTION_STRING) {
    initWeb({
      appInsights: ApplicationInsights,
      appInsightsConfig: APP_INSIGHTS,
      environment: ENVIRONMENT_CODE,
      history,
      jiraIssueConfig: JIRA_ISSUE_CONFIG.CORE_BUG,
      reactPlugin,
      sessionId: nanoid(),
      sessionKey: 'APP_INSIGHTS_INFO',
      isLogInfoEnabled: ENVIRONMENT_CODE !== 'prod',
      isLogErrorEnabled: ENVIRONMENT_CODE !== 'prod',
    });
  }
} catch {
  // eslint-disable-next-line no-console
  console.error('Cannot load window.sessionStorage');
}

msalInstance.initialize().then(() => {
  debugLog('MSAL: INITIALIZED');
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  } else if (msalInstance.getActiveAccount()) {
    debugLog(`MSAL: ${JSON.stringify({ account: msalInstance.getActiveAccount() })}`);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    debugLog('EVENT INFO', event);
    if (
      [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS].includes(
        event.eventType
      ) &&
      event.payload.account
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    } else if (
      [
        EventType.LOGIN_FAILURE,
        EventType.SSO_SILENT_FAILURE,
        EventType.ACQUIRE_TOKEN_FAILURE,
        EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
      ].includes(event.eventType)
    ) {
      debugLog('MSAL: log redirect');
      logger({
        type: APP_INSIGHTS_TRACK_TYPE.TRACE,
        name: 'Authentication Error',
        message: `Authentication Event Received: ${event.eventType}`,
        severity: SeverityLevel.Critical,
        logData: {
          errorCode: event.eventType || '(N/A)',
          errorDescription: event || '(N/A)',
        },
      });
      // TODO: Move to RequireAuth.jsx when we have that implemented
      const acct = msalInstance.getActiveAccount();
      if (acct?.idTokenClaims?.UserType === 'TA') {
        navigateTo(APP_PATHS.OOPS_PAGE);
      } else {
        msalInstance.loginRedirect(MSAL_CONFIG);
      }
      return;
    }
    debugLog('MSAL: not handled');
  });

  render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={<LoadingPage variant="blue" />} persistor={persistor}>
          <FlagsProvider value={FLAGS}>
            <Router history={history}>
              <AppContainer />
            </Router>
          </FlagsProvider>
        </PersistGate>
      </Provider>
    </MsalProvider>,
    document.getElementById('root')
  );
});
