import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, reset, stopSubmit, SubmissionError } from 'redux-form';
import { FORMS, TAB_NAMES } from '../../../common/Constants';
import { withContent, withLoadingGuard, withReinitializeForm } from '../../../components';
import airStore, { fetchTabContent, updatePassengerAirPreferences } from '../AirStore';
import AirPreferences from './AirPreferences';

const formName = FORMS.AIR;
const { getAvailablePreferencesTabContent, isLoadingTabs } = airStore.selectors;

const pageAnalyticsData = () => {
  return {
    page_name: 'Air Preferences',
    page_id: 'airpreferences',
  };
};

const mapStateToProps = (state) => {
  return {
    ...getAvailablePreferencesTabContent(state),
    isLoading: isLoadingTabs(state),
    pageAnalytics: pageAnalyticsData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () => dispatch(fetchTabContent(TAB_NAMES.AIR_PREFERENCES)),
  handleCancel: () => {
    window.scrollTo(0, 0);
    dispatch(reset(formName));
  },
});

const onSubmit = ({ seat, passengers }, dispatch, props) => {
  const promise = new Promise((resolve, reject) => {
    const payloadPassengers = [{ seat }, { seat }];
    if (Array.isArray(passengers)) {
      passengers.forEach((passenger, index) => {
        if (passenger) {
          const { meal, meetAndAssist, wheelchair, knownTravelerNumber, redressNumber, frequentFlyers } = passenger;
          const filteredFFNs = frequentFlyers.filter(
            (val, idx, arr) => arr.findIndex((t) => t.airlineCode === val.airlineCode) === idx
          );
          payloadPassengers[index] = {
            seat,
            meal,
            meetAndAssist,
            wheelchair,
            knownTravelerNumber,
            redressNumber,
            frequentFlyers: filteredFFNs.map((ff, idx) => ({
              ...ff,
              sequence: idx + 1,
            })),
          };
        }
      });

      dispatch(updatePassengerAirPreferences(payloadPassengers)).then(({ isSuccessful }) => {
        if (isSuccessful) {
          return resolve();
        }
        return reject(new SubmissionError({ _error: props.labels.error }));
      });

      return;
    }

    resolve(false);
  });
  return promise;
};

const onChange = (values, dispatch, { submitSucceeded, error }) => {
  if (submitSucceeded || error) {
    dispatch(stopSubmit(formName)); // stopSubmit clears submitSucceeded flag
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  withLoadingGuard,
  reduxForm({
    form: formName,
    onChange,
    onSubmit,
    enableReinitialize: true,
  }),
  withReinitializeForm
);

export default enhance(AirPreferences);
