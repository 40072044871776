import Radio from '@viking-eng/radio';
import withResponsiveGrid from '@viking-eng/responsive-input-grid';
import React, { Component } from 'react';
import { Field } from '..';
import PropTypes from '../../PropTypes';
import './SeatingPreference.scss';

const ResponsiveRadioGrid = withResponsiveGrid(Radio);

class SeatingPreference extends Component {
  createComponent = ({ input, disabled }) => {
    const { seatings, header } = this.props;

    const grids = [
      {
        tier: 'sm',
        rows: 2,
      },
      {
        tier: 'xl',
        rows: 1,
      },
    ];

    const mappedInputs = seatings.map((seat) => {
      const { label, imageUrl, id } = seat;
      const value = id;
      return {
        groupName: 'seat',
        id,
        label,
        imageURL: imageUrl,
        input: {
          ...input,
          disabled,
          value,
          checked: value === input.value,
        },
      };
    });

    return <ResponsiveRadioGrid grids={grids} inputData={mappedInputs} label={header} />;
  };

  render() {
    const { onChange, lockedDown } = this.props;
    return (
      <div className="seating-preference">
        <Field name="seat" component={this.createComponent} onChange={onChange} disabled={lockedDown} />
      </div>
    );
  }
}

SeatingPreference.propTypes = {
  seatings: PropTypes.arrayOf(PropTypes.seating),
  input: PropTypes.shape({}),
  header: PropTypes.string,
  onChange: PropTypes.func,
  lockedDown: PropTypes.bool,
};
SeatingPreference.defaultProps = {
  lockedDown: false,
  seatings: [],
  input: {
    value: '',
  },
  header: '',
  onChange: () => true,
};

export default SeatingPreference;
