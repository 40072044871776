import { connect } from 'react-redux';

import commonStore from '../../common/CommonStore';
import { setViewAndShowModal } from '../../common/ModalStore';

import PreAuthInterstitial from './PreAuthInterstitial';

const { getPreAuthInterstitialContent } = commonStore.selectors;
const { updateInterstitialVariables } = commonStore.creators;

const mapStateToProps = (state, { location: { pathname } }) => ({
  ...getPreAuthInterstitialContent(state)(pathname),
});

const mapDispatchToProps = (dispatch) => ({
  updateInterstitialVariables: (vars) => dispatch(updateInterstitialVariables(vars)),
  handleModalOpen: (modalId = '') => {
    const id = `${modalId}-modal`;
    dispatch(setViewAndShowModal(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreAuthInterstitial);
